<div class="dashboard-box">

  <app-navbar></app-navbar>
  <app-sidebar></app-sidebar>

  <div class="dashboard-body no-banner">
    <div class="container-fluid">
      <div class="page-header">
        <h2>My Profile</h2>
      </div>
    </div>

    <div class="container-fluid">
      <div class="card" *ngIf="profile">
        <div class="card-body">
          <div *ngIf="admissionStatus?.statusStep === 0">
            <button *ngIf="profile.formComplete === true;" (click)="editProfileDialog()"
              class="btn btn-square btn-primary edit-profile-btn">Edit Profile</button>
          </div>
          <button (click)="changePasswordDialog()" class="btn btn-square btn-secondary change-password-btn">Change
            Password</button>
          <div class="row">
            <div class="col-md-2">
              <div class="image-div">
                <img class="rounded-full" [src]="url + 'uploads/profilePic/' + profile.profilePic">
                <button (click)="editProfilePic()" class="edit-btn"><i class="far fa-edit"></i></button>
              </div>
            </div>
            <div class="col-md-3 my-auto">
              <div class="my-auto">
                <h4>{{profile.firstname}} {{profile.middlename}} {{profile.lastname}}</h4>
                <h5>{{profile.state}}<span *ngIf="profile.state">,</span> {{profile.country}}</h5>
              </div>
            </div>
            <div class="col-md-3 col-6 my-auto">
              <div class="my-auto">
                <p><i class="fas fa-envelope mr-2"></i> {{profile.email}}</p>
                <p *ngIf="profile.phone"><i class="fas fa-phone mr-2"></i> {{profile.phone}}</p>
                <p *ngIf="profile.address"><i class="fas fa-map-pin mr-2"></i> {{profile.address}}</p>
              </div>
            </div>
            <div class="col-md-3 col-6 my-auto">
              <div class="my-auto">
                <p *ngIf="profile.gender"><i class="fas fa-venus-mars mr-2"></i> {{profile.gender}}</p>
                <p *ngIf="profile.dob"><i class="fas fa-birthday-cake mr-2"></i> {{profile.dob | date}}</p>
                <p *ngIf="profile.postalCode"><i class="fas fa-map-marked mr-2"></i> {{profile.postalCode}}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="uploadDocumentsData || userStudyDetails || userSelectedService" class="card-body">
          <mat-tab-group>
            <mat-tab label="University And Colleges Selected" *ngIf="userStudyDetails.programs?.length > 0">
              <div class="row" *ngIf="nonEditStudy === true; else editStudy">
                <div class="col-md-6 mb-3" *ngIf="userStudyDetails.university?.length > 0">
                  <ul class="list-group">
                    <li class="list-group-item list-group-item-action active">Universities (order of priority)</li>
                    <li class="list-group-item" *ngFor="let university of userStudyDetails.university; let i = index">
                      {{i + 1}}. {{ university.itemName}} <span *ngIf="university.campus">, <strong>Campus: </strong>
                        {{ university.campus }}</span></li>
                  </ul>
                </div>
                <div class="col-md-6 mb-3" *ngIf="userStudyDetails.colleges?.length > 0">
                  <ul class="list-group">
                    <li class="list-group-item list-group-item-action active">Colleges (order of priority)</li>
                    <li class="list-group-item" *ngFor="let college of userStudyDetails.colleges; let i = index">
                      {{i + 1}}. {{ college.itemName}} <span *ngIf="college.campus">, <strong>Campus: </strong>
                        {{ college.campus }}</span></li>
                  </ul>
                </div>
                <div class="col-md-4 mb-3">
                  <ul class="list-group">
                    <li class="list-group-item list-group-item-action active">Programs</li>
                    <li class="list-group-item" *ngFor="let program of userStudyDetails.programs; let i = index">
                      {{i + 1}}. {{ program.itemName}}</li>
                  </ul>
                </div>
                <div class="col-md-4 mb-3">
                  <ul class="list-group">
                    <li class="list-group-item list-group-item-action active">Degree of interest</li>
                    <li class="list-group-item" *ngFor="let item of userStudyDetails.degree; let i = index">
                      {{ item.itemName}}</li>
                  </ul>
                </div>
                <div class="col-md-4 mb-3" *ngIf="userStudyDetails.currentStudy">
                  <ul class="list-group">
                    <li class="list-group-item list-group-item-action active">Current School/Institution/University
                    </li>
                    <li class="list-group-item">{{userStudyDetails.currentStudy}}</li>
                  </ul>
                </div>
                <div class="col-md-12" *ngIf="admissionStatus?.statusStep === 0 && immigrationStatus?.statusStep === 0">
                  <div class="text-center mt-3">
                    <div (click)="editStudyPopup()" class="btn btn-square btn-danger">Edit</div>
                  </div>
                </div>
              </div>
              <ng-template #editStudy>
                <app-edit-study-info (selectStory)="editStudyPopup()"></app-edit-study-info>
              </ng-template>
            </mat-tab>
            <mat-tab label="Services Required" *ngIf="userSelectedService">
              <div class="row" *ngIf="nonEditService === true; else editService">
                <div class="col-md-6" *ngFor="let services of userSelectedService.servicesName">
                  <div class="profile-card-inf__title mt-3">
                    <div class="row">
                      <div class="col-8 col-md-8">
                        <div class="profile-card-inf__item">{{services.heading}}:</div>
                      </div>
                      <div class="col-4 col-md-4 my-auto">
                        <span [ngClass]="{
                          'yes-btn': services.need,
                          'no-btn': !services.need
                      }">{{services.need ? "Yes":"No"}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="text-center mt-3">
                    <div (click)="editServicePopup()" class="btn btn-square btn-danger">Edit</div>
                  </div>
                </div>
              </div>
              <ng-template #editService>
                <app-edit-services (selectStory)="editServicePopup()"></app-edit-services>
              </ng-template>
            </mat-tab>
            <mat-tab label="My Uploaded Documents" *ngIf="uploadDocumentsData">
              <div class="row fileUploader" *ngFor="let item of documentList; let i = index">
                <div class="col-sm-12" id="one">
                  <div id="uploader">
                    <div class="row uploadDoc">
                      <div class="col-sm-8 my-auto">
                        <h4>{{i + 1}}. {{item.name}}</h4>
                        <p>{{item.description}}</p>
                      </div>
                      <div class="col-sm-4 text-right my-auto">
                        <a *ngIf="uploadDocumentsData[i]?.url === null; else afterUploaded"
                          class="btn btn-square btn-primary mr-1" href="/starting-documents">Upload File</a>
                        <ng-template #afterUploaded>
                          <a target="blank" [href]="uploadDocumentsData[i].url"
                            class="btn btn-square btn-primary mr-1">View</a>
                          <a (click)="uploadDocuments(item.name)" class="btn btn-square btn-success mr-1">Update</a>
                          <a class="btn btn-square btn-danger" (click)="removeDocuments(item.name)">X</a>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Additional Documents & Invoices" *ngIf="additionalDocs.length !== 0">
              <div class="row fileUploader" *ngFor="let item of additionalDocs; let i = index">
                <div class="col-sm-12" id="one">
                  <div id="uploader">
                    <div class="row uploadDoc">
                      <div class="col-sm-8 my-auto">
                        <h4>{{i + 1}}. {{item.title}}</h4>
                      </div>
                      <div class="col-sm-4 text-right my-auto">
                        <a target="blank" [href]="item.doc" class="btn btn-square btn-primary mr-1">View</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

    </div>
    <app-site-footer></app-site-footer>
  </div>
</div>
