<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <h3 class="mb-4">Housing Information</h3>
    <button *ngIf="!housingService?.selectedHouse" class="btn btn-danger edit-btn"
      (click)="editDialog(editModel)">Edit</button>
    <div class="row">
      <div class="col-md-4">
        <p><strong>City: </strong> {{housingService?.city}}</p>
        <p><strong>Move-In Year: </strong> {{housingService?.startSemesterYear}}</p>
        <p *ngIf="housingService?.budgetRange"><strong>Minimum Budget: </strong> C$
          {{housingService?.budgetRange.minimum}}</p>
      </div>
      <div class="col-md-8">
        <p><strong>University Or College: </strong> {{housingService?.university.itemName}},
          {{housingService?.university.city}}<span *ngIf="housingService?.university.campus">,
            {{housingService?.university.campus}} Campus</span> </p>
        <p><strong>Move-In Semester: </strong> {{housingService?.semesterType}}</p>
        <p *ngIf="housingService?.budgetRange"><strong>Maximum Budget: </strong> C$
          {{housingService?.budgetRange.maximum}}</p>
      </div>
    </div>
  </div>
</div>

<div class="card wow animate__animated animate__fadeInUp" *ngIf="housingService?.selectedHouse?.name">
  <div class="card-header">Your Selected House Plan</div>
  <div class="card-body">

    <div class="booking-design">
      <div class="row no-gutters">
        <div class="col-lg-4 col-md-5">
          <figure>
            <ngx-gallery [options]="galleryOptions" [images]="housingService?.selectedHouse.image" class="ngx-gallery">
            </ngx-gallery>
          </figure>
        </div>

        <div class="col-lg-8 pl-5 col-md-7">
          <div class="booking-design_full">
            <div class="booking-design_header">
              <div class="booking-design_header_left">
                <h4 class="booking-design_title">{{housingService?.selectedHouse.name}}</h4>
                <span class="location"><i class="ti-location-pin"></i> <span
                    *ngFor="let city of housingService?.selectedHouse.city"> {{city}}, </span></span>
              </div>
              <div class="booking-design_header_right">
                <h4 class="booking-design_price"><sup>C$</sup>
                  {{housingService?.selectedHouse?.plans[housingService?.selectedPlan]?.price}}</h4>
                <span class="booking-time">{{housingService?.selectedHouse.unit}}</span>
              </div>
            </div>
            <div class="booking-design_offers">
              <ul>
                <li><strong>Room Type: </strong>
                  {{housingService?.selectedHouse?.plans[housingService?.selectedPlan]?.roomType}}</li>
                <li><strong>Room Type: </strong>
                  {{housingService?.selectedHouse?.plans[housingService?.selectedPlan]?.type}}
                </li>
              </ul>
            </div>
            <div class="booking-design_rate">
              <button class="over_all_rate high more-eclips collapsed" (click)="isCollapsedOne=!isCollapsedOne"
                [attr.aria-expanded]="!isCollapsedOne" aria-controls="collapseExample"></button>
            </div>
          </div>
        </div>
      </div>
      <div id="collapseExample" [ngbCollapse]="!isCollapsedOne">
        <mat-tab-group>
          <mat-tab label="Description">
            <div class="p-3">
              <p [innerHTML]="housingService?.selectedHouse.description"></p>
            </div>
          </mat-tab>
          <mat-tab label="Universities & Colleges">
            <div class="p-3">
              <ul class="px-3">
                <li type="1" *ngFor="let university of housingService?.selectedHouse.universityNearBy">
                  {{university.itemName}},
                  {{university.city}}
                </li>
              </ul>
            </div>
          </mat-tab>
          <mat-tab label="Photos">
            <div class="p-3">
              <ngx-gallery [options]="galleryOptions2" [images]="housingService?.selectedHouse.image"
                class="ngx-gallery">
              </ngx-gallery>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <div *ngIf="housingService?.acceptPlan === false; else confirm">
      <h3>Your housing plan has not been confirmed yet. Our team will contact you to complete the process.
      </h3>
    </div>

    <ng-template #confirm>
      <h3>Your House Plan is Confirm With Number: <span style="color: #80cf00">{{housingService?.confirmNumber}}</span>
      </h3>
    </ng-template>
  </div>
</div>

<div class="card wow animate__animated animate__fadeInUp" *ngIf="housingService?.acceptPlan === false">
  <div class="card-header">Available Houses</div>
  <div class="card-body">

    <div class="text-center" *ngIf="housesList.length === 0; else founded">
      <h4 class="special-font">Sorry, there are currently no available housing plans matching your search.
        <br> Please check again
        later.</h4>
    </div>
    <ng-template #founded>
      <div class="row">
        <div class="col-md-12" *ngIf="housingService?.selectedHouse">
          <h3 class="mb-3">Other Housing Plan</h3>
        </div>

        <div class="booking-design" *ngFor="let house of housesList; let i = index">
          <div class="row no-gutters">

            <div class="col-lg-4 col-md-5">
              <figure>
                <ngx-gallery [options]="galleryOptions" [images]="house.image" class="ngx-gallery">
                </ngx-gallery>
              </figure>
            </div>

            <div class="col-lg-8 pl-5 col-md-7">
              <div class="booking-design_full">
                <div class="booking-design_header">
                  <div class="booking-design_header_left">
                    <h4 class="booking-design_title">{{house.name}}</h4>
                    <span class="location"><i class="ti-location-pin"></i> <span *ngFor="let city of house.city">
                        {{city}}, </span></span>
                  </div>
                  <div class="booking-design_header_right">
                    <h4 class="booking-design_price"><sup>C$</sup> {{house.plans[house.selectPlan].price}}</h4>
                    <span class="booking-time">{{house.unit}}</span>
                  </div>
                </div>
                <div class="booking-design_offers">
                  <ul>
                    <li><strong>Room Type: </strong>
                      {{house.plans[house.selectPlan].roomType}}</li>
                    <li><strong>Room Type: </strong>
                      {{house.plans[house.selectPlan].type}}
                    </li>
                  </ul>
                </div>
                <div class="booking-design_rate">
                  <label for="">Plan</label>
                  <select class="form-control over_all_rate high ml-3" (change)="planChange($event.target.value, i)">
                    <option [value]="plan.roomType" *ngFor="let plan of house.plans;">{{plan.roomType}}
                    </option>
                  </select>
                  <div class="over_all_rate high ml-3">
                    <a (click)="selectHouse(i)" class="inn-room-book">Reserve</a>
                  </div>
                  <button class="over_all_rate high more-eclips collapsed" (click)="isCollapsed=i"></button>
                </div>
              </div>
            </div>

          </div>
          <div [id]="'collapseViewMore-' + i" [ngbCollapse]="i!==isCollapsed">
            <mat-tab-group>
              <mat-tab active="true" label="Description">
                <div class="p-3">
                  <p [innerHTML]="house.description"></p>
                </div>
              </mat-tab>
              <mat-tab label="Universities & Colleges">
                <div class="p-3">
                  <ul class="px-3">
                    <li type="1" *ngFor="let university of house.universityNearBy">{{university.itemName}},
                      {{university.city}}
                    </li>
                  </ul>
                </div>
              </mat-tab>
              <mat-tab label="Photos">
                <div class="p-3">
                  <ngx-gallery [options]="galleryOptions2" [images]="house.image" class="ngx-gallery">
                  </ngx-gallery>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </ng-template>

  </div>
</div>

<ng-template #editModel let-tablename let-ref="dialogRef">
  <div class="card" mat-dialog-content>
    <div class="card-header">Edit Housing Details</div>
    <a (click)="closeDegreeDialog()" class="btn btn-square btn-danger close-btn-second ml-3">Close</a>
    <div class="card-body">
      <form [formGroup]="editHousingForm" (ngSubmit)="submitEditHouse(editHousingForm)" novalidate novalidate>
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label for="">Select University Or College*</label>
              <angular2-multiselect (ngModelChange)="selectUniversity($event)" [data]="allUniversityAndCollege"
                class="form-control angular-selector" formControlName="university" name="university"
                [settings]="universitySettings">
                <c-item>
                  <ng-template let-item="item">
                    <label *ngIf="!item.campus"> <strong>{{item.itemName}}, {{item.city}}</strong></label>
                    <label *ngIf="item.campus"><strong>{{item.itemName}}, {{item.city}}, {{item.campus}}
                        Campus </strong></label>
                  </ng-template>
                </c-item>
              </angular2-multiselect>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Select City*</label>
              <select class="form-control" name="city" formControlName="city">
                <option value="" selected disabled>Select City</option>
                <option [value]="city" *ngFor="let city of canadaCity">{{city}}</option>
              </select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Move-In Year*</label>
              <select class="form-control" placeholder="Move-In Year" type="text" formControlName="startSemesterYear"
                name="startSemesterYear" required>
                <option value="" selected disabled>Move-In Year</option>
                <option *ngFor="let year of years" [value]="year">
                  {{year}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Move-In Semester*</label>
              <select class="form-control" name="semesterType" formControlName="semesterType">
                <option value="" selected disabled>Select Move-In Semester</option>
                <option value="Fall">Fall</option>
                <option value="Winter">Winter</option>
                <option value="Summer">Summer</option>
                <option value="Fall & Winter">Fall & Winter</option>
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="budget">Monthly Budget Range*</label>
              <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
            </div>
          </div>
        </div>

        <div class="text-center mt-2">
          <button [disabled]="editHousingForm.invalid" class="btn btn-square btn-primary">Submit</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
