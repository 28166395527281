<section class="auth-section">
  <div class="container-fluid">
    <div class="row mx-0">
      <div class="col-md-6 side-bg-img"></div>
      <div class="col-md-6 form-box">
        <img class="bg-image-2" src="/assets/images/shapes/bg-circle-large.png" alt="">
        <div class="content-box">
          <div class="header">
            <a routerLink="/" class="logo"><img src="/assets/images/ss-logo-dark.png" alt="Student-Stop Logo"></a>
            <div class="nav-list">
              <a routerLink="/login" class="link active">Log In</a>
              <a routerLink="/register" class="link">Register</a>
            </div>
          </div>
          <div class="login-form">
            <div class="form-div">
              <h2>Log In</h2>
              <p>Sign into your account</p>
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="col-md-12">
                  <div class="form-group">
                    <input class="form-control" placeholder="Your Email" type="email" name="email"
                      formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  </div>
                  <div class="form-group">
                    <input class="form-control" placeholder="Password" type="password" name="password"
                      formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                  </div>
                  <ngb-alert type="danger" *ngIf="errorClosed" (close)="errorClosed=false">{{ error }}</ngb-alert>
                </div>
                <div class="form-group">
                  <div class="row mx-0">
                    <div class="col-md-6">
                      <button type="submit" class="btn generic-btn">
                        <span>Log in</span>
                      </button>
                    </div>
                    <div class="col-md-6 text-right my-auto">
                      <a routerLink="/forgot-password" class="forgot-text my-auto">Forgot Password?</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
