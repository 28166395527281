<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-header">
    <h3 class="card-title">Banking Info</h3>
  </div>
  <div class="card-body">
    <p *ngIf="bankService">1. Do you have bank account in Canada?: <strong>{{bankService.haveBank}}</strong>
    </p>
    <p *ngIf="bankService">2. Do you have CAD 10,000 added into a GIC account in your Canadian bank account?:
      <strong>{{bankService.haveAmt}}</strong></p>
    <p *ngIf="bankService">3. Would you also went Loan?: <strong>{{bankService.loan}}</strong></p>
    <p *ngIf="bankService?.loanAmt">4. Amount: <strong> C${{bankService.loanAmt}}</strong></p>

    <a (click)="openDialog(bankEditDialog)" class="btn btn-primary mt-3">Edit</a>
  </div>
</div>

<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body bg-secondary text-center" *ngIf="this.loanValue">
    <h3>Our team will contact you soon to get all your banking requirements completed.</h3>
  </div>
</div>

<ng-template #bankEditDialog>
  <div class="card">
    <div class="card-header">
      <p class="card-title">Edit Banking Details</p>
    </div>
    <div class="card-body">
      <form #bankForm="ngForm" (ngSubmit)="submitBanking()" novalidate>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Do you have a Canadian bank account?</label>
              <select class="form-control" (blur)="validateStatus(haveBank.value)"
                (change)="validateStatus(haveBank.value)" [class.is-invalid]="statusHasError && haveBank.touched"
                name="haveBank" ngModel #haveBank="ngModel" [(ngModel)]="bankModel.haveBank"
                (change)="changeData($event.target.value)" required>
                <option value="default" selected>Select option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div class="col-md-12 mt-4" *ngIf="isShown">
            <div class="form-group">
              <label for=""><strong>Open Now</strong></label>
              <a href="https://www.icicibank.ca/applynow/default.htm" target="blank"
                class="ml-3 btn btn-square btn-primary">Go to Bank Site</a>
            </div>
          </div>
          <div class="col-md-12 mt-4" *ngIf="bankOpen">
            <div class="form-group">
              <label for="">Do you have CAD 10,000 added into a GIC account in your Canadian bank
                account?</label>
              <select class="form-control" (blur)="validatehaveBalance(haveAmt.value)"
                (change)="validatehaveBalance(haveAmt.value)"
                [class.is-invalid]="haveBalanceHasError && haveAmt.touched" name="haveAmt" ngModel #haveAmt="ngModel"
                [(ngModel)]="bankModel.haveAmt" (change)="changeCadValue($event.target.value)" required>
                <option value="default" selected>Select option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div class="col-md-12 mt-4" *ngIf="cadValue">
            <div class="form-group">
              <label for=""><strong>Open a Student Visa eligible GIC</strong></label>
              <a href="https://www.icicibank.ca/personal/newcomers/overview.page " target="blank"
                class="ml-3 btn btn-square btn-primary">Go to Site</a>
            </div>
          </div>z

          <div class="col-md-12 mt-4">
            <div class="form-group">
              <label for="">Do you need a Loan?</label>
              <select class="form-control" (blur)="validatehaveLoan(loan.value)" (change)="validatehaveLoan(loan.value)"
                [class.is-invalid]="haveLaonError && loan.touched" name="loan" ngModel #loan="ngModel"
                [(ngModel)]="bankModel.loan" (change)="changeLoanValue($event.target.value)">
                <option value="default" selected>Select option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>

          <div class="col-md-12 mt-4" *ngIf="loanValue">
            <div class="form-group">
              <label for="">Enter Amount(In Canadian Dollar)</label>
              <input type="number" class="form-control" placeholder="Enter Amount" name="loanAmt"
                [(ngModel)]="bankModel.loanAmt" #loanAmt="ngModel" required>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <button [disabled]="bankForm.invalid || statusHasError || haveBalanceHasError || haveLaonError"
            (click)="submitBanking()" class="btn btn-square btn-secondary">Submit</button>
          <a (click)="onDialogClose()" class="btn btn-square btn-danger ml-2">Close</a>
        </div>
      </form>
    </div>
  </div>
</ng-template>
