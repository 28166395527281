import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdmissionService {

  private _completeProfile = environment.server;

  constructor(private http: HttpClient) { }

  getAdmission() {
    return this.http.get<any>(this._completeProfile + '/service/admission');
  }

  startAdmission() {
    return this.http.post<any>(this._completeProfile + '/service/admission/start', { id: 'submit' }, { responseType: 'text' as 'json' });
  }

  startAdmissionWithData(data) {
    return this.http.post<any>(this._completeProfile + '/service/admission/start-with-form', data, { responseType: 'text' as 'json' });
  }

  admissionInfo(data, category) {
    return this.http.post<any>(this._completeProfile + '/service/admission/service-info/' + category, data, { responseType: 'text' as 'json' });
  }

  admissionEdit(data, category, index) {
    return this.http.post<any>(this._completeProfile + '/service/admission/service-info/edit-info/' + category + '/' + index, data, { responseType: 'text' as 'json' });
  }

  admissionService(data) {
    return this.http.post<any>(this._completeProfile + '/service/admission/service-submit', data, { responseType: 'text' as 'json' });
  }

  itemDelete(index, category) {
    return this.http.post<any>(this._completeProfile + '/service/admission/service-info/delete/' + index + '/' + category, { true: true });
  }

  admissionDoc() {
    return this.http.post<any>(this._completeProfile + '/service/admission/documents-submit', null, { responseType: 'text' as 'json' });
  }

  paymentDone(data) {
    return this.http.post<any>(this._completeProfile + '/service/admission/payment-done', data, { responseType: 'text' as 'json' });
  }

  allDoneAdmission() {
    return this.http.post<any>(this._completeProfile + '/service/admission/all-done/submit', null);
  }

  createAdmissionInvoice(student_id) {
    return this.http.get<any>(this._completeProfile + '/invoice/create-admission-invoice/' + student_id);
  }
}
