import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  private _completeProfile = environment.server;

  constructor(private _http: HttpClient) { }

  getJobs() {
    return this._http.get(this._completeProfile + '/jobs/get-jobs');
  }

  getAppliedJobs() {
    return this._http.get(this._completeProfile + '/jobs/get-applied-jobs');
  }

  applyJob(data) {
    return this._http.post(this._completeProfile + '/jobs/apply-job', data);
  }
}
