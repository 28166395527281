import { MobileplansService } from './../../../../services/mobileplans.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-plans',
  templateUrl: './mobile-plans.component.html',
  styleUrls: ['./mobile-plans.component.scss']
})
export class MobilePlansComponent implements OnInit {

  mobilePlans = [
    {
      cost: '9',
      validity: '7 Days',
      data: '2.00',
      calling: 'Unlimited Nation-wide Calling',
      msg: 'Unlimited Nation-wide & International Messages'
    },
    {
      cost: '19',
      validity: '7 Days',
      data: '5.00',
      calling: 'Unlimited Nation-wide Calling',
      msg: 'Unlimited Nation-wide & International Messages'
    },
    {
      cost: '35',
      validity: '1 Month',
      data: '7.00',
      calling: 'Unlimited Nation-wide Calling',
      msg: 'Unlimited Nation-wide & International Messages'
    },
    {
      cost: '45',
      validity: '1 Month',
      data: '25.00',
      calling: 'Unlimited Nation-wide Calling',
      msg: 'Unlimited Nation-wide & International Messages'
    },
    {
      cost: '55',
      validity: '1 Month',
      data: '35.00',
      calling: 'Unlimited Nation-wide Calling',
      msg: 'Unlimited Nation-wide & International Messages'
    },
    {
      cost: '65',
      validity: '1 Month',
      data: '45.00',
      calling: 'Unlimited Nation-wide Calling',
      msg: 'Unlimited Nation-wide & International Messages'
    },
    {
      cost: '75',
      validity: '1 Month',
      data: '55.00',
      calling: 'Unlimited Nation-wide Calling',
      msg: 'Unlimited Nation-wide & International Messages'
    }
  ];
  appliedMoiblePlan: any = []

  constructor(
    private _mobilePlanService: MobileplansService
  ) { }

  ngOnInit(): void {
    this.getAppliedMobilePlan();
  }

  selectPlan(index) {
    const data = { selectedPlan: this.mobilePlans[index], planIndex: index + 1 }
    this._mobilePlanService.choosePlan(data).subscribe(res => {
      window.location.reload()
    }, err => {
      console.log(err)
    })
  }

  getAppliedMobilePlan() {
    this._mobilePlanService.getSelectedPlan().subscribe(
      res => {
        this.appliedMoiblePlan = res;
      },
      err => {
        console.log(err)
      }
    )
  }

}
