import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesSubmitService } from '../../../../../services/services-submit.service';
import { AppServicesService } from 'src/app/services/app-services.service';

@Component({
  selector: 'app-banking-questions',
  templateUrl: './banking-questions.component.html',
  styleUrls: ['./banking-questions.component.scss']
})
export class BankingQuestionsComponent implements OnInit {

  bankModel: any = {
    haveBank: null,
    haveAmt: null,
    loan: null,
    loanAmt: null,
    loadCheckbox: false
  }
  isShown = false;
  bankOpen = false;
  cadValue = false;
  loanValue = false;
  statusHasError = true;
  haveBalanceHasError = true;
  haveLaonError = true;
  haveLaonCheckBoxError = true;

  constructor(
    private _router: Router,
    private _services: ServicesSubmitService,
    private _analyse: AppServicesService
  ) { }

  ngOnInit(): void { }

  validateStatus(value) {
    if (value === 'default') {
      this.statusHasError = true;
    } else if (value === 'No') {
      this.statusHasError = true;
    } else {
      this.statusHasError = false;
    }
  }

  validatehaveBalance(value) {
    if (value === 'default') {
      this.haveBalanceHasError = true;
    } else if (value === 'No') {
      this.haveBalanceHasError = true;
    } else {
      this.haveBalanceHasError = false;
    }
  }

  validatehaveLoan(value) {
    if (value === 'default') {
      this.haveLaonError = true;
    } else if (value === 'No') {
      this.haveLaonError = false;
    } else {
      this.haveLaonError = false;
    }
  }

  changeLoanCheckbox(value) {
    if (value === false) {
      this.haveLaonCheckBoxError = true;
    } else if (value === true) {
      this.haveLaonCheckBoxError = false;
    } else {
      this.haveLaonCheckBoxError = false;
    }
  }

  changeData(value: string) {
    switch (value) {
      case "Yes":
        this.isShown = false;
        this.bankOpen = true;
        break;
      case "No":
        this.isShown = true;
        this.bankOpen = false;
        break;
      case "default":
        this.isShown = false;
        this.bankOpen = false;
        break;
      default:
        this.isShown = true;
        this.bankOpen = false;
        break;
    }
  }

  changeCadValue(value: string) {
    switch (value) {
      case "Yes":
        this.cadValue = false;
        break;
      case "No":
        this.cadValue = true;
        break;
      case "default":
        this.cadValue = false;
        break;
      default:
        this.cadValue = true;
        break;
    }
  }

  changeLoanValue(value: string) {
    switch (value) {
      case "Yes":
        this.loanValue = true;
        break;
      case "No":
        this.loanValue = false;
        break;
      case "default":
        this.loanValue = false;
        break;
      default:
        this.loanValue = true;
        break;
    }
  }

  submitBanking() {
    this._services.bankingSubmit(this.bankModel).subscribe((res) => {
      window.location.reload()
    },
      err => {
        console.log(err)
      })
  }

  analyse(type) {
    this._analyse.analyse(type).subscribe(
      res => {
        return
      },
      err => {
        console.log(err)
      }
    )
  }

  clickLoan(type) {
    this._analyse.clickLoan(type).subscribe(
      res => {
        return
      },
      err => {
        console.log(err)
      }
    )
  }
}
