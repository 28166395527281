import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudypermitService {

  private _completeProfile = environment.server;

  constructor(private http: HttpClient) { }

  getImmigration() {
    return this.http.get<any>(this._completeProfile + '/service/studypermit');
  }

  startImmigration() {
    return this.http.post<any>(this._completeProfile + '/service/studypermit/start', null);
  }

  studypermitDoc() {
    return this.http.post<any>(this._completeProfile + '/service/studypermit/documents-submit', null, { responseType: 'text' as 'json' });
  }

  studypermitReupload(document) {
    return this.http.post<any>(this._completeProfile + '/service/studypermit/reupload/document/' + document, document);
  }

  studypermitService(data) {
    return this.http.post<any>(this._completeProfile + '/service/studypermit/service-submit', data);
  }

  studypermitForm(data) {
    return this.http.post<any>(this._completeProfile + '/service/studypermit/fill-form', data);
  }

  studypermitQuestion(data) {
    return this.http.post<any>(this._completeProfile + '/service/studypermit/question-form', data);
  }

  paymentDone(data) {
    return this.http.post<any>(this._completeProfile + '/service/studypermit/payment-done', data);
  }

  createInvoice(student_id) {
    return this.http.get<any>(this._completeProfile + '/invoice/create-invoice/' + student_id);
  }
}
