import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  error;
  success;

  submitted = false;
  errorClosed = false;
  successClosed = false;

  constructor(
    private _auth: AuthService
  ) { }

  ngOnInit() {
    this.forgotForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  get f() { return this.forgotForm.controls; }

  forgotPassword() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return
    } else {
      this._auth.forgotUser(this.forgotForm.value)
        .subscribe(
          res => {
            this.success = res;
            this.successClosed = true
          },
          err => {
            if (err.statusText === "Unknown Error") {
              this.errorClosed = true;
              this.error = 'Something wrong in server. Please try again later.';
              setTimeout(() => {
                this.errorClosed = false;
                this.error = '';
              }, 3000);
            } else {
              this.errorClosed = true;
              this.error = err.error;
              setTimeout(() => {
                this.errorClosed = false;
                this.error = '';
              }, 3000);
            }
          }
        )
    }
  }
}
