import { Component, OnInit } from '@angular/core';
import { ExtraService } from 'src/app/services/extra.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  terms;

  constructor(
    private _extra: ExtraService
  ) { }

  ngOnInit(): void {
    this._extra.getTermsAndConditions().subscribe(
      res => {
        this.terms = res.terms
      },
      err => {
        console.log(err)
      }
    )
  }
}
