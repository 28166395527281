import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StudyPermitImportantInfoComponent } from 'src/app/components/dialogs/study-permit-important-info/study-permit-important-info.component';
import { StudypermitService } from 'src/app/services/studypermit.service';
import { SelectOptionData } from '../../../../../sharedata/selectOptionData';

@Component({
  selector: 'app-study-permit-form-step',
  templateUrl: './study-permit-form-step.component.html',
  styleUrls: ['./study-permit-form-step.component.scss']
})
export class StudyPermitFormStepComponent implements OnInit {

  @Input() profile;
  dialogRef;
  todayDate: Date = new Date();
  immigrationForm: FormGroup;
  showMarriage: Boolean = false;
  showComeBefore: Boolean = false;
  showRejectionReason: Boolean = false;
  showCriminalRecordDetail: Boolean = false;
  showUCI: Boolean = false;
  countryList = [];
  canadaCity = [];

  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _studypermit: StudypermitService,
  ) {
    const serviceData = new SelectOptionData;

    this.countryList = serviceData.country();
    this.canadaCity = serviceData.canadaCity();
  }

  ngOnInit(): void {
    this.immigrationForm = this._formBuilder.group({
      'firstname': new FormControl(this.profile.firstname, [Validators.required]),
      'middlename': new FormControl(this.profile.middlename, []),
      'lastname': new FormControl(this.profile.lastname, [Validators.required]),
      'email': new FormControl({ value: this.profile.email, disabled: true }, [Validators.required]),
      'phone': new FormControl(this.profile.phone, [Validators.required]),
      'dob': new FormControl(this.profile.dob, [Validators.required]),
      'maritalstatus': new FormControl('', [Validators.required]),
      'maritaldate': new FormControl('', [Validators.required]),
      'spousename': new FormControl('', [Validators.required]),
      'spouseage': new FormControl('', [Validators.required]),
      'spouseEducation': new FormControl('', [Validators.required]),
      'spouseAgencyTest': new FormControl('', [Validators.required]),
      'citizenship': new FormControl('', [Validators.required]),
      'residence': new FormControl('', [Validators.required]),
      'residenceStatus': new FormControl('', [Validators.required]),
      'educationYear': new FormControl('', [Validators.required]),
      'lastEntry': new FormControl('', [Validators.required]),
      'dateEntry': new FormControl('', [Validators.required]),
      'placeEntry': new FormControl('', [Validators.required]),
      'occupation': new FormControl('', [Validators.required]),
      'uciOption': new FormControl('', [Validators.required]),
      'uci': new FormControl('', [Validators.required]),
      'agencyTest': new FormControl('', [Validators.required]),
      'studyPermitRejected': new FormControl('', [Validators.required]),
      'criminalRecord': new FormControl('', [Validators.required]),
      'criminalRecordDetail': new FormControl('', [Validators.required]),
      'rejectionReason': new FormControl('', [Validators.required]),
    });

    this.todayDate.setFullYear(this.todayDate.getFullYear() - 13);
  }

  openInfoDialog() {
    this.dialogRef = this.dialog.open(StudyPermitImportantInfoComponent, {
      width: '1200px',
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  onChangesMarital() {
    this.immigrationForm.get('maritalstatus').valueChanges.subscribe(selectedOption => {
      if (selectedOption === 'Married') {
        this.showMarriage = true;
        this.immigrationForm.get('maritaldate').enable();
        this.immigrationForm.get('spousename').enable();
        this.immigrationForm.get('spouseage').enable();
        this.immigrationForm.get('spouseEducation').enable();
        this.immigrationForm.get('spouseAgencyTest').enable();
      }
      else {
        this.showMarriage = false;
        this.immigrationForm.get('maritaldate').disable();
        this.immigrationForm.get('spousename').disable();
        this.immigrationForm.get('spouseage').disable();
        this.immigrationForm.get('spouseEducation').disable();
        this.immigrationForm.get('spouseAgencyTest').disable();
      }
    });
  }

  onChangesComeBefore() {
    this.immigrationForm.get('lastEntry').valueChanges.subscribe(selectedOption => {
      if (selectedOption === 'Yes') {
        this.showComeBefore = true;
        this.immigrationForm.get('dateEntry').enable();
        this.immigrationForm.get('placeEntry').enable();
      }
      else {
        this.showComeBefore = false;
        this.immigrationForm.get('dateEntry').disable();
        this.immigrationForm.get('placeEntry').disable();
      }
    });
  }

  onChangesRejetionReason() {
    this.immigrationForm.get('studyPermitRejected').valueChanges.subscribe(selectedOption => {
      if (selectedOption === 'Yes') {
        this.showRejectionReason = true;
        this.immigrationForm.get('rejectionReason').enable();
      }
      else {
        this.showRejectionReason = false;
        this.immigrationForm.get('rejectionReason').disable();
      }
    });
  }

  onChangesCriminalRecord() {
    this.immigrationForm.get('criminalRecord').valueChanges.subscribe(selectedOption => {
      if (selectedOption === 'Yes') {
        this.showCriminalRecordDetail = true;
        this.immigrationForm.get('criminalRecordDetail').enable();
      }
      else {
        this.showCriminalRecordDetail = false;
        this.immigrationForm.get('criminalRecordDetail').disable();
      }
    });
  }

  onChangesUCI() {
    this.immigrationForm.get('uciOption').valueChanges.subscribe(selectedOption => {
      if (selectedOption === 'Yes') {
        this.showUCI = true;
        this.immigrationForm.get('uci').reset();
        this.immigrationForm.get('uci').enable();
      }
      else {
        this.showUCI = false;
        this.immigrationForm.get('uci').disable();
      }
    });
  }

  submitService() {
    this._studypermit.studypermitForm(this.immigrationForm.value).subscribe((res) => {
      window.location.reload()
    });
  }

}
