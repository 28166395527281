import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DocumentsService } from 'src/app/services/documents.service';
import { ProfileService } from 'src/app/services/profile.service';
import { UploadDocDialogComponent } from '../../dialogs/upload-doc-dialog/upload-doc-dialog.component';
import { StudypermitService } from 'src/app/services/studypermit.service';
import { AdmissionService } from 'src/app/services/admission.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PrimmigrationService } from 'src/app/services/primmigration.service';

@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss']
})
export class DocUploadComponent implements OnInit {

  @Input() docType;

  documentList;
  uploadDocumentsData;
  submitbtnValidation;
  userStudyData;

  dialogRef;
  formError = false;
  checkBoxForm: FormGroup;

  constructor(
    private _profile: ProfileService,
    private _document: DocumentsService,
    public dialog: MatDialog,
    private _router: Router,
    private _studypermit: StudypermitService,
    private _admission: AdmissionService,
    private _primmigration: PrimmigrationService
  ) { }

  ngOnInit(): void {
    this.checkBoxForm = new FormGroup({
      'checkboxinput': new FormControl(false, Validators.requiredTrue)
    });
    this.getDocuments()
  }

  getDocuments() {
    this._profile.getStudentDetails().subscribe((profile) => {
      if (profile.documents) {
        const studentDoc = profile.documents.documents;
        var uploadDocuments = [];

        if (this.docType === "Admission") {
          for (let degreeIndex in profile.userStudyDetails.degree) {
            if (profile.userStudyDetails.degree[degreeIndex].itemName === "Master's") {
              for (let i in studentDoc) {
                for (let categoryIndex in studentDoc[i].category) {
                  if (studentDoc[i].category[categoryIndex] === "Master Degree In Admission") {
                    uploadDocuments.push(studentDoc[i]);
                  }
                }
              }
            }

            if (profile.userStudyDetails.degree[degreeIndex].itemName === "Bachelor's Degree") {
              for (let i in studentDoc) {
                for (let categoryIndex in studentDoc[i].category) {
                  if (studentDoc[i].category[categoryIndex] === "Bachelor Degree In Admission") {
                    uploadDocuments.push(studentDoc[i]);
                  }
                }
              }
            }

            if (
              profile.userStudyDetails.degree[degreeIndex].itemName === "Associate Degree" ||
              profile.userStudyDetails.degree[degreeIndex].itemName === "Diploma" ||
              profile.userStudyDetails.degree[degreeIndex].itemName === "Certificate" ||
              profile.userStudyDetails.degree[degreeIndex].itemName === "Bachelor's/First" ||
              profile.userStudyDetails.degree[degreeIndex].itemName === "Graduate Certificate"
            ) {
              for (let i in studentDoc) {
                for (let categoryIndex in studentDoc[i].category) {
                  if (
                    studentDoc[i].category[categoryIndex] === "Associate Degree In Admission" ||
                    studentDoc[i].category[categoryIndex] === "Diploma In Admission" ||
                    studentDoc[i].category[categoryIndex] === "Certificate In Admission" ||
                    studentDoc[i].category[categoryIndex] === "Bachelor's/First In Admission"
                  ) {
                    uploadDocuments.push(studentDoc[i]);
                  }
                }
              }
            }
          }

          uploadDocuments = uploadDocuments.reduce((unique, o) => {
            if (!unique.some(obj => obj.name === o.name)) {
              unique.push(o);
            }
            return unique;
          }, []);
        } else {
          for (let i in studentDoc) {
            for (let categoryIndex in studentDoc[i].category) {
              if (studentDoc[i].category[categoryIndex] === this.docType) {
                uploadDocuments.push(studentDoc[i]);
              }
            }
          }
        }

        this.uploadDocumentsData = uploadDocuments.sort(this.dynamicSort("name"));
        for (let docIndex in this.uploadDocumentsData) {
          if (this.uploadDocumentsData[docIndex].url !== null) {
            this._document.viewDoc({ name: this.uploadDocumentsData[docIndex].name }).subscribe(
              res => {
                this.uploadDocumentsData[docIndex].url = res['document_url']
              },
              err => {
                console.log(err)
              }
            )
          }
        }

        const validator = uploadDocuments.filter(item => item.url === null);
        const mandatoryCheck = validator.filter(item => item.mandatory === true)
        if (mandatoryCheck.length === 0) {
          this.submitbtnValidation = true;
        }

        this._document.documentList().subscribe((doc: any) => {
          if (doc) {
            var documents = [];
            if (this.docType === "Admission") {
              for (let degreeIndex in profile.userStudyDetails.degree) {
                if (profile.userStudyDetails.degree[degreeIndex].itemName === "Master's") {
                  for (let i in doc) {
                    for (let categoryIndex in doc[i].category) {
                      if (doc[i].category[categoryIndex] === "Master Degree In Admission") {
                        documents.push(doc[i]);
                      }
                    }
                  }
                }

                if (profile.userStudyDetails.degree[degreeIndex].itemName === "Bachelor's Degree") {
                  for (let i in doc) {
                    for (let categoryIndex in doc[i].category) {
                      if (doc[i].category[categoryIndex] === "Bachelor Degree In Admission") {
                        documents.push(doc[i]);
                      }
                    }
                  }
                }

                if (
                  profile.userStudyDetails.degree[degreeIndex].itemName === "Associate Degree" ||
                  profile.userStudyDetails.degree[degreeIndex].itemName === "Diploma" ||
                  profile.userStudyDetails.degree[degreeIndex].itemName === "Certificate" ||
                  profile.userStudyDetails.degree[degreeIndex].itemName === "Bachelor's/First" ||
                  profile.userStudyDetails.degree[degreeIndex].itemName === "Graduate Certificate"
                ) {
                  for (let i in doc) {
                    for (let categoryIndex in doc[i].category) {
                      if (
                        doc[i].category[categoryIndex] === "Associate Degree In Admission" ||
                        doc[i].category[categoryIndex] === "Diploma In Admission" ||
                        doc[i].category[categoryIndex] === "Certificate In Admission" ||
                        doc[i].category[categoryIndex] === "Bachelor's/First In Admission"
                      ) {
                        documents.push(doc[i]);
                      }
                    }
                  }
                }
              }

              this.documentList = documents.sort(this.dynamicSort("name"));
              this.documentList = this.documentList.reduce((unique, o) => {
                if (!unique.some(obj => obj.name === o.name)) {
                  unique.push(o);
                }
                return unique;
              }, []);
            } else {
              for (let i in doc) {
                for (let categoryIndex in doc[i].category) {
                  if (doc[i].category[categoryIndex] === this.docType) {
                    documents.push(doc[i]);
                  }
                }
              }
              this.documentList = documents.sort(this.dynamicSort("name"));
            }
          }
        });

      }
    });
  }

  uploadDocuments(name): void {
    this.dialogRef = this.dialog.open(UploadDocDialogComponent, {
      width: '500px',
      data: { name },
      autoFocus: false,
      hasBackdrop: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      this.getDocuments();
    });
  }

  removeDocuments(value): void {
    this._document.deleteDocument({ name: value }).subscribe((res) => {
      window.location.reload()
    })
  }

  onSubmit() {

    if (this.docType === 'Starting Document') {
      this._document.documentUploadDone().subscribe(
        res => {
          this._router.navigate(['/profile']);
        },
        err => {
          console.log(err)
        }
      );
    }

    if (this.docType === 'Immigration Study Permit') {
      this._studypermit.studypermitDoc().subscribe((res) => {
        window.location.reload()
      });
    }

    if (this.docType === 'Admission') {
      this._admission.admissionDoc().subscribe((res) => {
        window.location.reload()
      });
    }

    if (this.docType === 'Immigration PR') {
      this._primmigration.primmigrationDoc().subscribe((res) => {
        window.location.reload()
      });
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
}
