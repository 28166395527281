import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from "@rxweb/reactive-form-validators"
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  error;
  success;
  token;

  submitted = false;
  errorClosed = false;
  successClosed = false;

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.token = params['token'];
      this._auth.checkToken(this.token).subscribe((res) => {

      },
        err => {
          this.error = err.error;
        });
      this.forgotForm = new FormGroup({
        'password': new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]),
        'confirmPassword': new FormControl('', RxwebValidators.compare({ fieldName: 'password' })),
      })
    });
  }

  get f() { return this.forgotForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return
    } else {
      console.log(this.token)
      const data = { token: this.token, password: this.forgotForm.value.password }
      this._auth.resetPassword(data).subscribe(
        res => {
          this.success = res;
          this.successClosed = true;
        },
        err => {
          if (err.statusText === "Unknown Error") {
            this.errorClosed = true;
            this.error = 'Something wrong in server. Please try again later.';
            setTimeout(() => {
              this.errorClosed = false;
              this.error = '';
            }, 3000);
          } else {
            this.errorClosed = true;
            this.error = JSON.parse(err.error).message;
            setTimeout(() => {
              this.errorClosed = false;
              this.error = '';
            }, 3000);
          }
        }
      )
    }
  }

}
