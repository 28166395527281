import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StudyPermitImportantInfoComponent } from 'src/app/components/dialogs/study-permit-important-info/study-permit-important-info.component';

@Component({
  selector: 'app-study-permit-documents',
  templateUrl: './study-permit-documents.component.html',
  styleUrls: ['./study-permit-documents.component.scss']
})
export class StudyPermitDocumentsComponent implements OnInit {

  dialogRef;

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() { }

  openInfoDialog() {
    this.dialogRef = this.dialog.open(StudyPermitImportantInfoComponent, {
      width: '1200px',
      hasBackdrop: false,
      autoFocus: false,
    });
  }
}
