import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-products-list-main',
  templateUrl: './products-list-main.component.html',
  styleUrls: ['./products-list-main.component.scss']
})
export class ProductsListMainComponent implements OnInit {

  profile;
  requestedProducts: any = [];

  constructor(
    private _profile: ProfileService,
    private _products: ProductsService,
  ) {
    this._profile.getStudentDetails().subscribe((res) => {
      this.profile = res;
      this.getRequestedProducts();
    });
  }

  ngOnInit(): void { }

  getRequestedProducts() {
    this._products.getRequestedProducts(this.profile._id).subscribe(res => {
      this.requestedProducts = res;
    })
  }
}
