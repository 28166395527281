import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { environment } from 'src/environments/environment';
import MenuData from 'src/app/sharedata/menuItems';
import * as $ from 'jquery';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SiteHeaderComponent implements OnInit {

  menu: Array<Object> = [];

  userProfile;
  url;
  loggedin: Boolean = false;
  showProfile: Boolean = true;

  constructor(private _profile: ProfileService) { }

  ngOnInit() {
    this.url = environment.url;
    this.menu = MenuData;
    const token = this._profile.getToken();
    if (token) {
      this._profile.getuser().subscribe(res => {
        this.userProfile = res.student
        if (this.userProfile) {
          this.loggedin = true
          this.showProfile = false
        } else {
          this.loggedin = false
          this.showProfile = true
        }
      },
        err => {
          console.log(err)
        })
    } else {
      this.loggedin = false
      this.showProfile = true
    }
    var current = window.location.href;
    $('.navbar-nav li.nav-item a').each(function () {
      var href;
      if (href === current) {
        $(this).addClass('active');
      }
    });
  }

  logout() {
    this._profile.logout();
    window.location.href = '/'
  }

  toogle() {
    $('#sidebar-menu').toggleClass('open-side');
  }

}
