<div class="dashboard-box">

  <app-navbar></app-navbar>
  <app-sidebar></app-sidebar>

  <div class="dashboard-body">
    <div class="container-fluid">
      <div class="page-header products">
        <div class="row">
          <div class="col-lg-12 main-header">
            <h2>Mobile Plans</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="top-box">
        <div class="card wow animate__animated animate__fadeInUp">
          <div class="card-body gray-bg">

            <div *ngIf="appliedMoiblePlan.length !== 0">
              <h4 class="py-3">Selected Mobile Plan</h4>
              <div class="row mt-3">
                <div class="col-md-4" *ngFor="let plan of appliedMoiblePlan;let i = index">
                  <div class="pricing-single mb-0">
                    <div class="pricing-header">
                      <h3>Plan {{plan.planIndex}}</h3>
                      <p>${{plan.mobile_plan.cost}} CAD</p>
                    </div>

                    <div class="pricing-body">
                      <ul>
                        <li>Validity: {{plan.mobile_plan.validity}}</li>
                        <li>Data: {{plan.mobile_plan.data}} GB</li>
                        <li>{{plan.mobile_plan.calling}}</li>
                        <li>{{plan.mobile_plan.msg}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 text-center mt-4">
                  <h4 *ngIf="!appliedMoiblePlan[0].confirm">Plan confirmation is in progress.</h4>
                  <h4 *ngIf="appliedMoiblePlan[0].confirm">Plan is confirmed.</h4>
                </div>
              </div>
            </div>


            <div *ngIf="appliedMoiblePlan.length === 0">
              <h4 class="text-center pt-3">Wireless Cell Phone Plans (Sim Cards)</h4>
              <h5 class="text-center pt-2">Select your preferred wireless cell phone plan </h5>
              <div class="text-center pt-2 px-5">
                <p>On Student-Stop, we bring to you the best wireless plan offers for students. Get your sim before you
                  fly.</p>
                <p>Select the plan of your choice and get coverage on one of Canada's leading networks. Sim cards will
                  be provided from our partner PhoneBox and will be run on the Rogers network. Please note, wireless
                  devices are not a part of the plan. You must bring your own device.</p>
              </div>
              <div class="row mt-3">
                <div class="col-md-3" *ngFor="let plan of mobilePlans;let i = index">
                  <div class="pricing-single">
                    <div class="pricing-header">
                      <h3>Plan {{i+1}}</h3>
                      <p>${{plan.cost}} CAD</p>
                    </div>

                    <div class="pricing-body">
                      <ul>
                        <li>Validity: {{plan.validity}}</li>
                        <li>Data: {{plan.data}} GB</li>
                        <li>{{plan.calling}}</li>
                        <li>{{plan.msg}}</li>
                      </ul>
                    </div>

                    <div class="pricing-footer">
                      <button (click)="selectPlan(i)" class="btn-choose">Select</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <app-site-footer></app-site-footer>
  </div>
</div>
