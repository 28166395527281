import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  private _completeProfile = environment.server;

  constructor(private _http: HttpClient) { }

  getDocuments() {
    return this._http.get(this._completeProfile + '/documents-upload');
  }

  viewDoc(data) {
    return this._http.post(this._completeProfile + '/documents-upload/view-file', data);
  }

  downloadDoc(url) {
    return this._http.post(this._completeProfile + '/documents-upload/download-file', url);
  }

  documentList() {
    return this._http.get(this._completeProfile + '/documents-list/lists');
  }

  uploadDocument(doc) {
    return this._http.post(this._completeProfile + '/documents-upload/upload-file', doc, { responseType: 'text' as 'json' });
  }

  reuploadDocumentSubmit(document, category) {
    return this._http.post(this._completeProfile + '/documents-upload/reupload/' + category, { document: document });
  }

  documentReuploadDelete(fileName, category) {
    return this._http.post(this._completeProfile + '/documents-upload/reupload/delete/' + fileName + '/' + category, fileName, { responseType: 'text' as 'json' });
  }

  documentReupload(data, category) {
    return this._http.post(this._completeProfile + '/documents-upload/reupload/upload/document/' + category, data);
  }

  deleteDocument(data) {
    return this._http.post(this._completeProfile + '/documents-upload/delete', data, { responseType: 'text' as 'json' });
  }

  documentUploadDone() {
    return this._http.post(this._completeProfile + '/documents-upload/document/submit', { id: 'Done' }, { responseType: 'text' as 'json' });
  }
}
