import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SelectOptionData } from '../../../sharedata/selectOptionData';
import { SelectOptions } from 'src/app/sharedata/selectedOptions';

@Component({
  selector: 'app-admission-stepper',
  templateUrl: './admission-stepper.component.html',
  styleUrls: ['./admission-stepper.component.scss']
})
export class AdmissionStepperComponent implements OnInit {

  @Input() admissionFormParent: FormGroup;
  decided: Number = 0;

  collegesList: Array<Object> = [];
  degreeList: Array<Object> = [];
  universityList: Array<Object> = [];
  programsList: Array<Object> = [];
  interestedList: Array<Object> = [];

  universitySettings;
  collegesSettings;
  degreeSettings;
  programsSettings;
  interestedSettings;

  universityShow: Boolean = false;
  collegesShow: Boolean = false;

  constructor() { }

  ngOnInit(): void {
    const serviceData = new SelectOptionData;
    const selectedOptions = new SelectOptions;

    this.collegesList = serviceData.collegeList();
    this.degreeList = serviceData.degree();
    this.universityList = serviceData.university();
    this.programsList = serviceData.program();
    this.interestedList = serviceData.interested();

    this.universitySettings = selectedOptions.universitySettings();
    this.collegesSettings = selectedOptions.collegesSettings();
    this.degreeSettings = selectedOptions.degreeSettings();
    this.programsSettings = selectedOptions.programsSettings();
    this.interestedSettings = selectedOptions.interestedSettings();
  }

  selectRadio(value) {
    if (value === 'notDecided') {
      this.decided = 2;
      this.admissionFormParent.get('university').clearValidators();
      this.admissionFormParent.get('colleges').clearValidators();;

      this.admissionFormParent.patchValue({
        interested: null,
        university: null,
        colleges: null
      });
      this.admissionFormParent.get('university').updateValueAndValidity();
      this.admissionFormParent.get('colleges').updateValueAndValidity();

    } else {
      this.decided = 1;
      this.admissionFormParent.get('university').setValidators([Validators.required]);
      this.admissionFormParent.get('colleges').setValidators([Validators.required]);

      this.admissionFormParent.patchValue({
        interested: null,
        university: null,
        colleges: null
      });
      this.admissionFormParent.get('university').updateValueAndValidity();
      this.admissionFormParent.get('colleges').updateValueAndValidity();
    }
  }

  onSelectInterested() {
    this.admissionFormParent.get('interested').valueChanges.subscribe(selectedInterested => {
      if (selectedInterested.length !== 0) {
        if (selectedInterested[0].itemName === 'Universities') {

          this.degreeList = [
            { "id": 4, "itemName": "Bachelor's/First", value: "Bachelor's/First (8 semesters / 4 years)" },
            { "id": 5, "itemName": "Master's", value: "Master's (2-4 semesters / 1-2 years)" }
          ];

          this.admissionFormParent.get('degree').reset();

          this.admissionFormParent.get('university').reset();
          this.admissionFormParent.get('university').enable();

          this.admissionFormParent.get('colleges').reset();
          this.admissionFormParent.get('colleges').disable();

          this.universitySettings['disabled'] = false;
          this.universitySettings = Object.assign({}, this.universitySettings);

          this.collegesSettings['disabled'] = true;
          this.collegesSettings = Object.assign({}, this.collegesSettings);

          this.universityShow = true;
          this.collegesShow = false;
        } else if (selectedInterested[0].itemName === 'Colleges') {

          this.degreeList = [
            { "id": 1, "itemName": "Certificate", value: "Certificate (<= 2 semesters / 1 year)" },
            { "id": 2, "itemName": "Diploma", value: "Diploma (at least 4 semesters / 2 years)" },
            { "id": 3, "itemName": "Associate Degree", value: "Associate Degree (4 semesters / 2 years)" },
            { "id": 4, "itemName": "Bachelor's/First", value: "Bachelor's/First (8 semesters / 4 years)" }
          ];

          this.admissionFormParent.get('degree').reset();

          this.admissionFormParent.get('university').reset();
          this.admissionFormParent.get('university').disable();

          this.admissionFormParent.get('colleges').reset();
          this.admissionFormParent.get('colleges').enable();

          this.universitySettings['disabled'] = true;
          this.universitySettings = Object.assign({}, this.universitySettings);

          this.collegesSettings['disabled'] = false;
          this.collegesSettings = Object.assign({}, this.collegesSettings);

          this.universityShow = false;
          this.collegesShow = true;
        } else if (selectedInterested[0].itemName === 'Universities And Colleges') {

          this.degreeList = [
            { "id": 1, "itemName": "Certificate", value: "Certificate (<= 2 semesters / 1 year)" },
            { "id": 2, "itemName": "Diploma", value: "Diploma (at least 4 semesters / 2 years)" },
            { "id": 3, "itemName": "Associate Degree", value: "Associate Degree (4 semesters / 2 years)" },
            { "id": 4, "itemName": "Bachelor's/First", value: "Bachelor's/First (8 semesters / 4 years)" },
            { "id": 5, "itemName": "Master's", value: "Master's (2-4 semesters / 1-2 years)" },
            { "id": 6, "itemName": "Graduate Certificate", value: "Graduate Certificate (2 Semesters / 1 year)" }
          ];

          this.admissionFormParent.get('degree').reset();

          this.admissionFormParent.get('university').reset();
          this.admissionFormParent.get('university').enable();

          this.admissionFormParent.get('colleges').reset();
          this.admissionFormParent.get('colleges').enable();

          this.universitySettings['disabled'] = false;
          this.universitySettings = Object.assign({}, this.universitySettings);

          this.collegesSettings['disabled'] = false;
          this.collegesSettings = Object.assign({}, this.collegesSettings);

          this.universityShow = true;
          this.collegesShow = true;
        }
      } else {

        this.degreeList = [
          { "id": 1, "itemName": "Certificate", value: "Certificate (<= 2 semesters / 1 year)" },
          { "id": 2, "itemName": "Diploma", value: "Diploma (at least 4 semesters / 2 years)" },
          { "id": 3, "itemName": "Associate Degree", value: "Associate Degree (4 semesters / 2 years)" },
          { "id": 4, "itemName": "Bachelor's/First", value: "Bachelor's/First (8 semesters / 4 years)" },
          { "id": 5, "itemName": "Master's", value: "Master's (2-4 semesters / 1-2 years)" },
          { "id": 6, "itemName": "Graduate Certificate", value: "Graduate Certificate (2 Semesters / 1 year)" }
        ];

        this.admissionFormParent.get('degree').reset();

        this.admissionFormParent.get('university').reset();
        this.admissionFormParent.get('university').disable();

        this.admissionFormParent.get('colleges').reset();
        this.admissionFormParent.get('colleges').disable();

        this.universitySettings['disabled'] = true;
        this.universitySettings = Object.assign({}, this.universitySettings);

        this.collegesSettings['disabled'] = true;
        this.collegesSettings = Object.assign({}, this.collegesSettings);

        this.universityShow = false;
        this.collegesShow = false;
      }
    });
  }

  onDecideFunction() {
    console.log('dddd')
    const selectedInterested = this.admissionFormParent.get('interested').value;
    if (selectedInterested.length !== 0) {
      if (selectedInterested[0].itemName === 'Universities') {

        this.degreeList = [
          { "id": 4, "itemName": "Bachelor's/First", value: "Bachelor's/First (8 semesters / 4 years)" },
          { "id": 5, "itemName": "Master's", value: "Master's (2-4 semesters / 1-2 years)" }
        ];

        this.admissionFormParent.get('university').enable();

        this.admissionFormParent.get('colleges').reset();
        this.admissionFormParent.get('colleges').disable();

        this.universitySettings['disabled'] = false;
        this.universitySettings = Object.assign({}, this.universitySettings);

        this.collegesSettings['disabled'] = true;
        this.collegesSettings = Object.assign({}, this.collegesSettings);

        this.universityShow = true;
        this.collegesShow = false;
      } else if (selectedInterested[0].itemName === 'Colleges') {

        this.degreeList = [
          { "id": 1, "itemName": "Certificate", value: "Certificate (<= 2 semesters / 1 year)" },
          { "id": 2, "itemName": "Diploma", value: "Diploma (at least 4 semesters / 2 years)" },
          { "id": 3, "itemName": "Associate Degree", value: "Associate Degree (4 semesters / 2 years)" },
          { "id": 4, "itemName": "Bachelor's/First", value: "Bachelor's/First (8 semesters / 4 years)" }
        ];

        this.admissionFormParent.get('university').reset();
        this.admissionFormParent.get('university').disable();

        this.admissionFormParent.get('colleges').enable();

        this.universitySettings['disabled'] = true;
        this.universitySettings = Object.assign({}, this.universitySettings);

        this.collegesSettings['disabled'] = false;
        this.collegesSettings = Object.assign({}, this.collegesSettings);

        this.universityShow = false;
        this.collegesShow = true;
      } else if (selectedInterested[0].itemName === 'Universities And Colleges') {

        this.degreeList = [
          { "id": 1, "itemName": "Certificate", value: "Certificate (<= 2 semesters / 1 year)" },
          { "id": 2, "itemName": "Diploma", value: "Diploma (at least 4 semesters / 2 years)" },
          { "id": 3, "itemName": "Associate Degree", value: "Associate Degree (4 semesters / 2 years)" },
          { "id": 4, "itemName": "Bachelor's/First", value: "Bachelor's/First (8 semesters / 4 years)" },
          { "id": 5, "itemName": "Master's", value: "Master's (2-4 semesters / 1-2 years)" },
          { "id": 6, "itemName": "Graduate Certificate", value: "Graduate Certificate (2 Semesters / 1 year)" }
        ];

        this.admissionFormParent.get('university').enable();

        this.admissionFormParent.get('colleges').enable();

        this.universitySettings['disabled'] = false;
        this.universitySettings = Object.assign({}, this.universitySettings);

        this.collegesSettings['disabled'] = false;
        this.collegesSettings = Object.assign({}, this.collegesSettings);

        this.universityShow = true;
        this.collegesShow = true;
      }
    } else {

      this.degreeList = [
        { "id": 1, "itemName": "Certificate", value: "Certificate (<= 2 semesters / 1 year)" },
        { "id": 2, "itemName": "Diploma", value: "Diploma (at least 4 semesters / 2 years)" },
        { "id": 3, "itemName": "Associate Degree", value: "Associate Degree (4 semesters / 2 years)" },
        { "id": 4, "itemName": "Bachelor's/First", value: "Bachelor's/First (8 semesters / 4 years)" },
        { "id": 5, "itemName": "Master's", value: "Master's (2-4 semesters / 1-2 years)" },
        { "id": 6, "itemName": "Graduate Certificate", value: "Graduate Certificate (2 Semesters / 1 year)" }
      ];

      this.admissionFormParent.get('university').reset();
      this.admissionFormParent.get('university').disable();

      this.admissionFormParent.get('colleges').reset();
      this.admissionFormParent.get('colleges').disable();

      this.universitySettings['disabled'] = true;
      this.universitySettings = Object.assign({}, this.universitySettings);

      this.collegesSettings['disabled'] = true;
      this.collegesSettings = Object.assign({}, this.collegesSettings);

      this.universityShow = false;
      this.collegesShow = false;
    }
  }
}
