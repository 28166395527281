import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, OnInit, Input } from '@angular/core';
import { ServicesSubmitService } from 'src/app/services/services-submit.service';

@Component({
  selector: 'app-banking-info',
  templateUrl: './banking-info.component.html',
  styleUrls: ['./banking-info.component.scss']
})
export class BankingInfoComponent implements OnInit {

  @Input() bankService;
  dialogRef;

  bankModel: any = {
    haveBank: null,
    haveAmt: null,
    loan: null,
    loanAmt: null
  }
  statusHasError;
  haveBalanceHasError;
  haveLaonError;

  isShown = false;
  bankOpen = false;
  cadValue = false;
  loanValue = false;

  constructor(
    private _services: ServicesSubmitService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.bankModel = {
      haveBank: this.bankService.haveBank,
      haveAmt: this.bankService.haveAmt,
      loan: this.bankService.loan,
      loanAmt: this.bankService.loanAmt
    }
    if (this.bankService.haveBank === "Yes") {
      this.isShown = false;
      this.bankOpen = true;
    } else {
      this.isShown = true;
      this.bankOpen = false;
    }

    if (this.bankService.haveAmt === "Yes") {
      this.cadValue = false;
    } else {
      this.cadValue = true;
    }

    if (this.bankService.loan === "Yes") {
      this.loanValue = true;
    } else {
      this.loanValue = false;
    }
  }

  openDialog(content) {
    this.dialogRef = this.dialog.open(content, {
      width: '1000px',
      hasBackdrop: false,
      autoFocus: false
    });
  }

  validateStatus(value) {
    if (value === 'default') {
      this.statusHasError = true;
    } else if (value === 'No') {
      this.statusHasError = true;
    } else {
      this.statusHasError = false;
    }
  }

  validatehaveBalance(value) {
    if (value === 'default') {
      this.haveBalanceHasError = true;
    } else if (value === 'No') {
      this.haveBalanceHasError = true;
    } else {
      this.haveBalanceHasError = false;
    }
  }

  validatehaveLoan(value) {
    if (value === 'default') {
      this.haveLaonError = true;
    } else if (value === 'No') {
      this.haveLaonError = false;
    } else {
      this.haveLaonError = false;
    }
  }

  changeData(value: string) {
    switch (value) {
      case "Yes":
        this.isShown = false;
        this.bankOpen = true;
        break;
      case "No":
        this.isShown = true;
        this.bankOpen = false;
        break;
      case "default":
        this.isShown = false;
        this.bankOpen = false;
        break;
      default:
        this.isShown = true;
        this.bankOpen = false;
        break;
    }
  }

  changeCadValue(value: string) {
    switch (value) {
      case "Yes":
        this.cadValue = false;
        break;
      case "No":
        this.cadValue = true;
        break;
      case "default":
        this.cadValue = false;
        break;
      default:
        this.cadValue = true;
        break;
    }
  }

  changeLoanValue(value: string) {
    switch (value) {
      case "Yes":
        this.loanValue = true;
        this.bankModel.loanAmt = null;
        break;
      case "No":
        this.loanValue = false;
        this.bankModel.loanAmt = null;
        break;
      case "default":
        this.loanValue = false;
        this.bankModel.loanAmt = null;
        break;
      default:
        this.loanValue = true;
        break;
    }
  }

  submitBanking() {
    this._services.bankingSubmit(this.bankModel).subscribe((res) => {
      window.location.reload()
    },
      err => {
        console.log(err)
      })
  }

  onDialogClose(): void {
    this.dialogRef.close();
  }

}
