<section class="auth-section">
  <div class="container-fluid">
    <div class="row mx-0">
      <div class="col-md-7 form-box">
        <img class="bg-image" src="/assets/images/shapes/bg-circle-large.png" alt="">
        <div class="content-box">
          <div class="header">
            <a routerLink="/" class="logo"><img src="/assets/images/ss-logo-dark.png" alt="Logo"></a>
            <div class="nav-list">
              <a routerLink="/login" class="link">Log In</a>
              <a routerLink="/register" class="link active">Register</a>
            </div>
          </div>
          <div class="login-form">
            <div class="form-div">
              <h2>Register</h2>
              <p>Join now and make your international student journey hassle-free!</p>
              <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                <div class="row mx-0">
                  <div class="col-md-4">
                    <div class="form-group">
                      <input class="form-control" placeholder="First Name*" type="text" name="firstname"
                        formControlName="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input class="form-control" placeholder="Middle Name" type="text" name="middlename"
                        formControlName="middlename" [ngClass]="{ 'is-invalid': submitted && f.middlename.errors }">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input class="form-control" placeholder="Last Name*" type="text" name="lastname"
                        formControlName="lastname" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input class="form-control" placeholder="Your Email" type="email" name="email"
                        formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="10"
                        [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phone"
                        formControlName="phone"
                        [cssClass]="{ 'form-control' : 1, 'is-invalid': submitted && f.phone.errors }">
                      </ngx-intl-tel-input>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input class="form-control" placeholder="Password" type="password" name="password"
                        formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                      <i class="fas fa-question" placement="left" ngbTooltip="Minimum 8 characters long. Contain one special character (@,#,$,%,&,*). The password must have at least 1 Small letter, 1 capital letter & 1
                        number."></i>
                    </div>
                  </div>
                </div>
                <div class="row mx-0">
                  <span class="mt-0 px-2 mb-3 font-14"><strong>Password: </strong> Minimum 8 characters long. Contain
                    one special character (@,#,$,%,&,*). The password must have at least 1 Small letter, 1 capital
                    letter & 1 number.</span>
                </div>
                <ngb-alert type="danger" *ngIf="errorClosed" (close)="errorClosed=false">{{ error }}</ngb-alert>
                <div class="row mx-0">
                  <div class="col-md-12">
                    <re-captcha formControlName="recaptchaReactive" siteKey="6Lc9L0kaAAAAAMt4A-HNKhMr434eWlwEVKBnEgN_">
                    </re-captcha>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <div class="row mx-0">
                    <div class="col-md-6">
                      <button type="submit" class="btn generic-btn">
                        <span>Register</span>
                      </button>
                    </div>
                    <div class="col-md-6 text-right my-auto">
                      <a routerLink="/login" class="forgot-text my-auto">Are have an account? Sign In</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 side-bg-img register-img">
      </div>
    </div>
  </div>
</section>
