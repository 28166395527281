<div class="card">
  <div class="card-header">
    Buy Product
    <button class="close" aria-label="Close" (click)="onDialogClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <form [formGroup]="buyProdForm" (ngSubmit)="buyProdSubmit(buyProdForm)">
      <div class="row">
        <div class="col-md-12">
          <img class="float-left w-30 d-inline-block" [src]="prod.image" alt="">
          <h3>{{prod.name}}</h3>
          <p class=" current-price">${{prod.price}}</p>
          <div [innerHTML]="safeDescription"></div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="mt-2" for="middle-name">Quantity</label>
            <input class="form-control" type="number" name="quantity" placeholder="Enter Quantity"
              formControlName="quantity">
          </div>
          <div class="form-group">
            <label class="mt-2" for="middle-name">Delivery Address</label>
            <textarea class="form-control" type="number" name="address" placeholder="Enter Delivery Address"
              formControlName="address"></textarea>
            <span class="mt-2 red">*Enter Only Canada Address</span>
          </div>
          <div *ngIf="error" class="alert alert-danger dark alert-dismissible fade show" role="alert">
            <p>{{error}}</p>
            <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                aria-hidden="true">×</span></button>
          </div>
          <div *ngIf="success" class="alert alert-success dark alert-dismissible fade show" role="alert">
            <p>{{success}}</p>
            <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                aria-hidden="true">×</span></button>
          </div>
          <div>
            <div class="text-center mt-3">
              <button type="submit" [disabled]="buyProdForm.invalid"
                class="btn btn-square btn-primary mr-2">Submit</button>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>
