<header class="header-area">
  <div class="header-navbar">
    <div class="navbar-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" routerLink="/">
                <img src="/assets/images/ss-logo.png" alt="Student-Stop Logo">
              </a>
              <div class="collapse navbar-collapse">
                <ul class="navbar-nav m-auto">
                  <li *ngFor="let item of menu" class="nav-item">
                    <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                      [routerLink]="item.href">{{item.label}}</a>
                    <ul *ngIf="item.children" class="sub-menu">
                      <li *ngFor="let submenu of item.children">
                        <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                          [routerLink]="submenu.href">{{submenu.label}}</a>
                        <ul *ngIf="submenu.children">
                          <li *ngFor="let submenu2 of submenu.children">
                            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                              [routerLink]="submenu2.href">{{submenu2.label}}</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="navbar-auth">
                <div *ngIf="!showProfile" class="side-profile">
                  <div id="dropdown">
                    <a routerLink="/profile">
                      <img [src]="url + 'uploads/profilePic/' + userProfile.profilePic" alt="Profile Pic"> <span
                        class="name">{{userProfile.firstname}} {{userProfile?.middlename}}
                        {{userProfile.lastname}}</span>
                    </a>
                  </div>
                </div>
                <ul *ngIf="!loggedin">
                  <li><a class="login" routerLink="/login">Login</a></li>
                  <li><a routerLink="/register">Register</a></li>
                </ul>
              </div>
              <div class="sidemenu-toggle">
                <a href="javascript:void(0)" (click)="toogle()">
                  <i class="fas fa-bars"></i>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div id="sidebar-menu" class="sidenav">
  <a href="javascript:void(0)" class="closebtn" (click)="toogle()">&times;</a>
  <a *ngFor="let item of menu" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
    [routerLink]="item.href">{{item.label}}</a>
  <a *ngIf="!loggedin" routerLink="/login">Login</a>
  <a *ngIf="!loggedin" routerLink="/register">Register</a>
</div>
