<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeIn">Contact Us</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">Contact Us</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="contact-us-section">
    <div class="container">
      <div class="sec-title text-center">
        <div class="title wow animate__animated animate__fadeInUp">Contact</div>
        <div class="separator center"><span></span></div>
        <h2 class="wow animate__animated animate__fadeInUp">Feel Free To Contact Us</h2>
      </div>
      <div class="row">
        <div class="col-md-12 wow animate__animated animate__fadeInRight">
          <div class="from-wraper">
            <form class="contact-form" [formGroup]="contactForm" (ngSubmit)="contactFormSubmit()" F novalidate>
              <div class="row">
                <div class="col-md-6">
                  <input type="text" placeholder="Name*" class="form-control mt-0" name="name" formControlName="name"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Name is a required.</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <input type="email" placeholder="Email*" class="form-control mt-0" name="email"
                    formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is a required.</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <input type="text" placeholder="Phone Number*" class="form-control" name="phone"
                    formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                  <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                    <div *ngIf="f.phone.errors.required">Phone Number is a required.</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <input type="text" placeholder="Subject*" class="form-control" name="subject"
                    formControlName="subject" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
                  <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                    <div *ngIf="f.subject.errors.required">Subject is a required.</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <textarea placeholder="How can we help you?" class="form-control" cols="30" rows="10" name="message"
                    formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }">></textarea>
                  <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                    <div *ngIf="f.message.errors.required">Message is a required.</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <button type="submit" class="submit-btn">Submit</button>
                  <div *ngIf="msgSuccess" class="alert alert-success dark alert-dismissible fade show" role="alert">
                    <p>{{msgSuccess}}</p>
                    <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-12 mt-4">
          <h2>Student-Stop Location</h2>
        </div>

        <div class="col-md-12 mt-2 wow animate__animated animate__fadeInLeft">
          <div class="row">
            <div class="col-md-4 mt-3">
              <div class="contact-info-wraper">
                <div class="list-content">
                  <img src="/assets/images/mclennan-location.jpg" alt="">
                  <a class="d-inline-block mt-3">Suite 331, 60 St. George Street,
                    McLennan Physics Laboratories,
                    University of Toronto,
                    Toronto, ON, M5S 1A7,
                    Canada</a>
                </div>
              </div>
            </div>
            <div class="col-md-4 mt-3">
              <div class="contact-info-wraper">
                <div class="list-content">
                  <img src="/assets/images/mississaug-location.jpg" alt="">
                  <a class="d-inline-block mt-3">3359 Mississauga Rd,
                    ICUBE, University of Toronto (Mississauga Campus),
                    Mississauga, ON L5L 1C6, Canada</a>
                </div>
              </div>
            </div>
            <div class="col-md-4 mt-3">
              <div class="contact-info-wraper">
                <div class="list-content">
                  <img src="/assets/images/gurugram-location.jpg" alt="">
                  <a class="d-inline-block mt-3"
                    href="https://www.google.com/maps?ll=28.446812,77.131472&z=12&t=m&hl=en-US&gl=US&mapclient=embed&q=JMD+Pacific+Square+Chander+Nagar,+Sector+15+Gurugram,+Haryana+122007"
                    target="_blank">7th Floor, Left Wing, JMD Pacific Square, Gurgaon, Haryana, 122001, India</a>
                </div>
              </div>
            </div>
            <div class="col-md-4 mt-3">
              <div class="contact-info-wraper">
                <div class="list-content">
                  <img src="/assets/images/dubai-location.jpeg" alt="">
                  <a class="d-inline-block mt-3"
                    href="https://www.google.com/maps/place/ONE+JLT+-+One+Business+Centre+DMCC+-+Serviced+Offices/@25.0725041,55.1358637,15z/data=!4m12!1m6!3m5!1s0x0:0x3de30a81416326c4!2sONE+JLT+-+One+Business+Centre+DMCC+-+Serviced+Offices!8m2!3d25.0725041!4d55.1446184!3m4!1s0x0:0x3de30a81416326c4!8m2!3d25.0725041!4d55.1446184"
                    target="_blank">OneJLT-05-89, One JLT, Jumeirah Lake Towers, Dubai, United Arab Emirates</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="map">
    <iframe src="https://maps.google.com/maps?q=JMD%20Pacific%20Square&t=&z=13&ie=UTF8&iwloc=&output=embed" height="500"
      frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
  </div>

  <app-site-footer></app-site-footer>

</main>
