import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticlesService } from 'src/app/services/articles.service';

@Component({
  selector: 'app-all-articles',
  templateUrl: './all-articles.component.html',
  styleUrls: ['./all-articles.component.scss']
})
export class AllArticlesComponent implements OnInit {

  articles;

  constructor(
    private _articles: ArticlesService
  ) { }

  ngOnInit(): void {
    this.getArticles()
  }

  getArticles() {
    this._articles.getArticles().subscribe(res => {
      this.articles = this.sortByDate(res);
    })
  }

  textReplacer(text) {
    var str = text.replace(/\s+/g, '-');
    return str.toLowerCase();
  }

  sortByDate(arr) {
    arr.sort(function (a, b) {
      return Number(new Date(b.publishon)) - Number(new Date(a.publishon));
    });

    return arr;
  }

}
