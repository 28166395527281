<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeIn">Channel Partner</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">Channel Partner</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-inner-top-section">
    <div class="container">
      <div class="row align-items-center">

        <div class="col-lg-5 col-md-12">
          <div class="why-choose-image wow animate__animated animate__fadeInUp">
            <img src="/assets/images/consultant.jpg" alt="image">
          </div>
        </div>

        <div class="col-lg-7 col-md-12">
          <div class="service-inner-content">
            <h2 class="wow animate__animated animate__fadeInUp">Consultants</h2>
            <div class="sec-title">
              <div class="separator"><span></span></div>
            </div>
            <div class="features-text wow animate__animated animate__fadeInUp">
              <p>Do you work/interact with students that want to study in Canada? If yes, help them succeed in their
                international journey, by referring them to Student-Stop. Student-Stop simplifies their complete
                journey, by bringing all the services at their fingertips. Approved consultants get a referral fee, for
                every student they refer, that successfully attends a partner university/college, through Student-Stop
                admission.</p>
            </div>

            <a href="https://channelpartner.student-stop.com"
              class="btn btn-primary wow animate__animated animate__fadeInUp animate__delay-1s">Register now!</a>
            <p class="mt-2 wow animate__animated animate__fadeInUp animate__delay-1s">* Terms And Conditions Apply</p>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="advisor-section">
    <div class="container">
      <div class="row ai">
        <div class="col-lg-6 col-md-6">
          <div class="advisor-content service-inner-content">
            <h2 class="wow animate__animated animate__fadeInUp">Tuition Centres</h2>
            <div class="sec-title">
              <div class="separator"><span></span></div>
            </div>
            <p class="features-text wow animate__animated animate__fadeInUp">Take the next step and give your students
              the opportunity to study in Canada through Student-Stop. Refer your students to Student-Stop and help them
              achieve their career goals. Being instrumental in this process, approved Tuition centres also earn a fee
              for every student who they refer, that successfully attends a partner university/college, as a result of
              their admission on Student-Stop. </p>

            <a href="https://channelpartner.student-stop.com"
              class="btn btn-primary wow animate__animated animate__fadeInUp animate__delay-1s">Register now!</a>
            <p class="mt-2 wow animate__animated animate__fadeInUp animate__delay-1s">* Terms And Conditions Apply</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="advisor-image">
            <div class="item-img">
              <img class="wow animate__animated animate__fadeInUp" src="/assets/images/tution-iilustration.jpg"
                alt="partner">
              <div class="shape-one">
                <img src="/assets/images/dots.png" alt="figure" width="100" height="100">
              </div>
              <div class="shape-two">
                <img src="/assets/images/dots.png" alt="figure" width="121" height="104">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="service-inner-top-section">
    <div class="container">
      <div class="row align-items-center">

        <div class="col-lg-5 col-md-12">
          <div class="why-choose-image wow animate__animated animate__fadeInUp">
            <img src="/assets/images/high-school-second.jpg" alt="image">
          </div>
        </div>

        <div class="col-lg-7 col-md-12">
          <div class="service-inner-content">
            <h2 class="wow animate__animated animate__fadeInUp">High Schools</h2>
            <div class="sec-title">
              <div class="separator"><span></span></div>
            </div>
            <div class="features-text wow animate__animated animate__fadeInUp">
              <p>Are you a high school with students interested to study in Canada? Offer your students a platform, that
                simplifies this complete process for them through Student-Stop. Interested schools can send us an email
                at
                <a href="mailto:contact@student-stop.com">contact@student-stop.com</a> to learn more.</p>
              <p class="mt-2 wow animate__animated animate__fadeInUp animate__delay-1s">* Terms And Conditions Apply</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <app-site-footer></app-site-footer>
</main>
