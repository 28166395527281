<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeInUp">Medical Insurance</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li><a href="/">Services</a></li>
              <li class="active">Medical Insurance</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-detail">
    <div class="container">
      <h2 class="wow animate__animated animate__fadeInUp white-text">Medical Insurance Support</h2><br>
      <p class="wow animate__animated animate__fadeInUp white-text"> 
        It is both mandatory and crucial to have medical coverage for any 
        unforeseen medical issues, that may arise during the course of your studies. 
        Medical costs can be a big shock unless you are adequately insured. 
        Finding the perfect medical insurance will 
        give you and your family the peace of mind, god-forbid should you fall sick when aborad. 
        <strong>Student-Stop</strong> with its insurance partner, 
        is here to support you in getting the best insurance, that covers you throughout your education. </p> <br>
        <p class="white-text">
          <strong>Note:</strong> All universities and colleges in Canada require students to have health insurance coverage.
        </p>
    </div>
  </section>

  <section class="service-inner-top-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="service-inner-content">
            <h2 class="wow animate__animated animate__fadeInUp">Protect youself financially.</h2>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Reliable service</h4>
              <p>Our carefully selected insurance partners provide the most reliable service to settle any claims.</p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Competitive Pricing</h4>
              <p>Get the best insurance at the most competitive rate.</p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i>Great Coverage</h4>
              <p>Coverage for a wide range of medical conditions.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="why-choose-image wow animate__animated animate__fadeInUp">
            <img
              src="/assets/images/doctor.jpg"
              alt="image">
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-site-footer></app-site-footer>

</main>
