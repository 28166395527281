<main class="bg-body-color">

  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeIn">Email Confirmation</h1>
          <div class="page-nav">

          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="graphic-div">
    <img src="/assets/images/confirm.png " alt="">
  </div>

  <div class="mail-box">

    <div class="card">
      <div class="card-body">
        <div *ngIf="success === 1" class="text-center">
          <h2 class="title">Thank you for confirming your email address.</h2>
          <p>Your email address has been successfully confirmed.</p>
          <a *ngIf="studentType === 0" class="btn btn-primary" href="/journey-begin">Continue Your Journey</a>
          <a *ngIf="studentType === 1" class="btn btn-primary"
            href="https://channelpartner.student-stop.com/registration-form">Please Continue</a>
          <a *ngIf="studentType === 2" class="btn btn-primary" href="https://housing.student-stop.com/dashboard">Please
            Continue</a>
          <a *ngIf="studentType === 3" class="btn btn-primary" href="https://food.student-stop.com/dashboard">Please
            Continue</a>
        </div>
        <div *ngIf="success === 0" class="text-center">
          <h2 class="title">Invalid link.</h2>
          <p>Your link is invalid please check it again.</p>
        </div>
      </div>
    </div>
  </div>


  <app-site-footer></app-site-footer>

</main>
