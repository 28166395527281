import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { RegistrationDialogComponent } from 'src/app/components/dialogs/registration-dialog/registration-dialog.component';
import { ProgramsListUniversity } from '../../../../sharedata/universityProgramList';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-recommended-form',
  templateUrl: './recommended-form.component.html',
  styleUrls: ['./recommended-form.component.scss']
})
export class RecommendedFormComponent implements OnInit {

  recommendedForm: FormGroup;

  minValue: number = 30000;
  maxValue: number = 80000;
  options: Options = {
    floor: 1000,
    ceil: 120000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min Price:</b> C$ ' + value;
        case LabelType.High:
          return '<b>Max Price:</b> C$ ' + value;
        default:
          return 'C$ ' + value;
      }
    }
  };

  dialogRef;

  radioSelect;

  programsList = [];
  programsListUniversity;
  bachelorList;
  masterList;
  boardList = [
    'CBSE (10th)',
    'CBSE (12th)',
    'ICSE',
    'ISC',
    'IB Diploma (Predicted/Actual)',
    'Bachelor\'s/First',
    'Master\'s'
  ];
  outofList = [
    {
      number: 100,
      board: 'CBSE (10th)'
    },
    {
      number: 100,
      board: 'CBSE (12th)'
    },
    {
      number: 100,
      board: 'ICSE'
    },
    {
      number: 100,
      board: 'ISC'
    },
    {
      number: 45,
      board: 'IB Diploma (Predicted/Actual)'
    },
    {
      number: 4,
      board: 'Bachelor\'s/First'
    },
    {
      number: 5,
      board: 'Bachelor\'s/First'
    },
    {
      number: 10,
      board: 'Bachelor\'s/First'
    },
    {
      number: 4,
      board: 'Master\'s'
    },
    {
      number: 5,
      board: 'Master\'s'
    },
    {
      number: 10,
      board: 'Master\'s'
    }
  ];
  selectedPursue = 0;

  programSettings = {
    singleSelection: false,
    text: "Select Program of Interest",
    selectAllText: null,
    unSelectAllText: null,
    itemsShowLimit: 15,
    enableCheckAll: false,
    enableFilterSelectAll: false,
    limitSelection: 15,
    badgeShowLimit: 15,
    enableSearchFilter: true,
    lazyLoading: true
  };

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private cookieService: CookieService
  ) {
    const programListUniveristy = new ProgramsListUniversity;
    this.bachelorList = programListUniveristy.bachelorListUniversity();
    this.masterList = programListUniveristy.masterListUniversity();
  }

  ngOnInit(): void {
    this.recommendedForm = this._formBuilder.group({
      'pursueto': new FormControl(null, [Validators.required]),
      'programsInterest': new FormControl(null, [Validators.required]),
      'programkey': new FormControl(null, [Validators.required]),
      'board': new FormControl(null),
      'boards': this._formBuilder.array([]),
    });
  }

  selectPursue(value) {
    if (value === "Bachelor's/First") {
      this.programsList = this.bachelorList;
      this.recommendedForm.patchValue({
        programsInterest: null
      });
    }

    if (value === "Master's") {
      this.selectedPursue = 2;
      this.programsList = this.masterList;
      this.recommendedForm.patchValue({
        programsInterest: null
      });
    }
  }

  selectRadio(value) {
    this.radioSelect = value;

    if (value === "keyword") {
      this.recommendedForm.get('programkey').setValidators([Validators.required]);
      this.recommendedForm.get('programsInterest').clearValidators();
      this.recommendedForm.patchValue({
        programsInterest: null
      });
      this.recommendedForm.get('programsInterest').updateValueAndValidity();
      this.recommendedForm.get('programkey').updateValueAndValidity();
    }

    if (value === "degreeDropdown") {
      this.recommendedForm.get('programsInterest').setValidators([Validators.required]);
      this.recommendedForm.get('programkey').clearValidators();
      this.recommendedForm.patchValue({
        programkey: null
      });
      this.recommendedForm.get('programsInterest').updateValueAndValidity();
      this.recommendedForm.get('programkey').updateValueAndValidity();
    }
  }

  onSelectBoard() {
    const value = this.recommendedForm.value.board;
    var existValue = false;
    this.boards().controls.map(item => {
      if (item['controls'].name.value === value) {
        existValue = true
      }
    });

    if (existValue === false) {

      if (value === 'CBSE (10th)') {
        this.boards().push(
          this._formBuilder.group({
            name: ['CBSE (10th)', Validators.required],
            total: [null, Validators.required],
            outof: [100, Validators.required],
          })
        );
      }
      if (value === 'CBSE (12th)') {
        this.boards().push(
          this._formBuilder.group({
            name: ['CBSE (12th)', Validators.required],
            total: [null, Validators.required],
            outof: [100, Validators.required],
          })
        );
      }
      if (value === 'ICSE') {
        this.boards().push(
          this._formBuilder.group({
            name: ['ICSE', Validators.required],
            total: [null, Validators.required],
            outof: [100, Validators.required],
          })
        );
      }
      if (value === 'ISC') {
        this.boards().push(
          this._formBuilder.group({
            name: ['ISC', Validators.required],
            total: [null, Validators.required],
            outof: [100, Validators.required],
          })
        );
      }

      if (value === 'IB Diploma (Predicted/Actual)') {
        this.boards().push(
          this._formBuilder.group({
            name: ['IB Diploma (Predicted/Actual)', Validators.required],
            total: [null, Validators.required],
            outof: [45, Validators.required],
          })
        );
      }

      if (value === 'Bachelor\'s/First') {
        this.boards().push(
          this._formBuilder.group({
            name: ['Bachelor\'s/First', Validators.required],
            total: [null, Validators.required],
            outof: [4, Validators.required],
          })
        );
      }

      if (value === 'Master\'s') {
        this.boards().push(
          this._formBuilder.group({
            name: ['Master\'s', Validators.required],
            total: [null, Validators.required],
            outof: [4, Validators.required],
          })
        );
      }
    }
  }

  boards(): FormArray {
    return this.recommendedForm.get('boards') as FormArray
  }

  createItem(): FormGroup {
    return this._formBuilder.group({
      name: ['', Validators.required],
      total: [null, Validators.required],
      notOf: [null, Validators.required],
    });
  }

  addBoard(): void {
    this.boards().push(this.createItem());
  }

  removeBoard(index): void {
    this.boards().removeAt(index);
  }

  submitForm(form) {
    const sendData = {
      pursueto: form.value.pursueto,
      programsInterest: form.value.programsInterest,
      programkey: form.value.programkey,
      boards: form.value.boards,
      budgetRange: {
        minimum: this.minValue,
        maximum: this.maxValue
      }
    }

    for (let board of sendData.boards) {
      board.outof = parseInt(board.outof)
    }

    const tokenCheck = this.cookieService.get('token')

    if (tokenCheck) {
      localStorage.setItem('searchProgram', JSON.stringify(sendData));
      this.recommendedForm.reset();
      this.radioSelect = null;
      window.location.href = "/student-university-match-list";
    } else {
      this.dialogRef = this.dialog.open(RegistrationDialogComponent, {
        width: '1000px',
        autoFocus: false,
        hasBackdrop: false,
        disableClose: true,
      });

      this.dialogRef.afterClosed().subscribe(result => {
        if (result === 'submit') {
          localStorage.setItem('searchProgram', JSON.stringify(sendData));
          this.recommendedForm.reset();
          this.radioSelect = null;
          window.location.href = "/student-university-match-list";
        }
      });
    }
  }

  getOutof(inputStr: string) {
    return this.outofList.filter(items => items.board === inputStr);
  }

}
