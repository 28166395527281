import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppServicesService } from '../../../services/app-services.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery';
import { ArticlesService } from 'src/app/services/articles.service';
declare const ScrollMagic;
declare const TweenLite;
declare const TimelineLite;
declare const Power1;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  contactForm: FormGroup
  submitted: Boolean = false;
  msgSuccess;
  articles: any = [];
  showMoreArticle: Boolean = false;

  customOptions: OwlOptions = {
    loop: true,
    margin: 30,
    nav: true,
    smartSpeed: 500,
    autoHeight: false,
    autoplay: true,
    dots: false,
    autoplayTimeout: 10000,
    navText: [
      '<i class="fa fa-long-arrow-left" aria-hidden="true"></i>',
      '<i class="fa fa-long-arrow-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      800: {
        items: 1,
      },
      1024: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  }

  constructor(
    private _services: AppServicesService,
    private fb: FormBuilder,
    private _articles: ArticlesService
  ) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      'name': ['', [Validators.required]],
      'email': ['', [Validators.required, Validators.email]],
      'phone': ['', [Validators.required]],
      'subject': ['', [Validators.required]],
      'message': ['', [Validators.required]],
    });

    this.generateStars();
    this.airplan();
    this.getArticles();
  }

  getArticles() {
    this._articles.getArticles().subscribe(res => {
      this.articles = this.sortByDate(res);
      if (this.articles.length > 3) {
        this.articles = this.articles.slice(0, 3);
        this.showMoreArticle = true;
      }
    })
  }

  contactFormSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    } else {
      this._services.contactForm(this.contactForm.value).subscribe((res) => {
        this.msgSuccess = res;
        setTimeout(() => {
          this.msgSuccess = '';
        }, 3000);
      });
    }
  }

  get f() {
    return this.contactForm.controls;
  }

  // Random Stars
  generateStars() {
    var $galaxy = $(".banner");
    var iterator = 0;

    while (iterator <= 50) {
      var xposition = Math.random();
      var yposition = Math.random();
      var star_type = Math.floor((Math.random() * 3) + 1);
      var position = {
        "x": ($galaxy.width() / 1) * xposition,
        "y": $galaxy.height() * yposition,
      };

      $('<div class="star star-type' + star_type + '"></div>').appendTo($galaxy).css({
        "top": position.y,
        "left": position.x
      });

      iterator++;
    }
  };

  airplan() {
    const flightPath = {
      curviness: 1.25,
      autoRotate: true,
      values: [{
        x: 0,
        y: -10
      },
      {
        x: 800,
        y: 180
      }
      ]
    };

    const flightPathSecond = {
      curviness: 1.25,
      autoRotate: true,
      values: [{
        x: 0,
        y: 20
      },
      {
        x: -800,
        y: 400
      }
      ]
    };

    const tween = new TimelineLite();

    tween.add(
      TweenLite.to(".moving-airplane", 1, {
        bezier: flightPath,
        ease: Power1.easeInOut
      })
    );

    const tweenSecond = new TimelineLite();

    tweenSecond.add(
      TweenLite.to(".moving-airplane-2", 2, {
        bezier: flightPathSecond,
        ease: Power1.easeInOut
      })
    );

    const controller = new ScrollMagic.Controller();
    const controllerSecond = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: '.link-images',
      duration: 1200
    })
      .setTween(tween)
      .addTo(controller);

    const sceneSecond = new ScrollMagic.Scene({
      triggerElement: '.link-images',
      duration: 2600
    })
      .setTween(tweenSecond)
      .addTo(controllerSecond);
  }

  textReplacer(text) {
    var str = text.replace(/\s+/g, '-');
    return str.toLowerCase();
  }

  sortByDate(arr) {
    arr.sort(function (a, b) {
      return Number(new Date(b.publishon)) - Number(new Date(a.publishon));
    });

    return arr;
  }

}
