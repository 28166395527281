import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-view-doc-dialog',
  templateUrl: './view-doc-dialog.component.html',
  styleUrls: ['./view-doc-dialog.component.scss']
})
export class ViewDocDialogComponent implements OnInit {

  doc;

  constructor(
    public dialogRef: MatDialogRef<ViewDocDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.doc = this.data;
  }

  closeDialog() {
    this.dialogRef.close()
  }
}
