<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeIn">Feedback</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">Feedback</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="contact-us-section">
    <div class="container">
      <div class="sec-title text-center">
        <div class="title wow animate__animated animate__fadeInUp">Feedback</div>
        <div class="separator center"><span></span></div>
        <h2 class="wow animate__animated animate__fadeInUp">Feel Free To Give Your Suggestions</h2>
      </div>
      <div class="row">
        <div class="col-md-12 wow animate__animated animate__fadeInRight">
          <div class="from-wraper">
            <form class="contact-form" [formGroup]="feedbackForm" (ngSubmit)="feedbackFormSubmit()" F novalidate>
              <div class="row">
                <div class="col-md-6">
                  <input type="text" placeholder="Name*" class="form-control mt-0" name="name" formControlName="name"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Name is a required.</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <input type="email" placeholder="Email*" class="form-control mt-0" name="email"
                    formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is a required.</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <input type="text" placeholder="Phone Number*" class="form-control" name="phone"
                    formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                  <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                    <div *ngIf="f.phone.errors.required">Phone Number is a required.</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <input type="text" placeholder="Topic*" class="form-control" name="topic"
                    formControlName="topic" [ngClass]="{ 'is-invalid': submitted && f.topic.errors }">
                  <div *ngIf="submitted && f.topic.errors" class="invalid-feedback">
                    <div *ngIf="f.topic.errors.required">Topic is a required.</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <textarea placeholder="Tell Your Feedback & Suggestions*" class="form-control" cols="30" rows="10" name="message"
                    formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }">></textarea>
                  <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                    <div *ngIf="f.message.errors.required">Message is a required.</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <button type="submit" class="submit-btn">Submit</button>
                  <div *ngIf="msgSuccess" class="alert alert-success dark alert-dismissible fade show" role="alert">
                    <p>{{msgSuccess}}</p>
                    <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-site-footer></app-site-footer>

</main>
