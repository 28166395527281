<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeIn">FAQ's</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">FAQ's</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="faqs">
    <div class="container">
      <div class="sec-title text-center">
        <h2 class="text-dark wow animate__animated animate__fadeInUp">FAQ's</h2>
        <div class="separator center"><span></span></div>
      </div>

      <div class="faq-wrapper">
        <ngb-accordion #a="ngbAccordion">
          <ngb-panel class="card-header">
            <ng-template ngbPanelHeader>
              <h5 class="mb-0">
                <button class="btn-link collapsed" ngbPanelToggle>
                  1. Why should I use <strong>Student-Stop</strong>? <span>
                    <i class="fas fa-plus-circle"></i>
                    <i class="fas fa-minus-circle"></i> </span>
                </button>
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              We have streamlined the complete process from admission till your arrival and stay in Canada.
              Therefore, you can trust us as being your single point of contact. We take care of all your
              headaches and make your transition smooth and comfortable.
            </ng-template>
          </ngb-panel>

          <ngb-panel class="card-header">
            <ng-template ngbPanelHeader>
              <h5 class="mb-0">
                <button class="btn-link collapsed" ngbPanelToggle>
                  2. Does <strong>Student-Stop</strong> guarentee my admission? <span>
                    <i class="fas fa-plus-circle"></i>
                    <i class="fas fa-minus-circle"></i> </span>
                </button>
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              We guarentee admission in our partner colleges and universities for students that meet the
              elligibility criteria. For non-partner universities, we will assist you in your application process
              through our experts.
            </ng-template>
          </ngb-panel>

          <ngb-panel class="card-header">
            <ng-template ngbPanelHeader>
              <h5 class="mb-0">
                <button class="btn-link collapsed" ngbPanelToggle>
                  3. What services does <strong>Student-Stop</strong> provide? <span>
                    <i class="fas fa-plus-circle"></i>
                    <i class="fas fa-minus-circle"></i> </span>
                </button>
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              We provide a variety of services from admission, banking, legal, financial, travel, accomodation,
              food and a lot more, which varies from city to city.
            </ng-template>
          </ngb-panel>

          <ngb-panel class="card-header">
            <ng-template ngbPanelHeader>
              <h5 class="mb-0">
                <button class="btn-link collapsed" ngbPanelToggle>
                  4. Why should I trust <strong>Student-Stop</strong> over agents? <span>
                    <i class="fas fa-plus-circle"></i>
                    <i class="fas fa-minus-circle"></i> </span>
                </button>
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              Many agents have been known to provide students with false information and defraud them for
              financial gain of their own. We are a reputed company that has been founded by alumni of top
              universities in Canada.
              Our team has succesfully gone through the complete student journey and the same is being offered to all
              of you leveraging our vast experience.
              We have offices in both India and Canada and are partnered with large organizations. You can
              trust us to meet all your needs as a one stop shop.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>


  <app-site-footer></app-site-footer>
</main>
