export class SelectOptions {
    universitySettings() {
        const universitySettings = {
            singleSelection: false,
            text: "Select University",
            selectAllText: null,
            unSelectAllText: null,
            itemsShowLimit: 5,
            enableCheckAll: false,
            enableFilterSelectAll: false,
            limitSelection: 5,
            badgeShowLimit: 5,
            enableSearchFilter: true,
            lazyLoading: true
        };
        return universitySettings;
    }

    collegesSettings() {
        const collegesSettings = {
            singleSelection: false,
            text: "Select Colleges",
            selectAllText: null,
            unSelectAllText: null,
            itemsShowLimit: 5,
            enableCheckAll: false,
            enableFilterSelectAll: false,
            limitSelection: 5,
            badgeShowLimit: 5,
            enableSearchFilter: true,
            lazyLoading: true
        };
        return collegesSettings;
    }

    degreeSettings() {
        const degreeSettings = {
            singleSelection: false,
            text: "Select Degree",
            selectAllText: null,
            unSelectAllText: null,
            itemsShowLimit: 2,
            enableCheckAll: false,
            enableFilterSelectAll: false,
            limitSelection: 2,
            badgeShowLimit: 2,
            enableSearchFilter: true
        };
        return degreeSettings;
    }

    programsSettings() {
        const programsSettings = {
            singleSelection: false,
            text: "Select Programs",
            selectAllText: null,
            unSelectAllText: null,
            itemsShowLimit: 3,
            enableCheckAll: false,
            enableFilterSelectAll: false,
            limitSelection: 3,
            badgeShowLimit: 3,
            enableSearchFilter: true,
            lazyLoading: true
        };
        return programsSettings;
    }

    interestedSettings() {
        const interestedSettings = {
            singleSelection: true,
            text: "Select Option",
            selectAllText: null,
            unSelectAllText: null,
            enableCheckAll: false,
            enableFilterSelectAll: false,
            enableSearchFilter: false
        };
        return interestedSettings;
    }

    countrySettings() {
        const countrySettings = {
            singleSelection: true,
            text: "Select Country",
            selectAllText: null,
            unSelectAllText: null,
            enableCheckAll: false,
            enableFilterSelectAll: false,
            enableSearchFilter: true
        };

        return countrySettings;
    }

    studyTypeSettings() {
        const studyTypeSettings = {
            singleSelection: true,
            text: "Select Study Type",
            selectAllText: null,
            unSelectAllText: null,
            enableCheckAll: false,
            enableFilterSelectAll: false,
            enableSearchFilter: true
        };
        return studyTypeSettings;
    }
}