import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from 'src/app/services/profile.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SelectOptionData } from '../../../sharedata/selectOptionData';

@Component({
  selector: 'app-edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss']
})
export class EditProfileDialogComponent implements OnInit {

  personalform: FormGroup;
  profile;

  countryList: Array<Object> = [];
  stateList: Array<Object> = [];
  todayDate = new Date();

  constructor(
    private _profile: ProfileService,
    public dialogRef: MatDialogRef<EditProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder
  ) {
    const serviceData = new SelectOptionData;
    this.countryList = serviceData.country();
    this.stateList = serviceData.state();
  }

  ngOnInit(): void {
    this.profile = this.data.profile;

    this.personalform = this._formBuilder.group({
      '_id': new FormControl(this.profile._id),
      'firstname': new FormControl(this.profile.firstname, [Validators.required]),
      'middlename': new FormControl(this.profile.middlename, []),
      'lastname': new FormControl(this.profile.lastname, [Validators.required]),
      'email': new FormControl(this.profile.email, [Validators.required]),
      'phone': new FormControl(this.profile.phone, [Validators.required]),
      'dob': new FormControl(this.profile.dob, [Validators.required]),
      'gender': new FormControl(this.profile.gender, [Validators.required]),
      'address': new FormControl(this.profile.address, [Validators.required]),
      'country': new FormControl(this.profile.country, [Validators.required]),
      'state': new FormControl(this.profile.state, [Validators.required]),
      'postalCode': new FormControl(this.profile.postalCode, [Validators.required]),
    });
  }

  onChanges() {
    this.personalform.get('country').valueChanges.subscribe(selectedCountry => {
      if (selectedCountry != 'India') {
        this.personalform.get('state').reset();
        this.personalform.get('state').disable();
      }
      else {
        this.personalform.get('state').enable();
      }
    });
  }

  updateProfile() {
    this._profile.updateProfile(this.personalform.value)
      .subscribe(
        res => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
        err => {
          console.log(err)
        }
      )
  }

  onDialogClose(): void {
    this.dialogRef.close();
  }
}
