<form [formGroup]="admissionForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="">Are you interested in*</label>
        <angular2-multiselect (click)="onSelectInterested()" [data]="interestedList"
          class="form-control angular-selector" formControlName="interested" name="interested"
          [settings]="interestedSettings">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="">Select Degree Type*</label>
        <angular2-multiselect [data]="degreeList" class="form-control angular-selector" formControlName="degree"
          name="degree" [settings]="degreeSettings">
          <c-item>
            <ng-template let-item="item">
              <label>{{item.value}}</label>
            </ng-template>
          </c-item>
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="postal-code">Enter Current School/Institution/University*</label>
        <input class="form-control" type="text" name="currentStudy"
          placeholder="Enter Current School/Institution/University" formControlName="currentStudy">
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="">Select Program* (Maximum 3)</label>
        <angular2-multiselect [data]="programsList" class="form-control angular-selector" formControlName="programs"
          name="programs" [settings]="programsSettings">
        </angular2-multiselect>
      </div>
    </div>
    <hr *ngIf="universityShow || collegesShow">
    <div class="col-md-12" *ngIf="universityShow">
      <div class="form-group">
        <label for="">Select Universities in order of preference* (Maximum 5)</label>
        <angular2-multiselect [data]="universityList" class="form-control angular-selector" formControlName="university"
          name="university" [settings]="universitySettings">
          <c-item>
            <ng-template let-item="item">
              <label><strong>Name: </strong> {{item.itemName}} |</label>
              <label><strong>DLI: </strong> {{item.dli}} |</label>
              <label *ngIf="item.campuse"><strong>Campuse: </strong> {{item.campuse}} |</label>
              <label><strong>City: </strong> {{item.city}} |</label>
              <label><strong>Offers PGWP Eligible: </strong> {{item.offersPGWPEligible}} |</label>
              <label><strong>Province: </strong> {{item.province}}</label>
            </ng-template>
          </c-item>
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-12" *ngIf="collegesShow">
      <div class="form-group">
        <label for="">Select Colleges* (Maximum 5)</label>
        <angular2-multiselect [data]="collegesList" class="form-control angular-selector" formControlName="colleges"
          name="colleges" [settings]="collegesSettings">
          <c-item>
            <ng-template let-item="item">
              <label><strong>Name: </strong> {{item.itemName}} |</label>
              <label><strong>DLI: </strong> {{item.dli}} |</label>
              <label *ngIf="item.campuse"><strong>Campuse: </strong> {{item.campuse}} |</label>
              <label><strong>City: </strong> {{item.city}} |</label>
              <label><strong>Offers PGWP Eligible: </strong> {{item.offersPGWPEligible}} |</label>
              <label><strong>Province: </strong> {{item.province}}</label>
            </ng-template>
          </c-item>
        </angular2-multiselect>
      </div>
    </div>
  </div>
  <div class="text-center mt-2">
    <button type="submit" class="btn btn-square btn-primary mr-2">Submit</button>
    <div (click)="editStudyPopup()" class="btn btn-square btn-danger">Cancel</div>
  </div>
</form>
