<div class="card" mat-dialog-content>
  <div class="card-header">Secondary and Post Secondary Institutes
    <button class="close" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <form [formGroup]="editFormData" (ngSubmit)="editInstitute(editFormData)" novalidate class="form-group box-form"
      novalidate>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Institute Type*</label>
            <angular2-multiselect class="form-control" [data]="studyTypeList" [settings]="studyTypeSettings"
              formControlName="studyType" name="studyType">
            </angular2-multiselect>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Country*</label>
            <angular2-multiselect class="form-control" [data]="countryList" [settings]="countrySettings"
              formControlName="country" name="country">
            </angular2-multiselect>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Date Attended From*</label>
            <input class="form-control digits" type="text" matInput [matDatepicker]="picker" name="attendedFrom"
              placeholder="Date Attended From" (dateChange)="depart()" formControlName="attendedFrom" [max]="todayDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Date Attended To*</label>
            <input class="form-control digits" type="text" matInput [matDatepicker]="pickersecond" name="attendedTo"
              placeholder="Date Attended To" formControlName="attendedTo" [max]="todayDate" [min]="toDate">
            <mat-datepicker-toggle matSuffix [for]="pickersecond"></mat-datepicker-toggle>
            <mat-datepicker #pickersecond></mat-datepicker>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Name Of Institute/College/School*</label>
            <input class="form-control" placeholder="Enter Name Of Institution/College/School"
              formControlName="nameOfInstitute" name="nameOfInstitute" required>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Degree/Certificate/Diploma Name* (e.g IB Diploma, ICSE, CBSE, ICE, IGCSE, IB Certificates)</label>
            <input class="form-control" placeholder="Enter Degree/Certificate/Diploma*" formControlName="degreeName"
              name="degreeName" required>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Date Graduated/Expected to Graduate*</label>
            <input class="form-control digits" type="text" matInput [matDatepicker]="picker3" name="indicateDate"
              placeholder="Enter Date Graduated/Expected to Graduate" formControlName="indicateDate" [min]="toDate">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </div>
        </div>
        <div class="col-md-6">
          <div class="submit-btn text-right">
            <br>
            <button type="submit" [disabled]="editFormData.invalid" class="btn btn-square btn-primary">Update</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
