<div class="dashboard-box">
  <app-navbar></app-navbar>
  <app-sidebar></app-sidebar>

  <div class="dashboard-body">
    <div class="container-fluid">
      <div class="page-header travel">
        <div class="row">
          <div class="col-lg-12 main-header">
            <h2>Travel</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="top-box">
        <app-travel-form-submit *ngIf="step === 0"></app-travel-form-submit>
        <app-travel-info [travelService]="travelService" *ngIf="step === 1"></app-travel-info>
      </div>
    </div>
    <app-site-footer></app-site-footer>
  </div>
</div>
