<footer class="footer">
  <div class="container">
    <div class="content-box wow animate__animated animate__fadeInUp">
      <h2>ADDRESS</h2>
      <div class="row">
        <div class="col-md-6">
          <p><strong>Canada</strong></p>
          <p>Suite 331, 60 St. George Street,
            McLennan Physics Laboratories,
            University of Toronto,
            Toronto, ON, M5S 1A7,
            Canada</p>
          <p>(By Appointment Only)</p>
          <p>3359 Mississauga Rd,
            ICUBE, University of Toronto (Mississauga Campus),
            Mississauga, ON L5L 1C6,
            Canada</p>
          <p>(By Appointment Only)</p>
        </div>
        <div class="col-md-6">
          <p><strong>Canada</strong></p>
          <p class="mt-2">4801 Steeles Ave. W, Suite #17, Toronto, <br>ON - M9L 2W1, Canada</p>
          <p>(By Appointment Only)</p>
          <p class="mt-2"><strong>United Arab Emirates</strong></p>
          <p>OneJLT-05-89, One JLT, Jumeirah Lake Towers, Dubai, United Arab Emirates</p>
          <p>(By Appointment Only)</p>
        </div>
      </div>
    </div>

    <div class="content-box wow animate__animated animate__fadeInUp">
      <div class="text-left">
        <h2 class="d-hide">GET IN TOUCH</h2>
        <p class="mt-2"><strong>India</strong></p>
        <p>7th Floor, Left Wing, JMD Pacific Square, Sec-15 II, Gurgaon - 122001 India.</p>
        <p>(By Appointment Only)</p>
      </div>
      <p>
        <a href="mailto:contact@student-stop.com">contact@student-stop.com</a>
      </p>
      <div class="social-buttons">
        <a target="blank" href="https://www.facebook.com/therealstudentstop/"
          class="sbtn social-button social-button-facebook" aria-label="Facebook">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a target="blank" href="https://twitter.com/realstudentstop" class="sbtn social-button social-button-twitter"
          aria-label="Twitter">
          <i class="fab fa-twitter"></i>
        </a>
        <a target="blank" href="https://www.instagram.com/studentstopglobal/"
          class="sbtn social-button social-button-instagram" aria-label="Instagram">
          <i class="fab fa-instagram"></i>
        </a>
      </div>
      <a class="venture-text mt-4" target="black" href="https://grintex.com/">A <span>Grintex</span> Venture</a>
    </div>

    <!-- <div class="content-box wow animate__animated animate__fadeInUp">
      <h2>IMPORTANT LINKS</h2>
      <ul class="link">
        <li><a routerLink="/about-us">About Us</a></li>
        <li><a routerLink="/services">Services</a></li>
        <li><a routerLink="/faqs">FAQ's</a></li>
        <li><a routerLink="/contact-us">Contact</a></li>
      </ul>
    </div> -->
  </div>
</footer>
<div class="sub-footer">
  <div class="container wow animate__animated animate__fadeInUp">
    <span>2021 - 2023 © <strong>Student-Stop</strong>. All Rights Reserved.</span>
    <ul>
      <li><a routerLink="/faqs">FAQ's</a></li>
      <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
      <li><a routerLink="/terms-and-conditions">Term And Condition</a></li>
    </ul>
  </div>
</div>
