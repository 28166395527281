<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <form [formGroup]="housingForm" class="mt-5" novalidate>
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <label for="">Select University Or College*</label>
            <angular2-multiselect (ngModelChange)="selectUniversity($event)" [data]="allUniversityAndCollege"
              class="form-control angular-selector" formControlName="university" name="university"
              [settings]="universitySettings">
              <c-item>
                <ng-template let-item="item">
                  <label *ngIf="!item.campus"> <strong>{{item.itemName}}, {{item.city}}</strong></label>
                  <label *ngIf="item.campus"><strong>{{item.itemName}}, {{item.city}}, {{item.campus}}
                      Campus </strong></label>
                </ng-template>
              </c-item>
            </angular2-multiselect>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Select City*</label>
            <select class="form-control" name="city" formControlName="city">
              <option value="" selected disabled>Select City</option>
              <option [value]="city" *ngFor="let city of canadaCity">{{city}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group">
            <label>Move-In Year*</label>
            <select class="form-control" placeholder="Move-In Year" type="text" formControlName="startSemesterYear"
              name="startSemesterYear" required>
              <option value="" selected disabled>Move-In Year</option>
              <option *ngFor="let year of years" [value]="year">
                {{year}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Move-In Semester*</label>
            <select class="form-control" name="semesterType" formControlName="semesterType">
              <option value="" selected disabled>Select Move-In Semester</option>
              <option value="Fall">Fall</option>
              <option value="Winter">Winter</option>
              <option value="Summer">Summer</option>
              <option value="Fall & Winter">Fall & Winter</option>
            </select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="budget">Monthly Budget Range*</label>
            <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
          </div>
        </div>
      </div>

      <div class="text-center mt-3">
        <button [disabled]="housingForm.invalid" (click)="submitService()"
          class="btn btn-square btn-primary">Submit</button>
      </div>
    </form>
  </div>
</div>
