import { Component, OnInit } from '@angular/core';
import { AppServicesService } from '../../../services/app-services.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-scholarship-apply',
  templateUrl: './scholarship-apply.component.html',
  styleUrls: ['./scholarship-apply.component.scss']
})
export class ScholarshipApplyComponent implements OnInit {

  webinarForm: FormGroup
  submitted;
  msgSuccess;
  registered = false;

  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;

  constructor(
    private _services: AppServicesService,
    private fb: FormBuilder,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.countDown();
    this.webinarForm = this.fb.group({
      'name': ['', [Validators.required]],
      'email': ['', [Validators.required, Validators.email]],
      'phone': ['', [Validators.required]]
    });
    const getCookie = this.cookieService.get('webiner-register')
    if (getCookie) {
      this.registered = true;
    }
  }

  webinarFormSubmit() {
    this.submitted = true;
    if (this.webinarForm.invalid) {
      return;
    } else {
      this._services.registerWebinar(this.webinarForm.value).subscribe((res) => {
        this.registered = true;
        const expDate = new Date();
        expDate.setDate(16)
        this.cookieService.set('webiner-register', 'a55dfbdb0e816966fccfcf9ed84d4fcaae20dab8801fb9bf', { expires: expDate });
      });
    }
  }

  get f() {
    return this.webinarForm.controls;
  }

  countDown() {
    const countDownDate = new Date("July 15, 2022 20:30:00").getTime();

    var x = setInterval(() => {
      var now = new Date().getTime();
      var distance = countDownDate - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(x);
      }
    }, 1000);
  }

}
