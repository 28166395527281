<div class="notification-bar">
  <div class="row text-center">
    <div class="col-md-9">
      <h2>Join us on the live webinar on the 22nd June 2022 at 6.30 pm IST and learn more about the platform.</h2>
    </div>
    <div class="col-md-3">
      <a class="btn btn-secondary"href="/webinar-registration">Register Now</a>
    </div>
  </div>
</div>
