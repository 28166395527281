import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { AuthService } from 'src/app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-registration-dialog',
  templateUrl: './registration-dialog.component.html',
  styleUrls: ['./registration-dialog.component.scss']
})
export class RegistrationDialogComponent implements OnInit {

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  signupForm: FormGroup;
  loginForm: FormGroup;

  error: string = '';

  submitted: Boolean = false;
  errorClosed: Boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _auth: AuthService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.signupForm = new FormGroup({
      'firstname': new FormControl(null, Validators.required),
      'middlename': new FormControl(null),
      'lastname': new FormControl(null, Validators.required),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'phone': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]),
      'recaptchaReactive': new FormControl(null, Validators.required),
    });

    this.loginForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.required])
    })
  }

  closeDialog() {
    this.dialogRef.close('cross')
  }

  onSubmit() {
    this.submitted = true;
    if (this.signupForm.invalid) {
      return
    } else {
      this._auth.registerUser(this.signupForm.value).subscribe(
        res => {
          if (res.token) {
            this.cookieService.set('token', res.token, { expires: new Date(new Date().setDate(new Date().getDate() + 7)) });
            this.dialogRef.close('submit')
          }
        },
        err => {
          if (err.statusText === "Unknown Error") {
            this.errorClosed = true;
            this.error = 'Something wrong in server. Please try again later.';
            setTimeout(() => {
              this.errorClosed = false;
              this.error = '';
            }, 3000);
          } else {
            this.errorClosed = true;
            this.error = err.error;
            setTimeout(() => {
              this.errorClosed = false;
              this.error = '';
            }, 3000);
          }
        }
      );
    }
  }

  submitLogin() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return
    } else {
      this._auth.loginUser(this.loginForm.value)
        .subscribe(
          res => {
            if (res.token) {
              this.cookieService.set('token', res.token, { expires: new Date(new Date().setDate(new Date().getDate() + 7)) });
              this.dialogRef.close('submit')
            }
          },
          err => {
            if (err.statusText === "Unknown Error") {
              this.errorClosed = true;
              this.error = 'Something wrong in server. Please try again later.';
              setTimeout(() => {
                this.errorClosed = false;
                this.error = '';
              }, 3000);
            } else {
              this.errorClosed = true;
              this.error = err.error;
              setTimeout(() => {
                this.errorClosed = false;
                this.error = '';
              }, 3000);
            }
          }
        );
    }
  }

  get f() { return this.signupForm.controls; }

  get g() { return this.loginForm.controls; }

  selectedIndex: number = 0;

  nextStep() {
    if (this.selectedIndex != 2) {
      this.selectedIndex = this.selectedIndex + 1;
    }
    console.log(this.selectedIndex);
  }

  previousStep() {
    if (this.selectedIndex != 0) {
      this.selectedIndex = this.selectedIndex - 1;
    }
    console.log(this.selectedIndex);
  }

}
