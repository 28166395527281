import { Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { TrackingService } from './services/tracking.service';
import * as publicIp from 'public-ip';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
declare const WOW;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  latitude;
  longitude;
  routerUrl;
  showBottomHeader = true;

  constructor(
    private _tracking: TrackingService,
    private cookieService: CookieService,
  ) { }

  ngOnInit() {
    if (!this.cookieService.get('cookie-accept')) {
      $('.overlay').addClass('show');
      $('.wrapper').addClass('show');
    }
    new WOW().init();
    this.getPosition();

    if (this.cookieService.get('token')) {
      this.showBottomHeader = false;
    }
  }

  accepted() {
    $('.overlay').removeClass('show');
    $('.wrapper').removeClass('show');
    this.cookieService.set('cookie-accept', 'accepted', 365);
  }

  getPosition() {
    navigator.geolocation.getCurrentPosition(async (position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      const ipAddress = await publicIp.v4()
      const data = {
        latitude: this.latitude,
        longitude: this.longitude,
        ipAddress
      }
      this._tracking.savePosition(data).subscribe(res => { })
    }, function () { }, { timeout: 10000 })
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    const triggerAt: number = 5;
    if (this.cookieService.get('token')) {
      this.showBottomHeader = false;
    } else {
      if (document.body.scrollHeight - (window.innerHeight + window.scrollY) <= triggerAt) {
        this.showBottomHeader = false;
      } else {
        this.showBottomHeader = true;
      }
    }
  }
}
