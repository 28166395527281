import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ExtraService } from 'src/app/services/extra.service';

@Component({
  selector: 'app-recommended-university-list',
  templateUrl: './recommended-university-list.component.html',
  styleUrls: ['./recommended-university-list.component.scss']
})
export class RecommendedUniversityListComponent implements OnInit {

  dream: any = [];
  reach: any = [];
  safety: any = [];
  dreamSearch: any = [];
  reachSearch: any = [];
  safetySearch: any = [];

  dreamNumber = 0;
  reachNumber = 0;
  safeNumber = 0;

  searchSummary;

  constructor(
    private _extra: ExtraService
  ) { }

  JSON_Lower_keys(J) {
    var ret = {};
    for (let [key, value] of Object.entries(JSON.parse(J))) {
      ret[key.toLowerCase().split(' ').join('_').replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, '_')] = value;
    }
    return ret;
  }

  ngOnInit(): void {
    const searchData = JSON.parse(localStorage.getItem('searchProgram'));
    var dreams = [];
    var reaches = [];
    var safeties = [];

    this.searchSummary = searchData;

    if (searchData) {
      if (searchData.programkey) {
        this._extra.studentUniversityMatchByKey(searchData).subscribe(res => {
          for (let dreamU of res.dream) {
            dreams.push(this.JSON_Lower_keys(JSON.stringify(dreamU)))
          }

          for (let reachU of res.reach) {
            reaches.push(this.JSON_Lower_keys(JSON.stringify(reachU)))
          }

          for (let safetyU of res.safety) {
            safeties.push(this.JSON_Lower_keys(JSON.stringify(safetyU)))
          }

          const dreamGroup = this.groupBy(dreams, dream => dream.university);
          var dreamArray = []

          for (let dd of dreams) {
            dreamArray.push(dreamGroup.get(dd.university))
          }

          let dreamSet = new Set(dreamArray);
          var finalDream = dreamSet;

          const reachesGroup = this.groupBy(reaches, reach => reach.university);
          var reachesArray = []

          for (let dd of reaches) {
            reachesArray.push(reachesGroup.get(dd.university))
          }

          let reachedSet = new Set(reachesArray);
          var finalReach = reachedSet;

          const safeGroup = this.groupBy(safeties, safe => safe.university);
          var safeArray = []

          for (let dd of safeties) {
            safeArray.push(safeGroup.get(dd.university))
          }

          let safeSet = new Set(safeArray);
          var finalSafe = safeSet;

          for (let d of finalDream) {
            this.dreamNumber = this.dreamNumber + d.length;
          }

          for (let r of finalReach) {
            this.reachNumber = this.reachNumber + r.length;
          }

          for (let s of finalSafe) {
            this.safeNumber = this.safeNumber + s.length;
          }

          this.dream = finalDream;
          this.reach = finalReach;
          this.safety = finalSafe;
          this.dreamSearch = finalDream;
          this.reachSearch = finalReach;
          this.safetySearch = finalSafe;
          localStorage.removeItem('searchProgram')
        })
      } else {
        this._extra.studentUniversityMatch(searchData).subscribe(res => {
          for (let dreamU of res.dream) {
            dreams.push(this.JSON_Lower_keys(JSON.stringify(dreamU)))
          }

          for (let reachU of res.reach) {
            reaches.push(this.JSON_Lower_keys(JSON.stringify(reachU)))
          }

          for (let safetyU of res.safety) {
            safeties.push(this.JSON_Lower_keys(JSON.stringify(safetyU)))
          }

          const dreamGroup = this.groupBy(dreams, dream => dream.university);
          var dreamArray = []

          for (let dd of dreams) {
            dreamArray.push(dreamGroup.get(dd.university))
          }

          let dreamSet = new Set(dreamArray);
          var finalDream = dreamSet;

          const reachesGroup = this.groupBy(reaches, reach => reach.university);
          var reachesArray = []

          for (let dd of reaches) {
            reachesArray.push(reachesGroup.get(dd.university))
          }

          let reachedSet = new Set(reachesArray);
          var finalReach = reachedSet;

          const safeGroup = this.groupBy(safeties, safe => safe.university);
          var safeArray = []

          for (let dd of safeties) {
            safeArray.push(safeGroup.get(dd.university))
          }

          let safeSet = new Set(safeArray);
          var finalSafe = safeSet;

          for (let d of finalDream) {
            this.dreamNumber = this.dreamNumber + d.length;
          }

          for (let r of finalReach) {
            this.reachNumber = this.reachNumber + r.length;
          }

          for (let s of finalSafe) {
            this.safeNumber = this.safeNumber + s.length;
          }

          this.dream = finalDream;
          this.reach = finalReach;
          this.safety = finalSafe;
          this.dreamSearch = finalDream;
          this.reachSearch = finalReach;
          this.safetySearch = finalSafe;
          localStorage.removeItem('searchProgram')
        })
      }

    } else {
      window.location.href = "student-university-match"
    }
  }

  searchMethod(type, value) {
    if (type === "dream") {
      if (value) {
        const data = this.searchKey(this.dreamSearch, value);
        this.dream = data;
      } else {
        this.dream = this.dreamSearch;
      }
    }

    if (type === "reach") {
      if (value) {
        const data = this.searchKey(this.reachSearch, value);
        this.reach = data;
      } else {
        this.reach = this.reachSearch;
      }
    }

    if (type === "safety") {
      if (value) {
        const data = this.searchKey(this.safetySearch, value);
        this.safety = data;
      } else {
        this.safety = this.safetySearch;
      }
    }
  }


  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  searchKey(arr, value) {
    return arr.filter((obj) => JSON.stringify(obj).toLowerCase().includes(value.toLowerCase()))
  }

}
