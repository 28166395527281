<div class="card">
  <div class="card-header">Document Viewer
    <button class="close" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <iframe *ngIf="doc.doc_mimetype !== 'image/jpeg' && doc.doc_mimetype !== 'image/jpg'" class="viewBox"
      [src]="doc.document_url | safe: 'resourceUrl'" width="100%" height="500px"></iframe>
    <img *ngIf="doc.doc_mimetype === 'image/jpeg' || doc.doc_mimetype === 'image/jpg'"
      [src]=" doc.document_url | safe: 'resourceUrl'" width="100%">
  </div>
</div>
