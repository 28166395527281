import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TravelService {

  private _completeProfile = environment.server;

  constructor(private http: HttpClient) { }

  getTravel() {
    return this.http.get<any>(this._completeProfile + '/service/travel');
  }

  travel(data) {
    return this.http.post<any>(this._completeProfile + '/service/travel', data);
  }

  travelUpdate(data) {
    return this.http.post<any>(this._completeProfile + '/service/travel/update', data, { responseType: 'text' as 'json' });
  }

  insurance(data) {
    return this.http.post<any>(this._completeProfile + '/service/insurance', data);
  }
}
