<div class="row" [formGroup]="personalformParent">
  <div class="col-md-4">
    <div class="form-group">
      <label for="firstname">First Name*</label>
      <input class="form-control" type="text" name="firstname" placeholder="First Name" formControlName="firstname">
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="middlename">Middle Name</label>
      <input class="form-control" type="text" name="middlename" placeholder="Middle Name" formControlName="middlename">
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="lastname">Last Name*</label>
      <input class="form-control" type="text" name="lastname" placeholder="Last Name" formControlName="lastname">
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="email">Email*</label>
      <input class="form-control" type="email" name="email" placeholder="Email" formControlName="email" readonly>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="phone">Phone*</label>
      <input class="form-control" type="tel" name="phone" placeholder="Phone" formControlName="phone">
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="dob">Date Of Birth*</label>
      <input class="form-control digits" type="text" matInput [matDatepicker]="picker" name="dob"
        placeholder="Date Of Birth" formControlName="dob" [max]="todayDate" (click)="picker?.open()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="gender">Select Gender*</label>
      <select class="form-control" name="gender" placeholder="Select Gender" formControlName="gender">
        <option value="" disabled>Select Gender</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Transgender">Transgender</option>
      </select>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="address">Enter Full Address*</label>
      <input class="form-control" type="text" name="address" placeholder="Enter Full Address" formControlName="address">
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="country">Country*</label>
      <select class="form-control" name="country" placeholder="Select Country" formControlName="country"
        (click)="onChanges()">
        <option value="" selected disabled>Select Country</option>
        <option *ngFor="let country of countryList" [value]="country.itemName">
          {{country.itemName}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="state">State*</label>
      <select class="form-control" name="state" placeholder="Select State" formControlName="state">
        <option value="" disabled selected>Select State</option>
        <option *ngFor="let state of stateList" [value]="state.itemName">
          {{state.itemName}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="postal-code">Postal Code*</label>`
      <input class="form-control" type="number" name="postalCode" placeholder="Postal Code"
        formControlName="postalCode">
    </div>
  </div>
  <hr>
  <div class="col-md-12 mb-2">
    <h4>Social Media Account</h4>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="postal-code">Facebook Account Link</label>
      <input class="form-control" type="text" name="fblink" placeholder="Enter Facebook Account Link"
        formControlName="fblink">
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="postal-code">Instagram Username</label>
      <input class="form-control" type="text" name="instausername" placeholder="Enter Instagram Username"
        formControlName="instausername">
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label for="postal-code">Twitter Username</label>
      <input class="form-control" type="text" name="twitterusername" placeholder="Enter Twitter Username"
        formControlName="twitterusername">
    </div>
  </div>
</div>
