import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-important-information',
  templateUrl: './important-information.component.html',
  styleUrls: ['./important-information.component.scss']
})
export class ImportantInformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
