import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private _completeProfile = environment.server;

  constructor(private http: HttpClient) { }

  getProducts() {
    return this.http.get(this._completeProfile + '/products/get-products');
  }

  getProduct(id) {
    return this.http.get(this._completeProfile + '/products/get-product/' + id);
  }

  sentProductRequest(data) {
    return this.http.post(this._completeProfile + '/products/sent-product-request', data);
  }

  getRequestedProducts(id) {
    return this.http.get(this._completeProfile + '/products/get-requested-products/' + id,);
  }
}
