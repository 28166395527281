import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DocumentsService } from 'src/app/services/documents.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-permanent-resident-status',
  templateUrl: './permanent-resident-status.component.html',
  styleUrls: ['./permanent-resident-status.component.scss']
})
export class PermanentResidentStatusComponent implements OnInit {

  @Input() permanentResidentService;
  currentStatus;
  reuploadDocuments;
  selectedFile;
  documentValidation = false;
  dialogRef;

  uploadDocumentsData = [];
  submitbtnValidation;;
  loading = false;

  constructor(
    private _document: DocumentsService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.currentStatus = this.permanentResidentService.currentStatus;
    if (this.permanentResidentService.currentStatus.documents.reupload === true) {
      this.fetchDoc();
      this.reuploadDocuments = this.permanentResidentService.currentStatus.reuploadDocuments;
    }

    $(document).on('change', '.up', function () {
      var names: any = [];
      var length = $(this).get(0).files.length;
      for (var i = 0; i < $(this).get(0).files.length; ++i) {
        names.push($(this).get(0).files[i].name);
      }
      if (length > 2) {
        var fileName = names.join(', ');
        $(this).closest('.form-group').find('.form-control').attr("value", length + " files selected");
      }
      else {
        $(this).closest('.form-group').find('.form-control').attr("value", names);
      }
    });
  }

  uploadDocumentsFile(name) {
    this.loading = true;
    const formData = new FormData();
    formData.append('document', this.selectedFile);
    formData.append('name', name);

    this._document.uploadDocument(formData).subscribe((res) => {
      this.loading = false;
      this._document.documentReupload({ name }, 'Immigration').subscribe((res) => {
        this.closeDialog();
        window.location.reload();
      });
    });
  }

  closeDialog() {
    this.dialogRef.close();
    this.selectedFile = null;
    this.documentValidation = false;
  }

  removeDocuments(value): void {
    this._document.deleteDocument({ name: value }).subscribe((res) => {
      this._document.documentReuploadDelete({ name: value }, 'Immigration PR').subscribe((res) => {
        window.location.reload();
      });
    });
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  fetchDoc() {
    this._document.getDocuments().subscribe((res: any) => {
      var uploadDocuments = [];
      for (let i in res.documents) {
        for (let categoryIndex in res.documents[i].category) {
          if (res.documents[i].category[categoryIndex] === "Immigration PR") {
            uploadDocuments.push(res.documents[i]);
          }
        }
      }

      this.uploadDocumentsData = uploadDocuments.reduce((unique, o) => {
        if (!unique.some(obj => obj.name === o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);

      this.uploadDocumentsData = this.uploadDocumentsData.sort(this.dynamicSort("name"));

      var finalDoc = []
      for (let doc of this.uploadDocumentsData) {
        for (let re of this.currentStatus.reuploadDocuments) {
          if (doc.name === re.name) {
            finalDoc.push(doc)
          }
        }
      }
      this.uploadDocumentsData = finalDoc;

      for (let docIndex in this.uploadDocumentsData) {
        if (this.uploadDocumentsData[docIndex].url !== null) {
          this._document.viewDoc({ name: this.uploadDocumentsData[docIndex].name }).subscribe(
            res => {
              this.uploadDocumentsData[docIndex].url = res['document_url']
            },
            err => {
              console.log(err)
            }
          )
        }
      }

      const validator = uploadDocuments.filter(item => item.url === null);
      const mandatoryCheck = validator.filter(item => item.mandatory === true)
      if (mandatoryCheck.length === 0) {
        this.submitbtnValidation = true;
      }
    });
  }

  selectFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const file_size = event.target.files[0].size;
      const fileType = file['type'];
      const validImageTypes = ['image/jpeg', 'image/jpg', 'application/pdf'];
      if (file_size > 4194304) {
        $("#file_error").html("File size must less then 4MB. Compress file <a target='blank' href='https://www.wecompress.com/en/'>Here</a>").show().delay(5000).fadeOut('slow');
        $("#uploadShow").css("border: 1px solid red");
        this.selectedFile = null;
        this.documentValidation = false;
      } else if (!validImageTypes.includes(fileType)) {
        $("#file_error").html("Invalid Document only Accept jpg, jpeg and pdf").show().delay(5000).fadeOut('slow');;
        $("#uploadShow").css("border: 1px solid red");
        this.selectedFile = null;
        this.documentValidation = false;
      } else {
        this.selectedFile = file;
        this.documentValidation = true;
      }
    }
  }

  docDiv() {
    document.getElementById("reuploadDiv").scrollIntoView();
  }

  uploadDocuments(content, name) {
    this.dialogRef = this.dialog.open(content, {
      width: '500px',
      data: { name: name },
      autoFocus: false,
      hasBackdrop: false
    });
  }

  reuploadConfirm() {
    this._document.reuploadDocumentSubmit(true, 'Immigration').subscribe((res) => {
      window.location.reload();
    });
  }

  onDialogClose() {
    this.dialogRef.close();
    this.selectedFile = null;
    this.documentValidation = false;
  }
}
