<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1>University List</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">University List</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="about-section student-match">
    <div class="container">

      <div class="sec-title text-center">
        <h3 class="wow animate__animated animate__fadeInUp">University List</h3>
        <div class="separator center"><span></span></div>
      </div>

      <h3>Search Summary</h3>
      <div class="summary-box">
        <div class="row">
          <div class="col-md-6">
            <p><strong>I want to pursue my: </strong> {{searchSummary.pursueto}}</p>
            <p><strong>Annual Budget Range: </strong>
              <br>
              Minimum: {{searchSummary.budgetRange.minimum}}
              <br>
              Maximum: {{searchSummary.budgetRange.maximum}}
            </p>

            <h6>Academic History: </h6>
            <ul>
              <li *ngFor="let board of searchSummary.boards; let i = index">{{i + 1}}. {{board.name}} {{board.total}}
              </li>
            </ul>
          </div>
          <div class="col-md-6" *ngIf="searchSummary.programsInterest">
            <h6>Select Program of Interest: </h6>
            <ul>
              <li *ngFor="let program of searchSummary.programsInterest; let i = index">{{i + 1}}. {{program.itemName}}
              </li>
            </ul>
          </div>
          <div class="col-md-6" *ngIf="searchSummary.programkey">
            <p><strong>Program of Interest By Key: </strong> {{searchSummary.programkey}}</p>
          </div>
        </div>
      </div>

      <hr>

      <h3>Acceptance Likelihood Indicator</h3>
      <div class="summary-box-2">
        <div class="row">
          <div class="col-md-12 my-1">
            <i class="fa fa-tachometer-alt green mr-2"></i>
            <strong>Easy: </strong>
            <span class="ml-2">High probability of acceptance</span>
          </div>
          <div class="col-md-12 my-1">
            <i class="fa fa-tachometer-alt yellow mr-2"></i>
            <strong>Medium: </strong>
            <span class="ml-2">Medium probability of acceptance</span>
          </div>
          <div class="col-md-12 my-1">
            <i class="fa fa-tachometer-alt red mr-2"></i>
            <strong>Difficult: </strong>
            <span class="ml-2">Low probability of acceptance</span>
          </div>
        </div>
      </div>

      <mat-tab-group mat-align-tabs="center">
        <mat-tab>
          <ng-template mat-tab-label>
            <i class="fa fa-tachometer-alt red mr-2"></i>
            <strong>Difficult</strong>
            <span class="program ml-2">Total Programs: </span>
            <span class="badge badge-success ml-2">{{dreamNumber}}</span>
          </ng-template>

          <div class="row mb-3">
            <div class="col-md-6">
              <h3>Dream Universities <i class="fa fa-tachometer-alt red ml-2"></i></h3>
            </div>
            <div class="col-md-6">
              <input (keyup)="searchMethod('dream', $event.target.value)" class="form-control" placeholder="Search..."
                type="text" required>
            </div>
          </div>

          <div *ngIf="dream.length === 0; else dreamV">
            <div class="box p-3">
              <p>Sorry, we could not find any universities matching your search criteriqa and grades in this category.
                Please broaden your search to improve your choices. Steps that can be taken include select more
                programs, increase the budget range etc.</p>
            </div>
          </div>
          <ng-template #dreamV>
            <div class="box p-0 px-3 pb-3 " *ngFor="let university of dream">
              <div class="row">
                <div class="col-md-12">
                  <div class="listing">
                    <div class="listing-content f-listing bg-card-primary mt-4">
                      <div class="row listing-info">
                        <div class="col-md-6 text">
                          <h3>{{university[0].university}}</h3>
                        </div>
                        <span class="col-md-6">
                          <i class="fas fa-money-bill"></i>
                          <strong> Approximate Fee (2 semesters):</strong>
                          {{university[0].approximate_fee__2_semesters_}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let uni of university; let i = index">
                    <div class="listing d-sm-flex">
                      <div class="listing-content f-listing">
                        <div class="l-author-info l-flex has-border-bottom">
                          <div class="text">
                            <!-- <h5>{{uni.university}}</h5> -->
                            <h5>{{i + 1}}). {{uni.program_name}}</h5>
                            <p><strong class="mr-2">Co-op (work): </strong>
                              <i *ngIf="uni.co_op_internship === 'Yes'" class="fas fa-check"></i>
                              <i *ngIf="uni.co_op_internship === 'No'" class="fas fa-times"></i>
                            </p>
                            <p><strong>Degree: </strong> {{uni.degree}}</p>
                            <p><strong>Language: </strong> {{uni.language}}</p>
                          </div>
                        </div>
                        <!-- <div class="listing-info pb-0">
                          <span><i class="fas fa-money-bill"></i> <strong>Approximate Fee (2
                              semesters):</strong> {{uni.approximate_fee__2_semesters_}}</span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <i class="fa fa-tachometer-alt yellow mr-2"></i>
            <strong>Medium</strong>
            <span class="program ml-2">Total Programs: </span>
            <span class="badge badge-success ml-2">{{reachNumber}}</span>
          </ng-template>

          <div class="row mb-3">
            <div class="col-md-6">
              <h3>Reach Universities <i class="fa fa-tachometer-alt yellow ml-2"></i></h3>
            </div>
            <div class="col-md-6">
              <input (keyup)="searchMethod('reach', $event.target.value)" class="form-control" placeholder="Search..."
                type="text" required>
            </div>
          </div>

          <div *ngIf="reach.length === 0; else reachV">
            <div class="box p-3">
              <p>Sorry, we could not find any universities matching your search criteriqa and grades in this category.
                Please broaden your search to improve your choices. Steps that can be taken include select more
                programs, increase the budget range etc.</p>
            </div>
          </div>
          <ng-template #reachV>
            <div class="box p-0 px-3 pb-3" *ngFor="let university of reach">
              <div class="row">
                <div class="col-md-12">
                  <div class="listing">
                    <div class="listing-content f-listing bg-card-primary mt-4">
                      <div class="row listing-info">
                        <div class="col-md-6 text">
                          <h3>{{university[0].university}}</h3>
                        </div>
                        <span class="col-md-6">
                          <i class="fas fa-money-bill"></i>
                          <strong> Approximate Fee (2 semesters):</strong>
                          {{university[0].approximate_fee__2_semesters_}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let uni of university">
                    <div class="listing d-sm-flex">
                      <div class="listing-content f-listing">
                        <div class="l-author-info l-flex has-border-bottom">
                          <div class="text">
                            <!-- <h5>{{uni.university}}</h5> -->
                            <h5>{{uni.program_name}}</h5>
                            <p><strong class="mr-2">Co-op (work): </strong>
                              <i *ngIf="uni.co_op_internship === 'Yes'" class="fas fa-check"></i>
                              <i *ngIf="uni.co_op_internship === 'No'" class="fas fa-times"></i>
                            </p>
                            <p><strong>Degree: </strong> {{uni.degree}}</p>
                            <p><strong>Language: </strong> {{uni.language}}</p>
                          </div>
                        </div>
                        <!-- <div class="listing-info pb-0">
                          <span><i class="fas fa-money-bill"></i> <strong>Approximate Fee (2
                              semesters):</strong> {{uni.approximate_fee__2_semesters_}}</span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>


        <mat-tab>
          <ng-template mat-tab-label>
            <i class="fa fa-tachometer-alt green mr-2"></i>
            <strong>Easy</strong>
            <span class="program ml-2">Total Programs: </span>
            <span class="badge badge-success ml-2">{{safeNumber}}</span>
          </ng-template>

          <div class="row mb-3">
            <div class="col-md-6">
              <h3>Safety Universities <i class="fa fa-tachometer-alt green ml-2"></i></h3>
            </div>
            <div class="col-md-6">
              <input (keyup)="searchMethod('safety', $event.target.value)" class="form-control" placeholder="Search..."
                type="text" required>
            </div>
          </div>
          <div *ngIf="safety.length === 0; else safetyV">
            <div class="box p-3">
              <p>Sorry, we could not find any universities matching your search criteriqa and grades in this category.
                Please broaden your search to improve your choices. Steps that can be taken include select more
                programs, increase the budget range etc.</p>
            </div>
          </div>

          <ng-template #safetyV>
            <div class="box p-0 px-3 pb-3" *ngFor="let university of safety">
              <div class="row">
                <div class="col-md-12">
                  <div class="listing">
                    <div class="listing-content f-listing bg-card-primary mt-4">
                      <div class="row listing-info">
                        <div class="col-md-6 text">
                          <h3>{{university[0].university}}</h3>
                        </div>
                        <span class="col-md-6">
                          <i class="fas fa-money-bill"></i>
                          <strong> Approximate Fee (2 semesters):</strong>
                          {{university[0].approximate_fee__2_semesters_}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let uni of university">
                    <div class="listing d-sm-flex">
                      <div class="listing-content f-listing">
                        <div class="l-author-info l-flex has-border-bottom">
                          <div class="text">
                            <!-- <h5>{{uni.university}}</h5> -->
                            <h5>{{uni.program_name}}</h5>
                            <p><strong class="mr-2">Co-op (work): </strong>
                              <i *ngIf="uni.co_op_internship === 'Yes'" class="fas fa-check"></i>
                              <i *ngIf="uni.co_op_internship === 'No'" class="fas fa-times"></i>
                            </p>
                            <p><strong>Degree: </strong> {{uni.degree}}</p>
                            <p><strong>Language: </strong> {{uni.language}}</p>
                          </div>
                        </div>
                        <!-- <div class="listing-info pb-0">
                            <span><i class="fas fa-money-bill"></i> <strong>Approximate Fee (2
                                semesters):</strong> {{uni.approximate_fee__2_semesters_}}</span>
                          </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <app-site-footer></app-site-footer>

</main>
