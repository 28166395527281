import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StudyPermitImportantInfoComponent } from 'src/app/components/dialogs/study-permit-important-info/study-permit-important-info.component';
import { StudypermitService } from 'src/app/services/studypermit.service';

@Component({
  selector: 'app-study-permit-questions',
  templateUrl: './study-permit-questions.component.html',
  styleUrls: ['./study-permit-questions.component.scss']
})
export class StudyPermitQuestionsComponent implements OnInit {

  dialogRef;
  immigrationQuestions: FormGroup

  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _studypermit: StudypermitService,
  ) { }

  ngOnInit(): void {
    this.immigrationQuestions = this._formBuilder.group({
      'whyincanada': new FormControl('', [Validators.required]),
      'qualification': new FormControl('', [Validators.required]),
      'appliedUniversity': new FormControl('', [Validators.required]),
      'programName': new FormControl('', [Validators.required]),
      'gapYear': new FormControl('', [Validators.required]),
      'returnToHome': new FormControl('', [Validators.required]),
      'fundStudie': new FormControl('', [Validators.required]),
      'understandResposibilities': new FormControl('', [Validators.required]),
      'careerGoal': new FormControl('', [Validators.required]),
      'curricularInterests': new FormControl('', [Validators.required])
    });
  }

  openInfoDialog() {
    this.dialogRef = this.dialog.open(StudyPermitImportantInfoComponent, {
      width: '1200px',
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  submitService() {
    this._studypermit.studypermitQuestion(this.immigrationQuestions.value).subscribe((res) => {
      window.location.reload()
    });
  }

}
