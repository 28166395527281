<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <h3 class="mt-4 mb-4">Status</h3>

    <div class="row">
      <div class="col-md-7 side-content">
        <div class="status-page-wizard">
          <ul>
            <li class="complete">
              <a>Application Submitted Successfully<i class="ico fas fa-check ico-green"></i>
                <span class="desc"> </span>
              </a>
            </li>
            <li *ngIf="currentStatus?.documents.status === false; else varifyDone" class="current">
              <a *ngIf="currentStatus?.documents.reupload === false;">Document Verification
                <span class="desc">{{currentStatus?.documents.note}}</span>
              </a>
              <a *ngIf="currentStatus?.documents.reupload === true;">Reupload the following Documents:
                <button (click)="docDiv()" class="btn btn-danger btn-square">Upload Documents</button>
              </a>
            </li>
            <li *ngIf="currentStatus?.documents.status === false" class="locked">
              <a>Awaiting Application Submission to University/College <i class="ico fas fa-lock"></i>
              </a>
            </li>
            <li *ngIf="currentStatus?.documents.status === false" class="locked">
              <a>Done <i class="ico fas fa-lock"></i>
              </a>
            </li>
            <ng-template #varifyDone>
              <li class="complete">
                <a>Documents have been successfully verified by our team. <i class="ico fas fa-check ico-green"></i>
                </a>
              </li>
              <li *ngIf="currentStatus?.medVerify.status === false && applied === 0" class="current">
                <a *ngIf="applied === 0">All Applications are awaiting submission to the University/Colleges
                  of
                  your choice.
                </a>
              </li>
              <li *ngIf="currentStatus?.medVerify.status === false && applied === 1" class="current">
                <a *ngIf="applied === 1">Some applications have been submitted to the University/Colleges of
                  your choice
                </a>
              </li>
              <li *ngIf="currentStatus?.medVerify.status === false && applied === 2" class="complete">
                <a *ngIf="applied === 2">All applications have been submitted to the University/Colleges of
                  your
                  choice.
                </a>
                <i class="ico fas fa-check ico-green"></i>
              </li>
              <li *ngIf="currentStatus?.medVerify.status === false" class="current">
                <a *ngIf="status === 0">No application has been processed yet by the University/Colleges of
                  your
                  choice.
                </a>
                <a *ngIf="status === 1">Some applications have been processed by the Universities/Colleges of
                  your choice.
                </a>
                <a *ngIf="status === 2">All applications have been processed by the Universities/Colleges of
                  your choice.
                </a>
              </li>
              <li *ngIf="currentStatus?.medVerify.status === false;" class="locked">
                <a>Done <i class="ico fas fa-lock"></i>
                </a>
              </li>
              <li class="complete" *ngIf="currentStatus?.medVerify.status === true">
                <a>All applications have been submitted to the University/Colleges of your
                  choice.
                  <i class="ico fas fa-check ico-green"></i>
                </a>
              </li>
              <li class="complete" *ngIf="currentStatus?.medVerify.status === true">
                <a>All applications have been processed by the Universities/Colleges of your choice.
                  <i class="ico fas fa-check ico-green"></i>
                </a>
              </li>
              <li class="complete" *ngIf="currentStatus?.medVerify.status === true">
                <a>Done<i class="ico fas fa-check ico-green"></i>
                </a>
              </li>
            </ng-template>
          </ul>
        </div>
      </div>
      <div class="col-md-5">
        <div class="status-page-wizard">
          <ul class="scroll">
            <div class="chat-div" *ngFor="let item of currentStatus?.stepsUpdate">
              <img src="/assets/images/profile-pic.png" alt="Profile Pic">
              <p>{{item.note}}</p>
              <span class="time">by {{item.sender}} on {{item.date | date}}</span>
            </div>
          </ul>
        </div>
      </div>
      <div class="col-md-12" *ngIf="downloadLinkInvoice">
        <hr>
        <p class="pl-2"><strong>Get your payment of admission service fee invoice:</strong>
          <span class="download-btn pl-2">
            <a target="blank" class="btn btn-danger btn-square" [href]="downloadLinkInvoice">Download
              Invoice</a>
          </span></p>
      </div>
    </div>
  </div>
</div>

<div id="reuploadDiv" class="card" *ngIf="currentStatus?.documents.reupload === true">
  <div class="card-body">
    <h3 class="mt-4 mb-4">Documents Reupload:</h3>
    <div id="uploader">
      <div class="row" *ngFor="let item of currentStatus?.reuploadDocuments; let i = index">
        <div class="col-sm-12" id="one" *ngIf="uploadDocumentsData.length > 0">
          <div id="uploader">
            <div class="row uploadDoc">
              <div class="col-sm-6">
                <h4>{{item.name}} <span class="red-color" *ngIf="item.mandatory">(Mandatory)</span> <span
                    *ngIf="!item.mandatory">(Not Mandatory)</span></h4>
              </div>
              <div class="col-sm-6 text-right">
                <button *ngIf="item.valid === false; else uploadDone" class="btn btn-square btn-primary mr-2"
                  (click)="uploadDocuments(documentDialog, item.name)">Reupload</button>
                <ng-template #uploadDone>
                  <a target="_blank" [href]="uploadDocumentsData[i]?.url"
                    class="btn btn-square btn-primary mr-1">View</a>
                  <a (click)="uploadDocuments(documentDialog, item.name)"
                    class="btn btn-square btn-info mr-1">Update</a>
                  <a (click)="removeDocuments(item.name)" class="btn btn-square btn-danger">X</a>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #documentDialog let-tablename let-ref="dialogRef">
  <div class="card">
    <div class="card-header">Select Document
      <button class="close" aria-label="Close" (click)="onDialogClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div *ngIf="loading" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="alert alert-info dark mt-0" role="alert">
        <p>Please upload documents only in JPEG, JPG & PDF formats with a maximum size of 4MB. Other
          formats are not
          accepted. </p>
      </div>
      <form class="form-group box-form" novalidate>
        <div class="row">
          <div class="col-md-12">
            <div class="input-group">
              <input type="text" class="form-control" readonly>
              <div class="input-group-btn">
                <span class="fileUpload btn btn-danger">
                  <span class="upl" id="upload">Select Document</span>
                  <input type="file" class="upload up btn btn-primary" id="up" [name]="tablename"
                    (change)="selectFile($event)" required />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mt-3">
          <button type="submit" class="btn btn-square btn-primary w-100" [disabled]="!documentValidation"
            (click)="uploadDocumentsFile(tablename.name)">Upload</button>
        </div>
        <div id="file_error"></div>
      </form>
    </div>
  </div>
</ng-template>


<!-- Status -->
<mat-tab-group class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <mat-tab label="Status Of Universities And Colleges">
      <div class="row p-3">
        <div class="col-md-12" *ngIf="applicationStatus?.university.length > 0">
          <h3 class="mb-3">Universities</h3>
          <div class="overflow">
            <table class="table table-style-1">
              <thead>
                <tr>
                  <th>University Name</th>
                  <th>Applied</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr class="tr-shadow" *ngFor="let item of applicationStatus?.university; let i = index">
                  <td>{{i + 1}}. {{item?.universityName}}</td>
                  <td>
                    <span class="info-btn" [ngStyle]="{'background-color':getColor(item?.applied)}">
                      {{item?.applied}}
                    </span>
                  </td>
                  <td>
                    <span class="info-btn" [ngStyle]="{'background-color':getStatusColor(item?.status)}">
                      {{item?.status}}
                    </span>
                  </td>
                </tr>
                <tr class="spacer"></tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr *ngIf="applicationStatus?.colleges.length > 0 && applicationStatus?.university.length !> 0">

        <div class="col-md-12" *ngIf="applicationStatus?.colleges.length > 0">
          <h3 class="mb-3">Colleges</h3>
          <div class="overflow">
            <table class="table table-style-1">
              <thead>
                <tr>
                  <th>College Name</th>
                  <th>Applied</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr class="tr-shadow" *ngFor="let item of applicationStatus?.colleges; let i = index">
                  <td>{{i + 1}}. {{item?.collegeName}}</td>
                  <td>
                    <span class="info-btn" [ngStyle]="{'background-color':getColor(item?.applied)}">
                      {{item?.applied}}
                    </span>
                  </td>
                  <td>
                    <span class="info-btn" [ngStyle]="{'background-color':getStatusColor(item?.status)}">
                      {{item?.status}}
                    </span>
                  </td>
                </tr>
                <tr class="spacer"></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Academic Background">
      <div class="p-3">
        <h2 class="mt-0">Secondary and Post Secondary Institutes (High Schools, Colleges, Universities) attended
        </h2>
        <div class="overflow">
          <table class="table table-style-1 small-text">
            <thead>
              <tr>
                <th>#</th>
                <th>Institute Type</th>
                <th>Attended From</th>
                <th>Attended To</th>
                <th>Country</th>
                <th>Name Of Institution/School/College</th>
                <th>Degree/Diploma/Certificate Name</th>
                <th>Graduation Date</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tr-shadow" *ngFor="let item of studyList; let i = index">
                <td>{{i + 1}}</td>
                <td>{{item?.studyType[0].itemName}}</td>
                <td>{{item?.attendedFrom | date}}</td>
                <td>{{item?.attendedTo | date}}</td>
                <td>{{item?.country[0].itemName}}</td>
                <td>{{item?.nameOfInstitute}}</td>
                <td>{{item?.degreeName}}</td>
                <td>{{item?.indicateDate | date}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr>

        <h2>Institute/College/School Information</h2>
        <div class="overflow">
          <table class="table table-style-1 small-text">
            <thead>
              <tr>
                <th>#</th>
                <th>Class</th>
                <th>Board Name</th>
                <th>Year of Completion</th>
                <th>Subjects</th>
                <th>Percentage/Grade</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tr-shadow" *ngFor="let item of gradeList; let i = index">
                <td>{{i + 1}}</td>
                <td>{{item?.class}}</td>
                <td>{{item?.boardName}}</td>
                <td>{{item?.passedYear}}</td>
                <td>
                  <span *ngFor="let subjects of item.subjects">
                    <span class="info-btn">{{subjects.name}}: {{subjects.marks}}</span>
                  </span>
                </td>
                <td>{{item?.percentage}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

  </div>
</mat-tab-group>
