import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-study-permit-main',
  templateUrl: './study-permit-main.component.html',
  styleUrls: ['./study-permit-main.component.scss']
})
export class StudyPermitMainComponent implements OnInit {

  step: Number;
  profile;
  studyPermitService;

  constructor(
    private _profile: ProfileService,
  ) { }

  ngOnInit(): void {
    this._profile.getStudentDetails().subscribe((res) => {
      if (res.selectedService) {
        for (var i = 0; i < res.selectedService.servicesName.length; i++) {
          if (res.selectedService.servicesName[i].name === 'immigration') {
            if (res.selectedService.servicesName[i].statusStep !== 0) {
              this.step = res.selectedService.servicesName[i].statusStep;
              this.profile = res;
              this.studyPermitService = res.studyPermitService;
            } else {
              this.step = 0;
            }
          }
        }
      }
    });
  }

}
