import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { TokenInterceptorService } from './services/token-interceptor.service';

import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MomentUtcDateAdapter } from './sharedata/moment-utc-date-adapter';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JourneyBeginComponent } from './pages/dashboard/journey-begin/journey-begin.component';
import { ProfileComponent } from './pages/dashboard/profile/profile.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { AlertComponent } from './components/alert/alert.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { StartingDocumentsComponent } from './pages/dashboard/starting-documents/starting-documents.component';
import { BankingInfoComponent } from './pages/dashboard/services/banking/banking-info/banking-info.component';
import { TravelFormSubmitComponent } from './pages/dashboard/services/travel/travel-form-submit/travel-form-submit.component';
import { TravelInfoComponent } from './pages/dashboard/services/travel/travel-info/travel-info.component';
import { HousingInfoComponent } from './pages/dashboard/services/housing/housing-info/housing-info.component';
import { FoodInfoComponent } from './pages/dashboard/services/food/food-info/food-info.component';
import { PrepareForCanadaInfoComponent } from './pages/dashboard/services/prepare-for-canada/prepare-for-canada-info/prepare-for-canada-info.component';
import { AdmissionConfirmComponent } from './pages/dashboard/services/admission/admission-confirm/admission-confirm.component';
import { StudyPermitInfoComponent } from './pages/dashboard/services/study-permit/study-permit-info/study-permit-info.component';
import { StudyPermitDocumentsComponent } from './pages/dashboard/services/study-permit/study-permit-documents/study-permit-documents.component';
import { StudyPermitServicesComponent } from './pages/dashboard/services/study-permit/study-permit-services/study-permit-services.component';
import { StudyPermitStatusComponent } from './pages/dashboard/services/study-permit/study-permit-status/study-permit-status.component';
import { AdmissionServicesComponent } from './pages/dashboard/services/admission/admission-services/admission-services.component';
import { AdmissionDocumentsComponent } from './pages/dashboard/services/admission/admission-documents/admission-documents.component';
import { AdmissionStatusComponent } from './pages/dashboard/services/admission/admission-status/admission-status.component';
import { EditServicesComponent } from './components/edit-components/edit-services/edit-services.component';
import { EditStudyInfoComponent } from './components/edit-components/edit-study-info/edit-study-info.component';
import { HomeComponent } from './pages/site/home/home.component';
import { AboutUsComponent } from './pages/site/about-us/about-us.component';
import { ContactUsComponent } from './pages/site/contact-us/contact-us.component';
import { OurServicesComponent } from './pages/site/our-services/our-services.component';
import { AdmissionComponent } from './pages/site/services-pages/admission/admission.component';
import { ImmigrationComponent } from './pages/site/services-pages/immigration/immigration.component';
import { StudyPermitComponent } from './pages/site/services-pages/study-permit/study-permit.component';
import { BankingComponent } from './pages/site/services-pages/banking/banking.component';
import { FoodComponent } from './pages/site/services-pages/food/food.component';
import { TravelComponent } from './pages/site/services-pages/travel/travel.component';
import { HousingComponent } from './pages/site/services-pages/housing/housing.component';
import { StudentJobComponent } from './pages/site/services-pages/student-job/student-job.component';
import { MedicalIncuranceComponent } from './pages/site/services-pages/medical-incurance/medical-incurance.component';
import { ImportantInformationComponent } from './pages/site/services-pages/important-information/important-information.component';
import { SiteHeaderComponent } from './components/site-header/site-header.component';
import { SiteFooterComponent } from './components/site-footer/site-footer.component';
import { FaqsComponent } from './pages/site/faqs/faqs.component';
import { TermsComponent } from './pages/site/terms/terms.component';
import { PrivacyPolicyComponent } from './pages/site/privacy-policy/privacy-policy.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ConfirmMailComponent } from './pages/confirm-mail/confirm-mail.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { PreDepartureComponent } from './pages/dashboard/services/pre-departure/pre-departure.component';
import { ErrorPageComponent } from './pages/site/error-page/error-page.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { SafePipe } from './safe.pipe';
import { ViewDocDialogComponent } from './components/dialogs/view-doc-dialog/view-doc-dialog.component';
import { AdvisorComponent } from './pages/site/microservices/advisor/advisor.component';
import { RecommendedFormComponent } from './pages/site/recommended-university/recommended-form/recommended-form.component';
import { RecommendedUniversityListComponent } from './pages/site/recommended-university/recommended-university-list/recommended-university-list.component';
import { BankingLoanComponent } from './pages/dashboard/services/banking/banking-loan/banking-loan.component';
import { RegistrationDialogComponent } from './components/dialogs/registration-dialog/registration-dialog.component';
import { JobsListComponent } from './pages/dashboard/services/jobs-listing/jobs-list/jobs-list.component';
import { StudyPermitFormStepComponent } from './pages/dashboard/services/study-permit/study-permit-form-step/study-permit-form-step.component';
import { StudyPermitQuestionsComponent } from './pages/dashboard/services/study-permit/study-permit-questions/study-permit-questions.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PersonalStepperComponent } from './components/steppers/personal-stepper/personal-stepper.component';
import { AdmissionStepperComponent } from './components/steppers/admission-stepper/admission-stepper.component';
import { ServicesStepperComponent } from './components/steppers/services-stepper/services-stepper.component';
import { ChangeProfileDialogComponent } from './components/dialogs/change-profile-dialog/change-profile-dialog.component';
import { UploadDocDialogComponent } from './components/dialogs/upload-doc-dialog/upload-doc-dialog.component';
import { ChangePasswordDialogComponent } from './components/dialogs/change-password-dialog/change-password-dialog.component';
import { EditProfileDialogComponent } from './components/dialogs/edit-profile-dialog/edit-profile-dialog.component';
import { DocUploadComponent } from './components/documents/doc-upload/doc-upload.component';
import { StudyPermitImportantInfoComponent } from './components/dialogs/study-permit-important-info/study-permit-important-info.component';
import { AdmissionApplicationSummaryDialogComponent } from './components/dialogs/admission-application-summary-dialog/admission-application-summary-dialog.component';
import { AddInstituteDialogComponent } from './components/dialogs/admission/add-institute-dialog/add-institute-dialog.component';
import { EditInstituteDialogComponent } from './components/dialogs/admission/edit-institute-dialog/edit-institute-dialog.component';
import { AddSchoolDialogComponent } from './components/dialogs/admission/add-school-dialog/add-school-dialog.component';
import { EditSchoolDialogComponent } from './components/dialogs/admission/edit-school-dialog/edit-school-dialog.component';
import { BankingMainComponent } from './pages/dashboard/services/banking/banking-main/banking-main.component';
import { BankingQuestionsComponent } from './pages/dashboard/services/banking/banking-questions/banking-questions.component';
import { TravelMainComponent } from './pages/dashboard/services/travel/travel-main/travel-main.component';
import { HousingMainComponent } from './pages/dashboard/services/housing/housing-main/housing-main.component';
import { HousingFormComponent } from './pages/dashboard/services/housing/housing-form/housing-form.component';
import { FoodMainComponent } from './pages/dashboard/services/food/food-main/food-main.component';
import { FoodFormComponent } from './pages/dashboard/services/food/food-form/food-form.component';
import { AdmissionMainComponent } from './pages/dashboard/services/admission/admission-main/admission-main.component';
import { StudyPermitMainComponent } from './pages/dashboard/services/study-permit/study-permit-main/study-permit-main.component';
import { ArticlePageComponent } from './pages/site/articles/article-page/article-page.component';
import { AllArticlesComponent } from './pages/site/articles/all-articles/all-articles.component';
import { AdmissionPaymentComponent } from './pages/dashboard/services/admission/admission-payment/admission-payment.component';
import { FeedbackFormComponent } from './pages/site/feedback-form/feedback-form.component';
import { NotificationBarComponent } from './components/notification-bar/notification-bar.component';
import { WebinerRegistrationComponent } from './pages/site/webiner-registration/webiner-registration.component';
import { ProductsListComponent } from './pages/dashboard/services/products-sale/products-list/products-list.component';
import { ProductsListMainComponent } from './pages/dashboard/services/products-sale/products-list-main/products-list-main.component';
import { BuyProductsDialogComponent } from './components/dialogs/buy-products-dialog/buy-products-dialog.component';
import { MobilePlansComponent } from './pages/dashboard/services/mobile-plans/mobile-plans.component';
import { PermanentResidentMainComponent } from './pages/dashboard/services/permanent-resident/permanent-resident-main/permanent-resident-main.component';
import { PermanentResidentDocumentsComponent } from './pages/dashboard/services/permanent-resident/permanent-resident-documents/permanent-resident-documents.component';
import { PermanentResidentStatusComponent } from './pages/dashboard/services/permanent-resident/permanent-resident-status/permanent-resident-status.component';
import { AddEnglishTestDialogComponent } from './components/dialogs/admission/add-english-test-dialog/add-english-test-dialog.component';
import { ScholarshipApplyComponent } from './pages/site/scholarship-apply/scholarship-apply.component';
@NgModule({
  declarations: [
    AppComponent,
    JourneyBeginComponent,
    ProfileComponent,
    LoginComponent,
    RegisterComponent,
    AlertComponent,
    SidebarComponent,
    NavbarComponent,
    StartingDocumentsComponent,
    BankingInfoComponent,
    TravelFormSubmitComponent,
    TravelInfoComponent,
    HousingInfoComponent,
    FoodInfoComponent,
    PrepareForCanadaInfoComponent,
    AdmissionConfirmComponent,
    StudyPermitInfoComponent,
    StudyPermitDocumentsComponent,
    StudyPermitServicesComponent,
    StudyPermitStatusComponent,
    AdmissionServicesComponent,
    AdmissionDocumentsComponent,
    AdmissionStatusComponent,
    EditServicesComponent,
    EditStudyInfoComponent,
    HomeComponent,
    AboutUsComponent,
    ContactUsComponent,
    OurServicesComponent,
    AdmissionComponent,
    ImmigrationComponent,
    StudyPermitComponent,
    BankingComponent,
    FoodComponent,
    TravelComponent,
    HousingComponent,
    StudentJobComponent,
    MedicalIncuranceComponent,
    ImportantInformationComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    FaqsComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    ForgotPasswordComponent,
    ConfirmMailComponent,
    ResetPasswordComponent,
    PreDepartureComponent,
    ErrorPageComponent,
    SafePipe,
    ViewDocDialogComponent,
    AdvisorComponent,
    RecommendedFormComponent,
    RecommendedUniversityListComponent,
    BankingLoanComponent,
    RegistrationDialogComponent,
    JobsListComponent,
    StudyPermitFormStepComponent,
    StudyPermitQuestionsComponent,
    PersonalStepperComponent,
    AdmissionStepperComponent,
    ServicesStepperComponent,
    ChangeProfileDialogComponent,
    UploadDocDialogComponent,
    ChangePasswordDialogComponent,
    EditProfileDialogComponent,
    DocUploadComponent,
    StudyPermitImportantInfoComponent,
    AdmissionApplicationSummaryDialogComponent,
    AddInstituteDialogComponent,
    EditInstituteDialogComponent,
    AddSchoolDialogComponent,
    EditSchoolDialogComponent,
    BankingMainComponent,
    BankingQuestionsComponent,
    TravelMainComponent,
    HousingMainComponent,
    HousingFormComponent,
    FoodMainComponent,
    FoodFormComponent,
    AdmissionMainComponent,
    StudyPermitMainComponent,
    ArticlePageComponent,
    AllArticlesComponent,
    AdmissionPaymentComponent,
    FeedbackFormComponent,
    NotificationBarComponent,
    WebinerRegistrationComponent,
    ProductsListComponent,
    ProductsListMainComponent,
    BuyProductsDialogComponent,
    MobilePlansComponent,
    PermanentResidentMainComponent,
    PermanentResidentDocumentsComponent,
    PermanentResidentStatusComponent,
    AddEnglishTestDialogComponent,
    ScholarshipApplyComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'StudentStopFrontEnd' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    AngularMultiSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxIntlTelInputModule,
    NgxSliderModule,
    CarouselModule,
    NgxGalleryModule,
    NgbModule,
    MatTabsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
