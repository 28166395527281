<div (click)="openInfoDialog()" class="info-dialog-btn">Important Information</div>

<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <h3 class="mt-4 mb-4">Important Information</h3>
    <h5 class="mb-4">Please make sure the data provided matches your passport details.</h5>
    <form [formGroup]="immigrationForm" (ngSubmit)="submitService()">
      <div class="row">
        <div class="col-md-12">
          <h5>Personal Information</h5>
          <hr>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">First Name</label>
            <input class="form-control" type="text" name="firstname" placeholder="Firstname"
              formControlName="firstname">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Middle Name</label>
            <input class="form-control" type="text" name="middlename" placeholder="Middlename"
              formControlName="middlename">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Last Name</label>
            <input class="form-control" type="text" name="lastname" placeholder="Lastname" formControlName="lastname">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Phone Number</label>
            <input class="form-control" type="text" name="phone" placeholder="Phone Number" formControlName="phone">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Email</label>
            <input [attr.disabled]="true" class="form-control" type="email" name="email" placeholder="Email"
              formControlName="email">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Date Of Birth</label>
            <input class="form-control digits" type="text" matInput [matDatepicker]="picker" name="dob"
              placeholder="Date Of Birth" formControlName="dob" [max]="todayDate" (click)="picker?.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>

        <div class="col-md-12 mt-4">
          <h5>Immigration and Residence status</h5>
          <hr>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="state">Your country of citizenship*</label>
            <select class="form-control" name="citizenship" placeholder="Select Country" formControlName="citizenship">
              <option value="" disabled selected>Select Country</option>
              <option [value]="country.itemName" *ngFor="let country of countryList">{{country.itemName}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="state">Current country of residence*</label>
            <select class="form-control" name="residence" placeholder="Select Country" formControlName="residence">
              <option value="" disabled selected>Select Country</option>
              <option [value]="country.itemName" *ngFor="let country of countryList">{{country.itemName}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="state">Your status in the country of residence?*</label>
            <select class="form-control" name="residenceStatus" placeholder="Select Status"
              formControlName="residenceStatus">
              <option value="" disabled selected>Select Status</option>
              <option value="Citizen">Citizen</option>
              <option value="Permanent Resident">Permanent Resident</option>
              <option value="Visitor">Visitor</option>
              <option value="Worker">Worker</option>
              <option value="Student">Student</option>
              <option value="Other">Other</option>
              <option value="Protected Person">Protected Person</option>
              <option value="Refugee Claimant">Refugee Claimant</option>
              <option value="Foreign National">Foreign National</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Has your Study Permit Application to Canada ever been rejected?</label>
            <select (click)="onChangesRejetionReason()" class="form-control" name="studyPermitRejected"
              placeholder="Select Option" formControlName="studyPermitRejected">
              <option value="" disabled selected>Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="showRejectionReason">
          <div class="form-group">
            <label for="">Please Provide the reason for rejection?</label>
            <textarea class="form-control" name="rejectionReason" placeholder="Select Option"
              formControlName="rejectionReason"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="state">Have you ever been to canada before?*</label>
            <select (click)="onChangesComeBefore()" class="form-control" name="lastEntry" placeholder="Select Option"
              formControlName="lastEntry">
              <option value="" disabled selected>Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="showComeBefore">
          <div class="form-group">
            <label for="state">Date of your last entry to Canada.*</label>
            <input class="form-control digits" type="text" matInput [matDatepicker]="pickerDate" name="dateEntry"
              placeholder="Select Date" formControlName="dateEntry" [max]="todayDate" (click)="pickerDate?.open()">
            <mat-datepicker-toggle matSuffix [for]="pickerDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerDate></mat-datepicker>
          </div>
        </div>
        <div class="col-md-6" *ngIf="showComeBefore">
          <div class="form-group">
            <label for="state">Place of your last entry to Canada.*</label>
            <select class="form-control" name="placeEntry" formControlName="placeEntry">
              <option value="" selected disabled>Select City</option>
              <option [value]="city" *ngFor="let city of canadaCity">{{city}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="state">Do you have a UCI number?*</label>
            <select (click)="onChangesUCI()" class="form-control" name="uciOption" placeholder="Select Option"
              formControlName="uciOption">
              <option value="" disabled selected>Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="showUCI">
          <div class="form-group">
            <label for="state">Please provide the UCI number*</label>
            <input class="form-control" type="text" name="uci" placeholder="UCI Number" formControlName="uci">
          </div>
        </div>

        <div class="col-md-12 mt-4">
          <h5>Education and Professional Background</h5>
          <hr>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="state">How many years of formal education do you have?*</label>
            <input class="form-control" type="text" name="educationYear" placeholder="Enter years of formal education"
              formControlName="educationYear">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="state">What is current occupation and year of experiance.*</label>
            <input class="form-control" type="text" name="occupation" placeholder="Current Occupation"
              formControlName="occupation">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="state">Have you taken a test from a designated agency to assess your proficiency in
              English or French?*</label>
            <select class="form-control" name="agencyTest" placeholder="Select Country" formControlName="agencyTest">
              <option value="" disabled selected>Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>

        <div class="col-md-12 mt-4">
          <h5>Criminal Records</h5>
          <hr>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="">Do you have any criminal record anywhere globally?</label>
            <select (click)="onChangesCriminalRecord()" class="form-control" name="criminalRecord"
              placeholder="Select Option" formControlName="criminalRecord">
              <option value="" disabled selected>Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="showCriminalRecordDetail">
          <div class="form-group">
            <label for="">Please provide the details of your criminal record?</label>
            <textarea class="form-control" name="criminalRecordDetail" placeholder="Select Option"
              formControlName="criminalRecordDetail"></textarea>
          </div>
        </div>

        <div class="col-md-12 mt-4">
          <h5>Family Information</h5>
          <hr>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label for="state">Marital Status*</label>
            <select (click)="onChangesMarital()" class="form-control" name="maritalstatus"
              placeholder="Select Marital Status" formControlName="maritalstatus">
              <option value="" disabled selected>Select Marital Status</option>
              <option value="Single">Single</option>
              <option value="Engaged">Engaged</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              <option value="Widowed">Widowed</option>
            </select>
          </div>
        </div>
        <div class="col-md-4" *ngIf="showMarriage">
          <div class="form-group">
            <label for="maritaldate">Date of Marriage*</label>
            <input class="form-control digits" type="text" matInput [matDatepicker]="pickertwo" name="maritaldate"
              placeholder="Date of Marriage" formControlName="maritaldate" [max]="todayDate"
              (click)="pickertwo?.open()">
            <mat-datepicker-toggle matSuffix [for]="pickertwo"></mat-datepicker-toggle>
            <mat-datepicker #pickertwo></mat-datepicker>
          </div>
        </div>
        <div class="col-md-4" *ngIf="showMarriage">
          <div class="form-group">
            <label for="spousename">Spouse Name*</label>
            <input class="form-control" type="text" name="spousename" placeholder="Spouse Name"
              formControlName="spousename">
          </div>
        </div>
        <div class="col-md-6" *ngIf="showMarriage">
          <div class="form-group">
            <label for="spouseage">Spouse Date Of Birth*</label>
            <input class="form-control digits" type="text" matInput [matDatepicker]="pickerthree" name="spouseage"
              placeholder="Spouse Age" formControlName="spouseage" [max]="todayDate" (click)="pickerthree?.open()">
            <mat-datepicker-toggle matSuffix [for]="pickerthree"></mat-datepicker-toggle>
            <mat-datepicker #pickerthree></mat-datepicker>
          </div>
        </div>
        <div class="col-md-6" *ngIf="showMarriage">
          <div class="form-group">
            <label for="spouseEducation">Spouse Education Qualification*</label>
            <input class="form-control" type="text" name="spouseEducation" placeholder="Spouse Education Qualification"
              formControlName="spouseEducation">
          </div>
        </div>
        <div class="col-md-12" *ngIf="showMarriage">
          <div class="form-group">
            <label for="state">Has your Spouse taken a test from a designated agency to assess his/her
              proficiency in English or French?*</label>
            <select class="form-control" name="spouseAgencyTest" placeholder="Select Country"
              formControlName="spouseAgencyTest">
              <option value="" disabled selected>Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
      </div>

      <div class="text-center mt-5">
        <button type="submit" [disabled]="immigrationForm.invalid" class="btn btn-square btn-primary">Next</button>
      </div>
    </form>
  </div>
</div>
