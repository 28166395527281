import { Component, OnInit } from '@angular/core';
import { ExtraService } from 'src/app/services/extra.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  privacy;

  constructor(
    private _extra: ExtraService
  ) { }

  ngOnInit(): void {
    this._extra.getTermsAndConditions().subscribe(
      res => {
        this.privacy = res.privacy
      },
      err => {
        console.log(err)
      }
    )
  }
}
