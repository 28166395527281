<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body" *ngIf="userStudyDetails?.decided === 'idecided'; else admissionFormTemp">
    <h2 class="mt-2 mb-3">Your selected Universities and Colleges</h2>
    <div class="row">
      <div class="col-md-6 mb-3" *ngIf="userStudyDetails?.university?.length > 0">
        <ul class="list-group">
          <li class="list-group-item list-group-item-action active">Universities (order of priority)</li>
          <li class="list-group-item" *ngFor="let university of userStudyDetails?.university; let i = index">
            {{i + 1}}. {{ university.itemName}} <span *ngIf="university.campus">, <strong>Campus: </strong>
              {{ university.campus }}</span></li>
        </ul>
      </div>
      <div class="col-md-6 mb-3" *ngIf="userStudyDetails?.colleges?.length > 0">
        <ul class="list-group">
          <li class="list-group-item list-group-item-action active">Colleges (order of priority)</li>
          <li class="list-group-item" *ngFor="let college of userStudyDetails?.colleges; let i = index">
            {{i + 1}}. {{ college.itemName}} <span *ngIf="college.campus">, <strong>Campus: </strong>
              {{ college.campus }}</span></li>
        </ul>
      </div>
      <div class="col-md-4 mb-3">
        <ul class="list-group">
          <li class="list-group-item list-group-item-action active">Program</li>
          <li class="list-group-item" *ngFor="let program of userStudyDetails?.programs; let i = index">
            {{i + 1}}. {{ program.itemName}}</li>
        </ul>
      </div>
      <div class="col-md-4 mb-3">
        <ul class="list-group">
          <li class="list-group-item list-group-item-action active">Degree of interest</li>
          <li class="list-group-item" *ngFor="let item of userStudyDetails?.degree; let i = index">
            {{ item.itemName}}</li>
        </ul>
      </div>
      <div class="col-md-4 mb-3" *ngIf="userStudyDetails?.currentStudy">
        <ul class="list-group">
          <li class="list-group-item list-group-item-action active">Current School/Institution/University</li>
          <li class="list-group-item">{{userStudyDetails?.currentStudy}}</li>
        </ul>
      </div>
      <div class="col-md-12 mb-3">
        <p class="note"><strong>Note: </strong> If you want change University, College or Degree. Please go to
          <a href="/profile">Profile Page</a>.</p>
        <div class="text-center mt-3">
          <button (click)="submitAdmission()" class="btn btn-primary">Confirm Selection</button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #admissionFormTemp>
    <div class="card-body">
      <h2 class="mt-2 mb-3">Fill The Universities And Colleges</h2>
      <form [formGroup]="admissionForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Are you interested in*</label>
              <angular2-multiselect (click)="onSelectInterested()" [data]="interestedList"
                class="form-control angular-selector" formControlName="interested" name="interested"
                [settings]="interestedSettings">
              </angular2-multiselect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Select Degree Type*</label>
              <angular2-multiselect [data]="degreeList" class="form-control angular-selector" formControlName="degree"
                name="degree" [settings]="degreeSettings">
                <c-item>
                  <ng-template let-item="item">
                    <label>{{item.value}}</label>
                  </ng-template>
                </c-item>
              </angular2-multiselect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="postal-code">Enter Current School/Institution/University*</label>
              <input class="form-control" type="text" name="currentStudy"
                placeholder="Enter Current School/Institution/University" formControlName="currentStudy">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Select Program* (Maximum 3)</label>
              <angular2-multiselect [data]="programsList" class="form-control angular-selector"
                formControlName="programs" name="programs" [settings]="programsSettings">
              </angular2-multiselect>
            </div>
          </div>
          <hr *ngIf="universityShow || collegesShow">
          <div class="col-md-12" *ngIf="universityShow">
            <div class="form-group">
              <label for="">Select Universities in order of preference* (Maximum 5)</label>
              <angular2-multiselect [data]="universityList" class="form-control angular-selector"
                formControlName="university" name="university" [settings]="universitySettings">
                <c-item>
                  <ng-template let-item="item">
                    <label><strong>Name: </strong> {{item.itemName}} |</label>
                    <label><strong>DLI: </strong> {{item.dli}} |</label>
                    <label *ngIf="item.campus"><strong>Campus: </strong> {{item.campus}} |</label>
                    <label><strong>City: </strong> {{item.city}} |</label>
                    <label><strong>Offers PGWP Eligible: </strong> {{item.offersPGWPEligible}} |</label>
                    <label><strong>Province: </strong> {{item.province}}</label>
                  </ng-template>
                </c-item>
              </angular2-multiselect>
            </div>
          </div>
          <div class="col-md-12" *ngIf="collegesShow">
            <div class="form-group">
              <label for="">Select Colleges* (Maximum 5)</label>
              <angular2-multiselect [data]="collegesList" class="form-control angular-selector"
                formControlName="colleges" name="colleges" [settings]="collegesSettings">
                <c-item>
                  <ng-template let-item="item">
                    <label><strong>Name: </strong> {{item.itemName}} |</label>
                    <label><strong>DLI: </strong> {{item.dli}} |</label>
                    <label *ngIf="item.campus"><strong>Campus: </strong> {{item.campus}} |</label>
                    <label><strong>City: </strong> {{item.city}} |</label>
                    <label><strong>Offers PGWP Eligible: </strong> {{item.offersPGWPEligible}} |</label>
                    <label><strong>Province: </strong> {{item.province}}</label>
                  </ng-template>
                </c-item>
              </angular2-multiselect>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group m-0 mt-3">
              <div class="checkbox checkbox-dark m-squar">
                <input id="scholarship" type="checkbox" name="scholarship" formControlName="scholarship">
                <label for="scholarship"><strong>I am interested in the Student-Stop Scholarship program. (*Terms and
                    Conditions Apply)</strong></label>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mt-2">
          <button type="submit" [disabled]="admissionForm.invalid"
            class="btn btn-square btn-primary mr-2">Submit</button>
        </div>
      </form>

    </div>
  </ng-template>
</div>
