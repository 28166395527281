<div class="card" mat-dialog-content>
  <div class="card-header">Your selected Universities and Colleges
    <button class="close" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6 mb-3" *ngIf="userStudyData?.university?.length > 0">
        <ul class="list-group">
          <li class="list-group-item list-group-item-action active">Universities (order of priority)</li>
          <li class="list-group-item" *ngFor="let university of userStudyData.university; let i = index">
            {{i + 1}}. {{ university.itemName}} <span *ngIf="university.campus">, <strong>Campus: </strong>
              {{ university.campus }}</span></li>
        </ul>
      </div>
      <div class="col-md-6 mb-3" *ngIf="userStudyData?.colleges?.length > 0">
        <ul class="list-group">
          <li class="list-group-item list-group-item-action active">Colleges (order of priority)</li>
          <li class="list-group-item" *ngFor="let college of userStudyData.colleges; let i = index">
            {{i + 1}}. {{ college.itemName}} <span *ngIf="college.campus">, <strong>Campus: </strong>
              {{ college.campus }}</span></li>
        </ul>
      </div>
      <div class="col-md-4 mb-3">
        <ul class="list-group">
          <li class="list-group-item list-group-item-action active">Programs</li>
          <li class="list-group-item" *ngFor="let program of userStudyData?.programs; let i = index">{{i + 1}}.
            {{ program.itemName}}</li>
        </ul>
      </div>
      <div class="col-md-4 mb-3">
        <ul class="list-group">
          <li class="list-group-item list-group-item-action active">Degree of interest</li>
          <li class="list-group-item" *ngFor="let item of userStudyData?.degree; let i = index">
            {{ item.itemName}}</li>
        </ul>
      </div>
      <div class="col-md-4 mb-3" *ngIf="userStudyData?.currentStudy">
        <ul class="list-group">
          <li class="list-group-item list-group-item-action active">Current School/Institution/University</li>
          <li class="list-group-item">{{userStudyData?.currentStudy}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
