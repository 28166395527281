import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-permanent-resident-documents',
  templateUrl: './permanent-resident-documents.component.html',
  styleUrls: ['./permanent-resident-documents.component.scss']
})
export class PermanentResidentDocumentsComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() { }

}
