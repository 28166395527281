<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <div class="text-center">
      <button id="oneWay" class="btn btn-square btn-primary btn-secondary mr-2" (click)="oneWay()">One
        Way</button>
      <button id="roundTrip" class="btn btn-square btn-primary" (click)="roundTrip()">Round Trip</button>
    </div>
    <form [formGroup]="submitForm" class="mt-5" novalidate>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Origin</label>
            <select class="form-control" name="origin" formControlName="origin">
              <option value="">Select Origin</option>
              <option [value]="origin" *ngFor="let origin of originList">{{origin}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Destination</label>
            <select class="form-control" name="destination" formControlName="destination">
              <option value="">Select Destination</option>
              <option [value]="destination" *ngFor="let destination of destinationList">
                {{destination}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Departure date*</label>
            <input class="form-control digits" type="text" matInput [matDatepicker]="picker" name="depart"
              placeholder="Departure Date" formControlName="depart" (dateChange)="depart()" [min]="tomorrow">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Return date</label>
            <input class="form-control digits" type="text" matInput [matDatepicker]="picker2" name="return"
              placeholder="Return Date" formControlName="return" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mt-3">
            <label for="">Class</label>
            <select class="form-control" name="class" formControlName="class">
              <option value="">Select Class</option>
              <option value="Economy Class">Economy Class</option>
              <option value="Business Class">Business Class</option>
              <option value="First Class">First Class</option>
            </select>
          </div>
        </div>
        <div class="col-md-9">
          <div class="form-group mt-3">
            <label>Note (Any important information e.g Stop Over/Preferences-Route/Airline/Duration/Direct
              etc.)</label>
            <textarea placeholder="Note" class="form-control" name="note" formControlName="note" required></textarea>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group m-0 mt-3">
            <div class="checkbox checkbox-dark m-squar">
              <input id="insurance" type="checkbox" name="insurance" formControlName="insurance">
              <label for="insurance"><strong>Would you like to register for Overseas Travel Insurance for
                  Students? (* Highly Recommended)</strong></label>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-4">
        <button [disabled]="submitForm.invalid" (click)="submitService()"
          class="btn btn-square btn-primary">Proceed</button>
      </div>
    </form>
  </div>
</div>

<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <h3 class="my-3">Insurance</h3>

    <div class="faq-wrapper">
      <ngb-accordion class="mb-0" #a="ngbAccordion">
        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                Important Info About Overseas Travel Insurance for Students <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>Student medical insurance is a mandatory requirement at most foreign universities/colleges.
              Some institutions will provide their insurance plan. However,
              it is recommended that you get a health insurance from your home country and
              apply for an insurance waiver because it is cheaper and provides the same coverage (and sometimes
              more) at a much lower cost.
            </p>

            <ul>
              <li>Usual coverage options can include:
                <ul>
                  <li>Hospitalization</li>
                  <li>Medical evacuation</li>
                  <li>Repatriation</li>
                  <li>Bail bond</li>
                  <li>Trip cancellation interruption and delay</li>
                  <li>Emergency medical treatment or evacuation</li>
                </ul>
              </li>
              <li>One major issue when it comes to insurance is finding a trustworthy agent and plan. We take
                this worry away from you
                as our experts will guide you through the process and help you find the best and most suitable
                plan for your requirement.
              </li>

              <li>Why should you get travel insurance?
                <ul>
                  <li>International travel accidents/health issues can lead to major bills that can be
                    unaffordable for many</li>
                  <li>It is cheap and can save a lot of headaches</li>
                </ul>
              </li>
            </ul>
            <p><strong>Partial Source: ICICI Lombard</strong></p>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>

  </div>
</div>
