<div class="dashboard-box">

  <app-navbar></app-navbar>

  <app-sidebar></app-sidebar>

  <div class="dashboard-body">
    <div class="container-fluid">
      <div class="page-header banking">
        <div class="row">
          <div class="col-lg-12 main-header">
            <h2>Banking Loan</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="top-box">

        <div class="card wow animate__animated animate__fadeInUp">
          <div class="card-header">
            <h3 class="card-title">Banking Loan</h3>
          </div>
          <div class="card-body">
            <mat-horizontal-stepper class="full-width" linear #stepper
              ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}" labelPosition="bottom">
              <mat-step [stepControl]="personalform">
                <form [formGroup]="personalform">
                  <ng-template matStepLabel>Personal Details</ng-template>
                  <div class="row">
                    <div class="col-md-12">
                      <label>Your Name:</label>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="prefix">Prefix*</label>
                        <select class="form-control" name="prefix" placeholder="Select Prefix" formControlName="prefix">
                          <option value="" disabled>Select Prefix</option>
                          <option value="Mr">Mr</option>
                          <option value="Ms">Ms</option>
                          <option value="Mrs">Mrs</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="first-name">First*</label>
                        <input class="form-control" type="text" name="firstname" placeholder="First Name"
                          formControlName="firstname">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="middle-name">Middle</label>
                        <input class="form-control" type="text" name="middlename" placeholder="Middle Name"
                          formControlName="middlename">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="last-name">Last*</label>
                        <input class="form-control" type="text" name="lastname" placeholder="Last Name"
                          formControlName="lastname">
                      </div>
                    </div>

                    <div class="col-md-12">
                      <hr class="divider">
                    </div>

                    <div class="col-md-12">
                      <label>Father’s / Spouse Name:</label>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="prefix">Prefix*</label>
                        <select class="form-control" name="prefix" placeholder="Select Prefix" formControlName="prefix">
                          <option value="" disabled>Select Prefix</option>
                          <option value="Mr">Mr</option>
                          <option value="Ms">Ms</option>
                          <option value="Mrs">Mrs</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="first-name">First*</label>
                        <input class="form-control" type="text" name="firstname" placeholder="First Name"
                          formControlName="firstname">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="middle-name">Middle</label>
                        <input class="form-control" type="text" name="middlename" placeholder="Middle Name"
                          formControlName="middlename">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="last-name">Last*</label>
                        <input class="form-control" type="text" name="lastname" placeholder="Last Name"
                          formControlName="lastname">
                      </div>
                    </div>


                    <div class="col-md-12">
                      <hr class="divider">
                    </div>
                    <div class="col-md-12">
                      <label>Mother’s Name:</label>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="prefix">Prefix*</label>
                        <select class="form-control" name="prefix" placeholder="Select Prefix" formControlName="prefix">
                          <option value="" disabled>Select Prefix</option>
                          <option value="Mr">Mr</option>
                          <option value="Ms">Ms</option>
                          <option value="Mrs">Mrs</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="first-name">First*</label>
                        <input class="form-control" type="text" name="firstname" placeholder="First Name"
                          formControlName="firstname">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="middle-name">Middle</label>
                        <input class="form-control" type="text" name="middlename" placeholder="Middle Name"
                          formControlName="middlename">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="last-name">Last*</label>
                        <input class="form-control" type="text" name="lastname" placeholder="Last Name"
                          formControlName="lastname">
                      </div>
                    </div>


                    <div class="col-md-12">
                      <hr class="divider">
                    </div>
                    <div class="col-md-12">
                      <label>Applicant’s Maiden Name:</label>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="prefix">Prefix*</label>
                        <select class="form-control" name="prefix" placeholder="Select Prefix" formControlName="prefix">
                          <option value="" disabled>Select Prefix</option>
                          <option value="Mr">Mr</option>
                          <option value="Ms">Ms</option>
                          <option value="Mrs">Mrs</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="first-name">First*</label>
                        <input class="form-control" type="text" name="firstname" placeholder="First Name"
                          formControlName="firstname">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="middle-name">Middle</label>
                        <input class="form-control" type="text" name="middlename" placeholder="Middle Name"
                          formControlName="middlename">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="last-name">Last*</label>
                        <input class="form-control" type="text" name="lastname" placeholder="Last Name"
                          formControlName="lastname">
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="dob">Date Of Birth*</label>
                        <input class="form-control digits" type="text" matInput [matDatepicker]="picker" name="dob"
                          placeholder="Date Of Birth" formControlName="dob" [max]="todayDate" (click)="picker?.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="gender">Select Gender*</label>
                        <select class="form-control" name="gender" placeholder="Select Gender" formControlName="gender">
                          <option value="" disabled>Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Transgender">Transgender</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="maritalstatus">Marital Status*</label>
                        <select class="form-control" name="maritalstatus" placeholder="Select Marital Status"
                          formControlName="maritalstatus">
                          <option value="" disabled>Select Marital Status</option>
                          <option value="Married">Married</option>
                          <option value="Single">Single</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="maritalstatus">Education*</label>
                        <select class="form-control" name="maritalstatus" placeholder="Select Education"
                          formControlName="maritalstatus">
                          <option value="" disabled>Select Education</option>
                          <option value="Post Graduate">Post Graduate</option>
                          <option value="Professional">Professional</option>
                          <option value="Diploma Holder">Diploma Holder</option>
                          <option value="Graduate">Graduate</option>
                          <option value="Under Graduate/ Matriculate">Under Graduate/ Matriculate</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="maritalstatus">Customer Category*</label>
                        <select class="form-control" name="maritalstatus" placeholder="Select Customer Category"
                          formControlName="maritalstatus">
                          <option value="" disabled>Select Customer Category</option>
                          <option value="General">General</option>
                          <option value="SC">SC</option>
                          <option value="ST">ST</option>
                          <option value="OBC">OBC</option>
                          <option value="MBC">MBC</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="maritalstatus">Religion*</label>
                        <select class="form-control" name="maritalstatus" placeholder="Select Religion"
                          formControlName="maritalstatus">
                          <option value="" disabled>Select Religion</option>
                          <option value="Hindu">Hindu</option>
                          <option value="Muslim">Muslim</option>
                          <option value="Sikh">Sikh</option>
                          <option value="Zoroastrian">Zoroastrian</option>
                          <option value="Christianity">Christianity</option>
                          <option value="Buddhist">Buddhist</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="address">Enter Current Address*</label>
                        <input class="form-control" type="text" name="address" placeholder="Enter Current Address"
                          formControlName="address">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="country">Country*</label>
                        <select class="form-control" name="country" placeholder="Select Country"
                          formControlName="country" (click)="onChanges()">
                          <option value="" selected disabled>Select Country</option>
                          <option *ngFor="let country of countryList" [value]="country.itemName">
                            {{country.itemName}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="state">State*</label>
                        <select class="form-control" name="state" placeholder="Select State" formControlName="state">
                          <option value="" disabled selected>Select State</option>
                          <option *ngFor="let state of stateList" [value]="state.itemName">
                            {{state.itemName}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="postal-code">Postal Code*</label>
                        <input class="form-control" type="number" name="postalcode" placeholder="Postal Code"
                          formControlName="postalcode">
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="email">Email*</label>
                        <input class="form-control" type="email" name="email" placeholder="Email"
                          formControlName="email" readonly>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="phone">Phone*</label>
                        <input class="form-control" type="tel" name="phone" placeholder="Phone" formControlName="phone">
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="maritalstatus">Location Category*</label>
                        <select class="form-control" name="maritalstatus" placeholder="Select Location Category"
                          formControlName="maritalstatus">
                          <option value="" disabled>Select Location Category</option>
                          <option value="Rural">Rural</option>
                          <option value="Semi-urban">Semi-urban</option>
                          <option value="Urban">Urban</option>
                          <option value="Metro">Metro</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="maritalstatus">Residential status*</label>
                        <select class="form-control" name="maritalstatus" placeholder="Select Residential status"
                          formControlName="maritalstatus">
                          <option value="" disabled>Select Residential status</option>
                          <option value="Resident Individua">Resident Individua</option>
                          <option value="NRI">NRI</option>
                          <option value="PIO">PIO</option>
                          <option value="Foreign National">Foreign National</option>
                        </select>
                      </div>
                    </div>

                  </div>
                  <div>
                    <div class="text-center mt-2">
                      <button [disabled]="personalform.invalid" class="btn btn-primary btn-next"
                        matStepperNext>Next</button>
                    </div>
                  </div>
                </form>
              </mat-step>
              <mat-step [stepControl]="courseform">
                <form [formGroup]="personalform">
                  <ng-template matStepLabel>Course Details</ng-template>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="first-name">Institution/ University(N)*</label>
                        <input class="form-control" type="text" name="firstname"
                          placeholder="Institution/ University(N)" formControlName="firstname">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="middle-name">Name of the Course</label>
                        <input class="form-control" type="text" name="middlename" placeholder="Name of the Course"
                          formControlName="middlename">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="prefix">Duration of Course*</label>
                        <select class="form-control" name="prefix" placeholder="Select Duration of Course"
                          formControlName="prefix">
                          <option value="" disabled>Select Duration of Course</option>
                          <option value="6 Month">6 Month</option>
                          <option value="1 Year">1 Year</option>
                          <option value="1.6 Year">1.6 Year</option>
                          <option value="2 Year">2 Year</option>
                          <option value="3 Year">3 Year</option>
                          <option value="5 Year">5 Year</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="country">Country*</label>
                        <select class="form-control" name="country" placeholder="Select Country"
                          formControlName="country" (click)="onChanges()">
                          <option value="" selected disabled>Select Country</option>
                          <option *ngFor="let country of countryList" [value]="country.itemName">
                            {{country.itemName}}</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="dob">Date of commencement of course*</label>
                        <input class="form-control digits" type="text" matInput [matDatepicker]="picker" name="dob"
                          placeholder="Date of commencement of course" formControlName="dob" [max]="todayDate"
                          (click)="picker?.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="gender">Level of Course*</label>
                        <select class="form-control" name="gender" placeholder="Level of Course"
                          formControlName="gender">
                          <option value="" disabled>Level of Course</option>
                          <option value="Graduate">Graduate</option>
                          <option value="Post Graduate">Post Graduate</option>
                          <option value="Professional">Professional</option>
                          <option value="Diploma">Diploma</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="gender">Course Approved by*</label>
                        <select class="form-control" name="gender" placeholder="Course Approved by"
                          formControlName="gender">
                          <option value="" disabled>Course Approved by</option>
                          <option value="UGC">UGC</option>
                          <option value="Govt">Govt</option>
                          <option value="AICTE">AICTE</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="gender">Course Stream*</label>
                        <select class="form-control" name="gender" placeholder="Course Stream" formControlName="gender">
                          <option value="" disabled>Course Stream</option>
                          <option value="Medical">Medical</option>
                          <option value="Engineering">Engineering</option>
                          <option value="Management">Management</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="gender">Type of course*</label>
                        <select class="form-control" name="gender" placeholder="Type of course"
                          formControlName="gender">
                          <option value="" disabled>Type of course</option>
                          <option value="Part time">Part time</option>
                          <option value="Full time">Full time</option>
                          <option value="Executive">Executive</option>
                        </select>
                      </div>
                    </div>

                  </div>

                  <div>
                    <div class="text-center mt-2">
                      <button [disabled]="personalform.invalid" class="btn btn-primary btn-next"
                        matStepperNext>Next</button>
                    </div>
                  </div>
                </form>
              </mat-step>
              <mat-step [stepControl]="educationform">
                <ng-template matStepLabel>Education Loan Details</ng-template>

                <form [formGroup]="personalform">

                  <h3>COURSE COST DETAILS</h3>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first-name">Tuition Fees</label>
                        <input class="form-control" type="number" name="firstname" placeholder="Tuition Fees"
                          formControlName="firstname">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first-name">Examination Fees</label>
                        <input class="form-control" type="number" name="firstname" placeholder="Examination Fees"
                          formControlName="firstname">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first-name">Cost of Study Materials</label>
                        <input class="form-control" type="number" name="firstname" placeholder="Cost of Study Materials"
                          formControlName="firstname">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first-name">Traveling Expenses</label>
                        <input class="form-control" type="number" name="firstname" placeholder="Traveling Expenses"
                          formControlName="firstname">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first-name">Others if any</label>
                        <input class="form-control" type="number" name="firstname" placeholder="Others if any"
                          formControlName="firstname">
                      </div>
                    </div>
                  </div>

                  <hr>

                  <h3>FUNDS AVAILABLE DETAILS</h3>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first-name">Own Source</label>
                        <input class="form-control" type="number" name="firstname" placeholder="Own Source"
                          formControlName="firstname">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first-name">Scholarship</label>
                        <input class="form-control" type="number" name="firstname" placeholder="Scholarship"
                          formControlName="firstname">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first-name">Others</label>
                        <input class="form-control" type="number" name="firstname" placeholder="Others
                            " formControlName="firstname">
                      </div>
                    </div>
                  </div>
                </form>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>Applicant Occupational Details</ng-template>
              </mat-step>
            </mat-horizontal-stepper>
          </div>
        </div>

      </div>
    </div>
    <app-site-footer></app-site-footer>
  </div>
</div>
