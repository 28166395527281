import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppServicesService } from 'src/app/services/app-services.service';
import { TravelService } from 'src/app/services/travel.service';
import { SelectOptionData } from 'src/app/sharedata/selectOptionData';
import * as $ from 'jquery';

@Component({
  selector: 'app-travel-form-submit',
  templateUrl: './travel-form-submit.component.html',
  styleUrls: ['./travel-form-submit.component.scss']
})
export class TravelFormSubmitComponent implements OnInit {

  serviceModel: any = {
    confirm: ''
  };
  ticketHasError = true;

  insurance: any = {
    _id: null,
    wantInsurance: null,
  }
  isShown = false;
  insuranceOpen = false;
  cadValue = false;
  statusHasError = true;

  submitForm: FormGroup;

  originList;
  destinationList;

  tomorrow = new Date();
  minDate = new Date();

  constructor(
    private _travel: TravelService,
    private _appServices: AppServicesService
  ) { }

  ngOnInit(): void {
    const serviceData = new SelectOptionData;

    this.originList = serviceData.originList();
    this.destinationList = serviceData.destinationList();

    this.tomorrow.setDate(this.tomorrow.getDate() + 1);

    this.submitForm = new FormGroup({
      'origin': new FormControl('', Validators.required),
      'destination': new FormControl(''),
      'return': new FormControl({ value: '', disabled: true }, Validators.required),
      'depart': new FormControl('', [Validators.required]),
      'class': new FormControl('', [Validators.required]),
      'note': new FormControl('', [Validators.required]),
      'insurance': new FormControl('', [Validators.required])
    });
  }

  submitService() {
    this._travel.travel(this.submitForm.value).subscribe((res) => {
      window.location.reload()
    });
  }

  roundTrip() {
    this.submitForm.controls.return.enable();
    $('#oneWay').removeClass('btn-secondary');
    $('#roundTrip').addClass('btn-secondary');
  }

  oneWay() {
    this.submitForm.controls.return.disable();
    $('#roundTrip').removeClass('btn-secondary');
    $('#oneWay').addClass('btn-secondary');
  }

  validateStatus(value) {
    if (value === 'default') {
      this.statusHasError = true;
    } else if (value === 'No') {
      this.statusHasError = true;
    } else {
      this.statusHasError = false;
    }
  }

  changeData(value: string) {
    switch (value) {
      case "Yes":
        this.isShown = true;
        this.insuranceOpen = true;
        break;
      case "No":
        this.isShown = false;
        this.insuranceOpen = true;
        break;
      case "default":
        this.isShown = false;
        this.insuranceOpen = false;
        break;
      default:
        this.isShown = true;
        this.insuranceOpen = false;
        break;
    }
  }


  analyse() {
    this._appServices.analyseInsurence();
  }

  depart() {
    this.minDate = this.submitForm.value.depart;
  }
}
