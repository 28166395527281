<div class="dashboard-box">

  <app-navbar></app-navbar>
  <app-sidebar></app-sidebar>

  <div class="dashboard-body">
    <div class="container-fluid">
      <div class="page-header study-permit">
        <div class="row">
          <div class="col-lg-12 main-header">
            <h2>Study Permit</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="top-box">
        <div class="card wow animate__animated animate__fadeInUp" *ngIf="step !== 6">
          <div class="card-body">
            <div class="step-wrapper">
              <div class="md-stepper-horizontal orange">
                <div class="md-step active">
                  <div class="md-step-circle"><span>1</span></div>
                  <div class="md-step-title">Important Information</div>
                  <div class="md-step-bar-left"></div>
                  <div class="md-step-bar-right"></div>
                </div>
                <div class="md-step" [class.active]="step >= 1">
                  <div class="md-step-circle"><span>2</span></div>
                  <div class="md-step-title">Documents Required (Upload)</div>
                  <div class="md-step-bar-left"></div>
                  <div class="md-step-bar-right"></div>
                </div>
                <div class="md-step" [class.active]="step >= 2">
                  <div class="md-step-circle"><span>3</span></div>
                  <div class="md-step-title">Fill Form Details</div>
                  <div class="md-step-bar-left"></div>
                  <div class="md-step-bar-right"></div>
                </div>
                <div class="md-step" [class.active]="step >= 3">
                  <div class="md-step-circle"><span>4</span></div>
                  <div class="md-step-title">SOP Support Questions</div>
                  <div class="md-step-bar-left"></div>
                  <div class="md-step-bar-right"></div>
                </div>
                <div class="md-step" [class.active]="step >= 4">
                  <div class="md-step-circle"><span>5</span></div>
                  <div class="md-step-title">Service Selection</div>
                  <div class="md-step-bar-left"></div>
                  <div class="md-step-bar-right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-study-permit-info *ngIf="step === 0"></app-study-permit-info>
        <app-study-permit-documents *ngIf="step === 1"></app-study-permit-documents>
        <app-study-permit-form-step [profile]="profile" *ngIf="step === 2"></app-study-permit-form-step>
        <app-study-permit-questions *ngIf="step === 3"></app-study-permit-questions>
        <app-study-permit-services [profile]="profile" *ngIf="step === 4"></app-study-permit-services>
        <app-study-permit-status [studyPermitService]="studyPermitService" *ngIf="step === 6"></app-study-permit-status>
      </div>
    </div>
    <app-site-footer></app-site-footer>
  </div>
</div>
