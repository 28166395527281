<app-site-header></app-site-header>

<section class="banner">
  <div class="container">
    <div class="banner-content">
      <div class="row">
        <div class="col-sm-7">
          <div class="content">
            <h1 class="wow animate__animated animate__zoomInDown">Hello Students, <br> Canada Welcomes You</h1>
            <p class="wow animate__animated animate__fadeInUp">AVAILABLE NOW:<br> A selection of programs to apply
              from! Register and Apply now. </p>
            <p class="wow animate__animated animate__fadeInUp animate__delay-1s">We take you through your complete
              journey, support you in all your needs including admission, student loans,
              immigration, housing, travel, food, jobs and a lot more, through a fast and simple process. </p>
            <a href="/register" class="btn wow animate__animated animate__fadeInUp animate__delay-2s">Register
              Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="services-section">

  <div class="moving-airplane">
    <img src="/assets/images/airplane-image.png" alt="Airplane Image">
  </div>

  <div class="moving-airplane-2">
    <img src="/assets/images/airplane-image-2.png" alt="Airplane Image">
  </div>

  <div class="container">

    <div class="sec-title text-center">
      <div class="title wow animate__animated animate__fadeIn">Our Services</div>
      <div class="separator center"><span></span></div>
      <h2 class="wow animate__animated animate__fadeIn">We Make Your Dream A Reality</h2>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-6 box-div wow animate__animated animate__fadeInUp">
        <div class="service-box">
          <div class="image-box-wrap">
            <div class="icon-div">
              <i class="fi flaticon-guarantee"></i>
            </div>
            <div class="content">
              <h3 class="heading">Admission Support </h3>
              <p>We support you in preparing your University and college applications. We also
                help with admission in our partner universites/colleges. </p>
              <div class="circle-arrow">
                <div class="middle-dot"></div>
                <div class="middle-dot dot-2"></div>
                <a href="/services/admission">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 box-div wow animate__animated animate__fadeInUp">
        <div class="service-box">
          <div class="image-box-wrap">
            <div class="icon-div">
              <i class="fi flaticon-content"></i>
            </div>
            <div class="content">
              <h3 class="heading">Immigration/Study Permit</h3>
              <p>We offer legal support to prepare and submit your study permit application. We ensure that everything
                is in order before you depart.</p>
              <div class="circle-arrow">
                <div class="middle-dot"></div>
                <div class="middle-dot dot-2"></div>
                <a href="/services/study-permit">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 box-div wow animate__animated animate__fadeInUp">
        <div class="service-box">
          <div class="image-box-wrap">
            <div class="icon-div">
              <i class="fi flaticon-email"></i>
            </div>
            <div class="content">
              <h3 class="heading">Banking & Finance</h3>
              <p>We support you in opening a Canadian Bank account for your GIC requirement & arrange study loans
                before you arrive in Canada.</p>
              <div class="circle-arrow">
                <div class="middle-dot"></div>
                <div class="middle-dot dot-2"></div>
                <a href="/services/banking">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 box-div wow animate__animated animate__fadeInUp">
        <div class="service-box">
          <div class="image-box-wrap">
            <div class="icon-div">
              <i class="fi flaticon-aim"></i>
            </div>
            <div class="content">
              <h3 class="heading">Food</h3>
              <p>Getting your favourite food is not a hassle anymore. Register for our meal plan available in selected
                cities and get excellent quality food of your choice.</p>
              <div class="circle-arrow">
                <div class="middle-dot"></div>
                <div class="middle-dot dot-2"></div>
                <a href="/services/food">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 box-div wow animate__animated animate__fadeInUp">
        <div class="service-box">
          <div class="image-box-wrap">
            <div class="icon-div">
              <i class="fi flaticon-paper-plane"></i>
            </div>
            <div class="content">
              <h3 class="heading">Travel</h3>
              <p>Arrange your flights and local transportation making your arrival in Canada a very smooth and
                comfortable experience.</p>
              <div class="circle-arrow">
                <div class="middle-dot"></div>
                <div class="middle-dot dot-2"></div>
                <a href="/services/travel">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 box-div wow animate__animated animate__fadeInUp">
        <div class="service-box">
          <div class="image-box-wrap">
            <div class="icon-div">
              <i class="fi flaticon-house"></i>
            </div>
            <div class="content">
              <h3 class="heading">Housing</h3>
              <p>We help you find comfortable housing near to campus to make your daily commute easy and guarantee
                yourself a place to stay.</p>
              <div class="circle-arrow">
                <div class="middle-dot"></div>
                <div class="middle-dot dot-2"></div>
                <a href="/services/housing">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 box-div wow animate__animated animate__fadeInUp">
        <div class="service-box">
          <div class="image-box-wrap">
            <div class="icon-div">
              <i class="fi flaticon-work"></i>
            </div>
            <div class="content">
              <h3 class="heading">Student Job</h3>
              <p>Want to work part time to meet your expenses or just want to get some work experience? We use our
                network to bring student work opportunities during
                and post-academic completion.</p>
              <div class="circle-arrow">
                <div class="middle-dot"></div>
                <div class="middle-dot dot-2"></div>
                <a href="/services/student-job">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 box-div wow animate__animated animate__fadeInUp">
        <div class="service-box">
          <div class="image-box-wrap">
            <div class="icon-div">
              <i class="fas fa-notes-medical"></i>
            </div>
            <div class="content">
              <h3 class="heading">Medical Insurance</h3>
              <p>Medical insurance is a necessity when travelling abroad. In case of an emergency, medical expenses
                can be extremely high. Register and let us take care of this requirement. </p>
              <div class="circle-arrow">
                <div class="middle-dot"></div>
                <div class="middle-dot dot-2"></div>
                <a href="/services/medical-insurance">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 box-div wow animate__animated animate__fadeInUp">
        <div class="service-box">
          <div class="image-box-wrap">
            <div class="icon-div">
              <i class="fi flaticon-visibility"></i>
            </div>
            <div class="content">
              <h3 class="heading">Important Information</h3>
              <p>On <strong>Student-Stop</strong>, we provide you with all the important information, that will
                prepare you for your
                stay in Canada. Click here to check out a snapshot of general important information.</p>
              <div class="circle-arrow">
                <div class="middle-dot"></div>
                <div class="middle-dot dot-2"></div>
                <a href="/services/important-information">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<section class="call-to-section">
  <div class="container">
    <div class="content">
      <span class="sub-title wow animate__animated animate__lightSpeedInRight">Hurry and Sign Up Now!</span>
      <h2 class="wow animate__animated animate__fadeInUp">Studying abroad has never been easier</h2>
      <div class="link-box">
        <a href="/register" class="cta-btn wow animate__animated animate__zoomIn animate__delay-1s">Get Started</a>
        <a href="/contact-us" class="cta-btn-2 wow animate__animated animate__zoomIn animate__delay-1s">Contact Us</a>
      </div>
    </div>
  </div>
</section>

<section class="country-section">
  <div class="country-section-contents">
    <div class="container text-center">
      <div class="sec-title  mt-5 mb-3">
        <h2 class="wow animate__animated animate__fadeIn">Supporting international students from around the world in
          their journey to Canada:</h2>
        <div class="separator center"><span></span></div>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <div class="logo-grid">
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/gate-of-india.png" alt="India">
            <img class="hover" src="/assets/images/country-icons/gate-of-india-white.png" alt="India">
            <h3>India</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/united-arab-emirates.png" alt="UAE">
            <img class="hover" src="/assets/images/country-icons/united-arab-emirates-white.png" alt="UAE">
            <h3>UAE</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/sri-lanka.png" alt="Sri Lanka">
            <img class="hover" src="/assets/images/country-icons/sri-lanka-white.png" alt="Sri Lanka">
            <h3>Sri Lanka</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/nepal.png" alt="Nepal">
            <img class="hover" src="/assets/images/country-icons/nepal-white.png" alt="Nepal">
            <h3>Nepal</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/bangladesh.png" alt="Bangladesh">
            <img class="hover" src="/assets/images/country-icons/bangladesh-white.png" alt="Bangladesh">
            <h3>Bangladesh</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/singapore.png" alt="Singapore">
            <img class="hover" src="/assets/images/country-icons/singapore-white.png" alt="Singapore">
            <h3>Singapore</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/germany.png" alt="Germany">
            <img class="hover" src="/assets/images/country-icons/germany-white.png" alt="Germany">
            <h3>Germany</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/brazil.png" alt="brazil">
            <img class="hover" src="/assets/images/country-icons/brazil-white.png" alt="brazil">
            <h3>Brazil</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/vietnam.png" alt="vietnam">
            <img class="hover" src="/assets/images/country-icons/vietnam-white.png" alt="vietnam">
            <h3>Vietnam</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/philippines.png" alt="Philippines">
            <img class="hover" src="/assets/images/country-icons/philippines-white.png" alt="Philippines">
            <h3>Philippines</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/iran.png" alt="Iran">
            <img class="hover" src="/assets/images/country-icons/iran-white.png" alt="Iran">
            <h3>Iran</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/nigeria.png" alt="Nigeria">
            <img class="hover" src="/assets/images/country-icons/nigeria-white.png" alt="Nigeria">
            <h3>Nigeria</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/ukraine.png" alt="Ukraine">
            <img class="hover" src="/assets/images/country-icons/ukraine-white.png" alt="Ukraine">
            <h3>Ukraine</h3>
          </a>
        </div>
      </div>
      <div class="logo mb-3">
        <div class="country-section-card">
          <a href="/register">
            <img class="nonhover" src="/assets/images/country-icons/trinidad-and-tobago.png" alt="Trinidad And Tobago">
            <img class="hover" src="/assets/images/country-icons/trinidad-and-tobago-white.png"
              alt="Trinidad And Tobago">
            <h3>Trinidad & Tobago</h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="scholarship-section">
  <div class="container">
    <div class="row">
      <div class="col-md-4 d-flex align-items-end">
        <div class="scholarship-section-img">
          <img src="/assets/images/scholarship-bg.png" alt="scholarship">
        </div>
      </div>
      <div class="col-md-8">
        <div class="scholarship-detail-box">
          <div class="scholarship-section-detail">
            <h2 class="heading">Student-Stop is offering Scholarships of up to C$1000</h2>
            <p class="scholarship-sub-text">We are excited to announce the availability of scholarships for
              international Student-Stop students in Canada. Scholarships of up to C$1000 available to support with your
              international student requirements, once you are in Canada. We know that funds matter when you are away
              from home. Register now and check if you are eligible.</p>
            <div class="find-more">
              <a href="/register" class="btn btn-primary">Register Now</a>
              <p class="dark-text">* Terms and Conditions Apply</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-why">
  <div class="container">
    <div class="sec-title text-center">
      <div class="title wow animate__animated animate__fadeIn">Canada</div>
      <div class="separator center"><span></span></div>
      <h2 class="wow animate__animated animate__fadeIn">Why study in Canada?</h2>
    </div>
    <div class="row">
      <div class="col-md-7">
        <div class="why-row">
          <ul>
            <li>
              <div class="why-item">
                <div class="why-head">
                  <div class="el-icon"><i class="fas fa-rocket"></i></div>
                  <h3 class="wow animate__animated animate__fadeInRight">Best country in the world</h3>
                </div>
                <div class="why-body">
                  <p class="wow animate__animated animate__fadeIn animate__delay-1s">Canada has been ranked as the
                    best country in the world for quality of life by US News. Canada is multicultural, safe and has
                    tremendous career opportunities with excellent work-life balance. Thats why most millenials prefer
                    moving to Canada. </p>
                </div>
              </div>
            </li>
            <li>
              <div class="why-item">
                <div class="why-head">
                  <div class="el-icon">
                    <<i class="far fa-gem"></i>
                  </div>
                  <h3 class="wow animate__animated animate__fadeInRight">Excellent Education</h3>
                </div>
                <div class="why-body">
                  <p class="wow animate__animated animate__fadeIn animate__delay-1s">With some of the best
                    universities/colleges in the world, Education in Canada is considered to be of the highest
                    quality. Did you know, Medical Insulin discovery, Ebola vaccine, IMAX movie system, Blackberry
                    phone, Java programming language, Cardiac pacemakers, Sonar and a lots of other technologies are
                    Canadian inventions. </p>
                </div>
              </div>
            </li>
            <li>
              <div class="why-item">
                <div class="why-head">
                  <div class="el-icon"><i class="fas fa-briefcase"></i></div>
                  <h3 class="wow animate__animated animate__fadeInRight">Work</h3>
                </div>
                <div class="why-body">
                  <p class="wow animate__animated animate__fadeIn animate__delay-1s">Canada is one of the best places
                    to work. Whether you are looking for a job or want to start your business after your education,
                    Canada is the place. With the growing economy, the job market is looking for young and educated
                    individuals. For people looking to start their business, there is immense support from the
                    Government to make sure your business is successful.</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-5 no-padding my-auto">
        <img class="wow animate__animated animate__jackInTheBox animate__delay-1s" src="assets/images/combined.png"
          alt="">
        <img class="mt-4 wow animate__animated animate__jackInTheBox animate__delay-1s" src="assets/images/banff.png"
          alt="">
      </div>
    </div>
  </div>
</section>

<section class="testimonials">
  <div class="container">
    <div class="row">
      <div class="col-xl-4">
        <div class="client-review-left">
          <div class="section-title text-left">
            <h2 class="section-title__title">What our users have to say</h2>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="client-review-right">
          <div class="client-review-carousel">
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide>
                <div class="client-review-single">
                  <p class="client-review-text">
                    I am Harshavardhini from India. I filed my student visa to pursue a Masters program at UBC,
                    Vancouver. Student-Stop guided me through the entire process and they responded to my every
                    queries. They have been a great support and helped me to secure my student visa on time which will
                    help me further to attend my classes as planned. Those who are facing issues with their study
                    permit application should definitely reach them as they are the best in the field.
                    <br>
                    @Student stop, Keep doing your good work!
                  </p>
                  <div class="client-review-client-info">
                    <h5 class="client-review-client-name">Harshavardhini</h5>
                  </div>
                  <div class="client-review-quote">
                    <img src="/assets/images/client-review-img.png" alt="">
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="client-review-single">
                  <p class="client-review-text">
                    I would like to mention that I had an excellent experience with Student-Stop.
                    Looking for my Accommodation and Travel, I found Student-Stop. They were very honest and helpful
                    to me during this COVID times, where most of the Agents and Private Community are taking advantage
                    of Students situation. Student-Stop came as a helping hand to me. <br>
                    Special mention to Siddharth Gautam, honest, informative and very much helpful. Total Respect
                    Siddharth, bless you man.
                  </p>
                  <div class="client-review-client-info">
                    <h5 class="client-review-client-name">Bhavin V</h5>
                  </div>
                  <div class="client-review-quote">
                    <img src="/assets/images/client-review-img.png" alt="">
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="location-section">
  <div class="container">
    <div class="row mb-5">
      <div class="col-md-12 text-center">
        <h2 class="heading">Now located in the University of Toronto</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="location-section-detail">
          <img src="/assets/images/university-of-toronto.jpg" alt="location">
        </div>
      </div>
      <div class="col-md-4">
        <div class="location-section-detail-box">
          <h3>Address</h3>
          <p><strong>St. George Campus:</strong> Suite 331, 60 St. George Street, McLennan Physics Laboratories,
            University of Toronto, Toronto, ON, M5S 1A7, Canada</p>
          <hr class="my-2">
          <p><strong>Mississauga Campus:</strong> 3359 Mississauga Rd, ICUBE, University of Toronto (Mississauga
            Campus), Mississauga, ON L5L 1C6, Canada</p>
          <a href="/contact-us" class="btn btn-primary mt-3">Contact Us</a>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="partner-section">
  <div class="container">
    <div class="sec-title text-center">
      <div class="title wow animate__animated animate__fadeIn">Our Partners</div>
      <div class="separator center"><span></span></div>
    </div>
    <div class="row">
      <div class="col-md-6 text-center">
        <h2 class="wow animate__animated animate__fadeIn">Banking and Finance Partner</h2>
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8 logo">
            <img src="/assets/images/icici-bank-logo-full.jpg" alt="ICICI Bank">
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
      <div class="col-md-6 text-center">
        <h2 class="wow animate__animated animate__fadeIn">Wireless Plans Partner</h2>
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8 logo">
            <img src="/assets/images/mobilebox-logo-full.jpg" alt="Mobile Box">
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="partner-section">
  <div class="container">
    <div class="sec-title text-center">
      <div class="title wow animate__animated animate__fadeIn">Our Partners</div>
      <div class="separator center"><span></span></div>
    </div>
    <div class="custom-carousel-btn text-center">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
          <h2 class="wow animate__animated animate__fadeIn">Banking and Finance Partner</h2>
          <div class="row mt-4">
            <div class="col-md-4"></div>
            <div class="col-md-4 logo">
              <img src="/assets/images/icici-bank-logo-full.jpg" alt="ICICI Bank">
            </div>
            <div class="col-md-4"></div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <h2 class="wow animate__animated animate__fadeIn">Wireless Plans</h2>
          <p>Get your Rogers Sim card before you fly on Student-Stop through:</p>
          <div class="row mt-4">
            <div class="col-md-4"></div>
            <div class="col-md-4 logo">
              <img src="/assets/images/mobilebox-logo-full.jpg" alt="Mobile Box">
            </div>
            <div class="col-md-4"></div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>


<section class="toronto-partner-section">
  <div class="container">
    <div class="sec-title text-center mb-3">
      <div class="title wow animate__animated animate__fadeIn">A proud member of ICUBE Venture Forward</div>
      <div class="separator center"><span></span></div>
      <!-- <h2 class="wow animate__animated animate__fadeIn">ICUBE Partner</h2> -->
    </div>
    <div class="row">
      <div class="col-md-12 text-center mb-3 px-70">
        <h2>Student-Stop is proud to be a part of ICUBE at the University of Toronto, one of the top Universities
          in Canada</h2>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4"><img src="/assets/images/icube-logo.jpeg" alt="ICUBE Bank"></div>
      <div class="col-md-4"></div>
    </div>
  </div>
</section>

<section class="letter-section">
  <div class="container">
    <div class="sec-title text-center">
      <h2 class="wow animate__ animate__fadeIn mt-0 animated">
        The Canada India Foundation Recommends Student-Stop
      </h2>
      <div class="separator center"><span></span></div>
    </div>

    <div class="box">
      <img src="/assets/images/letter.jpg" alt="Letter">
    </div>
  </div>
</section>

<section class="counter-section">
  <div class="container">

    <div class="sec-title">
      <h2 class="wow animate__animated animate__fadeInLeft">Why choose <strong>Student-Stop</strong>?</h2>
      <div class="separator"><span></span></div>
    </div>
    <div class="row clearfix">

      <div class="counter-column col-md-12 col-sm-12">
        <div class="inner-column">

          <div class="fact-counter style-two">
            <div class="row clearfix">

              <div
                class="column counter-column col-lg-4 col-md-6 col-sm-12 wow animate__animated animate__fadeIn animate__delay-1s">
                <div class="inner">
                  <div class="content">
                    <i class="fas fa-heart"></i>
                    <h4 class="counter-title">Trust</h4>
                  </div>
                </div>
              </div>

              <div
                class="column counter-column col-lg-4 col-md-6 col-sm-12 wow animate__animated animate__fadeIn animate__delay-1s">
                <div class="inner">
                  <div class="content">
                    <i class="fas fa-briefcase"></i>
                    <h4 class="counter-title">Experience</h4>
                  </div>
                </div>
              </div>

              <div
                class="column counter-column col-lg-4 col-md-12 col-sm-12 wow animate__animated animate__fadeIn animate__delay-1s">
                <div class="inner">
                  <div class="content">
                    <i class="fas fa-globe-asia"></i>
                    <h4 class="counter-title">Global Dedicated Team</h4>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</section>

<section class="article-section">
  <div class="container">
    <div class="sec-title text-center">
      <h2 class="wow animate__animated animate__fadeIn mt-0">Latest <strong>Articles</strong></h2>
      <div class="separator center"><span></span></div>
    </div>
    <div class="row">

      <div class="col-md-4" *ngFor="let article of articles">
        <div class="article">
          <div class="image">
            <img [src]="article.image" alt="Article Two">
          </div>
          <div class="content">
            <span>{{article.publishon | date: 'mediumDate'}}</span>
            <a routerLink="/article/{{textReplacer(article.title)}}">
              <h3>{{article.title}}</h3>
            </a>
            <a routerLink="/article/{{textReplacer(article.title)}}" class="article-btn">Read More <i
                class="fas fa-chevron-right"></i></a>
          </div>
        </div>
      </div>

      <div class="col-md-12 text-center">
        <a routerLink="/articles" class="btn btn-primary" *ngIf="showMoreArticle">Show More</a>
      </div>
    </div>
  </div>
</section>

<section class="contact-us-faqs">
  <div class="container">

    <div class="sec-title text-center">
      <h2 class="wow animate__animated animate__fadeInDown">Contact Us</h2>
      <div class="separator center"><span></span></div>
    </div>

    <div class="row">
      <div class="col-md-6 wow animate__animated animate__fadeInLeft">
        <form class="contact-form" [formGroup]="contactForm" (ngSubmit)="contactFormSubmit()" novalidate>
          <div class="row">
            <div class="col-md-6">
              <input type="text" placeholder="Name*" class="form-control mt-0" name="name" formControlName="name"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Name is a required.</div>
              </div>
            </div>
            <div class="col-md-6">
              <input type="email" placeholder="Email*" class="form-control mt-0" name="email" formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is a required.</div>
              </div>
            </div>
            <div class="col-md-12">
              <input type="text" placeholder="Phone Number*" class="form-control" name="phone" formControlName="phone"
                [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
              <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                <div *ngIf="f.phone.errors.required">Phone Number is a required.</div>
              </div>
            </div>
            <div class="col-md-12">
              <input type="text" placeholder="Subject*" class="form-control" name="subject" formControlName="subject"
                [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
              <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                <div *ngIf="f.subject.errors.required">Subject is a required.</div>
              </div>
            </div>
            <div class="col-md-12">
              <textarea placeholder="How can we help you?" class="form-control" cols="30" rows="10" name="message"
                formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }">></textarea>
              <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                <div *ngIf="f.message.errors.required">Message is a required.</div>
              </div>
            </div>
            <div class="col-md-12">
              <button type="submit" class="submit-btn mt-3">Submit</button>
              <div *ngIf="msgSuccess" class="alert alert-success dark alert-dismissible fade show" role="alert">
                <p>{{msgSuccess}}</p>
                <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                    aria-hidden="true">×</span></button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-6 wow animate__animated animate__fadeInRight">
        <div class="faq-wrapper">
          <ngb-accordion #a="ngbAccordion">
            <ngb-panel class="card-header">
              <ng-template ngbPanelHeader>
                <h5 class="mb-0">
                  <button class="btn-link collapsed" ngbPanelToggle>
                    1. Why should I use <strong>Student-Stop</strong>? <span>
                      <i class="fas fa-plus-circle"></i>
                      <i class="fas fa-minus-circle"></i> </span>
                  </button>
                </h5>
              </ng-template>
              <ng-template ngbPanelContent>
                We have streamlined the complete process from admission till your arrival and stay in Canada.
                Therefore, you can trust us as being your single point of contact. We take care of all your
                headaches and make your transition smooth and comfortable.
              </ng-template>
            </ngb-panel>

            <ngb-panel class="card-header">
              <ng-template ngbPanelHeader>
                <h5 class="mb-0">
                  <button class="btn-link collapsed" ngbPanelToggle>
                    2. Does <strong>Student-Stop</strong> guarentee my admission? <span>
                      <i class="fas fa-plus-circle"></i>
                      <i class="fas fa-minus-circle"></i> </span>
                  </button>
                </h5>
              </ng-template>
              <ng-template ngbPanelContent>
                We guarentee admission in our partner colleges and universities for students that meet the
                elligibility criteria. For non-partner universities, we will assist you in your application process
                through our experts.
              </ng-template>
            </ngb-panel>

            <ngb-panel class="card-header">
              <ng-template ngbPanelHeader>
                <h5 class="mb-0">
                  <button class="btn-link collapsed" ngbPanelToggle>
                    3. What services does <strong>Student-Stop</strong> provide? <span>
                      <i class="fas fa-plus-circle"></i>
                      <i class="fas fa-minus-circle"></i> </span>
                  </button>
                </h5>
              </ng-template>
              <ng-template ngbPanelContent>
                We provide a variety of services from admission, banking, legal, financial, travel, accomodation,
                food and a lot more, which varies from city to city.
              </ng-template>
            </ngb-panel>

            <ngb-panel class="card-header">
              <ng-template ngbPanelHeader>
                <h5 class="mb-0">
                  <button class="btn-link collapsed" ngbPanelToggle>
                    4. Why should I trust <strong>Student-Stop</strong> over agents? <span>
                      <i class="fas fa-plus-circle"></i>
                      <i class="fas fa-minus-circle"></i> </span>
                  </button>
                </h5>
              </ng-template>
              <ng-template ngbPanelContent>
                Many agents have been known to provide students with false information and defraud them for
                financial gain of their own. We are a reputed company that has been founded by alumni of top
                universities in Canada.
                Our team has succesfully gone through the complete student journey and the same is being offered to all
                of you leveraging our vast experience.
                We have offices in both India and Canada and are partnered with large organizations. You can
                trust us to meet all your needs as a one stop shop.
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>

      </div>
    </div>
  </div>
</section>

<app-site-footer></app-site-footer>
