<div (click)="openAcademicDialog(studyInfo)" class="study-btn">Submitted Academic Background</div>

<div class="card wow animate__animated animate__fadeInUp">
  <app-doc-upload docType="Admission"></app-doc-upload>
</div>

<ng-template #studyInfo>
  <div class="card" mat-dialog-content>
    <div class="card-header">Your Filled Academic Background
      <div (click)="closeDialog()" class="btn btn-square btn-danger close-btn mt-3">Close</div>
    </div>
    <div class="card-body">
      <h3>Secondary and Post Secondary Institutes (High Schools, Colleges, Universities) attended</h3>

      <div class="table-responsive">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th>#</th>
              <th>Institute Type</th>
              <th>Attended From</th>
              <th>Attended To</th>
              <th>Country</th>
              <th>Name Of Institution/School/College</th>
              <th>Degree/Diploma/Certificate Name</th>
              <th>Graduation Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="tr-shadow" *ngFor="let item of studyList; let i = index">
              <td>{{i + 1}}</td>
              <td>{{item?.studyType[0].itemName}}</td>
              <td>{{item?.attendedFrom | date}}</td>
              <td>{{item?.attendedTo | date}}</td>
              <td>{{item?.country[0].itemName}}</td>
              <td>{{item?.nameOfInstitute}}</td>
              <td>{{item?.degreeName}}</td>
              <td>{{item?.indicateDate | date}}</td>
              <td>
                <div class="table-data-feature">
                  <button (click)="editIntituteDialog(i)" class="item">
                    <i class="fas fa-edit"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <hr>

      <h3>Transcript/Grade Information</h3>
      <div class="overflow">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th>#</th>
              <th>Class</th>
              <th>Board Name</th>
              <th>Year of Completion</th>
              <th>Subjects</th>
              <th>Cumulative Percentage/Grade</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="tr-shadow" *ngFor="let item of gradeList; let i = index">
              <td>{{i + 1}}</td>
              <td>{{item?.class}}</td>
              <td>{{item?.boardName}}</td>
              <td>{{item?.passedYear}}</td>
              <td>
                <span *ngFor="let subjects of item.subjects">
                  <span class="info-btn">{{subjects.name}}: {{subjects.marks}}</span>
                </span>
              </td>
              <td>{{item?.percentage}}</td>
              <td>
                <button (click)="editDegreeDialog(i)" class="item">
                  <i class="fas fa-edit"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>
