import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../../../services/profile.service';
import { AdmissionStepperComponent } from 'src/app/components/steppers/admission-stepper/admission-stepper.component';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-journey-begin',
  templateUrl: './journey-begin.component.html',
  styleUrls: ['./journey-begin.component.scss'],
})
export class JourneyBeginComponent implements OnInit {

  // admissionForm: FormGroup;
  serviceForm: FormGroup;
  personalform: FormGroup;
  @ViewChild(AdmissionStepperComponent) admissionStepper;

  error: String;
  errorClosed: Boolean = false;
  success: String;
  successClosed: Boolean = false;

  profile: User;
  mailNotConfirm: Boolean = true;
  decided: Number = 0;

  constructor(
    private _profile: ProfileService,
    private _formBuilder: FormBuilder,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._profile.getuser().subscribe(res => {
      this.profile = res.student;

      if (this.profile.mailConfirm === true) {
        if (this.profile.formComplete === true) {
          window.location.href = "/profile"
        } else {
          this.mailNotConfirm = false
        }
      } else {
        this.mailNotConfirm = true
      }

      this.personalform = this._formBuilder.group({
        'firstname': new FormControl(this.profile.firstname, [Validators.required]),
        'middlename': new FormControl(this.profile.middlename, []),
        'lastname': new FormControl(this.profile.lastname, [Validators.required]),
        'email': new FormControl(this.profile.email, [Validators.required]),
        'phone': new FormControl(this.profile.phone, [Validators.required]),
        'dob': new FormControl('', [Validators.required]),
        'gender': new FormControl('', [Validators.required]),
        'address': new FormControl('', [Validators.required]),
        'country': new FormControl('', [Validators.required]),
        'state': new FormControl('', [Validators.required]),
        'fblink': new FormControl('', []),
        'instausername': new FormControl('', []),
        'twitterusername': new FormControl('', []),
        'postalCode': new FormControl('', Validators.compose([
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(5),
          Validators.maxLength(6),
        ])),
      });

      let personalData = {
        dob: '',
        gender: '',
        address: '',
        country: '',
        state: '',
        postalCode: '',
      };

      if (localStorage.getItem('personalData')) {
        personalData = JSON.parse(localStorage.getItem('personalData'));
      }

      if (personalData.country !== "India") {
        this.personalform.get('state').disable();
        this.personalform.get('state').clearValidators();;
        this.personalform.get('state').updateValueAndValidity();
      }

      this.personalform = this._formBuilder.group({
        'firstname': new FormControl(this.profile.firstname, [Validators.required]),
        'middlename': new FormControl(this.profile.middlename, []),
        'lastname': new FormControl(this.profile.lastname, [Validators.required]),
        'email': new FormControl(this.profile.email, [Validators.required]),
        'phone': new FormControl(this.profile.phone, [Validators.required]),
        'dob': new FormControl(personalData.dob, [Validators.required]),
        'gender': new FormControl(personalData.gender, [Validators.required]),
        'address': new FormControl(personalData.address, [Validators.required]),
        'country': new FormControl(personalData.country, [Validators.required]),
        'state': new FormControl(personalData.state, [Validators.required]),
        'postalCode': new FormControl(personalData.postalCode, Validators.compose([
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(5),
          Validators.maxLength(6),
        ])),
      });

      this.serviceForm = this._formBuilder.group({
        'admission': [false],
        'banking': [false],
        'financing': [false],
        'immigration': [false],
        'accommodation': [false],
        'food': [false],
        'travel': [false],
        'localConveyance': [false],
        'cellInternet': [false],
        'studentJob': [false],
        'tutoring': [false],
        'medicalInsurance': [false],
        'peernetwork': [false],
        'holidayEvents': [false],
        'primmigration': [false]
      });

      // let admissionData = {
      //   decided: null,
      //   interested: [],
      //   degree: [],
      //   university: [],
      //   colleges: [],
      //   programs: [],
      //   currentStudy: '',
      // };

      // if (localStorage.getItem('admissionData')) {
      //   admissionData = JSON.parse(localStorage.getItem('admissionData'));
      // }

      // if (admissionData.decided === 1) {
      //   this.admissionForm = this._formBuilder.group({
      //     'interested': new FormControl(admissionData.interested, [Validators.required]),
      //     'degree': new FormControl(admissionData.degree, [Validators.required]),
      //     'university': new FormControl(admissionData.university, [Validators.required]),
      //     'colleges': new FormControl(admissionData.colleges, [Validators.required]),
      //     'programs': new FormControl(admissionData.programs, [Validators.required]),
      //     'currentStudy': new FormControl(admissionData.currentStudy),
      //   });
      //   this.decided = 1;
      // } else if (admissionData.decided === 2) {
      //   this.admissionForm = this._formBuilder.group({
      //     'interested': new FormControl(admissionData.interested, [Validators.required]),
      //     'degree': new FormControl(admissionData.degree, [Validators.required]),
      //     'university': new FormControl(null, []),
      //     'colleges': new FormControl(null, []),
      //     'programs': new FormControl(admissionData.programs, [Validators.required]),
      //     'currentStudy': new FormControl(admissionData.currentStudy),
      //   });
      //   this.decided = 2;
      // } else {
      //   this.admissionForm = this._formBuilder.group({
      //     'interested': new FormControl(null, [Validators.required]),
      //     'degree': new FormControl(null, [Validators.required]),
      //     'university': new FormControl(null, []),
      //     'colleges': new FormControl(null, []),
      //     'programs': new FormControl(null, [Validators.required]),
      //     'currentStudy': new FormControl(null),
      //   });
      // }
    },
      err => {
        console.log(err)
      });
  }

  callChildFunction() {
    this.admissionStepper.onDecideFunction()
  }

  resendConfirm() {
    this._profile.resendConfirmMail(this.profile._id).subscribe(
      res => {
        this.successClosed = true;
        this.success = res.message;
        setTimeout(() => {
          this.successClosed = false;
          this.success = '';
        }, 3000);
      },
      err => {
        this.errorClosed = true;
        this.error = err.error;
        setTimeout(() => {
          this.errorClosed = false;
          this.error = '';
        }, 3000);
      }
    )
  }

  firstStepData() {
    this._profile.submitPersonalForm(this.personalform.value)
      .subscribe(
        res => {
          localStorage.setItem('personalData', JSON.stringify(this.personalform.value));
        },
        err => {
          console.log(err)
        }
      );
  }

  onSubmit() {
    let finalData = { ...this.personalform.value, ...this.serviceForm.value, decided: this.decided }

    this._profile.submitForm(finalData)
      .subscribe(
        res => {
          // localStorage.removeItem('admissionData');
          localStorage.removeItem('personalData');
          this._router.navigate(['/starting-documents'])
        },
        err => {
          console.log(err)
        }
      );
  }

  // secondStepData() {
  //   let data;
  //   if (this.admissionForm.value.university || this.admissionForm.value.colleges) {
  //     data = {
  //       ...this.admissionForm.value,
  //       decided: 1
  //     }
  //   } else {
  //     data = {
  //       ...this.admissionForm.value,
  //       decided: 2
  //     }
  //   }

  //   this._profile.submitAdmissionForm(data)
  //     .subscribe(
  //       res => {
  //         localStorage.setItem('admissionData', JSON.stringify(data));
  //       },
  //       err => {
  //         console.log(err)
  //       }
  //     );
  // }
}
