import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-housing-main',
  templateUrl: './housing-main.component.html',
  styleUrls: ['./housing-main.component.scss']
})
export class HousingMainComponent implements OnInit {

  step: Number = 0;
  housingService;

  constructor(
    private _profile: ProfileService
  ) { }

  ngOnInit(): void {
    this._profile.getStudentDetails().subscribe((res) => {
      if (res.selectedService) {
        for (var i = 0; i < res.selectedService.servicesName.length; i++) {
          if (res.selectedService.servicesName[i].name === 'accommodation') {
            if (res.selectedService.servicesName[i].statusStep !== 0) {
              this.step = 1
            }
          }
        }
      }
      if (res.housingService) {
        this.housingService = res.housingService;
      }
    });
  }
}
