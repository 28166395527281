<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeInUp">Student Job</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li><a href="/">Services</a></li>
              <li class="active">Student Job</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-detail">
    <div class="container">
      <h2 class="wow animate__animated animate__fadeInUp white-text">Student Job Support</h2>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <p class="wow animate__animated animate__fadeInUp white-text">Bring down financial pressure on your family by earning money while studying. Finding a convenient job while studying may be tough. 
            Let us help you connect to suitable student job opportunities. 
            </p>
            <div class="challange-solution-section clearfix wow animate__animated animate__fadeInUp">
              <div class="white-text">
                <h3>Our platform can help you: </h3>
                <p>Register your interest for a job when completing your profile on <strong>Student-Stop</strong> and:</p>
                <ul>
                  <li class="white-text"><span class="icon"><img alt="" src="assets/images/check.png"></span>Find potential job opportunities </li>
                  <li class="white-text"><span class="icon"><img alt="" src="assets/images/check.png"></span>Apply to companies </li>
                  <li class="white-text"><span class="icon"><img alt="" src="assets/images/check.png"></span>Connect with potential employers</li>
                  
                </ul>
              </div>
            </div>
          </div>
            <div class="col-lg-6 col-md-12">
              <div class="why-choose-image wow animate__animated animate__fadeInUp">
                <img id="immigrationimage" src="/assets/images/studentjob.png" alt="image">
              </div>
            </div>
            </div>
         
          
    </div>
  </section>

  <app-site-footer></app-site-footer>

</main>
