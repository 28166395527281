<div class="dashboard-box">

  <app-navbar></app-navbar>
  <app-sidebar></app-sidebar>

  <div class="dashboard-body">
    <div class="container-fluid">
      <div class="page-header job">
        <div class="row">
          <div class="col-lg-12 main-header">
            <h2>Jobs</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="top-box">
        <div class="card wow animate__animated animate__fadeInUp">
          <div class="card-body gray-bg">

            <div class="row">
              <div class="col-md-12 col-sm-12">

                <div class="wrap-search-filter">
                  <form [formGroup]="searchForm" (ngSubmit)="searchJob()" class="row">
                    <div class="col-md-10 col-sm-10">
                      <input type="text" class="form-control" formControlName="location"
                        placeholder="Search by Job Title, Location, Company Name">
                    </div>
                    <div class="col-md-2 col-sm-2">
                      <button type="submit" class="btn btn-primary full-width">Filter</button>
                    </div>
                  </form>
                </div>

                <div *ngIf="jobs.length === 0 && jobSearch === true">
                  <h4 class="mt-4 text-center">Sorry, there are currently no job openings that match your search.
                    Please check again as job openings are posted frequently and also fill up quickly.</h4>
                </div>

                <div *ngIf="appliedJobs.length !== 0">

                  <hr class="mt-3">
                  <h2>Applied Jobs</h2>
                  <div class="job-verticle-list" *ngFor="let appliedJob of appliedJobs">
                    <div class="vertical-job-card">
                      <div class="vertical-job-header">
                        <h4>{{appliedJob.job_id.title}}</h4>
                      </div>
                      <div class="vertical-job-body">
                        <div class="row">
                          <div class="col-md-4 col-sm-4">
                            <ul class="can-skils">
                              <li><strong>Company: </strong>{{appliedJob.job_id.company}}</li>
                              <li><strong>Experience Level: </strong>
                                <span *ngFor="let experiencelevel of appliedJob.job_id.experiencelevel"
                                  class="skill-tag">{{experiencelevel}}</span>
                              </li>

                            </ul>
                          </div>
                          <div class="col-md-4 col-sm-4">
                            <ul class="can-skils">
                              <li><strong>Experience: </strong>{{appliedJob.job_id.experienceRequired}}</li>
                              <li><strong>Location: </strong>{{appliedJob.job_id.location}}</li>
                            </ul>
                          </div>
                          <div class="col-md-4 col-sm-4">
                            <ul class="can-skils">
                              <li><strong>Salary: </strong>{{appliedJob.job_id.salary}}</li>
                            </ul>
                          </div>
                          <div class="col-md-12 col-sm-12">
                            <ul class="can-skils">
                              <li><strong>Description: </strong>{{appliedJob.job_id.description}}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="jobs.length !== 0">
                  <div *ngIf="appliedJobs.length !== 0">
                    <hr class="mt-3">
                    <h2>Other Jobs</h2>
                  </div>
                  <div class="job-verticle-list" *ngFor="let job of jobs">
                    <div class="vertical-job-card">
                      <div class="vertical-job-header">
                        <h4>{{job.title}}</h4>
                      </div>
                      <div class="vertical-job-body">
                        <div class="row">
                          <div class="col-md-9 col-sm-8">
                            <ul class="can-skils">
                              <li><strong>Company: </strong>{{job.company}}</li>
                              <li><strong>Experience Level: </strong>
                                <span *ngFor="let experiencelevel of job.experiencelevel"
                                  class="skill-tag">{{experiencelevel}}</span>
                              </li>
                              <li><strong>Experience: </strong>{{job.experienceRequired}}</li>
                              <li><strong>Location: </strong>{{job.location}}</li>
                              <li><strong>Salary: </strong>{{job.salary}}</li>
                              <li><strong>Description: </strong>{{job.description}}</li>
                            </ul>
                          </div>
                          <div class="col-md-3 col-sm-4">
                            <div class="vrt-job-act">
                              <button (click)="openForm(resumeDialog, job._id)"
                                class="btn-job theme-btn job-apply">Apply Now</button>
                              <!-- <a href="" title="" class="btn-job light-gray-btn">View Job</a> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <ng-template #resumeDialog let-tablename let-ref="dialogRef">
      <div class="card">
        <div class="card-header">Apply Job
          <button class="close" aria-label="Close" (click)="onDialogClose()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="card-body">
          <div *ngIf="loading" class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="alert alert-info dark mt-0" role="alert">
            <p>Please upload documents only in Doc & PDF formats with a maximum size of 4MB. Other
              formats are not accepted.</p>
          </div>
          <form class="form-group box-form" novalidate>
            <div class="row">
              <div class="col-md-12">
                <div class="input-group">
                  <input type="text" class="form-control" readonly>
                  <div class="input-group-btn">
                    <span class="fileUpload btn btn-danger">
                      <span class="upl" id="upload">Select Resume</span>
                      <input type="file" class="upload up btn btn-primary" id="up" [name]="tablename"
                        (change)="selectFile($event)" required />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-3">
              <button type="submit" class="btn btn-square btn-primary w-100" [disabled]="!documentValidation"
                (click)="applyJobSubmit(tablename.id)">Upload</button>
            </div>
            <div id="file_error"></div>
          </form>
        </div>
      </div>
    </ng-template>

    <app-site-footer></app-site-footer>
  </div>
</div>
