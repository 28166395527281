import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../../../services/profile.service';

@Component({
  selector: 'app-travel-main',
  templateUrl: './travel-main.component.html',
  styleUrls: ['./travel-main.component.scss']
})
export class TravelMainComponent implements OnInit {

  travelService;
  step: Number = 0;

  constructor(
    private _profile: ProfileService
  ) { }

  ngOnInit(): void {
    this._profile.getStudentDetails().subscribe((res) => {
      if (res.selectedService) {
        for (var i = 0; i < res.selectedService.servicesName.length; i++) {
          if (res.selectedService.servicesName[i].name === 'travel') {
            this.step = res.selectedService.servicesName[i].statusStep
          }
        }
      }
      if (res.travelService) {
        this.travelService = res.travelService
      }
    });
  }
}
