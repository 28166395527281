import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HouseService } from 'src/app/services/house.service';
import { SelectOptionData } from 'src/app/sharedata/selectOptionData';
import { LabelType, Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-housing-form',
  templateUrl: './housing-form.component.html',
  styleUrls: ['./housing-form.component.scss']
})
export class HousingFormComponent implements OnInit {

  housingForm: FormGroup;

  canadaCity;
  universityList;
  collegeList;
  allUniversityAndCollege;
  years = [];

  universitySettings = {
    singleSelection: true,
    text: "Select University Or College",
    selectAllText: false,
    unSelectAllText: false,
    enableSearchFilter: true,
    lazyLoading: true
  };

  minDate = new Date();
  minValue: number = 450;
  maxValue: number = 1200;
  options: Options = {
    floor: 200,
    ceil: 1500,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min Price:</b> C$ ' + value;
        case LabelType.High:
          return '<b>Max Price:</b> C$ ' + value;
        default:
          return 'C$ ' + value;
      }
    }
  };

  constructor(
    private _house: HouseService
  ) { }

  ngOnInit(): void {
    const serviceData = new SelectOptionData;
    this.canadaCity = serviceData.canadaCity();
    this.universityList = serviceData.university();
    this.collegeList = serviceData.collegeList();

    this.allUniversityAndCollege = this.universityList.concat(this.collegeList);

    this.allUniversityAndCollege.sort(function (a, b) {
      if (a.itemName < b.itemName) { return -1; }
      if (a.itemName > b.itemName) { return 1; }
      return 0;
    });

    const currentYear = new Date().getFullYear()
    for (var year = currentYear; year <= currentYear + 3; year++) {
      this.years.push(year);
    }

    this.housingForm = new FormGroup({
      'university': new FormControl('', Validators.required),
      'city': new FormControl('', Validators.required),
      'startSemesterYear': new FormControl('', [Validators.required]),
      'semesterType': new FormControl('', [Validators.required]),
    });
  }

  submitService() {
    const data = {
      ...this.housingForm.value,
      budgetRange: {
        minimum: this.minValue,
        maximum: this.maxValue
      }
    }
    this._house.housingSubmit(data).subscribe(
      res => {
        window.location.reload()
      }
    )
  }

  selectUniversity(item) {
    const city = item[0].city;
    if (city) {
      this.housingForm.patchValue({
        city
      });
    }
  }
}
