<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeInUp">FOOD</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li><a href="/">Services</a></li>
              <li class="active">Food</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-detail">
    <div class="container">
      <h2 class="wow animate__animated animate__fadeInUp white-text">Food to your taste</h2>
      <p class="wow animate__animated animate__fadeInUp white-text">Food can be a major concern for many students going abroad.
        Will I get vegetarian food? Will I get Indian food? How expensive will it be? These are some 
        of the concerns most students have. While moving to a new country can be challenging, it is very
        important to ensure that you are getting food according to your taste. 
        Students very often come across situations, where they are unable to afford good food or do not have the time to
        cook food for themselves, due to their study pressure or work obligations. <strong>Student-Stop</strong> is here to take care of your 
        dietary needs, so that you have one important thing less to worry about. We along with our partners, provide delicious and healthy food to
        students, who would like to avail of this service. Students have the choice to go for Vegetarian or Non
        vegetarian food(in selected cities) as per their preference.</p>
    </div>
  </section>

  <section class="service-inner-top-section wow animate__animated animate__fadeInUp">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="service-inner-content">
            <h2>Benefits of chosing us.</h2>
            <div class="sec-title">
              <div class="separator"><span></span></div>
            </div>
            <p>At <strong>Student-Stop</strong>, your health and nutrition is our priority. We understand
              the importance of food for your wellbeing and success as a student. </p>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Save money</h4>
              <p>We do our best to ensure you get affordable and nourishing food 
                at your doorstep. </p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Save time</h4>
              <p>Avoid the hassles of cooking and focus on studies.</p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Delicious and wholesome meals</h4>
              <p>We provide delicious and fresh meals through our hand-picked food partners to keep you healthy.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="why-choose-image wow animate__animated animate__fadeInUp">
            <img
              src="/assets/images/food.jpg"
              alt="image">
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-site-footer></app-site-footer>

</main>
