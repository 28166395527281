import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExtraService {

  private _Url = environment.server;

  constructor(private http: HttpClient) { }

  getTermsAndConditions() {
    return this.http.get<any>(this._Url + '/terms-and-privacy/terms-and-conditions');
  }

  studentUniversityMatch(data) {
    return this.http.post<any>('https://ssuniversitymatch.student-stop.com/findmatchingprograms', data);
  }

  studentUniversityMatchByKey(data) {
    return this.http.post<any>('https://ssuniversitymatch.student-stop.com/findmatchingprogramsbykey', data);
  }

}
