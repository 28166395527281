import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'app-travel-info',
  templateUrl: './travel-info.component.html',
  styleUrls: ['./travel-info.component.scss']
})
export class TravelInfoComponent implements OnInit {

  @Input() travelService;
  url;
  downloadLink;

  constructor(
    private _document: DocumentsService,
  ) { }

  ngOnInit(): void {
    this.url = environment.url;
    if (this.travelService?.documentSubmit) {
      this.onClickDownloadPdf(this.travelService?.documentSubmit)
    }
  }

  downloadPdf(base64String, fileName) {
    const source = `${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

  onClickDownloadPdf(url) {
    this._document.downloadDoc(url).subscribe(
      res => {
        this.downloadLink = res['document_url']
      },
      err => {
        console.log(err)
      }
    )
  }

}
