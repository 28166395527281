<main class="bg-body-color">

  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeIn">About Us</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">About Us</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="about-section">
    <div class="container">
      <div class="sec-title text-center">
        <div class="title wow animate__animated animate__fadeInUp">Team</div>
        <div class="separator center"><span></span></div>
        <h2 class="wow animate__animated animate__fadeInUp">Executive Team</h2>
      </div>
      <div class="row">
        <div class="col-md-4 text-center">
          <div class="team-item wow animate__animated animate__fadeInUp">
            <img src="assets/images/siddharth-picture.jpg" alt="">
            <h4>Siddharth Gautam <span>Co-Founder</span></h4>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <div class="team-item wow animate__animated animate__fadeInUp">
            <img src="assets/images/avishek-picture.jpg" alt="">
            <h4>Avishek Gautam <span>Co-Founder</span></h4>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <div class="team-item wow animate__animated animate__fadeInUp">
            <img src="assets/images/ankit-picture.jpg" alt="">
            <h4>Ankit Gautam <span>Co-Founder</span></h4>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 about-btm-text">
          <p class="text wow animate__animated animate__fadeInUp">Founded by Siddharth, Avishek and Ankit,
            <strong>Student-Stop</strong> is a platform that has been created to support international students coming
            to Canada. Having moved to Canada as students themselves, the founding team is well aware of the
            challenges faced during a student’s journey and life abroad. From the admissions process to getting settled
            in Canada, <strong>Student-Stop</strong> will work to make the process as simple and carefree for students
            as possible. Once abroad, the platform will act as your support outside home, by providing you with all the
            important information and tasks required to make your journey as a student successful.</p>
        </div>
      </div>
    </div>
    <div class="c-border-all">
      <div class="c-border">
        <div class="box c-border-1"></div>
      </div>
    </div>
  </div>

  <div class="about-grintex">
    <div class="container">
      <div class="sec-title text-center">
        <h2 class="wow animate__animated animate__fadeInUp">Student-Stop is a Grintex venture.</h2>
        <div class="separator center"><span></span></div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <p class="text wow animate__animated animate__fadeInUp">Grintex is a well-established ISO 9001:2015, ISO
            45001: 2018 & ISO/IEC 27001:2013, ISO 14001:2015, NSIC
            certified company with an International presence. Grintex has been providing high-end Technology Solutions
            and Services for more than two
            decades, in the areas of Software, Defense, Naval & Maritime Systems, Aviation, Space, Satcom Networks,
            Homeland Security, Manufacturing etc to leading Indian Government organizations, Indian MNCs and reputed
            International technology corporations.</p>
          <a class="d-block wow animate__animated animate__fadeInUp animate__delay-1s" target="blank"
            href="https://www.grintex.com">About Grintex</a>
          <img class="mt-4 wow animate__animated animate__fadeInUp" src="/assets/images/grintex-logo.png" alt="">
        </div>
      </div>
    </div>
  </div>


  <app-site-footer></app-site-footer>

</main>
