import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectOptionData } from '../../../sharedata/selectOptionData';

@Component({
  selector: 'app-personal-stepper',
  templateUrl: './personal-stepper.component.html',
  styleUrls: ['./personal-stepper.component.scss']
})
export class PersonalStepperComponent implements OnInit {

  @Input() personalformParent: FormGroup;
  countryList: Array<Object> = [];
  stateList: Array<Object> = [];
  todayDate: Date = new Date();

  constructor() { }

  ngOnInit(): void {
    const serviceData = new SelectOptionData;
    this.countryList = serviceData.country();
    this.stateList = serviceData.state();

    this.todayDate.setFullYear(this.todayDate.getFullYear() - 13);
  }

  onChanges() {
    this.personalformParent.get('country').valueChanges.subscribe(selectedCountry => {
      if (selectedCountry != 'India') {
        this.personalformParent.get('state').reset();
        this.personalformParent.get('state').disable();
      }
      else {
        this.personalformParent.get('state').enable();
      }
    });
  }

}
