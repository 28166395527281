<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeInUp">Housing</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li><a href="/">Services</a></li>
              <li class="active">Housing</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-detail">
    <div class="container">
      <h2 class="wow animate__animated animate__fadeInUp white-text">Housing Support</h2><br>
      <p class="wow animate__animated animate__fadeInUp white-text">Do not underestimate the importance of finding student housing near your university/college.
        Because of the huge demand and lack of supply, this has been known as a major bottleneck for international students coming to Canada.
        In the excitement of getting accepted, students do everything and ignore the importance of finding housing. 
        There have been instances, where students have had to return back to India because of they could not find affordable housing. 
        Finding suitable housing accommodation can be a painstaking task, especially when there is limited housing for students and
        many students competing for them. Students that do find housing online are unsure of their reliability and hesitate to pay for such accomadation prior to their departure from India. 
        <strong>Student-Stop</strong> is here to help you find the correct housing before you arrive in Canada and take away all your worries!
        <br><br><a routerLink="/register" style="color:#ff9600;"> Register Now</a>, so that our platform can give you all the guidance and prepare you with everything that you
          need, for a succesfull journey ahead!</p>
    </div>
  </section>

  <section class="service-inner-top-section wow animate__animated animate__fadeInUp">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="service-inner-content">
            <h2>The Perfect Student Housing</h2>
            <div class="sec-title">
              <div class="separator"><span></span></div>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Save time on commute</h4>
              <p>Your time as a student is precious. Choosing the wrong housing accommodation could cost you several hours of travel each
                day, depriving you from valuable study time. We help you find housing close to your institution and help you avoid unneccessary daily commute to your institution.</p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Save money on rent</h4>
              <p>Through our partners, <strong>Student-Stop</strong> can provide housing at affordable prices, to meet your needs.</p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Reliable housing verified by <strong>Student-Stop</strong></h4>
              <p>Residential location are selected and verified by the <strong>Student-Stop</strong> team, so that you are safe.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="why-choose-image wow animate__animated animate__fadeInUp">
            <div class="why-choose-image wow animate__animated animate__fadeInUp">
              <img src="/assets/images/room.png" alt="image">
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-site-footer></app-site-footer>

</main>
