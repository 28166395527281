import { Component, OnInit, Inject } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as $ from 'jquery';

@Component({
  selector: 'app-change-profile-dialog',
  templateUrl: './change-profile-dialog.component.html',
  styleUrls: ['./change-profile-dialog.component.scss']
})
export class ChangeProfileDialogComponent implements OnInit {

  selectedFile;
  documentValidation: Boolean = false;

  constructor(
    private _profile: ProfileService,
    public dialogRef: MatDialogRef<ChangeProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void { }

  updateProfilePic() {
    const formData = new FormData();
    formData.append('profile', this.selectedFile);

    this._profile.updateProfileImg(formData).subscribe((res) => {
      window.location.reload()
    })
  }

  selectFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const file_size = event.target.files[0].size;
      const fileType = file['type'];
      const validImageTypes = ['image/jpeg', 'image/jpg'];
      if (file_size > 10485760) {
        $("#file_error").html("File size must less then 10mb. Compress file <a target='blank' href='https://www.wecompress.com/en/'>Here</a>").show().delay(5000).fadeOut('slow');
        $("#uploadShow").css("border: 1px solid red");
        this.selectedFile = null;
        this.documentValidation = false;
      } else if (!validImageTypes.includes(fileType)) {
        $("#file_error").html("Invalid Document only Accept jpg, jpeg").show().delay(5000).fadeOut('slow');;
        $("#uploadShow").css("border: 1px solid red");
        this.selectedFile = null;
        this.documentValidation = false;
      } else {
        this.selectedFile = file;
        this.documentValidation = true;
      }
    }
  }

  onDialogClose(): void {
    this.dialogRef.close();
  }
}
