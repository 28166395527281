<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1>Student-University Match</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">Student-University Match</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="about-section">
    <div class="container">

      <div class="sec-title text-center">
        <div class="title wow animate__animated animate__fadeInUp">Find a University that fits your profile</div>
        <div class="separator center"><span></span></div>
        <h2 class="wow animate__animated animate__fadeInUp">Enter your details and find matching universities</h2>
      </div>

      <form [formGroup]="recommendedForm" (ngSubmit)="submitForm(recommendedForm)" novalidate
        class="form-group box-form" novalidate>
        <ng-template matStepLabel>Personal Details</ng-template>
        <div class="row">
          <div class="col-md-12 mb-2">
            <p><strong>Student-Stop's state of the art AI based Student-University matching algorithm helps you in
                finding the university, that will fit your academic profile as well as your personal preferences and
                budget contraints.</strong>
            </p>
          </div>
          <div class="col-md-12">
            <h2>My Preference</h2>
            <hr>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="class">I want to pursue my*</label>
              <select (change)="selectPursue($event.target.value)" class="form-control" name="pursueto"
                formControlName="pursueto">
                <option selected disabled value="">Select Option</option>
                <option value="Bachelor's/First">Bachelor's/First</option>
                <option value="Master's">Master's</option>
              </select>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="form-group mb-0">
              <h2>Please select one:</h2>
              <hr>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-check">
                    <input (change)="selectRadio($event.target.value)" class="form-check-input" type="radio"
                      name="program" id="keyword" value="keyword">
                    <label class="form-check-label" for="keyword">
                      Program of Interest (By Keyword Search e.g Computer Science)
                    </label>
                  </div>
                </div>
                <div class="col-md-12 my-3">
                  <span><strong>OR</strong></span>
                </div>
                <div class="col-md-12">
                  <div class="form-check">
                    <input (change)="selectRadio($event.target.value)" class="form-check-input" type="radio"
                      name="program" id="degreeDropdown" value="degreeDropdown">
                    <label class="form-check-label" for="degreeDropdown">
                      Select Program of Interest* (By degree dropdown)
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3" *ngIf="radioSelect === 'degreeDropdown'">
            <div class="form-group">
              <label>Select Program of Interest* (By degree dropdown)*</label>
              <angular2-multiselect [data]="programsList" class="form-control angular-selector"
                formControlName="programsInterest" name="programsInterest" [settings]="programSettings">
                <c-item>
                  <ng-template let-item="item">
                    <label> <strong>{{item.itemName}}</strong></label>
                  </ng-template>
                </c-item>
              </angular2-multiselect>
            </div>
          </div>
          <div class="col-md-12 mt-3" *ngIf="radioSelect === 'keyword'">
            <div class="form-group">
              <label>Program of Interest (By Keyword Search e.g Computer Science)*</label>
              <input class="form-control" placeholder="Enter Program of Interest" type="text"
                formControlName="programkey" name="programkey" required>
            </div>
          </div>

          <div class="col-md-12">
            <hr>
            <div class="form-group">
              <label for="budget">Annual Budget Range*</label>
              <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
            </div>
          </div>

          <div class="col-md-12">
            <h2>Academic History</h2>
            <hr>
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <label for="class">Board*</label>
              <select class="form-control" name="board" formControlName="board">
                <option selected disabled value="">Select Board</option>
                <option [value]="board" *ngFor="let board of boardList">{{board}}</option>
              </select>
            </div>
          </div>

          <div class="col-md-4">
            <br>
            <button type="button" (click)="onSelectBoard()" class="btn btn-primary btn-square add-field-btn">Add
              Board</button>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label for="averageGrade">List Of Added Boards*</label>
              <div formArrayName="boards"
                *ngFor="let item of recommendedForm.get('boards')['controls']; let i = index;">
                <div class="row" [formGroupName]="i">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Board Name</label>
                      <input class="form-control" placeholder="Board Name" type="text" formControlName="name"
                        name="name" readonly required>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label *ngIf="item.controls['name'].value === 'CBSE (10th)'">Percentage Received*</label>
                      <label *ngIf="item.controls['name'].value === 'CBSE (12th)'">Percentage Received*</label>
                      <label *ngIf="item.controls['name'].value === 'ICSE'">Percentage Received*</label>
                      <label *ngIf="item.controls['name'].value === 'ISC'">Percentage Received*</label>
                      <label *ngIf="item.controls['name'].value === 'IB Diploma (Predicted/Actual)'">Grade
                        Received*</label>
                      <label *ngIf="item.controls['name'].value === 'Bachelor\'s/First'">CGPA/Average
                        Percentage*</label>
                      <label *ngIf="item.controls['name'].value === 'Master\'s'">CGPA/Average Percentage*</label>
                      <input class="form-control" type="number" min="0" name="total" placeholder="Total"
                        formControlName="total">
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Out Of</label>
                      <select class="form-control" name="outof" formControlName="outof">
                        <option [value]="outof.number" *ngFor="let outof of getOutof(item.controls['name'].value)">
                          {{outof.number}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <br>
                    <button type="button" class="btn btn-square btn-danger" (click)="removeBoard(i)">X</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <button type="submit" class="btn btn-block btn-lg btn-primary p-3"
              [disabled]="recommendedForm.invalid">Submit</button>
          </div>

          <div class="col-md-12 mt-3">
            <p class="font-sm">Please Note: Student-Stop's advanced Student-University matching tool can help you in
              finding universities that will match your academic profile. However, University admissions are very
              competitive and factor in many other variables when making their final decision.
              This tool does not guarentee admissions, it is only meant to recommend universities where a student may
              have a good chance of being admitted based on their above mentioned academic profile. University
              requirements keep on changing.
            </p>
          </div>
        </div>
      </form>

    </div>
  </div>

  <app-site-footer></app-site-footer>

</main>
