<div class="card">
  <div class="card-header">
    Change Password
    <button class="close" aria-label="Close" (click)="onDialogClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <form [formGroup]="passwordChangeForm" (ngSubmit)="changePasswordSubmit(passwordChangeForm)">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="first-name">New Password*</label>
            <input class="form-control" type="password" name="password" placeholder="New Password"
              formControlName="password">
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="middle-name">Confirm Password</label>
            <input class="form-control" type="password" name="confirmPassword" placeholder="Confirm Password"
              formControlName="confirmPassword">
          </div>
        </div>
      </div>
      <div *ngIf="error" class="alert alert-danger dark alert-dismissible fade show" role="alert">
        <p>{{error}}</p>
        <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
            aria-hidden="true">×</span></button>
      </div>
      <div *ngIf="success" class="alert alert-success dark alert-dismissible fade show" role="alert">
        <p>{{success}}</p>
        <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
            aria-hidden="true">×</span></button>
      </div>
      <div>
        <div class="text-center mt-2">
          <button type="submit" [disabled]="passwordChangeForm.invalid"
            class="btn btn-square btn-primary mr-2">Update</button>
        </div>
      </div>
    </form>
  </div>
</div>
