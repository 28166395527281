import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../../services/profile.service';
import MenuData from 'src/app/sharedata/menuItems';
import * as $ from 'jquery';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menu: Array<Object> = [];
  url;
  userProfile;

  constructor(
    private _profile: ProfileService
  ) {
    $(document).ready(function () {
      if ($(window).width() < 600) {
        $('.dashboard-box .sidebar-menu').addClass('sidebar-close');
        $('.dashboard-box .dashboard-body').addClass('sidebar-close');
      }

      $('#sidebar-btn').on('click', function () {
        $('.sidebar-menu').toggleClass('sidebar-close');
        $('.dashboard-body').toggleClass('sidebar-close');
      });
    });


    var current = window.location.href;
    $('.navbar-nav li.nav-item a').each(function () {
      var href;
      if (href === current) {
        $(this).addClass('active');
      }
    });
  }

  ngOnInit(): void {
    this.url = environment.url;
    this.menu = MenuData;
    const token = this._profile.getToken();
    if (token) {
      this._profile.getuser().subscribe(res => {
        this.userProfile = res.student;
      },
        err => {
          console.log(err)
        })
    }
  }

  logout() {
    this._profile.logout();
    window.location.href = '/'
  }
}
