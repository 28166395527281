// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  server: 'http://localhost:4000/api',
  trackerKey: '6f0b84bca3c769c97ba0e3166f37c18621d96583c24246c27ba074ddb6a955cf',
  paymentKeys: {
    key: '9Jgu3wB8'
  },
  url: 'http://localhost:4000/',
  cookiesDomain: "http://localhost:4200",
  stripeKey: "pk_test_YR5zEoFMNqfqkSFs9DFFgRrL"
};

//pk_test_51JexnRSHlvC6ltjZ5DCtoPDCySUJQHTxbow5ayIjPnn36C96pU17nEQxXv2g2RTcMyUTrF6xl0XRwJDZnx3vbAvb00bRduDGEj

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
