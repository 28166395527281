import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { ProfileService } from '../../../services/profile.service';
import { ServicesSubmitService } from '../../../services/services-submit.service';

@Component({
  selector: 'app-edit-services',
  templateUrl: './edit-services.component.html',
  styleUrls: ['./edit-services.component.scss']
})
export class EditServicesComponent implements OnInit {

  nonEditService = true;
  userSelectedService;
  userProfile;
  profileSuccessMsg;

  @Output() selectStory = new EventEmitter<boolean>();

  constructor(
    private _profile: ProfileService,
    private _service: ServicesSubmitService,
  ) { }

  ngOnInit(): void {
    this.userProfile = this._profile.getuser();
    this._profile.getStudentDetails().subscribe((res) => {
      this.userSelectedService = res.selectedService;
    });
  }

  updateServices() {
    this._service.updateSelectedServices(this.userSelectedService)
      .subscribe(
        res => {
          this.profileSuccessMsg = res
          setTimeout(function () {
            window.location.reload();
          }, 500);
        },
        err => {
          console.log(err)
        }
      );
  }

  getCheckboxValues(event, data) {
    var index = this.userSelectedService.servicesName.findIndex(x => x.name === data);
    if (this.userSelectedService.servicesName[index].need === true) {
      this.userSelectedService.servicesName[index].need = false
    } else {
      this.userSelectedService.servicesName[index].need = true
    }
  }

  editServicePopup() {
    if (this.nonEditService === true) {
      this.nonEditService = false
      this.selectStory.emit(this.nonEditService);
    } else {
      this.nonEditService = true
    }
  }

}
