<div class="card text-white bg-primary wow animate__animated animate__fadeInUp">
  <div class="card-body">

    <h3 class="mb-4">Questions</h3>
    <form #bankForm="ngForm" (ngSubmit)="submitBanking()" novalidate>
      <div class="row">

        <div class="col-md-12">
          <div class="form-group">
            <label for="">Do you need a Loan?</label>
            <select class="form-control" (blur)="validatehaveLoan(loan.value)" (change)="validatehaveLoan(loan.value)"
              [class.is-invalid]="haveLaonError && loan.touched" name="loan" ngModel #loan="ngModel"
              [(ngModel)]="bankModel.loan" (change)="changeLoanValue($event.target.value)">
              <option value="default" selected>Select option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>

        <div class="col-md-12 mt-4" *ngIf="loanValue">
          <div class="form-group">
            <label for="">Enter Amount(In Canadian Dollar)</label>
            <input type="number" class="form-control" placeholder="Enter Amount" name="loanAmt"
              [(ngModel)]="bankModel.loanAmt" #loanAmt="ngModel" required>
          </div>
        </div>

        <!-- <div class="col-md-12 mt-4" *ngIf="loanValue">
          <div class="card">
            <div class="card-body p-4">
              <h3 class="dark-text mb-3">ICICI Bank Loan Offering: </h3>
              <ul class="list ml-5 dark-text">
                <li>Collateral free loans Up to INR 40 Lakhs</li>
                <li>Flexible repayment option</li>
                <li>Tax benefit u/s 80E</li>
                <li>Doorstep services</li>
                <li>Loans of upto 1 Crore</li>
                <li>Quick and hassle free process</li>
              </ul>

              <div class="form-group mt-4">
                <a (click)="clickLoan('Loan')"
                  href="https://loans.icicibank.com/loans/education-loan/?utm_source=DSA_Channel&utm_medium=ICICI+Canada+Student+Stop_00343253&utm_campaign=ELILENS"
                  target="blank" class="btn btn-square btn-secondary btn-large">Check eligibility now and Apply</a>
              </div>
              <p class="dark-text"><strong>*Terms and Conditions Apply</strong></p>
            </div>
          </div>
        </div> -->

        <div class="col-md-12 mt-4">
          <div class="form-group">
            <label for="">Do you have a Canadian bank account?</label>
            <select class="form-control" (blur)="validateStatus(haveBank.value)"
              (change)="validateStatus(haveBank.value)" [class.is-invalid]="statusHasError && haveBank.touched"
              name="haveBank" ngModel #haveBank="ngModel" [(ngModel)]="bankModel.haveBank"
              (change)="changeData($event.target.value)" required>
              <option value="default" selected>Select option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-12 mt-2" *ngIf="isShown">

          <div class="card m-0">
            <div class="card-header">Step by Step Process</div>
            <div class="card-body">

              <h2 class="text-center text-dark">Quick and Easy process!</h2>

              <div class="main-timeline">
                <div class="timeline">
                  <div class="timeline-icon"><span class="year">1</span></div>
                  <div class="timeline-content">
                    <h3 class="title">Create Account Online</h3>
                    <p class="description">GIC Account application is completely online. Receive the account
                      and funding details instantly</p>
                  </div>
                </div>
                <div class="timeline">
                  <div class="timeline-icon"><span class="year">2</span></div>
                  <div class="timeline-content">
                    <h3 class="title">Funding</h3>
                    <p class="description">Transfer funds from student or parent account. Funds are accepted
                      from parents account (conditions apply), allowing the student to save 5% LRS on outward
                      remittance of 7L & above</p>
                  </div>
                </div>
                <div class="timeline">
                  <div class="timeline-icon"><span class="year">3</span></div>
                  <div class="timeline-content">
                    <h3 class="title">GIC Issuance</h3>
                    <p class="description">ICICI Bank has one of the fastest GIC issuance TAT Issuance would
                      take 3 hours to 1 Working Day once the account is funded.</p>
                  </div>
                </div>
                <div class="timeline">
                  <div class="timeline-icon"><span class="year">4</span></div>
                  <div class="timeline-content">
                    <h3 class="title">Delivery of Debit Card</h3>
                    <p class="description">Once funded, the account debit card will be delivered to the
                      student’s Indian address within 3 weeks.</p>
                  </div>
                </div>
                <div class="timeline">
                  <div class="timeline-icon"><span class="year">5</span></div>
                  <div class="timeline-content">
                    <h3 class="title">Activation Process</h3>
                    <p class="description">Easy 2-step activation process. Online activation form through the
                      dedicated App or webpage. In person verification at over 6000 Canada Post locations.</p>
                  </div>
                </div>
                <div class="timeline">
                  <div class="timeline-icon"><span class="year">6</span></div>
                  <div class="timeline-content">
                    <h3 class="title">3600 ABM partners</h3>
                    <p class="description">ABM access to over 3600 ABM’s in Canada. Free and unlimited access
                      to your money. Visa debit card allows online transactions.</p>
                  </div>
                </div>
              </div>

              <hr>

              <h3 class="important-heading">Very Important Step During Account Creation</h3>
              <p class="imp-text">Please do not forget to fill this page, otherwise we cannot support in
                processing your GIC
                application with ICICI Bank Canada</p>

              <img src="/assets/images/banking-important.jpg" alt="">

              <p class="text-dark mt-3 text-big"><strong>You must fill in the Consultant/Agent section fields
                  shown
                  above with the following information:</strong></p>
              <ul class="text-big">
                <li class="text-dark">1). <strong>Agency Name:</strong> Student-Stop (Grintex Technologies
                  Inc.)</li>
                <li class="text-dark">2). <strong>Agency City:</strong> Markham</li>
                <li class="text-dark">3). <strong>Agency Contact Number:</strong> 647 990 9507</li>
                <li class="text-dark">4). <strong>Agency Email:</strong> siddharth.gautam@student-stop.com
                </li>
              </ul>

              <a (click)="analyse('Open Bank')"
                href="https://cadigital.icicibank.com/SPPIndia/apps/services/www/SPP/desktopbrowser/default/index.html#/login"
                target="_blank" class="btn btn-square btn-secondary btn-large mt-3">Create Account on Bank
                website</a>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-4" *ngIf="bankOpen">
          <div class="form-group">
            <label for="">Do you have CAD 10,000 added into a GIC account in your Canadian bank
              account?</label>
            <select class="form-control" (blur)="validatehaveBalance(haveAmt.value)"
              (change)="validatehaveBalance(haveAmt.value)" [class.is-invalid]="haveBalanceHasError && haveAmt.touched"
              name="haveAmt" ngModel #haveAmt="ngModel" [(ngModel)]="bankModel.haveAmt"
              (change)="changeCadValue($event.target.value)" required>
              <option value="default" selected>Select option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-12 mt-4" *ngIf="cadValue">
          <div class="form-group">
            <label for=""><strong>Open a Student Visa eligible GIC</strong></label>
            <a (click)="analyse('GICEligible')" href="https://www.icicibank.ca/personal/newcomers/overview.page "
              target="_blank" class="ml-3 btn btn-square btn-secondary">Go to Site</a>
          </div>
        </div>


        <div class="col-md-12 mt-4" *ngIf="loanValue">
          <div class="card">
            <div class="card-header">Important Information</div>
            <div class="card-body p-4">
              <div class="form-group m-0">
                <div class="checkbox checkbox-dark m-squar">
                  <input id="loadCheckbox" type="checkbox" name="loadCheckbox" ngModel #loadCheckbox="ngModel"
                    [(ngModel)]="bankModel.loadCheckbox" (change)="changeLoanCheckbox(loadCheckbox.value)">
                  <label class="text-dark mt-0" for="loadCheckbox">I hereby acknowledge and Understand that: (a) ICICI
                    Bank Limited, India (“ICICI India”) is an Indian bank that is not authorized to conduct any banking
                    or financial services business in Canada, and the products and services offered by ICICI India
                    (“India Products”) may be outside the purview of Canadian regulators; (b) India Products are offered
                    and administered by ICICI India exclusively outside Canada; (c) my rights and remedies in
                    relation to India Products lie exclusive against ICICI India, and I waive any right of action
                    against any Canadian entity; (d) I approached Grintex Technologies Inc. and requested
                    information regarding the availability of India Products, and Grintex Technologies Inc. did
                    not advise or solicit these India Products to me; and (e) all information provided by me,
                    including my personal information will be shared with ICICI India and its group companies, who
                    may collect, use, retain and process in locations including India.
                  </label>
                </div>
              </div>
              <p class="text-dark"><strong>Please note:</strong> Student-Stop cannot guarantee loans. All
                decisions in relation to offering of Education Loans shall be at the sole discretion of ICICI
                Group. Submission of an application by a student shall not guarantee the provision of an
                Education Loan.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <button
          [disabled]="bankForm.invalid || statusHasError || haveBalanceHasError || haveLaonError || haveLaonCheckBoxError"
          (click)="submitBanking()" class="btn btn-square btn-secondary">Submit</button>
      </div>
    </form>
  </div>
</div>

<mat-tab-group class="wow animate__animated animate__fadeInUp">
  <mat-tab label="Overview">
    <div class="card text-white bg-primary mb-3 wow animate__animated animate__fadeInUp">
      <div class="card-header">Why do I need to open a Canadian bank account?</div>
      <div class="card-body">
        <p class="card-text">A Bank account is a pre-requisite for many Study Permit and Visa application
          streams.
          For students applying under the Student Direct Stream Program(most students), having a Guaranteed
          Investment Certificate
          is a mandatory requirement.
          For students who are applying through other programs, proof of funds is usually a mandatory
          requirement. Using a Canadian bank account will help
          in fulfilling this and will also let you prepare in advance for your stay in Canada.</p>
      </div>
    </div>
    <div class="card text-white bg-primary mb-3 wow animate__animated animate__fadeInUp" style="max-width: 100rem;">
      <div class="card-header">How much funds do I need to transfer to my bank account in order to meet the
        IRCC
        requirement?
      </div>
      <div class="card-body">

        <table class="w-100 mt-2 mb-2" border="0">
          <thead></thead>
          <tbody>
            <tr>
              <td colspan="2">Through wire transfer, please deposit a minimum of CAD 10,175 into your Student
                Account as per the following breakdown(this is approximately Rs. 5,60,435 but may fluctuate
                based on
                exchange rate).</td>
            </tr>
            <tr>
              <td width="20%">
                <p>CAD 10,000 <br> CAD 11,000 (for Quebec)</p>
              </td>
              <td width="80%">
                <p>to be deposited into your Student Account (or the amount specified by Immigration, Refugees
                  and <br> Citizenship Canada for the province of your arrival);</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CAD 150</p>
              </td>
              <td>
                <p>to cover the non-refundable account processing fee;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CAD 25</p>
              </td>
              <td>
                <p>to cover any other corresponding bank charges, if applicable.</p>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <p>Please ensure that funds are wired from your bank account in one single transaction - do
                  not send multiple deposits into the account before arriving Canada.</p>
                <p>Upon receipt of your funds, an Investment Balance Confirmation will be sent to you via our
                  secure mailbox.&nbsp; We recommend that you print a copy of this confirmation for your
                  records. Should there be any excess amount after deducting the corresponding bank charges
                  and
                  processing fee, it will remain in your account.</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p><strong>Note:</strong>

          <span> 1).International wire transfers may take approximately 5 business days (or more) to reach the
            Bank in Canada. </span>
          <br>
          <span> <strong> 2).</strong>When you are applying for a student visa / permit, it is important to
            attach
            a copy of the Investment Balance Confirmation with your application when submitting it to the
            Canadian Visa office in your home country.</span></p>
      </div>
    </div>
    <br>
    <h3 class="wow animate__animated animate__fadeInUp">Student GIC Program (Guaranteed Investment Certificate
      Program)</h3><br>
    <div class="row">
      <div class="card text-white bg-primary mb-3 res-width">
        <div class="card-header">What is a Student GIC?</div>
        <div class="card-body">
          <p class="card-text">A Student GIC is an investment program. Through the program that we offer
            with our
            partner, you can add funds into your GIC and you will receive a portion of your investment plus
            interest
            every month for a 12-month period. The fee to participate in this program is CAD 150,
            non-refundable.
            You will be guarenteed the safety of funds that you put in.</p>
        </div>
      </div>
      <div class="card text-white bg-primary mb-3 res-width">
        <div class="card-header">Do I need a Student GIC?</div>
        <div class="card-body">
          <p class="card-text">If you are applying for the study permit and visitors visa under the Student
            Direct
            Stream Program(SDS), a requirement as specified by Immigration, Refugees & Citizenship Canada
            (IRCC),
            is
            to provide a GIC of at least CAD$ 10,000. Most students will apply under SDS because the usual
            processing time is 20 days. For non-SDS applications as well, most students will need a Student
            GIC to
            show proof of funds. We can facilitate this with our partner bank.</p>
        </div>
      </div>
      <div class="card text-white bg-primary mb-3 res-width">
        <div class="card-header">Who can apply and what are the steps?
        </div>
        <div class="card-body">
          <p class="card-text">Individuals who are citizens of and holding a valid passport issued by India,
            China,
            Phillipines, Vietnam, Morocco or Senegal.</p>
        </div>
      </div>
    </div>

    <div class="card text-white bg-primary mb-3 wow animate__animated animate__fadeInUp" style="max-width: 100rem;">
      <div class="card-header">Overview of the application process:</div>
      <div class="card-body">
        <h5 class="card-title"></h5>
        <div class="card-text">
          <li>Complete and submit an online application.</li>
          <li>Deposit your funds into your Student Account</li>
          <li>Upon your arrival in Canada and after successful in-person verification, the bank will open a
            personal chequing account for you. They will also transfer the funds to your new chequing
            account, as well as open a GIC account.</li>
          <li>Register for the bank's Secure Mail Service.</li>
          <li>You will receive your Student Account number immediately on successful submission of the
            application.</li>
          <li>An Investment Balance Confirmation will be sent to your secure mailbox once the funds are
            credited
            to your Student Account. You can apply for a Canadian Study Permit with this confirmation.</li>
          <p class="mt-3">Note: If your Student Visa/Permit request is declined or cancelled or if you
            withdraw from
            your studies after arriving in Canada, you can request a full refund of CAD 10,000, or the
            remaining
            balance in
            your Student Account. You can refer to the Refund section of the bank's website for details.</p>

        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="FAQs">

    <div class="faq-wrapper">
      <ngb-accordion class="mb-0" #a="ngbAccordion">
        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                What is the Student GIC Program? <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>Through the Student GIC Program, a client can open a Student Account
              in Canada from India, and invest in a Guaranteed Investment Certificate (GIC) in Canada.</p>
            <p>A GIC is a Canadian investment instrument (like a Fixed Deposit in India) that offers a
              guaranteed interest rate over a fixed period of time. It is similar to a fixed deposit account
              in India.</p>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                Why should I apply for the Student GIC Program? <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            If you are applying to colleges in Canada under the Student Direct Stream you must purchase a
            GIC to fulfil the proof of funds requirement for your Canadian visa/study permit application.
            If you are enrolled in a college or university that does not participate in SDS, you may still
            apply for the Student GIC Program to meet the Canadian visa office in India’s proof of funds
            requirement. Another advantage of the program is that you will have access to a Canadian bank
            account once a Student account is set up in your name.
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                Is my investment secure? <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>Our partner bank is a member of Canada Deposit Insurance Corporation. The instruments and/or
              products that are eligible for deposit insurance from the Canada Deposit Insurance Corporation
              (CDIC) are listed in the Deposit Register, subject always to coverage limitations as outlined
              by CDIC.</p>
            <p>For further information, please visit the CDIC website or call: 1-800-461-CDIC (2342).</p>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                Will the Bank pay any interest under the program? <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            Yes. Interest rates will be posted on their website.
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                When can I deposit funds into my Student Account? <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            You can deposit funds into your Student Account at any time during the year. However, it is
            your responsibility to ensure that you apply for the program well in advance, so funds are
            deposited in your investment account before you apply for a study permit. Normally the bank
            needs around 4 business days to open a Student Account and around 3 business to credit the money
            into that account.
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                What documents are required to apply for the program? <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>To apply, you will need to provide the following documents:</p>

            <ol type="a">
              <li>Copy of passport pages (i) the page in your passport containing your photo, passport
                number, date of birth and other personal details, and (ii) the page in your passport with
                other information, e.g. your parent / guardian names and addresses. If the passport does not
                have a page that bears the name of your parents / guardian then please provide any other
                Government issued identification document that bears all your personal information alongside
                the details of your parent(s) / guardian. The passport must also show your current address –
                if the passport does not show your address then please provide any other Government issued
                identification document that bears your personal information alongside your address, and
              </li>
              <li>Copy of acceptance/offer letter from a Canadian educational institution.</li>
            </ol>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                Where can I submit an application? <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            An application can only be submitted using the bank's online registration process.
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                Can I apply if the school I intend to attend is not listed as a participating college under
                the Student Direct Stream?
                <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>Yes, you can still apply even if your educational institution of choice does not participate
              in the Student Direct Stream.</p>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                Can I open a Student Account in a joint name?
                <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>No. Under the Student GIC Program, the Student Account can only be opened
              in the name of the student who is applying for a study permit under the Student Direct Stream.
            </p>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                Will the Bank refund my investment in case of any issues?
                <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>Yes, the Bank will refund your money under the following circumstances:</p>
            <ul class="disc">
              <li>Your visa/study permit application was declined, or</li>
              <li>Your application for admission to a Canadian educational institution was declined, or</li>
              <li>You have withdrawn enrolment from the Canadian educational institution before your arrival
                in Canada.</li>
            </ul>
            <p>Note: Students can withdraw from the Student GIC Program even after arriving in Canada, as
              long as they provide evidence that they have withdrawn enrolment from their Canadian
              educational institution and returned back to their home country. The investment will only be
              refunded after verification of a visa/study permit cancellation with the Canadian visa office.
            </p>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                Can I transfer funds to my Student Account through services other than a bank, such as a money
                exchange house (money transfer services)/third party services?
                <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>No. Funds must be wired from a bank where you hold an account exclusively in your name, or a
              joint account with your parent(s); otherwise, the money will be returned. You will be
              responsible for covering all administrative/intermediary bank fees incurred as a result of the
              return of funds.</p>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                Can a third-party deposit money into my Student Account?
                <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>No. You are the only person who can deposit funds into your Student Account, from your bank
              account in your home country. The bank account should be exclusively in your name, or a joint
              account with your parent(s). In the event of a refund, the money will be returned to this bank
              account where the original remittance was made from.</p>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                I have funded my Student Account, but never completed my study permit application. Can I use
                my existing Investment Balance Confirmation to re-apply for a study permit?
                <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>Yes, you can use an existing Investment Balance Confirmation when re-applying for a study
              permit. The Canadian visa office in India will validate the Investment Balance Confirmation
              with the bank directly.</p>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                Can I still use the same Student Account if I defer enrollment or change my educational
                institution??
                <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>Yes, you may still use the same Student Account if you have not requested for a refund.</p>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>

  </mat-tab>
</mat-tab-group>
