<div [formGroup]="admissionFormParent">
  <div class="col-md-12 my-2 mb-3">
    <div class="form-group mb-0">
      <div class="row">
        <div class="col-md-6">
          <div class="form-check">
            <input (change)="selectRadio($event.target.value)" class="form-check-input" type="radio" name="decided"
              id="idecided" value="idecided" [checked]="decided === 1">
            <label class="form-check-label" for="idecided">
              I know which universities/colleges I want to apply to.
            </label>
          </div>

        </div>
        <div class="col-md-6">
          <div class="form-check">
            <input (change)="selectRadio($event.target.value)" class="form-check-input" type="radio" name="decided"
              id="notDecided" value="notDecided" [checked]="decided === 2">
            <label class="form-check-label" for="notDecided">
              I do not know which universities/colleges to apply to.
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <div *ngIf="decided === 1">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Applying to*</label>
          <angular2-multiselect (click)="onSelectInterested()" [data]="interestedList"
            class="form-control angular-selector" formControlName="interested" name="interested"
            [settings]="interestedSettings">
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Select Degree Type*</label>
          <angular2-multiselect [data]="degreeList" class="form-control angular-selector" formControlName="degree"
            name="degree" [settings]="degreeSettings">
            <c-item>
              <ng-template let-item="item">
                <label>{{item.value}}</label>
              </ng-template>
            </c-item>
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="postal-code">Enter Current School/Institution/University*</label>
          <input class="form-control" type="text" name="currentStudy"
            placeholder="Enter Current School/Institution/University (eg. Hans Raj College (Delhi University))"
            formControlName="currentStudy">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Select Program* (Maximum 3)</label>
          <angular2-multiselect [data]="programsList" class="form-control angular-selector" formControlName="programs"
            name="programs" [settings]="programsSettings">
          </angular2-multiselect>
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-2">
      <p *ngIf="universityShow || collegesShow"><strong class="red-color">PGWP: </strong>
        Post-Graduate Work Permit
        Program</p>
      <p *ngIf="universityShow || collegesShow"><strong class="red-color">Note: </strong> If you
        intend to work in
        Canada after your education, please select
        universities that have programs with PGWP Eligible programs: Yes in the list </p>
    </div>
    <hr *ngIf="universityShow || collegesShow">
    <div class="row">
      <div class="col-md-12" *ngIf="universityShow">
        <div class="form-group">
          <label for="">Select Universities in order of preference* (Maximum 5)</label>
          <angular2-multiselect [data]="universityList" class="form-control angular-selector"
            formControlName="university" name="university" [settings]="universitySettings">
            <c-item>
              <ng-template let-item="item">
                <label *ngIf="!item.campus"> <strong>{{item.itemName}}, {{item.city}}</strong>
                  <br><span class="red-color">PGWP Eligible programs:
                    {{item.offersPGWPEligible}}</span></label>
                <label *ngIf="item.campus"><strong>{{item.itemName}}, {{item.city}},
                    {{item.campus}}
                    Campus </strong><br><span class="red-color">PGWP Eligible programs:
                    {{item.offersPGWPEligible}}</span></label>
              </ng-template>
            </c-item>
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-md-12" *ngIf="collegesShow">
        <div class="form-group">
          <label for="">Select Colleges in order of preference* (Maximum 5)</label>
          <angular2-multiselect [data]="collegesList" class="form-control angular-selector" formControlName="colleges"
            name="colleges" [settings]="collegesSettings">
            <c-item>
              <ng-template let-item="item">
                <label *ngIf="!item.campus"> <strong>{{item.itemName}}, {{item.city}}</strong>
                  <br><span class="red-color">PGWP Eligible programs:
                    {{item.offersPGWPEligible}}</span></label>
                <label *ngIf="item.campus"><strong>{{item.itemName}}, {{item.city}},
                    {{item.campus}}
                    Campus </strong><br><span class="red-color">PGWP Eligible programs:
                    {{item.offersPGWPEligible}}</span></label>
              </ng-template>
            </c-item>
          </angular2-multiselect>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="decided === 2">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Applying to*</label>
          <angular2-multiselect [data]="interestedList" class="form-control angular-selector"
            formControlName="interested" name="interested" [settings]="interestedSettings">
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Select Degree Type*</label>
          <angular2-multiselect [data]="degreeList" class="form-control angular-selector" formControlName="degree"
            name="degree" [settings]="degreeSettings">
            <c-item>
              <ng-template let-item="item">
                <label>{{item.value}}</label>
              </ng-template>
            </c-item>
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="postal-code">Enter Current School/Institution/University*</label>
          <input class="form-control" type="text" name="currentStudy"
            placeholder="Enter Current School/Institution/University (eg. Hans Raj College (Delhi University))"
            formControlName="currentStudy">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Select Program* (Maximum 3)</label>
          <angular2-multiselect [data]="programsList" class="form-control angular-selector" formControlName="programs"
            name="programs" [settings]="programsSettings">
          </angular2-multiselect>
        </div>
      </div>
    </div>
  </div>
</div>
