import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-add-school-dialog',
  templateUrl: './add-school-dialog.component.html',
  styleUrls: ['./add-school-dialog.component.scss']
})
export class AddSchoolDialogComponent implements OnInit {

  gradeForm: FormGroup;
  years = [];

  totalBoardValue = '';
  subjectMarkVal = '';

  constructor(
    public dialogRef: MatDialogRef<AddSchoolDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.gradeForm = new FormGroup({
      'class': new FormControl(''),
      'boardName': new FormControl('', Validators.required),
      'passedYear': new FormControl('', Validators.required),
      'subjects': this.fb.array([]),
      'percentage': new FormControl('', [Validators.required, Validators.maxLength(3)])
    });
    this.addSubject();

    for (var year = 1960; year <= 2030; year++) {
      this.years.push(year);
    }
  }

  addGrade(form) {
    this.dialogRef.close(form.value);
    this.gradeForm.reset();
  }

  boardSelect(value) {
    if (value === "CBSE" || "ICSE") {
      this.gradeForm.controls.percentage.enable();
      this.gradeForm.controls.percentage.setValidators([Validators.required, Validators.maxLength(3)]);
      this.totalBoardValue = '/100';
      this.subjectMarkVal = '';
    }
    if (value === "IB Diploma") {
      this.gradeForm.controls.percentage.enable();
      this.gradeForm.controls.percentage.setValidators([Validators.required, Validators.maxLength(1)]);
      this.totalBoardValue = '/45';
      this.subjectMarkVal = '/7';
    }
    if (value === "IB Certificate") {
      this.gradeForm.controls.percentage.disable();
      this.totalBoardValue = '';
      this.subjectMarkVal = '/7';
    }
    if (value === "State Board") {
      this.gradeForm.controls.percentage.enable();
      this.totalBoardValue = '/100';
      this.subjectMarkVal = '';
    }
  }

  // Form Functions
  subjects(): FormArray {
    return this.gradeForm.get('subjects') as FormArray
  }

  createItem(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      marks: ['', Validators.required],
    });
  }

  addSubject(): void {
    this.subjects().push(this.createItem());
  }

  removeSubject(index): void {
    this.subjects().removeAt(index)
  }

  closeDegreeDialog() {
    this.subjects().controls = [];
    this.dialogRef.close();
  }

}
