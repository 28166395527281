<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <h3 class="mt-4 mb-4">Status</h3>

    <div class="row">
      <div class="col-md-7 side-content">
        <div class="status-page-wizard">
          <ul>
            <li class="complete">
              <a>Your application has been successfully submitted on Student-Stop.
                <i class="ico fas fa-check ico-green"></i>
                <span class="desc"> </span>
              </a>
            </li>
            <li *ngIf="currentStatus?.documents.status === false else varifyDone" class="current">
              <a *ngIf="currentStatus?.documents.reupload === false;">Your documents are being verified by our
                team.
              </a>
              <a *ngIf="currentStatus?.documents.reupload === true;">Reupload the following Documents:
                <span class="desc">{{currentStatus?.documents.note}}</span>
                <button (click)="docDiv()" class="btn btn-danger btn-square">Upload Documents</button>
              </a>
            </li>
            <li *ngIf="currentStatus?.documents.status === false && currentStatus?.applicationSupport.step === 0"
              class="locked">
              <a>Application preparation support has not yet started.
                <i class="ico fas fa-lock"></i>
              </a>
            </li>
            <li *ngIf="currentStatus?.applicationSupport.step === 0 && currentStatus?.legalTeam.step === 0"
              class="locked">
              <a>The ICCRC Certified consultant has not yet started working on your application.
                <i class="ico fas fa-lock"></i>
              </a>
            </li>
            <li *ngIf="currentStatus?.legalTeam.step === 0 && currentStatus?.immigrationDepart.step === 0"
              class="locked">
              <a>Immigration department has not yet processed your application.
                <i class="ico fas fa-lock"></i>
              </a>
            </li>
            <ng-template #varifyDone>
              <li class="complete">
                <a>Your documents have been successfully verified by our team.
                  <i class="ico fas fa-check ico-green"></i>
                </a>
              </li>
              <li
                *ngIf="currentStatus?.documents.status === true && currentStatus?.applicationSupport.step === 0; else notApplicationSupport"
                class="current">
                <a>Our team is reviewing your submission and will contact you to prepare your application
                  form.
                  <br>
                  <small *ngIf="studyPermitService?.selectedPlan !== 'professional'" class="mt-2 d-block">Under
                    this plan, you are responsible for preparing your own SOP. We will help you in
                    preparing the application forms. You must submit the application yourself.</small>
                </a>
              </li>
              <li *ngIf="currentStatus?.applicationSupport.step === 1;" class="complete">
                <a>Your application form has been prepared.
                  <i class="ico fas fa-check ico-green"></i>
                </a>
                <span class="download-btn">Download your application form<a [href]="downloadLink"
                    class="btn btn-danger btn-square">Download Application</a></span>
              </li>

              <li
                *ngIf="currentStatus?.applicationSupport.step === 1 && currentStatus?.legalTeam.step === 0; else notLegalTeam"
                class="current">
                <a>The ICCRC Certified consultant is reviewing your application.</a>
              </li>
              <li *ngIf="currentStatus?.legalTeam.step === 1;" class="complete">
                <a>Your application has been submitted by the ICCRC Certified consultant.
                  <i class="ico fas fa-check ico-green"></i>
                </a>
                <span class="download-btn">Download your application<a [href]="downloadLinkIccrc"
                    class="btn btn-danger btn-square">Download Application</a></span>
              </li>

              <li *ngIf="currentStatus?.legalTeam.step === 1 && currentStatus?.immigrationDepart.step === 0;"
                class="current">
                <a>Awaiting decision by the immigration department.</a>
              </li>
              <li *ngIf="currentStatus?.legalTeam.step === 1 && currentStatus?.immigrationDepart.step === 1;"
                class="complete">
                <a>The immigration department has processed your application.
                  <i class="ico fas fa-check ico-green"></i>
                </a>
              </li>
              <li *ngIf="currentStatus?.legalTeam.step === 1 && currentStatus?.immigrationDepart.step === 2;"
                class="complete">
                <a>The immigration department has processed your application.
                  <i class="ico fas fa-check ico-green"></i>
                </a>
              </li>
              <li *ngIf="currentStatus?.legalTeam.step === 1 && currentStatus?.immigrationDepart.step === 1;"
                class="complete">
                <a>The immigration department has rejected your application.
                  <i class="ico fas fa-times ico-red"></i>
                </a>
              </li>
              <li *ngIf="currentStatus?.legalTeam.step === 1 && currentStatus?.immigrationDepart.step === 2;"
                class="complete">
                <a>The immigration department has accepted your application.
                  <i class="ico fas fa-check ico-green"></i>
                </a>
                <span class="download-btn">Download your application<a [href]="downloadLinkDepartment"
                    class="btn btn-danger btn-square">Download Application</a></span>
              </li>

              <ng-template #notApplicationSupport>
                <li *ngIf="currentStatus?.applicationSupport.step === 4 && currentStatus?.legalTeam.step === 0;"
                  class="current">
                  <a>The ICCRC Certified consultant is reviewing your application.</a>
                </li>
              </ng-template>

              <ng-template #notLegalTeam>
                <li
                  *ngIf="currentStatus?.legalTeam.step !== 1 && currentStatus?.legalTeam.step !== 0 && currentStatus?.applicationSupport.step === 1"
                  class="complete">
                  <a>Done <i class="ico fas fa-check ico-green"></i></a>
                </li>
              </ng-template>
            </ng-template>
          </ul>
        </div>
      </div>
      <div class="col-md-5">
        <div class="status-page-wizard">
          <ul class="scroll">
            <div class="chat-div" *ngFor="let item of currentStatus?.stepsUpdate">
              <img src="/assets/images/profile-pic.png" alt="Profile Pic">
              <p>{{item.note}}</p>
              <span class="time">by {{item.sender}} on {{item.date | date}}</span>
            </div>
          </ul>
        </div>
      </div>
      <div class="col-md-12">
        <hr>
        <p class="pl-2"><strong>Get your payment of study permit plan invoice:</strong>
          <span class="download-btn pl-2">
            <a target="blank" class="btn btn-danger btn-square" [href]="downloadLinkInvoice">Download
              Invoice</a>
          </span></p>
      </div>
    </div>

    <ng-template #documentDialog let-tablename let-ref="dialogRef">
      <div class="card">
        <div class="card-header">Select Document
          <button class="close" aria-label="Close" (click)="onDialogClose()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="card-body">
          <div *ngIf="loading" class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="alert alert-info dark mt-0" role="alert">
            <p>Please upload documents only in JPEG, JPG & PDF formats with a maximum size of 4MB. Other
              formats are not
              accepted. </p>
          </div>
          <form class="form-group box-form" novalidate>
            <div class="row">
              <div class="col-md-12">
                <div class="input-group">
                  <input type="text" class="form-control" readonly>
                  <div class="input-group-btn">
                    <span class="fileUpload btn btn-danger">
                      <span class="upl" id="upload">Select Document</span>
                      <input type="file" class="upload up btn btn-primary" id="up" [name]="tablename"
                        (change)="selectFile($event)" required />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-3">
              <button type="submit" class="btn btn-square btn-primary w-100" [disabled]="!documentValidation"
                (click)="uploadDocumentsFile(tablename.name)">Upload</button>
            </div>
            <div id="file_error"></div>
          </form>
        </div>
      </div>
    </ng-template>

  </div>
</div>

<div id="reuploadDiv" class="card" *ngIf="currentStatus?.documents.reupload === true">
  <div class="card-body">
    <h3 class="mt-2 mb-4">Documents Reupload:</h3>
    <div class="row it" *ngFor="let item of currentStatus?.reuploadDocuments; let i = index">
      <div class="col-sm-12" id="one" *ngIf="uploadDocumentsData.length > 0">
        <div id="uploader">
          <div class="row uploadDoc">
            <div class="col-sm-6">
              <h4>{{item.name}} <span class="red-color" *ngIf="item.mandatory">(Mandatory)</span> <span
                  *ngIf="!item.mandatory">(Not Mandatory)</span></h4>
            </div>
            <div class="col-sm-6 text-right">
              <button *ngIf="item.valid === false; else uploadDone" class="btn btn-square btn-primary mr-2"
                (click)="uploadDocuments(documentDialog, item.name)">Reupload</button>
              <ng-template #uploadDone>
                <a target="blank" [href]="uploadDocumentsData[i]?.url" class="btn btn-square btn-primary mr-1">View</a>
                <a (click)="uploadDocuments(documentDialog, item.name)" class="btn btn-square btn-info mr-1">Update</a>
                <!-- <a (click)="removeDocuments(item.name)" class="btn btn-square btn-danger">X</a> -->
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-square btn-primary mt-2" [disabled]="!submitbtnValidation" (click)="reuploadConfirm()">Submit
      Uploaded Documents</button>
  </div>
</div>
