import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  url;
  userDetails;
  selectedServices;
  services = [];

  constructor(
    private _profile: ProfileService,
  ) { }

  ngOnInit() {
    this.selectedServices = new Object();
    this.url = environment.url;
    this._profile.getStudentDetails().subscribe((res) => {
      this.userDetails = res;
      if (res.selectedService) {
        for (let service of res.selectedService.servicesName) {
          this.selectedServices[service.name] = new Array();
          this.selectedServices[service.name].push(service.need);
          this.selectedServices[service.name].push(service.step);
          this.services.push(service.name);
        }
      }
    });
  }

}
