<div class="dashboard-box">

  <app-navbar></app-navbar>

  <app-sidebar></app-sidebar>

  <div class="dashboard-body no-banner">
    <div class="container-fluid">
      <div class="page-header">
        <h2>Starting Documents</h2>
      </div>
    </div>

    <div class="container-fluid">
      <div class="card">
        <app-doc-upload docType="Starting Document"></app-doc-upload>
      </div>
    </div>
    <app-site-footer></app-site-footer>
  </div>
</div>
