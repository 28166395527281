import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { JobsService } from 'src/app/services/jobs.service';
import * as $ from 'jquery'

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss']
})
export class JobsListComponent implements OnInit {

  dialogRef;
  selectedFile;
  documentValidation = false;
  loading = false;
  jobSearch = false;
  jobs: any = [];
  allJobs;
  appliedJobs: any = [];
  searchForm: FormGroup;

  constructor(
    public dialog: MatDialog,
    public _jobs: JobsService
  ) { }

  ngOnInit(): void {
    this.getJobs();
    this.getAppliedJobs();

    this.searchForm = new FormGroup({
      // 'title': new FormControl('', Validators.required),
      'location': new FormControl('', Validators.required)
    });

    $(document).on('change', '.up', function () {
      var names: any = [];
      var length = $(this).get(0).files.length;
      for (var i = 0; i < $(this).get(0).files.length; ++i) {
        names.push($(this).get(0).files[i].name);
      }
      if (length > 2) {
        var fileName = names.join(', ');
        $(this).closest('.form-group').find('.form-control').attr("value", length + " files selected");
      }
      else {
        $(this).closest('.form-group').find('.form-control').attr("value", names);
      }
    });
  }

  getJobs() {
    this._jobs.getJobs().subscribe(
      res => {
        const jobs = res
        this.allJobs = res;
        this.jobs = jobs;
      },
      err => {
        console.log(err)
      }
    )
  }

  getAppliedJobs() {
    this._jobs.getAppliedJobs().subscribe(
      res => {
        this.appliedJobs = res;
      },
      err => {
        console.log(err)
      }
    )
  }

  searchJob() {
    this.jobSearch = true;
    const jobs = this.searchKey(this.allJobs, this.searchForm.value.location)
    this.jobs = jobs
  }

  searchKey(arr, value) {
    return arr.filter((obj) => JSON.stringify(obj).toLowerCase().includes(value.toLowerCase()))
  }

  openForm(content, id) {
    this.dialogRef = this.dialog.open(content, {
      width: '800px',
      data: { id },
      hasBackdrop: false,
      autoFocus: false
    });
  }

  selectFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const file_size = event.target.files[0].size;
      const fileType = file['type'];
      const validImageTypes = ['application/pdf', 'application/msword'];
      if (file_size > 4194304) {
        $("#file_error").html("File size must less then 4MB. Compress file <a target='blank' href='https://www.wecompress.com/en/'>Here</a>").show().delay(5000).fadeOut('slow');
        $("#uploadShow").css("border: 1px solid red");
        this.selectedFile = null;
        this.documentValidation = false;
      } else if (!validImageTypes.includes(fileType)) {
        $("#file_error").html("Invalid Document only Accept doc and pdf").show().delay(5000).fadeOut('slow');;
        $("#uploadShow").css("border: 1px solid red");
        this.selectedFile = null;
        this.documentValidation = false;
      } else {
        this.selectedFile = file;
        this.documentValidation = true;
      }
    }
  }

  applyJobSubmit(id) {
    this.loading = true;
    const formData = new FormData();
    formData.append('document', this.selectedFile);
    formData.append('job_id', id);

    this._jobs.applyJob(formData).subscribe((res) => {
      this.loading = false;
      this.dialogRef.close();
      this.selectedFile = null;
      this.documentValidation = false;
    })
  }

  onDialogClose() {
    this.dialogRef.close();
  }

}
