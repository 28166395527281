<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeInUp">STUDENT LOAN & CANADIAN BANK ACCOUNT</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li><a href="/">Services</a></li>
              <li class="active">Banking</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-detail">
    <div class="container">
      <h2 class="wow animate__animated animate__fadeInUp white-text">Student Loans made Easy !</h2>
      <p class="wow animate__animated animate__fadeInUp white-text">Through our partner Banks, we fund your
        tution fees and your cost of living in Canada through affordable
        Student loans, even before you start on your journey.
      </p>
      <p class="white-text"><a routerLink="/register" style="color:#ff9600;"> Register Now</a>, to explore more and get
        started today!</p>

    </div>
    <div class="container">
      <h2 class="wow animate__animated animate__fadeInUp white-text">Opening a Canadian Bank Account</h2>
      <p class="wow animate__animated animate__fadeInUp white-text"> Having funds available in a Canadian Bank account
        in
        the form of GIC(Guaranteed Investment Certificate) is
        a requirement for most study permit streams. At <strong>Student-Stop</strong>, we support you through our
        authorized partner banks, in opening a Canadian bank account and funding your GIC, from the comfort of your
        home. </p>
      <div class="challange-solution-section clearfix wow animate__animated animate__fadeInUp">
        <h3 class="wow animate__animated animate__fadeInUp white-text">Services</h3>
        <ul class="wow animate__animated animate__fadeInUp white-text">
          <li class="white-text"><span class="icon white-text"><img alt=""
                src="assets/images/check.png"></span>Financing Student Loans</li>
          <li class="white-text"><span class="icon white-text"><img alt="" src="assets/images/check.png"></span>Opening
            Canadian Bank Account</li>
        </ul>
      </div>
    </div>
  </section>

  <section class="service-inner-top-section wow animate__animated animate__fadeInUp">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="service-inner-content">
            <div class="sec-title">
              <div class="title">FINANCIAL ELLIGBILITY FOR STUDY PERMIT</div>
              <div class="separator"><span></span></div>
            </div>
            <h2>Proof of funds for Study Permit</h2>
            <p>To meet the proof of funds requirement for your study permit, we can support you with the following:</p>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Setup Canadian Bank Account</h4>
              <p>We can help you in setting up a Canadian Bank account through our authorized partner banks. Once funds
                are in the account, it will meet the
                requirement of having a Canadian bank account in your name and can be submitted for your study
                permit.</p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Guaranteed Investment Certificate (GIC)</h4>
              <p>Another alternate document that can be submitted to show proof of funds, is a Guaranteed Investment
                Certificate (GIC), from a participating Canadian financial institution. Our authorized partner
                banks can help in setting up a GIC for your study permit. <a routerLink="/register"
                  style="color:#ff9600;">Register</a> to learn more.</p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Proof of Loan</h4>
              <p>A student loan can make you eligible for your study permit. We can support eligible students
                in obtaining student loans through our authorized partner banks.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="why-choose-image wow animate__animated animate__fadeInUp">
            <img src="/assets/images/banking-banner.jpg" alt="image">
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-site-footer></app-site-footer>

</main>
