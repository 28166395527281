<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeIn">Webinar Registration</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">Webinar Registration</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="webinar-section">
    <div class="content-left">
      <span class="counter">15</span>
      <p>July 2022</p>
    </div>

    <div class="content-right">
      <div class="content">
        <p>Don't miss the upcoming Webinar</p>
        <h2>ONLINE WEBINAR ON 15th JULY 2022</h2>
        <ul id="countdown" class="countdown">
          <li class="clock-item"><span class="count-number days">{{days}}</span>
            <p class="days_text count-text">Days</p>
          </li>

          <li class="clock-item"><span class="count-number hours">{{hours}}</span>
            <p class="hours_text count-text">Hours</p>
          </li>

          <li class="clock-item"><span class="count-number minutes">{{minutes}}</span>
            <p class="minutes_text count-text">Min</p>
          </li>

          <li class="clock-item"><span class="count-number seconds">{{seconds}}</span>
            <p class="seconds_text count-text">Sec</p>
          </li>
        </ul>
      </div>
    </div>

  </section>

  <div class="contact-us-section">
    <div class="container">
      <div class="upper-box">
        <div class="container">
          <div *ngIf="!registered">
            <div class="sec-title text-center">
              <div class="title wow animate__animated animate__fadeInUp">Register For The Webinar</div>
              <div class="separator center"><span></span></div>
            </div>
            <div class="row">
              <div class="col-md-9 mx-auto wow animate__animated animate__fadeInRight">
                <div class="from-wraper">
                  <form class="contact-form" [formGroup]="webinarForm" (ngSubmit)="webinarFormSubmit()" F novalidate>
                    <div class="row">
                      <div class="col-md-12">
                        <input type="text" placeholder="Name*" class="form-control mt-0" name="name"
                          formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                          <div *ngIf="f.name.errors.required">Name is a required.</div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <input type="email" placeholder="Email*" class="form-control" name="email"
                          formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email is a required.</div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <input type="text" placeholder="Phone Number*" class="form-control" name="phone"
                          formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                          <div *ngIf="f.phone.errors.required">Phone Number is a required.</div>
                        </div>
                      </div>
                      <div class="col-md-12 text-center">
                        <button type="submit" class="submit-btn">Submit</button>
                        <div *ngIf="msgSuccess" class="alert alert-success dark alert-dismissible fade show"
                          role="alert">
                          <p>{{msgSuccess}}</p>
                          <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                              aria-hidden="true">×</span></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="registered">
            <div class="sec-title text-center">
              <div class="title wow animate__animated animate__fadeInUp">Thanks For The Registered</div>
              <div class="separator center"><span></span></div>
            </div>
            <div class="text-center">
              <h4>Click on the below button to join us during the live webinar on the <br> 15nd July 2022 at 8:30 pm
                IST.
              </h4>
              <a class="btn btn-primary mt-2" target="_blank" href="https://meet.google.com/ctx-przp-nkh">Joining
                Link</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-site-footer></app-site-footer>

</main>
