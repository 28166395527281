import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  separateDialCode: Boolean = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  // profile;
  loggedin: Boolean = false;
  showProfile: Boolean = true;
  referralCode;

  signupForm: FormGroup;
  error: string = '';

  submitted: Boolean = false;
  errorClosed: Boolean = false;

  constructor(
    private _profile: ProfileService,
    private _auth: AuthService,
    private _router: Router,
    private router: ActivatedRoute,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.router.queryParams.subscribe(params => {
      this.referralCode = params['referral'];
    });
    const token = this._profile.getToken();
    if (token) {
      this._router.navigate(['/profile']);
      this.loggedin = true;
      this.showProfile = false;
    } else {
      this.loggedin = false;
      this.showProfile = true;
    }

    this.signupForm = new FormGroup({
      'firstname': new FormControl(null, Validators.required),
      'middlename': new FormControl(null),
      'lastname': new FormControl(null, Validators.required),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'phone': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]),
      'recaptchaReactive': new FormControl(null, Validators.required),
    })
  }

  get f() { return this.signupForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.signupForm.invalid) {
      return
    } else {
      if (this.referralCode) {
        const data = {
          ...this.signupForm.value,
          referralCode: this.referralCode
        }
        this._auth.registerUser(data).subscribe(
          res => {
            this.cookieService.set('token', res.token, { expires: new Date(new Date().setDate(new Date().getDate() + 7)) });
            this._router.navigate(['/journey-begin']);
          },
          err => {
            if (err.statusText === "Unknown Error") {
              this.errorClosed = true;
              this.error = 'Something wrong in server. Please try again later.';
              setTimeout(() => {
                this.errorClosed = false;
                this.error = '';
              }, 3000);
            } else {
              this.errorClosed = true;
              this.error = err.error;
              setTimeout(() => {
                this.errorClosed = false;
                this.error = '';
              }, 3000);
            }
          }
        );
      } else {
        this._auth.registerUser(this.signupForm.value).subscribe(
          res => {
            this.cookieService.set('token', res.token, { expires: new Date(new Date().setDate(new Date().getDate() + 7)) });
            this._router.navigate(['/journey-begin']);
          },
          err => {
            if (err.statusText === "Unknown Error") {
              this.errorClosed = true;
              this.error = 'Something wrong in server. Please try again later.';
              setTimeout(() => {
                this.errorClosed = false;
                this.error = '';
              }, 3000);
            } else {
              this.errorClosed = true;
              this.error = err.error;
              setTimeout(() => {
                this.errorClosed = false;
                this.error = '';
              }, 3000);
            }
          }
        );
      }
    }
  }

}
