import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrimmigrationService {

  private _completeProfile = environment.server;

  constructor(private http: HttpClient) { }

  primmigrationDoc() {
    return this.http.post<any>(this._completeProfile + '/service/permanent-resident/documents-submit', null, { responseType: 'text' as 'json' });
  }

  allDoneAdmission() {
    return this.http.post<any>(this._completeProfile + '/service/permanent-resident/all-done/submit', null);
  }
}
