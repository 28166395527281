export class SelectOptionData {
    collegeList() {
        const collegesList = [
            {
                "id": 1,
                "itemName": "A1-Global College of Health, Business, and Technology",
                "dli": "O19394699296",
                "city": "Mississauga",
                "campus": "West",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 2,
                "itemName": "A1-Global College of Health, Business, and Technology",
                "dli": "O19394699296",
                "city": "Mississauga",
                "campus": "East",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 3,
                "itemName": "ABC Access Business College",
                "dli": "O242732890457",
                "city": "North York",
                "campus": "North York",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 4,
                "itemName": "ABM College of Business and Technology",
                "dli": "O242632228347",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 5,
                "itemName": "ACE Acumen Academy",
                "dli": "O242529990457",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 6,
                "itemName": "ACE Acumen Academy",
                "dli": "O242529990457",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 7,
                "itemName": "AIMS College Healthcare Management Technology",
                "dli": "O135631727752",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 8,
                "itemName": "APlus Institute",
                "dli": "O19304163112",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 9,
                "itemName": "ARV Canada College",
                "dli": "O19394940254",
                "city": "Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 10,
                "itemName": "Absolute Aviation",
                "dli": "O134231224842",
                "city": "Wetaskiwin",
                "campus": "Wetaskiwin",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 11,
                "itemName": "Academy Canada",
                "dli": "O19441333188",
                "city": "Corner Brook",
                "offersPGWPEligible": "No",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 12,
                "itemName": "Academy Canada",
                "dli": "O19441333188",
                "city": "St. John’s",
                "offersPGWPEligible": "No",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 13,
                "itemName": "Academy of Applied Pharmaceutical Sciences (AAPS) Inc.",
                "dli": "O19376386952",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 14,
                "itemName": "Academy of Cosmetology",
                "dli": "O214965234137",
                "city": "Dartmouth",
                "campus": "Dartmouth",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 15,
                "itemName": "Academy of Excellence Hair Design & Aesthetics Ltd.",
                "dli": "O123960086627",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 16,
                "itemName": "Academy of Fashion Design",
                "dli": "O110820050587",
                "city": "Saskatoon",
                "offersPGWPEligible": "No",
                "province": "Saskatchewan"
            },
            {
                "id": 17,
                "itemName": "Academy of Learning",
                "dli": "O19359144722",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 18,
                "itemName": "Academy of Learning Calgary",
                "dli": "O19273743562",
                "city": "Calgary",
                "campus": "Calgary South",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 19,
                "itemName": "Academy of Learning Calgary",
                "dli": "O19273743562",
                "city": "Calgary",
                "campus": "Calgary North East",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 20,
                "itemName": "Academy of Learning Career College – Thunder Bay",
                "dli": "O260518481572",
                "city": "Thunder Bay",
                "campus": "Thunder Bay",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 21,
                "itemName": "Academy of Learning Career and Business College",
                "dli": "O260328722832",
                "city": "Owen Sound",
                "campus": "Owen Sound",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 22,
                "itemName": "Academy of Learning Career and Business College",
                "dli": "O139832935932",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 23,
                "itemName": "Academy of Learning Career and Business College",
                "dli": "O132633122837",
                "city": "Belleville",
                "campus": "Belleville",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 24,
                "itemName": "Academy of Learning Career and Business College",
                "dli": "O112937383987",
                "city": "Etobicoke",
                "campus": "Etobicoke",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 25,
                "itemName": "Academy of Learning Career and Business College",
                "dli": "O19516732252",
                "city": "Scarborough",
                "campus": "Lawrence",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 26,
                "itemName": "Academy of Learning Career and Business College",
                "dli": "O19859544417",
                "city": "Toronto",
                "campus": "Bay/Bloor",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 27,
                "itemName": "Academy of Learning Career and Business College",
                "dli": "O262792573012",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 28,
                "itemName": "Academy of Learning College",
                "dli": "O128609354927",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 29,
                "itemName": "Academy of Learning College",
                "dli": "O19305245022",
                "city": "Hamilton",
                "campus": "Hamilton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 30,
                "itemName": "Academy of Learning College - Abbotsford",
                "dli": "O212477056057",
                "city": "Langley",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 31,
                "itemName": "Academy of Learning College - Abbotsford",
                "dli": "O212477056057",
                "city": "Abbotsford",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 32,
                "itemName": "Academy of Learning College - Halifax",
                "dli": "O111596476281",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 33,
                "itemName": "Academy of Learning College - Kingston",
                "dli": "O19303790322",
                "city": "Kingston",
                "campus": "Kingston",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 34,
                "itemName": "Academy of Learning College - Langley",
                "dli": "O212477063807",
                "city": "Langley",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 35,
                "itemName": "Academy of Learning College - Victoria",
                "dli": "O19395300024",
                "city": "Kelowna",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 36,
                "itemName": "Academy of Learning College - Victoria",
                "dli": "O19395300024",
                "city": "Kamloops",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 37,
                "itemName": "Academy of Learning College - Victoria",
                "dli": "O19395300024",
                "city": "Nanaimo",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 38,
                "itemName": "Academy of Learning College - Victoria",
                "dli": "O19395300024",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 39,
                "itemName": "Academy of Learning College – Richmond",
                "dli": "O110096211204",
                "city": "Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 40,
                "itemName": "Academy of Learning College – Saskatoon",
                "dli": "O212421004567",
                "city": "Saskatoon",
                "offersPGWPEligible": "No",
                "province": "Saskatchewan"
            },
            {
                "id": 41,
                "itemName": "Academy of Learning College – Surrey",
                "dli": "O19361063652",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 42,
                "itemName": "Academy of Learning College – Vancouver – Broadway",
                "dli": "O19376530942",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 43,
                "itemName": "Academy of Learning Edmonton",
                "dli": "O19273755422",
                "city": "Edmonton",
                "campus": "Edmonton Down Town",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 44,
                "itemName": "Academy of Learning Edmonton",
                "dli": "O19273755422",
                "city": "Edmonton",
                "campus": "Edmonton West",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 45,
                "itemName": "Academy of Learning Edmonton South",
                "dli": "O19273765312",
                "city": "Edmonton",
                "campus": "Edmonton South",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 46,
                "itemName": "Academy of Learning Medicine Hat",
                "dli": "O19273743562",
                "city": "Medicine Hat",
                "campus": "Medicine Hat",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 47,
                "itemName": "Academy of Learning Red Deer",
                "dli": "O19273743562",
                "city": "Red Deer",
                "campus": "Red Deer",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 48,
                "itemName": "Academy of Learning Winnipeg North (Computerized Accounting)",
                "dli": "O143830057202",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 49,
                "itemName": "Academy of Learning – PEI",
                "dli": "O19220171562",
                "city": "Charlottetown",
                "campus": "Charlottetown",
                "offersPGWPEligible": "No",
                "province": "Prince Edward Island"
            },
            {
                "id": 50,
                "itemName": "Acadia College",
                "dli": "O110735702489",
                "city": "Langley",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 51,
                "itemName": "Académie de Dessin de Mode Richard Robinson Academy of Fashion Design",
                "dli": "O119063232417",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 52,
                "itemName": "Académie de beauté Gordon-Robertson",
                "dli": "O246872714018",
                "city": "Beaconsfield",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 53,
                "itemName": "Académie de l’entrepreneurship Québécois inc.",
                "dli": "O19338214982",
                "city": "Longueuil",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 54,
                "itemName": "Académie des pompiers",
                "dli": "O246874709166",
                "city": "Mirabel",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 55,
                "itemName": "Acsenda School of Management",
                "dli": "O19278931852",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 56,
                "itemName": "Adler Graduate Professional School",
                "dli": "O19376414752",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 57,
                "itemName": "Advantage English School E/J",
                "dli": "O112543961007",
                "city": "Whistler",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 58,
                "itemName": "Adventure Aviation",
                "dli": "O140240413612",
                "city": "Grande Prairie",
                "campus": "Grande Prairie",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 59,
                "itemName": "Aesthetics Pro",
                "dli": "O261131738772",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 60,
                "itemName": "Agrinova",
                "dli": "O19359010241",
                "city": "Alma",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 61,
                "itemName": "Ahmadiyya Muslim Jama’at Canada Inc.",
                "dli": "O114556020427",
                "city": "Maple",
                "campus": "Maple",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 62,
                "itemName": "Air Richelieu",
                "dli": "O212472259601",
                "city": "Longeuil",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 63,
                "itemName": "Ajna Yoga Teacher Training",
                "dli": "O246351578557",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 64,
                "itemName": "Alberta Ballet School",
                "dli": "O111146866787",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 65,
                "itemName": "Alberta Bible College",
                "dli": "O122912253227",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 66,
                "itemName": "Alberta College of Acupuncture and Traditional Chinese Medicine",
                "dli": "O212235983277",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 67,
                "itemName": "Alberta College of Massage Therapy",
                "dli": "O133421346312",
                "city": "Edmonton",
                "campus": "Red Deer",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 68,
                "itemName": "Alberta College of Massage Therapy",
                "dli": "O133421346312",
                "city": "Edmonton",
                "campus": "Lloydminster",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 69,
                "itemName": "Alberta College of Massage Therapy",
                "dli": "O133421346312",
                "city": "Edmonton",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 70,
                "itemName": "Alberta College of Massage Therapy",
                "dli": "O133421346312",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 71,
                "itemName": "Alberta College of Massage Therapy",
                "dli": "O133421346312",
                "city": "Edmonton",
                "campus": "Edmonton – South",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 72,
                "itemName": "Alberta College of Massage Therapy",
                "dli": "O133421346312",
                "city": "Edmonton",
                "campus": "Fort McMurray",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 73,
                "itemName": "Alberta College of Massage Therapy",
                "dli": "O133421346312",
                "city": "Edmonton",
                "campus": "Grande Prairie",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 74,
                "itemName": "Alexander College",
                "dli": "O19347185182",
                "city": "Burnaby",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 75,
                "itemName": "Alexander College",
                "dli": "O19347185182",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 76,
                "itemName": "Algonquin Careers Academy",
                "dli": "O19319175222",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 77,
                "itemName": "Algonquin Careers Academy",
                "dli": "O19329829942",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 78,
                "itemName": "Algonquin College",
                "dli": "O19358971022",
                "city": "Ottawa",
                "campus": "Algonquin College of Applied Arts and Technology",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 79,
                "itemName": "Algonquin College",
                "dli": "O19358971022",
                "city": "Pembroke",
                "campus": "Algonquin College of Applied Arts and Technology",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 80,
                "itemName": "Algonquin College",
                "dli": "O19358971022",
                "city": "Pembroke",
                "campus": "Pembroke",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 81,
                "itemName": "Algonquin College",
                "dli": "O19358971022",
                "city": "Perth",
                "campus": "Algonquin College of Applied Arts and Technology",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 82,
                "itemName": "Algonquin College",
                "dli": "O19358971022",
                "city": "Perth",
                "campus": "Perth",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 83,
                "itemName": "Alison College",
                "dli": "O263079866852",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 84,
                "itemName": "Alkan Air Ltd.",
                "dli": "O259417724532",
                "city": "Whitehorse",
                "offersPGWPEligible": "Yes (details for Graduates of certain Quebec schools)",
                "province": "Yukon"
            },
            {
                "id": 85,
                "itemName": "All Body Laser Corp. Training Institute",
                "dli": "O19394940220",
                "city": "Port Coquitlam",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 86,
                "itemName": "Alpha College of Business and Technology",
                "dli": "O19555982922",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 87,
                "itemName": "Alpha College of Business and Technology",
                "dli": "O19555982922",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 88,
                "itemName": "Anderson College of Health, Business and Technology",
                "dli": "O19711641837",
                "city": "Hamilton",
                "campus": "Hamilton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 89,
                "itemName": "Anderson College of Health, Business and Technology",
                "dli": "O19711641837",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 90,
                "itemName": "Anderson College of Health, Business and Technology",
                "dli": "O19711641837",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 91,
                "itemName": "Anderson College of Health, Business and Technology",
                "dli": "O19386060342",
                "city": "Toronto",
                "campus": "North York",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 92,
                "itemName": "Anderson College of Health, Business and Technology",
                "dli": "O19386060342",
                "city": "Toronto",
                "campus": "Downtown",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 93,
                "itemName": "Anne’s Language House",
                "dli": "O246864157347",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 94,
                "itemName": "Apex Language and Career College",
                "dli": "O19330669492",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 95,
                "itemName": "Arbutus College of Communication Arts, Business & Technology",
                "dli": "O19219834012",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 96,
                "itemName": "Art & Technique Academy of Hairstyling and Esthetics",
                "dli": "O19530458702",
                "city": "Oshawa",
                "campus": "Oshawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 97,
                "itemName": "Ashton College",
                "dli": "O19219876582",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 98,
                "itemName": "Ashton College",
                "dli": "O19219876582",
                "city": "Abbotsford",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 99,
                "itemName": "Assiniboine Community College",
                "dli": "O19201282282",
                "city": "Brandon",
                "campus": "Brandon",
                "offersPGWPEligible": "Yes",
                "province": "Manitoba"
            },
            {
                "id": 100,
                "itemName": "Atelier-école Les Cèdres",
                "dli": "O246875390901",
                "city": "Les Cèdres",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 101,
                "itemName": "Atlantic Business College Ltd.",
                "dli": "O19273824102",
                "city": "Fredericton",
                "campus": "Fredericton",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 102,
                "itemName": "Atlantic Flight Attendant Academy",
                "dli": "O136991020372",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 103,
                "itemName": "Atlantic School of Theology",
                "dli": "O19391556837",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 104,
                "itemName": "Aurora College",
                "dli": "O19348462392",
                "city": "Fort Smith",
                "campus": "Thebacha",
                "offersPGWPEligible": "Yes",
                "province": "Northwest Territories"
            },
            {
                "id": 105,
                "itemName": "Aurora College",
                "dli": "O19348462392",
                "city": "Inuvik",
                "campus": "Aurora",
                "offersPGWPEligible": "Yes",
                "province": "Northwest Territories"
            },
            {
                "id": 106,
                "itemName": "Aurora College",
                "dli": "O19348462392",
                "city": "Yellowknife",
                "campus": "Yellowknife",
                "offersPGWPEligible": "Yes",
                "province": "Northwest Territories"
            },
            {
                "id": 107,
                "itemName": "Aurora College",
                "dli": "O19348462392",
                "city": "Yellowknife",
                "campus": "North Slave",
                "offersPGWPEligible": "Yes",
                "province": "Northwest Territories"
            },
            {
                "id": 108,
                "itemName": "Automotive Training Centre",
                "dli": "O19394940276",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 109,
                "itemName": "Aveda Institute Toronto",
                "dli": "O111596476472",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 110,
                "itemName": "Aveda Institute Winnipeg (Hairstyling)",
                "dli": "O19375444982",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 111,
                "itemName": "Aviron Québec Collège Technique",
                "dli": "O246875391161",
                "city": "Québec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 112,
                "itemName": "Avola College Of Hairstyling and Esthetics",
                "dli": "O116458043605",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 113,
                "itemName": "BC College of Optics",
                "dli": "O19273931872",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 114,
                "itemName": "BITTS International Career College",
                "dli": "O118231504027",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 115,
                "itemName": "Bay College of Health Business and Technology",
                "dli": "O136148508342",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 116,
                "itemName": "Bay River College",
                "dli": "O121321694207",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 117,
                "itemName": "Beauty Institute Canada",
                "dli": "O212074977897",
                "city": "Oshawa",
                "campus": "Oshawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 118,
                "itemName": "Beta College of Business and Technology",
                "dli": "O124136581227",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 119,
                "itemName": "Beta College of Business and Technology",
                "dli": "O124136581227",
                "city": "Toronto",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 120,
                "itemName": "Biopterre - Centre de développement des bioproduits",
                "dli": "O19359010254",
                "city": "La Pocatière",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 121,
                "itemName": "BizTech College of Health Sciences, Business & Technology",
                "dli": "O19304029082",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 122,
                "itemName": "Blanche Macdonald Centre",
                "dli": "O19257719332",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 123,
                "itemName": "Blue Bird Flight Academy",
                "dli": "O19394940288",
                "city": "Chilliwack",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 124,
                "itemName": "Body Glamour Institute of Beauty by Anita",
                "dli": "O116475837152",
                "city": "North Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 125,
                "itemName": "Boucher Institute of Naturopathic Medicine",
                "dli": "O19219916952",
                "city": "New Westminster",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 126,
                "itemName": "Bow Valley College",
                "dli": "O19273769422",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 127,
                "itemName": "Brampton Flight Centre",
                "dli": "O19303958082",
                "city": "Caledon",
                "campus": "Caledon",
                "offersPGWPEligible": "Yes (details for Flight schools)"
            },
            {
                "id": 128,
                "itemName": "Brampton Skin Care Academy Inc.",
                "dli": "O260429274962",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 129,
                "itemName": "Brandon Flying Club",
                "dli": "O110095820687",
                "city": "Brandon",
                "campus": "Brandon",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Manitoba"
            },
            {
                "id": 130,
                "itemName": "Brantford Aviation College",
                "dli": "O138135814462",
                "city": "Brantford",
                "campus": "Brantford",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 131,
                "itemName": "Briercrest College and Seminary",
                "dli": "O19425193620",
                "city": "Caronport",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 132,
                "itemName": "Brighton College",
                "dli": "O19257044542",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 133,
                "itemName": "Brighton College",
                "dli": "O19257044542",
                "city": "Burnaby",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 134,
                "itemName": "British Columbia Institute of Technology",
                "dli": "O19330128542",
                "city": "North Vancouver",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 135,
                "itemName": "British Columbia Institute of Technology",
                "dli": "O19330128542",
                "city": "Richmond",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 136,
                "itemName": "British Columbia Institute of Technology",
                "dli": "O19330128542",
                "city": "Burnaby",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 137,
                "itemName": "British Columbia Institute of Technology",
                "dli": "O19330128542",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 138,
                "itemName": "Bryan College of Applied Health & Business Sciences",
                "dli": "O116458952783",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 139,
                "itemName": "Business and Technical Training College Inc.",
                "dli": "O112370832787",
                "city": "North York",
                "campus": "North York",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 140,
                "itemName": "C.C.E.C. Canadian Career Education College Ltd",
                "dli": "O262228554872",
                "city": "Vaughan",
                "campus": "Vaughan",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 141,
                "itemName": "CBBC Career College",
                "dli": "O137691023352",
                "city": "Sydney",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 142,
                "itemName": "CBBC Career College",
                "dli": "O137691023352",
                "city": "Sydney",
                "campus": "Sydney",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 143,
                "itemName": "CDE Collège",
                "dli": "O19338281175",
                "city": "Sherbrooke",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 144,
                "itemName": "CDI College (Winnipeg ) - [Intra-Oral Dental Assistant]",
                "dli": "O125015936327",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 145,
                "itemName": "CDI College of Business, Technology and Health Care",
                "dli": "O262084414542",
                "city": "Calgary",
                "campus": "Calgary South",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 146,
                "itemName": "CDI College of Business, Technology and Health Care",
                "dli": "O262084414542",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 147,
                "itemName": "CDI College of Business, Technology and Health Care",
                "dli": "O262084414542",
                "city": "Calgary",
                "campus": "Calgary city Centre",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 148,
                "itemName": "CDI College of Business, Technology and Health Care",
                "dli": "O262084414542",
                "city": "Edmonton",
                "campus": "Edmonton city Centre",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 149,
                "itemName": "CDI College of Business, Technology and Health Care",
                "dli": "O262084414542",
                "city": "Edmonton",
                "campus": "Edmonton North",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 150,
                "itemName": "CDI College of Business, Technology and Health Care",
                "dli": "O262084414542",
                "city": "Edmonton",
                "campus": "Edmonton West",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 151,
                "itemName": "CDI College of Business, Technology and Health Care",
                "dli": "O262084414542",
                "city": "Edmonton",
                "campus": "Edmonton South",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 152,
                "itemName": "CDI College of Business, Technology and Health Care",
                "dli": "O262084414542",
                "city": "Red Deer",
                "campus": "Red Deer",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 153,
                "itemName": "CDI College of Business, Technology and Healthcare",
                "dli": "O19394924591",
                "city": "Toronto",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 154,
                "itemName": "CDI College of Business, Technology and Healthcare",
                "dli": "O19394924591",
                "city": "Toronto",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 155,
                "itemName": "CDI College of Business, Technology and Healthcare",
                "dli": "O19394924591",
                "city": "Toronto",
                "campus": "North York",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 156,
                "itemName": "CDI College of Business, Technology and Healthcare",
                "dli": "O19394924591",
                "city": "Toronto",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 157,
                "itemName": "CG Masters School of 3D Animation & VFX",
                "dli": "O19240092592",
                "city": "New Westminster",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 158,
                "itemName": "CITI College of Canadian Careers",
                "dli": "O19304574722",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 159,
                "itemName": "CITI College of Canadian Careers",
                "dli": "O19304574722",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 160,
                "itemName": "CJ Health Care College",
                "dli": "O134227229202",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 161,
                "itemName": "CJ Health Care College",
                "dli": "O134227229202",
                "city": "Toronto",
                "campus": "Etobicoke",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 162,
                "itemName": "CLI College of Business, Health & Technology",
                "dli": "O117353642757",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 163,
                "itemName": "CLI College of Business, Health & Technology",
                "dli": "O129494912147",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 164,
                "itemName": "CLLC Halifax",
                "dli": "O19209875232",
                "city": "Halifax",
                "campus": "Duke",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 165,
                "itemName": "CLLC Halifax",
                "dli": "O19209875232",
                "city": "Halifax",
                "campus": "Citadel",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 166,
                "itemName": "CLLC Inc.",
                "dli": "O213116733767",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 167,
                "itemName": "CLLC Inc.",
                "dli": "O213116733767",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 168,
                "itemName": "CMU College of Makeup Art and Design",
                "dli": "O19305293142",
                "city": "Toronto",
                "campus": "Lombard Street and Adelaide Street East",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 169,
                "itemName": "CPA Atlantic",
                "dli": "O214966777617",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 170,
                "itemName": "CPA Western School of Business",
                "dli": "O213501325307",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 171,
                "itemName": "CRD College of Health Care",
                "dli": "O19283941562",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 172,
                "itemName": "CSIC e-Academy",
                "dli": "O249677787727",
                "city": "Burnaby",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 173,
                "itemName": "CTS Canadian Career College Inc",
                "dli": "O244926829077",
                "city": "North Bay",
                "campus": "North Bay",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 174,
                "itemName": "CTS Canadian Career College Inc",
                "dli": "O244926829077",
                "city": "Sudbury",
                "campus": "Sudbury",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 175,
                "itemName": "CTS Canadian Career College Inc",
                "dli": "O244926829077",
                "city": "Sudbury",
                "campus": "Modern College of Hairstyling and Esthetics",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 176,
                "itemName": "Calgary College of Traditional Chinese Medicine and Acupuncture",
                "dli": "O111917070547",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 177,
                "itemName": "Calgary Flight Training Centre (CFTC)",
                "dli": "O116654582557",
                "city": "Olds",
                "campus": "Olds",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 178,
                "itemName": "Calgary Flying Club",
                "dli": "O116455095420",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 179,
                "itemName": "Camber College",
                "dli": "O19219937972",
                "city": "Powell River",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 180,
                "itemName": "Cambria College",
                "dli": "O19283903592",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 181,
                "itemName": "Cambrian College of Applied Arts and Technology",
                "dli": "O19394699409",
                "city": "Sudbury",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 182,
                "itemName": "Cambrian College of Applied Arts and Technology",
                "dli": "O19394699409",
                "city": "Brampton",
                "campus": "Cambrian College of Applied Arts and Technology at Hanson International Academy",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 183,
                "itemName": "Cambrian College of Applied Arts and Technology",
                "dli": "O19394699409",
                "city": "Toronto",
                "campus": "Cambrian College of Applied Arts and Technology at Hanson International Academy",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 184,
                "itemName": "Cambridge College",
                "dli": "O19394940342",
                "city": "Burnaby",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 185,
                "itemName": "Cambrooks College",
                "dli": "O121510793907",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 186,
                "itemName": "Camosun College",
                "dli": "O19361235542",
                "city": "Victoria",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 187,
                "itemName": "Campbell College",
                "dli": "O254363717757",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 188,
                "itemName": "Notre-Dame-de-Foy",
                "dli": "O19358463051",
                "city": "Saint-Augustin-de-Desmaures",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 189,
                "itemName": "d'effets visuels Inc",
                "dli": "O142870681702",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 190,
                "itemName": "Can-AIM Careers Academy",
                "dli": "O263851487422",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 191,
                "itemName": "Can-Quest ESL Academy",
                "dli": "O116342294647",
                "city": "North Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 192,
                "itemName": "CanPacific College of Business and English Inc.",
                "dli": "O255440517127",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 193,
                "itemName": "Canada Aviation Academy",
                "dli": "O259092443252",
                "city": "Abbotsford",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 194,
                "itemName": "Canada Aviation Academy",
                "dli": "O259092443252",
                "city": "Kamloops",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 195,
                "itemName": "Canada Christian College & School of Graduate Theological Studies",
                "dli": "O19857659067",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 196,
                "itemName": "Canada College Vancouver",
                "dli": "O140788898652",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 197,
                "itemName": "Canada College of Education",
                "dli": "O19833653497",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 198,
                "itemName": "Canada International Royal Arts College",
                "dli": "O142484375262",
                "city": "Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 199,
                "itemName": "Canada International Royal Arts College",
                "dli": "O142484375262",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 200,
                "itemName": "Canada’s National Ballet School",
                "dli": "O19361087992",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 201,
                "itemName": "Canadian Academy of Dental Health & Community Sciences",
                "dli": "O118203132467",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 202,
                "itemName": "Canadian All Care College",
                "dli": "O19350533892",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 203,
                "itemName": "Canadian All Care College",
                "dli": "O19350533892",
                "city": "Toronto",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 204,
                "itemName": "Canadian Automotive & Trucking Institute",
                "dli": "O19527255662",
                "city": "Cambridge",
                "campus": "Cambridge",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 205,
                "itemName": "Canadian Aviation College",
                "dli": "O110027361097",
                "city": "Pitt Meadows",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 206,
                "itemName": "Canadian Baptist Bible College",
                "dli": "O113889884617",
                "city": "Winkler",
                "campus": "Winkler",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 207,
                "itemName": "Canadian Baptist Seminary",
                "dli": "O19394940354",
                "city": "Langley",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 208,
                "itemName": "Canadian Beauty College",
                "dli": "O19361295922",
                "city": "Newmarket",
                "campus": "Newmarket",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 209,
                "itemName": "Canadian Beauty College",
                "dli": "O19315968872",
                "city": "Vaughan",
                "campus": "Vaughan",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 210,
                "itemName": "Canadian Beauty College",
                "dli": "O116263336197",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 211,
                "itemName": "Canadian Business College",
                "dli": "O19556076972",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 212,
                "itemName": "Canadian Business College",
                "dli": "O19556076972",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 213,
                "itemName": "Canadian Business College",
                "dli": "O19556076972",
                "city": "Toronto",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 214,
                "itemName": "Canadian Business Skills College of Technology",
                "dli": "O19332510172",
                "city": "Markham",
                "campus": "Markham",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 215,
                "itemName": "Canadian College",
                "dli": "O19352621432",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 216,
                "itemName": "Canadian College for Higher Studies",
                "dli": "O19283878482",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 217,
                "itemName": "Canadian College of Acupuncture and Traditional Chinese Medicine",
                "dli": "O250138504947",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 218,
                "itemName": "Canadian College of Business, Science & Technology",
                "dli": "O19376450572",
                "city": "Brampton",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 219,
                "itemName": "Canadian College of Business, Science & Technology",
                "dli": "O19376450572",
                "city": "Brampton",
                "campus": "North York",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 220,
                "itemName": "Canadian College of Business, Science & Technology",
                "dli": "O19376450572",
                "city": "Brampton",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 221,
                "itemName": "Canadian College of Business, Science & Technology",
                "dli": "O19376450572",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 222,
                "itemName": "Canadian College of Educators",
                "dli": "O19303896162",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 223,
                "itemName": "Canadian College of English Language",
                "dli": "O19352470312",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 224,
                "itemName": "Canadian College of Health, Science and Technology",
                "dli": "O19395677987",
                "city": "Windsor",
                "campus": "Windsor",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 225,
                "itemName": "Canadian College of Homeopathic Medicine",
                "dli": "O143265597512",
                "city": "Toronto",
                "campus": "Victoria College",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 226,
                "itemName": "Canadian College of Homeopathic Medicine",
                "dli": "O143265597512",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 227,
                "itemName": "Canadian College of Massage and Hydrotherapy",
                "dli": "O111416612697",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 228,
                "itemName": "Canadian College of Naturopathic Medicine",
                "dli": "O19305338292",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes"
            },
            {
                "id": 229,
                "itemName": "Canadian College of Performing Arts",
                "dli": "O110684673294",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 230,
                "itemName": "Canadian College of Shiatsu Therapy",
                "dli": "O19283908462",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 231,
                "itemName": "Canadian College of Shiatsu Therapy",
                "dli": "O19283908462",
                "city": "North Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 232,
                "itemName": "Canadian College of Technology and Business",
                "dli": "O134304821852",
                "city": "Delta",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 233,
                "itemName": "Canadian Film and Television Institute",
                "dli": "O19240322772",
                "city": "Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 234,
                "itemName": "Canadian Flight Centre (CFC)",
                "dli": "O110207076237",
                "city": "Delta",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 235,
                "itemName": "Canadian Flight Centre (CFC)",
                "dli": "O110207076237",
                "city": "Kamloops",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 236,
                "itemName": "Canadian Forces College",
                "dli": "O19994256968",
                "city": "North York",
                "campus": "North York",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 237,
                "itemName": "Canadian Forces School of Aerospace Studies",
                "dli": "O214392246052",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "Yes",
                "province": "Manitoba"
            },
            {
                "id": 238,
                "itemName": "Canadian Health Care Academy",
                "dli": "O19331168352",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 239,
                "itemName": "Canadian Health Care Academy",
                "dli": "O19331168352",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 240,
                "itemName": "Canadian Health and Business College",
                "dli": "O263194936682",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 241,
                "itemName": "Canadian Imperial College",
                "dli": "O142448481732",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 242,
                "itemName": "Canadian Institute of Management & Technology",
                "dli": "O19304117342",
                "city": "Malton",
                "campus": "Malton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 243,
                "itemName": "Canadian Institute of Management & Technology",
                "dli": "O19304117342",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 244,
                "itemName": "Canadian Institute of Management & Technology",
                "dli": "O19304117342",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 245,
                "itemName": "Canadian Institute of Management & Technology",
                "dli": "O19304117342",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 246,
                "itemName": "Canadian Institute of Osteopathic Therapy Inc",
                "dli": "O140801484352",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 247,
                "itemName": "Canadian Institute of Traditional Chinese Medicine Corp.",
                "dli": "O19256722732",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 248,
                "itemName": "Canadian International Career College",
                "dli": "O263819541542",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 249,
                "itemName": "Canadian Memorial Chiropractic College (CMCC)",
                "dli": "O19359174402",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes"
            },
            {
                "id": 250,
                "itemName": "Canadian National Institute of Business",
                "dli": "O19376505602",
                "city": "Coquitlam",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 251,
                "itemName": "Canadian National Institute of Health Inc.",
                "dli": "O19394699911",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 252,
                "itemName": "Canadian Natural Therapist and Aesthetics Academy",
                "dli": "O260787487202",
                "city": "North York",
                "campus": "North York",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 253,
                "itemName": "Canadian Reformed Theological Seminary",
                "dli": "O19395677259",
                "city": "Hamilton",
                "campus": "Hamilton",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 254,
                "itemName": "Canadian Revival Training Centre Inc.",
                "dli": "O111466325127",
                "city": "Prince Albert",
                "offersPGWPEligible": "No",
                "province": "Saskatchewan"
            },
            {
                "id": 255,
                "itemName": "Canadian School of Natural Nutrition",
                "dli": "O263151451182",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 256,
                "itemName": "Canadian School of Natural Nutrition (Vancouver)",
                "dli": "O19584795462",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 257,
                "itemName": "Canadian School of Natural Nutrition Vancouver Island",
                "dli": "O116149580707",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 258,
                "itemName": "Canadian School of Natural Nutrition Vancouver Island",
                "dli": "O116149580707",
                "city": "Nanaimo",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 259,
                "itemName": "Canadian Southern Baptist Seminary and College",
                "dli": "O19256741082",
                "city": "Cochrane",
                "campus": "Cochrane",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 260,
                "itemName": "Canadian Sports Business Academy",
                "dli": "O19395300110",
                "city": "Whistler",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 261,
                "itemName": "Canadian Technical and Management College",
                "dli": "O260907081662",
                "city": "Milton",
                "campus": "Milton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 262,
                "itemName": "Canadian Technology College",
                "dli": "O19316547532",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 263,
                "itemName": "Canadian Tourism College",
                "dli": "O19301323922",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 264,
                "itemName": "Canadian Tourism College",
                "dli": "O19301323922",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 265,
                "itemName": "Canadore College",
                "dli": "O19395535239",
                "city": "North Bay",
                "campus": "Aviation",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 266,
                "itemName": "Canadore College",
                "dli": "O19395535239",
                "city": "North Bay",
                "campus": "College Drive /The Education Centre",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 267,
                "itemName": "Canadore College",
                "dli": "O19395535239",
                "city": "North Bay",
                "campus": "Commerce Court",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 268,
                "itemName": "Canadore College",
                "dli": "O19395535239",
                "city": "North Bay",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 269,
                "itemName": "Canadore College at Stanford International College of Business and Technology",
                "dli": "O19395535239",
                "city": "Mississauga",
                "campus": "Scarborough",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 270,
                "itemName": "Canadore College at Stanford International College of Business and Technology",
                "dli": "O19395535239",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 271,
                "itemName": "Canadore College at Stanford International College of Business and Technology",
                "dli": "O19395535239",
                "city": "Mississauga",
                "campus": "Brampton",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 272,
                "itemName": "Canadore College of Applied Arts and Technology",
                "dli": "O19395535239",
                "city": "Parry Sound",
                "campus": "West Parry Sound",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 273,
                "itemName": "Canford Institute of Technology",
                "dli": "O137690731772",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 274,
                "itemName": "Capital College",
                "dli": "O19283932632",
                "city": "Coquitlam",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 275,
                "itemName": "Capital College",
                "dli": "O19283932632",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 276,
                "itemName": "Capital College",
                "dli": "O19283932632",
                "city": "Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 277,
                "itemName": "Capital English Solutions and Mentora College of Business & Technology",
                "dli": "O116458043402",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 278,
                "itemName": "Career College Group",
                "dli": "O19361149772",
                "city": "Brantford",
                "campus": "Brantford",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 279,
                "itemName": "Career College Group",
                "dli": "O19361149772",
                "city": "Kitchener",
                "campus": "Kitchener",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 280,
                "itemName": "Career College Group",
                "dli": "O19361149772",
                "city": "London",
                "campus": "Richmond",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 281,
                "itemName": "Career College Group",
                "dli": "O19361149772",
                "city": "London",
                "campus": "Highbury",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 282,
                "itemName": "Career College Group",
                "dli": "O19361153702",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 283,
                "itemName": "Career College Group",
                "dli": "O19361153702",
                "city": "Toronto",
                "campus": "North York , Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 284,
                "itemName": "Career city College",
                "dli": "O254785080177",
                "city": "Kelowna",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 285,
                "itemName": "CareerQuest Inc.",
                "dli": "O256568575757",
                "city": "Toronto",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 286,
                "itemName": "CareerQuest Inc.",
                "dli": "O256568575757",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 287,
                "itemName": "Carey Theological College",
                "dli": "O19394940388",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 288,
                "itemName": "Cargair ltée",
                "dli": "O117370284412",
                "city": "Longueuil",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 289,
                "itemName": "Carrefour Formation Mauricie formation professionnelle",
                "dli": "O246875758063",
                "city": "Shawinigan",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 290,
                "itemName": "Centennial College",
                "dli": "O19394700003",
                "city": "Toronto",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 291,
                "itemName": "Centennial College",
                "dli": "O19394700003",
                "city": "Toronto",
                "campus": "The Centre for Creative Communications",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 292,
                "itemName": "Centennial College",
                "dli": "O19394700003",
                "city": "Toronto",
                "campus": "Progress  & Student Residence",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 293,
                "itemName": "Centennial College",
                "dli": "O19394700003",
                "city": "Toronto",
                "campus": "Ashtonbee",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 294,
                "itemName": "Centennial College",
                "dli": "O19394700003",
                "city": "Toronto",
                "campus": "Morningside",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 295,
                "itemName": "Centennial Flight Centre, Inc.",
                "dli": "O111345518667",
                "city": "Sturgeon County",
                "campus": "Sturgeon County",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 296,
                "itemName": "Central College",
                "dli": "O19220052602",
                "city": "New Westminster",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 297,
                "itemName": "Centre Frère-Moffet",
                "dli": "O246881964161",
                "city": "Ville-Marie",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 298,
                "itemName": "Centre PACC-Formation professionnelle",
                "dli": "O246881964281",
                "city": "Lasalle",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 299,
                "itemName": "Centre Performance Plus",
                "dli": "O246881964299",
                "city": "Lachute",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 300,
                "itemName": "Centre Polymétier",
                "dli": "O246881964314",
                "city": "Rouyn-Noranda",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 301,
                "itemName": "Centre collégial de transfert de technologie en télécommunications",
                "dli": "O19359010266",
                "city": "Trois-Rivières",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 302,
                "itemName": "Centre collégial de transfert technologique en optique-photonique OPTECH",
                "dli": "O19359010767",
                "city": "La Pocatière",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 303,
                "itemName": "Centre de Formation professionnelle de Lennoxville",
                "dli": "O246878951128",
                "city": "Sherbrooke",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 304,
                "itemName": "Centre de carrières Saint Pius X",
                "dli": "O246875758438",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 305,
                "itemName": "Centre de développement des composites du Québec (CDCQ)",
                "dli": "O19359010279",
                "city": "Saint-Jérôme",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 306,
                "itemName": "Centre de développement des compétences Laurier",
                "dli": "O246875965662",
                "city": "Lachute",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 307,
                "itemName": "Centre de développement des compétences Laurier",
                "dli": "O246875965662",
                "city": "Laval",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 308,
                "itemName": "Centre de développement des compétences Laurier",
                "dli": "O246875965662",
                "city": "Repentigny",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 309,
                "itemName": "Centre de développement des compétences Laurier",
                "dli": "O246875965662",
                "city": "Saint-Eustache",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 310,
                "itemName": "Centre de formation Compétence-de-la-Rive-Sud (FP)",
                "dli": "O246876616939",
                "city": "La Prairie",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 311,
                "itemName": "Centre de formation Compétences-2000",
                "dli": "O246876617024",
                "city": "Laval",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 312,
                "itemName": "Centre de formation Harricana",
                "dli": "O246876985845",
                "city": "Amos",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 313,
                "itemName": "Centre de formation Le Chantier",
                "dli": "O246876986019",
                "city": "Laval",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 314,
                "itemName": "Centre de formation agricole",
                "dli": "O246876616749",
                "city": "Saint-Anselme",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 315,
                "itemName": "Centre de formation agricole de Mirabel",
                "dli": "O246876616844",
                "city": "Mirabel",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 316,
                "itemName": "Centre de formation continue Pontiac",
                "dli": "O246876617355",
                "city": "Shawville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 317,
                "itemName": "Centre de formation continue de Val d’Or",
                "dli": "O246876617230",
                "city": "Val-d’Or",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 318,
                "itemName": "Centre de formation des Bâtisseurs",
                "dli": "O246876617390",
                "city": "Sainte-Marie",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 319,
                "itemName": "Centre de formation des Métiers de l’Acier",
                "dli": "O246876796895",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 320,
                "itemName": "Centre de formation des Nouvelles-Technologies",
                "dli": "O246876797012",
                "city": "Sainte-Thérèse",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 321,
                "itemName": "Centre de formation des métiers de Verdun",
                "dli": "O260779618762",
                "city": "Verdun",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 322,
                "itemName": "Centre de formation du transport routier de Saint-Jérôme",
                "dli": "O246876797059",
                "city": "Mirabel",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 323,
                "itemName": "Centre de formation en montage de lignes",
                "dli": "O246876985654",
                "city": "Lévis",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 324,
                "itemName": "Centre de formation en mécanique de véhicules lourds",
                "dli": "O246876797184",
                "city": "Lévis",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 325,
                "itemName": "Centre de formation en métallurgie de Laval",
                "dli": "O246876797489",
                "city": "Laval",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 326,
                "itemName": "Centre de formation en transport de Charlesbourg",
                "dli": "O246876985769",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 327,
                "itemName": "Centre de formation générale aux adultes -  de Brome-Missisquoi",
                "dli": "O246875391505",
                "city": "Cowansville",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 328,
                "itemName": "Centre de formation horticole de Laval",
                "dli": "O246876985953",
                "city": "Laval",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 329,
                "itemName": "Centre de formation professionnelle",
                "dli": "O246877185664",
                "city": "Havre-Saint-Pierre",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 330,
                "itemName": "Centre de formation professionnelle (Paspébiac-Bonaventure)",
                "dli": "O246881575918",
                "city": "Bonaventure",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 331,
                "itemName": "Centre de formation professionnelle -  de Brome-Missisquoi",
                "dli": "O246877856021",
                "city": "Cowansville",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 332,
                "itemName": "Centre de formation professionnelle - Édifice André-Jacob - Pavillon des Rives - Pavillon des Baies",
                "dli": "O246880671219",
                "city": "Baie-Comeau",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 333,
                "itemName": "Centre de formation professionnelle 24-Juin",
                "dli": "O246877185894",
                "city": "Sherbrooke",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 334,
                "itemName": "Centre de formation professionnelle A.-W.-Gagné",
                "dli": "O246877186029",
                "city": "Sept-Îles",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 335,
                "itemName": "Centre de formation professionnelle ACCESS",
                "dli": "O246877185939",
                "city": "Saint-Lambert",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 336,
                "itemName": "Centre de formation professionnelle Alma",
                "dli": "O246877185954",
                "city": "Alma",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 337,
                "itemName": "Centre de formation professionnelle Anchor",
                "dli": "O246877185969",
                "city": "New Carlisle",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 338,
                "itemName": "Centre de formation professionnelle André-Morissette",
                "dli": "O246877185984",
                "city": "Plessisville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 339,
                "itemName": "Centre de formation professionnelle Anjou",
                "dli": "O246877185999",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 340,
                "itemName": "Centre de formation professionnelle Antoine-de-Saint-Exupéry",
                "dli": "O246877186014",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 341,
                "itemName": "Centre de formation professionnelle Bel-Avenir",
                "dli": "O246877186054",
                "city": "Trois-Rivières",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 342,
                "itemName": "Centre de formation professionnelle Bernard-Gariépy",
                "dli": "O246877186069",
                "city": "Sorel-Tracy",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 343,
                "itemName": "Centre de formation professionnelle CSSMI-CSSWL",
                "dli": "O133891420452",
                "city": "St-Eustache",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 344,
                "itemName": "Centre de formation professionnelle Calixa-Lavallée",
                "dli": "O246877855844",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 345,
                "itemName": "Centre de formation professionnelle Chanoine-Armand-Racicot",
                "dli": "O246877856046",
                "city": "Saint-Jean-sur-Richelieu",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 346,
                "itemName": "Centre de formation professionnelle Charlotte-Tassé",
                "dli": "O246877856101",
                "city": "Longueuil",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 347,
                "itemName": "Centre de formation professionnelle Châteauguay Valley",
                "dli": "O246877856207",
                "city": "Ormstown",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 348,
                "itemName": "Centre de formation professionnelle Compétences Outaouais",
                "dli": "O246877856332",
                "city": "Gatineau",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 349,
                "itemName": "Centre de formation professionnelle Daniel-Johnson",
                "dli": "O246877856483",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 350,
                "itemName": "Centre de formation professionnelle Gabriel-Dionne",
                "dli": "O246881135144",
                "city": "Côte-Nord-du-Golfe-du-Saint-Laurent",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 351,
                "itemName": "Centre de formation professionnelle Gabriel-Rousseau",
                "dli": "O246881135199",
                "city": "Lévis",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 352,
                "itemName": "Centre de formation professionnelle Harrington",
                "dli": "O246881135284",
                "city": "Côte-Nord-du-Golfe-du-Saint-Laurent",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 353,
                "itemName": "Centre de formation professionnelle Jacques-Rousseau",
                "dli": "O246881135379",
                "city": "Longueuil",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 354,
                "itemName": "Centre de formation professionnelle Jonquière",
                "dli": "O246881135474",
                "city": "Saguenay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 355,
                "itemName": "Centre de formation professionnelle Kegaska",
                "dli": "O246881135519",
                "city": "Côte-Nord-du-Golfe-du-Saint-Laurent",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 356,
                "itemName": "Centre de formation professionnelle La Relance",
                "dli": "O246881325669",
                "city": "Chandler",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 357,
                "itemName": "Centre de formation professionnelle Lac-Abitibi",
                "dli": "O246881325644",
                "city": "La Sarre",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 358,
                "itemName": "Centre de formation professionnelle Laurier Macdonald",
                "dli": "O246881325714",
                "city": "Saint-Léonard",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 359,
                "itemName": "Centre de formation professionnelle Léonard-De Vinci",
                "dli": "O246881326134",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 360,
                "itemName": "Centre de formation professionnelle L’Envol",
                "dli": "O246881325929",
                "city": "Carleton-sur-Mer",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 361,
                "itemName": "Centre de formation professionnelle L’Oasis",
                "dli": "O246881326224",
                "city": "Saguenay",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 362,
                "itemName": "Centre de formation professionnelle Marcel-Proulx",
                "dli": "O246881326269",
                "city": "Drummondville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 363,
                "itemName": "Centre de formation professionnelle Marie-Rollet",
                "dli": "O246881326354",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 364,
                "itemName": "Centre de formation professionnelle Marie-Sarah",
                "dli": "O246881326409",
                "city": "Côte-Nord-du-Golfe-du-Saint-Laurent",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 365,
                "itemName": "Centre de formation professionnelle Maurice-Barbeau",
                "dli": "O246881326524",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 366,
                "itemName": "Centre de formation professionnelle Mecatina",
                "dli": "O246881326561",
                "city": "Gros-Mécatina",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 367,
                "itemName": "Centre de formation professionnelle Monseigneur-Scheffer",
                "dli": "O246881575616",
                "city": "Blanc-Sablon",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 368,
                "itemName": "Centre de formation professionnelle Mountain Ridge",
                "dli": "O246881575631",
                "city": "Bonne-Espérance",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 369,
                "itemName": "Centre de formation professionnelle Netagamiou",
                "dli": "O246881575706",
                "city": "Côte-Nord-du-Golfe-du-Saint-Laurent",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 370,
                "itemName": "Centre de formation professionnelle Nova",
                "dli": "O246881575851",
                "city": "Châteauguay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 371,
                "itemName": "Centre de formation professionnelle Paul-Gérin-Lajoie",
                "dli": "O246881575948",
                "city": "Vaudreuil-Dorion",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 372,
                "itemName": "Centre de formation professionnelle Paul-Rousseau",
                "dli": "O246881575963",
                "city": "Drummondville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 373,
                "itemName": "Centre de formation professionnelle Paul-Émile-Dufresne",
                "dli": "O246881575933",
                "city": "Laval",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 374,
                "itemName": "Centre de formation professionnelle Pavillon-de-l’Avenir",
                "dli": "O246881575978",
                "city": "Rivière-du-Loup",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 375,
                "itemName": "Centre de formation professionnelle Pierre-Dupuy",
                "dli": "O246881576283",
                "city": "Longueuil",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 376,
                "itemName": "Centre de formation professionnelle Pontiac",
                "dli": "O246881576329",
                "city": "Fort-Coulonge",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 377,
                "itemName": "Centre de formation professionnelle Pozer",
                "dli": "O246881576429",
                "city": "Saint-Georges",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 378,
                "itemName": "Centre de formation professionnelle Qualitech",
                "dli": "O246881576464",
                "city": "Trois-Rivières",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 379,
                "itemName": "Centre de formation professionnelle Relais de la Lièvre-Seigneurie",
                "dli": "O246881576479",
                "city": "Gatineau",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 380,
                "itemName": "Centre de formation professionnelle Sabtuan (Chisasibi - Coastal)",
                "dli": "O246881576524",
                "city": "Chisasibi",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 381,
                "itemName": "Centre de formation professionnelle Sabtuan (Mistissini - Inland)",
                "dli": "O246881576599",
                "city": "Mistissini",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 382,
                "itemName": "Centre de formation professionnelle Saint-Augustine",
                "dli": "O246881963628",
                "city": "Saint-Augustin",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 383,
                "itemName": "Centre de formation professionnelle Saint-Bernard",
                "dli": "O246881963653",
                "city": "Blanc-Sablon",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 384,
                "itemName": "Centre de formation professionnelle Saint-Joseph",
                "dli": "O246881963668",
                "city": "L’Île-d’Anticosti",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 385,
                "itemName": "Centre de formation professionnelle Saint-Lawrence",
                "dli": "O246881963703",
                "city": "Gros-Mécatina",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 386,
                "itemName": "Centre de formation professionnelle Saint-Paul",
                "dli": "O246881963738",
                "city": "Bonne-Espérance",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 387,
                "itemName": "Centre de formation professionnelle Saint-Theresa",
                "dli": "O246881963773",
                "city": "Blanc-Sablon",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 388,
                "itemName": "Centre de formation professionnelle Samuel-De Champlain",
                "dli": "O246881963788",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 389,
                "itemName": "Centre de formation professionnelle Val-d’Or",
                "dli": "O246881963863",
                "city": "Val-d’Or",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 390,
                "itemName": "Centre de formation professionnelle Vision 20 20",
                "dli": "O246881963878",
                "city": "Victoriaville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 391,
                "itemName": "Centre de formation professionnelle Vision-Avenir",
                "dli": "O246881963906",
                "city": "Gatineau",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 392,
                "itemName": "Centre de formation professionnelle West-Island",
                "dli": "O246881963941",
                "city": "Pierrefonds",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 393,
                "itemName": "Centre de formation professionnelle Wilbrod-Bherer",
                "dli": "O246881963956",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 394,
                "itemName": "Centre de formation professionnelle de Black Lake",
                "dli": "O246877856588",
                "city": "Thetford Mines",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 395,
                "itemName": "Centre de formation professionnelle de Charlevoix",
                "dli": "O246878245753",
                "city": "La Malbaie",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 396,
                "itemName": "Centre de formation professionnelle de Coaticook (CRIFA)",
                "dli": "O246878245788",
                "city": "Coaticook",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 397,
                "itemName": "Centre de formation professionnelle de Fierbourg",
                "dli": "O246882793368",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 398,
                "itemName": "Centre de formation professionnelle de Forestville",
                "dli": "O246878245833",
                "city": "Forestville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 399,
                "itemName": "Centre de formation professionnelle de Grosse-Île",
                "dli": "O246878245849",
                "city": "Grosse-Île",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 400,
                "itemName": "Centre de formation professionnelle de Kuujjuaq Kajusivik",
                "dli": "O246878245974",
                "city": "Kuujjuaq",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 401,
                "itemName": "Centre de formation professionnelle de La Côte-de-Gaspé",
                "dli": "O246878732150",
                "city": "Gaspé",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 402,
                "itemName": "Centre de formation professionnelle de La Haute-Gaspésie",
                "dli": "O246878826695",
                "city": "Sainte-Anne-des-Monts",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 403,
                "itemName": "Centre de formation professionnelle de Lachine",
                "dli": "O246878246175",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 404,
                "itemName": "Centre de formation professionnelle de Limoilou",
                "dli": "O246878951444",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 405,
                "itemName": "Centre de formation professionnelle de Lévis",
                "dli": "O246878951409",
                "city": "Lévis",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 406,
                "itemName": "Centre de formation professionnelle de Maniwaki",
                "dli": "O246878951515",
                "city": "Maniwaki",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 407,
                "itemName": "Centre de formation professionnelle de Matane",
                "dli": "O246878951530",
                "city": "Matane",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 408,
                "itemName": "Centre de formation professionnelle de Memphrémagog",
                "dli": "O246878951575",
                "city": "Magog",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 409,
                "itemName": "Centre de formation professionnelle de Mont-Joli-Mitis",
                "dli": "O246879455601",
                "city": "Mont-Joli",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 410,
                "itemName": "Centre de formation professionnelle de Mont-Laurier",
                "dli": "O246879455636",
                "city": "Mont-Laurier",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 411,
                "itemName": "Centre de formation professionnelle de Neufchâtel",
                "dli": "O246879455671",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 412,
                "itemName": "Centre de formation professionnelle de Nicolet",
                "dli": "O246879455706",
                "city": "Nicolet",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 413,
                "itemName": "Centre de formation professionnelle de Northern Lights",
                "dli": "O246879455741",
                "city": "Sept-Îles",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 414,
                "itemName": "Centre de formation professionnelle de Portneuf",
                "dli": "O246879455796",
                "city": "Donnacona",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 415,
                "itemName": "Centre de formation professionnelle de Québec",
                "dli": "O246879456223",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 416,
                "itemName": "Centre de formation professionnelle de Rimouski-Neigette",
                "dli": "O246879456298",
                "city": "Rimouski",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 417,
                "itemName": "Centre de formation professionnelle de Verdun",
                "dli": "O246879963118",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 418,
                "itemName": "Centre de formation professionnelle de Wakeham",
                "dli": "O246879963143",
                "city": "Gaspé",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 419,
                "itemName": "Centre de formation professionnelle de Waswanipi",
                "dli": "O246879963158",
                "city": "Waswanipi",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 420,
                "itemName": "Centre de formation professionnelle de la Baie-James",
                "dli": "O246878246010",
                "city": "Chibougamau",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 421,
                "itemName": "Centre de formation professionnelle de la Pointe-du-Lac",
                "dli": "O246878826993",
                "city": "Salaberry-de-Valleyfield",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 422,
                "itemName": "Centre de formation professionnelle de la Vallée-de-la-Gatineau",
                "dli": "O246878950798",
                "city": "Maniwaki",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 423,
                "itemName": "Centre de formation professionnelle de l’Asbesterie",
                "dli": "O246878827298",
                "city": "Asbestos",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 424,
                "itemName": "Centre de formation professionnelle de l’Envolée",
                "dli": "O246878951383",
                "city": "Montmagny",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 425,
                "itemName": "Centre de formation professionnelle de l’automobile",
                "dli": "O246878827593",
                "city": "Sainte-Thérèse",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 426,
                "itemName": "Centre de formation professionnelle des Carrefours",
                "dli": "O246881964406",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 427,
                "itemName": "Centre de formation professionnelle des Moissons",
                "dli": "O246879456545",
                "city": "Beauharnois",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 428,
                "itemName": "Centre de formation professionnelle des Moulins",
                "dli": "O246879962730",
                "city": "Terrebonne",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 429,
                "itemName": "Centre de formation professionnelle des Patriotes",
                "dli": "O246879962865",
                "city": "Sainte-Julie",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 430,
                "itemName": "Centre de formation professionnelle des Riverains",
                "dli": "O246879962890",
                "city": "Repentigny",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 431,
                "itemName": "Centre de formation professionnelle des Sommets / L’Horizon",
                "dli": "O246879963015",
                "city": "Sainte-Agathe-des-Monts",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 432,
                "itemName": "Centre de formation professionnelle des Sources",
                "dli": "O246879963103",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 433,
                "itemName": "Centre de formation professionnelle des métiers de la santé",
                "dli": "O246879456490",
                "city": "Kirkland",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 434,
                "itemName": "Centre de formation professionnelle des Îles",
                "dli": "O246879456385",
                "city": "Les Îles-de-la-Madeleine",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 435,
                "itemName": "Centre de formation professionnelle du Fjord",
                "dli": "O246879963248",
                "city": "Saguenay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 436,
                "itemName": "Centre de formation professionnelle du Fleuve-et-des-Lacs",
                "dli": "O246880670744",
                "city": "Témiscouata-sur-le-Lac",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 437,
                "itemName": "Centre de formation professionnelle du Haut-Saint-François",
                "dli": "O246880670789",
                "city": "East Angus",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 438,
                "itemName": "Centre de formation professionnelle du Nunavik Nunavimmi Pigiursavik",
                "dli": "O246880670984",
                "city": "Inukjuak",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 439,
                "itemName": "Centre de formation professionnelle du Pays-des-Bleuets",
                "dli": "O246879963223",
                "city": "Dolbeau-Mistassini",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 440,
                "itemName": "Centre de formation professionnelle d’Amqui",
                "dli": "O246877856427",
                "city": "Amqui",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 441,
                "itemName": "Centre de formation professionnelle d’électrolyse et d’esthétique",
                "dli": "O246878950923",
                "city": "Longueuil",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 442,
                "itemName": "Centre de formation professionnelle en foresterie de l’Est-du-Québec",
                "dli": "O246881135039",
                "city": "Causapscal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 443,
                "itemName": "Centre de formation professionnelle en équipement motorisé",
                "dli": "O246881134904",
                "city": "Saguenay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 444,
                "itemName": "Centre de formation professionnelle le Granit",
                "dli": "O246881325759",
                "city": "Lac-Mégantic",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 445,
                "itemName": "Centre de formation professionnelle le Tremplin",
                "dli": "O246881326169",
                "city": "Thetford Mines",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 446,
                "itemName": "Centre de formation professionnelle pour Autochtones dans les métiers de la construction",
                "dli": "O246881576414",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 447,
                "itemName": "Centre de formation professionnelle, l’Émergence",
                "dli": "O246881325824",
                "city": "Deux-Montagnes",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 448,
                "itemName": "Centre de formation professsionnelle Eastern Québec",
                "dli": "O246881963981",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 449,
                "itemName": "Centre de géomatique du Québec inc.",
                "dli": "O19359010342",
                "city": "Saguenay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 450,
                "itemName": "Centre de métallurgie du Québec",
                "dli": "O19359010433",
                "city": "Trois-Rivières",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 451,
                "itemName": "Centre de production automatisée (CPA)",
                "dli": "O19359010467",
                "city": "Saguenay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 452,
                "itemName": "Centre de productique intégrée du Québec inc.",
                "dli": "O19359010480",
                "city": "Sherbrooke",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 453,
                "itemName": "Centre de recherche pour l’inclusion scolaire et professionnelle des étudiants en situation de handicap",
                "dli": "O19359010846",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 454,
                "itemName": "Centre de robotique et de vision industrielles inc.",
                "dli": "O19359010493",
                "city": "Lévis",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 455,
                "itemName": "Centre de solutions technologiques en orthèses et prothèses (CSTOP)",
                "dli": "O19359010505",
                "city": "Quebec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 456,
                "itemName": "Centre de technologie Rosemont",
                "dli": "O246881964051",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 457,
                "itemName": "Centre de technologie minérale et de plasturgie inc.",
                "dli": "O19359010544",
                "city": "Thetford Mines",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 458,
                "itemName": "Centre de transfert technologique en écologie industrielle, centre J-E. Simard",
                "dli": "O19359010582",
                "city": "Sorel-Tracy",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 459,
                "itemName": "Centre des Carrières Western Québec",
                "dli": "O246881964021",
                "city": "Gatineau",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 460,
                "itemName": "Centre des services aux entreprises",
                "dli": "O246881964036",
                "city": "Alma",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 461,
                "itemName": "Centre des technologies de l’eau",
                "dli": "O19359010728",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 462,
                "itemName": "Centre d’enseignement et de recherche en foresterie de Ste-Foy inc.",
                "dli": "O19359010316",
                "city": "Quebec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 463,
                "itemName": "Centre d’excellence en formation industrielle",
                "dli": "O246881964146",
                "city": "Windsor",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 464,
                "itemName": "Centre d’expérimentation et de développement en forêt boréale (CEDFOB)",
                "dli": "O19359010291",
                "city": "Baie-Comeau",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 465,
                "itemName": "Centre d’initiation à la recherche et d’aide au développement durable (CIRADD)",
                "dli": "O19359010407",
                "city": "Carleton-sur-Mer",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 466,
                "itemName": "Centre d’innovation en microélectronique du Québec (CIMEQ)",
                "dli": "O19359010355",
                "city": "Sainte-Thérèse",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 467,
                "itemName": "Centre d’innovation sociale en agriculture",
                "dli": "O19359010420",
                "city": "Victoriaville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 468,
                "itemName": "Centre d’éducation aux adultes et communautaire Huntingdon",
                "dli": "O246876157216",
                "city": "Huntingdon",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 469,
                "itemName": "Centre d’éducation des adultes de l’Accore (FP)",
                "dli": "O246876157481",
                "city": "Châteauguay",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 470,
                "itemName": "Centre d’éducation des adultes du Goéland (FP)",
                "dli": "O246876401906",
                "city": "La Prairie",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 471,
                "itemName": "Centre d’éducation professionnelle de Listuguj",
                "dli": "O246876402140",
                "city": "Pointe-à-la-Croix",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 472,
                "itemName": "Centre d’éducation professionnelle de New Richmond",
                "dli": "O246876402513",
                "city": "New Richmond",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 473,
                "itemName": "Centre d’électrotechnologie Pearson",
                "dli": "O246881963996",
                "city": "Lachine",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 474,
                "itemName": "Centre d’étude en responsabilité sociale et écocitoyenneté (CÉRSÉ)",
                "dli": "O19359010329",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 475,
                "itemName": "Centre d’études commerciales Shadd",
                "dli": "O246881964066",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 476,
                "itemName": "Centre d’études en procédés chimiques du Québec (CÉPROCQ)",
                "dli": "O19359010304",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 477,
                "itemName": "Centre d’études professionnelles",
                "dli": "O246881964131",
                "city": "Saint-Jérôme",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 478,
                "itemName": "Centre en imagerie numérique et médias interactifs (CIMMI)",
                "dli": "O19359010368",
                "city": "Quebec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 479,
                "itemName": "Centre for Arts and Technology",
                "dli": "O19283900882",
                "city": "Kelowna, Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 480,
                "itemName": "Centre for Nursing Studies",
                "dli": "O19441089096",
                "city": "St. John’s",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 481,
                "itemName": "Centre intégré de mécanique industrielle de la Chaudière",
                "dli": "O246881964191",
                "city": "Saint-Georges",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 482,
                "itemName": "Centre intégré de mécanique, de métallurgie et d’électricité (CIMME)",
                "dli": "O246881964176",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 483,
                "itemName": "Centre l’Envol",
                "dli": "O246881964206",
                "city": "Témiscaming",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 484,
                "itemName": "Centre multiservice des Samares",
                "dli": "O246881964251",
                "city": "Joliette",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 485,
                "itemName": "Centre national de conduite d’engins de chantier",
                "dli": "O246881964266",
                "city": "Lévis",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 486,
                "itemName": "Centre national en électrochimie et en technologies environnementales inc.",
                "dli": "O19359010454",
                "city": "Shawinigan",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 487,
                "itemName": "Centre of English Studies",
                "dli": "O19517085782",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 488,
                "itemName": "Centre régional de formation à distance du Grand-Montréal",
                "dli": "O246881964329",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 489,
                "itemName": "Centre régional intégré de formation",
                "dli": "O246881964344",
                "city": "Granby",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 490,
                "itemName": "Centre sectoriel des plastiques",
                "dli": "O246881964359",
                "city": "Saint-Damien-de-Buckland",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 491,
                "itemName": "Centre technologique des résidus industriels",
                "dli": "O19359010556",
                "city": "Rouyn-Noranda",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 492,
                "itemName": "Centre technologique en aérospatiale C.T.A.",
                "dli": "O19359010530",
                "city": "Saint-Hubert",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 493,
                "itemName": "Certcana Systems Institute",
                "dli": "O257702593357",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 494,
                "itemName": "Cestar College of Business Health & Technology",
                "dli": "O19283870732",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 495,
                "itemName": "Chaleur College Inc.",
                "dli": "O141517473662",
                "city": "Bathurst",
                "campus": "Bathurst",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 496,
                "itemName": "Champlain Regional College",
                "dli": "O19359201407",
                "city": "Sherbrooke",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 497,
                "itemName": "Chartered Professional Accountants (CPA) Manitoba",
                "dli": "O212511104177",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 498,
                "itemName": "Chartered Professional Accountants of British Columbia (CPABC)",
                "dli": "O19395300152",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 499,
                "itemName": "Chef School",
                "dli": "O19284163112",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 500,
                "itemName": "Chellsey Institute of Beauty and Health Inc.",
                "dli": "O19394851105",
                "city": "Burlington",
                "campus": "Burlington",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 501,
                "itemName": "Chellsey Institute of Beauty and Health Inc.",
                "dli": "O19394851105",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 502,
                "itemName": "Chinook Helicopters (1982) Ltd.",
                "dli": "O19280114462",
                "city": "Abbotsford",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 503,
                "itemName": "Cintech agroalimentaire",
                "dli": "O19359010381",
                "city": "Saint-Hyacinthe",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 504,
                "itemName": "Clarkridge Career Institute",
                "dli": "O142582170422",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 505,
                "itemName": "Clarkridge Career Institute",
                "dli": "O142582170422",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 506,
                "itemName": "Clearmind International Institute Inc.",
                "dli": "O19371018112",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 507,
                "itemName": "Clearmind International Institute Inc.",
                "dli": "O19371018112",
                "city": "Langley",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 508,
                "itemName": "Cloud Nine College Ltd.",
                "dli": "O19394940242",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 509,
                "itemName": "Coast Mountain College",
                "dli": "O110095977911",
                "city": "Hazelton, Houston, Kitimat, Prince Rupert, Masset (Haida Gwaii), Queen Charlotte, Smithers, Terrace",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 510,
                "itemName": "Coastal College of British Columbia",
                "dli": "O142195159782",
                "city": "New Westminster",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 511,
                "itemName": "Coastal Pacific Aviation Ltd.",
                "dli": "O19377035282",
                "city": "Abbotsford",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 512,
                "itemName": "CodeCore",
                "dli": "O116475837179",
                "city": "New Westminster",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 513,
                "itemName": "College of Emmanuel and St Chad",
                "dli": "O122051172047",
                "city": "Saskatoon",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 514,
                "itemName": "College of Morden Inc",
                "dli": "O261862108582",
                "city": "Morden",
                "campus": "Morden",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 515,
                "itemName": "College of New Caledonia",
                "dli": "O19360977332",
                "city": "Burns Lake, Fort St. James, Fraser Lake, Mackenzie, Prince George, Quesnel, Vanderhoof, Valemount",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 516,
                "itemName": "College of Traditional Chinese Medicine Canada",
                "dli": "O19376203332",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 517,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Baie Verte",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 518,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Bonavista",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 519,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Burin Bay Arm",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 520,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Carbonear",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 521,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Clarenville",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 522,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Conception Bay South",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 523,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Cornerbrook",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 524,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Gander",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 525,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Grand Falls-Windsor",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 526,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Happy Valley-Goose Bay",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 527,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Labrador city",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 528,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Placentia",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 529,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Port aux Basques",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 530,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "St. Anthony",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 531,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "St. John’s",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 532,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "St. John’s",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 533,
                "itemName": "College of the North Atlantic",
                "dli": "O19441088976",
                "city": "Stephenville",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 534,
                "itemName": "College of the Rockies",
                "dli": "O19377233362",
                "city": "Cranbrook, Creston, Fernie, Golden, Invermere, Kimberley",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 535,
                "itemName": "Collège André-Grasset (1973) inc.",
                "dli": "O19358462872",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 536,
                "itemName": "Collège April-Fortier inc.",
                "dli": "O19338280902",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 537,
                "itemName": "Collège Avalon",
                "dli": "O123445819232",
                "city": "Québec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 538,
                "itemName": "Collège Avalon",
                "dli": "O123445819232",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 539,
                "itemName": "Collège Bart (1975)",
                "dli": "O19358462886",
                "city": "Quebec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 540,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "Barrie",
                "campus": "Centre d’Accès Barrie",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 541,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "Sudbury",
                "campus": "Principal",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 542,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "Toronto",
                "campus": "de Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 543,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "Windsor",
                "campus": "Centre d’Accès Windsor",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 544,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "Alfred",
                "campus": "d’Alfred",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 545,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "Hamilton",
                "campus": "Centre d’Accès Hamilton",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 546,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "Hearst",
                "campus": "Collège Boréal Hearst",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 547,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "Kapuskasing",
                "campus": "de Kapuskasing",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 548,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "London",
                "campus": "Centre d’Accès London",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 549,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "New Liskeard",
                "campus": "de Témiskaming",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 550,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "Niagara",
                "campus": "Centre d’Accès Niagara",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 551,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "Sturgeon Falls",
                "campus": "de Nipissing",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 552,
                "itemName": "Collège Boréal",
                "dli": "O19395678039",
                "city": "Timmins",
                "campus": "de Timmins",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 553,
                "itemName": "Collège CDI Administration - Technologie - Santé/CDI College Business - Technology - Health Care",
                "dli": "O19338326485",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 554,
                "itemName": "Collège CDI Administration. Technologie. Santé",
                "dli": "O246881964376",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 555,
                "itemName": "Collège CDI Administration. Technologie. Santé",
                "dli": "O246881964376",
                "city": "Québec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 556,
                "itemName": "Collège CDI Administration. Technologie. Santé",
                "dli": "O246881964376",
                "city": "Laval",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 557,
                "itemName": "Collège Canada inc.",
                "dli": "O19338447695",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 558,
                "itemName": "Collège Centennale",
                "dli": "O19358462907",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 559,
                "itemName": "Collège Dawson",
                "dli": "O19359201433",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 560,
                "itemName": "Collège Eid Air Aviation",
                "dli": "O252894807712",
                "city": "Bromont",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 561,
                "itemName": "Collège Ellis",
                "dli": "O19358462920",
                "city": "Drummondville",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 562,
                "itemName": "Collège Ellis",
                "dli": "O19358462934",
                "city": "Trois-Rivières",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 563,
                "itemName": "Collège Greystone",
                "dli": "O256818010442",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 564,
                "itemName": "Collège Herzing",
                "dli": "O246881964479",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 565,
                "itemName": "Collège Herzing / Herzing College",
                "dli": "O19338389261",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 566,
                "itemName": "Collège Héritage",
                "dli": "O19359201551",
                "city": "Gatineau",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 567,
                "itemName": "Collège Inter-Dec",
                "dli": "O19338389324",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 568,
                "itemName": "Collège International des Marcellines",
                "dli": "O19358462947",
                "city": "Westmount",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 569,
                "itemName": "Collège Jean-de-Brébeuf",
                "dli": "O19358462960",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 570,
                "itemName": "Collège La Cabriole",
                "dli": "O19338389650",
                "city": "Saint-Jean-sur-Richelieu",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 571,
                "itemName": "Collège LaSalle",
                "dli": "O19358462986",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 572,
                "itemName": "Collège Laflèche",
                "dli": "O19358462973",
                "city": "Trois-Rivières",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 573,
                "itemName": "Collège M du Canada",
                "dli": "O142824217682",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 574,
                "itemName": "Collège Marianopolis",
                "dli": "O19358463012",
                "city": "Westmount",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 575,
                "itemName": "Collège Mathieu",
                "dli": "O242735687677",
                "city": "Gravelbourg",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 576,
                "itemName": "Collège Milestone",
                "dli": "O259228258052",
                "city": "Brossard",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 577,
                "itemName": "Collège MultiHexa Saguenay/Lac Saint-Jean",
                "dli": "O19338390153",
                "city": "Saguenay",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 578,
                "itemName": "Collège Mérici",
                "dli": "O19358463025",
                "city": "Quebec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 579,
                "itemName": "Collège O’Sullivan de Montréal inc.",
                "dli": "O19358463079",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 580,
                "itemName": "Collège O’Sullivan de Québec inc.",
                "dli": "O19358463092",
                "city": "Quebec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 581,
                "itemName": "Collège Salette inc.",
                "dli": "O19338447234",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 582,
                "itemName": "Collège Shawinigan",
                "dli": "O19359201819",
                "city": "Shawinigan",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 583,
                "itemName": "Collège St-Michel",
                "dli": "O19338447708",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 584,
                "itemName": "Collège Stanislas inc.",
                "dli": "O19358463131",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 585,
                "itemName": "Collège Supérieur de Montréal (C.S.M.) inc.",
                "dli": "O246881964554",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 586,
                "itemName": "Collège TAV",
                "dli": "O19358463154",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 587,
                "itemName": "Collège communautaire du Nouveau-Brunswick (CCNB)",
                "dli": "O19391556654",
                "city": "Bathurst",
                "campus": "de Bathurst",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 588,
                "itemName": "Collège communautaire du Nouveau-Brunswick (CCNB)",
                "dli": "O19391556654",
                "city": "Campbellton",
                "campus": "de Campbellton",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 589,
                "itemName": "Collège communautaire du Nouveau-Brunswick (CCNB)",
                "dli": "O19391556654",
                "city": "Dieppe",
                "campus": "de Dieppe",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 590,
                "itemName": "Collège communautaire du Nouveau-Brunswick (CCNB)",
                "dli": "O19391556654",
                "city": "Edmundston",
                "campus": "d’Edmundston",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 591,
                "itemName": "Collège communautaire du Nouveau-Brunswick (CCNB)",
                "dli": "O19391556654",
                "city": "Shippagan",
                "campus": "de la Péninsule acadienne",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 592,
                "itemName": "Collège de comptabilité et de secrétariat du Québec,  de Longueuil inc.",
                "dli": "O246881964391",
                "city": "Longueuil",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 593,
                "itemName": "Collège de comptabilité et de secrétariat du Québec,  de Sherbrooke inc.",
                "dli": "O246881964451",
                "city": "Sherbrooke",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 594,
                "itemName": "Collège de comptabilité et de secrétariat du Québec,  de Sherbrooke inc.",
                "dli": "O246881964451",
                "city": "Drummondville",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 595,
                "itemName": "Collège de gestion, technologie et santé Matrix",
                "dli": "O242715034170",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 596,
                "itemName": "Collège de l’immobilier du Québec",
                "dli": "O19338389347",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 597,
                "itemName": "Collège de l’Île",
                "dli": "O19220114112",
                "city": "Charlottetown",
                "campus": "Centre de formation Charlottetown",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 598,
                "itemName": "Collège de l’Île",
                "dli": "O19220114112",
                "city": "DeBlois",
                "campus": "Centre de formation DeBlois",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 599,
                "itemName": "Collège de l’Île",
                "dli": "O19220114112",
                "city": "Wellington",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 600,
                "itemName": "Collège de photographie Marsan",
                "dli": "O19338390167",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 601,
                "itemName": "Collège d’Alma",
                "dli": "O19359201344",
                "city": "Alma",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 602,
                "itemName": "Collège d’aéronautique",
                "dli": "O130335902202",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 603,
                "itemName": "Collège d’enseignement en immobilier inc.",
                "dli": "O19338359428",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 604,
                "itemName": "Collège international Marie de France",
                "dli": "O19358463038",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 605,
                "itemName": "Collège l’Avenir de Rosemont",
                "dli": "O263496138325",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 606,
                "itemName": "Collège radio télévision de Québec inc.",
                "dli": "O19338390190",
                "city": "Quebec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 607,
                "itemName": "Collège technique de Montréal inc.",
                "dli": "O19338447257",
                "city": "Mont-Royal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 608,
                "itemName": "Collège Éducacentre",
                "dli": "O19832975987",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 609,
                "itemName": "Collège Éducacentre",
                "dli": "O19832975987",
                "city": "Prince George",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 610,
                "itemName": "Collège Éducacentre",
                "dli": "O19832975987",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 611,
                "itemName": "Collégial international Sainte-Anne",
                "dli": "O19358463118",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 612,
                "itemName": "Columbia Bible College",
                "dli": "O19394940898",
                "city": "Abbotsford",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 613,
                "itemName": "Columbia College",
                "dli": "O19391275502",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 614,
                "itemName": "Columbia College",
                "dli": "O19279591302",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 615,
                "itemName": "Columbia Paramedic Academy",
                "dli": "O264899246972",
                "city": "Langley, Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 616,
                "itemName": "Commonwealth College Inc. (Culinary Arts Level One, Hospitality, Electrolysis, Esthetics, Nail Technician, Skin Technician)",
                "dli": "O242532309967",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 617,
                "itemName": "Computek College of Business Healthcare and Technology",
                "dli": "O19394699328",
                "city": "Markham",
                "campus": "Markham",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 618,
                "itemName": "Computek College of Business Healthcare and Technology",
                "dli": "O19394699328",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 619,
                "itemName": "Concordia Lutheran Seminary",
                "dli": "O110120786917",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 620,
                "itemName": "Conestoga College",
                "dli": "O19376158572",
                "city": "Kitchener",
                "campus": "Kitchener",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 621,
                "itemName": "Conestoga College",
                "dli": "O19376158572",
                "city": "Guelph",
                "campus": "Guelph",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 622,
                "itemName": "Conestoga College",
                "dli": "O19376158572",
                "city": "Brantford",
                "campus": "Brantford",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 623,
                "itemName": "Conestoga College",
                "dli": "O19376158572",
                "city": "Cambridge",
                "campus": "Cambridge",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 624,
                "itemName": "Conestoga College",
                "dli": "O19376158572",
                "city": "Ingersoll",
                "campus": "Ingersoll  (Ingersoll Skills Training Centre)",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 625,
                "itemName": "Conestoga College",
                "dli": "O19376158572",
                "city": "Kitchener",
                "campus": "Doon",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 626,
                "itemName": "Conestoga College",
                "dli": "O19376158572",
                "city": "Stratford",
                "campus": "Stratford",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 627,
                "itemName": "Conestoga College",
                "dli": "O19376158572",
                "city": "Waterloo",
                "campus": "Waterloo",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 628,
                "itemName": "Confederation College",
                "dli": "O19376986752",
                "city": "Thunder Bay",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 629,
                "itemName": "Confederation College",
                "dli": "O19376986752",
                "city": "Dryden",
                "campus": "Dryden",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 630,
                "itemName": "Confederation College",
                "dli": "O19376986752",
                "city": "Fort Frances",
                "campus": "Rainy River District",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 631,
                "itemName": "Confederation College",
                "dli": "O19376986752",
                "city": "Geraldton",
                "campus": "Greenstone",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 632,
                "itemName": "Confederation College",
                "dli": "O19376986752",
                "city": "Kenora",
                "campus": "Lake of the Woods",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 633,
                "itemName": "Connect International School",
                "dli": "O19516905192",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 634,
                "itemName": "Conseil de l’Éducation du Lac-Simon",
                "dli": "O246881964594",
                "city": "Lac-Simon",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 635,
                "itemName": "Conservatoire de musique de Gatineau",
                "dli": "O19359010871",
                "city": "Gatineau",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 636,
                "itemName": "Conservatoire de musique de Montréal",
                "dli": "O19359010884",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 637,
                "itemName": "Conservatoire de musique de Québec",
                "dli": "O19359010897",
                "city": "Quebec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 638,
                "itemName": "Conservatoire de musique de Rimouski",
                "dli": "O19359010910",
                "city": "Rimouski",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 639,
                "itemName": "Conservatoire de musique de Saguenay",
                "dli": "O19359010858",
                "city": "Saguenay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 640,
                "itemName": "Conservatoire de musique de Trois-Rivières",
                "dli": "O19359010924",
                "city": "Trois-Rivières",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 641,
                "itemName": "Conservatoire de musique de Val-d’Or",
                "dli": "O19359010937",
                "city": "Val-d’Or",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 642,
                "itemName": "Coquitlam College",
                "dli": "O19244321962",
                "city": "Coquitlam",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 643,
                "itemName": "Cornerstone International Community College of Canada",
                "dli": "O19274576342",
                "city": "Richmond, Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 644,
                "itemName": "Cornwall Aviation (1979) Ltd.",
                "dli": "O19394924449",
                "city": "Summerstown",
                "campus": "Summerstown",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 645,
                "itemName": "Corporation du service de recherche et d’expertise en transformation des produits forestiers de l’Est-du-Québec.",
                "dli": "O19359010795",
                "city": "Amqui",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 646,
                "itemName": "Corpus Christi College",
                "dli": "O19280180922",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 647,
                "itemName": "Create Career College",
                "dli": "O19275297052",
                "city": "Burnaby, Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 648,
                "itemName": "Cultural Connections Institute-The Learning Exchange (CCI-LEX)",
                "dli": "O125073908927",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 649,
                "itemName": "CultureWorks English as a Second Language, Inc.",
                "dli": "O110353890217",
                "city": "London",
                "campus": "London @ King’s College",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 650,
                "itemName": "CultureWorks English as a Second Language, Inc.",
                "dli": "O110353890217",
                "city": "London",
                "campus": "London @ Brescia College",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 651,
                "itemName": "CultureWorks English as a Second Language, Inc.",
                "dli": "O110353890217",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 652,
                "itemName": "CultureWorks English as a Second Language, Inc.",
                "dli": "O110353890217",
                "city": "Ottawa",
                "campus": "Carleton Technology and Training Centre",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 653,
                "itemName": "Cumberland College",
                "dli": "O117018032957",
                "city": "Nipawin",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 654,
                "itemName": "Cumberland College",
                "dli": "O117018032957",
                "city": "Melfort",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 655,
                "itemName": "Cumberland College",
                "dli": "O117018032957",
                "city": "Tisdale",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 656,
                "itemName": "Cypress College Inc.",
                "dli": "O242536283107",
                "city": "Medicine Hat",
                "campus": "Cypress College – Brooks",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 657,
                "itemName": "Cypress College Inc.",
                "dli": "O242536283107",
                "city": "Medicine Hat",
                "campus": "Cypress College – Taber",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 658,
                "itemName": "Cégep André-Laurendeau",
                "dli": "O19359201356",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 659,
                "itemName": "Cégep Beauce-Appalaches",
                "dli": "O19359201381",
                "city": "Saint-Georges",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 660,
                "itemName": "Cégep François-Xavier Garneau",
                "dli": "O19359201499",
                "city": "Quebec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 661,
                "itemName": "Cégep Gérald-Godin",
                "dli": "O19359201525",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 662,
                "itemName": "Cégep John Abbott",
                "dli": "O19359201601",
                "city": "Sainte-Anne-de-Bellevue",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 663,
                "itemName": "Cégep Limoilou",
                "dli": "O19359201678",
                "city": "Quebec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 664,
                "itemName": "Cégep Lionel Groulx",
                "dli": "O19359201692",
                "city": "Sainte-Thérèse",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 665,
                "itemName": "Cégep Marie-Victorin",
                "dli": "O19359201717",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 666,
                "itemName": "Cégep Montmorency",
                "dli": "O19359201744",
                "city": "Laval",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 667,
                "itemName": "Cégep Saint-Jean-sur-Richelieu",
                "dli": "O19359201577",
                "city": "Saint-Jean-sur-Richelieu",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 668,
                "itemName": "Cégep de Baie-Comeau",
                "dli": "O19359201369",
                "city": "Baie-Comeau",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 669,
                "itemName": "Cégep de Bois-de-Boulogne",
                "dli": "O19359201394",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 670,
                "itemName": "Cégep de Chicoutimi",
                "dli": "O19359201420",
                "city": "Saguenay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 671,
                "itemName": "Cégep de Drummondville",
                "dli": "O19359201445",
                "city": "Drummondville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 672,
                "itemName": "Cégep de Granby-Haute-Yamaska",
                "dli": "O19359201538",
                "city": "Granby",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 673,
                "itemName": "Cégep de Jonquière",
                "dli": "O19359201614",
                "city": "Saguenay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 674,
                "itemName": "Cégep de La Pocatière",
                "dli": "O19359201640",
                "city": "La Pocatière",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 675,
                "itemName": "Cégep de Lévis-Lauzon",
                "dli": "O19359201666",
                "city": "Lévis",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 676,
                "itemName": "Cégep de Maisonneuve",
                "dli": "O19359201705",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 677,
                "itemName": "Cégep de Matane",
                "dli": "O19359201731",
                "city": "Matane",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 678,
                "itemName": "Cégep de Rimouski",
                "dli": "O19359201769",
                "city": "Rimouski",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 679,
                "itemName": "Cégep de Rivière-du-Loup",
                "dli": "O19359201780",
                "city": "Rivière-du-Loup",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 680,
                "itemName": "Cégep de Rosemont",
                "dli": "O19359201793",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 681,
                "itemName": "Cégep de Saint-Jérôme",
                "dli": "O19359201588",
                "city": "Saint-Jérôme",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 682,
                "itemName": "Cégep de Saint-Laurent",
                "dli": "O19359201653",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 683,
                "itemName": "Cégep de Sainte-Foy",
                "dli": "O19359201485",
                "city": "Quebec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 684,
                "itemName": "Cégep de Sept-Îles",
                "dli": "O19359201806",
                "city": "Sept-Îles",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 685,
                "itemName": "Cégep de Sherbrooke",
                "dli": "O19359201832",
                "city": "Sherbrooke",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 686,
                "itemName": "Cégep de Sorel-Tracy",
                "dli": "O19359201845",
                "city": "Sorel-Tracy",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 687,
                "itemName": "Cégep de St-Félicien",
                "dli": "O19359201472",
                "city": "Saint-Félicien",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 688,
                "itemName": "Cégep de St-Hyacinthe",
                "dli": "O19359201564",
                "city": "Saint-Hyacinthe",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 689,
                "itemName": "Cégep de Thetford",
                "dli": "O19359201858",
                "city": "Thetford Mines",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 690,
                "itemName": "Cégep de Trois-Rivières",
                "dli": "O19359201871",
                "city": "Trois-Rivières",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 691,
                "itemName": "Cégep de Valleyfield",
                "dli": "O19359201884",
                "city": "Salaberry-de-Valleyfield",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 692,
                "itemName": "Cégep de Victoriaville",
                "dli": "O19359201910",
                "city": "Victoriaville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 693,
                "itemName": "Cégep de la Gaspésie et des Îles",
                "dli": "O19359201512",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 694,
                "itemName": "Cégep de la Gaspésie et des Îles",
                "dli": "O19359201512",
                "city": "Gaspé",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 695,
                "itemName": "Cégep de l’Abitibi-Témiscamingue",
                "dli": "O19359201318",
                "city": "Rouyn-Noranda",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 696,
                "itemName": "Cégep de l’Outaouais",
                "dli": "O19359201757",
                "city": "Gatineau",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 697,
                "itemName": "Cégep du Vieux Montréal",
                "dli": "O19359201923",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 698,
                "itemName": "Cégep d’Ahuntsic",
                "dli": "O19359201331",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 699,
                "itemName": "Cégep régional de Lanaudière",
                "dli": "O19359201627",
                "city": "Repentigny",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 700,
                "itemName": "Cégep Édouard Montpetit",
                "dli": "O19359201458",
                "city": "Longueuil",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 701,
                "itemName": "DEA Canadian College",
                "dli": "O143703092532",
                "city": "North Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 702,
                "itemName": "Da Vinci Centre of Arts and Design",
                "dli": "O19331278712",
                "city": "Lower Sackville",
                "campus": "Lower Sackville",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 703,
                "itemName": "DaVinci College of Art & Design NB Ltd.",
                "dli": "O19273839182",
                "city": "Fredericton",
                "campus": "Fredericton",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 704,
                "itemName": "Dan Robert College of Health Care",
                "dli": "O19332734842",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 705,
                "itemName": "Delmar College of Hair and Esthetics Inc.",
                "dli": "O110206814387",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 706,
                "itemName": "Diamond Flight Centre London Inc.",
                "dli": "O258211997542",
                "city": "London",
                "campus": "London",
                "offersPGWPEligible": "Yes (detailsfor Flight schools)",
                "province": "Ontario"
            },
            {
                "id": 707,
                "itemName": "Digital School",
                "dli": "O19256763892",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 708,
                "itemName": "Discovery Community College",
                "dli": "O19351714242",
                "city": "Campbell River, Courtenay, Maple Ridge, Nanaimo, Parksville, Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 709,
                "itemName": "DiveSafe International",
                "dli": "O261679968772",
                "city": "Campbell River",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 710,
                "itemName": "Diving Dynamics – Home of the Commercial Diving Institute of Canada (CDI)",
                "dli": "O264560001042",
                "city": "Kelowna",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 711,
                "itemName": "Dominion College of Business Health and Technology Inc.",
                "dli": "O134304400382",
                "city": "Toronto",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 712,
                "itemName": "Dominion College of Business Health and Technology Inc.",
                "dli": "O134304400382",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 713,
                "itemName": "Dominion Herbal College",
                "dli": "O19330170302",
                "city": "Burnaby",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 714,
                "itemName": "Don Mills Career College for Health, Business and Technology",
                "dli": "O19361109752",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 715,
                "itemName": "Douglas College",
                "dli": "O19360973702",
                "city": "Coquitlam, New Westminster, Surrey",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 716,
                "itemName": "Drake Medox College",
                "dli": "O19394940944",
                "city": "Surrey, Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 717,
                "itemName": "Durham College",
                "dli": "O19361081012",
                "city": "Oshawa",
                "campus": "Oshawa",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 718,
                "itemName": "Durham College",
                "dli": "O19361081012",
                "city": "Pickering",
                "campus": "Pickering",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 719,
                "itemName": "Durham College",
                "dli": "O19361081012",
                "city": "Whitby",
                "campus": "Whitby",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 720,
                "itemName": "Durham Flight Centre Inc.",
                "dli": "O142270514172",
                "city": "Oshawa",
                "campus": "Oshawa",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 721,
                "itemName": "EC Toronto",
                "dli": "O19351820052",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 722,
                "itemName": "EC Vancouver",
                "dli": "O19280217882",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 723,
                "itemName": "EF International Language",
                "dli": "O19314246102",
                "city": "Vancouver, Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 724,
                "itemName": "EF International Language Centres",
                "dli": "O19333134952",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 725,
                "itemName": "ELS Language Centers",
                "dli": "O19360978002",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 726,
                "itemName": "ELS Language Centers",
                "dli": "O19361070902",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 727,
                "itemName": "EQMBO-Entreprises Centre d’aide technique et technologique inc.",
                "dli": "O19359010595",
                "city": "Victoriaville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 728,
                "itemName": "ERP Technical College",
                "dli": "O124300140577",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 729,
                "itemName": "ESLI",
                "dli": "O19361202892",
                "city": "Langley",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 730,
                "itemName": "East Coast Language College",
                "dli": "O19130326522",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 731,
                "itemName": "East Coast Trades College",
                "dli": "O259056599482",
                "city": "Fredericton",
                "campus": "Fredericton",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 732,
                "itemName": "East-West College of Business and Technology",
                "dli": "O138917416102",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 733,
                "itemName": "Eastern College",
                "dli": "O112937696097",
                "city": "Saint John",
                "campus": "Saint John",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 734,
                "itemName": "Eastern College",
                "dli": "O112937696097",
                "city": "Fredericton",
                "campus": "Fredericton",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 735,
                "itemName": "Eastern College",
                "dli": "O112937696097",
                "city": "Moncton",
                "campus": "Moncton",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 736,
                "itemName": "Eastern College",
                "dli": "O19391556993",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 737,
                "itemName": "Eden College Health Sciences, Wellness, Aesthetics, Hair",
                "dli": "O116261235217",
                "city": "Bolton",
                "campus": "Bolton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 738,
                "itemName": "Edge Academy",
                "dli": "O136988539452",
                "city": "Burlington",
                "campus": "Burlington",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 739,
                "itemName": "Edmonton Flying Club",
                "dli": "O246354917297",
                "city": "Spruce Grove",
                "campus": "Spruce Grove",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 740,
                "itemName": "Eight Branches Inc.",
                "dli": "O211893372327",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 741,
                "itemName": "Elevate Beauty Institute of Cosmetology",
                "dli": "O139340859112",
                "city": "New Minas",
                "campus": "New Minas",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 742,
                "itemName": "Elevated Learning Academy Inc.",
                "dli": "O112565603857",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 743,
                "itemName": "Elevated Learning Academy Inc.",
                "dli": "O112565603857",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 744,
                "itemName": "Elite Hairstyling and Esthetics Training Center",
                "dli": "O132526861247",
                "city": "Moose Jaw",
                "offersPGWPEligible": "No",
                "province": "Saskatchewan"
            },
            {
                "id": 745,
                "itemName": "Embody School of Pilates",
                "dli": "O248326364297",
                "city": "Canmore",
                "campus": "Canmore",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 746,
                "itemName": "Emmanuel Bible College",
                "dli": "O19556087182",
                "city": "Kitchener",
                "campus": "Kitchener",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 747,
                "itemName": "English Depot Education Academy",
                "dli": "O143477803312",
                "city": "Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 748,
                "itemName": "English Encounters Inc.",
                "dli": "O19283938092",
                "city": "Burlington",
                "campus": "Burlington",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 749,
                "itemName": "English School of Canada Inc.",
                "dli": "O19350421222",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 750,
                "itemName": "Equilibrium School",
                "dli": "O212476866687",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 751,
                "itemName": "Essential Helicopters",
                "dli": "O212628957297",
                "city": "North Bay",
                "campus": "Canadore College Aviation",
                "offersPGWPEligible": "Yes (details for Flight schools)"
            },
            {
                "id": 752,
                "itemName": "Eston College",
                "dli": "O110032269317",
                "city": "Eston",
                "offersPGWPEligible": "No",
                "province": "Saskatchewan"
            },
            {
                "id": 753,
                "itemName": "Ethnos Canada",
                "dli": "O111244519157",
                "city": "Durham",
                "campus": "NTM Canada Missionary Training Centre",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 754,
                "itemName": "Eton College",
                "dli": "O19330008162",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 755,
                "itemName": "Eurocentres, Atlantic Canada",
                "dli": "O19210345642",
                "city": "Lunenburg",
                "campus": "Lunenburg",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 756,
                "itemName": "Evergreen College",
                "dli": "O127883500507",
                "city": "Markham",
                "campus": "Markham",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 757,
                "itemName": "Evergreen College",
                "dli": "O19304591592",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 758,
                "itemName": "Evergreen College",
                "dli": "O19304591592",
                "city": "Toronto",
                "campus": "North",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 759,
                "itemName": "Evergreen College",
                "dli": "O19304591592",
                "city": "Oakville",
                "campus": "West",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 760,
                "itemName": "Evergreen College Brampton",
                "dli": "O116456456218",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 761,
                "itemName": "Evergreen College Mississauga",
                "dli": "O246851123527",
                "city": "Mississauga",
                "campus": "Evergreen College Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 762,
                "itemName": "Excel Career College",
                "dli": "O19394940966",
                "city": "Courtenay",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 763,
                "itemName": "Excel Flight Training Incorporated",
                "dli": "O260902561272",
                "city": "Lethbridge",
                "campus": "Lethbridge",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 764,
                "itemName": "Extra Centre de Formation",
                "dli": "O246882793353",
                "city": "Boucherville",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 765,
                "itemName": "FaithWay Baptist College of Canada",
                "dli": "O256014050387",
                "city": "Ajax",
                "campus": "Ajax",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 766,
                "itemName": "Fanshawe College",
                "dli": "O19361039982",
                "city": "London",
                "campus": "London Main ,\n London Downtown ,\n London South ,\n Huron/Bruce Regional Sites – Clinton Centre,\n Simcoe/Norfolk ,\n St. Thomas/Elgin ,\n Woodstock/Oxford County",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 767,
                "itemName": "First College",
                "dli": "O256618792847",
                "city": "Kelowna, West Kelowna",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 768,
                "itemName": "Flair College of Management and Technology",
                "dli": "O19800346567",
                "city": "Vaughan",
                "campus": "Vaughan",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 769,
                "itemName": "Fleming College",
                "dli": "O19303189722",
                "city": "Cobourg",
                "campus": "Cobourg",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 770,
                "itemName": "Fleming College",
                "dli": "O19303189722",
                "city": "Haliburton",
                "campus": "Haliburton",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 771,
                "itemName": "Fleming College",
                "dli": "O19303189722",
                "city": "Lindsay",
                "campus": "Frost",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 772,
                "itemName": "Fleming College",
                "dli": "O19303189722",
                "city": "Peterborough",
                "campus": "Sutherland",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 773,
                "itemName": "Focus College",
                "dli": "O19330446102",
                "city": "Kelowna, Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 774,
                "itemName": "Foundation for Montessori Education",
                "dli": "O19395535573",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 775,
                "itemName": "Franklin English Language College",
                "dli": "O19396094599",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 776,
                "itemName": "Fraser International College",
                "dli": "O19239078442",
                "city": "Burnaby",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 777,
                "itemName": "Freedom College of Business Healthcare and Technology Inc",
                "dli": "O142386072212",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 778,
                "itemName": "Futures Canadian College of Business, Health and Technology",
                "dli": "O135634495162",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 779,
                "itemName": "GEOS Languages Plus",
                "dli": "O19361015442",
                "city": "Vancouver, Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 780,
                "itemName": "GEOS Languages Plus",
                "dli": "O19375841122",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 781,
                "itemName": "GEOS Languages Plus",
                "dli": "O19375841122",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 782,
                "itemName": "GEOS Languages Plus, Calgary",
                "dli": "O18831626322",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 783,
                "itemName": "Gander Flight Training (Exploits Valley Air Service)",
                "dli": "O19441196787",
                "city": "Gander",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 784,
                "itemName": "Gastown Business College",
                "dli": "O19238785512",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 785,
                "itemName": "Gateway College",
                "dli": "O19279807782",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 786,
                "itemName": "Georgian College",
                "dli": "O19395677361",
                "city": "Barrie",
                "campus": "Barrie",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 787,
                "itemName": "Georgian College",
                "dli": "O19395677361",
                "city": "Bracebridge",
                "campus": "Muskoka",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 788,
                "itemName": "Georgian College",
                "dli": "O19395677361",
                "city": "Midland",
                "campus": "Midland",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 789,
                "itemName": "Georgian College",
                "dli": "O19395677361",
                "city": "Orangeville",
                "campus": "Orangeville",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 790,
                "itemName": "Georgian College",
                "dli": "O19395677361",
                "city": "Orillia",
                "campus": "Orillia",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 791,
                "itemName": "Georgian College",
                "dli": "O19395677361",
                "city": "Owen Sound",
                "campus": "Owen Sound",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 792,
                "itemName": "Georgian College",
                "dli": "O19395677361",
                "city": "South Georgian Bay",
                "campus": "South Georgian Bay",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 793,
                "itemName": "Gestalt Institute of Toronto",
                "dli": "O116264030217",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 794,
                "itemName": "Glenbow College",
                "dli": "O265009534572",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 795,
                "itemName": "Global College",
                "dli": "O19347491712",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 796,
                "itemName": "Global Village Calgary (Rocky Mountain English Center Ltd)",
                "dli": "O18761476312",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 797,
                "itemName": "Global Village Victoria",
                "dli": "O19319050762",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 798,
                "itemName": "Goh Ballet Academy",
                "dli": "O110656686527",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 799,
                "itemName": "Grande Prairie Regional College",
                "dli": "O19391056654",
                "city": "Grand Prairie",
                "campus": "Grande Prairie",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 800,
                "itemName": "Granville College",
                "dli": "O116736261697",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 801,
                "itemName": "Great Lakes Helicopter Corp.",
                "dli": "O19516933662",
                "city": "Breslau",
                "campus": "Breslau",
                "offersPGWPEligible": "Yes (details for Flight schools)"
            },
            {
                "id": 802,
                "itemName": "Great Plains College",
                "dli": "O214383061827",
                "city": "Swift Current",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 803,
                "itemName": "Great Plains College",
                "dli": "O214383061827",
                "city": "Swift Current",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 804,
                "itemName": "Great Plains College",
                "dli": "O214383061827",
                "city": "Swift Current",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 805,
                "itemName": "Great Plains College",
                "dli": "O214383061827",
                "city": "Swift Current",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 806,
                "itemName": "Great Plains College",
                "dli": "O214383061827",
                "city": "Swift Current",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 807,
                "itemName": "Greystone College of Business and Technology",
                "dli": "O19352522932",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 808,
                "itemName": "Greystone College of Business and Technology (Toronto) Inc.",
                "dli": "O19376982302",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 809,
                "itemName": "Groupe CTT inc.",
                "dli": "O19359010569",
                "city": "Saint-Hyacinthe",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 810,
                "itemName": "Halifax Language Institute of Canada",
                "dli": "O19091611322",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 811,
                "itemName": "Hamilton Institute for Health Personnel",
                "dli": "O19303336332",
                "city": "Hamilton",
                "campus": "Hamilton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 812,
                "itemName": "Hansa Language Centre",
                "dli": "O19361318822",
                "city": "Toronto",
                "campus": "Eglinton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 813,
                "itemName": "Hansa Language Centre",
                "dli": "O19361318822",
                "city": "Toronto",
                "campus": "Yonge",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 814,
                "itemName": "Hanson International Academy",
                "dli": "O19618862812",
                "city": "New Westminster, Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 815,
                "itemName": "Hanson International Academy",
                "dli": "O19375796852",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 816,
                "itemName": "Hanson International Academy",
                "dli": "O19375796852",
                "city": "Toronto",
                "campus": "HSL School of Languages",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 817,
                "itemName": "Hanson International Academy",
                "dli": "O19375796852",
                "city": "North York",
                "campus": "North York",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 818,
                "itemName": "Harris Institute For The Arts",
                "dli": "O19331451562",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 819,
                "itemName": "Harv’s Air Service",
                "dli": "O19361304312",
                "city": "Steinbach",
                "campus": "Steinbach",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Manitoba"
            },
            {
                "id": 820,
                "itemName": "Heartland International English School",
                "dli": "O19179220532",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 821,
                "itemName": "Heartland International English School",
                "dli": "O141526185312",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 822,
                "itemName": "Heli-College Canada Training Inc.",
                "dli": "O19279689162",
                "city": "Langley",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 823,
                "itemName": "Helicopters Canada Flight Training School",
                "dli": "O19304016372",
                "city": "North Bay",
                "campus": "North Bay",
                "offersPGWPEligible": "Yes (details for Flight schools)"
            },
            {
                "id": 824,
                "itemName": "Heritage College & Seminary",
                "dli": "O110124108754",
                "city": "Cambridge",
                "campus": "Cambridge",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 825,
                "itemName": "Heritage Community College",
                "dli": "O132232057367",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 826,
                "itemName": "Herzing College",
                "dli": "O19397544252",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 827,
                "itemName": "Herzing College",
                "dli": "O19397544252",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 828,
                "itemName": "Herzing College [Supply Chain Management and Logistics; Occupational Health and Safety Officer; Medical Laboratory Assistant; Hospitality and Event Management]",
                "dli": "O262539608072",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 829,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "Alberton",
                "campus": "West Prince",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 830,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "Charlottetown",
                "campus": "Tourism and Culinary Centre",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 831,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "Charlottetown",
                "campus": "Prince of Wales",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 832,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "Georgetown",
                "campus": "Georgetown Centre",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 833,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "Montague",
                "campus": "Montague Centre",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 834,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "O’Leary",
                "campus": "O’Leary Centre",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 835,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "Slemon Park",
                "campus": "Atlantic Police Academy",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 836,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "Souris",
                "campus": "Souris Site",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 837,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "Summerside",
                "campus": "Summerside,",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 838,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "Summerside",
                "campus": "East Prince Centre,",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 839,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "Summerside",
                "campus": "Marine Training Centre",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 840,
                "itemName": "Holland College",
                "dli": "O19220082122",
                "city": "Tignish",
                "campus": "Dalton Centre",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 841,
                "itemName": "Horizon College and Seminary Inc.",
                "dli": "O214426376877",
                "city": "Saskatoon",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 842,
                "itemName": "Humber College",
                "dli": "O19376943122",
                "city": "Toronto",
                "campus": "Humber Lakeshore",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 843,
                "itemName": "Humber College",
                "dli": "O19376943122",
                "city": "Toronto",
                "campus": "Humber North",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 844,
                "itemName": "Humber College",
                "dli": "O19376943122",
                "city": "Orangeville",
                "campus": "Orangeville",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 845,
                "itemName": "Hélicraft",
                "dli": "O120216253925",
                "city": "Saint-Hubert",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 846,
                "itemName": "IBT College Business Travel & Tourism Technology",
                "dli": "O19359074332",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 847,
                "itemName": "ICT Northumberland College",
                "dli": "O115158695747",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 848,
                "itemName": "IH Career College",
                "dli": "O19395639240",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 849,
                "itemName": "ILAC - International College",
                "dli": "O19319417742",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 850,
                "itemName": "ILAC - International Language Academy of Canada",
                "dli": "O19319227442",
                "city": "Toronto",
                "campus": "Yorkville Avenue Location",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 851,
                "itemName": "ILAC - International Language Academy of Canada",
                "dli": "O19319227442",
                "city": "Toronto",
                "campus": "Yonge Street Location",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 852,
                "itemName": "ILAC - International Language Academy of Canada",
                "dli": "O19319227442",
                "city": "Toronto",
                "campus": "Main Location",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 853,
                "itemName": "ILAC - International Language Academy of Canada",
                "dli": "O19319227442",
                "city": "Toronto",
                "campus": "College Street Location",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 854,
                "itemName": "ILAC - International Language Academy of Canada",
                "dli": "O19319227442",
                "city": "Toronto",
                "campus": "Bloor Street West Location",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 855,
                "itemName": "ILAC - International Language Academy of Canada",
                "dli": "O19319227442",
                "city": "Toronto",
                "campus": "Bloor Street East Location",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 856,
                "itemName": "ILAC International College",
                "dli": "O110957942277",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 857,
                "itemName": "ILSC Vancouver",
                "dli": "O19275396842",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 858,
                "itemName": "IPIQ - Institut de protection contre les incendies du Québec",
                "dli": "O246882793428",
                "city": "Laval",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 859,
                "itemName": "ISS Language and Career College of BC",
                "dli": "O19279658882",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 860,
                "itemName": "In Focus Film School",
                "dli": "O19395639663",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 861,
                "itemName": "Infofit Educators",
                "dli": "O19394941064",
                "city": "Burnaby, Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 862,
                "itemName": "InnoTech College",
                "dli": "O126734792847",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 863,
                "itemName": "Innofibre - Centre d’innovation des produits cellulosiques",
                "dli": "O19359010394",
                "city": "Trois-Rivières",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 864,
                "itemName": "Innovation maritime",
                "dli": "O19359010660",
                "city": "Rimouski",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 865,
                "itemName": "Innovations in Business Solutions Inc",
                "dli": "O19361300542",
                "city": "North York",
                "campus": "North York",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 866,
                "itemName": "Innovations in Business Solutions Inc.",
                "dli": "O19361300542",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 867,
                "itemName": "Insignia College",
                "dli": "O137690849762",
                "city": "Delta, Surrey",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 868,
                "itemName": "Institut Quadrilium de Montréal",
                "dli": "O134634211862",
                "city": "Montreal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 869,
                "itemName": "Institut Teccart",
                "dli": "O246882793398",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 870,
                "itemName": "Institut Teccart (2003)",
                "dli": "O19359010205",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 871,
                "itemName": "Institut Trebas Québec Inc.",
                "dli": "O19338447392",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 872,
                "itemName": "Institut de formation théologique de Montréal",
                "dli": "O19359201258",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 873,
                "itemName": "Institut de recherche sur l’intégration professionnelle des immigrants (IRIPI)",
                "dli": "O19359010677",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 874,
                "itemName": "Institut de technologie agroalimentaire",
                "dli": "O19359010950",
                "city": "La Pocatière",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 875,
                "itemName": "Institut de technologie agroalimentaire",
                "dli": "O19359010967",
                "city": "Saint-Hyacinthe",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 876,
                "itemName": "Institut de technologie des emballages et du génie alimentaire (ITEGA)",
                "dli": "O19359010703",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 877,
                "itemName": "Institut de tourisme et d’hôtellerie du Québec",
                "dli": "O110173474479",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 878,
                "itemName": "Institut des communications graphiques et de l’imprimabilité",
                "dli": "O19359010634",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 879,
                "itemName": "Institut du transport avancé du Québec",
                "dli": "O19359010690",
                "city": "Saint-Jérôme",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 880,
                "itemName": "Institut d’enregistrement du Canada",
                "dli": "O19338447366",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 881,
                "itemName": "Institut international de logistique de Montréal (IILM)",
                "dli": "O19359010647",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 882,
                "itemName": "Institut national de la recherche scientifique",
                "dli": "O19359011185",
                "city": "Quebec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 883,
                "itemName": "Institut national de l’image et du son (INIS)",
                "dli": "O213292196972",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 884,
                "itemName": "Institut supérieur d’informatique I.S.I.",
                "dli": "O19338447379",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 885,
                "itemName": "Institut technique Aviron de Montréal inc.",
                "dli": "O246882793413",
                "city": "Mont-Royal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 886,
                "itemName": "Institut technologique de maintenance industrielle",
                "dli": "O19359010715",
                "city": "Sept-Îles",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 887,
                "itemName": "Institute for Advanced Judaic Studies",
                "dli": "O19361109472",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 888,
                "itemName": "Institute for Christian Studies",
                "dli": "O19527186232",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 889,
                "itemName": "Institute of Advanced Learning",
                "dli": "O19220185032",
                "city": "Charlottetown",
                "campus": "Charlottetown",
                "offersPGWPEligible": "No",
                "province": "Prince Edward Island"
            },
            {
                "id": 890,
                "itemName": "Institute of Machine Tool Technology",
                "dli": "O19333120432",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 891,
                "itemName": "Institute of Technical Trades Limited",
                "dli": "O19394924707",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 892,
                "itemName": "Institute of Technology Development of Canada (ITD)",
                "dli": "O19279542632",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 893,
                "itemName": "Interior Academy",
                "dli": "O19395639387",
                "city": "Kamloops",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 894,
                "itemName": "Interlake Aviation Ltd",
                "dli": "O123065735047",
                "city": "Gimli",
                "campus": "Gimli",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Manitoba"
            },
            {
                "id": 895,
                "itemName": "International Academy of Massage Inc",
                "dli": "O19395281777",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 896,
                "itemName": "International Beauty Institute",
                "dli": "O112063748307",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 897,
                "itemName": "International Centre for English for Academic Preparation Inc",
                "dli": "O136931269542",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 898,
                "itemName": "International College of Manitoba",
                "dli": "O19315356682",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 899,
                "itemName": "International Flight Centre Inc.",
                "dli": "O19395639399",
                "city": "Delta",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 900,
                "itemName": "International House Vancouver",
                "dli": "O19279619012",
                "city": "Vancouver, Whistler",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 901,
                "itemName": "International Institute of Travel",
                "dli": "O110096841789",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 902,
                "itemName": "International Language Academy of Canada (ILAC)",
                "dli": "O19283901032",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 903,
                "itemName": "International Language Institute",
                "dli": "O19126582122",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 904,
                "itemName": "International Test Pilots School",
                "dli": "O132382217337",
                "city": "London",
                "campus": "London",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 905,
                "itemName": "Isart Digital Montréal",
                "dli": "O112216989861",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 906,
                "itemName": "Island Career Academy",
                "dli": "O19370893142",
                "city": "Sydney",
                "campus": "Sydney",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 907,
                "itemName": "Island Coastal Aviation Inc.",
                "dli": "O110265135107",
                "city": "Pitt Meadows",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 908,
                "itemName": "JCI Institute",
                "dli": "O19283904182",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 909,
                "itemName": "JRS College of Business and Health Care Inc",
                "dli": "O19396019413",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 910,
                "itemName": "Jenny Wong Beauty Institute",
                "dli": "O212620403467",
                "city": "Markham",
                "campus": "Markham",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 911,
                "itemName": "Justice Institute of British Columbia",
                "dli": "O19394941140",
                "city": "Chilliwack, Kelowna, Maple Ridge, New Westminster, Victoria",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 912,
                "itemName": "Jyot’s Aesthetics Academy",
                "dli": "O139835841562",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 913,
                "itemName": "KLC College: Healthcare, Business, Education",
                "dli": "O19517585062",
                "city": "Kingston",
                "campus": "Main",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 914,
                "itemName": "KLC College: Healthcare, Business, Education",
                "dli": "O19517585062",
                "city": "Kingston",
                "campus": "East",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 915,
                "itemName": "KLC College: Healthcare, Business, Education",
                "dli": "O19517585062",
                "city": "Kingston",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 916,
                "itemName": "KLC College: Healthcare, Business, Education",
                "dli": "O19517585062",
                "city": "Kingston",
                "campus": "Whitby",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 917,
                "itemName": "Kanatamat Tshitipenitamunu",
                "dli": "O246882793443",
                "city": "Schefferville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 918,
                "itemName": "Kaplan International English",
                "dli": "O19527292642",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 919,
                "itemName": "Kaplan International Languages",
                "dli": "O19394941182",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 920,
                "itemName": "Kelowna College of Professional Counselling",
                "dli": "O124191974237",
                "city": "Kelowna",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 921,
                "itemName": "Keyano College",
                "dli": "O19283886532",
                "city": "Fort McMurray",
                "campus": "Fort McMurray",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 922,
                "itemName": "Keyin College (Advanced Educational Systems Inc.)",
                "dli": "O19441207179",
                "city": "St. John’s",
                "offersPGWPEligible": "No",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 923,
                "itemName": "Keyin College (Data Enterprises)",
                "dli": "O19441233940",
                "city": "Burin",
                "offersPGWPEligible": "No",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 924,
                "itemName": "Keyin College (Future First Inc.)",
                "dli": "O19441276742",
                "city": "Gander",
                "offersPGWPEligible": "No",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 925,
                "itemName": "Keyin College (Future First Inc.)",
                "dli": "O19441276742",
                "city": "Grand Falls-Windsor",
                "offersPGWPEligible": "No",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 926,
                "itemName": "Keyin College (KMD Educational Services Ltd.)",
                "dli": "O212215541367",
                "city": "Carbonear",
                "offersPGWPEligible": "No",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 927,
                "itemName": "Keyin College (Southcoast Training)",
                "dli": "O19441297352",
                "city": "Marystown",
                "offersPGWPEligible": "No",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 928,
                "itemName": "Keyin College Western",
                "dli": "O257888013712",
                "city": "Stephenville",
                "offersPGWPEligible": "No",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 929,
                "itemName": "Keystone College Ltd.",
                "dli": "O130389245177",
                "city": "Courtenay, Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 930,
                "itemName": "Kikkawa College",
                "dli": "O19351067522",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 931,
                "itemName": "Knox College",
                "dli": "O19859651317",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 932,
                "itemName": "Kootenay Columbia College of Integrative Health Sciences",
                "dli": "O19256958102",
                "city": "Nelson",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 933,
                "itemName": "Kosmetae Academy",
                "dli": "O19283913752",
                "city": "Abbotsford",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 934,
                "itemName": "Kutenai Art Therapy Institute",
                "dli": "O19395639473",
                "city": "Nelson",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 935,
                "itemName": "LR Helicopters Inc.",
                "dli": "O19257308652",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 936,
                "itemName": "La Cité collégiale",
                "dli": "O19395422135",
                "city": "Alfred",
                "campus": "d’Alfred",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 937,
                "itemName": "La Cité collégiale",
                "dli": "O19395422135",
                "city": "Hawkesbury",
                "campus": "de Hawkesbury",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 938,
                "itemName": "La Cité collégiale",
                "dli": "O19395422135",
                "city": "Ottawa",
                "campus": "Alphonse-Desjardins",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 939,
                "itemName": "La Cité collégiale",
                "dli": "O19395422135",
                "city": "Ottawa",
                "campus": "principal",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 940,
                "itemName": "La Cité collégiale",
                "dli": "O19395422135",
                "city": "Ottawa",
                "campus": "Université d’Ottawa (Collaboratif)",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 941,
                "itemName": "LaFrance School of Hair Design",
                "dli": "O123689900947",
                "city": "Grand Falls",
                "campus": "Grand Falls",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 942,
                "itemName": "LaSalle College Vancouver",
                "dli": "O19275426742",
                "city": "Kamloops, Vancouver, Victoria",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 943,
                "itemName": "Lachute Aviation",
                "dli": "O11003283266",
                "city": "Lachute",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 944,
                "itemName": "Lakeland College",
                "dli": "O18777577472",
                "city": "Lloydminster",
                "campus": "Lloydminster",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 945,
                "itemName": "Lakeland College",
                "dli": "O18777577472",
                "city": "Vermillion",
                "campus": "Vermillion",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 946,
                "itemName": "Lambton College",
                "dli": "O19305293332",
                "city": "Mississauga",
                "campus": "Lambton College at Queen’s College of Business",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 947,
                "itemName": "Lambton College",
                "dli": "O19305293332",
                "city": "Mississauga",
                "campus": "Technology and Public Safety",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 948,
                "itemName": "Lambton College",
                "dli": "O19305293332",
                "city": "Sarnia",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 949,
                "itemName": "Lambton College",
                "dli": "O19305293332",
                "city": "Toronto",
                "campus": "Lambton College at Cestar College of Business",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 950,
                "itemName": "Lambton College",
                "dli": "O19305293332",
                "city": "Toronto",
                "campus": "Health and Technology",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 951,
                "itemName": "Langara College",
                "dli": "O19319074622",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 952,
                "itemName": "Langley Flying School",
                "dli": "O19395299244",
                "city": "Langley",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 953,
                "itemName": "Language Arts Institute",
                "dli": "O262347512072",
                "city": "Burnaby",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 954,
                "itemName": "Language Studies International (Canada) Ltd.",
                "dli": "O19332668052",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 955,
                "itemName": "Language Studies International (LSI)",
                "dli": "O19283928542",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 956,
                "itemName": "Languages Across Borders",
                "dli": "O19274549462",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 957,
                "itemName": "Le Cordon Bleu Ottawa Culinary Arts Institute",
                "dli": "O19395083461",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 958,
                "itemName": "Legends Academy",
                "dli": "O122551197237",
                "city": "Abbotsford",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 959,
                "itemName": "Lethbridge College",
                "dli": "O19391056756",
                "city": "Lethbridge",
                "campus": "Lethbridge",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 960,
                "itemName": "Liaison College",
                "dli": "O260428979632",
                "city": "Kitchener",
                "campus": "Kitchener",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 961,
                "itemName": "Liaison College",
                "dli": "O19800254657",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 962,
                "itemName": "Liaison College",
                "dli": "O136578032172",
                "city": "Hamilton",
                "campus": "Hamilton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 963,
                "itemName": "Liaison College",
                "dli": "O19799913417",
                "city": "Oakville",
                "campus": "Oakville",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 964,
                "itemName": "Liaison College",
                "dli": "O110096841350",
                "city": "Toronto",
                "campus": "Downtown Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 965,
                "itemName": "Lighthouse Labs",
                "dli": "O110684673337",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 966,
                "itemName": "Living Word Bible College",
                "dli": "O213392219317",
                "city": "Swan River",
                "campus": "Swan River",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 967,
                "itemName": "London Language Institute",
                "dli": "O19304551432",
                "city": "London",
                "campus": "London",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 968,
                "itemName": "London School",
                "dli": "O19352116052",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 969,
                "itemName": "Lost Boys Studios - School of Visual Effects",
                "dli": "O19274961822",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 970,
                "itemName": "Louis Riel School Division Arts and Technology Centre",
                "dli": "O19708693987",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "Yes",
                "province": "Manitoba"
            },
            {
                "id": 971,
                "itemName": "Loyalist College",
                "dli": "O19359011572",
                "city": "Bancroft",
                "campus": "Bancroft",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 972,
                "itemName": "Loyalist College",
                "dli": "O19359011572",
                "city": "Belleville",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 973,
                "itemName": "Loyalist College at Toronto Business College",
                "dli": "O264223510342",
                "city": "Mississauga",
                "campus": "Toronto Business College – Mississauga",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 974,
                "itemName": "Lutheran Theological Seminary",
                "dli": "O213779356297",
                "city": "Saskatoon",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 975,
                "itemName": "L’École du routier professionnel du Québec (1996) inc.",
                "dli": "O246882793458",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 976,
                "itemName": "M.C. College",
                "dli": "O263573449582",
                "city": "Kelowna",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 977,
                "itemName": "M.C. College (M.C. College Group) - [Hairstyling, Esthetics, Nail Technician]",
                "dli": "O19391557112",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 978,
                "itemName": "MAG Canada",
                "dli": "O124449542367",
                "city": "Garson",
                "campus": "Garson",
                "offersPGWPEligible": "Yes (detailsfor Flight schools)",
                "province": "Ontario"
            },
            {
                "id": 979,
                "itemName": "MC College",
                "dli": "O19283886842",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 980,
                "itemName": "MC College",
                "dli": "O19283886842",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 981,
                "itemName": "MC College",
                "dli": "O19283886842",
                "city": "Red Deer",
                "campus": "Red Deer",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 982,
                "itemName": "MCG Career College",
                "dli": "O263593006222",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 983,
                "itemName": "MCG Career College",
                "dli": "O263593006222",
                "city": "Calgary",
                "campus": "Cold Lake",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 984,
                "itemName": "MCG Career College",
                "dli": "O263593006222",
                "city": "Calgary",
                "campus": "Red Deer",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 985,
                "itemName": "MH Vicars School of Massage Therapy Ltd",
                "dli": "O116699506097",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 986,
                "itemName": "MH Vicars School of Massage Therapy Ltd",
                "dli": "O116699506097",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 987,
                "itemName": "MLC College of Business, Technology and Healthcare",
                "dli": "O19607783802",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 988,
                "itemName": "MaKami College Inc.",
                "dli": "O113097545597",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 989,
                "itemName": "MaKami College Inc.",
                "dli": "O113097545597",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 990,
                "itemName": "Maayanos Torah",
                "dli": "O142266708602",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 991,
                "itemName": "Madlab School of Fitness",
                "dli": "O19375649052",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 992,
                "itemName": "Maison familiale rurale du Granit",
                "dli": "O246882793473",
                "city": "Saint-Romain",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 993,
                "itemName": "Manitoba Institute of Trades and Technology",
                "dli": "O19391828428",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "Yes",
                "province": "Manitoba"
            },
            {
                "id": 994,
                "itemName": "Manitoba Tourism Education College (Hospitality and Hotel Certificate)",
                "dli": "O263386042822",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 995,
                "itemName": "Maple Leaf Academy",
                "dli": "O114623912037",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 996,
                "itemName": "Marguerite Connolly Training and Consulting",
                "dli": "O19220652372",
                "city": "Charlottetown",
                "campus": "Charlottetown",
                "offersPGWPEligible": "No",
                "province": "Prince Edward Island"
            },
            {
                "id": 997,
                "itemName": "Maritime Business College",
                "dli": "O19391557015",
                "city": "Dartmouth",
                "campus": "Dartmouth",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 998,
                "itemName": "Maritime Christian College",
                "dli": "O19319254068",
                "city": "Charlottetown",
                "campus": "Charlottetown",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 999,
                "itemName": "Maritime College of Forest Technology / Collège de Technologie Forestière des Maritimes",
                "dli": "O19391276070",
                "city": "Bathurst",
                "campus": "Bathurst  (French )",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1000,
                "itemName": "Maritime College of Forest Technology / Collège de Technologie Forestière des Maritimes",
                "dli": "O19391276070",
                "city": "Bathurst",
                "campus": "Collège de Technologie Forestière des Maritimes",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1001,
                "itemName": "Maritime College of Forest Technology / Collège de Technologie Forestière des Maritimes",
                "dli": "O19391276070",
                "city": "Fredericton",
                "campus": "Maritime College of Forest Technology",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1002,
                "itemName": "Maritime College of Forest Technology / Collège de Technologie Forestière des Maritimes",
                "dli": "O19391276070",
                "city": "Fredericton",
                "campus": "Maritime College of Forest Technology",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1003,
                "itemName": "Maritime Environmental Training Institute (METI)",
                "dli": "O112783108317",
                "city": "Sydney",
                "campus": "Sydney",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 1004,
                "itemName": "Mark Anthony Academy of Cosmetology",
                "dli": "O19314231702",
                "city": "Langley",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1005,
                "itemName": "Master’s College and Seminary",
                "dli": "O19395535645",
                "city": "Peterborough",
                "campus": "Peterborough",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 1006,
                "itemName": "Max the Mutt College of Animation, Art & Design",
                "dli": "O19305316242",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1007,
                "itemName": "McKenzie College",
                "dli": "O19391275539",
                "city": "Moncton",
                "campus": "Moncton",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 1008,
                "itemName": "McKenzie College Language Learning Centre",
                "dli": "O246774185777",
                "city": "Moncton",
                "campus": "Moncton",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 1009,
                "itemName": "McMaster Divinity College",
                "dli": "O19395535687",
                "city": "Hamilton",
                "campus": "Hamilton",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1010,
                "itemName": "McMurray Aviation",
                "dli": "O134967087712",
                "city": "Fort McMurray",
                "campus": "Fort McMurray",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 1011,
                "itemName": "Medavie HealthEd",
                "dli": "O19353075272",
                "city": "Dartmouth",
                "campus": "Dartmouth",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 1012,
                "itemName": "Medicine Hat College",
                "dli": "O18795445892",
                "city": "Brooks",
                "campus": "Brooks",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1013,
                "itemName": "Medicine Hat College",
                "dli": "O18795445892",
                "city": "Medicine Hat",
                "campus": "Medicine Hat",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1014,
                "itemName": "Mennonite Brethren Biblical Seminary",
                "dli": "O19395299306",
                "city": "Langley",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1015,
                "itemName": "Merinov (CCTT des pêches)",
                "dli": "O19359010621",
                "city": "Gaspé",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1016,
                "itemName": "Metalworks Institute of Sound & Music Production",
                "dli": "O110123589171",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1017,
                "itemName": "Metro College of Technology",
                "dli": "O260421248262",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1018,
                "itemName": "Metropolitan Community College",
                "dli": "O19329963492",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1019,
                "itemName": "Mid-Ocean School of Media Arts (Audio in Media)",
                "dli": "O118486001687",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 1020,
                "itemName": "Millar College of the Bible",
                "dli": "O110820219297",
                "city": "Pambrun",
                "offersPGWPEligible": "No",
                "province": "Saskatchewan"
            },
            {
                "id": 1021,
                "itemName": "Millennium Aviation",
                "dli": "O248070508887",
                "city": "Saskatoon",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Saskatchewan"
            },
            {
                "id": 1022,
                "itemName": "Mississauga Career College",
                "dli": "O262025367662",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1023,
                "itemName": "Mitchinson Flying Service",
                "dli": "O212534589967",
                "city": "Saskatoon",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Saskatchewan"
            },
            {
                "id": 1024,
                "itemName": "Mohawk College",
                "dli": "O19376045902",
                "city": "Brantford",
                "campus": "Mohawk Laurier",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1025,
                "itemName": "Mohawk College",
                "dli": "O19376045902",
                "city": "Stoney Creek",
                "campus": "Stoney Creek  (Skilled Trades and Apprenticeship Research, Resources and Training Institute)",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1026,
                "itemName": "Mohawk College",
                "dli": "O19376045902",
                "city": "Hamilton",
                "campus": "Institute for Applied Health Sciences",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1027,
                "itemName": "Mohawk College",
                "dli": "O19376045902",
                "city": "Hamilton",
                "campus": "Fennell",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1028,
                "itemName": "Moncton Flight College",
                "dli": "O19274437002",
                "city": "Dieppe",
                "campus": "Moncton",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "New Brunswick"
            },
            {
                "id": 1029,
                "itemName": "Moncton Flight College",
                "dli": "O19274437002",
                "city": "Fredericton",
                "campus": "Fredericton",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "New Brunswick"
            },
            {
                "id": 1030,
                "itemName": "Montair",
                "dli": "O113462258677",
                "city": "Pitt Meadows",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 1031,
                "itemName": "Montair Aviation",
                "dli": "O245553175217",
                "city": "Springbrook",
                "campus": "Springbrook",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 1032,
                "itemName": "Montair Aviation",
                "dli": "O245553175217",
                "city": "Pitt Meadows",
                "campus": "Pitt Meadows",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 1033,
                "itemName": "Montessori Training Centre of British Columbia",
                "dli": "O19301054682",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1034,
                "itemName": "Montreal College of Information Technology",
                "dli": "O19338447682",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1035,
                "itemName": "Moonlight International Academy",
                "dli": "O19220127182",
                "city": "Little Sands",
                "campus": "Little Sands",
                "offersPGWPEligible": "No",
                "province": "Prince Edward Island"
            },
            {
                "id": 1036,
                "itemName": "Mount Saint Joseph Nursing Home",
                "dli": "O143065902232",
                "city": "Miramichi",
                "campus": "Miramichi",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 1037,
                "itemName": "Mountain Transport Institute Ltd. Castlegar",
                "dli": "O19395299350",
                "city": "Castlegar",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1038,
                "itemName": "Musitechnic Formation",
                "dli": "O19338447415",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1039,
                "itemName": "MÉCANIUM inc.",
                "dli": "O19359010741",
                "city": "Saint-Georges",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1040,
                "itemName": "NAIT (Northern Alberta Institute of Technology)",
                "dli": "O18713200642",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1041,
                "itemName": "NAIT (Northern Alberta Institute of Technology)",
                "dli": "O18713200642",
                "city": "Edmonton",
                "campus": "Patricia",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1042,
                "itemName": "NAIT (Northern Alberta Institute of Technology)",
                "dli": "O18713200642",
                "city": "Edmonton",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1043,
                "itemName": "NAIT (Northern Alberta Institute of Technology)",
                "dli": "O18713200642",
                "city": "Edmonton",
                "campus": "Souch",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1044,
                "itemName": "NAIT (Northern Alberta Institute of Technology)",
                "dli": "O18713200642",
                "city": "St. Albert",
                "campus": "St. Albert",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1045,
                "itemName": "NAV Canada",
                "dli": "O254795577477",
                "city": "Cornwall",
                "campus": "Cornwall (NAV Centre Training and Conference Centre)",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1046,
                "itemName": "NIWE Academy Inc.",
                "dli": "O124154604587",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1047,
                "itemName": "National Academy of Health & Business",
                "dli": "O19711641837",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1048,
                "itemName": "National Academy of Health & Business",
                "dli": "O19711641837",
                "city": "Hamilton",
                "campus": "Hamilton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1049,
                "itemName": "National Academy of Health & Business",
                "dli": "O19711641837",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1050,
                "itemName": "National Helicopters Inc",
                "dli": "O260020165732",
                "city": "Kleinburg",
                "campus": "Kleinburg",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1051,
                "itemName": "National Institute of Hairstyling and Advanced Aesthetics",
                "dli": "O19394924959",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1052,
                "itemName": "Ner Israel Joseph (J.T.) Tanenbaum Yeshivah College of Toronto",
                "dli": "O116453040792",
                "city": "Thornhill",
                "campus": "Thornhill",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 1053,
                "itemName": "NetPlus College of Information Technology",
                "dli": "O19303303802",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1054,
                "itemName": "Netsoft College of Technology",
                "dli": "O255347057587",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1055,
                "itemName": "New Brunswick Bible Institute Inc.",
                "dli": "O111394075331",
                "city": "Victoria Corner",
                "campus": "Victoria Corner",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1056,
                "itemName": "New Brunswick College of Craft and Design",
                "dli": "O19391556333",
                "city": "Fredericton",
                "campus": "Fredericton",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1057,
                "itemName": "New Brunswick Community College (NBCC)",
                "dli": "O19391556439",
                "city": "Fredericton",
                "campus": "NBCC - Fredericton",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1058,
                "itemName": "New Brunswick Community College (NBCC)",
                "dli": "O19391556439",
                "city": "Miramichi",
                "campus": "NBCC - Miramichi",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1059,
                "itemName": "New Brunswick Community College (NBCC)",
                "dli": "O19391556439",
                "city": "Moncton",
                "campus": "NBCC - Moncton",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1060,
                "itemName": "New Brunswick Community College (NBCC)",
                "dli": "O19391556439",
                "city": "Saint John",
                "campus": "NBCC – Saint John",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1061,
                "itemName": "New Brunswick Community College (NBCC)",
                "dli": "O19391556439",
                "city": "St. Andrews",
                "campus": "NBCC - St. Andrews",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1062,
                "itemName": "New Brunswick Community College (NBCC)",
                "dli": "O19391556439",
                "city": "Woodstock",
                "campus": "NBCC - Woodstock",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 1063,
                "itemName": "New Image College",
                "dli": "O19274696442",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1064,
                "itemName": "New Link College",
                "dli": "O19280343742",
                "city": "Burnaby, Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1065,
                "itemName": "New York Institute of Technology",
                "dli": "O19280365272",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 1066,
                "itemName": "NewCastle College of Business and Technology",
                "dli": "O139903401892",
                "city": "Brantford",
                "campus": "Brantford",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1067,
                "itemName": "Newman Theological College",
                "dli": "O19391057177",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 1068,
                "itemName": "Niagara College Canada",
                "dli": "O19396019469",
                "city": "Virgil",
                "campus": "Virgil",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1069,
                "itemName": "Niagara College Canada",
                "dli": "O19396019469",
                "city": "St. Catharines",
                "campus": "St. Catharines",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1070,
                "itemName": "Niagara College Canada",
                "dli": "O19396019469",
                "city": "Niagara-On-The-Lake",
                "campus": "Niagara-On-The-Lake",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1071,
                "itemName": "Niagara College Canada",
                "dli": "O19396019469",
                "city": "Welland",
                "campus": "Welland",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1072,
                "itemName": "Nicola Valley Institute of Technology",
                "dli": "O19395299432",
                "city": "Burnaby, Merritt",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 1073,
                "itemName": "Nimbus School of Recording & Media",
                "dli": "O19283933722",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1074,
                "itemName": "Nipawin Bible College",
                "dli": "O117830380977",
                "city": "Nipawin",
                "offersPGWPEligible": "No",
                "province": "Saskatchewan"
            },
            {
                "id": 1075,
                "itemName": "NorQuest College",
                "dli": "O18795996622",
                "city": "Drayton Valley",
                "campus": "Drayton Valley",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1076,
                "itemName": "NorQuest College",
                "dli": "O18795996622",
                "city": "Edmonton",
                "campus": "Westmount",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1077,
                "itemName": "NorQuest College",
                "dli": "O18795996622",
                "city": "Edmonton",
                "campus": "Edmonton Downtown",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1078,
                "itemName": "NorQuest College",
                "dli": "O18795996622",
                "city": "Stony Plain",
                "campus": "Stony Plain",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1079,
                "itemName": "NorQuest College",
                "dli": "O18795996622",
                "city": "Westlock",
                "campus": "Westlock",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1080,
                "itemName": "NorQuest College",
                "dli": "O18795996622",
                "city": "Wetaskiwin",
                "campus": "Wetaskiwin",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1081,
                "itemName": "NorQuest College",
                "dli": "O18795996622",
                "city": "Whitecourt",
                "campus": "Whitecourt",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1082,
                "itemName": "North American Business College of Sciences & Language",
                "dli": "O138196078692",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1083,
                "itemName": "North American College of Information Technology",
                "dli": "O135829985902",
                "city": "Toronto",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1084,
                "itemName": "North American College of Information Technology",
                "dli": "O135829985902",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1085,
                "itemName": "North American College of Pharmaceutical Technology",
                "dli": "O19358993982",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1086,
                "itemName": "North American School of Information Technology Inc. (NASIT)",
                "dli": "O116453040879",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1087,
                "itemName": "North Island College",
                "dli": "O19301182602",
                "city": "Campbell River, Courtenay, Port Alberni, Port Hardy, Ucluelet",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 1088,
                "itemName": "Northeast Christian College",
                "dli": "O19352105132",
                "city": "Fredericton",
                "campus": "Fredericton",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 1089,
                "itemName": "Northern College",
                "dli": "O19315830082",
                "city": "Kirkland Lake",
                "campus": "Kirkland Lake",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1090,
                "itemName": "Northern College",
                "dli": "O19315830082",
                "city": "South Porcupine",
                "campus": "Timmins",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1091,
                "itemName": "Northern College",
                "dli": "O19315830082",
                "city": "Temiskaming Shores",
                "campus": "Haileybury",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1092,
                "itemName": "Northern College",
                "dli": "O19315830082",
                "city": "Scarborough",
                "campus": "Northern College at Pures College of Technology",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1093,
                "itemName": "Northern Lakes College",
                "dli": "O19391056848",
                "city": "Grouard",
                "campus": "Grouard",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1094,
                "itemName": "Northern Lakes College",
                "dli": "O19391056848",
                "city": "Slave Lake",
                "campus": "Slave Lake Main",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1095,
                "itemName": "Northern Lakes College",
                "dli": "O19391056848",
                "city": "Athabasca",
                "campus": "Athabasca",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1096,
                "itemName": "Northern Lakes College",
                "dli": "O19391056848",
                "city": "Fort Vermillion",
                "campus": "Fort Vermillion",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1097,
                "itemName": "Northern Lakes College",
                "dli": "O19391056848",
                "city": "High Level",
                "campus": "High Level",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1098,
                "itemName": "Northern Lakes College",
                "dli": "O19391056848",
                "city": "High Prairie",
                "campus": "High Prairie",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1099,
                "itemName": "Northern Lakes College",
                "dli": "O19391056848",
                "city": "Grande Prairie",
                "campus": "Grande Prairie",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1100,
                "itemName": "Northern Lakes College",
                "dli": "O19391056848",
                "city": "Peace River",
                "campus": "Peace River",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1101,
                "itemName": "Northern Lakes College",
                "dli": "O19391056848",
                "city": "Valleyview",
                "campus": "Valleyview",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1102,
                "itemName": "Northern Lakes College",
                "dli": "O19391056848",
                "city": "Wabasca",
                "campus": "Wabasca",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1103,
                "itemName": "Northern Lights College",
                "dli": "O19395299444",
                "city": "Chetwynd, Dawson Creek, Fort Nelson, Fort St John, Tumbler Ridge",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 1104,
                "itemName": "Northwest Baptist Seminary",
                "dli": "O19330361712",
                "city": "Langley",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1105,
                "itemName": "Northwest Culinary Academy of Vancouver",
                "dli": "O19395299466",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1106,
                "itemName": "Nova Scotia College of Early Childhood Education",
                "dli": "O19353105672",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 1107,
                "itemName": "Nova Scotia Community College",
                "dli": "O19091820452",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 1108,
                "itemName": "Nova Scotia Fire Fighters School",
                "dli": "O19391557060",
                "city": "Waverley",
                "campus": "Waverley",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 1109,
                "itemName": "OHC Calgary",
                "dli": "O18779890482",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1110,
                "itemName": "OHC Vancouver",
                "dli": "O19394452186",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1111,
                "itemName": "OIEG Oxford International Education Group",
                "dli": "O19376112172",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1112,
                "itemName": "OIEG Oxford International Education Group/Eurocentres Canada",
                "dli": "O19275179522",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1113,
                "itemName": "Okanagan College",
                "dli": "O19395299488",
                "city": "Kelowna, Oliver, Penticton, Revelstoke, Salmon Arm, Summerland, Vernon",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 1114,
                "itemName": "Okanagan Mountain Helicopters FTU Ltd.",
                "dli": "O138327915822",
                "city": "Kelowna",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 1115,
                "itemName": "Okanagan Valley College of Massage Therapy Ltd.",
                "dli": "O247767381127",
                "city": "Vernon",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1116,
                "itemName": "Olds College",
                "dli": "O18713227822",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1117,
                "itemName": "Olds College",
                "dli": "O18713227822",
                "city": "Olds",
                "campus": "Olds",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1118,
                "itemName": "Oleotek inc.",
                "dli": "O19359010754",
                "city": "Thetford Mines",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1119,
                "itemName": "Omni College",
                "dli": "O19347439002",
                "city": "Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1120,
                "itemName": "Ontario College of Business and Law",
                "dli": "O144539947072",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1121,
                "itemName": "Ontario College of Health and Technology",
                "dli": "O260174251212",
                "city": "Stoney Creek",
                "campus": "Stoney Creek",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1122,
                "itemName": "Ontario College of Technology",
                "dli": "O212126071591",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1123,
                "itemName": "Ontario College of Traditional Chinese Medicine",
                "dli": "O120656139497",
                "city": "Markham",
                "campus": "Markham",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1124,
                "itemName": "Ontario College of Traditional Chinese Medicine",
                "dli": "O120656139497",
                "city": "Markham",
                "campus": "Markham Clinic",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1125,
                "itemName": "Ontario Institute of Audio Recording Technology",
                "dli": "O131302443977",
                "city": "London",
                "campus": "London",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1126,
                "itemName": "Ontario Truck Driving School",
                "dli": "O128678374377",
                "city": "London",
                "campus": "London",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1127,
                "itemName": "Ontario Truck Driving School",
                "dli": "O128678374377",
                "city": "Niagara-on-the-Lake",
                "campus": "Niagara-on-the-Lake",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1128,
                "itemName": "Orane International College",
                "dli": "O259056158042",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1129,
                "itemName": "Orca Institute",
                "dli": "O19394451545",
                "city": "New Westminster, Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1130,
                "itemName": "Ordre des comptables professionnels agréés",
                "dli": "O112575462931",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1131,
                "itemName": "Oscar International College",
                "dli": "O138917878102",
                "city": "Abbotsford, Surrey, Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1132,
                "itemName": "Oshio College of Acupuncture and Herbology",
                "dli": "O19329994642",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1133,
                "itemName": "Ottawa School of Art / École d’art d’Ottawa",
                "dli": "O19711214477",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1134,
                "itemName": "Ottawa School of Beauty Limited",
                "dli": "O117986383607",
                "city": "Ottawa",
                "campus": "Ottawa Academy",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1135,
                "itemName": "Ottawa School of Beauty Limited",
                "dli": "O117986383607",
                "city": "Ottawa",
                "campus": "West End Academy",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1136,
                "itemName": "Ottawa School of Beauty Limited",
                "dli": "O117986383607",
                "city": "London",
                "campus": "Beauty Academy London",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1137,
                "itemName": "Oulton College",
                "dli": "O19391275785",
                "city": "Moncton",
                "campus": "Moncton",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 1138,
                "itemName": "Our Lady Seat of Wisdom College",
                "dli": "O19331501342",
                "city": "Barry’s Bay",
                "campus": "Barry’s Bay",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 1139,
                "itemName": "Oxford College of Arts, Business & Technology Inc.",
                "dli": "O19375414252",
                "city": "Barrie",
                "campus": "Barrie",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1140,
                "itemName": "Oxford College of Arts, Business and Technology Inc.",
                "dli": "O19375414252",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1141,
                "itemName": "Oxford College of Arts, Business and Technology Inc.",
                "dli": "O19375414252",
                "city": "Scarborough",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1142,
                "itemName": "Oxford College of Arts, Business and Technology Inc.",
                "dli": "O19375414252",
                "city": "Scarborough",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1143,
                "itemName": "Oxford College of Arts, Business and Technology Inc.",
                "dli": "O19375414252",
                "city": "Scarborough",
                "campus": "Peterborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1144,
                "itemName": "Oxford College of Arts, Business and Technology Inc.",
                "dli": "O19375414252",
                "city": "Scarborough",
                "campus": "Burlington",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1145,
                "itemName": "Pacific Coast Community College",
                "dli": "O19274781992",
                "city": "New Westminster",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1146,
                "itemName": "Pacific DanceArts",
                "dli": "O19395639631",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1147,
                "itemName": "Pacific Design Academy",
                "dli": "O19394451567",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1148,
                "itemName": "Pacific Flying Club",
                "dli": "O19274466542",
                "city": "Delta",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 1149,
                "itemName": "Pacific Institute of Culinary Arts",
                "dli": "O19238828022",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1150,
                "itemName": "Pacific Life Bible College",
                "dli": "O19394451600",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1151,
                "itemName": "Pacific Link College",
                "dli": "O19394451662",
                "city": "Vancouver,\n Burnaby, Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1152,
                "itemName": "Pacific Rim Aviation Academy Inc.",
                "dli": "O116542595727",
                "city": "Pitt Meadows",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 1153,
                "itemName": "Pacific Rim College",
                "dli": "O19304067532",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1154,
                "itemName": "Pacific Rim Early Childhood Institute Inc.",
                "dli": "O19394451698",
                "city": "Saanichton",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1155,
                "itemName": "Parkland College",
                "dli": "O19425521569",
                "city": "Yorkton",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 1156,
                "itemName": "Passport Hélico",
                "dli": "O128269263186",
                "city": "Mascouche",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1157,
                "itemName": "Peak Healthcare Private College",
                "dli": "O259226180682",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1158,
                "itemName": "Pearson College UWC",
                "dli": "O19395639737",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1159,
                "itemName": "Petrel College of Technology",
                "dli": "O246148742007",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1160,
                "itemName": "Pharma-Medical Science College of Canada",
                "dli": "O19283879782",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1161,
                "itemName": "Pixel Blue College Inc",
                "dli": "O114625611087",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1162,
                "itemName": "Portage College",
                "dli": "O111010246767",
                "city": "Lac La Biche",
                "campus": "Lac La Biche",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1163,
                "itemName": "Prairie Bible Institute",
                "dli": "O19273724652",
                "city": "Three Hills",
                "campus": "Three Hills",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 1164,
                "itemName": "Principal Air",
                "dli": "O246621852107",
                "city": "Abbotsford",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 1165,
                "itemName": "Professional Flight Centre",
                "dli": "O19238920192",
                "city": "Delta",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 1166,
                "itemName": "Professional Institute of Massage Therapy",
                "dli": "O213391076817",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1167,
                "itemName": "Progressive Training College of Business and Health",
                "dli": "O136148136712",
                "city": "Toronto",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1168,
                "itemName": "Progressive Training College of Business and Health",
                "dli": "O136148136712",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1169,
                "itemName": "Protégé School",
                "dli": "O19332956402",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1170,
                "itemName": "Purely Inspired Academy",
                "dli": "O142244492352",
                "city": "Lethbridge",
                "campus": "Lethbridge",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1171,
                "itemName": "Pures College of Technology",
                "dli": "O19305393392",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1172,
                "itemName": "Pures College of Technology",
                "dli": "O19305393392",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1173,
                "itemName": "Q College",
                "dli": "O125645169227",
                "city": "Vancouver, Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1174,
                "itemName": "QCom College of Technology",
                "dli": "O263592785432",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1175,
                "itemName": "Quantum Academy",
                "dli": "O19395639759",
                "city": "Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1176,
                "itemName": "Queen's Language School",
                "dli": "O255779881147",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1177,
                "itemName": "Queen’s College",
                "dli": "O19441168228",
                "city": "St. John’s",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 1178,
                "itemName": "Queen’s College of Business, Technology & Public Safety",
                "dli": "O119539592517",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1179,
                "itemName": "Quest Language Studies",
                "dli": "O19375993682",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1180,
                "itemName": "RAC Digital Arts College, Recording Arts Canada",
                "dli": "O19304530972",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1181,
                "itemName": "Randolph College for the Performing Arts",
                "dli": "O19527236002",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1182,
                "itemName": "Ready Arc Training and Testing",
                "dli": "O138505979102",
                "city": "Saint John",
                "campus": "Saint John",
                "offersPGWPEligible": "No",
                "province": "New Brunswick"
            },
            {
                "id": 1183,
                "itemName": "Red Cliff College of Applied Technology and Management",
                "dli": "O137690837282",
                "city": "Charlottetown",
                "campus": "Charlottetown",
                "offersPGWPEligible": "No",
                "province": "Prince Edward Island"
            },
            {
                "id": 1184,
                "itemName": "Red Deer College",
                "dli": "O19391056880",
                "city": "Red Deer",
                "campus": "Red Deer",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1185,
                "itemName": "Red River College (Red River College of Applied Arts, Science and Technology)",
                "dli": "O19305836302",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "Yes",
                "province": "Manitoba"
            },
            {
                "id": 1186,
                "itemName": "Reeves College",
                "dli": "O140845267032",
                "city": "Calgary",
                "campus": "Calgary North",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1187,
                "itemName": "Reeves College",
                "dli": "O140845267032",
                "city": "Calgary",
                "campus": "Calgary city Centre",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1188,
                "itemName": "Reeves College",
                "dli": "O140845267032",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1189,
                "itemName": "Reeves College",
                "dli": "O140845267032",
                "city": "Lethbridge",
                "campus": "Lethbridge",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1190,
                "itemName": "Reeves College",
                "dli": "O140845267032",
                "city": "Lloydminster",
                "campus": "Lloydminster",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1191,
                "itemName": "Regent College",
                "dli": "O19283912352",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1192,
                "itemName": "Regina Flying Club",
                "dli": "O119370011347",
                "city": "Regina",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Saskatchewan"
            },
            {
                "id": 1193,
                "itemName": "Regis College",
                "dli": "O19358972972",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1194,
                "itemName": "Rhodes Wellness College Ltd.",
                "dli": "O19273650972",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1195,
                "itemName": "Richmond International Academy",
                "dli": "O142586176482",
                "city": "Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1196,
                "itemName": "Robertson College",
                "dli": "O143319448932",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1197,
                "itemName": "Robertson College",
                "dli": "O143319448932",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1198,
                "itemName": "Robertson College (Accounting and Payroll Administrator, Logistics and Supply Chain Management, Massage Therapy, Medical Laboratory Assistant, Pharmacy Technician)",
                "dli": "O123079633257",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 1199,
                "itemName": "Robertson College (Massage Therapy)",
                "dli": "O123079633257",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 1200,
                "itemName": "Robertson College (Medical Laboratory Assistant)",
                "dli": "O123079633257",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 1201,
                "itemName": "Robertson College (Pharmacy Technician)",
                "dli": "O123079633257",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 1202,
                "itemName": "Rocky Mountain College",
                "dli": "O19257272712",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 1203,
                "itemName": "Rosewood College",
                "dli": "O141551857572",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1204,
                "itemName": "Royal Bridge College",
                "dli": "O142488561162",
                "city": "Coquitlam",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1205,
                "itemName": "Royal Canadian College of Massage Therapy Inc.",
                "dli": "O247790815707",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1206,
                "itemName": "Royal Canadian Institute of International Studies",
                "dli": "O19440339342",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1207,
                "itemName": "Royal Crown College of Business and Technology",
                "dli": "O242531216037",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1208,
                "itemName": "Royal Metro College",
                "dli": "O19361238482",
                "city": "Coquitlam",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1209,
                "itemName": "Royal Military College Saint-Jean",
                "dli": "O19993116991",
                "city": "Saint-Jean-sur-Richelieu",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1210,
                "itemName": "Royal Military College of Canada",
                "dli": "O19993856974",
                "city": "Kingston",
                "campus": "Kingston",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1211,
                "itemName": "Royal Winnipeg Ballet School",
                "dli": "O211919169393",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 1212,
                "itemName": "Rubika, école supérieure de création numérique appliquée à l’animation, au design et au jeu vidéo inc.",
                "dli": "O254893616833",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1213,
                "itemName": "Rudolf Steiner Centre Toronto",
                "dli": "O255214515817",
                "city": "Thornhill",
                "campus": "Thornhill",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1214,
                "itemName": "SAE Institute",
                "dli": "O19330716802",
                "city": "North Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1215,
                "itemName": "SC institute of the Arts",
                "dli": "O19394452136",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1216,
                "itemName": "SELC Career College Vancouver",
                "dli": "O19395639791",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1217,
                "itemName": "SELC English Language Centre Canada",
                "dli": "O19330751052",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1218,
                "itemName": "Saint Elizabeth Health Career College",
                "dli": "O139595339102",
                "city": "Markham",
                "campus": "Markham",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1219,
                "itemName": "Saskatchewan Polytechnic",
                "dli": "O19425521849",
                "city": "Moose Jaw",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 1220,
                "itemName": "Saskatchewan Polytechnic",
                "dli": "O19425521849",
                "city": "Prince Albert",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 1221,
                "itemName": "Saskatchewan Polytechnic",
                "dli": "O19425521849",
                "city": "Regina",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 1222,
                "itemName": "Saskatchewan Polytechnic",
                "dli": "O19425521849",
                "city": "Saskatoon",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 1223,
                "itemName": "Saskatoon Business College",
                "dli": "O19425521728",
                "city": "Saskatoon",
                "offersPGWPEligible": "No",
                "province": "Saskatchewan"
            },
            {
                "id": 1224,
                "itemName": "Saskatoon Health Region Orthoptic Program",
                "dli": "O115945875827",
                "city": "Saskatoon",
                "offersPGWPEligible": "No",
                "province": "Saskatchewan"
            },
            {
                "id": 1225,
                "itemName": "Sault College",
                "dli": "O19395677683",
                "city": "Sault Ste. Marie",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1226,
                "itemName": "Sault College",
                "dli": "O19395677683",
                "city": "Sudbury",
                "campus": "Collège Boréal d’arts appliqués et de technologie (Collaborative)",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1227,
                "itemName": "Sault College",
                "dli": "O19395677683",
                "city": "Toronto",
                "campus": "Collège Boréal d’arts appliqués et de technologie (Collaborative)",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1228,
                "itemName": "Sault College of Applied Arts and Technology at Trios College",
                "dli": "O264224031432",
                "city": "Brampton",
                "campus": "Trios College - Brampton",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1229,
                "itemName": "School of Contemporary Dancers",
                "dli": "O114867912617",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 1230,
                "itemName": "School of Ministers (Southland Church)",
                "dli": "O125192431487",
                "city": "Steinbach",
                "campus": "Steinbach",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 1231,
                "itemName": "School of Photographic Arts Ottawa",
                "dli": "O264423058712",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1232,
                "itemName": "Sea Land Air Flight Centre",
                "dli": "O19394452152",
                "city": "Delta, Richmond",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 1233,
                "itemName": "Select Aviation",
                "dli": "O127380461633",
                "city": "Drummondville",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1234,
                "itemName": "Selkirk College",
                "dli": "O19360990072",
                "city": "Castlegar, Grand Forks, Kaslo Centre, Nakusp, Nelson, Trail",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 1235,
                "itemName": "Seminary of Christ the King",
                "dli": "O19329910782",
                "city": "Mission",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1236,
                "itemName": "Seneca College",
                "dli": "O19395536013",
                "city": "Markham",
                "campus": "Markham",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1237,
                "itemName": "Seneca College",
                "dli": "O19395536013",
                "city": "Peterborough",
                "campus": "Peterborough",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1238,
                "itemName": "Seneca College",
                "dli": "O19395536013",
                "city": "King city",
                "campus": "King",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1239,
                "itemName": "Seneca College",
                "dli": "O19395536013",
                "city": "Toronto",
                "campus": "Jane",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1240,
                "itemName": "Seneca College",
                "dli": "O19395536013",
                "city": "Toronto",
                "campus": "Newnham",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1241,
                "itemName": "Seneca College",
                "dli": "O19395536013",
                "city": "Toronto",
                "campus": "Seneca @ York",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1242,
                "itemName": "Seneca College",
                "dli": "O19395536013",
                "city": "Toronto",
                "campus": "Yorkgate",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1243,
                "itemName": "Senniyo Aesthetics International School of Canada Inc.",
                "dli": "O19394452174",
                "city": "Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1244,
                "itemName": "Sheridan College",
                "dli": "O19385946782",
                "city": "Mississauga",
                "campus": "Hazel McCallion",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1245,
                "itemName": "Sheridan College",
                "dli": "O19385946782",
                "city": "Brampton",
                "campus": "Davis",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1246,
                "itemName": "Sheridan College",
                "dli": "O19385946782",
                "city": "Oakville",
                "campus": "Oakville - Trafalgar",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1247,
                "itemName": "Shiatsu School of Canada Inc",
                "dli": "O19396261561",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1248,
                "itemName": "Six Nations Polytechnic",
                "dli": "O19395164991",
                "city": "Ohsweken",
                "campus": "Ohsweken",
                "offersPGWPEligible": "Yes (details for Six Nations Polytechnic)"
            },
            {
                "id": 1249,
                "itemName": "Sky Wings Aviation Academy",
                "dli": "O110116403397",
                "city": "Penhold",
                "campus": "Penhold",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 1250,
                "itemName": "SkyQuest Aviation Ltd.",
                "dli": "O19396094731",
                "city": "Langley",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 1251,
                "itemName": "Sol Schools International",
                "dli": "O19517393412",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1252,
                "itemName": "Solomon College",
                "dli": "O110120953397",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 1253,
                "itemName": "Solutions Novika",
                "dli": "O19359010517",
                "city": "La Pocatière",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1254,
                "itemName": "Southern Alberta Institute of Technology (SAIT Polytechnic)",
                "dli": "O18761749692",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 1255,
                "itemName": "Southern Ontario Dental College",
                "dli": "O110123916630",
                "city": "Ancaster",
                "campus": "Ancaster",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1256,
                "itemName": "Spectrum Airways",
                "dli": "O117793705737",
                "city": "Burlington",
                "campus": "Burlington",
                "offersPGWPEligible": "Yes (details for Flight schools)"
            },
            {
                "id": 1257,
                "itemName": "Springbank Air Training College",
                "dli": "O19257198622",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 1258,
                "itemName": "Sprott Shaw College",
                "dli": "O19278865162",
                "city": "Abbotsford, Burnaby, Chilliwack, Kamloops, Kelowna, Maple Ridge, Nanaimo, New Westminster, Osoyoos, Penticton, Richmond, Surrey, Vancouver, Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1259,
                "itemName": "Sprott Shaw Language College",
                "dli": "O19283917732",
                "city": "Surrey, Vancouver, Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1260,
                "itemName": "Sprott Shaw Language College",
                "dli": "O19283917732",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1261,
                "itemName": "Sprott Shaw Language College (Ontario) Corp.",
                "dli": "O19395083755",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1262,
                "itemName": "St. Andrew’s College",
                "dli": "O212421811707",
                "city": "Saskatoon",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 1263,
                "itemName": "St. Andrew’s College (St. Andrew’s College in Winnipeg)",
                "dli": "O110513623367",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 1264,
                "itemName": "St. Augustine’s Seminary of Toronto",
                "dli": "O19361100382",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1265,
                "itemName": "St. Augustine’s Seminary of Toronto",
                "dli": "O19361100382",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1266,
                "itemName": "St. Clair College",
                "dli": "O19395083703",
                "city": "Chatham",
                "campus": "Thames",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1267,
                "itemName": "St. Clair College",
                "dli": "O19395083703",
                "city": "Windsor",
                "campus": "Windsor",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1268,
                "itemName": "St. Clair College",
                "dli": "O19395083703",
                "city": "Windsor",
                "campus": "St. Clair College Centre for the Arts",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1269,
                "itemName": "St. Clair College at Ace Acumen Academy",
                "dli": "O19395083703",
                "city": "Toronto",
                "campus": "St. Clair College at ACE Acumen Academy - Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1270,
                "itemName": "St. Clair College at Ace Acumen Academy",
                "dli": "O19395083703",
                "city": "Mississauga",
                "campus": "St. Clair College at ACE Acumen Academy - Mississauga",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1271,
                "itemName": "St. George International College",
                "dli": "O19352161652",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1272,
                "itemName": "St. George International College",
                "dli": "O212206178197",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1273,
                "itemName": "St. Giles Vancouver",
                "dli": "O19395299510",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1274,
                "itemName": "St. Lawrence College",
                "dli": "O19332845222",
                "city": "Brockville",
                "campus": "Brockville",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1275,
                "itemName": "St. Lawrence College",
                "dli": "O19332845222",
                "city": "Cornwall",
                "campus": "Cornwall",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1276,
                "itemName": "St. Lawrence College",
                "dli": "O19332845222",
                "city": "Kingston",
                "campus": "Kingston",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1277,
                "itemName": "St. Lawrence College",
                "dli": "O19332845222",
                "city": "Toronto",
                "campus": "St. Lawrence College at Alpha International Academy (Collaborative)",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1278,
                "itemName": "St. Mark’s College",
                "dli": "O144740585612",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1279,
                "itemName": "St. Peter’s College",
                "dli": "O115221677157",
                "city": "Muenster",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 1280,
                "itemName": "St. Philip’s Seminary / The Oratory of St. Philip Neri-Toronto",
                "dli": "O111916829547",
                "city": "Toronto",
                "campus": "King Street West",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 1281,
                "itemName": "St. Philip’s Seminary / The Oratory of St. Philip Neri-Toronto",
                "dli": "O111916829547",
                "city": "Toronto",
                "campus": "Dunn Avenue",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 1282,
                "itemName": "St. Stephen’s College (U of Alberta )",
                "dli": "O19391057115",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 1283,
                "itemName": "St. Thomas Flight Centre",
                "dli": "O117758925477",
                "city": "St.Thomas",
                "campus": "St.Thomas",
                "offersPGWPEligible": "Yes (details for Flight schools)"
            },
            {
                "id": 1284,
                "itemName": "Stafford House Calgary",
                "dli": "O18713255252",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1285,
                "itemName": "Stafford House Toronto",
                "dli": "O19516888332",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1286,
                "itemName": "Stanford International College of Business and Technology",
                "dli": "O19332546352",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1287,
                "itemName": "Stanford International College of Business and Technology",
                "dli": "O19332546352",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1288,
                "itemName": "Stanford International College of Business and Technology",
                "dli": "O19332546352",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1289,
                "itemName": "Stanford International College of Business and Technology",
                "dli": "O19332546352",
                "city": "Toronto",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1290,
                "itemName": "Steinbach Bible College (Steinbach Bible College Inc.)",
                "dli": "O19208275702",
                "city": "Steinbach",
                "campus": "Steinbach",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Manitoba"
            },
            {
                "id": 1291,
                "itemName": "Stenberg College",
                "dli": "O19283910772",
                "city": "Kamloops",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1292,
                "itemName": "Stenberg College",
                "dli": "O19283910772",
                "city": "Duncan",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1293,
                "itemName": "Stenberg College",
                "dli": "O19283910772",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1294,
                "itemName": "Stenberg College",
                "dli": "O19283910772",
                "city": "Vernon",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1295,
                "itemName": "Sterling College",
                "dli": "O19279828772",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1296,
                "itemName": "Sterling College",
                "dli": "O19279828772",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1297,
                "itemName": "Stratford Air Services Ltd.",
                "dli": "O119062742467",
                "city": "Stratford",
                "campus": "Stratford",
                "offersPGWPEligible": "Yes (details for Flight schools)"
            },
            {
                "id": 1298,
                "itemName": "Study Abroad Canada Language Institute",
                "dli": "O19225872992",
                "city": "Charlottetown",
                "campus": "Charlottetown",
                "offersPGWPEligible": "No",
                "province": "Prince Edward Island"
            },
            {
                "id": 1299,
                "itemName": "Success College of Applied Arts and Technology",
                "dli": "O19391557027",
                "city": "Lower Sackville",
                "campus": "Lower Sackville",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 1300,
                "itemName": "Summit Pacific College",
                "dli": "O19330780272",
                "city": "Abbotsford",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1301,
                "itemName": "Sundance College",
                "dli": "O142067911062",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1302,
                "itemName": "Sunview College Business Technology Healthcare",
                "dli": "O19395164201",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1303,
                "itemName": "Sunview College Business Technology Healthcare",
                "dli": "O19395164201",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1304,
                "itemName": "Sunview College Business Technology Healthcare",
                "dli": "O19395164201",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1305,
                "itemName": "Sunview College Business Technology Healthcare",
                "dli": "O19395164201",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1306,
                "itemName": "Super T Aviation Academy",
                "dli": "O110657012357",
                "city": "Medicine Hat",
                "campus": "Medicine Hat",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 1307,
                "itemName": "Syn Studio",
                "dli": "O242715034057",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1308,
                "itemName": "Synergy Flight Training Inc",
                "dli": "O120462423427",
                "city": "Spruce Grove",
                "campus": "Spruce Grove",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Alberta"
            },
            {
                "id": 1309,
                "itemName": "Séminaire de Sherbrooke",
                "dli": "O19359010218",
                "city": "Sherbrooke",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1310,
                "itemName": "TAHA Academy of Beauty, Business & Health",
                "dli": "O136467574022",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1311,
                "itemName": "TLC-International Training Centre for Caregiving Inc.",
                "dli": "O19391275439",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1312,
                "itemName": "Tamwood Careers",
                "dli": "O19361007342",
                "city": "Whistler",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1313,
                "itemName": "Tamwood Careers",
                "dli": "O19361007342",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1314,
                "itemName": "Tamwood Careers",
                "dli": "O19375949112",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1315,
                "itemName": "Tamwood International College Ltd.",
                "dli": "O19375949112",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1316,
                "itemName": "Tamwood Language Centres",
                "dli": "O19280516442",
                "city": "Vancouver, Whistler",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1317,
                "itemName": "Tamwood Language Centres",
                "dli": "O19375949112",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1318,
                "itemName": "Taylor College and Seminary",
                "dli": "O113098133037",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 1319,
                "itemName": "Taylor Pro Training",
                "dli": "O245272133587",
                "city": "Kelowna",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1320,
                "itemName": "TechnoCentre éolien Gaspésie, les Îles",
                "dli": "O19359010834",
                "city": "Gaspé",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1321,
                "itemName": "The Aesthetics and Hairstyling College of Canada",
                "dli": "O259416765842",
                "city": "Vaughan",
                "campus": "Vaughan",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1322,
                "itemName": "The Audio Recording Academy (T.A.R.A)",
                "dli": "O19369394582",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1323,
                "itemName": "The Automotive Training Centre (Ontario) Ltd.",
                "dli": "O19527255662",
                "city": "Toronto",
                "campus": "Cambridge",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1324,
                "itemName": "The Automotive Training Centre (Ontario) Ltd.",
                "dli": "O19527255662",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1325,
                "itemName": "The Create Institute",
                "dli": "O19396261319",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1326,
                "itemName": "The Cut Fashion Design Academy",
                "dli": "O142582255042",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1327,
                "itemName": "The Esthetic Institute Training Center",
                "dli": "O256565633727",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1328,
                "itemName": "The Hair Art Academy",
                "dli": "O243191415557",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1329,
                "itemName": "The Hair Art Academy",
                "dli": "O243191415557",
                "city": "Langley",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1330,
                "itemName": "The Institute of Holistic Nutrition",
                "dli": "O19395639374",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1331,
                "itemName": "The Language Gallery",
                "dli": "O129472251057",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1332,
                "itemName": "The Michener Institute for Applied Health Sciences",
                "dli": "O19395677373",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1333,
                "itemName": "The Professional Institute of Massage Therapy Ltd",
                "dli": "O117975904187",
                "city": "Saskatoon",
                "offersPGWPEligible": "No",
                "province": "Saskatchewan"
            },
            {
                "id": 1334,
                "itemName": "The Royal Alberta College",
                "dli": "O142243313422",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1335,
                "itemName": "The Royal Conservatory of Music",
                "dli": "O19396019573",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 1336,
                "itemName": "The School of Toronto Dance Theatre",
                "dli": "O110123589585",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1337,
                "itemName": "Think Tank Training Centre",
                "dli": "O19395299578",
                "city": "North Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1338,
                "itemName": "Timberline Canadian Alpine Academy",
                "dli": "O129951946077",
                "city": "Banff",
                "campus": "Banff",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1339,
                "itemName": "Torchbearers Capernwray Canada Society",
                "dli": "O19361274462",
                "city": "Thetis Island",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1340,
                "itemName": "Toronto Airways Inc.",
                "dli": "O19304162252",
                "city": "Markham",
                "campus": "Markham",
                "offersPGWPEligible": "Yes (details for Flight schools)"
            },
            {
                "id": 1341,
                "itemName": "Toronto Art Therapy Institute",
                "dli": "O19395084047",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1342,
                "itemName": "Toronto Baptist Seminary and Bible College",
                "dli": "O19332993322",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 1343,
                "itemName": "Toronto Business College",
                "dli": "O118334609637",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1344,
                "itemName": "Toronto College of Dental Hygiene and Auxiliaries Inc.",
                "dli": "O19395084089",
                "city": "Toronto",
                "campus": "Lodestar",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1345,
                "itemName": "Toronto College of Dental Hygiene and Auxiliaries Inc.",
                "dli": "O19395084089",
                "city": "Toronto",
                "campus": "Steeprock",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1346,
                "itemName": "Toronto Economical Management College",
                "dli": "O142167031052",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1347,
                "itemName": "Toronto Film School",
                "dli": "O19396019511",
                "city": "Toronto",
                "campus": "Dundas Square Main",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1348,
                "itemName": "Toronto Film School",
                "dli": "O19396019511",
                "city": "Toronto",
                "campus": "Davisville",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1349,
                "itemName": "Toronto Film School",
                "dli": "O19396019511",
                "city": "Toronto",
                "campus": "Dundas Square 2nd",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1350,
                "itemName": "Toronto Institute of Pharmaceutical Technology",
                "dli": "O19517075012",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1351,
                "itemName": "Toronto International Business College",
                "dli": "O19316512192",
                "city": "Scarborough",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1352,
                "itemName": "Toronto International Business College",
                "dli": "O19316512192",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1353,
                "itemName": "Toronto Montessori Institute",
                "dli": "O19304353722",
                "city": "Richmond Hill",
                "campus": "Richmond Hill",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1354,
                "itemName": "Toronto School of Management",
                "dli": "O131394510557",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1355,
                "itemName": "Toronto School of Traditional Chinese Medicine",
                "dli": "O244866794117",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1356,
                "itemName": "TransBIOTech Centre de recherche et de transfert en biotechnologie",
                "dli": "O19359010808",
                "city": "Lévis",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1357,
                "itemName": "Transitions College of Business & Career Studies",
                "dli": "O110096211816",
                "city": "Niagara Falls",
                "campus": "Niagara Falls",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1358,
                "itemName": "Travel College Canada",
                "dli": "O19303969142",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1359,
                "itemName": "Trebas Institute",
                "dli": "O19375919832",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1360,
                "itemName": "Trillium College",
                "dli": "O19532727322",
                "city": "Oshawa",
                "campus": "Oshawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1361,
                "itemName": "Trillium College",
                "dli": "O19532727322",
                "city": "St. Catharines",
                "campus": "St. Catharines",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1362,
                "itemName": "Trillium Esthetic & Hair Technology College",
                "dli": "O19332628162",
                "city": "Richmond Hill",
                "campus": "Richmond Hill",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1363,
                "itemName": "Tzu Chi International College of Traditional Chinese Medicine (TCICTCM)",
                "dli": "O19394941076",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1365,
                "itemName": "Providence University College and Theological Seminary",
                "dli": "O19021124362",
                "city": "Otterburne",
                "campus": "Otterburne",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Manitoba"
            },
            {
                "id": 1366,
                "itemName": "Redeemer University College",
                "dli": "O19395677559",
                "city": "Ancaster",
                "campus": "Ancaster",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 1367,
                "itemName": "Renison University College",
                "dli": "O19395281561",
                "city": "Waterloo",
                "campus": "Waterloo",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1368,
                "itemName": "Upper Madison College of Advanced Language Learning and Skill Development",
                "dli": "O19529728922",
                "city": "Toronto",
                "campus": "Advanced Learning Centre",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1369,
                "itemName": "VESTECHPRO Centre de recherche et d’innovation en habillement",
                "dli": "O19359010821",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1370,
                "itemName": "VGC International College",
                "dli": "O19386095472",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1371,
                "itemName": "VanWest College (Kelowna)",
                "dli": "O19395299852",
                "city": "Kelowna",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1372,
                "itemName": "VanWest College (Vancouver)",
                "dli": "O19283891072",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1373,
                "itemName": "Vancouver Academy of Dramatic Arts",
                "dli": "O212566030007",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1374,
                "itemName": "Vancouver Academy of Music",
                "dli": "O19280560262",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1375,
                "itemName": "Vancouver Acting School",
                "dli": "O19394452136",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1376,
                "itemName": "Vancouver Animation School",
                "dli": "O19283928982",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1377,
                "itemName": "Vancouver Art Therapy Institute",
                "dli": "O19275386392",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1378,
                "itemName": "Vancouver Beijing College of Chinese Medicine",
                "dli": "O19280573292",
                "city": "Richmond",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1379,
                "itemName": "Vancouver Career College (Burnaby) Inc.",
                "dli": "O19395299664",
                "city": "Abbotsford, Burnaby, Chilliwack, Coquitlam, Kelowna, Richmond, Surrey, Vancouver, Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1380,
                "itemName": "Vancouver College of Counsellor Training",
                "dli": "O111991452477",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1381,
                "itemName": "Vancouver College of Dental Hygiene Inc.",
                "dli": "O19301369352",
                "city": "New Westminster",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1382,
                "itemName": "Vancouver College of Massage Therapy",
                "dli": "O212534420437",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1383,
                "itemName": "Vancouver Community College",
                "dli": "O19279717512",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 1384,
                "itemName": "Vancouver Film School",
                "dli": "O19239015912",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1385,
                "itemName": "Vancouver Institute of Media Arts (VanArts)",
                "dli": "O19331363352",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1386,
                "itemName": "Vancouver International College",
                "dli": "O19283898312",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1387,
                "itemName": "Vancouver Island College",
                "dli": "O264603551792",
                "city": "Nanaimo",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1388,
                "itemName": "Vancouver Island School of Art",
                "dli": "O111345278377",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1389,
                "itemName": "Vancouver Premier College of Arts and Science",
                "dli": "O19274398652",
                "city": "Burnaby",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1390,
                "itemName": "Vancouver Premier College of Business and Management Ltd",
                "dli": "O143533237562",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1391,
                "itemName": "Vancouver Premier College of Hotel Management",
                "dli": "O19280589592",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1392,
                "itemName": "Vancouver School of Healing Arts",
                "dli": "O119520583367",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1393,
                "itemName": "Vancouver School of Theology",
                "dli": "O19407411913",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1394,
                "itemName": "Vanguard College",
                "dli": "O19283889212",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 1395,
                "itemName": "Vanier College",
                "dli": "O19359201897",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1396,
                "itemName": "Victoria Academy of Ballet",
                "dli": "O19347351442",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1397,
                "itemName": "Victoria Academy of Dramatic Arts",
                "dli": "O246912351437",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1398,
                "itemName": "Victoria College of Art",
                "dli": "O19395299874",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1399,
                "itemName": "Victoria Conservatory of Music",
                "dli": "O122562157147",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1400,
                "itemName": "Victoria Flying Club",
                "dli": "O19283898542",
                "city": "Victoria",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 1401,
                "itemName": "Wales Young Institute",
                "dli": "O19305771772",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1402,
                "itemName": "Waterloo Wellington Flight Centre",
                "dli": "O19333179582",
                "city": "Breslau",
                "campus": "Breslau",
                "offersPGWPEligible": "Yes (details for Flight schools)"
            },
            {
                "id": 1403,
                "itemName": "Wellsprings College of Massage Therapy & Esthetics",
                "dli": "O260546051152",
                "city": "Richmond Hill",
                "campus": "Richmond Hill",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1404,
                "itemName": "West Coast College of Massage Therapy",
                "dli": "O19395299928",
                "city": "New Westminster",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1405,
                "itemName": "West Coast College of Massage Therapy",
                "dli": "O19395299928",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1406,
                "itemName": "West Coast Institute for Studies in Anthroposophy",
                "dli": "O264573530022",
                "city": "Sechelt",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1407,
                "itemName": "Westcoast Adventure College",
                "dli": "O19361275312",
                "city": "Sooke",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1408,
                "itemName": "Western Community College",
                "dli": "O19396094295",
                "city": "Abbotsford",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1409,
                "itemName": "Western Community College",
                "dli": "O19396094295",
                "city": "Surrey",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1410,
                "itemName": "Western Maritime Institute",
                "dli": "O19395299940",
                "city": "Ladysmith",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1411,
                "itemName": "Western Maritime Institute",
                "dli": "O19395299940",
                "city": "Steveston",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1412,
                "itemName": "Western Regional School of Nursing",
                "dli": "O19441148519",
                "city": "Corner Brook",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 1413,
                "itemName": "Western Town College for ESL",
                "dli": "O110984817067",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1414,
                "itemName": "Westervelt College",
                "dli": "O19516877052",
                "city": "London",
                "campus": "Brantford",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1415,
                "itemName": "Westervelt College",
                "dli": "O19516877052",
                "city": "London",
                "campus": "Kitchener",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1416,
                "itemName": "Westervelt College",
                "dli": "O19516877052",
                "city": "London",
                "campus": "London",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1417,
                "itemName": "Whistler Adventure School",
                "dli": "O19273949312",
                "city": "Whistler",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1418,
                "itemName": "Wholistic Health Training & Research Centre Ltd",
                "dli": "O121289584447",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "No",
                "province": "Alberta"
            },
            {
                "id": 1419,
                "itemName": "Willis College of Business, Technology, Healthcare",
                "dli": "O19316160382",
                "city": "Arnprior",
                "campus": "Arnprior",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1420,
                "itemName": "Willis College of Business, Technology, Healthcare",
                "dli": "O19316160382",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1421,
                "itemName": "Willis College of Business, Technology, Healthcare",
                "dli": "O19316160382",
                "city": "Smiths Falls",
                "campus": "Smiths Falls",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1422,
                "itemName": "Willowbank School of Restoration Arts",
                "dli": "O19833559557",
                "city": "Queenston",
                "campus": "Queenston",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1423,
                "itemName": "Windsong College of Healing Arts",
                "dli": "O246050646187",
                "city": "Port Alberni",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1424,
                "itemName": "Winnipeg Aviation (Winnipeg Aviation (2003) Ltd.)",
                "dli": "O19370973922",
                "city": "St. Andrews",
                "campus": "St. Andrews",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Manitoba"
            },
            {
                "id": 1425,
                "itemName": "Winnipeg Global Education College Inc.",
                "dli": "O243237116047",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "No",
                "province": "Manitoba"
            },
            {
                "id": 1426,
                "itemName": "Winston College",
                "dli": "O19280640022",
                "city": "New Westminster",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1427,
                "itemName": "Word of Life Bible Institute",
                "dli": "O132628724777",
                "city": "Owen Sound",
                "campus": "Owen Sound",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1428,
                "itemName": "World Chinese Medicine and Acupuncture College",
                "dli": "O262955887992",
                "city": "Scarborough",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1429,
                "itemName": "Wycliffe College",
                "dli": "O19572552822",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1430,
                "itemName": "York Construction Academy",
                "dli": "O136037464242",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1431,
                "itemName": "city College of Business, Health & Technology",
                "dli": "O124137834427",
                "city": "North York",
                "campus": "North York",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1432,
                "itemName": "iTTTi Vancouver",
                "dli": "O19274183992",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1433,
                "itemName": "inlingua Vancouver dba INVO Career College",
                "dli": "O19279564092",
                "city": "Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1434,
                "itemName": "inlingua Victoria College of Languages",
                "dli": "O19350928592",
                "city": "Victoria",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 1435,
                "itemName": "triOS College Business Technology Healthcare Inc.",
                "dli": "O19361101002",
                "city": "Brampton",
                "campus": "Brampton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1436,
                "itemName": "triOS College Business Technology Healthcare Inc.",
                "dli": "O19361101002",
                "city": "Hamilton",
                "campus": "Hamilton",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1437,
                "itemName": "triOS College Business Technology Healthcare Inc.",
                "dli": "O19361101002",
                "city": "Kitchener",
                "campus": "Kitchener",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1438,
                "itemName": "triOS College Business Technology Healthcare Inc.",
                "dli": "O19361101002",
                "city": "London",
                "campus": "London",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1439,
                "itemName": "triOS College Business Technology Healthcare Inc.",
                "dli": "O19361101002",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1440,
                "itemName": "triOS College Business Technology Healthcare Inc.",
                "dli": "O19361101002",
                "city": "Oshawa",
                "campus": "Oshawa",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1441,
                "itemName": "triOS College Business Technology Healthcare Inc.",
                "dli": "O19361101002",
                "city": "Windsor",
                "campus": "Windsor",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1442,
                "itemName": "triOS College Business Technology Healthcare Inc. (Scarborough)",
                "dli": "O19361101002",
                "city": "Toronto",
                "campus": "Scarborough",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1443,
                "itemName": "triOS College Business Technology Healthcare Inc. (Scarborough)",
                "dli": "O19361101002",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 1444,
                "itemName": "ÉCOBES Recherche et transfert",
                "dli": "O19359010608",
                "city": "Saguenay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1445,
                "itemName": "École Manikanetish du conseil des Montagnais de Sept-Îles et Maliotenam",
                "dli": "O246882793194",
                "city": "Sept-Îles",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1446,
                "itemName": "École Nationale du meuble et de l’ébénisterie",
                "dli": "O246882793254",
                "city": "Victoriaville",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1447,
                "itemName": "École Pivaut Montréal Inc",
                "dli": "O264360756642",
                "city": "Montreal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1448,
                "itemName": "École Polymécanique de Laval",
                "dli": "O246882793269",
                "city": "Laval",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1449,
                "itemName": "École Polytechnique de Montréal",
                "dli": "O19359011070",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1450,
                "itemName": "École de cirque de Québec",
                "dli": "O213292196501",
                "city": "Quebec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1451,
                "itemName": "École de cirque de Verdun",
                "dli": "O213292628276",
                "city": "Verdun",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1452,
                "itemName": "École de danse contemporaine de Montréal",
                "dli": "O19338447720",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1453,
                "itemName": "École de danse de Québec",
                "dli": "O19338447270",
                "city": "Quebec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1454,
                "itemName": "École de foresterie et de technologie du bois de Duchesnay",
                "dli": "O246882792619",
                "city": "Sainte-Catherine-de-la-Jacques-Cartier",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1455,
                "itemName": "École de formation professionnelle de Châteauguay (FP)",
                "dli": "O246882792634",
                "city": "Châteauguay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1456,
                "itemName": "École de management INSA",
                "dli": "O137083107422",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1457,
                "itemName": "École de musique Vincent d’Indy",
                "dli": "O19358463167",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1458,
                "itemName": "École de pilotage Saint-Hubert inc.",
                "dli": "O247982768002",
                "city": "Longueuil",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1459,
                "itemName": "École de sténographie judiciaire du Québec",
                "dli": "O19358463193",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1460,
                "itemName": "École de technologie gazière",
                "dli": "O246882793044",
                "city": "Boucherville",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1461,
                "itemName": "École de technologie supérieure",
                "dli": "O19359201210",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1462,
                "itemName": "École des Hautes Études Commerciales de Montréal (HEC Montréal)",
                "dli": "O19359011058",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1463,
                "itemName": "École des entrepreneurs du Québec",
                "dli": "O127382560275",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1464,
                "itemName": "École des métiers de la construction de Montréal",
                "dli": "O246882792669",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1465,
                "itemName": "École des métiers de la restauration et du tourisme de Montréal",
                "dli": "O246882792779",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1466,
                "itemName": "École des métiers de l’aérospatiale de Montréal",
                "dli": "O246882792764",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1467,
                "itemName": "École des métiers de l’horticulture de Montréal",
                "dli": "O246882792829",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1468,
                "itemName": "École des métiers de l’informatique, du commerce et de l’administration de Montréal (ÉMICA)",
                "dli": "O246882792844",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1469,
                "itemName": "École des métiers de l’équipement motorisé de Montréal",
                "dli": "O246882792804",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1470,
                "itemName": "École des métiers des Faubourgs-de-Montréal",
                "dli": "O246882792929",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1471,
                "itemName": "École des métiers du Sud-Ouest-de-Montréal",
                "dli": "O246882792959",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1472,
                "itemName": "École des métiers du meuble de Montréal",
                "dli": "O246882792944",
                "city": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1473,
                "itemName": "École des métiers et occupations de l’industrie de la construction de Québec",
                "dli": "O246882792994",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1474,
                "itemName": "École des métiers spécialisés Asticou",
                "dli": "O246878951490",
                "city": "Gatineau",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1475,
                "itemName": "École des pêches et de l’aquaculture du Québec",
                "dli": "O246882793009",
                "city": "Grande-Rivière",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1476,
                "itemName": "École du Barreau du Québec",
                "dli": "O112575462911",
                "city": "Gatineau",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1477,
                "itemName": "École du Barreau du Québec",
                "dli": "O112575462911",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1478,
                "itemName": "École du Barreau du Québec",
                "dli": "O112575462911",
                "city": "Quebec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1479,
                "itemName": "École du Barreau du Québec",
                "dli": "O112575462911",
                "city": "Sherbrooke",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1480,
                "itemName": "École du Routier G.C. inc.",
                "dli": "O246882793069",
                "city": "Trois-Rivières",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1481,
                "itemName": "École du Routier G.C. inc.",
                "dli": "O246882793069",
                "city": "Drummondville",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1482,
                "itemName": "École du show-business",
                "dli": "O19338447320",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1483,
                "itemName": "École forestière de La Tuque - Formation professionnelle",
                "dli": "O246882793094",
                "city": "La Tuque",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1484,
                "itemName": "École hôtelière de Laval",
                "dli": "O246882793144",
                "city": "Laval",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1485,
                "itemName": "École hôtelière de la Capitale",
                "dli": "O246882793109",
                "city": "Québec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1486,
                "itemName": "École hôtelière de la Montérégie",
                "dli": "O142824336652",
                "city": "Longueuil",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1487,
                "itemName": "École hôtelière des Laurentides",
                "dli": "O246882793159",
                "city": "Sainte-Adèle",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1488,
                "itemName": "École nationale de camionnage et équipement lourd (E.N.C.E.L.)",
                "dli": "O246882793219",
                "city": "Québec",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1489,
                "itemName": "École nationale de camionnage et équipement lourd (E.N.C.E.L.)",
                "dli": "O246882793219",
                "city": "Terrebonne",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1490,
                "itemName": "École nationale de camionnage et équipement lourd (E.N.C.E.L.)",
                "dli": "O246882793219",
                "city": "Saint-Jean-sur-Richelieu",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1491,
                "itemName": "École nationale de cirque",
                "dli": "O19358463180",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1492,
                "itemName": "École nationale de l’humour",
                "dli": "O19338447284",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1493,
                "itemName": "École nationale de théâtre du Canada",
                "dli": "O19338447307",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1494,
                "itemName": "École nationale d’administration publique",
                "dli": "O19359011197",
                "city": "Quebec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1495,
                "itemName": "École professionnelle de Saint-Hyacinthe",
                "dli": "O246882793284",
                "city": "Saint-Hyacinthe",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1496,
                "itemName": "École professionnelle des Métiers",
                "dli": "O246882793299",
                "city": "Saint-Jean-sur-Richelieu",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1497,
                "itemName": "École secondaire Kassinu Mamu",
                "dli": "O246882793324",
                "city": "Mashteuiatsh",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1498,
                "itemName": "École secondaire Mikisiw",
                "dli": "O246882793339",
                "city": "Obedjiwan",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 1499,
                "itemName": "École supérieure de ballet du Québec",
                "dli": "O213292432416",
                "city": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 1450,
                "itemName": "George Brown College",
                "dli": "O19283850612",
                "city": "Toronto",
                "campus": "Young Centre for Performing Arts",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1451,
                "itemName": "George Brown College",
                "dli": "O19283850612",
                "city": "Toronto",
                "campus": "Waterfront",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1452,
                "itemName": "George Brown College",
                "dli": "O19283850612",
                "city": "Toronto",
                "campus": "St. James",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1453,
                "itemName": "George Brown College",
                "dli": "O19283850612",
                "city": "Toronto",
                "campus": "Ryerson University",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1454,
                "itemName": "George Brown College",
                "dli": "O19283850612",
                "city": "Toronto",
                "campus": "Casa Loma",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1455,
                "itemName": "Seneca International Academy",
                "dli": "O19395536013",
                "city": "Markham",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 1456,
                "itemName": "Seneca Downtown",
                "dli": "O19395536013",
                "city": "Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
        ];
        return collegesList;
    }

    university() {
        const universityData = [
            {
                "id": 1,
                "itemName": "Acadia University",
                "dli": "O19391556792",
                "city": "Wolfville",
                "campus": "Wolfville",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 2,
                "itemName": "Acadia University: English Language Centre",
                "dli": "O19391556981",
                "city": "Wolfville",
                "campus": "Wolfville",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 3,
                "itemName": "Adler University",
                "dli": "O19394452198",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 4,
                "itemName": "Alberta University of the Arts",
                "dli": "O18760756062",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 5,
                "itemName": "Algoma University",
                "dli": "O19395422197",
                "city": "Brampton",
                "campus": "Algoma at Brampton",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 6,
                "itemName": "Algoma University",
                "dli": "O19395422197",
                "city": "Sault Ste. Marie",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 7,
                "itemName": "Algoma University",
                "dli": "O19395422197",
                "city": "Timmins",
                "campus": "Algoma at Timmins",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 8,
                "itemName": "Ambrose University",
                "dli": "O18713147522",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes (details)",
                "province": "Alberta"
            },
            {
                "id": 9,
                "itemName": "Athabasca University",
                "dli": "O19092373272",
                "city": "Athabasca",
                "campus": "Athabasca",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 10,
                "itemName": "Athabasca University",
                "dli": "O19092373272",
                "city": "Edmonton",
                "campus": "Athabasca University – Edmonton",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 11,
                "itemName": "Bishop’s University",
                "dli": "O19359010995",
                "city": "Sherbrooke",
                "campus": "Sherbrooke",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 12,
                "itemName": "Booth University College",
                "dli": "O19147992362",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "Yes (details for Flight schools)",
                "province": "Manitoba"
            },
            {
                "id": 13,
                "itemName": "Brandon University",
                "dli": "O19201298932",
                "city": "Brandon",
                "campus": "Brandon",
                "offersPGWPEligible": "Yes",
                "province": "Manitoba"
            },
            {
                "id": 14,
                "itemName": "Brescia University College",
                "dli": "O19331472822",
                "city": "London",
                "campus": "London",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 15,
                "itemName": "Brock University",
                "dli": "O19394569014",
                "city": "Hamilton",
                "campus": "Hamilton",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 16,
                "itemName": "Brock University",
                "dli": "O19394569014",
                "city": "St. Catharines",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 17,
                "itemName": "Burman University",
                "dli": "O19390898172",
                "city": "Lacombe",
                "campus": "Lacombe",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 18,
                "itemName": "Canadian Mennonite University",
                "dli": "O19021102272",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "Yes",
                "province": "Manitoba"
            },
            {
                "id": 19,
                "itemName": "Cape Breton University",
                "dli": "O19391556824",
                "city": "Sydney",
                "campus": "Sydney",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 20,
                "itemName": "Capilano University",
                "dli": "O19280078102",
                "city": "Sechelt",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 21,
                "itemName": "Capilano University",
                "dli": "O19280078102",
                "city": "North Vancouver",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 22,
                "itemName": "Carleton University",
                "dli": "O19332687812",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 23,
                "itemName": "City University of Seattle",
                "dli": "O19361040422",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 24,
                "itemName": "City University of Seattle",
                "dli": "O19361040422",
                "city": "Langford",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 25,
                "itemName": "Collège Universel",
                "dli": "O19358463105",
                "city": "Gatineau",
                "campus": "Gatineau",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 26,
                "itemName": "Concordia Lutheran Theological Seminary (Brock University)",
                "dli": "O19394569014",
                "city": "St. Catharines",
                "campus": "St. Catharines",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 27,
                "itemName": "Concordia University",
                "dli": "O19359011007",
                "city": "Montréal",
                "campus": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 28,
                "itemName": "Concordia University of Edmonton",
                "dli": "O19073475522",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 29,
                "itemName": "Conrad Grebel University College",
                "dli": "O19305471522",
                "city": "Waterloo",
                "campus": "Affiliated with the University of Waterloo.",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 30,
                "itemName": "Crandall University",
                "dli": "O19391275763",
                "city": "Moncton",
                "campus": "Moncton",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 31,
                "itemName": "Dalhousie University",
                "dli": "O19209939282",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 32,
                "itemName": "Dalhousie University ESL Programs",
                "dli": "O19091594822",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 33,
                "itemName": "Dominican University College",
                "dli": "O19361087792",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 34,
                "itemName": "Emily Carr University of Art and Design",
                "dli": "O19361259712",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 35,
                "itemName": "Emmanuel College of Victoria University",
                "dli": "O19359108312",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 36,
                "itemName": "Faculté de Théologie Évangélique de l’Université Acadia",
                "dli": "O253199102048",
                "city": "Montréal",
                "campus": "Montréal",
                "offersPGWPEligible": "Details for Graduates of certain Quebec schools",
                "province": "Quebec"
            },
            {
                "id": 37,
                "itemName": "Fairleigh Dickinson University (Vancouver)",
                "dli": "O19227134842",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 43,
                "itemName": "Huntington University (Laurentian University)",
                "dli": "O19304259382",
                "city": "Sudbury",
                "campus": "Sudbury",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 44,
                "itemName": "Huron University College",
                "dli": "O19332573852",
                "city": "London",
                "campus": "London",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 45,
                "itemName": "ICEAP Nova Scotia Inc",
                "dli": "O19210055012",
                "city": "Sydney",
                "campus": "Cape Breton University and New Dawn Centre for Social Innovation",
                "offersPGWPEligible": "No",
                "province": "Nova Scotia"
            },
            {
                "id": 46,
                "itemName": "ILSC Toronto",
                "dli": "O19375697742",
                "city": "Toronto",
                "campus": "Bloor Street West",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 47,
                "itemName": "ILSC Toronto",
                "dli": "O19375697742",
                "city": "Toronto",
                "campus": "Dundas Street West",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 48,
                "itemName": "ILSC Toronto",
                "dli": "O19375697742",
                "city": "Toronto",
                "campus": "Elizabeth Street",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 49,
                "itemName": "ILSC Toronto",
                "dli": "O19375697742",
                "city": "Toronto",
                "campus": "St. Patrick Street",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 50,
                "itemName": "ILSC Toronto",
                "dli": "O19375697742",
                "city": "Toronto",
                "campus": "University Avenue",
                "offersPGWPEligible": "No",
                "province": "Ontario"
            },
            {
                "id": 51,
                "itemName": "Kingswood University",
                "dli": "O19348776942",
                "city": "Sussex",
                "campus": "Sussex",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 52,
                "itemName": "King’s University College",
                "dli": "O19376872842",
                "city": "London",
                "campus": "London",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 53,
                "itemName": "Kwantlen Polytechnic University",
                "dli": "O19350676872",
                "city": "Cloverdale, Langley, Richmond,Surrey",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 54,
                "itemName": "Lakehead University",
                "dli": "O19396019447",
                "city": "Thunder Bay",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 55,
                "itemName": "Lakehead University",
                "dli": "O19396019447",
                "city": "Orillia",
                "campus": "Orillia",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 56,
                "itemName": "MacEwan University",
                "dli": "O19092022262",
                "city": "Edmonton",
                "campus": "City Centre",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 57,
                "itemName": "MacEwan University",
                "dli": "O19092022262",
                "city": "Edmonton",
                "campus": "Centre for the Arts & Communications",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 58,
                "itemName": "MacEwan University",
                "dli": "O19092022262",
                "city": "Edmonton",
                "campus": "Alberta College",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 59,
                "itemName": "McGill University",
                "dli": "O19359011033",
                "city": "Montréal",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 60,
                "itemName": "McGill University",
                "dli": "O19359010982",
                "city": "Sainte-Anne-de-Bellevue",
                "campus": "Macdonald",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 61,
                "itemName": "Memorial University of Newfoundland",
                "dli": "O19440995346",
                "city": "Corner Brook",
                "campus": "Grenfell",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 62,
                "itemName": "Memorial University of Newfoundland",
                "dli": "O19440995346",
                "city": "St. John’s",
                "campus": "St. John’s",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 63,
                "itemName": "Memorial University of Newfoundland",
                "dli": "O19440995346",
                "city": "St. John’s",
                "campus": "Fisheries and Marine Institute",
                "offersPGWPEligible": "Yes",
                "province": "Newfoundland and Labrador"
            },
            {
                "id": 64,
                "itemName": "Mount Allison University",
                "dli": "O19273922302",
                "city": "Sackville",
                "campus": "Sackville",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 65,
                "itemName": "Mount Royal University",
                "dli": "O18761482032",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 66,
                "itemName": "Mount Saint Vincent University",
                "dli": "O19301947442",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 67,
                "itemName": "NSCAD University (Nova Scotia College of Art and Design)",
                "dli": "O19021269652",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 68,
                "itemName": "Nipissing University",
                "dli": "O19395535971",
                "city": "North Bay",
                "campus": "North Bay",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 69,
                "itemName": "Nipissing University",
                "dli": "O19395535971",
                "city": "Brantford",
                "campus": "Brantford",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 70,
                "itemName": "Nipissing University",
                "dli": "O19395535971",
                "city": "Bracebridge",
                "campus": "Muskoka",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 71,
                "itemName": "Nipissing University",
                "dli": "O19395535971",
                "city": "North Bay",
                "campus": "North Bay",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 72,
                "itemName": "Nipissing University",
                "dli": "O19395535971",
                "city": "Brantford",
                "campus": "Brantford",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 73,
                "itemName": "Nipissing University",
                "dli": "O19395535971",
                "city": "Bracebridge",
                "campus": "Muskoka",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 74,
                "itemName": "Northeastern University",
                "dli": "O142031583162",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 75,
                "itemName": "Northeastern University",
                "dli": "O141550844262",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 76,
                "itemName": "Northeastern University",
                "dli": "O141550844262",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 77,
                "itemName": "OCAD University",
                "dli": "O19332928222",
                "city": "Toronto",
                "campus": "Ontario College of Art & Design University",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 78,
                "itemName": "Télé-université",
                "dli": "O19359201245",
                "city": "Quebec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 79,
                "itemName": "Queen’s University",
                "dli": "O19376023352",
                "city": "Kingston",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 80,
                "itemName": "Queen’s University",
                "dli": "O19376023352",
                "city": "Toronto",
                "campus": "Queen’s School of Business Toronto Facility",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 81,
                "itemName": "Quest University Canada",
                "dli": "O19377267862",
                "city": "Squamish",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 84,
                "itemName": "Royal Roads University",
                "dli": "O19330635812",
                "city": "Victoria",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 85,
                "itemName": "Ryerson University",
                "dli": "O19395677651",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 86,
                "itemName": "Saint Mary’s University",
                "dli": "O19021279692",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 87,
                "itemName": "Simon Fraser University (SFU)",
                "dli": "O18781994282",
                "city": "Burnaby, Surrey, Vancouver",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 88,
                "itemName": "St. Francis Xavier University",
                "dli": "O19391556899",
                "city": "Antigonish",
                "campus": "Antigonish",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 89,
                "itemName": "St. Jerome’s University (University of Waterloo)",
                "dli": "O19305471522",
                "city": "Waterloo",
                "campus": "Waterloo",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 90,
                "itemName": "St. Mary’s University",
                "dli": "O19273782872",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 91,
                "itemName": "St. Paul’s University College (University of Waterloo)",
                "dli": "O19305471522",
                "city": "Waterloo",
                "campus": "Waterloo",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 92,
                "itemName": "St. Stephen’s University",
                "dli": "O19391276057",
                "city": "St. Stephen",
                "campus": "St. Stephen",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 93,
                "itemName": "St. Thomas University",
                "dli": "O19391556376",
                "city": "Fredericton",
                "campus": "Fredericton",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 94,
                "itemName": "The King’s University",
                "dli": "O18713250142",
                "city": "Edmonton",
                "campus": "Edmonton",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 95,
                "itemName": "The Language Centre at Saint Mary’s University",
                "dli": "O19347769972",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 96,
                "itemName": "Thompson Rivers University",
                "dli": "O19395299610",
                "city": "Williams Lake",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 97,
                "itemName": "Thompson Rivers University",
                "dli": "O19395299610",
                "city": "Kamloops",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 98,
                "itemName": "Thorneloe University (Laurentian University)",
                "dli": "O19304259382",
                "city": "Sudbury",
                "campus": "Sudbury",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 99,
                "itemName": "Trent University",
                "dli": "O19395164223",
                "city": "Oshawa",
                "campus": "Trent University Durham",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 100,
                "itemName": "Trent University",
                "dli": "O19395164223",
                "city": "Peterborough",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 101,
                "itemName": "Trinity Western University",
                "dli": "O19347055392",
                "city": "Richmond",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 102,
                "itemName": "Trinity Western University",
                "dli": "O19347055392",
                "city": "Langley",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 103,
                "itemName": "Tyndale University College & Seminary",
                "dli": "O19711152427",
                "city": "Toronto",
                "campus": "Bayview",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 104,
                "itemName": "Tyndale University College & Seminary",
                "dli": "O19711152427",
                "city": "Toronto",
                "campus": "Ballyconnor",
                "offersPGWPEligible": "Yes (details)",
                "province": "Ontario"
            },
            {
                "id": 105,
                "itemName": "Universal Learning Institute",
                "dli": "O19283932472",
                "city": "Richmond, Vancouver",
                "offersPGWPEligible": "No",
                "province": "British Columbia"
            },
            {
                "id": 106,
                "itemName": "University Canada West",
                "dli": "O19377235822",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 107,
                "itemName": "University College of the North",
                "dli": "O19283880122",
                "city": "The Pas",
                "campus": "The Pas",
                "offersPGWPEligible": "Yes",
                "province": "Manitoba"
            },
            {
                "id": 108,
                "itemName": "University of Alberta",
                "dli": "O19257171832",
                "city": "Camrose",
                "campus": "Augustana",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 109,
                "itemName": "University of Alberta",
                "dli": "O19257171832",
                "city": "Edmonton",
                "campus": "St. Jean",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 110,
                "itemName": "University of Alberta",
                "dli": "O19257171832",
                "city": "Edmonton",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 111,
                "itemName": "University of British Columbia (UBC)",
                "dli": "O19330231062",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 112,
                "itemName": "University of British Columbia (UBC)",
                "dli": "O19330231062",
                "city": "Kelowna",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 113,
                "itemName": "University of Calgary",
                "dli": "O18886830282",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 114,
                "itemName": "University of Guelph",
                "dli": "O19305391192",
                "city": "Guelph",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 115,
                "itemName": "University of Guelph",
                "dli": "O19305391192",
                "city": "Toronto",
                "campus": "University of Guelph-Humber",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 116,
                "itemName": "University of Guelph",
                "dli": "O19305391192",
                "city": "Alfred",
                "campus": "d’Alfred",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 117,
                "itemName": "University of Guelph",
                "dli": "O19305391192",
                "city": "Kemptville",
                "campus": "Kemptville",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 118,
                "itemName": "University of Guelph",
                "dli": "O19305391192",
                "city": "Ridgetown",
                "campus": "Ridgetown",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 119,
                "itemName": "University of King’s College",
                "dli": "O19391556768",
                "city": "Halifax",
                "campus": "Halifax",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 120,
                "itemName": "University of Lethbridge",
                "dli": "O18776949622",
                "city": "Calgary",
                "campus": "Calgary",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 121,
                "itemName": "University of Lethbridge",
                "dli": "O18776949622",
                "city": "Lethbridge",
                "campus": "Lethbridge",
                "offersPGWPEligible": "Yes",
                "province": "Alberta"
            },
            {
                "id": 122,
                "itemName": "University of Manitoba",
                "dli": "O19091528512",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "Yes",
                "province": "Manitoba"
            },
            {
                "id": 123,
                "itemName": "University of New Brunswick",
                "dli": "O19348802512",
                "city": "Fredericton",
                "campus": "UNB – Fredericton",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 124,
                "itemName": "University of New Brunswick",
                "dli": "O19348802512",
                "city": "Saint John",
                "campus": "UNB – Saint John",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 125,
                "itemName": "University of Northern British Columbia",
                "dli": "O19283889692",
                "city": "Fort St. John, Prince George, Quesnel, Terrace",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 126,
                "itemName": "University of Ontario Institute of Technology (UOIT)",
                "dli": "O19315945002",
                "city": "Oshawa",
                "campus": "Oshawa",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 127,
                "itemName": "University of Prince Edward Island",
                "dli": "O19220071452",
                "city": "Charlottetown",
                "campus": "Charlottetown",
                "offersPGWPEligible": "Yes",
                "province": "Prince Edward Island"
            },
            {
                "id": 128,
                "itemName": "University of Regina, including Campion College, First Nations University of Canada and Luther College",
                "dli": "O19425660270",
                "city": "Regina",
                "campus": "Regina",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 129,
                "itemName": "University of Saskatchewan, including St. Thomas More College",
                "dli": "O19425660421",
                "city": "Saskatoon",
                "campus": "Saskatoon",
                "offersPGWPEligible": "Yes",
                "province": "Saskatchewan"
            },
            {
                "id": 130,
                "itemName": "University of St. Michael’s College",
                "dli": "O19303916042",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 131,
                "itemName": "University of Sudbury (Laurentian University)",
                "dli": "O19304259382",
                "city": "Sudbury",
                "campus": "Sudbury",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 132,
                "itemName": "University of Toronto",
                "dli": "O19332746152",
                "city": "Mississauga",
                "campus": "Mississauga",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 133,
                "itemName": "University of Toronto",
                "dli": "O19332746152",
                "city": "Toronto",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 134,
                "itemName": "University of Toronto",
                "dli": "O19332746152",
                "city": "Toronto",
                "campus": "Scarborough",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 135,
                "itemName": "University of Trinity College",
                "dli": "O211893245447",
                "city": "Toronto",
                "campus": "University of Toronto - Hoskin Avenue",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 136,
                "itemName": "University of Trinity College",
                "dli": "O211893245447",
                "city": "Toronto",
                "campus": "University of Toronto - Devonshire Place",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 137,
                "itemName": "University of Victoria",
                "dli": "O19280533442",
                "city": "Victoria",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 138,
                "itemName": "University of Waterloo",
                "dli": "O19305471522",
                "city": "Breslau",
                "campus": "Wellington Flight Centre  (Aviation)",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 139,
                "itemName": "University of Waterloo",
                "dli": "O19305471522",
                "city": "Cambridge",
                "campus": "School of Architecture",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 140,
                "itemName": "University of Waterloo",
                "dli": "O19305471522",
                "city": "Waterloo",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 141,
                "itemName": "University of Waterloo",
                "dli": "O19305471522",
                "city": "Waterloo",
                "campus": "Balsillie School of International Affairs",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 142,
                "itemName": "University of Waterloo",
                "dli": "O19305471522",
                "city": "Waterloo",
                "campus": "Allen Square",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 143,
                "itemName": "University of Waterloo",
                "dli": "O19305471522",
                "city": "Kitchener",
                "campus": "44 Gaukel St",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 144,
                "itemName": "University of Waterloo",
                "dli": "O19305471522",
                "city": "Kitchener",
                "campus": "Health Sciences",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 145,
                "itemName": "University of Waterloo",
                "dli": "O19305471522",
                "city": "Kitchener",
                "campus": "ELAS program (operated by Conestoga College)",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 146,
                "itemName": "University of Waterloo",
                "dli": "O19305471522",
                "city": "Stratford",
                "campus": "Stratford",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 147,
                "itemName": "University of Waterloo",
                "dli": "O19305471522",
                "city": "Toronto",
                "campus": "Master of Taxation Site",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 148,
                "itemName": "University of Windsor",
                "dli": "O19358946722",
                "city": "Windsor",
                "campus": "Windsor",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 149,
                "itemName": "University of Winnipeg",
                "dli": "O19147986012",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "Yes",
                "province": "Manitoba"
            },
            {
                "id": 150,
                "itemName": "University of the Fraser Valley",
                "dli": "O19395299642",
                "city": "Chilliwack",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 151,
                "itemName": "University of the Fraser Valley",
                "dli": "O19395299642",
                "city": "Abbotsford",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 152,
                "itemName": "University of the Fraser Valley",
                "dli": "O19395299642",
                "city": "Chilliwack",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 153,
                "itemName": "University of the Fraser Valley",
                "dli": "O19395299642",
                "city": "Mission",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 154,
                "itemName": "Université Laval",
                "dli": "O19359011020",
                "city": "Quebec",
                "campus": "Quebec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 155,
                "itemName": "Université Saint-Paul/St. Paul University",
                "dli": "O19395164265",
                "city": "Ottawa",
                "campus": "Ottawa",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 156,
                "itemName": "Université Sainte-Anne",
                "dli": "O19391556726",
                "city": "Church Point",
                "campus": "Church Point",
                "offersPGWPEligible": "Yes",
                "province": "Nova Scotia"
            },
            {
                "id": 157,
                "itemName": "Université de Moncton",
                "dli": "O19391556532",
                "city": "Edmundston",
                "campus": "d’Edmunsdston",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 158,
                "itemName": "Université de Moncton",
                "dli": "O19391556532",
                "city": "Moncton",
                "campus": "de Moncton",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 159,
                "itemName": "Université de Moncton",
                "dli": "O19391556532",
                "city": "Shippagan",
                "campus": "de Shippagan",
                "offersPGWPEligible": "Yes",
                "province": "New Brunswick"
            },
            {
                "id": 160,
                "itemName": "Université de Montréal",
                "dli": "O19359011045",
                "city": "Montréal",
                "campus": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 161,
                "itemName": "Université de Saint-Boniface",
                "dli": "O19126670302",
                "city": "Winnipeg",
                "campus": "Winnipeg",
                "offersPGWPEligible": "Yes",
                "province": "Manitoba"
            },
            {
                "id": 162,
                "itemName": "Université de Sherbrooke",
                "dli": "O19359011083",
                "city": "Sherbrooke",
                "campus": "Sherbrooke",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 163,
                "itemName": "Université du Québec",
                "dli": "O19359011096",
                "city": "Quebec",
                "campus": "Quebec",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 164,
                "itemName": "Université du Québec en Abitibi-Témiscamingue",
                "dli": "O19359011109",
                "city": "Rouyn-Noranda",
                "campus": "Rouyn-Noranda",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 165,
                "itemName": "Université du Québec en Outaouais Pavillon Alexandre-Taché",
                "dli": "O19359011146",
                "city": "Gatineau",
                "campus": "Gatineau",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 166,
                "itemName": "Université du Québec à Chicoutimi",
                "dli": "O19359011122",
                "city": "Saguenay",
                "campus": "Saguenay",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 167,
                "itemName": "Université du Québec à Montréal",
                "dli": "O19359011134",
                "city": "Montréal",
                "campus": "Montréal",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 168,
                "itemName": "Université du Québec à Rimouski",
                "dli": "O19359011159",
                "city": "Rimouski",
                "campus": "Rimouski",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 169,
                "itemName": "Université du Québec à Trois-Rivières",
                "dli": "O19359011172",
                "city": "Trois-Rivières",
                "campus": "Trois-Rivières",
                "offersPGWPEligible": "Yes",
                "province": "Quebec"
            },
            {
                "id": 170,
                "itemName": "Université d’Ottawa/University of Ottawa",
                "dli": "O19397188593",
                "city": "Ottawa",
                "campus": "La Cité Collégiale (Collaborative)",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 171,
                "itemName": "Université d’Ottawa/University of Ottawa",
                "dli": "O19397188593",
                "city": "Ottawa",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 172,
                "itemName": "Université d’Ottawa/University of Ottawa",
                "dli": "O19397188593",
                "city": "Ottawa",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 173,
                "itemName": "Université d’Ottawa/University of Ottawa",
                "dli": "O19397188593",
                "city": "Ottawa",
                "campus": "Algonquin College of Applied Arts and Technology (Collaborative)",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 174,
                "itemName": "Université d’Ottawa/University of Ottawa",
                "dli": "O19397188593",
                "city": "Pembroke",
                "campus": "Algonquin College of Applied Arts and Technology (Collaborative)",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 175,
                "itemName": "Vancouver Island University",
                "dli": "O19395299688",
                "city": "Duncan, Nanaimo, Parksville, Powell River",
                "offersPGWPEligible": "Yes",
                "province": "British Columbia"
            },
            {
                "id": 176,
                "itemName": "Victoria College in Victoria University (University of Toronto)",
                "dli": "O19332746152",
                "city": "Toronto",
                "campus": "Toronto",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 177,
                "itemName": "Waterloo Lutheran Seminary (Wilfred Laurier University)",
                "dli": "O19395164307",
                "city": "Waterloo",
                "campus": "Waterloo",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 178,
                "itemName": "Western University",
                "dli": "O19375892122",
                "city": "London",
                "campus": "The University of Western Ontario",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 179,
                "itemName": "Wilfrid Laurier University",
                "dli": "O19395164307",
                "city": "Brantford",
                "campus": "Brantford",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 180,
                "itemName": "Wilfrid Laurier University",
                "dli": "O19395164307",
                "city": "Kitchener",
                "campus": "Kitchener Location",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 181,
                "itemName": "Wilfrid Laurier University",
                "dli": "O19395164307",
                "city": "Toronto",
                "campus": "Toronto Location",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 182,
                "itemName": "Wilfrid Laurier University",
                "dli": "O19395164307",
                "city": "Waterloo",
                "campus": "Main",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 183,
                "itemName": "York University",
                "dli": "O19361109242",
                "city": "Toronto",
                "campus": "Osgoode Hall Law School",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 184,
                "itemName": "York University",
                "dli": "O19361109242",
                "city": "Toronto",
                "campus": "Glendon,",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 185,
                "itemName": "York University",
                "dli": "O19361109242",
                "city": "Toronto",
                "campus": "Miles S. Nadal Management Centre",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 186,
                "itemName": "York University",
                "dli": "O19361109242",
                "city": "Toronto",
                "campus": "principal",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 187,
                "itemName": "Yorkville University",
                "dli": "O129034707957",
                "city": "Vancouver",
                "offersPGWPEligible": "Yes (details)",
                "province": "British Columbia"
            },
            {
                "id": 188,
                "itemName": "Yorkville University/Toronto Film School",
                "dli": "O19396019511",
                "city": "Concord",
                "campus": "Concord",
                "offersPGWPEligible": "Yes (details for Yorkville University/Toronto Film School)"
            },
            {
                "id": 189,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Carcross",
                "campus": "Carcross Community",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 190,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Carmacks",
                "campus": "Carmacks Community",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 191,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Dawson city",
                "campus": "Dawson city Tr’odëk Hätr’unohtän Zho",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 192,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Faro",
                "campus": "Faro Community",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 193,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Haines Junction",
                "campus": "Haines Junction Community",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 194,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Mayo",
                "campus": "Mayo Community",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 195,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Old Crow",
                "campus": "Old Crow Alice Frost Community",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 196,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Pelly Crossing",
                "campus": "Pelly Crossing Hets’edän Kú’Learning House",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 197,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Ross River",
                "campus": "Ross River Dene Cho Kê’endj",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 198,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Teslin",
                "campus": "Teslin Community",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 199,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Watson Lake",
                "campus": "Watson Lake Community",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 200,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Whitehorse",
                "campus": "Kwanlin Dun First Nation Kenädän Ku House of Learning",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 201,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Whitehorse",
                "campus": "Ayamdigut",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 202,
                "itemName": "Yukon University",
                "dli": "O19604209351",
                "city": "Whitehorse",
                "campus": "Whitehorse Correctional Centre",
                "offersPGWPEligible": "Yes",
                "province": "Yukon"
            },
            {
                "id": 203,
                "itemName": "Université de Hearst",
                "dli": "O19395677925",
                "city": "Hearst",
                "campus": "de Hearst",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 204,
                "itemName": "Université de Hearst",
                "dli": "O19395677925",
                "city": "Kapuskasing",
                "campus": "de Kapuskasing",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            },
            {
                "id": 205,
                "itemName": "Université de Hearst",
                "dli": "O19395677925",
                "city": "Timmins",
                "campus": "de Timmins",
                "offersPGWPEligible": "Yes",
                "province": "Ontario"
            }
        ]
        return universityData;
    }

    state() {
        const stateData = [
            { "id": 1, "itemName": "Andra Pradesh" },
            { "id": 2, "itemName": "Andaman and Nicobar Islands" },
            { "id": 3, "itemName": "Arunachal Pradesh" },
            { "id": 4, "itemName": "Assam" },
            { "id": 5, "itemName": "Bihar" },
            { "id": 6, "itemName": "Chandigarh" },
            { "id": 7, "itemName": "Chhattisgarh" },
            { "id": 8, "itemName": "Dadar and Nagar Haveli" },
            { "id": 9, "itemName": "Daman and Diu" },
            { "id": 10, "itemName": "Delhi" },
            { "id": 11, "itemName": "Goa" },
            { "id": 12, "itemName": "Gujarat" },
            { "id": 13, "itemName": "Haryana" },
            { "id": 14, "itemName": "Himachal Pradesh" },
            { "id": 15, "itemName": "Jammu and Kashmir" },
            { "id": 16, "itemName": "Jharkhand" },
            { "id": 17, "itemName": "Karnataka" },
            { "id": 18, "itemName": "Kerala" },
            { "id": 19, "itemName": "Lakshadeep" },
            { "id": 20, "itemName": "Madya Pradesh" },
            { "id": 21, "itemName": "Maharashtra" },
            { "id": 22, "itemName": "Manipur" },
            { "id": 23, "itemName": "Meghalaya" },
            { "id": 24, "itemName": "Mizoram" },
            { "id": 25, "itemName": "Nagaland" },
            { "id": 26, "itemName": "Orissa" },
            { "id": 27, "itemName": "Punjab" },
            { "id": 28, "itemName": "Pondicherry" },
            { "id": 29, "itemName": "Rajasthan" },
            { "id": 30, "itemName": "Sikkim" },
            { "id": 31, "itemName": "Tamil Nadu" },
            { "id": 32, "itemName": "Telagana" },
            { "id": 33, "itemName": "Tripura" },
            { "id": 34, "itemName": "Uttaranchal" },
            { "id": 35, "itemName": "Uttar Pradesh" },
            { "id": 36, "itemName": "West Bengal" }
        ];
        return stateData;
    }

    program() {
        const programData = [
            {
                "id": 1,
                "itemName": "Accounting"
            },
            {
                "id": 2,
                "itemName": "Actuarial Science"
            },
            {
                "id": 636,
                "itemName": "Adult And Higher Education"
            },
            {
                "id": 3,
                "itemName": "Adult Learning and Education"
            },
            {
                "id": 4,
                "itemName": "Adult Learning and Global Change"
            },
            {
                "id": 5,
                "itemName": "Advanced Materials Manufacturing"
            },
            {
                "id": 6,
                "itemName": "African Studies"
            },
            {
                "id": 637,
                "itemName": "Agricultural Science (Top Up) Bedford College Shuttleworth BSc (Hons)"
            },
            {
                "id": 638,
                "itemName": "Agriculture FdSc"
            },
            {
                "id": 7,
                "itemName": "American Studies"
            },
            {
                "id": 9,
                "itemName": "Ancient Culture, Religion and Ethnicity"
            },
            {
                "id": 8,
                "itemName": "Ancient and Medieval History"
            },
            {
                "id": 10,
                "itemName": "Animal Physiology"
            },
            {
                "id": 11,
                "itemName": "Anthropology"
            },
            {
                "id": 12,
                "itemName": "Anthropology (Biological)"
            },
            {
                "id": 13,
                "itemName": "Anthropology (HBA)"
            },
            {
                "id": 14,
                "itemName": "Anthropology (HBSc)"
            },
            {
                "id": 15,
                "itemName": "Anthropology (Social and Cultural)"
            },
            {
                "id": 16,
                "itemName": "Applied Animal Biology"
            },
            {
                "id": 639,
                "itemName": "Applied Early Years Studies (Top up) BA (Hons)"
            },
            {
                "id": 640,
                "itemName": "Applied Early Years Studies (Top up) BA (Hons)"
            },
            {
                "id": 641,
                "itemName": "Applied Early Years Studies (Top up) BA (Hons)"
            },
            {
                "id": 642,
                "itemName": "Applied Education Studies (Daytime Delivery) Luton BA (Hons)"
            },
            {
                "id": 643,
                "itemName": "Applied Education Studies (Evening Delivery) Luton BA (Hons)"
            },
            {
                "id": 644,
                "itemName": "Applied Education Studies BA (Hons)"
            },
            {
                "id": 645,
                "itemName": "Applied Education Studies BA (Hons)"
            },
            {
                "id": 646,
                "itemName": "Applied Education Studies BA (Hons)"
            },
            {
                "id": 647,
                "itemName": "Applied Linguistics (TEFL) MA"
            },
            {
                "id": 648,
                "itemName": "Applied Linguistics (TEFL) MA"
            },
            {
                "id": 649,
                "itemName": "Applied Linguistics MA"
            },
            {
                "id": 17,
                "itemName": "Applied Mathematics"
            },
            {
                "id": 650,
                "itemName": "Applied Personal Training and Specialist Exercise Instruction BSc (Hons)"
            },
            {
                "id": 18,
                "itemName": "Applied Statistics"
            },
            {
                "id": 19,
                "itemName": "Archaeology"
            },
            {
                "id": 20,
                "itemName": "Archaeology (Bachelor of Arts)"
            },
            {
                "id": 21,
                "itemName": "Archaeology (Bachelor of Science)"
            },
            {
                "id": 22,
                "itemName": "Architectural Studies"
            },
            {
                "id": 23,
                "itemName": "Architecture"
            },
            {
                "id": 24,
                "itemName": "Archival Studies"
            },
            {
                "id": 25,
                "itemName": "Archival Studies and Library Information Studies"
            },
            {
                "id": 27,
                "itemName": "Art Education"
            },
            {
                "id": 28,
                "itemName": "Art History"
            },
            {
                "id": 29,
                "itemName": "Art History (Critical Curatorial Studies)"
            },
            {
                "id": 26,
                "itemName": "Art and Art History (Joint Program with Sheridan College)"
            },
            {
                "id": 30,
                "itemName": "Arts Management"
            },
            {
                "id": 31,
                "itemName": "Asian Canadian Studies"
            },
            {
                "id": 32,
                "itemName": "Asian Geographies"
            },
            {
                "id": 33,
                "itemName": "Asian Literatures and Culture"
            },
            {
                "id": 34,
                "itemName": "Asian Studies"
            },
            {
                "id": 35,
                "itemName": "Astronomical Sciences"
            },
            {
                "id": 36,
                "itemName": "Astronomy"
            },
            {
                "id": 37,
                "itemName": "Astronomy and Astrophysics"
            },
            {
                "id": 38,
                "itemName": "Astronomy and Physics"
            },
            {
                "id": 39,
                "itemName": "Astrophysics"
            },
            {
                "id": 40,
                "itemName": "Atmospheric Science"
            },
            {
                "id": 41,
                "itemName": "Audiology and Speech Sciences"
            },
            {
                "id": 651,
                "itemName": "Automotive Collision and Refinishing Diploma (International Cohort)"
            },
            {
                "id": 652,
                "itemName": "Automotive Service Technician"
            },
            {
                "id": 653,
                "itemName": "Automotive Service Technician Foundation"
            },
            {
                "id": 654,
                "itemName": "Automotive Service Technology Diploma (International Cohort)"
            },
            {
                "id": 655,
                "itemName": "Automotive Technician - Service and Management (Motive Power Technician)"
            },
            {
                "id": 656,
                "itemName": "B.S. Art Education"
            },
            {
                "id": 657,
                "itemName": "B.S. Career"
            },
            {
                "id": 658,
                "itemName": "B.S. Early Childhood Education"
            },
            {
                "id": 659,
                "itemName": "B.S. Family and Consumer Sciences Education"
            },
            {
                "id": 660,
                "itemName": "B.S. Family and Consumer Sciences Education"
            },
            {
                "id": 661,
                "itemName": "B.S. Marketing and Business Education"
            },
            {
                "id": 662,
                "itemName": "B.S. Marketing and Business Education"
            },
            {
                "id": 663,
                "itemName": "B.S. Special Education"
            },
            {
                "id": 664,
                "itemName": "B.S. Special Education"
            },
            {
                "id": 665,
                "itemName": "B.S. Technology Education"
            },
            {
                "id": 666,
                "itemName": "B.S. Technology Education"
            },
            {
                "id": 42,
                "itemName": "Bachelor of Education"
            },
            {
                "id": 43,
                "itemName": "Bachelor of Education (French)"
            },
            {
                "id": 667,
                "itemName": "Bedfordshire International and EU Foundation Year"
            },
            {
                "id": 44,
                "itemName": "Biochemistry"
            },
            {
                "id": 45,
                "itemName": "Biochemistry and Molecular Biology"
            },
            {
                "id": 46,
                "itemName": "Biodiversity and Conservation Biology"
            },
            {
                "id": 47,
                "itemName": "Bioethics"
            },
            {
                "id": 48,
                "itemName": "Bioinformatics"
            },
            {
                "id": 49,
                "itemName": "Bioinformatics and Computational Biology"
            },
            {
                "id": 50,
                "itemName": "Biological Chemistry"
            },
            {
                "id": 51,
                "itemName": "Biological Physics"
            },
            {
                "id": 52,
                "itemName": "Biological Sciences"
            },
            {
                "id": 53,
                "itemName": "Biology"
            },
            {
                "id": 54,
                "itemName": "Biology / Biologie"
            },
            {
                "id": 55,
                "itemName": "Biomechanics"
            },
            {
                "id": 56,
                "itemName": "Biomedical Communications"
            },
            {
                "id": 57,
                "itemName": "Biomedical Engineering"
            },
            {
                "id": 58,
                "itemName": "Biomedical Physics"
            },
            {
                "id": 59,
                "itemName": "Biomedical Science"
            },
            {
                "id": 60,
                "itemName": "Biomedical Sciences"
            },
            {
                "id": 61,
                "itemName": "Biomedical Toxicology"
            },
            {
                "id": 62,
                "itemName": "Biophysics"
            },
            {
                "id": 63,
                "itemName": "Biotechnology"
            },
            {
                "id": 64,
                "itemName": "Book and Media Studies"
            },
            {
                "id": 65,
                "itemName": "Botany"
            },
            {
                "id": 66,
                "itemName": "Buddhism, Psychology, and Mental Health"
            },
            {
                "id": 67,
                "itemName": "Buddhist Studies"
            },
            {
                "id": 668,
                "itemName": "Building Construction Technician"
            },
            {
                "id": 669,
                "itemName": "Building Construction Technician"
            },
            {
                "id": 670,
                "itemName": "Building Services and Sustainability FD"
            },
            {
                "id": 671,
                "itemName": "Building Technology FdSc"
            },
            {
                "id": 68,
                "itemName": "Business"
            },
            {
                "id": 69,
                "itemName": "Business & Society"
            },
            {
                "id": 70,
                "itemName": "Business Administration"
            },
            {
                "id": 71,
                "itemName": "Business Analytics"
            },
            {
                "id": 72,
                "itemName": "Business Economics"
            },
            {
                "id": 73,
                "itemName": "Business Economics / Économie et commerce"
            },
            {
                "id": 74,
                "itemName": "Business Technology Management"
            },
            {
                "id": 673,
                "itemName": "Cabinetmaking Techniques"
            },
            {
                "id": 672,
                "itemName": "Cabinetmaking and Furniture Technician"
            },
            {
                "id": 75,
                "itemName": "Canadian Studies"
            },
            {
                "id": 76,
                "itemName": "Canadian Studies / Études canadiennes"
            },
            {
                "id": 77,
                "itemName": "Caribbean Studies"
            },
            {
                "id": 674,
                "itemName": "Carpenter"
            },
            {
                "id": 675,
                "itemName": "Carpenter Foundation"
            },
            {
                "id": 676,
                "itemName": "Carpentry & Renovation Techniques"
            },
            {
                "id": 683,
                "itemName": "Carpentry Renovation Techniques"
            },
            {
                "id": 684,
                "itemName": "Carpentry Techniques"
            },
            {
                "id": 677,
                "itemName": "Carpentry and Joinery - Heritage"
            },
            {
                "id": 678,
                "itemName": "Carpentry and Renovation Technician"
            },
            {
                "id": 679,
                "itemName": "Carpentry and Renovation Techniques"
            },
            {
                "id": 680,
                "itemName": "Carpentry and Renovation Techniques"
            },
            {
                "id": 681,
                "itemName": "Carpentry and Renovation Techniques"
            },
            {
                "id": 682,
                "itemName": "Carpentry and Renovation Techniques"
            },
            {
                "id": 78,
                "itemName": "Cell and Developmental Biology"
            },
            {
                "id": 79,
                "itemName": "Cell and Molecular Biology"
            },
            {
                "id": 80,
                "itemName": "Cellular, Molecular and Microbial Biology"
            },
            {
                "id": 81,
                "itemName": "Celtic Studies"
            },
            {
                "id": 83,
                "itemName": "Chemical Engineering"
            },
            {
                "id": 84,
                "itemName": "Chemical Physics"
            },
            {
                "id": 82,
                "itemName": "Chemical and Biological Engineering"
            },
            {
                "id": 85,
                "itemName": "Chemistry"
            },
            {
                "id": 87,
                "itemName": "Children's Literature"
            },
            {
                "id": 86,
                "itemName": "Children, Childhood and Youth"
            },
            {
                "id": 88,
                "itemName": "Choral Conducting"
            },
            {
                "id": 89,
                "itemName": "Christianity and Culture"
            },
            {
                "id": 90,
                "itemName": "Cinema & Media Studies"
            },
            {
                "id": 92,
                "itemName": "Cinema Studies"
            },
            {
                "id": 91,
                "itemName": "Cinema and Media Studies"
            },
            {
                "id": 93,
                "itemName": "City Studies"
            },
            {
                "id": 94,
                "itemName": "Civil Engineering"
            },
            {
                "id": 685,
                "itemName": "Civil Engineering Technology (compressed)"
            },
            {
                "id": 96,
                "itemName": "Classical Civilization"
            },
            {
                "id": 97,
                "itemName": "Classical Studies"
            },
            {
                "id": 98,
                "itemName": "Classical Studies/Classics"
            },
            {
                "id": 95,
                "itemName": "Classical and Near Eastern Archaeology"
            },
            {
                "id": 99,
                "itemName": "Classics"
            },
            {
                "id": 100,
                "itemName": "Classics (Greek and Latin)"
            },
            {
                "id": 101,
                "itemName": "Clean Energy Engineering"
            },
            {
                "id": 102,
                "itemName": "Clinical Education"
            },
            {
                "id": 103,
                "itemName": "Cognitive Science"
            },
            {
                "id": 104,
                "itemName": "Cognitive Science (HBA)"
            },
            {
                "id": 105,
                "itemName": "Cognitive Science (HBSc)"
            },
            {
                "id": 686,
                "itemName": "Collision Repair and Refinishing"
            },
            {
                "id": 106,
                "itemName": "Commerce"
            },
            {
                "id": 108,
                "itemName": "Communication Studies"
            },
            {
                "id": 107,
                "itemName": "Communication and Media Studies"
            },
            {
                "id": 109,
                "itemName": "Communication, Culture, Information, and Technology (Joint Program with Sheridan College)"
            },
            {
                "id": 110,
                "itemName": "Communications / Communications"
            },
            {
                "id": 111,
                "itemName": "Community Rehabilitation"
            },
            {
                "id": 112,
                "itemName": "Comparative Physiology"
            },
            {
                "id": 113,
                "itemName": "Composition"
            },
            {
                "id": 114,
                "itemName": "Computer Engineering"
            },
            {
                "id": 687,
                "itemName": "Computer Engineering Technican"
            },
            {
                "id": 115,
                "itemName": "Computer Science"
            },
            {
                "id": 116,
                "itemName": "Computer Security"
            },
            {
                "id": 688,
                "itemName": "Computer Systems Technician"
            },
            {
                "id": 689,
                "itemName": "Construction Engineering Technican"
            },
            {
                "id": 691,
                "itemName": "Construction Techniques (Carpentry"
            },
            {
                "id": 690,
                "itemName": "Construction Techniques (Carpentry)"
            },
            {
                "id": 692,
                "itemName": "Construction Techniques (HVAC"
            },
            {
                "id": 693,
                "itemName": "Construction Techniques (Plumbing"
            },
            {
                "id": 117,
                "itemName": "Contemporary Asian Studies Program (Dr. David Chu)"
            },
            {
                "id": 118,
                "itemName": "Counselling Psychology"
            },
            {
                "id": 694,
                "itemName": "Crane Operation"
            },
            {
                "id": 119,
                "itemName": "Craniofacial Science"
            },
            {
                "id": 120,
                "itemName": "Creative Expression & Society"
            },
            {
                "id": 121,
                "itemName": "Creative Writing"
            },
            {
                "id": 695,
                "itemName": "Creative Writing BA (Hons)"
            },
            {
                "id": 122,
                "itemName": "Creative Writing and Theatre"
            },
            {
                "id": 123,
                "itemName": "Criminology"
            },
            {
                "id": 124,
                "itemName": "Criminology & Socio-Legal Studies"
            },
            {
                "id": 125,
                "itemName": "Criminology, Law & Society"
            },
            {
                "id": 126,
                "itemName": "Critical Studies in Equity and Solidarity"
            },
            {
                "id": 696,
                "itemName": "Culinary Management/Cook Co-op Diploma Apprenticeship"
            },
            {
                "id": 127,
                "itemName": "Culture & Expression"
            },
            {
                "id": 129,
                "itemName": "Curriculum Studies"
            },
            {
                "id": 128,
                "itemName": "Curriculum and Leadership"
            },
            {
                "id": 130,
                "itemName": "Dance"
            },
            {
                "id": 131,
                "itemName": "Data Science"
            },
            {
                "id": 132,
                "itemName": "Dependable Software Systems"
            },
            {
                "id": 133,
                "itemName": "Design"
            },
            {
                "id": 134,
                "itemName": "Development Studies"
            },
            {
                "id": 135,
                "itemName": "Developmental Biology"
            },
            {
                "id": 136,
                "itemName": "Diaspora and Transnational Studies"
            },
            {
                "id": 697,
                "itemName": "Diesel Engine Mechanic"
            },
            {
                "id": 137,
                "itemName": "Digital Enterprise Management (Joint Program with Sheridan College)"
            },
            {
                "id": 138,
                "itemName": "Digital Humanities"
            },
            {
                "id": 139,
                "itemName": "Digital Media"
            },
            {
                "id": 140,
                "itemName": "Disaster & Emergency Management"
            },
            {
                "id": 141,
                "itemName": "Drama"
            },
            {
                "id": 142,
                "itemName": "Drama Studies / Études d'art dramatique"
            },
            {
                "id": 705,
                "itemName": "EDS1W - Education Support"
            },
            {
                "id": 143,
                "itemName": "Early Childhood Education"
            },
            {
                "id": 698,
                "itemName": "Early Childhood Education (Graduate Practitioner) BA (Hons)"
            },
            {
                "id": 699,
                "itemName": "Early Years (Birth to 5) (with EYTS) PGCE"
            },
            {
                "id": 701,
                "itemName": "Early Years Studies FdA"
            },
            {
                "id": 702,
                "itemName": "Early Years Teaching (with recommendation for EYTS) Pen Green"
            },
            {
                "id": 700,
                "itemName": "Early Years and English Language Teaching BA (Hons)"
            },
            {
                "id": 144,
                "itemName": "Earth & Atmospheric Science"
            },
            {
                "id": 146,
                "itemName": "Earth Science"
            },
            {
                "id": 145,
                "itemName": "Earth and Environmental Systems"
            },
            {
                "id": 147,
                "itemName": "East Asian Language Studies"
            },
            {
                "id": 148,
                "itemName": "East Asian Studies"
            },
            {
                "id": 149,
                "itemName": "Ecology"
            },
            {
                "id": 150,
                "itemName": "Ecology and Evolution"
            },
            {
                "id": 151,
                "itemName": "Economics"
            },
            {
                "id": 152,
                "itemName": "Economics / Science économique"
            },
            {
                "id": 703,
                "itemName": "Economics Teaching Minor"
            },
            {
                "id": 153,
                "itemName": "Economics and Mathematics"
            },
            {
                "id": 154,
                "itemName": "Ecosystem Management - York/Fleming Joint Program"
            },
            {
                "id": 704,
                "itemName": "Ed.D. Career and Technical Education Leadership"
            },
            {
                "id": 155,
                "itemName": "Education"
            },
            {
                "id": 156,
                "itemName": "Education (Early Childhood Education)"
            },
            {
                "id": 157,
                "itemName": "Education (English Language Learners, French, Second Languages)"
            },
            {
                "id": 158,
                "itemName": "Education (Fine Arts, English)"
            },
            {
                "id": 159,
                "itemName": "Education (Inclusive Education, Physical Education)"
            },
            {
                "id": 706,
                "itemName": "Education (Leadership)"
            },
            {
                "id": 160,
                "itemName": "Education (Math, Science)"
            },
            {
                "id": 707,
                "itemName": "Education (National Award for Special Educational Needs Co-ordination) Distance Learning PgCert"
            },
            {
                "id": 708,
                "itemName": "Education (National Award for Special Educational Needs Co-ordination) PgCert"
            },
            {
                "id": 161,
                "itemName": "Education (Social Studies)"
            },
            {
                "id": 162,
                "itemName": "Education Studies"
            },
            {
                "id": 709,
                "itemName": "Educational Administration"
            },
            {
                "id": 163,
                "itemName": "Educational Administration and Leadership"
            },
            {
                "id": 164,
                "itemName": "Educational Leadership and Policy"
            },
            {
                "id": 710,
                "itemName": "Educational Practice FD"
            },
            {
                "id": 711,
                "itemName": "Educational Practice FD"
            },
            {
                "id": 712,
                "itemName": "Educational Practice FD"
            },
            {
                "id": 165,
                "itemName": "Educational Studies"
            },
            {
                "id": 166,
                "itemName": "Educational Technology"
            },
            {
                "id": 168,
                "itemName": "Electrical Engineering"
            },
            {
                "id": 713,
                "itemName": "Electrical Engineering Techncian"
            },
            {
                "id": 714,
                "itemName": "Electrical Engineering Technician"
            },
            {
                "id": 715,
                "itemName": "Electrical Engineering Technician"
            },
            {
                "id": 716,
                "itemName": "Electrical Engineering Technician - Industrial"
            },
            {
                "id": 717,
                "itemName": "Electrical Engineering Technology"
            },
            {
                "id": 718,
                "itemName": "Electrical Technician - Industrial (Optional Co-op)"
            },
            {
                "id": 719,
                "itemName": "Electrical Technician - Industrial (Optional Co-op)"
            },
            {
                "id": 720,
                "itemName": "Electrical Techniques"
            },
            {
                "id": 721,
                "itemName": "Electrical Techniques"
            },
            {
                "id": 722,
                "itemName": "Electrical Techniques"
            },
            {
                "id": 723,
                "itemName": "Electrical Techniques"
            },
            {
                "id": 724,
                "itemName": "Electrical Techniques"
            },
            {
                "id": 167,
                "itemName": "Electrical and Computer Engineering"
            },
            {
                "id": 725,
                "itemName": "Electrical/Industrial Mechanic"
            },
            {
                "id": 726,
                "itemName": "Electrician"
            },
            {
                "id": 727,
                "itemName": "Electrician Foundation"
            },
            {
                "id": 728,
                "itemName": "Electro-Mechanical Maintenance (Optional Co-op)"
            },
            {
                "id": 169,
                "itemName": "Endodontics"
            },
            {
                "id": 171,
                "itemName": "Energy Engineering"
            },
            {
                "id": 729,
                "itemName": "Energy Policy Certificate"
            },
            {
                "id": 170,
                "itemName": "Energy and Professional Land Management"
            },
            {
                "id": 172,
                "itemName": "Engineering & International Development Studies"
            },
            {
                "id": 173,
                "itemName": "Engineering Physics"
            },
            {
                "id": 174,
                "itemName": "Engineering Science"
            },
            {
                "id": 175,
                "itemName": "English"
            },
            {
                "id": 176,
                "itemName": "English & Professional Writing"
            },
            {
                "id": 177,
                "itemName": "English Language Linguistics"
            },
            {
                "id": 730,
                "itemName": "English Language Teaching"
            },
            {
                "id": 178,
                "itemName": "English Literature"
            },
            {
                "id": 179,
                "itemName": "English Studies / Études anglaises"
            },
            {
                "id": 180,
                "itemName": "English/Chinese Translation"
            },
            {
                "id": 181,
                "itemName": "Entrepreneurship and Innovation"
            },
            {
                "id": 182,
                "itemName": "Environment and Behaviour"
            },
            {
                "id": 183,
                "itemName": "Environment and Energy"
            },
            {
                "id": 184,
                "itemName": "Environment and Health"
            },
            {
                "id": 185,
                "itemName": "Environment and Toxicology"
            },
            {
                "id": 186,
                "itemName": "Environmental Anthropology"
            },
            {
                "id": 187,
                "itemName": "Environmental Biology"
            },
            {
                "id": 188,
                "itemName": "Environmental Chemistry"
            },
            {
                "id": 189,
                "itemName": "Environmental Design"
            },
            {
                "id": 190,
                "itemName": "Environmental Economics"
            },
            {
                "id": 191,
                "itemName": "Environmental Ethics"
            },
            {
                "id": 192,
                "itemName": "Environmental Geography"
            },
            {
                "id": 193,
                "itemName": "Environmental Geosciences"
            },
            {
                "id": 194,
                "itemName": "Environmental Law and Policy"
            },
            {
                "id": 195,
                "itemName": "Environmental Management"
            },
            {
                "id": 731,
                "itemName": "Environmental Management MSc"
            },
            {
                "id": 196,
                "itemName": "Environmental Physics"
            },
            {
                "id": 197,
                "itemName": "Environmental Science"
            },
            {
                "id": 198,
                "itemName": "Environmental Studies"
            },
            {
                "id": 732,
                "itemName": "Environmental Techncian- Water and Wastewater Systems Operations (compressed/Co-op)"
            },
            {
                "id": 199,
                "itemName": "Environmental Technology - York/Seneca Joint Program"
            },
            {
                "id": 200,
                "itemName": "Estonian Studies"
            },
            {
                "id": 201,
                "itemName": "Ethics, Society and Law"
            },
            {
                "id": 202,
                "itemName": "Ethnomusicology"
            },
            {
                "id": 203,
                "itemName": "European Studies"
            },
            {
                "id": 204,
                "itemName": "European Union Studies"
            },
            {
                "id": 205,
                "itemName": "Exceptionality in Human Learning"
            },
            {
                "id": 206,
                "itemName": "Exercise and Health Physiology"
            },
            {
                "id": 207,
                "itemName": "Experimental Medicine"
            },
            {
                "id": 733,
                "itemName": "Fabricator - Welder"
            },
            {
                "id": 208,
                "itemName": "Film Production"
            },
            {
                "id": 209,
                "itemName": "Film Production and Creative Writing"
            },
            {
                "id": 210,
                "itemName": "Film Studies"
            },
            {
                "id": 211,
                "itemName": "Finance"
            },
            {
                "id": 212,
                "itemName": "Financial & Business Economics"
            },
            {
                "id": 213,
                "itemName": "Financial Economics"
            },
            {
                "id": 214,
                "itemName": "Finnish Studies"
            },
            {
                "id": 734,
                "itemName": "First Certificate in English (FCE)"
            },
            {
                "id": 215,
                "itemName": "Food And Resource Economics"
            },
            {
                "id": 216,
                "itemName": "Food Science"
            },
            {
                "id": 217,
                "itemName": "Food Studies"
            },
            {
                "id": 218,
                "itemName": "Forensic Anthropology"
            },
            {
                "id": 219,
                "itemName": "Forensic Biology"
            },
            {
                "id": 220,
                "itemName": "Forensic Chemistry"
            },
            {
                "id": 221,
                "itemName": "Forensic Psychology"
            },
            {
                "id": 222,
                "itemName": "Forensic Science"
            },
            {
                "id": 223,
                "itemName": "Forest Biomaterials Science"
            },
            {
                "id": 224,
                "itemName": "Forest Conservation"
            },
            {
                "id": 225,
                "itemName": "Forest Conservation Science"
            },
            {
                "id": 226,
                "itemName": "Forestry"
            },
            {
                "id": 227,
                "itemName": "Francophone Studies"
            },
            {
                "id": 228,
                "itemName": "French"
            },
            {
                "id": 229,
                "itemName": "French Studies"
            },
            {
                "id": 230,
                "itemName": "French Studies / Études françaises"
            },
            {
                "id": 735,
                "itemName": "Gas Technician"
            },
            {
                "id": 736,
                "itemName": "Gas Technician 2"
            },
            {
                "id": 231,
                "itemName": "Gender & Women's Studies"
            },
            {
                "id": 232,
                "itemName": "Gender & Women's Studies / Études des femmes et de genre"
            },
            {
                "id": 233,
                "itemName": "Gender, Race, Sexuality and Social Justice"
            },
            {
                "id": 737,
                "itemName": "General Construction Carpentry Techniques"
            },
            {
                "id": 234,
                "itemName": "General Mathematics"
            },
            {
                "id": 235,
                "itemName": "Genetic Counselling"
            },
            {
                "id": 236,
                "itemName": "Genome Biology"
            },
            {
                "id": 237,
                "itemName": "Genome Science and Technology"
            },
            {
                "id": 238,
                "itemName": "Geographic Information Science"
            },
            {
                "id": 239,
                "itemName": "Geographical Information Systems"
            },
            {
                "id": 240,
                "itemName": "Geography"
            },
            {
                "id": 241,
                "itemName": "Geography & Urban Studies"
            },
            {
                "id": 242,
                "itemName": "Geography (Bachelor of Arts)"
            },
            {
                "id": 243,
                "itemName": "Geography (Bachelor of Science)"
            },
            {
                "id": 244,
                "itemName": "Geography (HBA)"
            },
            {
                "id": 245,
                "itemName": "Geography (HBSc)"
            },
            {
                "id": 246,
                "itemName": "Geography: Human"
            },
            {
                "id": 247,
                "itemName": "Geological Engineering"
            },
            {
                "id": 248,
                "itemName": "Geological Sciences"
            },
            {
                "id": 249,
                "itemName": "Geology"
            },
            {
                "id": 250,
                "itemName": "Geomatics Engineering"
            },
            {
                "id": 251,
                "itemName": "Geophysics"
            },
            {
                "id": 252,
                "itemName": "Geoscience"
            },
            {
                "id": 253,
                "itemName": "German"
            },
            {
                "id": 254,
                "itemName": "German Studies"
            },
            {
                "id": 255,
                "itemName": "Germanic Studies"
            },
            {
                "id": 256,
                "itemName": "Global Asia Studies"
            },
            {
                "id": 738,
                "itemName": "Global Fluency Certificate"
            },
            {
                "id": 257,
                "itemName": "Global Food Security"
            },
            {
                "id": 258,
                "itemName": "Global Health"
            },
            {
                "id": 259,
                "itemName": "Global Political Studies"
            },
            {
                "id": 260,
                "itemName": "Global Surgical Care"
            },
            {
                "id": 261,
                "itemName": "Greek"
            },
            {
                "id": 262,
                "itemName": "Greek and Roman Studies"
            },
            {
                "id": 263,
                "itemName": "Guitar"
            },
            {
                "id": 739,
                "itemName": "Hairstyling"
            },
            {
                "id": 740,
                "itemName": "Hairstylist Foundation"
            },
            {
                "id": 264,
                "itemName": "Harpsichord"
            },
            {
                "id": 265,
                "itemName": "Health & Society"
            },
            {
                "id": 266,
                "itemName": "Health Administration"
            },
            {
                "id": 268,
                "itemName": "Health Geography"
            },
            {
                "id": 269,
                "itemName": "Health Humanities"
            },
            {
                "id": 270,
                "itemName": "Health Sciences"
            },
            {
                "id": 271,
                "itemName": "Health Studies"
            },
            {
                "id": 267,
                "itemName": "Health and Society"
            },
            {
                "id": 741,
                "itemName": "Health and Wellness Education Minor"
            },
            {
                "id": 272,
                "itemName": "Health, Outdoor and Physical Education"
            },
            {
                "id": 742,
                "itemName": "Healthcare Management"
            },
            {
                "id": 743,
                "itemName": "Heating"
            },
            {
                "id": 744,
                "itemName": "Heating"
            },
            {
                "id": 745,
                "itemName": "Heating"
            },
            {
                "id": 746,
                "itemName": "Heating"
            },
            {
                "id": 747,
                "itemName": "Heating"
            },
            {
                "id": 748,
                "itemName": "Heating"
            },
            {
                "id": 749,
                "itemName": "Heating"
            },
            {
                "id": 750,
                "itemName": "Heating"
            },
            {
                "id": 751,
                "itemName": "Heating"
            },
            {
                "id": 752,
                "itemName": "Heavy Construction Equipment Operation"
            },
            {
                "id": 753,
                "itemName": "Heavy Construction Equipment Operation"
            },
            {
                "id": 754,
                "itemName": "Heavy Duty Equipment Technician"
            },
            {
                "id": 755,
                "itemName": "Heavy Duty Equipment Technician Foundation"
            },
            {
                "id": 756,
                "itemName": "Heavy Equipment Technician"
            },
            {
                "id": 757,
                "itemName": "Heavy Equipment Techniques (Co-op)"
            },
            {
                "id": 758,
                "itemName": "Heavy Mechanical Technology Diploma (International Cohort)"
            },
            {
                "id": 273,
                "itemName": "Hellenic Studies"
            },
            {
                "id": 274,
                "itemName": "High Performance Buildings"
            },
            {
                "id": 275,
                "itemName": "Higher Education"
            },
            {
                "id": 276,
                "itemName": "Hispanic Studies"
            },
            {
                "id": 277,
                "itemName": "History"
            },
            {
                "id": 278,
                "itemName": "History / Histoire"
            },
            {
                "id": 759,
                "itemName": "History Teaching Minor"
            },
            {
                "id": 279,
                "itemName": "History and Philosophy of Science"
            },
            {
                "id": 280,
                "itemName": "History and Philosophy of Science and Technology"
            },
            {
                "id": 281,
                "itemName": "History of Religions"
            },
            {
                "id": 282,
                "itemName": "Home Economics Education"
            },
            {
                "id": 760,
                "itemName": "Horticultural Industries"
            },
            {
                "id": 283,
                "itemName": "Human Biology"
            },
            {
                "id": 284,
                "itemName": "Human Development, Learning, and Culture"
            },
            {
                "id": 285,
                "itemName": "Human Geography"
            },
            {
                "id": 286,
                "itemName": "Human Nutrition"
            },
            {
                "id": 287,
                "itemName": "Human Resources Management"
            },
            {
                "id": 288,
                "itemName": "Human Rights & Equity Studies"
            },
            {
                "id": 289,
                "itemName": "Humanities"
            },
            {
                "id": 290,
                "itemName": "Hungarian Studies"
            },
            {
                "id": 291,
                "itemName": "Immunology"
            },
            {
                "id": 292,
                "itemName": "Immunology (Interdepartmental Honours program in Immunology (IHI))"
            },
            {
                "id": 293,
                "itemName": "Indigenous Studies"
            },
            {
                "id": 294,
                "itemName": "Individualized Studies"
            },
            {
                "id": 296,
                "itemName": "Industrial Engineering"
            },
            {
                "id": 761,
                "itemName": "Industrial Mechanic (Millwright)"
            },
            {
                "id": 762,
                "itemName": "Industrial Mechanic (Millwright) Foundation"
            },
            {
                "id": 763,
                "itemName": "Industrial Mechanical Millwright Technician"
            },
            {
                "id": 297,
                "itemName": "Industrial Relations and Human Resources"
            },
            {
                "id": 295,
                "itemName": "Industrial and Labour Relations"
            },
            {
                "id": 298,
                "itemName": "Information Security"
            },
            {
                "id": 299,
                "itemName": "Information Systems"
            },
            {
                "id": 300,
                "itemName": "Information Systems - Digital Innovation"
            },
            {
                "id": 301,
                "itemName": "Information Systems - IT for Business"
            },
            {
                "id": 764,
                "itemName": "Information Systems Business Analysis"
            },
            {
                "id": 302,
                "itemName": "Information Technology"
            },
            {
                "id": 765,
                "itemName": "Instrumentation & Control Engineering Technican"
            },
            {
                "id": 766,
                "itemName": "Instrumentation & Control Technician"
            },
            {
                "id": 303,
                "itemName": "Integrated Science"
            },
            {
                "id": 304,
                "itemName": "Integrated Studies In Land and Food Systems"
            },
            {
                "id": 305,
                "itemName": "Integrated Water Management"
            },
            {
                "id": 306,
                "itemName": "Integrative Biology"
            },
            {
                "id": 307,
                "itemName": "Interdisciplinary Life Sciences"
            },
            {
                "id": 308,
                "itemName": "Interdisciplinary Oncology"
            },
            {
                "id": 309,
                "itemName": "Interdisciplinary Social Science"
            },
            {
                "id": 310,
                "itemName": "Interdisciplinary Studies"
            },
            {
                "id": 767,
                "itemName": "Intermediate Child Focused Systemic Practice PgCert"
            },
            {
                "id": 768,
                "itemName": "Intermediate Systemic Practice with Families and Couples PgCert"
            },
            {
                "id": 311,
                "itemName": "International Affairs"
            },
            {
                "id": 312,
                "itemName": "International Agriculture"
            },
            {
                "id": 313,
                "itemName": "International Business"
            },
            {
                "id": 314,
                "itemName": "International Business Strategy"
            },
            {
                "id": 315,
                "itemName": "International Development - York/Humber Joint Program"
            },
            {
                "id": 316,
                "itemName": "International Development Studies"
            },
            {
                "id": 769,
                "itemName": "International Finance and Banking BSc (Hons)"
            },
            {
                "id": 317,
                "itemName": "International Indigenous Studies"
            },
            {
                "id": 318,
                "itemName": "International Management"
            },
            {
                "id": 319,
                "itemName": "International Relations"
            },
            {
                "id": 320,
                "itemName": "International Studies & Business Administration / Études internationales et administration des affaires"
            },
            {
                "id": 321,
                "itemName": "International Studies / Études internationales"
            },
            {
                "id": 322,
                "itemName": "Investment Management"
            },
            {
                "id": 323,
                "itemName": "Islamic Studies"
            },
            {
                "id": 324,
                "itemName": "Italian"
            },
            {
                "id": 325,
                "itemName": "Italian Studies"
            },
            {
                "id": 326,
                "itemName": "Jewish Studies"
            },
            {
                "id": 327,
                "itemName": "Journalism"
            },
            {
                "id": 328,
                "itemName": "Kinesiology"
            },
            {
                "id": 329,
                "itemName": "Kinesiology & Health Science"
            },
            {
                "id": 330,
                "itemName": "Kinesiology for Science Students"
            },
            {
                "id": 331,
                "itemName": "Labour-Management Relations and Human Resources"
            },
            {
                "id": 333,
                "itemName": "Land Surface Processes and Environmental Change"
            },
            {
                "id": 332,
                "itemName": "Land and Water Systems"
            },
            {
                "id": 334,
                "itemName": "Landscape Architecture"
            },
            {
                "id": 770,
                "itemName": "Language And Literacy"
            },
            {
                "id": 336,
                "itemName": "Language Teaching and Learning"
            },
            {
                "id": 335,
                "itemName": "Language and Literacy Education"
            },
            {
                "id": 337,
                "itemName": "Latin"
            },
            {
                "id": 338,
                "itemName": "Latin American & Caribbean Studies"
            },
            {
                "id": 339,
                "itemName": "Latin American Studies"
            },
            {
                "id": 340,
                "itemName": "Latin-American and Caribbean Studies"
            },
            {
                "id": 341,
                "itemName": "Law"
            },
            {
                "id": 343,
                "itemName": "Law & Society"
            },
            {
                "id": 342,
                "itemName": "Law - Juris Doctor"
            },
            {
                "id": 344,
                "itemName": "Law and Society"
            },
            {
                "id": 345,
                "itemName": "Leadership in Pedagogy and Coaching"
            },
            {
                "id": 346,
                "itemName": "Liberal Arts"
            },
            {
                "id": 347,
                "itemName": "Library and Information Studies"
            },
            {
                "id": 348,
                "itemName": "Library, Archival and Information Studies"
            },
            {
                "id": 349,
                "itemName": "Life Sciences"
            },
            {
                "id": 350,
                "itemName": "Life Sciences (Multidisciplinary)"
            },
            {
                "id": 351,
                "itemName": "Linguistics"
            },
            {
                "id": 352,
                "itemName": "Linguistics & Language Studies / Linguistique et sciences du langage"
            },
            {
                "id": 353,
                "itemName": "Linguistics and Language"
            },
            {
                "id": 354,
                "itemName": "Literacy Education"
            },
            {
                "id": 355,
                "itemName": "Literature and Critical Theory"
            },
            {
                "id": 356,
                "itemName": "Literature and Film Studies"
            },
            {
                "id": 357,
                "itemName": "Littératures de langue française, de traduction et de création (Enrichie)"
            },
            {
                "id": 360,
                "itemName": "Littératures de langue française, de traduction et de création (Spécialisation enriché)"
            },
            {
                "id": 361,
                "itemName": "Littératures de langue française, de traduction et de création (Traduction)"
            },
            {
                "id": 359,
                "itemName": "Littératures de langue française, de traduction et de création (option Langue française)"
            },
            {
                "id": 358,
                "itemName": "Littératures de langue française, de traduction et de création (Études et Pratiques Littéraire)"
            },
            {
                "id": 771,
                "itemName": "Machinist"
            },
            {
                "id": 772,
                "itemName": "Machinist"
            },
            {
                "id": 362,
                "itemName": "Management"
            },
            {
                "id": 363,
                "itemName": "Management (For Non-Management Students)"
            },
            {
                "id": 364,
                "itemName": "Management Information Systems"
            },
            {
                "id": 365,
                "itemName": "Management Science"
            },
            {
                "id": 366,
                "itemName": "Managing for Sustainability"
            },
            {
                "id": 773,
                "itemName": "Manufacturing Engineering Technician"
            },
            {
                "id": 774,
                "itemName": "Manufacturing Technician"
            },
            {
                "id": 367,
                "itemName": "Marketing"
            },
            {
                "id": 368,
                "itemName": "Marketing (For Non-Management Students)"
            },
            {
                "id": 775,
                "itemName": "Master In Teaching (Bellingham)"
            },
            {
                "id": 776,
                "itemName": "Master In Teaching (Everett)"
            },
            {
                "id": 777,
                "itemName": "Master Of Music"
            },
            {
                "id": 778,
                "itemName": "Master of Physical Education (Secondary) (with QTS) MPhysEd"
            },
            {
                "id": 779,
                "itemName": "Master of Physical Education (Secondary) (with QTS) MPhysEd"
            },
            {
                "id": 369,
                "itemName": "Material Culture"
            },
            {
                "id": 370,
                "itemName": "Materials Engineering"
            },
            {
                "id": 371,
                "itemName": "Materials Engineering (Co-op & Minor)"
            },
            {
                "id": 372,
                "itemName": "Materials Science"
            },
            {
                "id": 373,
                "itemName": "Mathematical Applications in Economics and Finance"
            },
            {
                "id": 374,
                "itemName": "Mathematical Biology"
            },
            {
                "id": 375,
                "itemName": "Mathematical Sciences"
            },
            {
                "id": 376,
                "itemName": "Mathematics"
            },
            {
                "id": 377,
                "itemName": "Mathematics (Desautels Faculty of Management)"
            },
            {
                "id": 378,
                "itemName": "Mathematics (Faculty of Arts)"
            },
            {
                "id": 379,
                "itemName": "Mathematics (Faculty of Engineering)"
            },
            {
                "id": 380,
                "itemName": "Mathematics (Faculty of Science)"
            },
            {
                "id": 381,
                "itemName": "Mathematics (Probability and Statistics - Faculty of Arts)"
            },
            {
                "id": 382,
                "itemName": "Mathematics (Probability and Statistics - Faculty of Science)"
            },
            {
                "id": 383,
                "itemName": "Mathematics / Mathématiques"
            },
            {
                "id": 390,
                "itemName": "Mathematics Education"
            },
            {
                "id": 780,
                "itemName": "Mathematics Teaching Minor"
            },
            {
                "id": 384,
                "itemName": "Mathematics and Computer Science (Faculty of Arts)"
            },
            {
                "id": 385,
                "itemName": "Mathematics and Computer Science (Faculty of Science)"
            },
            {
                "id": 386,
                "itemName": "Mathematics and Its Applications"
            },
            {
                "id": 387,
                "itemName": "Mathematics and Its Applications: Physical Science"
            },
            {
                "id": 388,
                "itemName": "Mathematics and Philosophy"
            },
            {
                "id": 389,
                "itemName": "Mathematics and Physics"
            },
            {
                "id": 391,
                "itemName": "Mathematics for Education"
            },
            {
                "id": 392,
                "itemName": "Mathematics, Applied (Faculty of Arts)"
            },
            {
                "id": 393,
                "itemName": "Measurement, Evaluation and Research Methodology"
            },
            {
                "id": 394,
                "itemName": "Mechanical Engineering"
            },
            {
                "id": 395,
                "itemName": "Mechanical Engineering (Aeronautical Engineering Concentration)"
            },
            {
                "id": 396,
                "itemName": "Mechanical Engineering (Design Concentration)"
            },
            {
                "id": 781,
                "itemName": "Mechanical Engineering Technician"
            },
            {
                "id": 782,
                "itemName": "Mechanical Technician - CNC"
            },
            {
                "id": 787,
                "itemName": "Mechanical Technician - Elevating Devices"
            },
            {
                "id": 783,
                "itemName": "Mechanical Technician - General Machinist (Optional Co-op)"
            },
            {
                "id": 788,
                "itemName": "Mechanical Technician - Manufacturing / Machining"
            },
            {
                "id": 789,
                "itemName": "Mechanical Technician - Millwright"
            },
            {
                "id": 784,
                "itemName": "Mechanical Technician - Precision Skills"
            },
            {
                "id": 790,
                "itemName": "Mechanical Technician - Tool Making"
            },
            {
                "id": 785,
                "itemName": "Mechanical Technician - Tool and Die/Tool Maker (Optional Co-op)"
            },
            {
                "id": 786,
                "itemName": "Mechanical Technician -Industrial Milwright (Co-op)"
            },
            {
                "id": 791,
                "itemName": "Mechanical Techniques"
            },
            {
                "id": 792,
                "itemName": "Mechanical Techniques"
            },
            {
                "id": 793,
                "itemName": "Mechanical Techniques - Industrial Maintenance"
            },
            {
                "id": 794,
                "itemName": "Mechanical Techniques - Industrial Millwright"
            },
            {
                "id": 795,
                "itemName": "Mechanical Techniques - Marine Engine Mechanic"
            },
            {
                "id": 796,
                "itemName": "Mechanical Techniques - Plumbing"
            },
            {
                "id": 797,
                "itemName": "Mechanical Techniques - Plumbing"
            },
            {
                "id": 800,
                "itemName": "Mechanical Techniques - Plumbing"
            },
            {
                "id": 801,
                "itemName": "Mechanical Techniques - Plumbing"
            },
            {
                "id": 802,
                "itemName": "Mechanical Techniques - Plumbing"
            },
            {
                "id": 798,
                "itemName": "Mechanical Techniques - Precision Machining and Tooling"
            },
            {
                "id": 799,
                "itemName": "Mechanical Techniques - Small Engine Mechanic"
            },
            {
                "id": 803,
                "itemName": "Mechanical Techniques - Tool and Die Maker"
            },
            {
                "id": 397,
                "itemName": "Mechatronics Design"
            },
            {
                "id": 804,
                "itemName": "Mechnical Techncian -Welder Fitter (compressed)"
            },
            {
                "id": 399,
                "itemName": "Media Arts"
            },
            {
                "id": 400,
                "itemName": "Media Studies"
            },
            {
                "id": 398,
                "itemName": "Media and Technology Studies Education"
            },
            {
                "id": 401,
                "itemName": "Media, Journalism & Digital Cultures"
            },
            {
                "id": 402,
                "itemName": "Mediaeval Studies"
            },
            {
                "id": 403,
                "itemName": "Medical Genetics"
            },
            {
                "id": 404,
                "itemName": "Medical Physics"
            },
            {
                "id": 405,
                "itemName": "Medicine"
            },
            {
                "id": 406,
                "itemName": "Medieval Studies"
            },
            {
                "id": 407,
                "itemName": "Mental Health Studies"
            },
            {
                "id": 805,
                "itemName": "Metal Fabricator"
            },
            {
                "id": 408,
                "itemName": "Meteorology (Diploma)"
            },
            {
                "id": 409,
                "itemName": "Microbiology and Immunology"
            },
            {
                "id": 410,
                "itemName": "Microbiology and Molecular Biotechnology"
            },
            {
                "id": 411,
                "itemName": "Mind Sciences in Kinesiology"
            },
            {
                "id": 412,
                "itemName": "Mineral Engineering"
            },
            {
                "id": 413,
                "itemName": "Mining Engineering"
            },
            {
                "id": 414,
                "itemName": "Mining Engineering (Co-Op)"
            },
            {
                "id": 806,
                "itemName": "Mining Engineering Techncian"
            },
            {
                "id": 807,
                "itemName": "Mobile Applications Development"
            },
            {
                "id": 415,
                "itemName": "Modern Languages Education"
            },
            {
                "id": 416,
                "itemName": "Molecular Biology"
            },
            {
                "id": 417,
                "itemName": "Molecular Biology and Biotechnology"
            },
            {
                "id": 418,
                "itemName": "Molecular Genetics and Microbiology"
            },
            {
                "id": 808,
                "itemName": "Motive Power Fundamentals - Automotive Repair"
            },
            {
                "id": 809,
                "itemName": "Motive Power Fundamentals - Parts & Counter Personnel"
            },
            {
                "id": 810,
                "itemName": "Motive Power Techician - Automotive (compressed)"
            },
            {
                "id": 811,
                "itemName": "Motive Power Techician - Heavy Equipment (Co-op)"
            },
            {
                "id": 812,
                "itemName": "Motive Power Technician"
            },
            {
                "id": 813,
                "itemName": "Motive Power Technician"
            },
            {
                "id": 814,
                "itemName": "Motive Power Technician"
            },
            {
                "id": 815,
                "itemName": "Motive Power Technician - Automotive Service"
            },
            {
                "id": 816,
                "itemName": "Motive Power Technician - Heavy Duty Equipment (Optional Co-op)"
            },
            {
                "id": 818,
                "itemName": "Motive Power Technician - Service & Management"
            },
            {
                "id": 819,
                "itemName": "Motive Power Technician - Service and Management"
            },
            {
                "id": 817,
                "itemName": "Motive Power Technician - Truck and Coach"
            },
            {
                "id": 820,
                "itemName": "Motive Power Technician - Truck and Coach"
            },
            {
                "id": 821,
                "itemName": "Motive Power Techniques - Automotive"
            },
            {
                "id": 822,
                "itemName": "Motive Power Techniques - Heavy Duty Equipment Repair"
            },
            {
                "id": 823,
                "itemName": "Motive Power Techniques - Heavy Equipment"
            },
            {
                "id": 824,
                "itemName": "Motive Power Techniques - Motorcycle and Power Sport Vehicles Repair"
            },
            {
                "id": 825,
                "itemName": "Motive Power Techniques - Truck and Coach Repair"
            },
            {
                "id": 419,
                "itemName": "Multidisciplinary Studies"
            },
            {
                "id": 420,
                "itemName": "Museum Education"
            },
            {
                "id": 421,
                "itemName": "Music"
            },
            {
                "id": 422,
                "itemName": "Music (Arts program)"
            },
            {
                "id": 423,
                "itemName": "Music (Bachelor of Arts)"
            },
            {
                "id": 424,
                "itemName": "Music (Bachelor of Music)"
            },
            {
                "id": 425,
                "itemName": "Music (MusBac; MusBacPerf)"
            },
            {
                "id": 427,
                "itemName": "Music Education"
            },
            {
                "id": 428,
                "itemName": "Music Education (Minor)"
            },
            {
                "id": 429,
                "itemName": "Music Entrepreneurship"
            },
            {
                "id": 430,
                "itemName": "Music Faculty Program"
            },
            {
                "id": 431,
                "itemName": "Music Faculty Program (Jazz)"
            },
            {
                "id": 432,
                "itemName": "Music History"
            },
            {
                "id": 433,
                "itemName": "Music History and Culture"
            },
            {
                "id": 434,
                "itemName": "Music Performance (Early Music - Instruments)"
            },
            {
                "id": 435,
                "itemName": "Music Performance (Early Music - Voice)"
            },
            {
                "id": 436,
                "itemName": "Music Performance (Jazz)"
            },
            {
                "id": 437,
                "itemName": "Music Performance (Licentiate in Music)"
            },
            {
                "id": 438,
                "itemName": "Music Performance (Orchestral Instruments)"
            },
            {
                "id": 439,
                "itemName": "Music Performance (Organ, Harpsichord, Guitar)"
            },
            {
                "id": 440,
                "itemName": "Music Performance (Piano)"
            },
            {
                "id": 441,
                "itemName": "Music Performance (Voice)"
            },
            {
                "id": 826,
                "itemName": "Music Technology FD"
            },
            {
                "id": 442,
                "itemName": "Music Theory"
            },
            {
                "id": 426,
                "itemName": "Music and Culture"
            },
            {
                "id": 443,
                "itemName": "Music: Composition"
            },
            {
                "id": 444,
                "itemName": "Musical Applications of Technology"
            },
            {
                "id": 445,
                "itemName": "Musical Science and Technology"
            },
            {
                "id": 446,
                "itemName": "Musicology"
            },
            {
                "id": 447,
                "itemName": "Nanoscience"
            },
            {
                "id": 448,
                "itemName": "Nanotechnology"
            },
            {
                "id": 449,
                "itemName": "Natural History"
            },
            {
                "id": 450,
                "itemName": "Natural Sciences"
            },
            {
                "id": 451,
                "itemName": "Naval Architecture and Marine Engineering"
            },
            {
                "id": 452,
                "itemName": "Near and Middle Eastern Civilizations"
            },
            {
                "id": 453,
                "itemName": "Neuroscience"
            },
            {
                "id": 454,
                "itemName": "New Media Studies (Joint Program with Centennial College)"
            },
            {
                "id": 827,
                "itemName": "Northwest Electric Energy Systems Certificate"
            },
            {
                "id": 455,
                "itemName": "Nursing"
            },
            {
                "id": 457,
                "itemName": "Nursing (Integrated)"
            },
            {
                "id": 456,
                "itemName": "Nursing - Nurse Practitioner"
            },
            {
                "id": 458,
                "itemName": "Nursing: Collaborative (York-Seneca-Georgian)"
            },
            {
                "id": 459,
                "itemName": "Nursing: Post-RN for Internationally Educated Nurses"
            },
            {
                "id": 460,
                "itemName": "Nursing: Second Entry"
            },
            {
                "id": 461,
                "itemName": "Nutrition"
            },
            {
                "id": 462,
                "itemName": "Nutritional Sciences"
            },
            {
                "id": 464,
                "itemName": "Occupational Therapy"
            },
            {
                "id": 463,
                "itemName": "Occupational and Environmental Hygiene"
            },
            {
                "id": 465,
                "itemName": "Oceanography"
            },
            {
                "id": 466,
                "itemName": "Oceans and Fisheries"
            },
            {
                "id": 467,
                "itemName": "Oil and Gas Engineering"
            },
            {
                "id": 468,
                "itemName": "Opera"
            },
            {
                "id": 469,
                "itemName": "Operations Management"
            },
            {
                "id": 470,
                "itemName": "Operations Management (For Non-Management Students)"
            },
            {
                "id": 471,
                "itemName": "Orchestral Conducting"
            },
            {
                "id": 472,
                "itemName": "Orchestral Instrument"
            },
            {
                "id": 473,
                "itemName": "Organ"
            },
            {
                "id": 474,
                "itemName": "Organizational Behavior"
            },
            {
                "id": 475,
                "itemName": "Organizational Behaviour"
            },
            {
                "id": 476,
                "itemName": "Organizational Behaviour and Human Resources"
            },
            {
                "id": 477,
                "itemName": "Orthodontics"
            },
            {
                "id": 828,
                "itemName": "PGCE Early Years Birth to 5 (with EYTS) Employment Pathway PGCE"
            },
            {
                "id": 478,
                "itemName": "Paleontology"
            },
            {
                "id": 479,
                "itemName": "Paramedicine (Joint Program with Centennial College)"
            },
            {
                "id": 480,
                "itemName": "Pathobiology"
            },
            {
                "id": 481,
                "itemName": "Pathology and Laboratory Medicine"
            },
            {
                "id": 482,
                "itemName": "Peace, Conflict and Justice"
            },
            {
                "id": 483,
                "itemName": "Pediatric Dentistry"
            },
            {
                "id": 484,
                "itemName": "Periodontics"
            },
            {
                "id": 485,
                "itemName": "Persian Language"
            },
            {
                "id": 486,
                "itemName": "Personal Financial Planning"
            },
            {
                "id": 487,
                "itemName": "Pharmaceutical Chemistry"
            },
            {
                "id": 488,
                "itemName": "Pharmaceutical Sciences"
            },
            {
                "id": 489,
                "itemName": "Pharmacology"
            },
            {
                "id": 490,
                "itemName": "Pharmacology and Biomedical Toxicology"
            },
            {
                "id": 491,
                "itemName": "Philosophy"
            },
            {
                "id": 492,
                "itemName": "Philosophy / Philosophie"
            },
            {
                "id": 495,
                "itemName": "Physical Sciences"
            },
            {
                "id": 496,
                "itemName": "Physical Therapy"
            },
            {
                "id": 493,
                "itemName": "Physical and Environmental Geography"
            },
            {
                "id": 494,
                "itemName": "Physical and Mathematical Sciences"
            },
            {
                "id": 497,
                "itemName": "Physics"
            },
            {
                "id": 498,
                "itemName": "Physics & Astronomy"
            },
            {
                "id": 499,
                "itemName": "Physics (Biological Physics)"
            },
            {
                "id": 500,
                "itemName": "Physics (Faculty of Science)"
            },
            {
                "id": 501,
                "itemName": "Physics (Minor, Faculty of Engineering)"
            },
            {
                "id": 502,
                "itemName": "Physics and Astrophysics"
            },
            {
                "id": 503,
                "itemName": "Physics and Chemistry (Joint Honours)"
            },
            {
                "id": 504,
                "itemName": "Physics and Computer Science (Joint Major)"
            },
            {
                "id": 505,
                "itemName": "Physics and Geophysics (Joint Major)"
            },
            {
                "id": 506,
                "itemName": "Physics and Philosophy"
            },
            {
                "id": 507,
                "itemName": "Physiology"
            },
            {
                "id": 508,
                "itemName": "Physiology and Mathematics"
            },
            {
                "id": 509,
                "itemName": "Physiology and Physics"
            },
            {
                "id": 510,
                "itemName": "Piano"
            },
            {
                "id": 511,
                "itemName": "Planatery Science"
            },
            {
                "id": 512,
                "itemName": "Planetary Sciences"
            },
            {
                "id": 513,
                "itemName": "Planning"
            },
            {
                "id": 514,
                "itemName": "Plant Biology"
            },
            {
                "id": 515,
                "itemName": "Plant Production"
            },
            {
                "id": 516,
                "itemName": "Plant Science"
            },
            {
                "id": 829,
                "itemName": "Plumber"
            },
            {
                "id": 830,
                "itemName": "Plumbing Technician"
            },
            {
                "id": 831,
                "itemName": "Plumbing Techniques"
            },
            {
                "id": 832,
                "itemName": "Plumbing Techniques"
            },
            {
                "id": 517,
                "itemName": "Political Science"
            },
            {
                "id": 518,
                "itemName": "Political Science / Science politique"
            },
            {
                "id": 519,
                "itemName": "Population and Public Health"
            },
            {
                "id": 520,
                "itemName": "Portuguese"
            },
            {
                "id": 521,
                "itemName": "Portuguese & Luso-Brazilian Studies"
            },
            {
                "id": 833,
                "itemName": "Post Compulsory Education - Professional Graduate Certificate in Education"
            },
            {
                "id": 834,
                "itemName": "Post Compulsory Education - Professional Graduate Certificate in Education"
            },
            {
                "id": 835,
                "itemName": "Post Compulsory Education - Professional Graduate Certificate in Education"
            },
            {
                "id": 836,
                "itemName": "Post Compulsory Education - Professional Graduate Certificate in Education"
            },
            {
                "id": 837,
                "itemName": "Post Compulsory Education - Professional Graduate Certificate in Education"
            },
            {
                "id": 838,
                "itemName": "Power Engineering Fourth Class"
            },
            {
                "id": 839,
                "itemName": "Power Engineering Techniques - Fourth Class - NEW"
            },
            {
                "id": 840,
                "itemName": "Power Engineering Technology"
            },
            {
                "id": 841,
                "itemName": "Power Engineering Technology - Mechanical"
            },
            {
                "id": 842,
                "itemName": "Powerline Technician"
            },
            {
                "id": 843,
                "itemName": "Powerline Technician"
            },
            {
                "id": 844,
                "itemName": "Powerline Technician"
            },
            {
                "id": 845,
                "itemName": "Powerline Technician (Optional Co-op)"
            },
            {
                "id": 846,
                "itemName": "Pre-Service Firefighter Education and Training"
            },
            {
                "id": 847,
                "itemName": "Pre-Trades/Technology"
            },
            {
                "id": 848,
                "itemName": "Precision Metal Machining"
            },
            {
                "id": 849,
                "itemName": "Preparation for Cambridge (CPE) Undergraduate Course"
            },
            {
                "id": 850,
                "itemName": "Preparation for Certificate in Advanced English (CAE)"
            },
            {
                "id": 851,
                "itemName": "Preparation for IELTS"
            },
            {
                "id": 852,
                "itemName": "Primary Education PGCE"
            },
            {
                "id": 853,
                "itemName": "Primary with Mathematics PGCE"
            },
            {
                "id": 854,
                "itemName": "Primary with Physical Education PGCE"
            },
            {
                "id": 855,
                "itemName": "Principal’S Residency Certificate"
            },
            {
                "id": 522,
                "itemName": "Professional Agrology"
            },
            {
                "id": 856,
                "itemName": "Professional Social Work Practice PgDip"
            },
            {
                "id": 523,
                "itemName": "Professional Writing"
            },
            {
                "id": 524,
                "itemName": "Professional Writing and Communication"
            },
            {
                "id": 525,
                "itemName": "Prosthodontics"
            },
            {
                "id": 857,
                "itemName": "Protection"
            },
            {
                "id": 858,
                "itemName": "Protection"
            },
            {
                "id": 526,
                "itemName": "Psycholinguistics"
            },
            {
                "id": 527,
                "itemName": "Psychology"
            },
            {
                "id": 528,
                "itemName": "Psychology (Bachelor of Arts)"
            },
            {
                "id": 529,
                "itemName": "Psychology (Bachelor of Science)"
            },
            {
                "id": 530,
                "itemName": "Psychology (Faculty of Arts)"
            },
            {
                "id": 531,
                "itemName": "Psychology (Faculty of Science)"
            },
            {
                "id": 532,
                "itemName": "Psychology / Psychologie"
            },
            {
                "id": 533,
                "itemName": "Public Administration"
            },
            {
                "id": 534,
                "itemName": "Public Law"
            },
            {
                "id": 535,
                "itemName": "Public Policy"
            },
            {
                "id": 536,
                "itemName": "Public Policy and Global Affairs"
            },
            {
                "id": 537,
                "itemName": "Quebec Studies/Études sur le Québec"
            },
            {
                "id": 859,
                "itemName": "Real Estate"
            },
            {
                "id": 860,
                "itemName": "Real Estate"
            },
            {
                "id": 538,
                "itemName": "Real Estate Studies"
            },
            {
                "id": 861,
                "itemName": "Refrigeration And Air Conditioning Mechanic - Heating"
            },
            {
                "id": 862,
                "itemName": "Refrigeration and Air Conditioning Mechanic "
            },
            {
                "id": 539,
                "itemName": "Rehabilitation Sciences"
            },
            {
                "id": 540,
                "itemName": "Religion"
            },
            {
                "id": 541,
                "itemName": "Religion and Globalization"
            },
            {
                "id": 542,
                "itemName": "Religious Studies"
            },
            {
                "id": 543,
                "itemName": "Religious Studies (Asian Religions)"
            },
            {
                "id": 544,
                "itemName": "Religious Studies (Bachelor of Theology)"
            },
            {
                "id": 545,
                "itemName": "Religious Studies (Western Religions)"
            },
            {
                "id": 546,
                "itemName": "Religious Studies (World Religions)"
            },
            {
                "id": 547,
                "itemName": "Renaissance Studies"
            },
            {
                "id": 548,
                "itemName": "Renewable Resource Management"
            },
            {
                "id": 549,
                "itemName": "Reproductive and Developmental Sciences"
            },
            {
                "id": 550,
                "itemName": "Resources, Environment and Sustainability"
            },
            {
                "id": 551,
                "itemName": "Risk Management and Insurance"
            },
            {
                "id": 552,
                "itemName": "Risk Management: Insurance and Finance"
            },
            {
                "id": 553,
                "itemName": "Rotman Commerce (BCom)"
            },
            {
                "id": 554,
                "itemName": "Russian"
            },
            {
                "id": 555,
                "itemName": "Russian Culture"
            },
            {
                "id": 863,
                "itemName": "School Counseling"
            },
            {
                "id": 556,
                "itemName": "School and Applied Child Psychology"
            },
            {
                "id": 559,
                "itemName": "Science Education"
            },
            {
                "id": 557,
                "itemName": "Science and Society"
            },
            {
                "id": 558,
                "itemName": "Science and Technology Studies"
            },
            {
                "id": 560,
                "itemName": "Science for Arts Students"
            },
            {
                "id": 561,
                "itemName": "Screenwriting"
            },
            {
                "id": 864,
                "itemName": "Secondary Drama PGCE"
            },
            {
                "id": 865,
                "itemName": "Secondary English PGCE"
            },
            {
                "id": 866,
                "itemName": "Secondary Geography PGCE"
            },
            {
                "id": 867,
                "itemName": "Secondary History PGCE"
            },
            {
                "id": 868,
                "itemName": "Secondary Maths PGCE"
            },
            {
                "id": 869,
                "itemName": "Secondary Music PGCE"
            },
            {
                "id": 870,
                "itemName": "Secondary Physical Education PGCE"
            },
            {
                "id": 871,
                "itemName": "Secondary Religious Education PGCE"
            },
            {
                "id": 562,
                "itemName": "Semiotics and Communications Studies"
            },
            {
                "id": 563,
                "itemName": "Seniors Care"
            },
            {
                "id": 564,
                "itemName": "Sexual Diversity Studies"
            },
            {
                "id": 565,
                "itemName": "Sexuality Studies"
            },
            {
                "id": 566,
                "itemName": "Sexuality Studies / Études sur la sexualité"
            },
            {
                "id": 567,
                "itemName": "Slavic Languages and Cultures"
            },
            {
                "id": 568,
                "itemName": "Social Entrepreneurship (Faculty of Arts)"
            },
            {
                "id": 569,
                "itemName": "Social Studies Education"
            },
            {
                "id": 570,
                "itemName": "Social Studies of Medicine"
            },
            {
                "id": 571,
                "itemName": "Social Work"
            },
            {
                "id": 872,
                "itemName": "Social Work Practice"
            },
            {
                "id": 572,
                "itemName": "Society, Culture and Politics in Education"
            },
            {
                "id": 573,
                "itemName": "Sociology"
            },
            {
                "id": 574,
                "itemName": "Sociology / Sociologie"
            },
            {
                "id": 575,
                "itemName": "Software Engineering"
            },
            {
                "id": 576,
                "itemName": "Software Engineering (Faculty of Arts)"
            },
            {
                "id": 577,
                "itemName": "Software Engineering (Faculty of Engineering)"
            },
            {
                "id": 578,
                "itemName": "Software Engineering (Faculty of Science)"
            },
            {
                "id": 579,
                "itemName": "Soil Science"
            },
            {
                "id": 580,
                "itemName": "South Asian Studies"
            },
            {
                "id": 581,
                "itemName": "Space Engineering"
            },
            {
                "id": 582,
                "itemName": "Spanish"
            },
            {
                "id": 583,
                "itemName": "Spanish (Hispanic Studies) / Études hispaniques"
            },
            {
                "id": 584,
                "itemName": "Special Education"
            },
            {
                "id": 585,
                "itemName": "Statistics"
            },
            {
                "id": 586,
                "itemName": "Statistics (Desautels Faculty of Management)"
            },
            {
                "id": 587,
                "itemName": "Statistics (Faculty of Arts)"
            },
            {
                "id": 588,
                "itemName": "Statistics (Faculty of Science)"
            },
            {
                "id": 589,
                "itemName": "Statistics and Computer Science"
            },
            {
                "id": 590,
                "itemName": "Strategic Management"
            },
            {
                "id": 591,
                "itemName": "Strategic Management - Global Strategy"
            },
            {
                "id": 592,
                "itemName": "Strategic Management - Social Business & Enterprise"
            },
            {
                "id": 593,
                "itemName": "Strategy & Business Economics"
            },
            {
                "id": 594,
                "itemName": "Studio"
            },
            {
                "id": 873,
                "itemName": "Superintendent’S Certificate"
            },
            {
                "id": 595,
                "itemName": "Supply Chain Management"
            },
            {
                "id": 596,
                "itemName": "Surgery"
            },
            {
                "id": 597,
                "itemName": "Sustainability"
            },
            {
                "id": 598,
                "itemName": "Sustainability, Science and Society"
            },
            {
                "id": 874,
                "itemName": "Sustainable Construction FD"
            },
            {
                "id": 599,
                "itemName": "Sustainable Process Engineering"
            },
            {
                "id": 600,
                "itemName": "Synthetic and Catalytic Chemistry"
            },
            {
                "id": 875,
                "itemName": "Teaching English (Literacy CPD) in the Lifelong Learning Sector"
            },
            {
                "id": 601,
                "itemName": "Teaching English as a Second Language"
            },
            {
                "id": 876,
                "itemName": "Teaching Mathematics (Numeracy CPD) in the Lifelong Learning Sector"
            },
            {
                "id": 877,
                "itemName": "Teaching Mathematics (Numeracy CPD) in the Lifelong Learning Sector"
            },
            {
                "id": 602,
                "itemName": "Technological Entrepreneurship (Engineering)"
            },
            {
                "id": 603,
                "itemName": "Theatre"
            },
            {
                "id": 604,
                "itemName": "Theatre and Drama Studies (Joint Program with Sheridan College)"
            },
            {
                "id": 605,
                "itemName": "Theatre and Performance Studies"
            },
            {
                "id": 878,
                "itemName": "Timber Framing"
            },
            {
                "id": 606,
                "itemName": "TrackOne, Undeclared Engineering"
            },
            {
                "id": 879,
                "itemName": "Trades Fundamentals"
            },
            {
                "id": 880,
                "itemName": "Trades Fundamentals"
            },
            {
                "id": 881,
                "itemName": "Traditional Chinese Medicine - Acupuncture"
            },
            {
                "id": 607,
                "itemName": "Translation / Traduction"
            },
            {
                "id": 608,
                "itemName": "Transportation & Logistics"
            },
            {
                "id": 609,
                "itemName": "Turkish Language"
            },
            {
                "id": 610,
                "itemName": "Undecided Major"
            },
            {
                "id": 611,
                "itemName": "Undecided Major / Majeure non déterminée"
            },
            {
                "id": 612,
                "itemName": "Undeclared (Arts)"
            },
            {
                "id": 613,
                "itemName": "Undeclared Major"
            },
            {
                "id": 882,
                "itemName": "University Certificate of Continuing Professional Development in Teaching Learners with Additional Needs (Tresham)"
            },
            {
                "id": 614,
                "itemName": "Urban Design"
            },
            {
                "id": 615,
                "itemName": "Urban Land Economics"
            },
            {
                "id": 616,
                "itemName": "Urban Studies"
            },
            {
                "id": 617,
                "itemName": "Urban Sustainability - York/Seneca Joint Program"
            },
            {
                "id": 618,
                "itemName": "Urban Systems"
            },
            {
                "id": 619,
                "itemName": "Urdu Language"
            },
            {
                "id": 620,
                "itemName": "Veterinary Medicine"
            },
            {
                "id": 621,
                "itemName": "Visual Arts"
            },
            {
                "id": 622,
                "itemName": "Visual Culture and Communication"
            },
            {
                "id": 623,
                "itemName": "Visual Studies"
            },
            {
                "id": 624,
                "itemName": "Voice"
            },
            {
                "id": 625,
                "itemName": "Water Environments and Ecosystems"
            },
            {
                "id": 883,
                "itemName": "Welder"
            },
            {
                "id": 884,
                "itemName": "Welder Foundation"
            },
            {
                "id": 885,
                "itemName": "Welding"
            },
            {
                "id": 886,
                "itemName": "Welding"
            },
            {
                "id": 887,
                "itemName": "Welding & Fabrication Technician"
            },
            {
                "id": 888,
                "itemName": "Welding & Fabrication Technician"
            },
            {
                "id": 889,
                "itemName": "Welding & Fabrication Technician Co-op Diploma Apprenticeship"
            },
            {
                "id": 895,
                "itemName": "Welding Engineering Technician (Co-op option available)"
            },
            {
                "id": 896,
                "itemName": "Welding Engineering Technician -Inspection"
            },
            {
                "id": 897,
                "itemName": "Welding Engineering Technician -Inspection (Co-op)"
            },
            {
                "id": 898,
                "itemName": "Welding Engineering Technology"
            },
            {
                "id": 899,
                "itemName": "Welding Engineering Technology (Co-op)"
            },
            {
                "id": 900,
                "itemName": "Welding Techniques"
            },
            {
                "id": 901,
                "itemName": "Welding Techniques"
            },
            {
                "id": 902,
                "itemName": "Welding Techniques"
            },
            {
                "id": 903,
                "itemName": "Welding Techniques"
            },
            {
                "id": 904,
                "itemName": "Welding Techniques"
            },
            {
                "id": 905,
                "itemName": "Welding Techniques"
            },
            {
                "id": 906,
                "itemName": "Welding Techniques"
            },
            {
                "id": 907,
                "itemName": "Welding Techniques"
            },
            {
                "id": 908,
                "itemName": "Welding Techniques"
            },
            {
                "id": 890,
                "itemName": "Welding and Fabrication Technician"
            },
            {
                "id": 891,
                "itemName": "Welding and Fabrication Technician"
            },
            {
                "id": 892,
                "itemName": "Welding and Fabrication Technician"
            },
            {
                "id": 893,
                "itemName": "Welding and Fabrication Technician/Welding Techniques"
            },
            {
                "id": 894,
                "itemName": "Welding and Fabrication Techniques"
            },
            {
                "id": 626,
                "itemName": "Wildlife Biology"
            },
            {
                "id": 627,
                "itemName": "Wind Conducting"
            },
            {
                "id": 628,
                "itemName": "Women and Gender Studies"
            },
            {
                "id": 629,
                "itemName": "Women's Studies"
            },
            {
                "id": 909,
                "itemName": "Woodworking Technician"
            },
            {
                "id": 910,
                "itemName": "Woodworking Technician"
            },
            {
                "id": 911,
                "itemName": "Woodworking Technology (Optional Co-op)"
            },
            {
                "id": 630,
                "itemName": "Work & Labour Studies"
            },
            {
                "id": 631,
                "itemName": "World Cinemas"
            },
            {
                "id": 632,
                "itemName": "World Islamic & Middle East Studies"
            },
            {
                "id": 633,
                "itemName": "Writing and Rhetoric"
            },
            {
                "id": 634,
                "itemName": "Yiddish, Al and Malka Green Program"
            },
            {
                "id": 635,
                "itemName": "Zoology"
            },
            {
                "id": 912,
                "itemName": "Public Relations - Corporate Communications"
            },
            {
                "id": 913,
                "itemName": "Esports Marketing Management"
            },
            {
                "id": 914,
                "itemName": "Supply Chain Management - Global Logistics"
            }
        ];
        return programData;
    }

    country() {
        const countrydata = [
            { "id": 1, "itemName": "Afghanistan" },
            { "id": 2, "itemName": "Albania" },
            { "id": 3, "itemName": "Algeria" },
            { "id": 4, "itemName": "Andorra" },
            { "id": 5, "itemName": "Angola" },
            { "id": 6, "itemName": "Antigua and Barbuda" },
            { "id": 7, "itemName": "Argentina" },
            { "id": 8, "itemName": "Armenia" },
            { "id": 9, "itemName": "Australia" },
            { "id": 10, "itemName": "Austria" },
            { "id": 11, "itemName": "Azerbaijan" },
            { "id": 12, "itemName": "Bahamas" },
            { "id": 13, "itemName": "Bahrain" },
            { "id": 14, "itemName": "Bangladesh" },
            { "id": 15, "itemName": "Barbados" },
            { "id": 16, "itemName": "Belarus" },
            { "id": 17, "itemName": "Belgium" },
            { "id": 18, "itemName": "Belize" },
            { "id": 19, "itemName": "Benin" },
            { "id": 20, "itemName": "Bhutan" },
            { "id": 21, "itemName": "Bolivia" },
            { "id": 22, "itemName": "Bosnia and Herzegovina" },
            { "id": 23, "itemName": "Botswana" },
            { "id": 24, "itemName": "Brazil" },
            { "id": 25, "itemName": "Brunei" },
            { "id": 26, "itemName": "Bulgaria" },
            { "id": 27, "itemName": "Burkina Faso" },
            { "id": 28, "itemName": "Burundi" },
            { "id": 29, "itemName": "Côte d'Ivoire" },
            { "id": 30, "itemName": "Cabo Verde" },
            { "id": 31, "itemName": "Cambodia" },
            { "id": 32, "itemName": "Cameroon" },
            { "id": 33, "itemName": "Canada" },
            { "id": 34, "itemName": "Central African Republic" },
            { "id": 35, "itemName": "Chad" },
            { "id": 36, "itemName": "Chile" },
            { "id": 37, "itemName": "Colombia" },
            { "id": 38, "itemName": "Comoros" },
            { "id": 39, "itemName": "Congo (Congo-Brazzaville)" },
            { "id": 40, "itemName": "Costa Rica" },
            { "id": 41, "itemName": "Croatia" },
            { "id": 42, "itemName": "Cuba" },
            { "id": 43, "itemName": "Cyprus" },
            { "id": 44, "itemName": "Czechia (Czech Republic)" },
            { "id": 45, "itemName": "Democratic Republic of the Congo" },
            { "id": 46, "itemName": "Denmark" },
            { "id": 47, "itemName": "Djibouti" },
            { "id": 48, "itemName": "Dominica" },
            { "id": 49, "itemName": "Dominican Republic" },
            { "id": 50, "itemName": "Ecuador" },
            { "id": 51, "itemName": "Egypt" },
            { "id": 52, "itemName": "El Salvador" },
            { "id": 53, "itemName": "Equatorial Guinea" },
            { "id": 54, "itemName": "Eritrea" },
            { "id": 55, "itemName": "Estonia" },
            { "id": 56, "itemName": "Eswatini (fmr. 'Swaziland')" },
            { "id": 57, "itemName": "Ethiopia" },
            { "id": 58, "itemName": "Fiji" },
            { "id": 59, "itemName": "Finland" },
            { "id": 60, "itemName": "France" },
            { "id": 61, "itemName": "Gabon" },
            { "id": 62, "itemName": "Gambia" },
            { "id": 63, "itemName": "Georgia" },
            { "id": 64, "itemName": "Germany" },
            { "id": 65, "itemName": "Ghana" },
            { "id": 66, "itemName": "Greece" },
            { "id": 67, "itemName": "Grenada" },
            { "id": 68, "itemName": "Guatemala" },
            { "id": 69, "itemName": "Guinea" },
            { "id": 70, "itemName": "Guinea-Bissau" },
            { "id": 71, "itemName": "Guyana" },
            { "id": 72, "itemName": "Haiti" },
            { "id": 73, "itemName": "Holy See" },
            { "id": 74, "itemName": "Honduras" },
            { "id": 75, "itemName": "Hungary" },
            { "id": 76, "itemName": "Iceland" },
            { "id": 77, "itemName": "India" },
            { "id": 78, "itemName": "Indonesia" },
            { "id": 79, "itemName": "Iran" },
            { "id": 80, "itemName": "Iraq" },
            { "id": 81, "itemName": "Ireland" },
            { "id": 82, "itemName": "Israel" },
            { "id": 83, "itemName": "Italy" },
            { "id": 84, "itemName": "Jamaica" },
            { "id": 85, "itemName": "Japan" },
            { "id": 86, "itemName": "Jordan" },
            { "id": 87, "itemName": "Kazakhstan" },
            { "id": 88, "itemName": "Kenya" },
            { "id": 89, "itemName": "Kiribati" },
            { "id": 90, "itemName": "Kuwait" },
            { "id": 91, "itemName": "Kyrgyzstan" },
            { "id": 92, "itemName": "Laos" },
            { "id": 93, "itemName": "Latvia" },
            { "id": 94, "itemName": "Lebanon" },
            { "id": 95, "itemName": "Lesotho" },
            { "id": 96, "itemName": "Liberia" },
            { "id": 97, "itemName": "Libya" },
            { "id": 98, "itemName": "Liechtenstein" },
            { "id": 99, "itemName": "Lithuania" },
            { "id": 100, "itemName": "Luxembourg" },
            { "id": 101, "itemName": "Madagascar" },
            { "id": 102, "itemName": "Malawi" },
            { "id": 103, "itemName": "Malaysia" },
            { "id": 104, "itemName": "Maldives" },
            { "id": 105, "itemName": "Mali" },
            { "id": 106, "itemName": "Malta" },
            { "id": 107, "itemName": "Marshall Islands" },
            { "id": 108, "itemName": "Mauritania" },
            { "id": 109, "itemName": "Mauritius" },
            { "id": 110, "itemName": "Mexico" },
            { "id": 111, "itemName": "Micronesia" },
            { "id": 112, "itemName": "Moldova" },
            { "id": 113, "itemName": "Monaco" },
            { "id": 114, "itemName": "Mongolia" },
            { "id": 115, "itemName": "Montenegro" },
            { "id": 116, "itemName": "Morocco" },
            { "id": 117, "itemName": "Mozambique" },
            { "id": 118, "itemName": "Myanmar (formerly Burma)" },
            { "id": 119, "itemName": "Namibia" },
            { "id": 120, "itemName": "Nauru" },
            { "id": 121, "itemName": "Nepal" },
            { "id": 122, "itemName": "Netherlands" },
            { "id": 123, "itemName": "New Zealand" },
            { "id": 124, "itemName": "Nicaragua" },
            { "id": 125, "itemName": "Niger" },
            { "id": 126, "itemName": "Nigeria" },
            { "id": 127, "itemName": "North Korea" },
            { "id": 128, "itemName": "North Macedonia" },
            { "id": 129, "itemName": "Norway" },
            { "id": 130, "itemName": "Oman" },
            { "id": 131, "itemName": "Pakistan" },
            { "id": 132, "itemName": "Palau" },
            { "id": 133, "itemName": "Palestine State" },
            { "id": 134, "itemName": "Panama" },
            { "id": 135, "itemName": "Papua New Guinea" },
            { "id": 136, "itemName": "Paraguay" },
            { "id": 137, "itemName": "Peru" },
            { "id": 138, "itemName": "Philippines" },
            { "id": 139, "itemName": "Poland" },
            { "id": 140, "itemName": "Portugal" },
            { "id": 141, "itemName": "Qatar" },
            { "id": 142, "itemName": "Romania" },
            { "id": 143, "itemName": "Russia" },
            { "id": 144, "itemName": "Rwanda" },
            { "id": 145, "itemName": "Saint Kitts and Nevis" },
            { "id": 146, "itemName": "Saint Lucia" },
            { "id": 147, "itemName": "Saint Vincent and the Grenadines" },
            { "id": 148, "itemName": "Samoa" },
            { "id": 149, "itemName": "San Marino" },
            { "id": 150, "itemName": "Sao Tome and Principe" },
            { "id": 151, "itemName": "Saudi Arabia" },
            { "id": 152, "itemName": "Senegal" },
            { "id": 153, "itemName": "Serbia" },
            { "id": 154, "itemName": "Seychelles" },
            { "id": 155, "itemName": "Sierra Leone" },
            { "id": 156, "itemName": "Singapore" },
            { "id": 157, "itemName": "Slovakia" },
            { "id": 158, "itemName": "Slovenia" },
            { "id": 159, "itemName": "Solomon Islands" },
            { "id": 160, "itemName": "Somalia" },
            { "id": 161, "itemName": "South Africa" },
            { "id": 162, "itemName": "South Korea" },
            { "id": 163, "itemName": "South Sudan" },
            { "id": 164, "itemName": "Spain" },
            { "id": 165, "itemName": "Sri Lanka" },
            { "id": 166, "itemName": "Sudan" },
            { "id": 167, "itemName": "Suriname" },
            { "id": 168, "itemName": "Sweden" },
            { "id": 169, "itemName": "Switzerland" },
            { "id": 170, "itemName": "Syria" },
            { "id": 171, "itemName": "Tajikistan" },
            { "id": 172, "itemName": "Tanzania" },
            { "id": 173, "itemName": "Thailand" },
            { "id": 174, "itemName": "Timor-Leste" },
            { "id": 175, "itemName": "Togo" },
            { "id": 176, "itemName": "Tonga" },
            { "id": 177, "itemName": "Trinidad and Tobago" },
            { "id": 178, "itemName": "Tunisia" },
            { "id": 179, "itemName": "Turkey" },
            { "id": 180, "itemName": "Turkmenistan" },
            { "id": 181, "itemName": "Tuvalu" },
            { "id": 182, "itemName": "Uganda" },
            { "id": 183, "itemName": "Ukraine" },
            { "id": 184, "itemName": "United Arab Emirates" },
            { "id": 185, "itemName": "United Kingdom" },
            { "id": 186, "itemName": "United States of America" },
            { "id": 187, "itemName": "Uruguay" },
            { "id": 188, "itemName": "Uzbekistan" },
            { "id": 189, "itemName": "Vanuatu" },
            { "id": 190, "itemName": "Venezuela" },
            { "id": 191, "itemName": "Vietnam" },
            { "id": 192, "itemName": "Yemen" },
            { "id": 193, "itemName": "Zambia" },
            { "id": 194, "itemName": "Zimbabwe" }
        ];
        return countrydata;
    }

    degree() {
        const degreeList = [
            { "id": 1, "itemName": "Certificate", value: "Certificate (<= 2 semesters / 1 year)" },
            { "id": 2, "itemName": "Diploma", value: "Diploma (at least 4 semesters / 2 years)" },
            { "id": 3, "itemName": "Associate Degree", value: "Associate Degree (4 semesters / 2 years)" },
            { "id": 4, "itemName": "Bachelor's/First", value: "Bachelor's/First (8 semesters / 4 years)" },
            { "id": 5, "itemName": "Master's", value: "Master's (2-4 semesters / 1-2 years)" },
            { "id": 6, "itemName": "Graduate Certificate", value: "Graduate Certificate (2 Semesters / 1 year)" }
        ];
        return degreeList;
    }

    interested() {
        const interestedList = [
            { "id": 1, "itemName": "Universities" },
            { "id": 2, "itemName": "Colleges" },
            { "id": 3, "itemName": "Universities And Colleges" }
        ];
        return interestedList;
    }

    studyType() {
        const studyTypeList = [
            { "id": 1, "itemName": "High School" },
            { "id": 2, "itemName": "College" },
            { "id": 3, "itemName": "University" },
        ];
        return studyTypeList;
    }

    canadaCity() {
        const canadaCity = [
            "Abbotsford",
            "Airdrie",
            "Ajax",
            "Aurora",
            "Barrie",
            "Belleville",
            "Blainville",
            "Brampton",
            "Brantford",
            "Brossard",
            "Buffalo",
            "Burlington",
            "Burnaby",
            "Caledon",
            "Calgary",
            "Cambridge",
            "Cape Breton",
            "Chatham - Kent",
            "Chilliwack",
            "Clarington",
            "Coquitlam",
            "Delta",
            "Drummondville",
            "Edmonton",
            "Fredericton",
            "Gatineau",
            "Granby",
            "Grande",
            "Guelph",
            "Halifax",
            "Halton Hills",
            "Hamilton",
            "Kamloops",
            "Kawartha Lakes",
            "Kelowna",
            "Kingston",
            "Kitchener",
            "Langley",
            "Laval",
            "Lethbridge",
            "London",
            "Longueuil",
            "Maple Ridge",
            "Markham",
            "Medicine Hat",
            "Milton",
            "Mirabel",
            "Mississauga",
            "Moncton",
            "Montreal",
            "Nanaimo",
            "Newmarket",
            "Niagara Falls",
            "Norfolk County",
            "North Bay",
            "Oakville",
            "Oshawa",
            "Ottawa",
            "Peterborough",
            "Pickering",
            "Prairie",
            "Prince George",
            "Quebec",
            "Red Deer",
            "Regina",
            "Repentigny",
            "Richmond Hill",
            "Saanich",
            "Saguenay",
            "Saint - Hyacinthe",
            "Saint - Jerome",
            "Saint Albert",
            "Saint Catharines",
            "Saint John",
            "Saint John",
            "Saint-Jean-sur-Richelieu",
            "Sarnia",
            "Saskatoon",
            "Sault Ste.Marie",
            "Scarborough",
            "Sherbrooke",
            "St - Jean - sur - Richelieu",
            "St.Albert",
            "St.Catharines",
            "St.Hyacinyhe",
            "St.Jerome",
            "St.John",
            "St.John",
            "Strathcona County",
            "Sudbury",
            "Surrey",
            "Terrebonne",
            "Thunder Bay",
            "Toronto",
            "Trois - Rivieres",
            "Vancouver",
            "Vaughan",
            "Victoria",
            "Waterloo",
            "Welland",
            "Westminster",
            "Whitby",
            "Windsor",
            "Winnipeg",
            "Wood"
        ]
        return canadaCity;
    }

    originList() {
        const originList = [
            "Adelaide",
            "Ahmedabad",
            "Amritsar",
            "Auckland",
            "Bagdogra",
            "Balikpapan",
            "Bandung",
            "Bangkok",
            "Bengaluru",
            "Bhubaneswar",
            "Brisbane",
            "Cairns",
            "Canberra",
            "Chandigarh",
            "Chennai",
            "Christchurch",
            "Colombo",
            "Darwin",
            "Dehradun",
            "Delhi",
            "Denpasar Bali",
            "Dibrugarh",
            "Dubai",
            "Goa",
            "Guwahati",
            "Hanoi",
            "Ho Chi Minh City",
            "Hyderabad",
            "Indore",
            "Jakarta",
            "Jammu",
            "Jodhpur",
            "Kathmandu",
            "Khajuraho",
            "Ko Samui",
            "Kochi",
            "Kolkata",
            "Kuala Lumpur",
            "Leh",
            "Lombok",
            "Lucknow",
            "Manado",
            "Medan",
            "Melbourne",
            "Mumbai",
            "Patna",
            "Perth",
            "Phnom Penh",
            "Phuket",
            "Port Blair",
            "Pune",
            "Raipur",
            "Ranchi",
            "Singapore",
            "Srinagar",
            "Surabaya",
            "Sydney",
            "Thiruvanant,puram",
            "Udaipur",
            "Varanasi",
            "Wellington",
            "Yogyakarta"
        ]
        return originList;
    }

    destinationList() {
        const destinationList = [
            "Calgary International Airport",
            "Edmonton International Airport",
            "Gander International Airport",
            "Greater Moncton Roméo LeBlanc International Airport",
            "Halifax Stanfield International Airport",
            "Hamilton/John C. Munro International Airport",
            "London International Airport",
            "Montréal–Pierre Elliott Trudeau International Airport",
            "Ottawa Macdonald–Cartier International Airport",
            "Québec/Jean Lesage International Airport",
            "Regina International Airport",
            "Saskatoon John G. Diefenbaker International Airport",
            "St. John's International Airport",
            "Toronto Pearson International Airport",
            "Vancouver International Airport",
            "Victoria International Airport",
            "Winnipeg International Airport"
        ];
        return destinationList;
    }

}