<app-site-header></app-site-header>

<section class="page-banner">
  <div class="image-layer banner-img"></div>

  <div class="banner-inner">
    <div class="container">
      <div class="inner-container clearfix">
        <h1 class="wow animate__animated animate__fadeIn">Articles</h1>
        <div class="page-nav">
          <ul class="bread-crumb clearfix">
            <li><a href="/">Home</a></li>
            <li class="active">Articles</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="article-section">
  <div class="container">
    <div class="sec-title text-center">
      <h2 class="wow animate__animated animate__fadeIn mt-0">All <strong>Articles</strong></h2>
      <div class="separator center"><span></span></div>
    </div>
    <div class="row">

      <div class="col-md-4" *ngFor="let article of articles">
        <div class="article">
          <div class="image">
            <img [src]="article.image" alt="Article Two">
          </div>
          <div class="content">
            <span>{{article.publishon | date: 'mediumDate'}}</span>
            <a routerLink="/article/{{textReplacer(article.title)}}">
              <h3>{{article.title}}</h3>
            </a>
            <a routerLink="/article/{{textReplacer(article.title)}}" class="article-btn">Read More <i
                class="fas fa-chevron-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-site-footer></app-site-footer>
