import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  url = environment.server;

  constructor(
    private http: HttpClient,
  ) { }

  getIp() {
    return this.http.get('https://api.ipify.org/?format=json')
  }

  getCountry() {
    return this.http.get('https://geolocation-db.com/json/')
  }

  savePosition(data) {
    return this.http.post(this.url + `/tracking/save-position`, data)
  }
}
