import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-add-english-test-dialog',
  templateUrl: './add-english-test-dialog.component.html',
  styleUrls: ['./add-english-test-dialog.component.scss']
})
export class AddEnglishTestDialogComponent implements OnInit {

  englishTestForm: FormGroup;
  englishTestList = [
    { "id": 1, "itemName": "IELTS", value: "IELTS" },
    { "id": 2, "itemName": "TOEFL", value: "TOEFL" },
    { "id": 3, "itemName": "PTE", value: "PTE" }
  ];
  englishTestSettings = {
    singleSelection: false,
    text: "Select English Test",
    selectAllText: null,
    unSelectAllText: null,
    itemsShowLimit: 1,
    enableCheckAll: false,
    enableFilterSelectAll: false,
    limitSelection: 1,
    badgeShowLimit: 1,
    enableSearchFilter: true
  };

  constructor(
    public dialogRef: MatDialogRef<AddEnglishTestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.englishTestForm = new FormGroup({
      'testName': new FormControl('', Validators.required),
      'passedYear': new FormControl('', Validators.required),
      'reading': new FormControl('', Validators.required),
      'writing': new FormControl('', Validators.required),
      'speaking': new FormControl('', Validators.required),
      'listening': new FormControl('', Validators.required),
    });
  }

  addGrade(form) {
    this.dialogRef.close(form.value);
    this.englishTestForm.reset();
  }

  closeDegreeDialog() {
    this.dialogRef.close();
  }

}
