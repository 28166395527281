<div class="dashboard-box">

  <app-navbar></app-navbar>

  <app-sidebar></app-sidebar>

  <div class="dashboard-body no-banner">
    <div class="container-fluid">
      <div class="page-header-second">
        <h2>Pre-Departure</h2>
      </div>
    </div>

    <div class="container-fluid">
      <div class="card wow animate__animated animate__fadeInUp">
        <div class="card-body">
          <h3>When you travel to Canada, you must bring your:</h3>
          <p>Letter of introduction that says your application is approved. <br> Valid passport that has your visitor
            visa stuck to one of its pages.</p>
        </div>
      </div>

      <div class="card wow animate__animated animate__fadeInUp extra-page-inner">
        <div class="card-body">
          <div [innerHTML]="predeparture"></div>
        </div>
      </div>
    </div>
    <app-site-footer></app-site-footer>
  </div>
</div>
