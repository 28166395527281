import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthService } from 'src/app/services/auth.service';
import { RxwebValidators, } from "@rxweb/reactive-form-validators";

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  success;
  error;

  passwordChangeForm: FormGroup
  selectedFile;
  documentValidation: Boolean = false;
  loading = false;

  uploadDocumentsData;
  submitbtnValidation;


  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.passwordChangeForm = this._formBuilder.group({
      'password': new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]),
      'confirmPassword': new FormControl('', RxwebValidators.compare({ fieldName: 'password' })),
    });
  }

  changePasswordSubmit(form: FormGroup) {
    this._auth.changePassword(form.value).subscribe((res) => {
      this.success = 'Password Updated.';
      setTimeout(() => {
        this.success = '';
        this.onDialogClose()
      }, 3000);
    },
      (err) => {
        this.error = err.error;
        setTimeout(() => {
          this.error = '';
        }, 3000);
      });
  }

  onDialogClose(): void {
    this.dialogRef.close();
  }
}
