import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  profile;
  loggedin: Boolean = false;
  showProfile: Boolean = true;

  loginForm: FormGroup
  error = '';

  submitted = false;
  errorClosed = false;

  constructor(
    private _profile: ProfileService,
    private _auth: AuthService,
    private _router: Router,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    const token = this._profile.getToken();
    if (token) {
      this._router.navigate(['/profile']);
      this.loggedin = true
      this.showProfile = false
    } else {
      this.loggedin = false
      this.showProfile = true
    }

    this.loginForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.required])
    })
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return
    } else {
      this._auth.loginUser(this.loginForm.value)
        .subscribe(
          res => {
            this.cookieService.set('token', res.token, { expires: new Date(new Date().setDate(new Date().getDate() + 7)) });
            this._router.navigate(['/profile']);
          },
          err => {
            if (err.statusText === "Unknown Error") {
              this.errorClosed = true;
              this.error = 'Something wrong in server. Please try again later.';
              setTimeout(() => {
                this.errorClosed = false;
                this.error = '';
              }, 3000);
            } else {
              this.errorClosed = true;
              this.error = err.error;
              setTimeout(() => {
                this.errorClosed = false;
                this.error = '';
              }, 3000);
            }
          }
        );
    }
  }

}
