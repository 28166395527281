<div (click)="openInfoDialog()" class="info-dialog-btn">Important Information</div>

<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <h3>SOP Support Questions</h3>
    <h5 class="mt-4 mb-4">Please answer the following questions accurately and in detail.</h5>
    <form [formGroup]="immigrationQuestions" (ngSubmit)="submitService()">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Please describe your academic qualification and experience (if any)?</label>
            <textarea class="form-control" type="text" rows="6" name="qualification" placeholder="Enter your answer"
              formControlName="qualification"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Why have you applied to this university/college in Canada?</label>
            <textarea class="form-control" type="text" rows="6" name="appliedUniversity" placeholder="Enter your answer"
              formControlName="appliedUniversity"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Why do you want to study in Canada?</label>
            <textarea class="form-control" type="text" rows="6" name="whyincanada" placeholder="Enter your answer"
              formControlName="whyincanada"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Which program do you want to study and why?</label>
            <textarea class="form-control" type="text" rows="6" name="programName" placeholder="Enter your answer"
              formControlName="programName"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Do you have a gap year? If yes, explain what you did during your gap.</label>
            <textarea class="form-control" type="text" rows="6" name="gapYear" placeholder="Enter your answer"
              formControlName="gapYear"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Are you going to return to your home country?</label>
            <textarea class="form-control" type="text" rows="6" name="returnToHome" placeholder="Enter your answer"
              formControlName="returnToHome"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">How are you going to fund your studies in Canada?</label>
            <textarea class="form-control" type="text" rows="6" name="fundStudie" placeholder="Enter your answer"
              formControlName="fundStudie"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">What are your short/long term career goals?</label>
            <textarea class="form-control" type="text" rows="6" name="careerGoal" placeholder="Enter your answer"
              formControlName="careerGoal"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Describe any extra Curricular Activities that you participated in and your other
              interests?</label>
            <textarea class="form-control" type="text" rows="6" name="curricularInterests"
              placeholder="Enter your answer" formControlName="curricularInterests"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Do you understand all your responsibilities as an international student?</label>
            <select class="form-control" name="understandResposibilities" formControlName="understandResposibilities">
              <option value="" disabled selected>Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <button [disabled]="immigrationQuestions.invalid" type="submit" class="btn btn-square btn-primary">Next</button>
      </div>
    </form>
  </div>
</div>
