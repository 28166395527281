import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  private _completeProfile = environment.server;

  constructor(private http: HttpClient) { }

  getArticles() {
    return this.http.get(this._completeProfile + '/article/get-articles');
  }

  getArticle(id) {
    return this.http.get(this._completeProfile + '/article/get-article/' + id);
  }
}
