import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectOptionData } from '../../../../../sharedata/selectOptionData';
import { ProfileService } from '../../../../../services/profile.service';

@Component({
  selector: 'app-banking-loan',
  templateUrl: './banking-loan.component.html',
  styleUrls: ['./banking-loan.component.scss']
})
export class BankingLoanComponent implements OnInit {

  personalform: FormGroup;
  courseform: FormGroup;
  educationform: FormGroup
  profile;
  todayDate = new Date();
  countryList;
  stateList;

  constructor(
    private _profile: ProfileService,
    private _formBuilder: FormBuilder,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.profile = this._profile.getuser();
    const serviceData = new SelectOptionData;
    this.countryList = serviceData.country();
    this.stateList = serviceData.state();

    let personalData = {
      dob: '',
      gender: '',
      address: '',
      country: '',
      state: '',
      postalcode: '',
    };

    if (localStorage.getItem('personalData')) {
      personalData = JSON.parse(localStorage.getItem('personalData'));
    }

    this.personalform = this._formBuilder.group({
      'prefix': new FormControl('',),
      'firstname': new FormControl('',),
      'middlename': new FormControl('', []),
      'lastname': new FormControl('',),
      'email': new FormControl('',),
      'phone': new FormControl('',),
      'dob': new FormControl('',),
      'gender': new FormControl(personalData.gender,),
      'maritalstatus': new FormControl('',),
      'address': new FormControl(personalData.address,),
      'country': new FormControl(personalData.country,),
      'state': new FormControl(personalData.state,),
      'postalcode': new FormControl(personalData.postalcode),
    });
  }

  onChanges() {
    this.personalform.get('country').valueChanges.subscribe(selectedCountry => {
      if (selectedCountry != 'India') {
        this.personalform.get('state').reset();
        this.personalform.get('state').disable();
      }
      else {
        this.personalform.get('state').enable();
      }
    });
  }
}
