import { Component, OnInit } from '@angular/core';
import { ServicesSubmitService } from 'src/app/services/services-submit.service';
import { ProfileService } from 'src/app/services/profile.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-prepare-for-canada-info',
  templateUrl: './prepare-for-canada-info.component.html',
  styleUrls: ['./prepare-for-canada-info.component.scss']
})
export class PrepareForCanadaInfoComponent implements OnInit {

  profile;
  selectedService;
  cellInternet;
  studentJob;
  localConveyance;
  tutoring;
  peernetwork;
  holidayEvents;

  constructor(
    private _profile: ProfileService,
    private _services: ServicesSubmitService
  ) {}

  ngOnInit(): void {
    this.profile = this._profile.getuser();

    this._services.getSelectedServices().subscribe((res) => {
      for (var i=0; i < res.servicesName.length; i++) {
        if (res.servicesName[i].name === 'cellInternet') {
          if(res.servicesName[i].need = true){
            this.cellInternet = true
          }
        }
        if (res.servicesName[i].name === 'studentJob') {
          if(res.servicesName[i].need = true){
            this.studentJob = true
          }
        }
        if (res.servicesName[i].name === 'localConveyance') {
          if(res.servicesName[i].need = true){
            this.localConveyance = true
          }
        }
        if (res.servicesName[i].name === 'tutoring') {
          if(res.servicesName[i].need = true){
            this.tutoring = true
          }
        }
        if (res.servicesName[i].name === 'peernetwork') {
          if(res.servicesName[i].need = true){
            this.peernetwork = true
          }
        }
        if (res.servicesName[i].name === 'holidayEvents') {
          if(res.servicesName[i].need = true){
            this.holidayEvents = true
          }
        }
      }
    });
  }

}
