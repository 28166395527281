<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeIn">ADMISSION</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li><a href="/">Services</a></li>
              <li class="active">Admission</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-detail bg-white">
    <div class="container">
      <h2 class="wow animate__animated animate__fadeInUp">Admission Support</h2>
      <p class="wow animate__animated animate__fadeInUp"><strong>Student-Stop</strong> aims to provide you with support
        to get into the
        university/college of your choice. Our experts
        are here to help you with your applications and provide you with all the guidance possible. We also provide
        guarenteed admission(limited availability) with our partner university/colleges. The streamlined design of our
        platform will smoothly guide you to the admission process in a few easy steps. Just select the university and
        colleges of your choice, upload the documents, select the support needed and submit. Its that simple! Register
        to
        find out more today.</p>

      <div class="challange-solution-section clearfix wow animate__animated animate__fadeInUp">
        <h2 class="wow animate__animated animate__fadeInUp">Start Date</h2>
        <p class="wow animate__animated animate__fadeInUp">The first step when doing your research is to find out when
          you will be eligible to join university/college for your specific course.
          Your schedule will depend on this important factor. Different institutions offer different start dates.
          September(Fall) is
          considered the most popular. Please note, some institutions may
          offer various start dates, but they may not be applicable to new students.</p>
        <div>
          <h3>University</h3>
          <p>Most University programs accept enrollment for Fall semester. However, some programs offer a Winter start
            date as well. </p>
          <ul>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Fall Term - End August / Early
              September</li>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Winter Term - January</li>
          </ul>
        </div>
        <div>
          <h3>College</h3>
          <p>Most College programs offer a variety of start dates to give you the flexibility to choose
            when to begin your studies. </p>
          <ul>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Fall Term - August / September</li>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Winter Term - January</li>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Spring/Summer Term - May</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="service-detail pt-5 pb-5">
    <div class="container">
      <h2 class="wow animate__animated animate__fadeInUp m-5 white-text">General University & College Timeline for Fall
        Semester
        Applicants</h2>

      <div class="timeline">
        <div class="timeline-left">
          <span class="circle"></span>
          <div class="header-content">
            <h2>May-August</h2>
          </div>
          <div class="content">
            <p><strong>University & Colleges:</strong> Students must research programs of interest and shortlist an
              initial set of courses and institutions they are interested to attend.
              <a routerLink="/register" style="color:#ff9600;">Enroll</a> on <strong>Student-Stop</strong> for FREE and
              begin your
              journey.</p>
          </div>
        </div>
        <div class="timeline-right">
          <span class="circle"></span>
          <div class="header-content">
            <h2>September-October</h2>
          </div>
          <div class="content">
            <p><strong>University & Colleges:</strong> Online Applications
              Open for next year</p>
          </div>
        </div>

        <div class="timeline-left">
          <span class="circle"></span>
          <div class="header-content">
            <h2>December</h2>
          </div>
          <div class="content">
            <p><strong>University:</strong> Early Application Deadline(where applicable) for early selection.</p>
          </div>
        </div>
        <div class="timeline-right">
          <span class="circle"></span>
          <div class="header-content">
            <h2>January</h2>
          </div>
          <div class="content">
            <p><strong>University:</strong> Deadline for most university applications.</p>
          </div>
        </div>

        <div class="timeline-left">
          <span class="circle"></span>
          <div class="header-content">
            <h2>February</h2>
          </div>
          <div class="content">
            <p><strong>College:</strong> Equal consideration
              Deadline(where applicable): Apply by this date to
              be equally considered with other applicants. Applications after this deadline will be considered on a
              first come first serve basis.</p>
          </div>
        </div>
        <div class="timeline-right">
          <span class="circle"></span>
          <div class="header-content">
            <h2>March</h2>
          </div>
          <div class="content">
            <p><strong>College:</strong>Application deadline for most colleges where admission
              is not on a rolling basis.</p>
          </div>
        </div>

        <div class="timeline-left">
          <span class="circle"></span>
          <div class="header-content">
            <h2>May</h2>
          </div>
          <div class="content">
            <p><strong>University & Colleges:</strong>Most decisions will be made. Also general deadline to confirm
              offers.</p>
          </div>
        </div>
        <div class="timeline-right">
          <span class="circle"></span>
          <div class="header-content">
            <h2>June-August</h2>
          </div>
          <div class="content">
            <p><strong>University & College:</strong>Tution Deposit due and course enrolment begins.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-inner-top-section wow animate__animated animate__fadeInUp">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="service-inner-content">
            <div class="sec-title">
              <div class="title wow animate__animated animate__fadeInUp">REQUIREMENTS</div>
              <div class="separator"><span></span></div>
            </div>
            <h2 class="wow animate__animated animate__fadeInUp">Important Documents required</h2>
            <p class="wow animate__animated animate__fadeInUp">Please find below a basic list of documents required. <a
                routerLink="/register" style="color:#ff9600;">Log in</a> to find the detailed list.</p>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Transcripts, High School Certificates and degrees(if applicable)</h4>
              <p>You may need your high school transcripts, certificates and degrees(if applicable) based on the course
                you apply for. Sign in for more information.</p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> English Language Testing Certificate (If applicable)</h4>
              <p>Test of English As a Foreign Language (TOEFL), International English Language Testing System (IELTS) or
                Canadian Academic English Language Assessment (CAEL) with a minimum grade. Some colleges offer alternate
                options for people without tests (log in for details).</p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i>Recommendation Letter(if applicable)</h4>
              <p>Some institutions do require recommendation letters from your professors/teachers/counsellor. If
                required, this will have to be arranged.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="why-choose-image wow animate__animated animate__fadeInUp">
            <img src="/assets/images/admission.jpg" alt="image">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="call-to-section-second">
    <div class="container">
      <div class="content">
        <div class="sub-title wow animate__animated animate__lightSpeedInRight">Hurry and Sign Up Now!</div>
        <h2 class="wow animate__animated animate__fadeInUp">Register/Log In now to for more details and to start the
          process today! </h2>
        <div class="link-box">
          <a href="/register" class="cta-btn wow animate__animated animate__zoomIn animate__delay-1s">Get Started</a>
        </div>
      </div>
    </div>
  </section>

  <app-site-footer></app-site-footer>

</main>
