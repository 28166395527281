import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-services-stepper',
  templateUrl: './services-stepper.component.html',
  styleUrls: ['./services-stepper.component.scss']
})
export class ServicesStepperComponent implements OnInit {

  @Input() serviceFormParent: FormGroup;
  selectAllServiceValue: Boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  selectAllService() {
    if (this.selectAllServiceValue === false) {
      this.serviceFormParent.controls.admission.setValue(true);
      this.serviceFormParent.controls.banking.setValue(true);
      this.serviceFormParent.controls.financing.setValue(true);
      this.serviceFormParent.controls.immigration.setValue(true);
      this.serviceFormParent.controls.accommodation.setValue(true);
      this.serviceFormParent.controls.food.setValue(true);
      this.serviceFormParent.controls.travel.setValue(true);
      this.serviceFormParent.controls.localConveyance.setValue(true);
      this.serviceFormParent.controls.cellInternet.setValue(true);
      this.serviceFormParent.controls.studentJob.setValue(true);
      this.serviceFormParent.controls.tutoring.setValue(true);
      this.serviceFormParent.controls.medicalInsurance.setValue(true);
      this.serviceFormParent.controls.peernetwork.setValue(true);
      this.serviceFormParent.controls.holidayEvents.setValue(true);
      this.serviceFormParent.controls.primmigration.setValue(true);

      this.selectAllServiceValue = true;
    } else {
      this.serviceFormParent.controls.admission.setValue(false);
      this.serviceFormParent.controls.banking.setValue(false);
      this.serviceFormParent.controls.financing.setValue(false);
      this.serviceFormParent.controls.immigration.setValue(false);
      this.serviceFormParent.controls.accommodation.setValue(false);
      this.serviceFormParent.controls.food.setValue(false);
      this.serviceFormParent.controls.travel.setValue(false);
      this.serviceFormParent.controls.localConveyance.setValue(false);
      this.serviceFormParent.controls.cellInternet.setValue(false);
      this.serviceFormParent.controls.studentJob.setValue(false);
      this.serviceFormParent.controls.tutoring.setValue(false);
      this.serviceFormParent.controls.medicalInsurance.setValue(false);
      this.serviceFormParent.controls.peernetwork.setValue(false);
      this.serviceFormParent.controls.holidayEvents.setValue(false);
      this.serviceFormParent.controls.primmigration.setValue(false);

      this.selectAllServiceValue = false;
    }
  }

}
