import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AdmissionService } from 'src/app/services/admission.service';
import { AdmissionApplicationSummaryDialogComponent } from 'src/app/components/dialogs/admission-application-summary-dialog/admission-application-summary-dialog.component';
import { AddInstituteDialogComponent } from 'src/app/components/dialogs/admission/add-institute-dialog/add-institute-dialog.component';
import { EditInstituteDialogComponent } from 'src/app/components/dialogs/admission/edit-institute-dialog/edit-institute-dialog.component';
import { AddSchoolDialogComponent } from 'src/app/components/dialogs/admission/add-school-dialog/add-school-dialog.component';
import { EditSchoolDialogComponent } from 'src/app/components/dialogs/admission/edit-school-dialog/edit-school-dialog.component';
import { AddEnglishTestDialogComponent } from 'src/app/components/dialogs/admission/add-english-test-dialog/add-english-test-dialog.component';

@Component({
  selector: 'app-admission-services',
  templateUrl: './admission-services.component.html',
  styleUrls: ['./admission-services.component.scss']
})
export class AdmissionServicesComponent implements OnInit {

  dialogRef;
  studyList = [];
  gradeList = [];
  englishTestList = [];
  nextBtnValidation = false;

  testValue;
  showTestTable = false;
  submitValid = false;

  constructor(
    private _admission: AdmissionService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.serviceFetch();
    this.checkButtonValidation();
  }

  openAppSummaryDialog() {
    this.dialogRef = this.dialog.open(AdmissionApplicationSummaryDialogComponent, {
      width: '1200px',
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  addInstitute() {
    this.dialogRef = this.dialog.open(AddInstituteDialogComponent, {
      width: '1000px',
      data: {},
      hasBackdrop: false,
      autoFocus: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.studyList.push(result);
        this._admission.admissionInfo(this.studyList, 'studyList').subscribe((res) => {
          return null;
        });
      }
    });
  }

  editIntituteDialog(index) {
    var editFormDetails = this.studyList[index];
    this.dialogRef = this.dialog.open(EditInstituteDialogComponent, {
      width: '1000px',
      data: { editFormDetails },
      hasBackdrop: false,
      autoFocus: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._admission.admissionEdit(result, 'studyList', index).subscribe((res) => {
          this.serviceFetch();
        });
      }
    });
  }

  openDegreeForm() {
    this.dialogRef = this.dialog.open(AddSchoolDialogComponent, {
      width: '1000px',
      data: {},
      hasBackdrop: false,
      autoFocus: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.gradeList.push(result);
        this._admission.admissionInfo(this.gradeList, 'gradeList').subscribe((res) => {
          this.serviceFetch();
        });
      }
    });
  }

  editDegreeDialog(index) {
    var editFormDetails = this.gradeList[index];
    this.dialogRef = this.dialog.open(EditSchoolDialogComponent, {
      width: '1000px',
      data: { editFormDetails },
      hasBackdrop: false,
      autoFocus: false,
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._admission.admissionEdit(result, 'gradeList', index).subscribe((res) => {
          this.serviceFetch();
        });
      }
    });
  }

  openEnglishTestForm() {
    this.dialogRef = this.dialog.open(AddEnglishTestDialogComponent, {
      width: '1000px',
      data: {},
      hasBackdrop: false,
      autoFocus: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.englishTestList.push(result);
        this._admission.admissionInfo(this.englishTestList, 'englishTest').subscribe((res) => {
          this.serviceFetch();
        });
      }
    });
  }

  checkButtonValidation() {
    if (this.gradeList.length !== 0 && this.studyList.length !== 0) {
      this.nextBtnValidation = true;
    } else {
      this.nextBtnValidation = false;
    }
  }

  serviceFetch() {
    this._admission.getAdmission().subscribe((res) => {
      let admissionStatus = res;
      this.studyList = admissionStatus.studyInfo;
      this.gradeList = admissionStatus.gradeList;
      this.englishTestList = admissionStatus.englishTestList;
      if (this.englishTestList.length === 0) {
        this.submitValid = false;
      } else {
        this.testValue = 'Yes';
        this.showTestTable = true;
        this.submitValid = true;
      }
      this.checkButtonValidation();
    });
  }

  deleteItem(index, category) {
    this._admission.itemDelete(index, category).subscribe((res) => {
      this.serviceFetch();
      this.checkButtonValidation();
    });
  }

  selectTestOption(value) {
    if (value === "Yes") {
      this.showTestTable = true;
      if (this.englishTestList.length === 0) {
        this.submitValid = false;
      } else {
        this.submitValid = true;
      }
    }

    if (value === "No") {
      this.showTestTable = false;
      this.submitValid = true;
    }
  }

  onSubmit() {
    this._admission.admissionService(this.studyList).subscribe((res) => {
      window.location.reload()
    });
  }
}
