import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-admission-main',
  templateUrl: './admission-main.component.html',
  styleUrls: ['./admission-main.component.scss']
})
export class AdmissionMainComponent implements OnInit {

  profile;
  step: Number;
  userStudyDetails;
  admissionService;
  documents;

  constructor(
    private _profile: ProfileService
  ) { }

  ngOnInit(): void {
    this._profile.getStudentDetails().subscribe((res) => {
      if (res.selectedService) {
        for (var i = 0; i < res.selectedService.servicesName.length; i++) {
          if (res.selectedService.servicesName[i].name === 'admission') {
            if (res.selectedService.servicesName[i].statusStep !== 0) {
              this.step = res.selectedService.servicesName[i].statusStep;
              this.userStudyDetails = res.userStudyDetails;
              this.admissionService = res.admissionService;
              this.documents = res.documents;
              this.profile = res;
            } else {
              this.userStudyDetails = res.userStudyDetails;
              this.step = 0;
            }
          }
        }
      }
    });
  }
}
