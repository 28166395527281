<div class="card" mat-dialog-content>
  <div class="card-header">
    English Test Score
    <button class="close" aria-label="Close" (click)="closeDegreeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <form [formGroup]="englishTestForm" (ngSubmit)="addGrade(englishTestForm)" novalidate novalidate>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Select English Test*</label>
            <angular2-multiselect [data]="englishTestList" class="form-control angular-selector"
              formControlName="testName" name="testName" [settings]="englishTestSettings">
              <c-item>
                <ng-template let-item="item">
                  <label>{{item.value}}</label>
                </ng-template>
              </c-item>
            </angular2-multiselect>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="postal-code">Passed Year*</label>
            <input class="form-control" type="text" name="passedYear" placeholder="Enter Passed Year"
              formControlName="passedYear">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="postal-code">Reading Score*</label>
            <input class="form-control" type="number" name="reading" placeholder="Enter Reading Score"
              formControlName="reading">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="postal-code">Writing Score*</label>
            <input class="form-control" type="number" name="writing" placeholder="Enter Writing Score"
              formControlName="writing">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="postal-code">Speaking Score*</label>
            <input class="form-control" type="number" name="speaking" placeholder="Enter Speaking Score"
              formControlName="speaking">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="postal-code">Listening Score*</label>
            <input class="form-control" type="number" name="listening" placeholder="Enter Listening Score"
              formControlName="listening">
          </div>
        </div>
        <div class="col-md-12">
          <div class="submit-btn-second text-center">
            <br><br>
            <button type="submit" [disabled]="englishTestForm.invalid"
              class="btn btn-square btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
