<div class="dashboard-box">
  <app-navbar></app-navbar>
  <app-sidebar></app-sidebar>

  <div class="dashboard-body">
    <div class="container-fluid">
      <div class="page-header admission">
        <div class="row">
          <div class="col-lg-12 main-header">
            <h2>Admission</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="top-box">

        <div class="card wow animate__animated animate__fadeInUp" *ngIf="step !== 4">
          <div class="card-body">
            <div class="step-wrapper">
              <div class="md-stepper-horizontal orange">
                <div class="md-step active">
                  <div class="md-step-circle"><span>1</span></div>
                  <div class="md-step-title">Important Information</div>
                  <div class="md-step-bar-left"></div>
                  <div class="md-step-bar-right"></div>
                </div>
                <div class="md-step" [class.active]="step >= 1">
                  <div class="md-step-circle"><span>2</span></div>
                  <div class="md-step-title">Academic Background</div>
                  <div class="md-step-bar-left"></div>
                  <div class="md-step-bar-right"></div>
                </div>
                <div class="md-step" [class.active]="step >= 2">
                  <div class="md-step-circle"><span>3</span></div>
                  <div class="md-step-title">Documents Required (Upload)</div>
                  <div class="md-step-bar-left"></div>
                  <div class="md-step-bar-right"></div>
                </div>
                <div class="md-step" [class.active]="step >= 3">
                  <div class="md-step-circle"><span>4</span></div>
                  <div class="md-step-title">Academic guidance and application preparation support</div>
                  <div class="md-step-bar-left"></div>
                  <div class="md-step-bar-right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-admission-confirm [userStudyDetails]="userStudyDetails" *ngIf="step === 0"></app-admission-confirm>
        <app-admission-services *ngIf="step === 1"></app-admission-services>
        <app-admission-documents *ngIf="step === 2"></app-admission-documents>
        <app-admission-payment [profile]="profile" *ngIf="step === 3"></app-admission-payment>
        <app-admission-status [documents]="documents" [admissionService]="admissionService"
          [userStudyDetails]="userStudyDetails" *ngIf="step === 4"></app-admission-status>
      </div>
    </div>
    <app-site-footer></app-site-footer>
  </div>
</div>
