import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FoodService } from './../../../../../services/food.service';
import { SelectOptionData } from '../../../../../sharedata/selectOptionData';

@Component({
  selector: 'app-food-info',
  templateUrl: './food-info.component.html',
  styleUrls: ['./food-info.component.scss']
})
export class FoodInfoComponent implements OnInit {

  @Input() foodService;

  foodList = [];
  dialogRef;
  editFoodForm: FormGroup;

  url;
  canadaCity;
  universityList;
  collegeList;
  allUniversityAndCollege;
  years = [];

  universitySettings = {
    singleSelection: true,
    text: "Select University Or College",
    selectAllText: false,
    unSelectAllText: false,
    enableSearchFilter: true,
    lazyLoading: true
  };

  minDate = new Date();

  constructor(
    private _food: FoodService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.url = environment.url;

    this.editFoodForm = new FormGroup({
      'university': new FormControl([this.foodService.university], Validators.required),
      'city': new FormControl(this.foodService.city, Validators.required),
      'startSemesterYear': new FormControl(this.foodService.startSemesterYear, [Validators.required]),
      'semesterType': new FormControl(this.foodService.semesterType, [Validators.required]),
      'dietType': new FormControl(this.foodService.dietType, [Validators.required]),
      'cuisinePreference': new FormControl(this.foodService.cuisinePreference, [Validators.required]),
      'allergies': new FormControl(this.foodService.allergies, []),
    });

    if (this.foodService.selectedFood) {
      this._food.getfoodbyId(this.foodService.selectedFood).subscribe(res => {
        this.foodService.selectedFood = res;
      })
    }

    this._food.getAvailableFoods(this.foodService).subscribe(
      data => {
        this.foodList = JSON.parse(data);
      },
      err => {
        console.log(err)
      }
    )

    const currentYear = new Date().getFullYear()
    for (var year = currentYear; year <= currentYear + 3; year++) {
      this.years.push(year);
    }

    const serviceData = new SelectOptionData;
    this.canadaCity = serviceData.canadaCity();
    this.universityList = serviceData.university();
    this.collegeList = serviceData.collegeList();

    this.allUniversityAndCollege = this.universityList.concat(this.collegeList);

    this.allUniversityAndCollege.sort(function (a, b) {
      if (a.itemName < b.itemName) { return -1; }
      if (a.itemName > b.itemName) { return 1; }
      return 0;
    })
  }

  selectFood(id) {
    const data = {
      id
    }
    this._food.selectFood(data).subscribe(
      res => {
        window.location.reload();
      },
      err => {
        console.log(err)
      }
    )
  }

  editDialog(dialog) {
    this.dialogRef = this.dialog.open(dialog, {
      width: '1000px',
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  selectUniversity(item) {
    const city = item[0].city;
    if (city) {
      this.editFoodForm.patchValue({
        city
      });
    }
  }

  closeDegreeDialog() {
    this.dialogRef.close();
  }

  submitEditFood(form) {
    this._food.editFoodSubmit(form.value).subscribe(
      res => {
        window.location.reload()
      }
    )
  }

}
