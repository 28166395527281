import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';
import { AdmissionService } from 'src/app/services/admission.service';
import { EditInstituteDialogComponent } from 'src/app/components/dialogs/admission/edit-institute-dialog/edit-institute-dialog.component';
import { EditSchoolDialogComponent } from 'src/app/components/dialogs/admission/edit-school-dialog/edit-school-dialog.component';

@Component({
  selector: 'app-admission-documents',
  templateUrl: './admission-documents.component.html',
  styleUrls: ['./admission-documents.component.scss']
})
export class AdmissionDocumentsComponent implements OnInit {

  url;
  studyList = [];
  gradeList = [];
  dialogRef;

  constructor(
    private _admission: AdmissionService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.url = environment.url;
  }

  openAcademicDialog(dialog) {
    this.serviceFetch();
    this.dialogRef = this.dialog.open(dialog, {
      width: '1200px',
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  serviceFetch() {
    this._admission.getAdmission().subscribe((res) => {
      let admissionStatus = res;
      this.studyList = admissionStatus.studyInfo;
      this.gradeList = admissionStatus.gradeList;
    });
  }

  editIntituteDialog(index) {
    var editFormDetails = this.studyList[index];
    this.dialogRef = this.dialog.open(EditInstituteDialogComponent, {
      width: '1000px',
      data: { editFormDetails },
      hasBackdrop: false,
      autoFocus: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._admission.admissionEdit(result, 'studyList', index).subscribe((res) => {
          this.serviceFetch();
        });
      }
    });
  }

  editDegreeDialog(index) {
    var editFormDetails = this.gradeList[index];
    this.dialogRef = this.dialog.open(EditSchoolDialogComponent, {
      width: '1000px',
      data: { editFormDetails },
      hasBackdrop: false,
      autoFocus: false,
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._admission.admissionEdit(result, 'gradeList', index).subscribe((res) => {
          this.serviceFetch();
        });
      }
    });
  }
}
