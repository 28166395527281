import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SelectOptionData } from 'src/app/sharedata/selectOptionData';
import { SelectOptions } from 'src/app/sharedata/selectedOptions';

@Component({
  selector: 'app-add-institute-dialog',
  templateUrl: './add-institute-dialog.component.html',
  styleUrls: ['./add-institute-dialog.component.scss']
})
export class AddInstituteDialogComponent implements OnInit {

  tableForm: FormGroup;
  toDate = new Date();
  todayDate = new Date();

  studyTypeList;
  studyTypeSettings;
  countryList;
  countrySettings;

  constructor(
    public dialogRef: MatDialogRef<AddInstituteDialogComponent>,
  ) {
    const serviceData = new SelectOptionData;
    const selectedOptions = new SelectOptions;

    this.countryList = serviceData.country();
    this.countrySettings = selectedOptions.countrySettings();

    this.studyTypeList = serviceData.studyType();
    this.studyTypeSettings = selectedOptions.studyTypeSettings();
  }

  ngOnInit(): void {
    this.tableForm = new FormGroup({
      'studyType': new FormControl('', Validators.required),
      'country': new FormControl(''),
      'nameOfInstitute': new FormControl('', Validators.required),
      'attendedFrom': new FormControl('', Validators.required),
      'attendedTo': new FormControl('', Validators.required),
      'degreeName': new FormControl('', [Validators.required]),
      'indicateDate': new FormControl('', [Validators.required]),
    });
  }

  addInstitute(form) {
    this.dialogRef.close(form.value);
    this.tableForm.reset();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  depart() {
    this.toDate = this.tableForm.value.attendedFrom;
  }

}
