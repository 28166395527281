<div class="sidebar-menu">
  <div class="sidebar">
    <ul class="sidebarmenu-list custom-scrollbar" *ngIf="services.length > 0">
      <li class="open">
        <a class="list-icons">
          <i class="fas fa-plane-departure"></i>
          <span>Journey Begins</span>
        </a>
      </li>
      <li *ngIf="this.selectedServices['admission'][0] == true"
        [ngClass]="{ 'bg-scholarship': this.selectedServices['admission'][1] === 0  }">
        <a class="list-icons" [routerLink]="['/admission']" [routerLinkActive]="['active']" [ngClass]="
        {
          'danger-bg': this.selectedServices['admission'][1] === 1, 
          'success-full-bg': this.selectedServices['admission'][1] === 4, 
          'success-bg': this.selectedServices['admission'][1] === 2 }
        ">
          <i class="fas fa-university"></i>
          <span>Admission</span>
        </a>
        <img class="scholarship" src="/assets/images/scholarship-icon.png">
        <span *ngIf="selectedServices['admission'][1] === 1" class="badge badge-pill badge-danger">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        <span *ngIf="selectedServices['admission'][1] === 4" class="badge badge-pill badge-success">
          <i class="fas fa-check-double"></i>
        </span>
        <span *ngIf="selectedServices['admission'][1] === 2" class="badge badge-pill badge-success">
          <i class="fas fa-check"></i>
        </span>
      </li>
      <li *ngIf="this.selectedServices['banking'][0] == true">
        <a class="list-icons" [routerLink]="['/banking']" [routerLinkActive]="['active']" [ngClass]="
        {
          'danger-bg': this.selectedServices['banking'][1] === 1, 
          'success-full-bg': this.selectedServices['banking'][1] === 4, 
          'success-bg': this.selectedServices['banking'][1] === 2 }
        ">
          <i class="fas fa-money-bill-wave"></i>
          <span>Banking</span>
        </a>
        <span *ngIf="selectedServices['banking'][1] === 1" class="badge badge-pill badge-danger">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        <span *ngIf="selectedServices['banking'][1] === 4" class="badge badge-pill badge-success">
          <i class="fas fa-check-double"></i>
        </span>
        <span *ngIf="selectedServices['banking'][1] === 2" class="badge badge-pill badge-success">
          <i class="fas fa-check"></i>
        </span>
      </li>
      <li *ngIf="this.selectedServices['immigration'][0] == true">
        <a class="list-icons" [routerLink]="['/study-permit']" [routerLinkActive]="['active']" [ngClass]="
        {
          'danger-bg': this.selectedServices['immigration'][1] === 1, 
          'success-full-bg': this.selectedServices['immigration'][1] === 4, 
          'success-bg': this.selectedServices['immigration'][1] === 2 }
        ">
          <i class="fas fa-file"></i>
          <span>Study Permit</span>
        </a>
        <span *ngIf="selectedServices['immigration'][1] === 1" class="badge badge-pill badge-danger">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        <span *ngIf="selectedServices['immigration'][1] === 4" class="badge badge-pill badge-success">
          <i class="fas fa-check-double"></i>
        </span>
        <span *ngIf="selectedServices['immigration'][1] === 2" class="badge badge-pill badge-success">
          <i class="fas fa-check"></i>
        </span>
      </li>
      <li *ngIf="this.selectedServices['travel'][0] == true">
        <a class="list-icons" [routerLink]="['/travel']" [routerLinkActive]="['active']" [ngClass]="
        {
          'danger-bg': this.selectedServices['travel'][1] === 1, 
          'success-full-bg': this.selectedServices['travel'][1] === 4, 
          'success-bg': this.selectedServices['travel'][1] === 2 }
        ">
          <i class="fas fa-plane"></i>
          <span>Travel & Insurance</span>
        </a>
        <span *ngIf="selectedServices['travel'][1] === 4" class="badge badge-pill badge-success">
          <i class="fas fa-check-double"></i>
        </span>
        <span *ngIf="selectedServices['travel'][1] === 2" class="badge badge-pill badge-success">
          <i class="fas fa-check"></i>
        </span>
      </li>
      <li *ngIf="this.selectedServices['accommodation'][0] == true">
        <a class="list-icons" [routerLink]="['/housing']" [routerLinkActive]="['active']" [ngClass]="
        {
          'danger-bg': this.selectedServices['accommodation'][1] === 1, 
          'success-full-bg': this.selectedServices['accommodation'][1] === 4, 
          'success-bg': this.selectedServices['accommodation'][1] === 2 }
        ">
          <i class="fas fa-hotel"></i>
          <span>Housing</span>
        </a>
        <span *ngIf="selectedServices['accommodation'][1] === 1" class="badge badge-pill badge-danger">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        <span *ngIf="selectedServices['accommodation'][1] === 4" class="badge badge-pill badge-success">
          <i class="fas fa-check-double"></i>
        </span>
        <span *ngIf="selectedServices['accommodation'][1] === 2" class="badge badge-pill badge-success">
          <i class="fas fa-check"></i>
        </span>
      </li>
      <li *ngIf="this.selectedServices['food'][0] == true">
        <a class="list-icons" [routerLink]="['/food']" [routerLinkActive]="['active']" [ngClass]="
        {
          'danger-bg': this.selectedServices['food'][1] === 1, 
          'success-bg': this.selectedServices['food'][1] === 4, 
          'success-full-bg': this.selectedServices['food'][1] === 2 }
        ">
          <i class="fas fa-utensils"></i>
          <span>Food</span>
        </a>
        <span *ngIf="selectedServices['food'][1] === 1" class="badge badge-pill badge-danger">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        <span *ngIf="selectedServices['food'][1] === 4" class="badge badge-pill badge-success">
          <i class="fas fa-check-double"></i>
        </span>
        <span *ngIf="selectedServices['food'][1] === 2" class="badge badge-pill badge-success">
          <i class="fas fa-check"></i>
        </span>
      </li>
      <li>
        <a class="list-icons" [routerLink]="['/products']" [routerLinkActive]="['active']">
          <i class="fas fa-suitcase"></i>
          <span>Products</span>
        </a>
      </li>
      <li>
        <a class="list-icons" [routerLink]="['/pre-departure']" [routerLinkActive]="['active']">
          <i class="fas fa-suitcase"></i>
          <span>Pre-Departure</span>
        </a>
      </li>
      <li *ngIf="this.selectedServices['cellInternet'][0] == true">
        <a class="list-icons" [routerLink]="['/mobile-plans']" [routerLinkActive]="['active']">
          <i class="fas fa-mobile"></i>
          <span>Mobile Plans</span>
        </a>
      </li>
      <li>
        <a class="list-icons" [routerLink]="['/prepare-for-canada']" [routerLinkActive]="['active']">
          <i class="fas fa-plane-arrival"></i>
          <span>Arrive in Canada</span>
        </a>
      </li>
      <li *ngIf="this.selectedServices['studentJob'][0] == true">
        <a class="list-icons" [routerLink]="['/jobs']" [routerLinkActive]="['active']">
          <i class="fas fa-building"></i>
          <span>Student Jobs</span>
        </a>
        <span *ngIf="selectedServices['studentJob'][1] === 4" class="badge badge-pill badge-success">
          <i class="fas fa-check"></i>
        </span>
      </li>
      <li *ngIf="this.selectedServices['primmigration'][0] == true">
        <a class="list-icons" [routerLink]="['/permanent-resident']" [routerLinkActive]="['active']" [ngClass]="
        {
          'danger-bg': this.selectedServices['primmigration'][1] === 1, 
          'success-bg': this.selectedServices['primmigration'][1] === 4, 
          'success-full-bg': this.selectedServices['primmigration'][1] === 2 }
        ">
          <i class="fas fa-passport"></i>
          <span>Immigration: PR</span>
        </a>
        <span *ngIf="selectedServices['primmigration'][1] === 4" class="badge badge-pill badge-success">
          <i class="fas fa-check"></i>
        </span>
      </li>
      <li class="open">
        <a class="list-icons">
          <i class="fas fa-check-circle"></i>
          <span>Journey Complete</span>
        </a>
      </li>
    </ul>
  </div>
</div>
