export class ProgramsListUniversity {
    bachelorListUniversity() {
        const bachelorList = [
            {
                "id": 1,
                "itemName": "Doctor of Dental Surgery (D.D.S.)"
            },
            {
                "id": 2,
                "itemName": "Combined Degree Program: Law, Juris Doctor/Management, Master of Business Administration"
            },
            {
                "id": 3,
                "itemName": "Combined Degree Program: Law, Juris Doctor/Master of Social Work"
            },
            {
                "id": 4,
                "itemName": "Combined Degree Program: Law, Juris Doctor/Criminology and Sociolegal Studies, Master of Arts"
            },
            {
                "id": 5,
                "itemName": "Combined Degree Program: Law, Juris Doctor/European and Russian Affairs, Master of Arts"
            },
            {
                "id": 6,
                "itemName": "Combined Degree Program: Law, Juris Doctor/Economics, Master of Economics"
            },
            {
                "id": 7,
                "itemName": "B.Sc. in Medical Radiation Sciences"
            },
            {
                "id": 8,
                "itemName": "H.B.Sc. in Physical Sciences (Major)"
            },
            {
                "id": 9,
                "itemName": "H.B.Sc. in Environmental Science (Major, Minor)"
            },
            {
                "id": 10,
                "itemName": "H.B.Sc. in Biological Chemistry (Specialist)"
            },
            {
                "id": 11,
                "itemName": "H.B.Sc. in Computer Science (Specialist, Major, Minor)"
            },
            {
                "id": 12,
                "itemName": "H.B.Sc. in Statistics (Specialist, Major, Minor)"
            },
            {
                "id": 13,
                "itemName": "H.B.A. in Women and Gender Studies (Specialist, Major, Minor)"
            },
            {
                "id": 14,
                "itemName": "H.B.Sc. in Psychology (Specialist, Major, Minor)"
            },
            {
                "id": 15,
                "itemName": "H.B.A. in Religion (Specialist, Major, Minor)"
            },
            {
                "id": 16,
                "itemName": "H.B.A. in Religion: Christian Origins (Specialist)"
            },
            {
                "id": 17,
                "itemName": "H.B.A. in Cinema Studies (Specialist, Major, Minor)"
            },
            {
                "id": 18,
                "itemName": "H.B.Sc. in Materials Science (Specialist)"
            },
            {
                "id": 19,
                "itemName": "H.B.A. in Hungarian Studies (Major, Minor)"
            },
            {
                "id": 20,
                "itemName": "H.B.A. in Latin American Studies (Major, Minor)"
            },
            {
                "id": 21,
                "itemName": "H.B.A. in Urban Studies (Specialist, Major, Minor)"
            },
            {
                "id": 22,
                "itemName": "H.B.Sc. in Physiology (Specialist, Major, Minor)"
            },
            {
                "id": 23,
                "itemName": "H.B.Sc. in Biochemistry (Specialist, Major)"
            },
            {
                "id": 24,
                "itemName": "H.B.Sc. in Immunology (Specialist, Major, Minor)"
            },
            {
                "id": 25,
                "itemName": "H.B.Sc. in Nutritional Sciences (Major)"
            },
            {
                "id": 26,
                "itemName": "H.BSc. in Pharmacology (Specialist, Major)"
            },
            {
                "id": 27,
                "itemName": "H.B.Sc. in Statistics and Mathematics (Specialist)"
            },
            {
                "id": 28,
                "itemName": "H.B.Sc. in Actuarial Science (Specialist, Major)"
            },
            {
                "id": 29,
                "itemName": "H.B.Sc. in Earth and Environmental Systems (Specialist, Major, Minor)"
            },
            {
                "id": 30,
                "itemName": "H.B.A. in East Asian Studies (Specialist, Major, Minor)"
            },
            {
                "id": 31,
                "itemName": "H.B.Sc. in Biological Physics (Specialist)"
            },
            {
                "id": 32,
                "itemName": "H.B.Sc. in Astronomy and Astrophysics (Major, Minor)"
            },
            {
                "id": 33,
                "itemName": "H.B.Sc. in Ecology and Evolutionary Biology (Specialist, Major)"
            },
            {
                "id": 34,
                "itemName": "H.B.Sc. in Geology (Specialist)"
            },
            {
                "id": 35,
                "itemName": "H.B.Sc. in Geophysics (Specialist)"
            },
            {
                "id": 36,
                "itemName": "H.B.A. in History (Specialist, Major, Minor)"
            },
            {
                "id": 37,
                "itemName": "H.B.Sc. in Mathematics and Its Applications (Specialist)"
            },
            {
                "id": 38,
                "itemName": "H.B.Sc. in Mathematics and Philosophy (Specialist)"
            },
            {
                "id": 39,
                "itemName": "H.B.Sc. in Mathematics and Physics (Specialist)"
            },
            {
                "id": 40,
                "itemName": "H.B.A. in Near and Middle Eastern Civilizations (Specialist, Major, Minor)"
            },
            {
                "id": 41,
                "itemName": "H.B.A. in Bioethics (Specialist, Major, Minor)"
            },
            {
                "id": 42,
                "itemName": "H.B.Sc. in Physics (Specialist, Major, Minor)"
            },
            {
                "id": 43,
                "itemName": "H.B.Sc. in Statistical Science: Theory and Methods (Specialist)"
            },
            {
                "id": 44,
                "itemName": "H.B.A. in Cognitive Science (Major)"
            },
            {
                "id": 45,
                "itemName": "H.B.Sc. in Astronomy and Physics (Specialist)"
            },
            {
                "id": 46,
                "itemName": "H.B.A. in Philosophy (Specialist, Major, Minor)"
            },
            {
                "id": 47,
                "itemName": "H.B.Sc. in Forest Conservation Science (Specialist, Major, Minor)"
            },
            {
                "id": 48,
                "itemName": "H.B.A. in Finnish Studies (Major, Minor)"
            },
            {
                "id": 49,
                "itemName": "H.B.A. in German Studies (Specialist, Major, Minor)"
            },
            {
                "id": 50,
                "itemName": "H.B.Sc. in Applied Mathematics (Specialist)"
            },
            {
                "id": 51,
                "itemName": "H.B.Sc. in Geography (Specialist, Major, Minor)"
            },
            {
                "id": 52,
                "itemName": "H.B.A. in Art History (Specialist, Major, Minor)"
            },
            {
                "id": 53,
                "itemName": "H.B.A. in English (Specialist, Major, Minor)"
            },
            {
                "id": 54,
                "itemName": "H.B.A. in Economics (Specialist, Major, Minor)"
            },
            {
                "id": 55,
                "itemName": "H.B.Sc. in Chemistry (Specialist, Major, Minor)"
            },
            {
                "id": 56,
                "itemName": "H.B.A. in Canadian Studies (Major, Minor)"
            },
            {
                "id": 57,
                "itemName": "H.B.Sc. in Anthropology (Specialist, Major)"
            },
            {
                "id": 58,
                "itemName": "H.B.A. in Theatre and Drama Studies (Specialist)"
            },
            {
                "id": 59,
                "itemName": "H.B.Sc. in Applied Statistics (Specialist, Major, Minor)"
            },
            {
                "id": 60,
                "itemName": "H.B.Sc. in Biology (Specialist, Major, Minor)"
            },
            {
                "id": 61,
                "itemName": "H.B.Sc. in Astronomical Sciences (Specialist)"
            },
            {
                "id": 62,
                "itemName": "H.B.Sc. in Astronomy (Major)"
            },
            {
                "id": 63,
                "itemName": "B.Com. in Economics (Specialist)"
            },
            {
                "id": 64,
                "itemName": "H.B.A. in Commerce (Major)"
            },
            {
                "id": 65,
                "itemName": "H.B.A. in Classical Civilization (Major, Minor)"
            },
            {
                "id": 66,
                "itemName": "H.B.A in Art and Art History (Specialist, Major)"
            },
            {
                "id": 67,
                "itemName": "H.B.Sc. in Earth Science (Specialist, Major, Minor)"
            },
            {
                "id": 68,
                "itemName": "H.B.A. in Economics and Political Science (Specialist)"
            },
            {
                "id": 69,
                "itemName": "H.B.A. in History and Political Science (Specialist)"
            },
            {
                "id": 70,
                "itemName": "H.B.Sc. in Geographical Information Systems (Major, Minor)"
            },
            {
                "id": 71,
                "itemName": "H.B.A. in Geography (Specialist, Major, Minor)"
            },
            {
                "id": 72,
                "itemName": "H.B.Sc. in Forensic Science (Major, Minor)"
            },
            {
                "id": 73,
                "itemName": "H.B.A. in Italian (Specialist, Major, Minor)"
            },
            {
                "id": 74,
                "itemName": "H.B.Sc. in Molecular Biology (Specialist)"
            },
            {
                "id": 75,
                "itemName": "H.B.A. in Sociology (Specialist, Major, Minor)"
            },
            {
                "id": 76,
                "itemName": "H.B.A. in Political Science (Specialist, Major, Minor)"
            },
            {
                "id": 77,
                "itemName": "H.B.Sc. in Biochemistry (Major)"
            },
            {
                "id": 78,
                "itemName": "H.B.Sc. in Neuroscience (Specialist, Major)"
            },
            {
                "id": 79,
                "itemName": "H.B.Sc. in Molecular Genetics and Microbiology (Specialist, Major)"
            },
            {
                "id": 80,
                "itemName": "H.B.A. in Theatre and Performance (Major, Minor)"
            },
            {
                "id": 81,
                "itemName": "H.B.A. in English (Specialist, Major)"
            },
            {
                "id": 82,
                "itemName": "H.B.A. in Studio (Specialist, Major, Minor)"
            },
            {
                "id": 83,
                "itemName": "H.B.A. in French (Specialist, Major, Minor)"
            },
            {
                "id": 84,
                "itemName": "H.B.A. in Linguistics (Specialist, Major, Minor)"
            },
            {
                "id": 85,
                "itemName": "H.B.A in Women's and Gender Studies (Major, Minor)"
            },
            {
                "id": 86,
                "itemName": "H.B.A. in Art History and Visual Culture (Major, Minor)"
            },
            {
                "id": 87,
                "itemName": "B.Sc. in Nursing "
            },
            {
                "id": 88,
                "itemName": "Mus.Bac.Perf Music in Performance "
            },
            {
                "id": 89,
                "itemName": "Combined Degree Program: Law, Juris Doctor/Economics, Master of Arts"
            },
            {
                "id": 90,
                "itemName": "B.Com. in Commerce(Specialist)"
            },
            {
                "id": 91,
                "itemName": "H.B.A. in Women and Gender Studies (Major, Minor)"
            },
            {
                "id": 92,
                "itemName": "H.B.A. in International Affairs (Specialist)"
            },
            {
                "id": 93,
                "itemName": "H.B.A. in Environmental Management (Specialist, Major, Minor)"
            },
            {
                "id": 94,
                "itemName": "H.B.Sc. in Behaviour, Genetics and Neurobiology (Specialist)"
            },
            {
                "id": 95,
                "itemName": "H.B.Sc. in Mathematical Sciences (Specialist, Major, Minor)"
            },
            {
                "id": 96,
                "itemName": "H.B.Sc. in Exceptionality in Human Learning (Specialist, Major)"
            },
            {
                "id": 97,
                "itemName": "H.B.A. in Forest Conservation (Specialist, Major, Minor)"
            },
            {
                "id": 98,
                "itemName": "H.B.A. in Spanish (Specialist, Major, Minor)"
            },
            {
                "id": 99,
                "itemName": "H.B.Sc. in Mathematics (Specialist, Major, Minor)"
            },
            {
                "id": 100,
                "itemName": "H.B.A. in Greek (Major, Minor)"
            },
            {
                "id": 101,
                "itemName": "H.B.A. in French Language and Literature (Specialist, Major)"
            },
            {
                "id": 102,
                "itemName": "H.B.A. in History and Philosophy of Science and Technology (Major, Minor)"
            },
            {
                "id": 103,
                "itemName": "H.B.A. in Latin (Major, Minor)"
            },
            {
                "id": 104,
                "itemName": "H.B.A. in Anthropology (General) (Major, Minor)"
            },
            {
                "id": 105,
                "itemName": "H.B.A. in Classics (Greek and Latin) (Major)"
            },
            {
                "id": 106,
                "itemName": "H.B.Sc. in Economics and Mathematics (Specialist)"
            },
            {
                "id": 107,
                "itemName": "H.B.Sc. in Environment and Health (Specialist, Major)"
            },
            {
                "id": 108,
                "itemName": "H.B.A. in Visual Studies (Specialist, Major, Minor)"
            },
            {
                "id": 109,
                "itemName": "H.B.A. in French Language and French Linguistics (Specialist, Major)"
            },
            {
                "id": 110,
                "itemName": "H.B.Sc. in Physical and Environmental Geography (Specialist, Major, Minor)"
            },
            {
                "id": 111,
                "itemName": "H.B.A. in Jewish Studies (Specialist, Major, Minor)"
            },
            {
                "id": 112,
                "itemName": "B.A. in Architectural Studies (Major)"
            },
            {
                "id": 113,
                "itemName": "H.B.A in Caribbean Studies (Specialist, Major, Minor)"
            },
            {
                "id": 114,
                "itemName": "H.B.A. in Equity Studies (Major)"
            },
            {
                "id": 115,
                "itemName": "H.B.A. in Sexual Diversity Studies (Specialist, Major, Minor)"
            },
            {
                "id": 116,
                "itemName": "Doctor of Medicine, M.D."
            },
            {
                "id": 117,
                "itemName": "B.B.A in Management (Specialist)"
            },
            {
                "id": 118,
                "itemName": "H.B.A. in Economics for Management Studies (Specialist, Major, Minor)"
            },
            {
                "id": 119,
                "itemName": "H.B.A. in African Studies (Specialist, Major, Minor)"
            },
            {
                "id": 120,
                "itemName": "H.B.A. in Christianity and Culture (Specialist, Major, Minor)"
            },
            {
                "id": 121,
                "itemName": "H.B.A. in Celtic Studies (Specialist, Major, Minor)"
            },
            {
                "id": 122,
                "itemName": "H.B.A. in Portuguese (Specialist, Major, Minor)"
            },
            {
                "id": 123,
                "itemName": "Minor in South Asian Studies (Freestanding Minor)"
            },
            {
                "id": 124,
                "itemName": "H.B.A. in Mediaeval Studies (Specialist, Major, Minor)"
            },
            {
                "id": 125,
                "itemName": "H.B.A. in Drama (Specialist, Major, Minor)"
            },
            {
                "id": 126,
                "itemName": "H.B.A. in Canadian Studies (Specialist, Major, Minor)"
            },
            {
                "id": 127,
                "itemName": "H.B.A. in Ethics, Society and Law (Major)"
            },
            {
                "id": 128,
                "itemName": "H.B.A. in International Relations (Specialist, Major)"
            },
            {
                "id": 129,
                "itemName": "H.B.A. in Literary Studies (Specialist, Major, Minor)"
            },
            {
                "id": 130,
                "itemName": "Minor in Semiotics and Communication Theory (Freestanding Minor)"
            },
            {
                "id": 131,
                "itemName": "H.B.A. in Renaissance Studies (Specialist, Major, Minor)"
            },
            {
                "id": 132,
                "itemName": "H.B.A. in American Studies (Major, Minor)"
            },
            {
                "id": 133,
                "itemName": "H.B.A. in Peace, Conflict and Justice Studies (Specialist, Major)"
            },
            {
                "id": 134,
                "itemName": "H.B.A. in Employment Relations (Specialist, Major)"
            },
            {
                "id": 135,
                "itemName": "H.B.A. in Criminology (Specialist, Major)"
            },
            {
                "id": 136,
                "itemName": "B.A.Sc. in Chemical Engineering"
            },
            {
                "id": 137,
                "itemName": "BASc in Mechanical Engineering"
            },
            {
                "id": 138,
                "itemName": "B.A.Sc. in Lassonde Mineral Engineering"
            },
            {
                "id": 139,
                "itemName": "B.A.Sc. in Civil Engineering"
            },
            {
                "id": 140,
                "itemName": "B.A.Sc. in Materials Engineering"
            },
            {
                "id": 141,
                "itemName": "B.A.Sc. in Electrical Engineering"
            },
            {
                "id": 142,
                "itemName": "The Jeffrey Skoll Combined Bachelor of Applied Science in Engineering/Master of Business Administration"
            },
            {
                "id": 143,
                "itemName": "H.B.Sc. in Biotechnology (Specialist)"
            },
            {
                "id": 144,
                "itemName": "H.B.A. in Visual Culture and Communication (Specialist)"
            },
            {
                "id": 145,
                "itemName": "H.B.A. in Digital Enterprise Management (Specialist)"
            },
            {
                "id": 146,
                "itemName": "H.B.Sc. in Forensic Anthropology (Specialist)"
            },
            {
                "id": 147,
                "itemName": "H.B.Sc. in Forensic Biology (Specialist)"
            },
            {
                "id": 148,
                "itemName": "H.B.Sc. in Forensic Psychology (Specialist)"
            },
            {
                "id": 149,
                "itemName": "H.B.A. in Theatre, Drama and Performance Studies  (Major, Minor)"
            },
            {
                "id": 150,
                "itemName": "H.B.A. in Professional Writing and Communication (Major, Minor)"
            },
            {
                "id": 151,
                "itemName": "H.B.A in Buddhist Studies (Specialist, Major)"
            },
            {
                "id": 152,
                "itemName": "H.B.A. in Physical and Human Geography (Specialist, Major)"
            },
            {
                "id": 153,
                "itemName": "H.B.A. in Public Policy (Major)"
            },
            {
                "id": 154,
                "itemName": "H.B.A. in City Studies (Specialist, Major)"
            },
            {
                "id": 155,
                "itemName": "H.B.A. in Health Studies (Major)"
            },
            {
                "id": 156,
                "itemName": "H.B.A. in International Development Studies (Specialist, Major)"
            },
            {
                "id": 157,
                "itemName": "H.B.Sc. in Physics and Astrophysics (Specialist, Major)"
            },
            {
                "id": 158,
                "itemName": "H.B.Sc. in Chemistry (Specialist, Major)"
            },
            {
                "id": 159,
                "itemName": "H.B.A in Environmental Studies (Major)"
            },
            {
                "id": 160,
                "itemName": "H.B.Sc. in Integrative Biology (Specialist)"
            },
            {
                "id": 161,
                "itemName": "B.A.Sc. in Engineering Science"
            },
            {
                "id": 162,
                "itemName": "H.B.A. in Anthropology (Society, Culture and Language) (Specialist, Major)"
            },
            {
                "id": 163,
                "itemName": "H.B.Sc. in Anthropology (Biological) (Major)"
            },
            {
                "id": 164,
                "itemName": "H.B.A. in Book and Media Studies (Major, Minor)"
            },
            {
                "id": 165,
                "itemName": "B.Com. in Finance and Economics (Specialist)"
            },
            {
                "id": 166,
                "itemName": "H.B.A in Indigenous Studies (Specialist, Major, Minor)"
            },
            {
                "id": 167,
                "itemName": "H.B.A. in Archaeology (Specialist)"
            },
            {
                "id": 168,
                "itemName": "H.B.Sc. in Chemical Physics (Specialist)"
            },
            {
                "id": 169,
                "itemName": "H.B.Sc. in Animal Physiology (Major)"
            },
            {
                "id": 170,
                "itemName": "H.B.Sc. in Environment and Toxicology (Specialist)"
            },
            {
                "id": 171,
                "itemName": "H.B.Sc. in Environmental Chemistry (Specialist, Minor)"
            },
            {
                "id": 172,
                "itemName": "H.B.A. in Health Studies (Specialist, Major)"
            },
            {
                "id": 173,
                "itemName": "H.B.Sc in Health and Disease (Specialist, Major)"
            },
            {
                "id": 174,
                "itemName": "H.B.Sc.  in Pathobiology (Specialist)"
            },
            {
                "id": 175,
                "itemName": "H.B.Sc. in Pharmacology and Biomedical Toxicology (Specialist)"
            },
            {
                "id": 176,
                "itemName": "H.B.Sc. in Pharmaceutical Chemistry (Specialist)"
            },
            {
                "id": 177,
                "itemName": "H.B.Sc. in Planetary Science (Specialist)"
            },
            {
                "id": 178,
                "itemName": "H.B.Sc. in Psychology Research (Specialist)"
            },
            {
                "id": 179,
                "itemName": "Combined Degree Program: Law, Juris Doctor/Master of Information"
            },
            {
                "id": 180,
                "itemName": "Combined Degree Program: Law, Juris Doctor/Political Science, Doctor of Political Science"
            },
            {
                "id": 181,
                "itemName": "H.B.Sc. in Forensic Chemistry (Specialist)"
            },
            {
                "id": 182,
                "itemName": "H.B.A. in French and Italian (Specialist)"
            },
            {
                "id": 183,
                "itemName": "H.B.Sc. in Physics (Major, Minor)"
            },
            {
                "id": 184,
                "itemName": "H.B.Sc. in Comparative Physiology (Specialist)"
            },
            {
                "id": 185,
                "itemName": "H.B.Sc. in Ecology and Evolution (Specialist)"
            },
            {
                "id": 186,
                "itemName": "Minor in Business German (Freestanding Minor)"
            },
            {
                "id": 187,
                "itemName": "H.B.A. in Diaspora and Transnational Studies (Major, Minor)"
            },
            {
                "id": 188,
                "itemName": "Minor in Environmental Anthropology (Freestanding Minor)"
            },
            {
                "id": 189,
                "itemName": "Minor in Environmental Economics (Freestanding Minor)"
            },
            {
                "id": 190,
                "itemName": "H.B.A. in Environmental Ethics (Major, Minor)"
            },
            {
                "id": 191,
                "itemName": "Minor in Estonian Studies (Freestanding Minor)"
            },
            {
                "id": 192,
                "itemName": "H.B.A. in European Studies (Major)"
            },
            {
                "id": 193,
                "itemName": "Minor in French as a Second Language (Freestanding Minor)"
            },
            {
                "id": 194,
                "itemName": "Minor in French Studies (Freestanding Minor)"
            },
            {
                "id": 195,
                "itemName": "Minor in Italian Culture and Communication Studies (Freestanding Minor)"
            },
            {
                "id": 196,
                "itemName": "H.B.A. in Music (Specialist, Major)"
            },
            {
                "id": 197,
                "itemName": "Minor in Music History and Culture (Freestanding Minor)"
            },
            {
                "id": 198,
                "itemName": "Minor in Russian Language (Freestanding Minor)"
            },
            {
                "id": 199,
                "itemName": "Minor in Russian Literature in Translation (Freestanding Minor)"
            },
            {
                "id": 200,
                "itemName": "H.B.Sc. in Bioinformatics and Computational Biology (Specialist)"
            },
            {
                "id": 201,
                "itemName": "H.B.Sc. in Fundamental Genetics and its Applications (Specialist, Major)"
            },
            {
                "id": 202,
                "itemName": "H.B.Sc in Philosophy and Physics (Specialist)"
            },
            {
                "id": 203,
                "itemName": "Juris Doctor (J.D.)"
            },
            {
                "id": 204,
                "itemName": "Combined Degree Program: Law, Juris Doctor/Philosophy, Doctor of Philosophy"
            },
            {
                "id": 205,
                "itemName": "Doctor of Pharmacy (Pharm.D.)"
            },
            {
                "id": 206,
                "itemName": "Minor in Cinema Studies (Freestanding Minor)"
            },
            {
                "id": 207,
                "itemName": "H.B.A. in Communication and Culture and Information Technology (Major)"
            },
            {
                "id": 208,
                "itemName": "Minor in Francophone Studies (Freestanding Minor)"
            },
            {
                "id": 209,
                "itemName": "H.B.A. in French Studies (Specialist, Major, Minor)"
            },
            {
                "id": 210,
                "itemName": "Minor in Functional French (Freestanding Minor)"
            },
            {
                "id": 211,
                "itemName": "Minor in Philosophy of Science (Freestanding Minor)"
            },
            {
                "id": 212,
                "itemName": "B.Com. in Commerce: Accounting (Specialist)"
            },
            {
                "id": 213,
                "itemName": "H.B.Sc. in Bioinformatics (Specialist)"
            },
            {
                "id": 214,
                "itemName": "H.B.Sc. in Paleontology (Major)"
            },
            {
                "id": 215,
                "itemName": "H.B.A. in Journalism (Specialist)"
            },
            {
                "id": 216,
                "itemName": "H.B.A. in Music and Culture (Major, Minor)"
            },
            {
                "id": 217,
                "itemName": "H.B.A. in New Media Studies (Major)"
            },
            {
                "id": 218,
                "itemName": "H.B.A. in Psycholinguistics (Specialist)"
            },
            {
                "id": 219,
                "itemName": "B.B.A. in Economics for Management Studies (Specialist)"
            },
            {
                "id": 220,
                "itemName": "B.B.A. in Management and Information Technology (Specialist)"
            },
            {
                "id": 221,
                "itemName": "H.B.Sc. in Biology (Major, Minor)"
            },
            {
                "id": 222,
                "itemName": "H.B.Sc. in Environmental Biology (Specialist)"
            },
            {
                "id": 223,
                "itemName": "H.B.Sc. in Environmental Chemistry (Specialist)"
            },
            {
                "id": 224,
                "itemName": "H.B.Sc. in Environmental Geoscience (Specialist)"
            },
            {
                "id": 225,
                "itemName": "H.B.Sc. in Environmental Physics (Specialist)"
            },
            {
                "id": 226,
                "itemName": "H.B.Sc. in Human Biology (Specialist, Major)"
            },
            {
                "id": 227,
                "itemName": "H.B.Sc. in Paramedicine (Specialist)"
            },
            {
                "id": 228,
                "itemName": "H.B.Sc. in Physical and Mathematical Sciences (Specialist)"
            },
            {
                "id": 229,
                "itemName": "H.B.Sc.. in Biomedical Physics (Specialist)"
            },
            {
                "id": 230,
                "itemName": "B.Com. in Commerce Finance (Specialist)"
            },
            {
                "id": 231,
                "itemName": "B.Com. in Commerce: Marketing (Specialist)"
            },
            {
                "id": 232,
                "itemName": "H.B.Sc. in Financial Economics (Specialist)"
            },
            {
                "id": 233,
                "itemName": "Minor in Bioengineering (Freestanding Minor)"
            },
            {
                "id": 234,
                "itemName": "Minor in Buddhism, Psychology and Mental Health (Freestanding Minor)"
            },
            {
                "id": 235,
                "itemName": "Minor in Christianity and Education (Freestanding Minor)"
            },
            {
                "id": 236,
                "itemName": "Minor in European Union Studies (Freestanding Minor)"
            },
            {
                "id": 237,
                "itemName": "Minor in Al and Malka Green Yiddish Program"
            },
            {
                "id": 238,
                "itemName": "H.B.A. in Islamic Studies (Major)"
            },
            {
                "id": 239,
                "itemName": "Minor in Writing and Rhetoric (Freestanding Minor)"
            },
            {
                "id": 240,
                "itemName": "B.Com. in Accounting (Specialist)"
            },
            {
                "id": 241,
                "itemName": "B.Com. in Management (Specialist)"
            },
            {
                "id": 242,
                "itemName": "H.B.Sc. in Global Health (Specialist, Major)"
            },
            {
                "id": 243,
                "itemName": "B.Sc. in Neuroscience (Specialist, Major)"
            },
            {
                "id": 244,
                "itemName": "H.B.Sc in Cell and Molecular Biology (Specialist, Major)"
            },
            {
                "id": 245,
                "itemName": "Minor in Environment and Behaviour (Freestanding Minor)"
            },
            {
                "id": 246,
                "itemName": "Minor in Environment and Energy (Freestanding Minor)"
            },
            {
                "id": 247,
                "itemName": "H.B.Sc. in Forest Biomaterials Science (Major, Minor)"
            },
            {
                "id": 248,
                "itemName": "H.B.Sc. in Mathematical Applications in Economics and Finance (Specialist)"
            },
            {
                "id": 249,
                "itemName": "H.B.A. in Language Teaching and Learning: French (Major)"
            },
            {
                "id": 250,
                "itemName": "H.B.A. in History of Religions (Specialist, Major, Minor)"
            },
            {
                "id": 251,
                "itemName": "H.B.A. in Linguistics Studies (Major, Minor)"
            },
            {
                "id": 252,
                "itemName": "Minor in Biomedical Communications (Freestanding Minor)"
            },
            {
                "id": 253,
                "itemName": "H.B.Sc. in Information Security (Specialist)"
            },
            {
                "id": 254,
                "itemName": "H.B.Sc. in Health Studies (Major)"
            },
            {
                "id": 255,
                "itemName": "Minor in Environmental Engineering (Freestanding Minor)"
            },
            {
                "id": 256,
                "itemName": "Minor in Sustainable Energy (Freestanding Minor)"
            },
            {
                "id": 257,
                "itemName": "Minor in Asian Geographies (Freestanding Minor)"
            },
            {
                "id": 258,
                "itemName": "Minor in Asian Literatures and Cultures (Freestanding Minor)"
            },
            {
                "id": 259,
                "itemName": "H.B.A. in Environmental Geography (Specialist, Major, Minor)"
            },
            {
                "id": 260,
                "itemName": "H.B.A in Environmental Studies (Major, Minor)"
            },
            {
                "id": 261,
                "itemName": "Minor in Geographic Information Systems (Freestanding Minor)"
            },
            {
                "id": 262,
                "itemName": "H.B.A. in Human Geography (Specialist, Major, Minor)"
            },
            {
                "id": 263,
                "itemName": "H.B.Sc. in Biomedical Toxicology (Specialist, Major)"
            },
            {
                "id": 264,
                "itemName": "H.B.Sc. in Synthetic and Catalytic Chemistry (Specialist)"
            },
            {
                "id": 265,
                "itemName": "H.B.Sc. in Geoscience (Major, Minor)"
            },
            {
                "id": 266,
                "itemName": "Bachelor of Science Physician Assistant (B.Sc.P.A.) "
            },
            {
                "id": 267,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Statistics"
            },
            {
                "id": 268,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Music"
            },
            {
                "id": 269,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Russian and Slavic Studies"
            },
            {
                "id": 270,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in East Asian Studies"
            },
            {
                "id": 271,
                "itemName": "Bachelor of Science in Architecture"
            },
            {
                "id": 272,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Linguistics"
            },
            {
                "id": 273,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Latin American and Caribbean Studies"
            },
            {
                "id": 274,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Jewish Studies"
            },
            {
                "id": 275,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in History"
            },
            {
                "id": 276,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Hispanic Studies"
            },
            {
                "id": 277,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in German Studies"
            },
            {
                "id": 278,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Geography"
            },
            {
                "id": 279,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in English - Drama and Theatre"
            },
            {
                "id": 280,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in English - Literature"
            },
            {
                "id": 281,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in English - Cultural Studies"
            },
            {
                "id": 282,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Economics"
            },
            {
                "id": 283,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in East Asian Studies"
            },
            {
                "id": 284,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Classics"
            },
            {
                "id": 285,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Classics"
            },
            {
                "id": 286,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Art History"
            },
            {
                "id": 287,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Anthropology"
            },
            {
                "id": 288,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Italian Studies"
            },
            {
                "id": 289,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in International Development Studies"
            },
            {
                "id": 290,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Hispanic Literature and Culture"
            },
            {
                "id": 291,
                "itemName": "Bachelor of Arts, BA (Major Concentration) German Studies"
            },
            {
                "id": 292,
                "itemName": "Bachelor of Arts, BA Faculty Program/Honours in Environment"
            },
            {
                "id": 293,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Computer Science"
            },
            {
                "id": 294,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Anthropology"
            },
            {
                "id": 295,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Latin American and Caribbean Studies"
            },
            {
                "id": 296,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in African Studies"
            },
            {
                "id": 297,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Economics"
            },
            {
                "id": 298,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Sociology"
            },
            {
                "id": 299,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Psychology"
            },
            {
                "id": 300,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Philosophy"
            },
            {
                "id": 301,
                "itemName": "Bachelor of Arts, BA Joint Honours in Mathematics"
            },
            {
                "id": 302,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Jewish Studies"
            },
            {
                "id": 303,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Political Science"
            },
            {
                "id": 304,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Linguistics"
            },
            {
                "id": 305,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Art History"
            },
            {
                "id": 306,
                "itemName": "Bachelor of Arts, BA Faculty Program in Industrial and Labour Relations"
            },
            {
                "id": 307,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Hispanic Studies"
            },
            {
                "id": 308,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Geography"
            },
            {
                "id": 309,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in History"
            },
            {
                "id": 310,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Philosophy"
            },
            {
                "id": 311,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Political Science"
            },
            {
                "id": 312,
                "itemName": "Bachelor of Arts, BA Honours in Psychology"
            },
            {
                "id": 313,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Russian"
            },
            {
                "id": 314,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Sociology"
            },
            {
                "id": 315,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Religious Studies"
            },
            {
                "id": 316,
                "itemName": "Bachelor of Education, BEd for Certified Teachers in Elementary Education - Indigenous Education"
            },
            {
                "id": 317,
                "itemName": "Concurrent Bachelor of Education in Music and Bachelor of Music (Music Education)"
            },
            {
                "id": 318,
                "itemName": "BSc Kinesiology"
            },
            {
                "id": 319,
                "itemName": "Bachelor of Education, BEd in Physical and Health Education"
            },
            {
                "id": 320,
                "itemName": "Bachelor of Education, BEd in Secondary Program"
            },
            {
                "id": 321,
                "itemName": "Bachelor of Commerce (B.Com) with Concentration in Operations Management"
            },
            {
                "id": 322,
                "itemName": "Bachelor of Commerce (B.Com) with Concentration in Accounting"
            },
            {
                "id": 323,
                "itemName": "Bachelor of Commerce (B.Com) with Concentration in Marketing"
            },
            {
                "id": 324,
                "itemName": "Bachelor of Commerce (B.Com) with Concentration in Organizational Behaviour"
            },
            {
                "id": 325,
                "itemName": "Bachelor of Commerce (B.Com) with Concentration in International Business"
            },
            {
                "id": 326,
                "itemName": "Bachelor of Commerce (B.Com) with Major Concentration in Mathematics"
            },
            {
                "id": 327,
                "itemName": "Bachelor of Commerce (B.Com) with Concentration in Labour-Management Relations  and Human Resources"
            },
            {
                "id": 328,
                "itemName": "Bachelor Music, BMus Major in Performance (Piano)"
            },
            {
                "id": 329,
                "itemName": "Bachelor of Music, BMus Major in Early Music Performance"
            },
            {
                "id": 330,
                "itemName": "Bachelor of Music, BMus Major in Composition"
            },
            {
                "id": 331,
                "itemName": "Bachelor of Music, BMus Major in Music History"
            },
            {
                "id": 332,
                "itemName": "Bachelor of Music, BMus Major in Performance Jazz (Saxophone, Trumpet, Trombone, Drums, Piano, Guitar, Bass, Voice)"
            },
            {
                "id": 333,
                "itemName": "Bachelor of Science, BSc Major in Mathematics"
            },
            {
                "id": 334,
                "itemName": "Bachelor of Science, BSc - Major in Physiology"
            },
            {
                "id": 335,
                "itemName": "Bachelor of Science, BSc Joint Major in Physiology and Physics"
            },
            {
                "id": 336,
                "itemName": "Bachelor of Science, BSc - Major in Psychology"
            },
            {
                "id": 337,
                "itemName": "Bachelor of Science, BSc Honours in Immunology (Interdepartmental)"
            },
            {
                "id": 338,
                "itemName": "Bachelor of Science, BSc -Major in Physics"
            },
            {
                "id": 339,
                "itemName": "Bachelor of Science, BSc Joint Honours in Mathematics and Computer Science"
            },
            {
                "id": 340,
                "itemName": "Bachelor of Science, BSc Honours in Applied Mathematics"
            },
            {
                "id": 341,
                "itemName": "Bachelor of Science, BSc Joint Major in Physiology and Mathematics"
            },
            {
                "id": 342,
                "itemName": "Bachelor of Theology in Religious Studies"
            },
            {
                "id": 343,
                "itemName": "Bachelor of Commerce part-time"
            },
            {
                "id": 344,
                "itemName": "Bachelor of Science, BSc Joint Honours in Mathematics and Physics"
            },
            {
                "id": 345,
                "itemName": "Bachelor of Science, BSc Honours in Probability and Statistics"
            },
            {
                "id": 346,
                "itemName": "Bachelor of Science, BSc Major in Geography"
            },
            {
                "id": 347,
                "itemName": "Bachelor of Science, BSc Major in Geology"
            },
            {
                "id": 348,
                "itemName": "Bachelor of Science, BSc Major in Biology"
            },
            {
                "id": 349,
                "itemName": "Bachelor of Science, BSc Honours in Biology"
            },
            {
                "id": 350,
                "itemName": "Bachelor of Science, BSc Major/Honours in Environment"
            },
            {
                "id": 351,
                "itemName": "Bachelor of Science, BSc Major/Honours  in Biochemistry"
            },
            {
                "id": 352,
                "itemName": "Bachelor of Science, BSc Honours in Atmospheric Science"
            },
            {
                "id": 353,
                "itemName": "Bachelor of Science, BSc Major/Honours in Anatomy and Cell Biology"
            },
            {
                "id": 354,
                "itemName": "Bachelor of Science, BSc Major in Computer Science"
            },
            {
                "id": 355,
                "itemName": "Bachelor of Science, BSc Major in Software Engineering"
            },
            {
                "id": 356,
                "itemName": "Bachelor of Science, BSc Honours in Mathematics"
            },
            {
                "id": 357,
                "itemName": "Bachelor of Science, BSc Honours in Biochemistry"
            },
            {
                "id": 358,
                "itemName": "Bachelor of Science, BSc Honours in Anatomy and Cell Biology"
            },
            {
                "id": 359,
                "itemName": "Bachelor of Science, BSc Honours in Psychology"
            },
            {
                "id": 360,
                "itemName": "Bachelor of Science, BSc - Major in Chemistry"
            },
            {
                "id": 361,
                "itemName": "Bachelor of Science, BSc Honours in Geography"
            },
            {
                "id": 362,
                "itemName": "Bachelor of Science, BSc Joint Major in Atmospheric Science and Physics"
            },
            {
                "id": 363,
                "itemName": "Bachelor of Science, BSc Joint Major in Physics and Geophysics"
            },
            {
                "id": 364,
                "itemName": "Bachelor of Science, BSc - Major in Microbiology and Immunology"
            },
            {
                "id": 365,
                "itemName": "Bachelor of Science, BSc Honours in Microbiology and Immunology"
            },
            {
                "id": 366,
                "itemName": "BSc (Rehab.Sc.); Major in (Occupational Therapy)"
            },
            {
                "id": 367,
                "itemName": "BSc (Rehab.Sc); Major (Physical Therapy)"
            },
            {
                "id": 368,
                "itemName": "Bachelor of Science in Nursing"
            },
            {
                "id": 369,
                "itemName": "Concurrent BMus and BEd"
            },
            {
                "id": 370,
                "itemName": "Bachelor of Music, BMus Major in Theory"
            },
            {
                "id": 371,
                "itemName": "Bachelor of Commerce (B.Com) with Major in Economics"
            },
            {
                "id": 372,
                "itemName": "Bachelor of Commerce (B.Com) with Concentration in Information Systems - Digital Innovation"
            },
            {
                "id": 373,
                "itemName": "Bachelor of Commerce (B.Com) with Concentration in Strategic Management"
            },
            {
                "id": 374,
                "itemName": "Bachelor of Commerce (B.Com) with Concentration in Entrepreneurship"
            },
            {
                "id": 375,
                "itemName": "Bachelor of Commerce (B.Com.) with Concentration in Finance"
            },
            {
                "id": 376,
                "itemName": "Bachelor of Civil Law (B.C.L.) and Juris Doctor (J.D.)"
            },
            {
                "id": 377,
                "itemName": "Bachelor of Engineering, BEng in Mechanical Engineering (Honours)"
            },
            {
                "id": 378,
                "itemName": "Bachelor of Engineering, BEng in Electrical Engineering (Honours)"
            },
            {
                "id": 379,
                "itemName": "Bachelor of Engineering, BEng in Mining Engineering - Co-op Program"
            },
            {
                "id": 380,
                "itemName": "Bachelor of Engineering, BEng in Materials Engineering - Co-op Program"
            },
            {
                "id": 381,
                "itemName": "Bachelor of Engineering, BEng in Electrical Engineering"
            },
            {
                "id": 382,
                "itemName": "Bachelor of Engineering, BEng in Chemical Engineering"
            },
            {
                "id": 383,
                "itemName": "Bachelor of Engineering, BEng in Civil Engineering"
            },
            {
                "id": 384,
                "itemName": "Bachelor of Engineering, BEng in Computer Engineering"
            },
            {
                "id": 385,
                "itemName": "Bachelor of Engineering, BEng in Mechanical Engineering"
            },
            {
                "id": 386,
                "itemName": "Bachelor of Education, BEd in Kindergarten and Elementary Education"
            },
            {
                "id": 387,
                "itemName": "BSc (FSc) Bachelor of Science, Major in Food Science"
            },
            {
                "id": 388,
                "itemName": "BSc (AgEnvSc) Bachelor of Science in Agricultural and Environmental Science, Major in Environmental Biology"
            },
            {
                "id": 389,
                "itemName": "BSc (AgEnvSc) Bachelor of Science Agricultural and Environmental Science, Major in Agricultural Economics"
            },
            {
                "id": 390,
                "itemName": "BSc (AgEnvSc) Bachelor of Science in Agricultural and Environmental Science, Major in Life Sciences (Biological and Agricultural)"
            },
            {
                "id": 391,
                "itemName": "BSc (Ag EnvSc) Bachelor of Science in Agricultural and Environmental Sciences, Major in Agro-Environmental Science"
            },
            {
                "id": 392,
                "itemName": "BSc (NutrSc) Bachelor of Science in Nutritional Sciences, Major in Nutrition"
            },
            {
                "id": 393,
                "itemName": "BSc (NutrSc) Bachelor of Science in Nutritional Sciences, Major in Dietetics"
            },
            {
                "id": 394,
                "itemName": "BSc Major in Environment"
            },
            {
                "id": 395,
                "itemName": "BA Faculty Program in Environment"
            },
            {
                "id": 396,
                "itemName": "Bachelor of Social Work"
            },
            {
                "id": 397,
                "itemName": "Bachelor of Science in Nutritional Sciences"
            },
            {
                "id": 398,
                "itemName": "Bachelor of Education, BEd in Teaching English as a Second Language"
            },
            {
                "id": 399,
                "itemName": "Bachelor of Engineering, BEng in Mining Engineering"
            },
            {
                "id": 400,
                "itemName": "Bachelor of Arts and Science (BA & Sc)"
            },
            {
                "id": 401,
                "itemName": "Bachelor of Engineering, Major in Bioresource Engineering"
            },
            {
                "id": 402,
                "itemName": "BSc (AgEnvSc) Bachelor of Science in Agricultural and Environmental Sciences, Major in Environment"
            },
            {
                "id": 403,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in English"
            },
            {
                "id": 404,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in International Development Studies"
            },
            {
                "id": 405,
                "itemName": "Bachelor of Commerce (B.Com) with Major in Accounting"
            },
            {
                "id": 406,
                "itemName": "Bachelor of Commerce (B.Com) with Major in Finance"
            },
            {
                "id": 407,
                "itemName": "Bachelor of Commerce (B.Com) with Major in Information Systems"
            },
            {
                "id": 408,
                "itemName": "Bachelor of Commerce (B.Com) with Major in Labour-Management Relations and Human Resources"
            },
            {
                "id": 409,
                "itemName": "Bachelor of Commerce (B.Com) with Major in Marketing"
            },
            {
                "id": 410,
                "itemName": "Bachelor of Commerce (B.Com) with Joint Honours in Economics and Accounting"
            },
            {
                "id": 411,
                "itemName": "Bachelor of Commerce (B.Com) with Joint Honours in Economics and Finance"
            },
            {
                "id": 412,
                "itemName": "Bachelor of Commerce (B.Com) with Honours in Economics"
            },
            {
                "id": 413,
                "itemName": "Bachelor of Science, BSc Honours in Chemistry"
            },
            {
                "id": 414,
                "itemName": "Bachelor of Arts and Science (BA & Sc) Interfaculty Program in Cognitive Science"
            },
            {
                "id": 415,
                "itemName": "Bachelor of Nursing (Integrated)"
            },
            {
                "id": 416,
                "itemName": "Bachelor of Commerce (B.Com) with Major Concentration in Statistics (for Management students)"
            },
            {
                "id": 417,
                "itemName": "Doctor of Medicine and Master of Surgery (MD, CM)"
            },
            {
                "id": 418,
                "itemName": "Bachelor of Science, BSc, Liberal Program"
            },
            {
                "id": 419,
                "itemName": "Bachelor of Arts and Science (BA & Sc) Interfaculty/Honours Program in Environment"
            },
            {
                "id": 420,
                "itemName": "BA en français - Spécialisation enrichie langue et littérature françaises - études et pratiques littéraires"
            },
            {
                "id": 421,
                "itemName": "BA en français avec concentration majeure en lettres et traduction"
            },
            {
                "id": 422,
                "itemName": "Doctor of Dental Medicine (DMD)"
            },
            {
                "id": 423,
                "itemName": "Concurrent Bachelor of Science in Food Science [BSc(FSc)] and Bachelor of Science in Nutritional Sciences [BSc(NutrSc)]"
            },
            {
                "id": 424,
                "itemName": "Bachelor of Arts, BA Joint Honours in Economics and Accounting"
            },
            {
                "id": 425,
                "itemName": "Bachelor of Arts, BA Joint Honours in Economics and Finance"
            },
            {
                "id": 426,
                "itemName": "Bachelor of Commerce (B.Com) with Honours in Investment Management"
            },
            {
                "id": 427,
                "itemName": "Bachelor of Science, BSc Joint Major in Computer Science and Biology"
            },
            {
                "id": 428,
                "itemName": "Bachelor of Commerce (B.Com) with Major in International Management"
            },
            {
                "id": 429,
                "itemName": "Bachelor of Music, BMus Major in Performance (Organ, Guitar)"
            },
            {
                "id": 430,
                "itemName": "Bachelor of Music, BMus Major in Performance (Voice)"
            },
            {
                "id": 431,
                "itemName": "Bachelor of Music, BMus Major in Performance (Orchestral Instruments)"
            },
            {
                "id": 432,
                "itemName": "Bachelor of Theology in Religious Studies, Honours"
            },
            {
                "id": 433,
                "itemName": "Bachelor of Science, BSc Honours in Planetary Sciences"
            },
            {
                "id": 434,
                "itemName": "Bachelor of Science, BSc Joint Major in Biology and Mathematics"
            },
            {
                "id": 435,
                "itemName": "Bachelor of Science, BSc Joint Honours in Physics and Computer Science"
            },
            {
                "id": 436,
                "itemName": "Bachelor of Science, BSc Joint Major in Mathematics and Computer Science"
            },
            {
                "id": 437,
                "itemName": "Bachelor of Science, BSc Joint Major in Statistics and Computer Science"
            },
            {
                "id": 438,
                "itemName": "Bachelor of Science, BSc Honours in Computer Science"
            },
            {
                "id": 439,
                "itemName": "BEng (Bioresource) Major in Bioresource Engineering; Professional Agrology"
            },
            {
                "id": 440,
                "itemName": "FAES Internship Option"
            },
            {
                "id": 441,
                "itemName": "BSc (AgEnvSc) Bachelor of Science in Agricultural and Environmental Sciences; Specialization in Agri-Business"
            },
            {
                "id": 442,
                "itemName": "BSc (AgEnvSc) Bachelor of Science in Agricultural and Environmental Sciences; Specialization in Environmental Economics"
            },
            {
                "id": 443,
                "itemName": "BSc (AgEnvSc) Bachelor of Science in Agricultural and Environmental Sciences; Specialization Professional Agrology"
            },
            {
                "id": 444,
                "itemName": "Bachelor of Arts and Science (BA & Sc), Interfaculty program in Sustainability, Science and Society"
            },
            {
                "id": 445,
                "itemName": "Bachelor of Music, BMus Major in Early Music Performance (Voice)"
            },
            {
                "id": 446,
                "itemName": "Bachelor of Music, Faculty Program Music"
            },
            {
                "id": 447,
                "itemName": "Bachelor of Science, BSc Major in Pharmacology"
            },
            {
                "id": 448,
                "itemName": "Bachelor of Science, BSc Honours in Pharmacology"
            },
            {
                "id": 449,
                "itemName": "Bachelor of Science, BSc Honours in Software Engineering"
            },
            {
                "id": 450,
                "itemName": "Bachelor of Arts and Science (BA & Sc), interfaculty Program in Sustainability, Science and Society"
            },
            {
                "id": 451,
                "itemName": "B.C.L./LL.B. (programme combiné)"
            },
            {
                "id": 452,
                "itemName": "Bachelor of Commerce (B.Com) with Major in Organizational Behaviour"
            },
            {
                "id": 453,
                "itemName": "BSc (AgEnvSc) Bachelor of Science in Agricultural and Environmental Sciences, Major in Global Food Security"
            },
            {
                "id": 454,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Liberal Arts"
            },
            {
                "id": 455,
                "itemName": "Bachelor of Science B Sc. Honours in Neuroscience"
            },
            {
                "id": 456,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Liberal Arts"
            },
            {
                "id": 457,
                "itemName": "Bachelor of Arts, BA Joint Honours in African Studies Component"
            },
            {
                "id": 458,
                "itemName": "Bachelor of Commerce (B.Com) with Concentration in Managing for Sustainability"
            },
            {
                "id": 459,
                "itemName": "Bachelor of Commerce (B.Com) with Major in Managing for Sustainability"
            },
            {
                "id": 460,
                "itemName": "Bachelor of Commerce (B.Com) with Major in Strategic Management"
            },
            {
                "id": 461,
                "itemName": "Bachelor of Arts, BA (Major concentration) in World Islamic & Middle East Studies"
            },
            {
                "id": 462,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in World Islamic & Middle East Studies"
            },
            {
                "id": 463,
                "itemName": "Bachelor of Commerce (B.Com.) with Concentration in Information Systems - IT for Business"
            },
            {
                "id": 464,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Gender, Sexuality, Feminist, and Social Justice Studies"
            },
            {
                "id": 465,
                "itemName": "Bachelor of Arts, BA (Joint Honours) in Gender, Sexuality, Feminist, and Social Justice Studies"
            },
            {
                "id": 466,
                "itemName": "Bachelor of Arts, BA (Honours) in Gender, Sexuality, Feminist, and Social Justice Studies"
            },
            {
                "id": 467,
                "itemName": "Bachelor of Arts, BA Honours/Joint Honours in Gender, Sexuality, Feminist & Social Justice Studies"
            },
            {
                "id": 468,
                "itemName": "Bachelor of Engineering, BEng in Bioengineering"
            },
            {
                "id": 469,
                "itemName": "Bachelor of Engineering, Software Engineering"
            },
            {
                "id": 470,
                "itemName": "Bachelor of Science, BSc Honours in Computer Science and Biology"
            },
            {
                "id": 471,
                "itemName": "Bachelor of Science, BSc Joint Major in Physics and Computer Science"
            },
            {
                "id": 472,
                "itemName": "BSc (AgEnvSc) Bachelor of Science in Agricultural and Environmental Sciences; Specialization Professional Agrology for Agri-business"
            },
            {
                "id": 473,
                "itemName": "Bachelor of Arts, BA (Major Concentration) in Geography (Urban Studies)"
            },
            {
                "id": 474,
                "itemName": "Bachelor of Education for Certified Teachers - Elementary Education: Indigenous Education"
            },
            {
                "id": 475,
                "itemName": "BSc Honours in Biology"
            },
            {
                "id": 476,
                "itemName": "BSc Honours in Psychology"
            },
            {
                "id": 477,
                "itemName": "BSc Honours in Physics"
            },
            {
                "id": 478,
                "itemName": "Diploma or Minor in Peace and Conflict Studies"
            },
            {
                "id": 479,
                "itemName": "BSc Honours in Biotechnology/Chartered Professional Accountancy"
            },
            {
                "id": 480,
                "itemName": "Doctor of Optometry/MSc in Vision Science"
            },
            {
                "id": 481,
                "itemName": "BSc Honours in Science and Business"
            },
            {
                "id": 482,
                "itemName": "BSc Honours in Biochemistry"
            },
            {
                "id": 483,
                "itemName": "Doctor of Optometry (OD)"
            },
            {
                "id": 484,
                "itemName": "BSc Honours in Environmental Sciences"
            },
            {
                "id": 485,
                "itemName": "BSc Honours in Earth Sciences"
            },
            {
                "id": 486,
                "itemName": "BSc Honours in Chemistry"
            },
            {
                "id": 487,
                "itemName": "BA Honours in Economics"
            },
            {
                "id": 488,
                "itemName": "BA Honours in Classical Studies"
            },
            {
                "id": 489,
                "itemName": "BA Honours in Anthropology"
            },
            {
                "id": 490,
                "itemName": "BSc Honours in Kinesiology"
            },
            {
                "id": 491,
                "itemName": "BSc Honours in Health Studies"
            },
            {
                "id": 492,
                "itemName": "BA Honours in Recreation and Leisure Studies"
            },
            {
                "id": 493,
                "itemName": "BASc in Civil Engineering"
            },
            {
                "id": 494,
                "itemName": "BASc in Chemical Engineering"
            },
            {
                "id": 495,
                "itemName": "BASc in Electrical Engineering"
            },
            {
                "id": 496,
                "itemName": "BASc in Systems Design Engineering"
            },
            {
                "id": 497,
                "itemName": "BASc in Computer Engineering"
            },
            {
                "id": 498,
                "itemName": "BASc in Geological Engineering"
            },
            {
                "id": 499,
                "itemName": "BA/BEd Honours in French - French Teaching Specialization"
            },
            {
                "id": 500,
                "itemName": "BA Honours in Arts and Business"
            },
            {
                "id": 501,
                "itemName": "BA General in Gender and Social Justice"
            },
            {
                "id": 502,
                "itemName": "BA Honours in Medieval Studies"
            },
            {
                "id": 503,
                "itemName": "BA General in Medieval Studies"
            },
            {
                "id": 504,
                "itemName": "BA Honours in Spanish"
            },
            {
                "id": 505,
                "itemName": "BA Honours in Sociology"
            },
            {
                "id": 506,
                "itemName": "BA Honours in Psychology"
            },
            {
                "id": 507,
                "itemName": "BA Honours in Political Science"
            },
            {
                "id": 508,
                "itemName": "BA Honours in Philosophy"
            },
            {
                "id": 509,
                "itemName": "BA Honours in Music"
            },
            {
                "id": 510,
                "itemName": "BA Honours in History"
            },
            {
                "id": 511,
                "itemName": "BA Honours in German"
            },
            {
                "id": 512,
                "itemName": "BA General in Fine Arts - General Studio Art"
            },
            {
                "id": 513,
                "itemName": "BA Honours in English Literature"
            },
            {
                "id": 514,
                "itemName": "BA General in Economics"
            },
            {
                "id": 515,
                "itemName": "BA General in Anthropology"
            },
            {
                "id": 516,
                "itemName": "BA General in Sociology"
            },
            {
                "id": 517,
                "itemName": "BA General in Political Science"
            },
            {
                "id": 518,
                "itemName": "BA General in Philosophy"
            },
            {
                "id": 519,
                "itemName": "BA General in Music"
            },
            {
                "id": 520,
                "itemName": "BA General in Communication Studies"
            },
            {
                "id": 521,
                "itemName": "BA General in Classical Studies"
            },
            {
                "id": 522,
                "itemName": "BA General in German"
            },
            {
                "id": 523,
                "itemName": "BA General in Spanish"
            },
            {
                "id": 524,
                "itemName": "BA General in Religious Studies"
            },
            {
                "id": 525,
                "itemName": "Bachelor of Accounting and Financial Management (BAFM) in Accounting and Financial Management"
            },
            {
                "id": 526,
                "itemName": "BA General in Social Development Studies"
            },
            {
                "id": 527,
                "itemName": "BA General in French"
            },
            {
                "id": 528,
                "itemName": "BMath in Mathematics - Teaching"
            },
            {
                "id": 529,
                "itemName": "BMath Honours in Actuarial Science"
            },
            {
                "id": 530,
                "itemName": "BMath Honours in Mathematics/Business Administration"
            },
            {
                "id": 531,
                "itemName": "BMath Honours in Applied Mathematics"
            },
            {
                "id": 532,
                "itemName": "BMath Honours in Mathematical Optimization"
            },
            {
                "id": 533,
                "itemName": "BMath Honours in Combinatorics and Optimization"
            },
            {
                "id": 534,
                "itemName": "BMath Honours in Data Science"
            },
            {
                "id": 535,
                "itemName": "BMath Honours in Mathematical Studies"
            },
            {
                "id": 536,
                "itemName": "BMath Honours in Pure Mathematics"
            },
            {
                "id": 537,
                "itemName": "BMath Honours in Mathematics/Chartered Professional Accountancy"
            },
            {
                "id": 538,
                "itemName": "BMath Honours in Statistics"
            },
            {
                "id": 539,
                "itemName": "Bachelor of Environmental Studies (BES) Honours in Geography and Environmental Management"
            },
            {
                "id": 540,
                "itemName": "Bachelor of Environmental Studies (BES) Honours in Environment, Resources and Sustainability"
            },
            {
                "id": 541,
                "itemName": "Bachelor of Environmental Studies (BES) Honours in Planning"
            },
            {
                "id": 542,
                "itemName": "BA Honours in Recreation and Sport Business"
            },
            {
                "id": 543,
                "itemName": "BA General in English Language and Literature"
            },
            {
                "id": 544,
                "itemName": "BA Honours in Theatre and Performance"
            },
            {
                "id": 545,
                "itemName": "BA Honours in Communication Studies"
            },
            {
                "id": 546,
                "itemName": "BA General in Sexuality, Marriage, and Family Studies"
            },
            {
                "id": 547,
                "itemName": "BASc in Environmental Engineering"
            },
            {
                "id": 548,
                "itemName": "BASc in Mechatronics Engineering"
            },
            {
                "id": 549,
                "itemName": "BBA/BMath in Business Administration (Laurier) and Mathematics (Waterloo) double degree"
            },
            {
                "id": 550,
                "itemName": "Bachelor of Computer Science (BCS) Honours in Computer Science"
            },
            {
                "id": 551,
                "itemName": "BMath Honours in Computational Mathematics"
            },
            {
                "id": 552,
                "itemName": "BSc Honours Science"
            },
            {
                "id": 553,
                "itemName": "Minor in Cognitive Science"
            },
            {
                "id": 554,
                "itemName": "Minor in International Studies"
            },
            {
                "id": 555,
                "itemName": "Minor in Jewish Studies"
            },
            {
                "id": 556,
                "itemName": "Minor in Legal Studies"
            },
            {
                "id": 557,
                "itemName": "Option in Society, Technology and Values"
            },
            {
                "id": 558,
                "itemName": "Minor in Speech Communication"
            },
            {
                "id": 559,
                "itemName": "Diploma or Minor in Sexuality, Marriage and Family Studies"
            },
            {
                "id": 560,
                "itemName": "Diploma or Minor in Gender and Social Justice"
            },
            {
                "id": 561,
                "itemName": "BA General in Legal Studies"
            },
            {
                "id": 562,
                "itemName": "BASc in Nanotechnology Engineering"
            },
            {
                "id": 563,
                "itemName": "Health informatics option"
            },
            {
                "id": 564,
                "itemName": "BA Honours in English Literature and Rhetoric"
            },
            {
                "id": 565,
                "itemName": "Bachelor of Architectural Studies (BAS)"
            },
            {
                "id": 566,
                "itemName": "Bachelor of Environmental Studies (BES) Honours in Environment and Business"
            },
            {
                "id": 567,
                "itemName": "BA General in Recreation and Leisure Studies"
            },
            {
                "id": 568,
                "itemName": "BSc General in Kinesiology"
            },
            {
                "id": 569,
                "itemName": "BA Honours in French"
            },
            {
                "id": 570,
                "itemName": "BA Honours in Legal Studies"
            },
            {
                "id": 571,
                "itemName": "Bachelor of Environmental Studies (BES) General in Geography and Environmental Management"
            },
            {
                "id": 572,
                "itemName": "BSc General Science"
            },
            {
                "id": 573,
                "itemName": "BA Honours in Therapeutic Recreation"
            },
            {
                "id": 574,
                "itemName": "BA General in Liberal Studies"
            },
            {
                "id": 575,
                "itemName": "BA General in Peace and Conflict Studies"
            },
            {
                "id": 576,
                "itemName": "BA Honours in Peace and Conflict Studies"
            },
            {
                "id": 577,
                "itemName": "Bachelor of Computing and Financial Management (BCFM) Honours in Computing and Financial Management"
            },
            {
                "id": 578,
                "itemName": "BASc in Management Engineering"
            },
            {
                "id": 579,
                "itemName": "Bachelor of Software Engineering (BSE)"
            },
            {
                "id": 580,
                "itemName": "Bachelor of Environmental Studies (BES) Honours in Geography and Aviation"
            },
            {
                "id": 581,
                "itemName": "Bachelor of Environmental Studies (BES) Honours in Geomatics"
            },
            {
                "id": 582,
                "itemName": "Bachelor of Computing and Financial Management (BCFM)"
            },
            {
                "id": 583,
                "itemName": "BA Honours in English Rhetoric, Media, and Professional Communication"
            },
            {
                "id": 584,
                "itemName": "Bachelor of Knowledge Integration (BKI) Honours in Knowledge Integration"
            },
            {
                "id": 585,
                "itemName": "BSc Honours in Science and Aviation"
            },
            {
                "id": 586,
                "itemName": "Bachelor of Environmental Studies (BES) Honours in International Development"
            },
            {
                "id": 587,
                "itemName": "BMath in Mathematics/Financial Analysis and Risk Management (FARM)"
            },
            {
                "id": 588,
                "itemName": "BA General in Psychology"
            },
            {
                "id": 589,
                "itemName": "BA Honours in Mathematical Economics"
            },
            {
                "id": 590,
                "itemName": "BA Honours in Sexuality, Marriage and Family Studies"
            },
            {
                "id": 591,
                "itemName": "BMath Honours in Information Technology Management"
            },
            {
                "id": 592,
                "itemName": "BMath Honours in Mathematical Finance"
            },
            {
                "id": 593,
                "itemName": "BMath Honours in Mathematical Physics"
            },
            {
                "id": 594,
                "itemName": "Undergraduate Doctor of Pharmacy (PharmD)"
            },
            {
                "id": 595,
                "itemName": "Minor in Classical Studies"
            },
            {
                "id": 596,
                "itemName": "BBA/BCS in Business Administration (Laurier) and Computer Science (Waterloo) double degree"
            },
            {
                "id": 597,
                "itemName": "BMath Honours in Mathematical Economics"
            },
            {
                "id": 598,
                "itemName": "BMath Honours in Biostatistics"
            },
            {
                "id": 599,
                "itemName": "BSc Honours in Biomedical Sciences"
            },
            {
                "id": 600,
                "itemName": "BSc Honours in Mathematical Physics"
            },
            {
                "id": 601,
                "itemName": "BSc Honours in Medicinal Chemistry"
            },
            {
                "id": 602,
                "itemName": "Diploma or Minor in Gerontology"
            },
            {
                "id": 603,
                "itemName": "BA Honours in Tourism Development"
            },
            {
                "id": 604,
                "itemName": "BA Honours in Social Development Studies"
            },
            {
                "id": 605,
                "itemName": "Bachelor of Global Business and Digital Arts (BGBDA) Honours in Global Business and Digital Arts"
            },
            {
                "id": 606,
                "itemName": "BA Honours in Gender and Social Justice"
            },
            {
                "id": 607,
                "itemName": "Master of Development Practice (MDP)"
            },
            {
                "id": 608,
                "itemName": "BSc Honours in Life Physics"
            },
            {
                "id": 609,
                "itemName": "BSc Honours in Materials and Nanoscience"
            },
            {
                "id": 610,
                "itemName": "BA Honours in Fine Arts - Studio Practice"
            },
            {
                "id": 611,
                "itemName": "Honours Bachelor of Public Health (BPH)"
            },
            {
                "id": 612,
                "itemName": "BASc in Biomedical Engineering"
            },
            {
                "id": 613,
                "itemName": "BSc Honours in Physics and Astronomy"
            },
            {
                "id": 614,
                "itemName": "BA General in History"
            },
            {
                "id": 615,
                "itemName": "BMath Honours in Mathematics"
            },
            {
                "id": 616,
                "itemName": "BA Honours in Classics"
            },
            {
                "id": 617,
                "itemName": "BSc Honours in Geochemistry"
            },
            {
                "id": 618,
                "itemName": "Minor in Catholic Studies"
            },
            {
                "id": 619,
                "itemName": "Minor in Greek"
            },
            {
                "id": 620,
                "itemName": "Minor in Russian and East European Studies"
            },
            {
                "id": 621,
                "itemName": "Minor in Latin"
            },
            {
                "id": 622,
                "itemName": "Minor in Technical Writing"
            },
            {
                "id": 623,
                "itemName": "Minor in Mennonite Studies"
            },
            {
                "id": 624,
                "itemName": "Minor in Public Policy and Administration"
            },
            {
                "id": 625,
                "itemName": "Minor in Digital Arts Communication"
            },
            {
                "id": 626,
                "itemName": "Minor in Human Science"
            },
            {
                "id": 627,
                "itemName": "Minor in International Trade"
            },
            {
                "id": 628,
                "itemName": "BA Honours in Religious Studies"
            },
            {
                "id": 629,
                "itemName": "Bachelor of Computer Science (BCS) Honours in Data Science"
            },
            {
                "id": 630,
                "itemName": "BA General in Visual Culture"
            },
            {
                "id": 631,
                "itemName": "BA Honours in Visual Culture"
            },
            {
                "id": 632,
                "itemName": "BASc in Architectural Engineering"
            },
            {
                "id": 633,
                "itemName": "BMath Honours in Computer Science"
            },
            {
                "id": 634,
                "itemName": "BA General in English Literature"
            },
            {
                "id": 635,
                "itemName": "BA General in English Literature and Rhetoric"
            },
            {
                "id": 636,
                "itemName": "BA General in English Rhetoric, Media, and Professional Communication"
            },
            {
                "id": 637,
                "itemName": "Minor in Ergonomics and Injury Prevention"
            },
            {
                "id": 638,
                "itemName": "Minor in Economic Theory"
            },
            {
                "id": 639,
                "itemName": "Minor in Visual Culture in a Global Context"
            },
            {
                "id": 640,
                "itemName": "Minor in Human Rights"
            },
            {
                "id": 641,
                "itemName": "Minor in Anthropology"
            },
            {
                "id": 642,
                "itemName": "Minor in Canadian Studies"
            },
            {
                "id": 643,
                "itemName": "Minor in Cultural identities"
            },
            {
                "id": 644,
                "itemName": "Minor in Economics"
            },
            {
                "id": 645,
                "itemName": "Minor in English"
            },
            {
                "id": 646,
                "itemName": "Minor in Fine Arts Studio"
            },
            {
                "id": 647,
                "itemName": "Minor in German"
            },
            {
                "id": 648,
                "itemName": "Minor in History"
            },
            {
                "id": 649,
                "itemName": "Minor in Indigenous Studies"
            },
            {
                "id": 650,
                "itemName": "Minor in Music"
            },
            {
                "id": 651,
                "itemName": "Minor in Medieval Studies"
            },
            {
                "id": 652,
                "itemName": "Minor in Philosophy"
            },
            {
                "id": 653,
                "itemName": "Minor in Political Science"
            },
            {
                "id": 654,
                "itemName": "Minor in Psychology"
            },
            {
                "id": 655,
                "itemName": "Minor in Religious Studies"
            },
            {
                "id": 656,
                "itemName": "Minor in Social Development Studies"
            },
            {
                "id": 657,
                "itemName": "Minor in Sociology"
            },
            {
                "id": 658,
                "itemName": "Minor in Theatre and Performance"
            },
            {
                "id": 659,
                "itemName": "Minor in Environment, Resources and Sustainability"
            },
            {
                "id": 660,
                "itemName": "Minor in Geography and Environmental Management"
            },
            {
                "id": 661,
                "itemName": "Minor in International Development"
            },
            {
                "id": 662,
                "itemName": "BA Honours in Liberal Studies"
            },
            {
                "id": 663,
                "itemName": "Artificial Intelligence Option"
            },
            {
                "id": 664,
                "itemName": "Bioinformatics Option"
            },
            {
                "id": 665,
                "itemName": "Biomechanics Option"
            },
            {
                "id": 666,
                "itemName": "Entrepreneurship Option"
            },
            {
                "id": 667,
                "itemName": "Environmental Engineering Option"
            },
            {
                "id": 668,
                "itemName": "Mechatronics Option"
            },
            {
                "id": 669,
                "itemName": "Physical Sciences Option"
            },
            {
                "id": 670,
                "itemName": "Software Engineering Option"
            },
            {
                "id": 671,
                "itemName": "Statistics Option"
            },
            {
                "id": 672,
                "itemName": "Minor in Actuarial Science"
            },
            {
                "id": 673,
                "itemName": "Minor in Applied Math"
            },
            {
                "id": 674,
                "itemName": "Minor in Astrophysics"
            },
            {
                "id": 675,
                "itemName": "Minor in Biology"
            },
            {
                "id": 676,
                "itemName": "Minor in Biophysics"
            },
            {
                "id": 677,
                "itemName": "Minor in Chemistry"
            },
            {
                "id": 678,
                "itemName": "Minor in Combinatorics and Optimization"
            },
            {
                "id": 679,
                "itemName": "Minor in Computational Mathematics"
            },
            {
                "id": 680,
                "itemName": "Minor in Computer Science"
            },
            {
                "id": 681,
                "itemName": "Minor in Computing"
            },
            {
                "id": 682,
                "itemName": "Minor in Earth Sciences"
            },
            {
                "id": 683,
                "itemName": "Minor in Entrepreneurship"
            },
            {
                "id": 684,
                "itemName": "Minor in Event Management"
            },
            {
                "id": 685,
                "itemName": "Minor in French"
            },
            {
                "id": 686,
                "itemName": "Minor in Human Nutrition"
            },
            {
                "id": 687,
                "itemName": "Minor in Mathematics"
            },
            {
                "id": 688,
                "itemName": "Minor in Medical Physiology"
            },
            {
                "id": 689,
                "itemName": "Minor in Parks"
            },
            {
                "id": 690,
                "itemName": "Minor in Physics"
            },
            {
                "id": 691,
                "itemName": "Minor in Pure Mathematics"
            },
            {
                "id": 692,
                "itemName": "Minor in Statistics"
            },
            {
                "id": 693,
                "itemName": "Minor in Tourism"
            },
            {
                "id": 694,
                "itemName": "Minor in Urban Studies"
            },
            {
                "id": 695,
                "itemName": "Bachelor of arts and science"
            },
            {
                "id": 696,
                "itemName": "BSc in physics"
            },
            {
                "id": 697,
                "itemName": "BSc in environmental sciences"
            },
            {
                "id": 698,
                "itemName": "BSc in psychology, neuroscience & behaviour"
            },
            {
                "id": 699,
                "itemName": "BSc in chemistry"
            },
            {
                "id": 700,
                "itemName": "BHSc in biology and pharmacology"
            },
            {
                "id": 701,
                "itemName": "BSc in biochemistry"
            },
            {
                "id": 702,
                "itemName": "BSc in life sciences"
            },
            {
                "id": 703,
                "itemName": "BSc in biology"
            },
            {
                "id": 704,
                "itemName": "BSc in mathematics and statistics"
            },
            {
                "id": 705,
                "itemName": "BSc in chemical and physical sciences"
            },
            {
                "id": 706,
                "itemName": "BSc in mathematical science"
            },
            {
                "id": 707,
                "itemName": "BSc in earth and environmental science"
            },
            {
                "id": 708,
                "itemName": "Bachelor of health sciences (honours program)"
            },
            {
                "id": 709,
                "itemName": "Bachelor of science in nursing"
            },
            {
                "id": 710,
                "itemName": "Bachelor of health sciences in midwifery"
            },
            {
                "id": 711,
                "itemName": "Bachelor of commerce"
            },
            {
                "id": 712,
                "itemName": "BA in linguistics"
            },
            {
                "id": 713,
                "itemName": "BA in classics"
            },
            {
                "id": 714,
                "itemName": "BA in art history"
            },
            {
                "id": 715,
                "itemName": "Bachelor of music"
            },
            {
                "id": 716,
                "itemName": "BA in philosophy"
            },
            {
                "id": 717,
                "itemName": "BA in history"
            },
            {
                "id": 718,
                "itemName": "BA in French"
            },
            {
                "id": 719,
                "itemName": "BA in english and cultural studies"
            },
            {
                "id": 720,
                "itemName": "BA in economics"
            },
            {
                "id": 721,
                "itemName": "BA in anthropology"
            },
            {
                "id": 722,
                "itemName": "BA in labour studies"
            },
            {
                "id": 723,
                "itemName": "BA in sociology"
            },
            {
                "id": 724,
                "itemName": "BA in society, culture, and religion"
            },
            {
                "id": 725,
                "itemName": "BA in psychology, neuroscience & behaviour"
            },
            {
                "id": 726,
                "itemName": "BA in political science"
            },
            {
                "id": 727,
                "itemName": "BA in environment and society"
            },
            {
                "id": 728,
                "itemName": "Bachelor of social work"
            },
            {
                "id": 729,
                "itemName": "BA in geography and environmental studies"
            },
            {
                "id": 730,
                "itemName": "BA in multimedia"
            },
            {
                "id": 731,
                "itemName": "BA in theatre and film studies"
            },
            {
                "id": 732,
                "itemName": "BA in music"
            },
            {
                "id": 733,
                "itemName": "Doctor of medicine"
            },
            {
                "id": 734,
                "itemName": "Bachelor of engineering in electrical engineering"
            },
            {
                "id": 735,
                "itemName": "Bachelor of engineering and society in electrical engineering"
            },
            {
                "id": 736,
                "itemName": "Bachelor of engineering and management in electrical engineering"
            },
            {
                "id": 737,
                "itemName": "Bachelor of engineering in software engineering"
            },
            {
                "id": 738,
                "itemName": "Bachelor of engineering and society in civil engineering"
            },
            {
                "id": 739,
                "itemName": "Bachelor of engineering and management in civil engineering"
            },
            {
                "id": 740,
                "itemName": "Bachelor of engineering in civil engineering"
            },
            {
                "id": 741,
                "itemName": "Bachelor of engineering and society in chemical engineering"
            },
            {
                "id": 742,
                "itemName": "Bachelor of engineering and management in chemical engineering"
            },
            {
                "id": 743,
                "itemName": "Bachelor of engineering in chemical engineering"
            },
            {
                "id": 744,
                "itemName": "Bachelor of engineering in materials engineering"
            },
            {
                "id": 745,
                "itemName": "Bachelor of engineering in mechanical engineering"
            },
            {
                "id": 746,
                "itemName": "Bachelor of engineering in engineering physics"
            },
            {
                "id": 747,
                "itemName": "Bachelor of engineering and society in materials engineering"
            },
            {
                "id": 748,
                "itemName": "Bachelor of engineering and management in engineering physics"
            },
            {
                "id": 749,
                "itemName": "Bachelor of engineering and society in engineering physics"
            },
            {
                "id": 750,
                "itemName": "Bachelor of engineering and society in mechanical engineering"
            },
            {
                "id": 751,
                "itemName": "Bachelor of engineering and management in computer engineering"
            },
            {
                "id": 752,
                "itemName": "Bachelor of engineering in computer engineering"
            },
            {
                "id": 753,
                "itemName": "Bachelor of engineering in chemical engineering and bioengineering"
            },
            {
                "id": 754,
                "itemName": "BA in communications studies"
            },
            {
                "id": 755,
                "itemName": "BA in peace studies (combined honours)"
            },
            {
                "id": 756,
                "itemName": "Bachelor of engineering and society in computer engineering"
            },
            {
                "id": 757,
                "itemName": "Bachelor of engineering and management in mechanical engineering"
            },
            {
                "id": 758,
                "itemName": "BSc kinesiology"
            },
            {
                "id": 759,
                "itemName": "Bachelor of Medical Radiation Sciences"
            },
            {
                "id": 760,
                "itemName": "Bachelor of engineering and management in materials engineering"
            },
            {
                "id": 761,
                "itemName": "Bachelor of engineering and society in software engineering"
            },
            {
                "id": 762,
                "itemName": "Bachelor of engineering and management in software engineering"
            },
            {
                "id": 763,
                "itemName": "Bachelor of engineering in mechatronics engineering"
            },
            {
                "id": 764,
                "itemName": "BA in cognitive science of language"
            },
            {
                "id": 765,
                "itemName": "Bachelor of applied science in computer science"
            },
            {
                "id": 766,
                "itemName": "Bachelor of technology"
            },
            {
                "id": 767,
                "itemName": "Bachelor of engineering and society in mechatronics engineering"
            },
            {
                "id": 768,
                "itemName": "Bachelor of health sciences in biomedical science"
            },
            {
                "id": 769,
                "itemName": "BA in economics and mathematics"
            },
            {
                "id": 770,
                "itemName": "BA in economics and computer science"
            },
            {
                "id": 771,
                "itemName": "BA in indigenous studies (combined program)"
            },
            {
                "id": 772,
                "itemName": "Bachelor of engineering and management in mechatronics engineering"
            },
            {
                "id": 773,
                "itemName": "Bachelor of health sciences (physician assistant)"
            },
            {
                "id": 774,
                "itemName": "BA in philosophy and biology"
            },
            {
                "id": 775,
                "itemName": "BA in philosophy and mathematics"
            },
            {
                "id": 776,
                "itemName": "BSc in bio-diversity and environmental sciences"
            },
            {
                "id": 777,
                "itemName": "BSc in biology and mathematics"
            },
            {
                "id": 778,
                "itemName": "BSc in biology and psychology"
            },
            {
                "id": 779,
                "itemName": "BSc in chemical biology"
            },
            {
                "id": 780,
                "itemName": "BSc in integrated science"
            },
            {
                "id": 781,
                "itemName": "BSc in mathematics and computer science"
            },
            {
                "id": 782,
                "itemName": "BSc in mathematics and physics"
            },
            {
                "id": 783,
                "itemName": "BA in social psychology"
            },
            {
                "id": 784,
                "itemName": "BSc in environment and society"
            },
            {
                "id": 785,
                "itemName": "BA in Health, Aging & Society"
            },
            {
                "id": 786,
                "itemName": "Bachelor of fine arts in studio art"
            },
            {
                "id": 787,
                "itemName": "BSc in actuarial and financial mathematics"
            },
            {
                "id": 788,
                "itemName": "BA in English and cultural studies and mathematics (combined honours program)"
            },
            {
                "id": 789,
                "itemName": "BA in French and mathematics"
            },
            {
                "id": 790,
                "itemName": "BA in history and mathematics"
            },
            {
                "id": 791,
                "itemName": "BSc in astrophysics"
            },
            {
                "id": 792,
                "itemName": "MD/PhD in Biochemistry"
            },
            {
                "id": 793,
                "itemName": "B.A.Sc. in Human Behaviour"
            },
            {
                "id": 794,
                "itemName": "B.A.Sc. in Human Behaviour - Autism and Behavioural Science Specialization"
            },
            {
                "id": 795,
                "itemName": "B.A.Sc. in Human Behaviour - Early Childhood Education Specialization"
            },
            {
                "id": 796,
                "itemName": "BSc in biology (discovery sub-plan)"
            },
            {
                "id": 797,
                "itemName": "B.Sc. in neuroscience"
            },
            {
                "id": 798,
                "itemName": "BA in economics (specialist option)"
            },
            {
                "id": 799,
                "itemName": "BA in indigenous studies (single honours program)"
            },
            {
                "id": 800,
                "itemName": "BA in political science specialization in public law and judicial studies"
            },
            {
                "id": 801,
                "itemName": "Bachelor of social work (honours program)"
            },
            {
                "id": 802,
                "itemName": "Bachelor of Commerce, Integrated Business and Humanities (Honours)"
            },
            {
                "id": 803,
                "itemName": "Bachelor of engineering in Biomedical Engineering (B.Eng.BME): Integrated Biomedical Engineering and Health Sciences Program (IBEHS) Program"
            },
            {
                "id": 804,
                "itemName": "Bachelor of Health Sciences in Health, Engineering Science and Entrepreneurship (HESE): Integrated Biomedical Engineering and Health Sciences Program (IBEHS) Program"
            },
            {
                "id": 805,
                "itemName": "BSc in Medical and Biological Physics"
            },
            {
                "id": 806,
                "itemName": "BA in sociology (Specialist Option) (Honours)"
            },
            {
                "id": 807,
                "itemName": "BA in labour studies and another subject (combined honours program)"
            },
            {
                "id": 808,
                "itemName": "BA in political science and another subject (combined honours program)"
            },
            {
                "id": 809,
                "itemName": "BA in psychology, neuroscience & behaviour and another subject (combined honours program)"
            },
            {
                "id": 810,
                "itemName": "BA in society, culture, and religion and another subject (combined honours program)"
            },
            {
                "id": 811,
                "itemName": "BA in anthropology and another subject (combined honours program)"
            },
            {
                "id": 812,
                "itemName": "BA in economics and another subject (combined honours program)"
            },
            {
                "id": 813,
                "itemName": "BA in environment and society and another subject (combined honours program)"
            },
            {
                "id": 814,
                "itemName": "BA in Aging & Society"
            },
            {
                "id": 815,
                "itemName": "BA in Health and Society"
            },
            {
                "id": 816,
                "itemName": "Aging and Society and another subject (combined honours program)"
            },
            {
                "id": 817,
                "itemName": "Health and Society and another subject (combined honours program)"
            },
            {
                "id": 818,
                "itemName": "BA in Justice, Political Philosophy, and Law"
            },
            {
                "id": 819,
                "itemName": "BA in Justice, Political Philosophy, and Law with a specialized minor in commerce"
            },
            {
                "id": 820,
                "itemName": "BA in linguistics with a specialized minor in commerce"
            },
            {
                "id": 821,
                "itemName": "BA in multimedia and another subject (combined honours program)"
            },
            {
                "id": 822,
                "itemName": "BA in multimedia with a specialized minor in commerce"
            },
            {
                "id": 823,
                "itemName": "BA in music and another subject (combined honours program)"
            },
            {
                "id": 824,
                "itemName": "B.Mus. in Music Cognition"
            },
            {
                "id": 825,
                "itemName": "BA in philosophy and another subject  (combined honours program)"
            },
            {
                "id": 826,
                "itemName": "BA in philosophy with a specialized minor in commerce"
            },
            {
                "id": 827,
                "itemName": "BA in theatre and film studies and another subject (combined honours program)"
            },
            {
                "id": 828,
                "itemName": "BA in theatre and film studies with a specialized minor in commerce"
            },
            {
                "id": 829,
                "itemName": "BA in Art History and another subject (combined honours program)"
            },
            {
                "id": 830,
                "itemName": "BA in Art History with a specialized minor in commerce"
            },
            {
                "id": 831,
                "itemName": "BA in cognitive science of language and another subject"
            },
            {
                "id": 832,
                "itemName": "BA in communications studies and another subject (combined honours program)"
            },
            {
                "id": 833,
                "itemName": "BA in communications studies with a specialized minor in commerce"
            },
            {
                "id": 834,
                "itemName": "BA in English and cultural studies and another subject (combined honours program)"
            },
            {
                "id": 835,
                "itemName": "BA in french and another subject (combined honours program)"
            },
            {
                "id": 836,
                "itemName": "BA in French with a specialized minor in commerce"
            },
            {
                "id": 837,
                "itemName": "BA in history with a specialized minor in commerce"
            },
            {
                "id": 838,
                "itemName": "BA in political science specialization in global citizenship"
            },
            {
                "id": 839,
                "itemName": "BASc in sustainable chemistry"
            },
            {
                "id": 840,
                "itemName": "Doctor of Dental Surgery"
            },
            {
                "id": 841,
                "itemName": "Bachelor of Science in Environmental and Conservation Sciences"
            },
            {
                "id": 842,
                "itemName": "Bachelor of Science in Forest Business Management"
            },
            {
                "id": 843,
                "itemName": "Bachelor of Science in Forestry"
            },
            {
                "id": 844,
                "itemName": "Bachelor of Science in Agriculture"
            },
            {
                "id": 845,
                "itemName": "Bachelor of Fine Arts in Art and Design"
            },
            {
                "id": 846,
                "itemName": "Bachelor of Science/Bachelor of Education (Secondary)"
            },
            {
                "id": 847,
                "itemName": "Bachelor of Science in Human Ecology/Bachelor of Education in Secondary Education"
            },
            {
                "id": 848,
                "itemName": "BSc (Specialization in Science and Education)/BEd (Secondary)"
            },
            {
                "id": 849,
                "itemName": "Registered Psychiatric Nurse to Bachelor of Science in Nursing"
            },
            {
                "id": 850,
                "itemName": "Bachelor of Arts in Native Studies with Honors"
            },
            {
                "id": 851,
                "itemName": "Doctor of Medicine"
            },
            {
                "id": 852,
                "itemName": "Bachelor of Science in Medical Laboratory Science"
            },
            {
                "id": 853,
                "itemName": "Bachelor of Medical Science"
            },
            {
                "id": 854,
                "itemName": "Juris Doctor"
            },
            {
                "id": 855,
                "itemName": "Bachelor of Science in Mechanical Engineering"
            },
            {
                "id": 856,
                "itemName": "Bachelor of Science in Electrical Engineering"
            },
            {
                "id": 857,
                "itemName": "Bachelor of Science in Computer Engineering"
            },
            {
                "id": 858,
                "itemName": "Bachelor of Science in Petroleum Engineering"
            },
            {
                "id": 859,
                "itemName": "Bachelor of Science in Materials Engineering"
            },
            {
                "id": 860,
                "itemName": "Bachelor of Science in Engineering Physics"
            },
            {
                "id": 861,
                "itemName": "Bachelor of Science in Mining Engineering"
            },
            {
                "id": 862,
                "itemName": "Bachelor of Science in Chemical Engineering"
            },
            {
                "id": 863,
                "itemName": "Bachelor of Music"
            },
            {
                "id": 864,
                "itemName": "Bachelor of Fine Arts in Drama"
            },
            {
                "id": 865,
                "itemName": "Bachelor of Science in Agricultural/Food Business Management"
            },
            {
                "id": 866,
                "itemName": "Bachelor of Science in Nutrition and Food Science"
            },
            {
                "id": 867,
                "itemName": "Bachelor of Science in Human Ecology"
            },
            {
                "id": 868,
                "itemName": "Bachelor of Science in Pharmacy"
            },
            {
                "id": 869,
                "itemName": "Baccalauréat ès Sciences"
            },
            {
                "id": 870,
                "itemName": "Bilingual Bachelor of Commerce/Baccalauréat bilingue en Administration des affaires"
            },
            {
                "id": 871,
                "itemName": "Baccalauréat en Éducation élémentaire"
            },
            {
                "id": 872,
                "itemName": "Baccalauréat en Éducation/Baccalauréat-es-Sciences"
            },
            {
                "id": 873,
                "itemName": "Bachelor of Management"
            },
            {
                "id": 874,
                "itemName": "Bachelor of Science in Environmental and Conservation Sciences/Bachelor of Arts in Native Studies"
            },
            {
                "id": 875,
                "itemName": "Bachelor of Arts in Environmental Studies"
            },
            {
                "id": 876,
                "itemName": "Bachelor of Science in Nursing - After Degree"
            },
            {
                "id": 877,
                "itemName": "Bachelor of Science General"
            },
            {
                "id": 878,
                "itemName": "Bachelor of Arts in Native Studies"
            },
            {
                "id": 879,
                "itemName": "BSc in Environmental and Conservation Sci/BSc ès sciences de l'environnement et de la conservation"
            },
            {
                "id": 880,
                "itemName": "Bachelor of Science in Civil Engineering"
            },
            {
                "id": 881,
                "itemName": "Doctor of Dental Surgery Advanced Placement"
            },
            {
                "id": 882,
                "itemName": "Bilingual Bachelor of Science in Nursing/Baccalauréat bilingue ès sciences infirmières"
            },
            {
                "id": 883,
                "itemName": "Baccalauréat-ès-Arts"
            },
            {
                "id": 884,
                "itemName": "Baccalauréat en Éducation secondaire"
            },
            {
                "id": 885,
                "itemName": "BSc in Environmental and Conservation Sci/BSc en sciences de l'environnement et de la conservation"
            },
            {
                "id": 886,
                "itemName": "Bachelor of Design"
            },
            {
                "id": 887,
                "itemName": "Baccalauréat-ès-Arts avec spécialisation"
            },
            {
                "id": 888,
                "itemName": "Bachelor of Science in Animal Health"
            },
            {
                "id": 889,
                "itemName": "Bachelor of Arts (Drama)/Bachelor of Education in Secondary Education"
            },
            {
                "id": 890,
                "itemName": "Bachelor of Arts in Criminology"
            },
            {
                "id": 891,
                "itemName": "Bachelor of Arts in Native Studies/Bachelor of Education in Elementary Education"
            },
            {
                "id": 892,
                "itemName": "Bachelor of Science in Nursing with Honors"
            },
            {
                "id": 893,
                "itemName": "Baccalauréat en Éducation après un premier diplôme (élémentaire)"
            },
            {
                "id": 894,
                "itemName": "Baccalauréat en Éducation après un premier diplôme (secondaire)"
            },
            {
                "id": 895,
                "itemName": "Bachelor of Arts"
            },
            {
                "id": 896,
                "itemName": "Bachelor of Science"
            },
            {
                "id": 897,
                "itemName": "Bachelor of Arts with Honors"
            },
            {
                "id": 898,
                "itemName": "Bachelor of Fine Arts in Drama - Acting"
            },
            {
                "id": 899,
                "itemName": "Bachelor of Music/Bachelor of Education (Elementary)"
            },
            {
                "id": 900,
                "itemName": "Bachelor of Music/Bachelor of Education (Secondary)"
            },
            {
                "id": 901,
                "itemName": "Bachelor of Commerce"
            },
            {
                "id": 902,
                "itemName": "Bachelor of Commerce - After Degree"
            },
            {
                "id": 903,
                "itemName": "Bachelor of Commerce with Honors"
            },
            {
                "id": 904,
                "itemName": "Bachelor of Arts in Native Studies/Bachelor of Education in Secondary Education"
            },
            {
                "id": 905,
                "itemName": "Bachelor of Education in Adult Education"
            },
            {
                "id": 906,
                "itemName": "Bachelor of Education in Elementary Education"
            },
            {
                "id": 907,
                "itemName": "Bachelor of Education in Secondary Education"
            },
            {
                "id": 908,
                "itemName": "Bachelor of Education in Adult Education - After Degree"
            },
            {
                "id": 909,
                "itemName": "Bachelor of Education in Elementary Education - After Degree"
            },
            {
                "id": 910,
                "itemName": "Bachelor of Education in Secondary Education - After Degree"
            },
            {
                "id": 911,
                "itemName": "Bachelor of Kinesiology/Bachelor of Education in Elementary Education"
            },
            {
                "id": 912,
                "itemName": "Bachelor of Kinesiology/Bachelor of Education in Secondary Education"
            },
            {
                "id": 913,
                "itemName": "Bachelor of Physical Education/Bachelor of Education in Elementary Education"
            },
            {
                "id": 914,
                "itemName": "Bachelor of Physical Education/Bachelor of Education in Secondary Education"
            },
            {
                "id": 915,
                "itemName": "Bachelor of Science in Chemical Engineering Biomedical Option"
            },
            {
                "id": 916,
                "itemName": "Bachelor of Science in Chemical Engineering Computer Process Control"
            },
            {
                "id": 917,
                "itemName": "Bachelor of Science in Civil Engineering Biomedical Option"
            },
            {
                "id": 918,
                "itemName": "Bachelor of Science in Civil Engineering Environmental Engineering"
            },
            {
                "id": 919,
                "itemName": "Bachelor of Science in Computer Engineering Nanoscale System Design Option"
            },
            {
                "id": 920,
                "itemName": "Bachelor of Science in Computer Engineering Software Option"
            },
            {
                "id": 921,
                "itemName": "Bachelor of Science in Electrical Engineering Biomedical Option"
            },
            {
                "id": 922,
                "itemName": "Bachelor of Science in Electrical Engineering Nanoengineering Option"
            },
            {
                "id": 923,
                "itemName": "Bachelor of Science in Engineering Physics Nanoengineering Option"
            },
            {
                "id": 924,
                "itemName": "Bachelor of Science in Materials Engineering Biomedical Option"
            },
            {
                "id": 925,
                "itemName": "Bachelor of Science in Materials Engineering Nano and Functional Engineering Option Co-operative"
            },
            {
                "id": 926,
                "itemName": "Bachelor of Science in Mechanical Engineering Biomedical Option Co-operative"
            },
            {
                "id": 927,
                "itemName": "Master of Business Administration/Juris Doctor"
            },
            {
                "id": 928,
                "itemName": "Doctor of Medicine/Doctor of Philosophy"
            },
            {
                "id": 929,
                "itemName": "Doctor of Medicine/Master of Business Administration"
            },
            {
                "id": 930,
                "itemName": "Bachelor of Science in Radiation Therapy"
            },
            {
                "id": 931,
                "itemName": "Bachelor of Science (Dental Hygiene Specialization)"
            },
            {
                "id": 932,
                "itemName": "Doctor of Pharmacy"
            },
            {
                "id": 933,
                "itemName": "Bachelor of Science - Business Minor"
            },
            {
                "id": 934,
                "itemName": "Bachelor of Science with Honors"
            },
            {
                "id": 935,
                "itemName": "Bachelor of Science with Specialization"
            },
            {
                "id": 936,
                "itemName": "Bachelor of Science in Nutrition and Food Science with Specialization in Dietetics"
            },
            {
                "id": 937,
                "itemName": "Bachelor of Science in Nutrition and Food Science with Specialization in Food Science and Technology"
            },
            {
                "id": 938,
                "itemName": "Bachelor of Science in Nursing with Honors - After Degree"
            },
            {
                "id": 939,
                "itemName": "Bachelor of Kinesiology"
            },
            {
                "id": 940,
                "itemName": "Bachelor of Science in Occupational Therapy"
            },
            {
                "id": 941,
                "itemName": "Bachelor of Science in Chemical Engineering Biomedical Option Co-operative"
            },
            {
                "id": 942,
                "itemName": "Bachelor of Science in Chemical Engineering Computer Process Control Co-operative"
            },
            {
                "id": 943,
                "itemName": "Bachelor of Science in Chemical Engineering Co-operative"
            },
            {
                "id": 944,
                "itemName": "Bachelor of Science in Civil Engineering Co-operative"
            },
            {
                "id": 945,
                "itemName": "Bachelor of Science in Civil Engineering Environmental Engineering Co-operative"
            },
            {
                "id": 946,
                "itemName": "Bachelor of Science in Computer Engineering Co-operative"
            },
            {
                "id": 947,
                "itemName": "Bachelor of Science in Computer Engineering Nanoscale System Design Option Co-operative"
            },
            {
                "id": 948,
                "itemName": "Bachelor of Science in Computer Engineering Software Option Co-operative"
            },
            {
                "id": 949,
                "itemName": "Bachelor of Science in Electrical Engineering Co-operative"
            },
            {
                "id": 950,
                "itemName": "Bachelor of Science in Electrical Engineering Nanoengineering Option Co-operative"
            },
            {
                "id": 951,
                "itemName": "Bachelor of Science in Engineering"
            },
            {
                "id": 952,
                "itemName": "Bachelor of Science in Engineering Physics Co-operative"
            },
            {
                "id": 953,
                "itemName": "Bachelor of Science in Engineering Physics Nanoengineering Option Co-operative"
            },
            {
                "id": 954,
                "itemName": "Bachelor of Science in Materials Engineering Biomedical Option Co-operative"
            },
            {
                "id": 955,
                "itemName": "Bachelor of Science in Materials Engineering Co-operative"
            },
            {
                "id": 956,
                "itemName": "Bachelor of Science in Materials Engineering Nano and Functional Materials Option"
            },
            {
                "id": 957,
                "itemName": "Bachelor of Science in Mechanical Engineering Co-operative"
            },
            {
                "id": 958,
                "itemName": "Bachelor of Science in Mining Engineering Co-operative"
            },
            {
                "id": 959,
                "itemName": "Bachelor of Science in Petroleum Engineering Co-operative"
            },
            {
                "id": 960,
                "itemName": "Bachelor of Science in Medicine"
            },
            {
                "id": 961,
                "itemName": "Visual Arts Certificate"
            },
            {
                "id": 962,
                "itemName": "Bachelor of Science in Fashion Business Management"
            },
            {
                "id": 963,
                "itemName": "BSc (Dental Hygiene Specialization)-Post Diploma Degree Completion"
            },
            {
                "id": 964,
                "itemName": "Bachelor of Arts in Recreation, Sport and Tourism"
            },
            {
                "id": 965,
                "itemName": "Bachelor of Science in Kinesiology"
            },
            {
                "id": 966,
                "itemName": "BSc (Honours)/Bachelor of Education in Intermediate-Senior program"
            },
            {
                "id": 967,
                "itemName": "BA (Honours)/Bachelor of Education in Intermediate-Senior program"
            },
            {
                "id": 968,
                "itemName": "Bachelor of Education in Intermediate-Senior program"
            },
            {
                "id": 969,
                "itemName": "Bachelor of Education"
            },
            {
                "id": 970,
                "itemName": "BSc (Honours)/Bachelor of Education in Primary-Junior program"
            },
            {
                "id": 971,
                "itemName": "Bachelor of Education in Technological Education"
            },
            {
                "id": 972,
                "itemName": "B.A.Sc.in Civil Engineering"
            },
            {
                "id": 973,
                "itemName": "B.A.Sc. in Mechanical Engineering"
            },
            {
                "id": 974,
                "itemName": "B.A.Sc. in Engineering Chemistry"
            },
            {
                "id": 975,
                "itemName": "B.A.Sc. in Computer Engineering"
            },
            {
                "id": 976,
                "itemName": "B.A.Sc. in Engineering Physics"
            },
            {
                "id": 977,
                "itemName": "B.A.Sc. in Geological Engineering"
            },
            {
                "id": 978,
                "itemName": "B.A.Sc. in Mathematics and Engineering"
            },
            {
                "id": 979,
                "itemName": "B.A.Sc.in Mining Engineering"
            },
            {
                "id": 980,
                "itemName": "Juris Doctor (Law)"
            },
            {
                "id": 981,
                "itemName": "Bachelor of Nursing Science"
            },
            {
                "id": 982,
                "itemName": "BA in Linguistics"
            },
            {
                "id": 983,
                "itemName": "BSc (Honours) in Environmental Science"
            },
            {
                "id": 984,
                "itemName": "BA (Honours) in Classics"
            },
            {
                "id": 985,
                "itemName": "BA in Mathematics"
            },
            {
                "id": 986,
                "itemName": "BSc (Honours) in Psychology"
            },
            {
                "id": 987,
                "itemName": "BA in Hispanic Studies"
            },
            {
                "id": 988,
                "itemName": "BA in Classical Studies"
            },
            {
                "id": 989,
                "itemName": "BA in Biology"
            },
            {
                "id": 990,
                "itemName": "BSc in Mathematics"
            },
            {
                "id": 991,
                "itemName": "BSc in Statistics"
            },
            {
                "id": 992,
                "itemName": "BA in History"
            },
            {
                "id": 993,
                "itemName": "BA in Geography"
            },
            {
                "id": 994,
                "itemName": "BSc in Geography"
            },
            {
                "id": 995,
                "itemName": "BA in French Studies"
            },
            {
                "id": 996,
                "itemName": "BA in Film and Media"
            },
            {
                "id": 997,
                "itemName": "BSc (Honours) in Biochemistry"
            },
            {
                "id": 998,
                "itemName": "BA in Economics"
            },
            {
                "id": 999,
                "itemName": "BA in Drama"
            },
            {
                "id": 1000,
                "itemName": "BA in Computing"
            },
            {
                "id": 1001,
                "itemName": "BA in Italian"
            },
            {
                "id": 1002,
                "itemName": "BFA (Honours) in Visual Art"
            },
            {
                "id": 1003,
                "itemName": "BA in Chemistry"
            },
            {
                "id": 1004,
                "itemName": "BA in Gender Studies"
            },
            {
                "id": 1005,
                "itemName": "BA in Religious Studies"
            },
            {
                "id": 1006,
                "itemName": "BA in Political Studies"
            },
            {
                "id": 1007,
                "itemName": "BA in Physics"
            },
            {
                "id": 1008,
                "itemName": "BA in English Language and Literature"
            },
            {
                "id": 1009,
                "itemName": "BA in Sociology"
            },
            {
                "id": 1010,
                "itemName": "BA in Psychology"
            },
            {
                "id": 1011,
                "itemName": "BA in Philosophy"
            },
            {
                "id": 1012,
                "itemName": "BA in Music"
            },
            {
                "id": 1013,
                "itemName": "BA in German Studies"
            },
            {
                "id": 1014,
                "itemName": "BSc in Life Sciences"
            },
            {
                "id": 1015,
                "itemName": "BA in Statistics"
            },
            {
                "id": 1016,
                "itemName": "BSc (Honours) in Astrophysics"
            },
            {
                "id": 1017,
                "itemName": "BA (Honours) in Psychology"
            },
            {
                "id": 1018,
                "itemName": "BA in Global Development Studies"
            },
            {
                "id": 1019,
                "itemName": "BA in Environmental Studies"
            },
            {
                "id": 1020,
                "itemName": "BFAH/Bachelor of Education in Intermediate-Senior program (one of two teaching subjects must be in Visual Art)"
            },
            {
                "id": 1021,
                "itemName": "BA in Art History"
            },
            {
                "id": 1022,
                "itemName": "BA in Health Studies"
            },
            {
                "id": 1023,
                "itemName": "BMus/Bachelor of Education in Primary-Junior program"
            },
            {
                "id": 1024,
                "itemName": "BMus/Bachelor of Education in Intermediate-Senior program"
            },
            {
                "id": 1025,
                "itemName": "Bachelor of Computing (BCmpH) (Honours) in Biomedical Computing"
            },
            {
                "id": 1026,
                "itemName": "Bachelor of Computing (BCmpH) (Honours) in Cognitive Science"
            },
            {
                "id": 1027,
                "itemName": "Bachelor of Computing (BCmpH) (Honours)"
            },
            {
                "id": 1028,
                "itemName": "Bachelor of Computing (BCmp)"
            },
            {
                "id": 1029,
                "itemName": "Bachelor of Computing (BCmpH) Honours in Software Design"
            },
            {
                "id": 1030,
                "itemName": "Bachelor of Arts (Honours)/Bachelor of Education"
            },
            {
                "id": 1031,
                "itemName": "Bachelor of Science (Honours)/Bachelor of Education"
            },
            {
                "id": 1032,
                "itemName": "Bachelor of Education in Aboriginal Teacher Education"
            },
            {
                "id": 1033,
                "itemName": "MD/PhD"
            },
            {
                "id": 1034,
                "itemName": "MD/Master's"
            },
            {
                "id": 1035,
                "itemName": "BA (Honours) in Art History"
            },
            {
                "id": 1036,
                "itemName": "BA (Honours) in English Language and Literature"
            },
            {
                "id": 1037,
                "itemName": "BA (Honours) in French Studies"
            },
            {
                "id": 1038,
                "itemName": "BA (Honours) in Geography"
            },
            {
                "id": 1039,
                "itemName": "BA (Honours) in Health Studies"
            },
            {
                "id": 1040,
                "itemName": "BA in Indigenous Studies"
            },
            {
                "id": 1041,
                "itemName": "BA in Jewish Studies"
            },
            {
                "id": 1042,
                "itemName": "BA (Honours) in Hispanic Studies"
            },
            {
                "id": 1043,
                "itemName": "BA in Spanish and Latin American Studies"
            },
            {
                "id": 1044,
                "itemName": "BA (Honours) in Classical Studies"
            },
            {
                "id": 1045,
                "itemName": "BA (Honours) in Computing and the Creative Arts"
            },
            {
                "id": 1046,
                "itemName": "BA (Honours) in Drama"
            },
            {
                "id": 1047,
                "itemName": "BA (Honours) in Economics"
            },
            {
                "id": 1048,
                "itemName": "BA (Honours) in Film and Media"
            },
            {
                "id": 1049,
                "itemName": "BA (Honours) in Gender Studies"
            },
            {
                "id": 1050,
                "itemName": "BA in Geology"
            },
            {
                "id": 1051,
                "itemName": "BA (Honours) in Global Development Studies"
            },
            {
                "id": 1052,
                "itemName": "BA (Honours) in History"
            },
            {
                "id": 1053,
                "itemName": "BA (Honours) in Linguistics"
            },
            {
                "id": 1054,
                "itemName": "BA in Medieval Studies"
            },
            {
                "id": 1055,
                "itemName": "BA (Honours) in Music"
            },
            {
                "id": 1056,
                "itemName": "BA (Honours) in Philosophy"
            },
            {
                "id": 1057,
                "itemName": "BA (Honours) in Political Studies"
            },
            {
                "id": 1058,
                "itemName": "BA (Honours) in Religious Studies"
            },
            {
                "id": 1059,
                "itemName": "BA (Honours) in Sociology"
            },
            {
                "id": 1060,
                "itemName": "BA in World Language Studies"
            },
            {
                "id": 1061,
                "itemName": "BSc in Biology"
            },
            {
                "id": 1062,
                "itemName": "BSc (Honours) in Biology"
            },
            {
                "id": 1063,
                "itemName": "BSc (Honours) in Biology and Mathematics"
            },
            {
                "id": 1064,
                "itemName": "BSc (Honours) in Biology and Psychology"
            },
            {
                "id": 1065,
                "itemName": "BSc in Chemistry"
            },
            {
                "id": 1066,
                "itemName": "BSc (Honours) in Chemistry"
            },
            {
                "id": 1067,
                "itemName": "BSc (Honours) in Geography"
            },
            {
                "id": 1068,
                "itemName": "BSc in Geology"
            },
            {
                "id": 1069,
                "itemName": "BSc (Honours) in Geology"
            },
            {
                "id": 1070,
                "itemName": "BSc (Honours) in Environmental Biology"
            },
            {
                "id": 1071,
                "itemName": "BSc (Honours) in Environmental Chemistry"
            },
            {
                "id": 1072,
                "itemName": "BSc (Honours) in Environmental Geology"
            },
            {
                "id": 1073,
                "itemName": "BSc (Honours) in Earth System Science"
            },
            {
                "id": 1074,
                "itemName": "BSc (Honours) in Environmental Life Sciences"
            },
            {
                "id": 1075,
                "itemName": "BSc (Honours) in Environmental Toxicology"
            },
            {
                "id": 1076,
                "itemName": "BSc (Honours) in Kinesiology"
            },
            {
                "id": 1077,
                "itemName": "BSc (Honours) in Life Sciences"
            },
            {
                "id": 1078,
                "itemName": "BSc (Honours) in Mathematics"
            },
            {
                "id": 1079,
                "itemName": "BSc in Physics"
            },
            {
                "id": 1080,
                "itemName": "BSc (Honours) in Physics"
            },
            {
                "id": 1081,
                "itemName": "BSc (Honours) in Statistics"
            },
            {
                "id": 1082,
                "itemName": "Bachelor of Music/Bachelor of Education"
            },
            {
                "id": 1083,
                "itemName": "BFA in Visual Art"
            },
            {
                "id": 1084,
                "itemName": "BSc (Honours) in Biotechnology"
            },
            {
                "id": 1085,
                "itemName": "Bachelor of Fine Art (Honours)/Bachelor of Education"
            },
            {
                "id": 1086,
                "itemName": "Bachelor of Computing BCmpH (Honours) in Computer Science"
            },
            {
                "id": 1087,
                "itemName": "BHSc in Health Sciences"
            },
            {
                "id": 1088,
                "itemName": "BA (Honours) in Applied Economics"
            },
            {
                "id": 1089,
                "itemName": "BA (Honours) in German Studies"
            },
            {
                "id": 1090,
                "itemName": "BA (Honours) in Languages, Literatures and Cultures"
            },
            {
                "id": 1091,
                "itemName": "BA (Honours) in Mathematics"
            },
            {
                "id": 1092,
                "itemName": "BA (Honours) in Politics, Philosophy and Economics"
            },
            {
                "id": 1093,
                "itemName": "BA (Honours) in Statistics"
            },
            {
                "id": 1094,
                "itemName": "BA in Liberal Studies"
            },
            {
                "id": 1095,
                "itemName": "BSc (Honours) in Mathematical Physics"
            },
            {
                "id": 1096,
                "itemName": "BSc in Biochemistry"
            },
            {
                "id": 1097,
                "itemName": "BSc in Kinesiology"
            },
            {
                "id": 1098,
                "itemName": "Bachelor of MusicTheatre"
            },
            {
                "id": 1099,
                "itemName": "Bachelor of Mining Engineering Technology"
            },
            {
                "id": 1100,
                "itemName": "Bachelor of Commerce-Juris Doctor combined degree"
            },
            {
                "id": 1101,
                "itemName": "Juris Doctor-Master of Business Administration"
            },
            {
                "id": 1102,
                "itemName": "Bachelor of Computing BCmpH (Honours) in Computing and Mathematics"
            },
            {
                "id": 1103,
                "itemName": "BA (Honours)/Bachelor of Education in primary-junior program"
            },
            {
                "id": 1104,
                "itemName": "BA (Honours) in Environmental Studies"
            },
            {
                "id": 1105,
                "itemName": "BA (Honours) in Media and Performance Production"
            },
            {
                "id": 1106,
                "itemName": "BA )Honours)/Bachelor of Education in Primary-Junior program"
            },
            {
                "id": 1107,
                "itemName": "BFAH/Bachelor of Education in Primary-Junior program"
            },
            {
                "id": 1108,
                "itemName": "BA in World Languages"
            },
            {
                "id": 1109,
                "itemName": "BA in Employment Relations"
            },
            {
                "id": 1110,
                "itemName": "BA (Honours) in Indigenous Studies"
            },
            {
                "id": 1111,
                "itemName": "BA in Media, Information and Technoculture (MIT)  (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1112,
                "itemName": "BSc in Kinesiology  (Honours Specialization)"
            },
            {
                "id": 1113,
                "itemName": "BA in Kinesiology  (Honours Specialization, Major)"
            },
            {
                "id": 1114,
                "itemName": "BA in Kinesiology - Sport Management (Honours Specialization) / BA in Honours Business Administration"
            },
            {
                "id": 1115,
                "itemName": "BA in Philosophy (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 1116,
                "itemName": "BA in Latin (Minor)"
            },
            {
                "id": 1117,
                "itemName": "BA in Classical Studies (Honours, Specialization, Specialization Major, Minor)"
            },
            {
                "id": 1118,
                "itemName": "BA in Music Administrative Studies (Specialization)"
            },
            {
                "id": 1119,
                "itemName": "BA in Music (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1120,
                "itemName": "Bachelor of Music with Honours in Music History"
            },
            {
                "id": 1121,
                "itemName": "Bachelor of Music with Honours in Music Theory"
            },
            {
                "id": 1122,
                "itemName": "Bachelor of Music with Honours in Music Education"
            },
            {
                "id": 1123,
                "itemName": "Bachelor of Music with Honours in Performance (Orchestral Instrument, Piano, Voice)"
            },
            {
                "id": 1124,
                "itemName": "Bachelor of Laws in Common Law/Juris Doctor in Civil Law"
            },
            {
                "id": 1125,
                "itemName": "Juris Doctor (JD)"
            },
            {
                "id": 1126,
                "itemName": "BESc (integrated engineering)"
            },
            {
                "id": 1127,
                "itemName": "BESc in Chemical Engineering"
            },
            {
                "id": 1128,
                "itemName": "BESc in Environmental Engineering (International Development option)"
            },
            {
                "id": 1129,
                "itemName": "BESc in Structural Engineering"
            },
            {
                "id": 1130,
                "itemName": "BESc in Electrical Engineering"
            },
            {
                "id": 1131,
                "itemName": "BESc in Mechanical Engineering"
            },
            {
                "id": 1132,
                "itemName": "BESc in Biochemical and Environmental Engineering"
            },
            {
                "id": 1133,
                "itemName": "Bachelor of Education in primary-junior program"
            },
            {
                "id": 1134,
                "itemName": "Bachelor of Education in junior-intermediate"
            },
            {
                "id": 1135,
                "itemName": "BA in French Studies (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 1136,
                "itemName": "BA in Greek (Minor)"
            },
            {
                "id": 1137,
                "itemName": "BA in Philosophy of Science (Minor)"
            },
            {
                "id": 1138,
                "itemName": "BA in Greek and Latin (Minor)"
            },
            {
                "id": 1139,
                "itemName": "BA in Visual Arts (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 1140,
                "itemName": "BA in Comparative Literature and Culture (Minor)"
            },
            {
                "id": 1141,
                "itemName": "BFA in Studio Art (Honours Specialization)"
            },
            {
                "id": 1142,
                "itemName": "BA in Art History and Museum Studies (Honours Specialization)"
            },
            {
                "id": 1143,
                "itemName": "BA in Film Studies (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 1144,
                "itemName": "BA in General Cinema Studies (Minor)"
            },
            {
                "id": 1145,
                "itemName": "BSc in Environmental Science (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1146,
                "itemName": "BSc in Chemistry (Honors Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1147,
                "itemName": "BSc in Biology (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1148,
                "itemName": "BSc in Biochemistry (Major, Minor)"
            },
            {
                "id": 1149,
                "itemName": "BSc in Statistics (Honours Specialization)"
            },
            {
                "id": 1150,
                "itemName": "BSc in Physics (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1151,
                "itemName": "BSc in Mathematics (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1152,
                "itemName": "BA in Geography (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1153,
                "itemName": "BA in Anthropology (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1154,
                "itemName": "BSc in Psychology (Honours Specialization)"
            },
            {
                "id": 1155,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Biochemistry of Infection and Immunity (Honours Specialization)"
            },
            {
                "id": 1156,
                "itemName": "BSc in Geography (Honours Specialization)"
            },
            {
                "id": 1157,
                "itemName": "BA in Women's Studies (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1158,
                "itemName": "BA in Sociology (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1159,
                "itemName": "BA in Psychology (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1160,
                "itemName": "BA in Political Science (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1161,
                "itemName": "Bachelor of Management and Organizational Studies (BMOS) in Human Resource Management (Honours Specialization, Specialization, Major)"
            },
            {
                "id": 1162,
                "itemName": "Bachelor of Management and Organizational Studies (BMOS) in Commercial Aviation Management (Honours Specialization, Major, Specialization)"
            },
            {
                "id": 1163,
                "itemName": "Bachelor of Management and Organizational Studies (BMOS) in Consumer Behavior (Honours Specialization, Major, Specialization)"
            },
            {
                "id": 1164,
                "itemName": "BA in History (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1165,
                "itemName": "BA in Economics (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1166,
                "itemName": "Bachelor of Management and Organizational Studies (BMOS) in Finance for MOS (Honours Specialization, Major, Specialization)"
            },
            {
                "id": 1167,
                "itemName": "BSc in Computer Science (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1168,
                "itemName": "BSc in Applied Mathematics (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 1169,
                "itemName": "BSc in Physiology (Major)"
            },
            {
                "id": 1170,
                "itemName": "BA in English Language and Literature (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 1171,
                "itemName": "BA in General English (Minor)"
            },
            {
                "id": 1172,
                "itemName": "BESc in Software Engineering"
            },
            {
                "id": 1173,
                "itemName": "Bachelor of Health Sciences (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 1174,
                "itemName": "BA in English for Teachers (Minor)"
            },
            {
                "id": 1175,
                "itemName": "BA in Linguistics (Honors Specialization, Major, Minor)"
            },
            {
                "id": 1176,
                "itemName": "BA in Greek and Roman Archeology (Minor)"
            },
            {
                "id": 1177,
                "itemName": "BA in Ethics (Minor)"
            },
            {
                "id": 1178,
                "itemName": "BA in Museum and Curatorial Studies (Major, Minor)"
            },
            {
                "id": 1179,
                "itemName": "BA in Women's Studies (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 1180,
                "itemName": "BA in Feminist, Queer, and Critical Race Theory (Minor)"
            },
            {
                "id": 1181,
                "itemName": "BA in Honours Business Administration"
            },
            {
                "id": 1182,
                "itemName": "Bachelor of Engineering Science (BESc) / BA in Honours Business Administration"
            },
            {
                "id": 1183,
                "itemName": "BA in Kinesiology (Sport Management) / BA in Honours Business Administration"
            },
            {
                "id": 1184,
                "itemName": "Juris Doctor (JD) / BA in Honours Business Administration"
            },
            {
                "id": 1185,
                "itemName": "BA Honours in Media, Information and Technoculture (Honours Specialization)(MIT) / BA in Honours Business Administration"
            },
            {
                "id": 1186,
                "itemName": "BSc (Honours / BA Honors) Business Administration"
            },
            {
                "id": 1187,
                "itemName": "Bachelor of Education in intermediate-senior"
            },
            {
                "id": 1188,
                "itemName": "Bachelor of Health Sciences - Health Promotion (Honours Specialization)"
            },
            {
                "id": 1189,
                "itemName": "Bachelor of Health Sciences in Rehabilitation Sciences (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1190,
                "itemName": "Bachelor of Health Sciences with Biology (Honours Specialization)"
            },
            {
                "id": 1191,
                "itemName": "BA in Media, Information and Technoculture (Honours Specialization) / BA in Honours Business Administration"
            },
            {
                "id": 1192,
                "itemName": "BA in Media and Public Interest (MPI) (Honors Specialization, Major)"
            },
            {
                "id": 1193,
                "itemName": "Bachelor of Musical Arts (Honours Music)"
            },
            {
                "id": 1194,
                "itemName": "BA in Popular Music Studies (Major)"
            },
            {
                "id": 1195,
                "itemName": "BSc in Scientific Computing and Numerical Methods (Major)"
            },
            {
                "id": 1196,
                "itemName": "BSc in Applied Mathematical Methods (Major)"
            },
            {
                "id": 1197,
                "itemName": "BSc in Theoretical Physics (Major)"
            },
            {
                "id": 1198,
                "itemName": "BSc in Mathematical and Numerical Methods (Minor)"
            },
            {
                "id": 1199,
                "itemName": "BSc in Medical Cell Biology (Major, Minor)"
            },
            {
                "id": 1200,
                "itemName": "BSc in Biochemistry and Chemistry (Honours Specialization)"
            },
            {
                "id": 1201,
                "itemName": "BSc in Genetics and Biochemistry (Honours Specialization)"
            },
            {
                "id": 1202,
                "itemName": "BSc in Physics of Materials (Minor)"
            },
            {
                "id": 1203,
                "itemName": "BSc in Bioinformatics (Honours Specialization)"
            },
            {
                "id": 1204,
                "itemName": "BSc in Animal Behaviour (Honours Specialization)"
            },
            {
                "id": 1205,
                "itemName": "BSc in Genetics (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1206,
                "itemName": "BSc in Ecosystem Health (Major)"
            },
            {
                "id": 1207,
                "itemName": "BSc in Advanced Chemistry (Minor)"
            },
            {
                "id": 1208,
                "itemName": "BSc in Software Engineering (Minor)"
            },
            {
                "id": 1209,
                "itemName": "BSc in Computer Algebra (Minor)"
            },
            {
                "id": 1210,
                "itemName": "BSc in Theoretical Computer Science (Minor)"
            },
            {
                "id": 1211,
                "itemName": "BSc in Applications of Computer Science (Minor)"
            },
            {
                "id": 1212,
                "itemName": "BSc in Game Development (Minor)"
            },
            {
                "id": 1213,
                "itemName": "BSc in Geology (Major, Minor)"
            },
            {
                "id": 1214,
                "itemName": "BSc in Geophysics (Minor)"
            },
            {
                "id": 1215,
                "itemName": "BSc in Geology and Biology (Honours Specialization, Specialization)"
            },
            {
                "id": 1216,
                "itemName": "BSc in Mathematical and Statistical Sciences (Honours Specialization)"
            },
            {
                "id": 1217,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Medical Sciences  (Major, Minor)"
            },
            {
                "id": 1218,
                "itemName": "Bachelor of Medical Sciences (BMSc) Honours Specialization in Medical Sciences / BA in Honors Business Administration"
            },
            {
                "id": 1219,
                "itemName": "BSc in Medical Biophysics - Physical Science Concentration (Honours Specialization)"
            },
            {
                "id": 1220,
                "itemName": "BSc in Medical Biophysics  (Major, Minor)"
            },
            {
                "id": 1221,
                "itemName": "BSc in Microbiology and Immunology (Major, Minor)"
            },
            {
                "id": 1222,
                "itemName": "BSc in Astrophysics (Honours Specialization, Major, Specialization)"
            },
            {
                "id": 1223,
                "itemName": "BSc in Medical Physics (Honours Specialization, Major, Specialization)"
            },
            {
                "id": 1224,
                "itemName": "BSc in Materials Science (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1225,
                "itemName": "BSc in Conceptual Astronomy (Minor)"
            },
            {
                "id": 1226,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Biochemistry and Cell Biology (Honours Specialization)"
            },
            {
                "id": 1227,
                "itemName": "BSc in Pharmacology (Major, Minor)"
            },
            {
                "id": 1228,
                "itemName": "BSc in Actuarial Science (Honors Specialization, Major)"
            },
            {
                "id": 1229,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Medical Cell Biology (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1230,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Biochemistry (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1231,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Medical Biophysics (Major, Minor)"
            },
            {
                "id": 1232,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Microbiology & Immunology (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1233,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Pathology (Honours Specialization, Major)"
            },
            {
                "id": 1234,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Pharmacology (Honours Specialization, Major, Specialization, Minor)"
            },
            {
                "id": 1235,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Physiology (Honours Specialization, Major)"
            },
            {
                "id": 1236,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Physiology & Pharmacology (Honours Specialization, Specialization)"
            },
            {
                "id": 1237,
                "itemName": "Bachelor of Management and Organizational Studies (BMOS) in Accounting (Honours Specialization, Major, Specialization)"
            },
            {
                "id": 1238,
                "itemName": "BA in American Cultural Studies (Honours Specialization)"
            },
            {
                "id": 1239,
                "itemName": "BA in American Studies (Major, Minor)"
            },
            {
                "id": 1240,
                "itemName": "BA in Linguistic Anthropology (Major)"
            },
            {
                "id": 1241,
                "itemName": "BA in Sociocultural Anthropology (Major)"
            },
            {
                "id": 1242,
                "itemName": "BA in Archaeology and Biological Anthropology (Major)"
            },
            {
                "id": 1243,
                "itemName": "BA in Refugee and Migrant Studies (Minor)"
            },
            {
                "id": 1244,
                "itemName": "BA in Financial Economics (Major)"
            },
            {
                "id": 1245,
                "itemName": "BA in Indigenous Studies (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1246,
                "itemName": "BA in Geographic Information Science (Honours Specialization, Major)"
            },
            {
                "id": 1247,
                "itemName": "BA in Urban Development (Honours Specialization)"
            },
            {
                "id": 1248,
                "itemName": "BA in International Relations (Honours Specialization)"
            },
            {
                "id": 1249,
                "itemName": "BA in Linguistics (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1250,
                "itemName": "BA in Criminology (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1251,
                "itemName": "BSc in Geographic Information Science (Honours Specialization)"
            },
            {
                "id": 1252,
                "itemName": "BA in Global Development (Honours Specialization)/BA in Honours Business Administration"
            },
            {
                "id": 1253,
                "itemName": "BSc in Financial Modelling (Honours Specialization, Major)"
            },
            {
                "id": 1254,
                "itemName": "BA in Creative Writing (Minor)"
            },
            {
                "id": 1255,
                "itemName": "BA in Popular Literature and Cultural Studies (Minor)"
            },
            {
                "id": 1256,
                "itemName": "Bachelor of Health Sciences (Honors Specialization)/ - HBA (BA in Honors Business Administration)"
            },
            {
                "id": 1257,
                "itemName": "BSc in Information Systems (Honours Specialization)"
            },
            {
                "id": 1258,
                "itemName": "BSc in Mathematics in Society (Honours Specialization, Specialization)"
            },
            {
                "id": 1259,
                "itemName": "BA in Environment and Culture (Minor)"
            },
            {
                "id": 1260,
                "itemName": "BA in Jewish Studies (Major, Minor)"
            },
            {
                "id": 1261,
                "itemName": "Bachelor of Medical Sciences (Honours Specialization) (BMSc) / BA in Honours Business Administration"
            },
            {
                "id": 1262,
                "itemName": "BA in Globalization (Honours Specialization)/BA in Honours Business Administration"
            },
            {
                "id": 1263,
                "itemName": "BA in Art History (Major, Minor)"
            },
            {
                "id": 1264,
                "itemName": "BA in Writing Studies (Minor)"
            },
            {
                "id": 1265,
                "itemName": "BESc in Green Process Engineering"
            },
            {
                "id": 1266,
                "itemName": "BESc/BA in Honors Business Administration Program"
            },
            {
                "id": 1267,
                "itemName": "BESc/JD (Juris Doctor) Program"
            },
            {
                "id": 1268,
                "itemName": "BSc (Honours)/BA in Honours Business Administration"
            },
            {
                "id": 1269,
                "itemName": "BSc in Advanced Physics (Minor)"
            },
            {
                "id": 1270,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Medical Biophysics - Physical Science Concentration (Honours Specialization)"
            },
            {
                "id": 1271,
                "itemName": "BA in Politics, Philosophy and Economics (Honours Specialization)"
            },
            {
                "id": 1272,
                "itemName": "BA in Global Economics (Honours Specialization)"
            },
            {
                "id": 1273,
                "itemName": "BA in Global Culture Studies (Honours Specialization)/BA in Honours Business Administration"
            },
            {
                "id": 1274,
                "itemName": "BA in Greek and Latin Literature (Minor)"
            },
            {
                "id": 1275,
                "itemName": "BESc in Mechatronic Systems Engineering"
            },
            {
                "id": 1276,
                "itemName": "BA in Kinesiology - Sport Management (Honours Specialization)"
            },
            {
                "id": 1277,
                "itemName": "BA or BSc in Foods and Nutrition (Minor)"
            },
            {
                "id": 1278,
                "itemName": "Bachelor Health Sciences in Foods and Nutrition (Minor)"
            },
            {
                "id": 1279,
                "itemName": "BSc in Foods and Nutrition (Specialization)"
            },
            {
                "id": 1280,
                "itemName": "BA in Economics (Honours) / BA Honours Business Administration"
            },
            {
                "id": 1281,
                "itemName": "BA in Political Science (Honours Specialization)/BA in Honours Business Administration"
            },
            {
                "id": 1282,
                "itemName": "BA in Psychology (Honours Specialization) / BA in Honours Business Administration"
            },
            {
                "id": 1283,
                "itemName": "BA in Economics (Honours)/BA Honours Business Administration"
            },
            {
                "id": 1284,
                "itemName": "BA in Political Science (Honours Specialization) / BA in Honours Business Administration"
            },
            {
                "id": 1285,
                "itemName": "BA in Dance (Minor)"
            },
            {
                "id": 1286,
                "itemName": "Music Recording Arts Program - Bachelor of Musical Arts/Diploma in Musical Industry Arts (MIA)"
            },
            {
                "id": 1287,
                "itemName": "BA in Sexuality Studies (Major, Minor)"
            },
            {
                "id": 1288,
                "itemName": "BA/BA in Honours Business Administration"
            },
            {
                "id": 1289,
                "itemName": "BSc in Applied Statistics (Major, Minor)"
            },
            {
                "id": 1290,
                "itemName": "Bachelor of Music with Honours in Composition"
            },
            {
                "id": 1291,
                "itemName": "BA in Arts and Humanities (Major)"
            },
            {
                "id": 1292,
                "itemName": "BA in Creative Writing & English Language and Literature (Honours Specialization)"
            },
            {
                "id": 1293,
                "itemName": "BA in Digital Humanities (Minor)"
            },
            {
                "id": 1294,
                "itemName": "BSc in High Performance Computing (Minor)"
            },
            {
                "id": 1295,
                "itemName": "BSc in Medical Science (Major, Minor)"
            },
            {
                "id": 1296,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Chemical Biology (Honours Specialization)"
            },
            {
                "id": 1297,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Computational Biochemistry (Honours Specialization)"
            },
            {
                "id": 1298,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Medical Biophysics - Clinical Physics Concentration (Honours Specialization)"
            },
            {
                "id": 1299,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Medical Health Informatics (Honours Specialization)"
            },
            {
                "id": 1300,
                "itemName": "BA in Physical Geography (Major)"
            },
            {
                "id": 1301,
                "itemName": "BA in Transitional Justice and Post-Conflict Reconstruction (Minor)"
            },
            {
                "id": 1302,
                "itemName": "BSc in Physical Geography (Major)"
            },
            {
                "id": 1303,
                "itemName": "BA in Huron Psychology (Honours Specialization) / BA in Honours Business Administration"
            },
            {
                "id": 1304,
                "itemName": "BSc in Nutrition and Dietetics (Honours Specialization) / BA in Honours Business Administration"
            },
            {
                "id": 1305,
                "itemName": "BA in Music (Honours Specialization, Major)/BA in Honours Business Administration"
            },
            {
                "id": 1306,
                "itemName": "Bachelor of Musical Arts (Honours Music)/BA in Honours Business Administration"
            },
            {
                "id": 1307,
                "itemName": "BA in Digital Spanish (Minor)"
            },
            {
                "id": 1308,
                "itemName": "BA in German Language and Culture (Major, Minor)"
            },
            {
                "id": 1309,
                "itemName": "BA in Greek and Roman History (Minor)"
            },
            {
                "id": 1310,
                "itemName": "BA in Italian Language and Culture (Major, Minor)"
            },
            {
                "id": 1311,
                "itemName": "BA in Spanish Language and Hispanic Culture (Honours Specialization, Specialization)"
            },
            {
                "id": 1312,
                "itemName": "Bachelor of Education in primary-junior with French as a second language program"
            },
            {
                "id": 1313,
                "itemName": "BESc in Structural Engineering with International Development"
            },
            {
                "id": 1314,
                "itemName": "Engineering Leadership and Innovation Certificate"
            },
            {
                "id": 1315,
                "itemName": "BSc in Medical Biophysics - Biological Science Concentration (Honours Specialization)"
            },
            {
                "id": 1316,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Epidemiology and Biostatistics (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1317,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Interdisciplinary Medical Sciences (Honours Specialization, Major, Specialization)"
            },
            {
                "id": 1318,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Medical Biophysics - Biological Science Concentration (Honours Specialization)"
            },
            {
                "id": 1319,
                "itemName": "BSc in Neuroscience (Honours Specialization)"
            },
            {
                "id": 1320,
                "itemName": "BA in Environment and Health (Major)"
            },
            {
                "id": 1321,
                "itemName": "BA in Geography and Commercial Aviation Management (Honours Specialization, Specialization)"
            },
            {
                "id": 1322,
                "itemName": "BA in Geography (Honours Specialization) / BA in Honours Business Administration"
            },
            {
                "id": 1323,
                "itemName": "BA in Middle East Studies (Major)"
            },
            {
                "id": 1324,
                "itemName": "BA in Urban Development (Honours Specialization)/BA in Honours Business Administration"
            },
            {
                "id": 1325,
                "itemName": "BA in Music (Honours Specialization, Major) / BA in Honours Business Administration"
            },
            {
                "id": 1326,
                "itemName": "Bachelor of Health Sciences (BHSc)/BA in Honours Business Administration"
            },
            {
                "id": 1327,
                "itemName": "BA in Medieval Studies (Major, Minor)"
            },
            {
                "id": 1328,
                "itemName": "BESc in Electrical Devices for Ubiquitous Computing"
            },
            {
                "id": 1329,
                "itemName": "BESc in Software Systems for Ubiquitous Computing"
            },
            {
                "id": 1330,
                "itemName": "BSc in Planetary Science and Space Exploration (Minor)"
            },
            {
                "id": 1331,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Biochemistry and Pathology of Human Disease (Honours Specialization)"
            },
            {
                "id": 1332,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Medical Biophysics and Biochemistry (Honours Specialization)"
            },
            {
                "id": 1333,
                "itemName": "BA in Geography (Honours Specialization)/BA in Honours Business Administration"
            },
            {
                "id": 1334,
                "itemName": "BA in Urban Development (Honours Specialization) / BA in Honours Business Administration"
            },
            {
                "id": 1335,
                "itemName": "Bachelor of Medical Sciences in Biochemistry (Honours Specialization) (BMSc)/BA in Honours Business Administration"
            },
            {
                "id": 1336,
                "itemName": "BESc in Environmental Engineering"
            },
            {
                "id": 1337,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Medical Biophysics - Medical Science Concentration (Honours Specialization)"
            },
            {
                "id": 1338,
                "itemName": "BA in Intercultural communications for Arabic (Minor)"
            },
            {
                "id": 1339,
                "itemName": "BA in Intercultural Communications for Japanese (Minor)"
            },
            {
                "id": 1340,
                "itemName": "BA in Health Sciences in Health and Aging (Honours Specialization)"
            },
            {
                "id": 1341,
                "itemName": "BSc in Biodiversity and Conservation (Honours Specialization)"
            },
            {
                "id": 1342,
                "itemName": "BSc in Integrated Science with Biology (Honours Specialization)"
            },
            {
                "id": 1343,
                "itemName": "BSc in Integrated Science with Chemistry (Honours Specialization)"
            },
            {
                "id": 1344,
                "itemName": "BSc in Integrated Science with Computer Science (Honours Specialization)"
            },
            {
                "id": 1345,
                "itemName": "BSc in Integrated Science with Earth Sciences (Honours Specialization)"
            },
            {
                "id": 1346,
                "itemName": "BSc in Integrated Science with Environmental Science (Honours Specialization)"
            },
            {
                "id": 1347,
                "itemName": "BSc in Integrated Science with Genetics (Honours Specialization)"
            },
            {
                "id": 1348,
                "itemName": "BSc in Integrated Science with Mathematical Statistical Sciences (Honours Specialization)"
            },
            {
                "id": 1349,
                "itemName": "BSc in Integrated Science with Physics (Honours Specialization)"
            },
            {
                "id": 1350,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Microbiology & Immunology with Pathology (Honours Specialization)"
            },
            {
                "id": 1351,
                "itemName": "Bachelor of Medical Sciences (BMSc) in One Health (Honours Specialization)"
            },
            {
                "id": 1352,
                "itemName": "BA in Clinical (Honours Specialization)"
            },
            {
                "id": 1353,
                "itemName": "BSc in Computer Hardware Design (Minor)"
            },
            {
                "id": 1354,
                "itemName": "Bachelor of Medical Sciences (BMSc) in Biochemistry and Cancer Biology (Honours Specialization)"
            },
            {
                "id": 1355,
                "itemName": "BA in Public History (Minor)"
            },
            {
                "id": 1356,
                "itemName": "BA in International Relations (Honours) / BA Honours Business Administration"
            },
            {
                "id": 1357,
                "itemName": "BESc in Biomedical Engineering with Mechanical Engineering Foundations"
            },
            {
                "id": 1358,
                "itemName": "BESc in Biomedical Engineering with Electrical Engineering Foundations"
            },
            {
                "id": 1359,
                "itemName": "BESc in Biomedical Engineering with Chemical Engineering Foundations"
            },
            {
                "id": 1360,
                "itemName": "Bachelor of Management and Organizational Studies (BMOS) in Management and Legal Studies (Honours Specialization, Specialization)"
            },
            {
                "id": 1361,
                "itemName": "Bachelor of Musical Arts"
            },
            {
                "id": 1362,
                "itemName": "BA in Art History and Studio Arts (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1363,
                "itemName": "BA in Global Gender Studies (Honous Specialization)"
            },
            {
                "id": 1364,
                "itemName": "BA in Photography (Minor)"
            },
            {
                "id": 1365,
                "itemName": "BA in Spanish (Major, Minor)"
            },
            {
                "id": 1366,
                "itemName": "BA in Theatre Studies (Major, Minor)"
            },
            {
                "id": 1367,
                "itemName": "BESc in Chemical Engineering and BESc in Biomedical Engineering"
            },
            {
                "id": 1368,
                "itemName": "BESc in Electrical Engineering and BESc in Biomedical Engineering"
            },
            {
                "id": 1369,
                "itemName": "BESc in Mechanical Engineering and BESc in Biomedical Engineering"
            },
            {
                "id": 1370,
                "itemName": "BESc in Mechatroinic Systems Engineering and BESc in Biomedical Engineering"
            },
            {
                "id": 1371,
                "itemName": "BSc in Applied Financial Modelling (Minor)"
            },
            {
                "id": 1372,
                "itemName": "BSc in Data Science (Honours Specialization, Major, Minor)"
            },
            {
                "id": 1373,
                "itemName": "BSc in Environmental Geoscience - For Professional Registration (Honours Specialization, Specialization)"
            },
            {
                "id": 1374,
                "itemName": "BSc in Epidemiology and Biostatistics (Major)"
            },
            {
                "id": 1375,
                "itemName": "BSc in Geology - For Professional Registration (Honours Specialization, Specialization)"
            },
            {
                "id": 1376,
                "itemName": "BSc in Geophysics - For Professional Registration (Honours Specialization, Specialization)"
            },
            {
                "id": 1377,
                "itemName": "BSc in Integrated Science with Astrophysics (Honours Specialization)"
            },
            {
                "id": 1378,
                "itemName": "BSc in Synthetic Biology (Honours Specialization)"
            },
            {
                "id": 1379,
                "itemName": "BA in Global Gender Studies (Honours Specialization)"
            },
            {
                "id": 1380,
                "itemName": "BA in Global Justice (Honours Specialization, Major)"
            },
            {
                "id": 1381,
                "itemName": "BA in International Relations (Honours Specialization) / BA in Honours Business Administration"
            },
            {
                "id": 1382,
                "itemName": "BA in Politics, Philosophy and Economics - Politics and Philosophy (Honours Specialization)"
            },
            {
                "id": 1383,
                "itemName": "BA in Politics, Philosophy and Economics - Economics (Honours Specialization) / BA in Honours Business Administration"
            },
            {
                "id": 1384,
                "itemName": "BA in Politics, Philosophy and Economics - Politics and Philosophy (Honours Specialization) / BA in Honours Business Administration"
            },
            {
                "id": 1385,
                "itemName": "BA in Politics, Philosophy and Economics - Economics (Honours Specialization) / BA in Honours Business"
            },
            {
                "id": 1386,
                "itemName": "BA in Politics, Philosophy and Economics - Politics and Philosophy (Honours Specialization) / BA in Honours Business"
            },
            {
                "id": 1387,
                "itemName": "Doctorat en médecine dentaire"
            },
            {
                "id": 1388,
                "itemName": "Baccalauréat en criminologie"
            },
            {
                "id": 1389,
                "itemName": "Baccalauréat en sciences biologiques"
            },
            {
                "id": 1390,
                "itemName": "Baccalauréat en études classiques"
            },
            {
                "id": 1391,
                "itemName": "Baccalauréat en mathématiques et informatique"
            },
            {
                "id": 1392,
                "itemName": "Baccalauréat en philosophie"
            },
            {
                "id": 1393,
                "itemName": "Baccalauréat en études hispaniques"
            },
            {
                "id": 1394,
                "itemName": "Baccalauréat en sociologie"
            },
            {
                "id": 1395,
                "itemName": "Baccalauréat en travail social"
            },
            {
                "id": 1396,
                "itemName": "Baccalauréat en sciences économiques"
            },
            {
                "id": 1397,
                "itemName": "Baccalauréat en études allemandes"
            },
            {
                "id": 1398,
                "itemName": "Baccalauréat en anthropologie"
            },
            {
                "id": 1399,
                "itemName": "Baccalauréat en science politique"
            },
            {
                "id": 1400,
                "itemName": "Baccalauréat en relations industrielles"
            },
            {
                "id": 1401,
                "itemName": "Baccalauréat en urbanisme"
            },
            {
                "id": 1402,
                "itemName": "Baccalauréat en design industriel"
            },
            {
                "id": 1403,
                "itemName": "Baccalauréat en sciences architecture"
            },
            {
                "id": 1404,
                "itemName": "Baccalauréat en architecture de paysage"
            },
            {
                "id": 1405,
                "itemName": "Baccalauréat en psychoéducation"
            },
            {
                "id": 1406,
                "itemName": "Baccalauréat en sécurité et études policières"
            },
            {
                "id": 1407,
                "itemName": "Baccalauréat en droit"
            },
            {
                "id": 1408,
                "itemName": "Baccalauréat en études cinématographiques et littérature comparée"
            },
            {
                "id": 1409,
                "itemName": "Baccalauréat en études anglaises et littérature comparée"
            },
            {
                "id": 1410,
                "itemName": "Baccalauréat en démographie et géographie"
            },
            {
                "id": 1411,
                "itemName": "Baccalauréat en lettres et sciences humaines"
            },
            {
                "id": 1412,
                "itemName": "Baccalauréat en études internationales"
            },
            {
                "id": 1413,
                "itemName": "Baccalauréat en sciences de la communication"
            },
            {
                "id": 1414,
                "itemName": "Baccalauréat en physique"
            },
            {
                "id": 1415,
                "itemName": "Baccalauréat en géographie environnementale"
            },
            {
                "id": 1416,
                "itemName": "Baccalauréat en biochimie et médecine moléculaire"
            },
            {
                "id": 1417,
                "itemName": "Baccalauréat en littératures de langue française"
            },
            {
                "id": 1418,
                "itemName": "Baccalauréat en études anglaises"
            },
            {
                "id": 1419,
                "itemName": "Baccalauréat en chimie"
            },
            {
                "id": 1420,
                "itemName": "Baccalauréat en histoire"
            },
            {
                "id": 1421,
                "itemName": "Baccalauréat en psychologie"
            },
            {
                "id": 1422,
                "itemName": "Baccalauréat en traduction"
            },
            {
                "id": 1423,
                "itemName": "Baccalauréat en sciences de la santé (ergothérapie)"
            },
            {
                "id": 1424,
                "itemName": "Doctorat en médecine"
            },
            {
                "id": 1425,
                "itemName": "Baccalauréat en nutrition"
            },
            {
                "id": 1426,
                "itemName": "Baccalauréat en sciences de la santé (physiothérapie)"
            },
            {
                "id": 1427,
                "itemName": "Baccalauréat en kinésiologie"
            },
            {
                "id": 1428,
                "itemName": "Baccalauréat en sciences infirmières"
            },
            {
                "id": 1429,
                "itemName": "Doctorat en médecine vétérinaire"
            },
            {
                "id": 1430,
                "itemName": "Baccalauréat en musique général"
            },
            {
                "id": 1431,
                "itemName": "Baccalauréat en enseignement du français langue seconde (B.Ed.)"
            },
            {
                "id": 1432,
                "itemName": "Baccalauréat en enseignement en adaptation scolaire (B.Ed.)"
            },
            {
                "id": 1433,
                "itemName": "Baccalauréat en éducation préscolaire et enseignement primaire (B.Ed.)"
            },
            {
                "id": 1434,
                "itemName": "Doctorat en pharmacie"
            },
            {
                "id": 1435,
                "itemName": "Baccalauréat en musique écriture"
            },
            {
                "id": 1436,
                "itemName": "Baccalauréat en musique musicologie"
            },
            {
                "id": 1437,
                "itemName": "Baccalauréat en musique composition électroacoustique"
            },
            {
                "id": 1438,
                "itemName": "Majeure  en traduction"
            },
            {
                "id": 1439,
                "itemName": "Majeure en mathématiques"
            },
            {
                "id": 1440,
                "itemName": "Majeure  en études classiques"
            },
            {
                "id": 1441,
                "itemName": "Majeure en sciences de la communication"
            },
            {
                "id": 1442,
                "itemName": "Majeure en chimie"
            },
            {
                "id": 1443,
                "itemName": "Majeure en anthropologie"
            },
            {
                "id": 1444,
                "itemName": "Majeure en études anglaises"
            },
            {
                "id": 1445,
                "itemName": "Majeure en géographie"
            },
            {
                "id": 1446,
                "itemName": "Majeure en littératures de langue française"
            },
            {
                "id": 1447,
                "itemName": "Majeure en études est-asiatiques"
            },
            {
                "id": 1448,
                "itemName": "Majeure en histoire"
            },
            {
                "id": 1449,
                "itemName": "Majeure en études cinématographiques"
            },
            {
                "id": 1450,
                "itemName": "Majeure en linguistique"
            },
            {
                "id": 1451,
                "itemName": "Majeure en informatique"
            },
            {
                "id": 1452,
                "itemName": "Majeure en études hispaniques"
            },
            {
                "id": 1453,
                "itemName": "Majeure en études allemandes"
            },
            {
                "id": 1454,
                "itemName": "Majeure en physique"
            },
            {
                "id": 1455,
                "itemName": "Majeure en philosophie"
            },
            {
                "id": 1456,
                "itemName": "Majeure en psychologie"
            },
            {
                "id": 1457,
                "itemName": "Majeure en sciences biologiques"
            },
            {
                "id": 1458,
                "itemName": "Majeure en science politiques"
            },
            {
                "id": 1459,
                "itemName": "Majeure en sociologie"
            },
            {
                "id": 1460,
                "itemName": "Majeure en sciences économiques"
            },
            {
                "id": 1461,
                "itemName": "Majeure en théologie"
            },
            {
                "id": 1462,
                "itemName": "Mineure en mathématiques"
            },
            {
                "id": 1463,
                "itemName": "Mineure en études est-asiatiques"
            },
            {
                "id": 1464,
                "itemName": "Mineure en criminologie"
            },
            {
                "id": 1465,
                "itemName": "Mineure en sciences de la communication"
            },
            {
                "id": 1466,
                "itemName": "Mineure  en chimie"
            },
            {
                "id": 1467,
                "itemName": "Mineure en arts et sciences"
            },
            {
                "id": 1468,
                "itemName": "Mineure en anthropologie"
            },
            {
                "id": 1469,
                "itemName": "Mineure en études québécoises"
            },
            {
                "id": 1470,
                "itemName": "Mineure en études latino-américaines"
            },
            {
                "id": 1471,
                "itemName": "Mineure  en démographie"
            },
            {
                "id": 1472,
                "itemName": "Mineure en études classiques"
            },
            {
                "id": 1473,
                "itemName": "Mineure en études italiennes"
            },
            {
                "id": 1474,
                "itemName": "Mineure en études arabes"
            },
            {
                "id": 1475,
                "itemName": "Mineure en histoire"
            },
            {
                "id": 1476,
                "itemName": "Mineure  en linguistique"
            },
            {
                "id": 1477,
                "itemName": "Mineure en informatique"
            },
            {
                "id": 1478,
                "itemName": "Mineure en littérature comparée"
            },
            {
                "id": 1479,
                "itemName": "Mineure en éthique et droit"
            },
            {
                "id": 1480,
                "itemName": "Mineure en géographie"
            },
            {
                "id": 1481,
                "itemName": "Mineure en littératures de langue française"
            },
            {
                "id": 1482,
                "itemName": "Mineure en études anglaises"
            },
            {
                "id": 1483,
                "itemName": "Mineure en études hispaniques"
            },
            {
                "id": 1484,
                "itemName": "Mineure en études allemandes"
            },
            {
                "id": 1485,
                "itemName": "Mineure en psychoéducation"
            },
            {
                "id": 1486,
                "itemName": "Mineure  en physique"
            },
            {
                "id": 1487,
                "itemName": "Mineure en philosophie"
            },
            {
                "id": 1488,
                "itemName": "Mineure  en psychologie"
            },
            {
                "id": 1489,
                "itemName": "Mineure en sciences biologiques"
            },
            {
                "id": 1490,
                "itemName": "Mineure en science politique"
            },
            {
                "id": 1491,
                "itemName": "Mineure en sociologie"
            },
            {
                "id": 1492,
                "itemName": "Mineure en sciences économiques"
            },
            {
                "id": 1493,
                "itemName": "Mineure  en urbanisme"
            },
            {
                "id": 1494,
                "itemName": "Mineure en sciences cognitives"
            },
            {
                "id": 1495,
                "itemName": "Mineure en études cinématographiques"
            },
            {
                "id": 1496,
                "itemName": "Baccalauréat en design d'intérieur"
            },
            {
                "id": 1497,
                "itemName": "Mineure en design des jardins"
            },
            {
                "id": 1498,
                "itemName": "Baccalauréat en musique composition mixte"
            },
            {
                "id": 1499,
                "itemName": "Baccalauréat en musique interprétation chant classique"
            },
            {
                "id": 1500,
                "itemName": "Baccalauréat en musique interprétation instruments classiques"
            },
            {
                "id": 1501,
                "itemName": "Baccalauréat en musique interprétation jazz"
            },
            {
                "id": 1502,
                "itemName": "Baccalauréat en sciences biomédicales"
            },
            {
                "id": 1503,
                "itemName": "Baccalauréat en études allemandes et histoire"
            },
            {
                "id": 1504,
                "itemName": "Baccalauréat en littératures de langue française et linguistique"
            },
            {
                "id": 1505,
                "itemName": "Baccalauréat en littératures de langue française et philosophie"
            },
            {
                "id": 1506,
                "itemName": "Baccalauréat en littérature comparée et philosophie"
            },
            {
                "id": 1507,
                "itemName": "Baccalauréat en histoire de l'art"
            },
            {
                "id": 1508,
                "itemName": "Baccalauréat en économie et politique"
            },
            {
                "id": 1509,
                "itemName": "Baccalauréat en communication et politique"
            },
            {
                "id": 1510,
                "itemName": "Baccalauréat en mathématiques et économie"
            },
            {
                "id": 1511,
                "itemName": "Baccalauréat en mathématiques et physique"
            },
            {
                "id": 1512,
                "itemName": "Baccalauréat en physique et informatique"
            },
            {
                "id": 1513,
                "itemName": "Baccalauréat en science politique et philosophie"
            },
            {
                "id": 1514,
                "itemName": "Baccalauréat en bio-informatique"
            },
            {
                "id": 1515,
                "itemName": "Baccalauréat en informatique"
            },
            {
                "id": 1516,
                "itemName": "Baccalauréat en mathématiques"
            },
            {
                "id": 1517,
                "itemName": "Mineure en histoire de l'art"
            },
            {
                "id": 1518,
                "itemName": "Mineure  en gestion de l'information numérique"
            },
            {
                "id": 1519,
                "itemName": "Mineure en langue portugaise et cultures lusophones"
            },
            {
                "id": 1520,
                "itemName": "Majeure en études italiennes"
            },
            {
                "id": 1521,
                "itemName": "Majeure en histoire de l'art"
            },
            {
                "id": 1522,
                "itemName": "Majeure en littérature comparée"
            },
            {
                "id": 1523,
                "itemName": "Baccalauréat en enseignement de l'éducation physique et à la santé (B.Ed.)"
            },
            {
                "id": 1524,
                "itemName": "Baccalauréat en enseignement de l'univers social au secondaire (B.Ed.)"
            },
            {
                "id": 1525,
                "itemName": "Baccalauréat en enseignement des mathématiques au secondaire (B.Ed.)"
            },
            {
                "id": 1526,
                "itemName": "Baccalauréat en enseignement des sciences et des technologies au secondaire (B.Ed.)"
            },
            {
                "id": 1527,
                "itemName": "Baccalauréat en enseignement du français au secondaire (B.Ed.)"
            },
            {
                "id": 1528,
                "itemName": "Baccalauréat en histoire et études classiques"
            },
            {
                "id": 1529,
                "itemName": "Baccalauréat en philosophie et études classiques"
            },
            {
                "id": 1530,
                "itemName": "Baccalauréat en études cinématographiques"
            },
            {
                "id": 1531,
                "itemName": "Baccalauréat en linguistique et psychologie"
            },
            {
                "id": 1532,
                "itemName": "Baccalauréat en psychologie et sociologie"
            },
            {
                "id": 1533,
                "itemName": "Baccalauréat en démographie et statistique"
            },
            {
                "id": 1534,
                "itemName": "Baccalauréat en démographie et anthropologie"
            },
            {
                "id": 1535,
                "itemName": "Baccalauréat en enseignement de l'éthique et de la culture religieuse au secondaire (B.Ed.)"
            },
            {
                "id": 1536,
                "itemName": "Baccalauréat en écriture de scénario et création littéraire"
            },
            {
                "id": 1537,
                "itemName": "Baccalauréat en littératures de langues anglaise et française"
            },
            {
                "id": 1538,
                "itemName": "Baccalauréat en études classiques et anthropologie"
            },
            {
                "id": 1539,
                "itemName": "Majeure en études médiévales"
            },
            {
                "id": 1540,
                "itemName": "Mineure en musiques numériques"
            },
            {
                "id": 1541,
                "itemName": "Majeure en musiques numériques"
            },
            {
                "id": 1542,
                "itemName": "Baccalauréat en sciences biopharmaceutiques"
            },
            {
                "id": 1543,
                "itemName": "Mineure  en études du jeu vidéo"
            },
            {
                "id": 1544,
                "itemName": "Baccalauréat en neurosciences"
            },
            {
                "id": 1545,
                "itemName": "Majeure en sciences des religions"
            },
            {
                "id": 1546,
                "itemName": "Baccalauréat en études religieuses"
            },
            {
                "id": 1547,
                "itemName": "Baccalauréat en microbiologie et immunologie"
            },
            {
                "id": 1548,
                "itemName": "Baccalauréat en cinéma"
            },
            {
                "id": 1549,
                "itemName": "Baccalauréat en études asiatiques"
            },
            {
                "id": 1550,
                "itemName": "Mineure en études Catalanes"
            },
            {
                "id": 1551,
                "itemName": "Baccalauréat en musique numériques"
            },
            {
                "id": 1552,
                "itemName": "Baccalauréat en littératures comparée"
            },
            {
                "id": 1553,
                "itemName": "Majeure en études autochtones"
            },
            {
                "id": 1554,
                "itemName": "Majeure en études lusophones"
            },
            {
                "id": 1555,
                "itemName": "Baccalauréat en neuroscience cognitive"
            },
            {
                "id": 1556,
                "itemName": "Majeure en composition et arrangement jazz"
            },
            {
                "id": 1557,
                "itemName": "Mineure en santé publique et mondialisation"
            },
            {
                "id": 1558,
                "itemName": "Majeure en musique, histoire et sociétés"
            },
            {
                "id": 1559,
                "itemName": "Mineure en musique et arts"
            },
            {
                "id": 1560,
                "itemName": "Majeure en écriture musicale"
            },
            {
                "id": 1561,
                "itemName": "Mineure en écriture musicale"
            },
            {
                "id": 1562,
                "itemName": "Majeure en soins préhospitaliers d'urgence avancés"
            },
            {
                "id": 1563,
                "itemName": "Mineure en études religieuses"
            },
            {
                "id": 1564,
                "itemName": "Baccalauréat en santé publique environnementale et sécurité publique"
            },
            {
                "id": 1565,
                "itemName": "Majeure en démographie"
            },
            {
                "id": 1566,
                "itemName": "Majeure en jeu vidéo"
            },
            {
                "id": 1567,
                "itemName": "BA or BSc in computing science (honours/major/minor)"
            },
            {
                "id": 1568,
                "itemName": "BSc (major/honours/minor) in Molecular Biology and Biochemistry)"
            },
            {
                "id": 1569,
                "itemName": "BSc (major/honours) in Actuarial Science"
            },
            {
                "id": 1570,
                "itemName": "BSc (Double Minor) General Science"
            },
            {
                "id": 1571,
                "itemName": "BSc (major/honours/minor) in Chemistry "
            },
            {
                "id": 1572,
                "itemName": "BSc (major/honours) in Environmental Science"
            },
            {
                "id": 1573,
                "itemName": "BSc (major/honors/minor) in Biological Sciences"
            },
            {
                "id": 1574,
                "itemName": "BSc (major/honours/minor) in Earth Sciences "
            },
            {
                "id": 1575,
                "itemName": "BSc  (major/honors/minor) in Physics"
            },
            {
                "id": 1576,
                "itemName": "BSc (major/honours) in Data Science "
            },
            {
                "id": 1577,
                "itemName": "BSc (major/honours/minor) in Mathematics"
            },
            {
                "id": 1578,
                "itemName": "BSc (major/honours/minor) in Kinesiology"
            },
            {
                "id": 1579,
                "itemName": "BASc (major/honours) in engineering science"
            },
            {
                "id": 1580,
                "itemName": "Bachelor of Education (major/honours/minor)"
            },
            {
                "id": 1581,
                "itemName": "Bachelor of Business Administration (major/honours/minor)"
            },
            {
                "id": 1582,
                "itemName": "BA (major/honours/minor/extended minor) in Humanities"
            },
            {
                "id": 1583,
                "itemName": "BA (major/honours/minor/extended minor) in Anthropology"
            },
            {
                "id": 1584,
                "itemName": "BA (major/honours/minor) in Cognitive Science"
            },
            {
                "id": 1585,
                "itemName": "BA (major/honours/minor/extended minor) in Archaeology"
            },
            {
                "id": 1586,
                "itemName": "BA (major/honours/minor/extended minor) in Criminology"
            },
            {
                "id": 1587,
                "itemName": "BA (major/honours/minor/extended minor) in History"
            },
            {
                "id": 1588,
                "itemName": "BA (major/honours/minor/extended minor) in Psychology"
            },
            {
                "id": 1589,
                "itemName": "BA (major/honours/minor/extended minor) in Political Science"
            },
            {
                "id": 1590,
                "itemName": "BA (major/honours/minor/extended minor) in Economics"
            },
            {
                "id": 1591,
                "itemName": "BA (major/honours/minor/extended minor) in French"
            },
            {
                "id": 1592,
                "itemName": "BA (major/honours/minor/extended minor) in Sociology"
            },
            {
                "id": 1593,
                "itemName": "BA (major/minor/extended minor) in Gender, Sexuality and Women's Studies"
            },
            {
                "id": 1594,
                "itemName": "BA (major/honours/minor/extended minor) in Philosophy"
            },
            {
                "id": 1595,
                "itemName": "BA (major/honours/minor/extended minor) in English"
            },
            {
                "id": 1596,
                "itemName": "BA (major/honours/extended minor) in Linguistics"
            },
            {
                "id": 1597,
                "itemName": "BA (major/honours/minor/extended minor) in World Literature"
            },
            {
                "id": 1598,
                "itemName": "BSc (major/honours/minor) in Physical Geography"
            },
            {
                "id": 1599,
                "itemName": "BSc in Computing Sciences SFU-BEng/Zhejiang University Dual Degree"
            },
            {
                "id": 1600,
                "itemName": "BSc (major/honours) in Geographic Information Science"
            },
            {
                "id": 1601,
                "itemName": "Bachelor of General Studies (Applied Sciences)"
            },
            {
                "id": 1602,
                "itemName": "BA (major/honours/minor) in International Studies"
            },
            {
                "id": 1603,
                "itemName": "BA (major/honours/minor) in Health Sciences"
            },
            {
                "id": 1604,
                "itemName": "BSc (major/honors/minor) in Statistics "
            },
            {
                "id": 1605,
                "itemName": "BA (major/minor) in First Nations Studies"
            },
            {
                "id": 1606,
                "itemName": "BSc (major/honours/minor) in Health Sciences"
            },
            {
                "id": 1607,
                "itemName": "BSc in Software Systems"
            },
            {
                "id": 1608,
                "itemName": "BASc (major/honours) Mechatronic Systems Engineering"
            },
            {
                "id": 1609,
                "itemName": "Bachelor of Business Administration / BASc Mechatronic Systems Engineering"
            },
            {
                "id": 1610,
                "itemName": "Minor in Global Asia"
            },
            {
                "id": 1611,
                "itemName": "BSc (major/honours) in Behavioural Neuroscience"
            },
            {
                "id": 1612,
                "itemName": "BA (major/honours) in Human Geography"
            },
            {
                "id": 1613,
                "itemName": "Bachelor of General Studies (Education)"
            },
            {
                "id": 1614,
                "itemName": "BA (major/honours/minor/extended minor) in Communication"
            },
            {
                "id": 1615,
                "itemName": "BFA (major/honours/extended minor) in Dance"
            },
            {
                "id": 1616,
                "itemName": "BFA (major/honours/extended minor) in Film"
            },
            {
                "id": 1617,
                "itemName": "BFA (major/honours/extended minor) in Music"
            },
            {
                "id": 1618,
                "itemName": "BFA (major/honours/extended minor) in Theatre"
            },
            {
                "id": 1619,
                "itemName": "BFA (major/honours/extended minor) in Visual Art"
            },
            {
                "id": 1620,
                "itemName": "BA (major/honours/minor) Interactive Arts and Technology"
            },
            {
                "id": 1621,
                "itemName": "BSc (major/honours/minor) in Interactive Arts and Technology"
            },
            {
                "id": 1622,
                "itemName": "Minor in Print and Digital Publishing"
            },
            {
                "id": 1623,
                "itemName": "BSc (major/honours/minor) in Biomedical Physiology"
            },
            {
                "id": 1624,
                "itemName": "BSc (major/honors) in Applied Mathematics "
            },
            {
                "id": 1625,
                "itemName": "BSc (major/honors) in Operations Research"
            },
            {
                "id": 1626,
                "itemName": "BSc (honours) in Mathematical Physics "
            },
            {
                "id": 1627,
                "itemName": "BSc (major/honors) in Applied Physics "
            },
            {
                "id": 1628,
                "itemName": "BSc (major/honors) in Chemical Physics "
            },
            {
                "id": 1629,
                "itemName": "Minor in Nuclear Science"
            },
            {
                "id": 1630,
                "itemName": "Minor in Computer and Electronics Design"
            },
            {
                "id": 1631,
                "itemName": "Bachelor of General Studies (Arts and Social Sciences)"
            },
            {
                "id": 1632,
                "itemName": "BSc (major/honors) in Biological Physics "
            },
            {
                "id": 1633,
                "itemName": "Minor in Environmental Chemistry"
            },
            {
                "id": 1634,
                "itemName": "BA (major/minor) in Labour Studies"
            },
            {
                "id": 1635,
                "itemName": "BA (major/honours/minor) in Art, Performance and Cinema Studies"
            },
            {
                "id": 1636,
                "itemName": "Post-Baccalaureate Diploma in Contemporary Arts"
            },
            {
                "id": 1637,
                "itemName": "Baccalauréat en éducation (B.Ed) comme deuxième diplôme"
            },
            {
                "id": 1638,
                "itemName": "French Cohort Program"
            },
            {
                "id": 1639,
                "itemName": "BA (double minor)"
            },
            {
                "id": 1640,
                "itemName": "BASc (major) in Sustainable Energy Engineering"
            },
            {
                "id": 1641,
                "itemName": "Minor in Environmental Toxicology"
            },
            {
                "id": 1642,
                "itemName": "Minor in Social Data Analytics"
            },
            {
                "id": 1643,
                "itemName": "BEnv (major/honours) in Global Environmental Systems"
            },
            {
                "id": 1644,
                "itemName": "BEnv (major/honours/minor) in Resource and Environmental Management"
            },
            {
                "id": 1645,
                "itemName": "BEnv (major) in Sustainable Business"
            },
            {
                "id": 1646,
                "itemName": "MD/Doctorate in biomedical technology/health sciences"
            },
            {
                "id": 1647,
                "itemName": "Juris Doctor (JD)/Master of business administration"
            },
            {
                "id": 1648,
                "itemName": "MD/Master of science in biomedical technology/health sciences (combined degree program)"
            },
            {
                "id": 1649,
                "itemName": "BA in communications and media studies"
            },
            {
                "id": 1650,
                "itemName": "BA in law and society"
            },
            {
                "id": 1651,
                "itemName": "Bachelor of communication and media studies (BCMS)"
            },
            {
                "id": 1652,
                "itemName": "BA in development studies"
            },
            {
                "id": 1653,
                "itemName": "BA in women's studies"
            },
            {
                "id": 1654,
                "itemName": "BA in Canadian studies"
            },
            {
                "id": 1655,
                "itemName": "BFA (visual studies)"
            },
            {
                "id": 1656,
                "itemName": "BSc in software engineering"
            },
            {
                "id": 1657,
                "itemName": "BSc in oil and gas engineering"
            },
            {
                "id": 1658,
                "itemName": "BSc in civil engineering with minor in transportation engineering"
            },
            {
                "id": 1659,
                "itemName": "BSc in chemical engineering with minor in petroleum engineering"
            },
            {
                "id": 1660,
                "itemName": "BSc in civil engineering"
            },
            {
                "id": 1661,
                "itemName": "Bachelor of music with major in composition"
            },
            {
                "id": 1662,
                "itemName": "BA in dance"
            },
            {
                "id": 1663,
                "itemName": "Bachelor of music with major in performance"
            },
            {
                "id": 1664,
                "itemName": "BFA (drama)"
            },
            {
                "id": 1665,
                "itemName": "BSc in chemical engineering"
            },
            {
                "id": 1666,
                "itemName": "BSc in geomatics engineering"
            },
            {
                "id": 1667,
                "itemName": "BSc in mechanical engineering"
            },
            {
                "id": 1668,
                "itemName": "BSc in electrical engineering"
            },
            {
                "id": 1669,
                "itemName": "Bachelor of community rehabilitation"
            },
            {
                "id": 1670,
                "itemName": "BSc in environmental science"
            },
            {
                "id": 1671,
                "itemName": "BA in international relations"
            },
            {
                "id": 1672,
                "itemName": "BSc in Anthropology"
            },
            {
                "id": 1673,
                "itemName": "BA in geography"
            },
            {
                "id": 1674,
                "itemName": "BSc in psychology"
            },
            {
                "id": 1675,
                "itemName": "BSc in archaeology"
            },
            {
                "id": 1676,
                "itemName": "BA/BSc in archaeology"
            },
            {
                "id": 1677,
                "itemName": "BA in social and cultural anthropology"
            },
            {
                "id": 1678,
                "itemName": "BSc in earth science"
            },
            {
                "id": 1679,
                "itemName": "BA in psychology"
            },
            {
                "id": 1680,
                "itemName": "BSc in geography"
            },
            {
                "id": 1681,
                "itemName": "BSc in geophysics"
            },
            {
                "id": 1682,
                "itemName": "BSc in computer science"
            },
            {
                "id": 1683,
                "itemName": "BSc in plant biology"
            },
            {
                "id": 1684,
                "itemName": "BSc in geology (Petroleum geology concentration)"
            },
            {
                "id": 1685,
                "itemName": "BSc in biological sciences"
            },
            {
                "id": 1686,
                "itemName": "Bachelor of kinesiology/Bachelor of Commerce"
            },
            {
                "id": 1687,
                "itemName": "BSc in geology"
            },
            {
                "id": 1688,
                "itemName": "Bachelor of kinesiology (Leadership in pedagogy and coaching)/Bachelor of education"
            },
            {
                "id": 1689,
                "itemName": "BSc in ecology"
            },
            {
                "id": 1690,
                "itemName": "BSc in cellular, molecular and microbial biology"
            },
            {
                "id": 1691,
                "itemName": "BSc in actuarial science"
            },
            {
                "id": 1692,
                "itemName": "Bachelor of commerce with concentration in international business"
            },
            {
                "id": 1693,
                "itemName": "Bachelor of commerce with concentration in Organizational Behaviour and Human Resources"
            },
            {
                "id": 1694,
                "itemName": "Bachelor of commerce with concentration in accounting"
            },
            {
                "id": 1695,
                "itemName": "Bachelor of commerce with concentration in finance"
            },
            {
                "id": 1696,
                "itemName": "Bachelor of commerce with concentration in risk management and insurance"
            },
            {
                "id": 1697,
                "itemName": "Bachelor of commerce with concentration in marketing"
            },
            {
                "id": 1698,
                "itemName": "Bachelor of commerce/BSc in actuarial science or mathematics or computer science"
            },
            {
                "id": 1699,
                "itemName": "Bachelor of commerce with concentration in supply chain management"
            },
            {
                "id": 1700,
                "itemName": "Bachelor of commerce with concentration in operations management"
            },
            {
                "id": 1701,
                "itemName": "Bachelor of commerce with concentration in business technology management"
            },
            {
                "id": 1702,
                "itemName": "Bachelor of commerce/Bachelor of arts"
            },
            {
                "id": 1703,
                "itemName": "BA in ancient and medieval history"
            },
            {
                "id": 1704,
                "itemName": "BA in Greek and Roman studies"
            },
            {
                "id": 1705,
                "itemName": "BA in Spanish"
            },
            {
                "id": 1706,
                "itemName": "BA in religious studies"
            },
            {
                "id": 1707,
                "itemName": "BA in German"
            },
            {
                "id": 1708,
                "itemName": "BA in English"
            },
            {
                "id": 1709,
                "itemName": "BA in Russian"
            },
            {
                "id": 1710,
                "itemName": "BSc in civil engineering with minor in structural engineering"
            },
            {
                "id": 1711,
                "itemName": "BSc in Mechanical Engineering with Minor in Mechatronics"
            },
            {
                "id": 1712,
                "itemName": "BSc in mechanical engineering with minor in petroleum engineering"
            },
            {
                "id": 1713,
                "itemName": "BSc (all majors in Engineering)/BA (all majors in Arts)"
            },
            {
                "id": 1714,
                "itemName": "BA in East Asian Studies"
            },
            {
                "id": 1715,
                "itemName": "BA in Latin American studies"
            },
            {
                "id": 1716,
                "itemName": "BSc"
            },
            {
                "id": 1717,
                "itemName": "Bachelor of nursing"
            },
            {
                "id": 1718,
                "itemName": "BSc in natural sciences"
            },
            {
                "id": 1719,
                "itemName": "BSc in zoology"
            },
            {
                "id": 1720,
                "itemName": "BCR/BA or BSc (psychology)"
            },
            {
                "id": 1721,
                "itemName": "Bachelor of health sciences in bioinformatics"
            },
            {
                "id": 1722,
                "itemName": "Bachelor of health sciences in biomedical sciences"
            },
            {
                "id": 1723,
                "itemName": "Bachelor of health sciences in health and society"
            },
            {
                "id": 1724,
                "itemName": "BSc in general mathematics"
            },
            {
                "id": 1725,
                "itemName": "BA in urban studies"
            },
            {
                "id": 1726,
                "itemName": "Bachelor of commerce with concentration in entrepreneurship and innovation"
            },
            {
                "id": 1727,
                "itemName": "Bachelor of commerce with general concentration"
            },
            {
                "id": 1728,
                "itemName": "Bachelor of commerce/Bachelor of Kinesiology"
            },
            {
                "id": 1729,
                "itemName": "BSc in computer science and Bachelor of Commerce"
            },
            {
                "id": 1730,
                "itemName": "BSc in computer science and mathematics"
            },
            {
                "id": 1731,
                "itemName": "BSc in chemical engineering with a minor in biomedical engineering"
            },
            {
                "id": 1732,
                "itemName": "BSc in civil engineering with a minor in biomedical engineering"
            },
            {
                "id": 1733,
                "itemName": "BSc in electrical engineering with a specialization in biomedical engineering"
            },
            {
                "id": 1734,
                "itemName": "BSc in Geomatics engineering with a minor in biomedical engineering"
            },
            {
                "id": 1735,
                "itemName": "BSc in mechanical engineering with a minor in biomedical engineering"
            },
            {
                "id": 1736,
                "itemName": "BSc/BA"
            },
            {
                "id": 1737,
                "itemName": "BSc/BA or BSc"
            },
            {
                "id": 1738,
                "itemName": "BA in international indigenous studies"
            },
            {
                "id": 1739,
                "itemName": "Doctor of Medicine/Master's degree"
            },
            {
                "id": 1740,
                "itemName": "BSc in computer science and biological sciences"
            },
            {
                "id": 1741,
                "itemName": "BA in archaeology"
            },
            {
                "id": 1742,
                "itemName": "BA in economics with a concentration in applied energy economics"
            },
            {
                "id": 1743,
                "itemName": "BA in Film Studies"
            },
            {
                "id": 1744,
                "itemName": "BA in Italian Studies"
            },
            {
                "id": 1745,
                "itemName": "BA in linguistics and language"
            },
            {
                "id": 1746,
                "itemName": "Bachelor of commerce with concentration in personal financial planning"
            },
            {
                "id": 1747,
                "itemName": "BSc in electrical engineering with a minor in energy and environment"
            },
            {
                "id": 1748,
                "itemName": "BSc in geomatics engineering with a minor in energy and environment"
            },
            {
                "id": 1749,
                "itemName": "BEd Concurrent Education Program"
            },
            {
                "id": 1750,
                "itemName": "BEd After Degree Program"
            },
            {
                "id": 1751,
                "itemName": "Bachelor of music with major in integrated studies"
            },
            {
                "id": 1752,
                "itemName": "Bachelor of commerce with concentration in risk management: insurance and finance"
            },
            {
                "id": 1753,
                "itemName": "BSc in mechanical engineering with minor in manufacturing engineering"
            },
            {
                "id": 1754,
                "itemName": "BSc in mechanical engineering with energy and environment specialization"
            },
            {
                "id": 1755,
                "itemName": "BSc in software engineering with a minor in biomedical engineering"
            },
            {
                "id": 1756,
                "itemName": "BSc Honours in neuroscience"
            },
            {
                "id": 1757,
                "itemName": "BSc in energy engineering"
            },
            {
                "id": 1758,
                "itemName": "BEd Community-Based Program"
            },
            {
                "id": 1759,
                "itemName": "Bachelor of commerce with concentration in real estate studies"
            },
            {
                "id": 1760,
                "itemName": "BSc in civil engineering with a minor in Energy and Environment"
            },
            {
                "id": 1761,
                "itemName": "BSc in civil engineering/Bachelor of commerce"
            },
            {
                "id": 1762,
                "itemName": "BSc in electrical engineering with a minor in Computer Engineering"
            },
            {
                "id": 1763,
                "itemName": "BSc in electrical engineering/Bachelor of commerce"
            },
            {
                "id": 1764,
                "itemName": "BSc in geomatics engineering/Bachelor of commerce"
            },
            {
                "id": 1765,
                "itemName": "BSc in mechanical engineering/Bachelor of commerce"
            },
            {
                "id": 1766,
                "itemName": "BSc in software engineering/Bachelor of commerce"
            },
            {
                "id": 1767,
                "itemName": "Juris Doctor (JD)/Master of Public Policy (MPP)"
            },
            {
                "id": 1768,
                "itemName": "Bachelor of commerce with concentration in business analytics"
            },
            {
                "id": 1769,
                "itemName": "Bachelor of commerce/BSc in engineering"
            },
            {
                "id": 1770,
                "itemName": "BSc in engineering/Bachelor of commerce (BComm)"
            },
            {
                "id": 1771,
                "itemName": "BSc in chemical engineering/Bachelor of commerce"
            },
            {
                "id": 1772,
                "itemName": "BA in East Asian Language Studies"
            },
            {
                "id": 1773,
                "itemName": "BA in multidisciplinary studies"
            },
            {
                "id": 1774,
                "itemName": "Bachelor of Kinesiology (Kinesiology)/Bachelor of Arts (Dance)"
            },
            {
                "id": 1775,
                "itemName": "BSc in general mathematics/Bachelor of Education"
            },
            {
                "id": 1776,
                "itemName": "BSc in mathematics"
            },
            {
                "id": 1777,
                "itemName": "BSc in mathematics/BComm"
            },
            {
                "id": 1778,
                "itemName": "Bachelor of commerce with concentration in energy and professional land management"
            },
            {
                "id": 1779,
                "itemName": "BSc in chemical engineering with a minor in energy and environment"
            },
            {
                "id": 1780,
                "itemName": "BSc in mechanical engineering with a minor in energy and environment"
            },
            {
                "id": 1781,
                "itemName": "Juris Doctor (JD) Calgary/JD (Houston)"
            },
            {
                "id": 1782,
                "itemName": "Bachelor of commerce with concentration in business"
            },
            {
                "id": 1783,
                "itemName": "BSc in chemical engineering with a minor in digital engineering"
            },
            {
                "id": 1784,
                "itemName": "BSc in civil engineering with a minor in digital engineering"
            },
            {
                "id": 1785,
                "itemName": "BSc in electrical engineering with a minor in aerospace engineering"
            },
            {
                "id": 1786,
                "itemName": "BSc in electrical engineering with a minor in digital engineering"
            },
            {
                "id": 1787,
                "itemName": "BSc in Geomatics engineering with a minor in aerospace engineering"
            },
            {
                "id": 1788,
                "itemName": "BSc in Geomatics engineering with a digital engineering minor"
            },
            {
                "id": 1789,
                "itemName": "BSc in Geomatics engineering with a concentration in cadastral surveying"
            },
            {
                "id": 1790,
                "itemName": "BSc in mechanical engineering with a minor in aerospace engineering"
            },
            {
                "id": 1791,
                "itemName": "BSc in mechanical engineering with a minor in digital engineering"
            },
            {
                "id": 1792,
                "itemName": "BSc in oil and gas engineering with a minor in digital engineering"
            },
            {
                "id": 1793,
                "itemName": "BSc in oil and gas engineering with a minor in energy and environment engineering"
            },
            {
                "id": 1794,
                "itemName": "BSc in software engineering with a minor in aerospace engineering"
            },
            {
                "id": 1795,
                "itemName": "Bachelor of Management with major in Entrepreneurship and Innovation"
            },
            {
                "id": 1796,
                "itemName": "Bachelor of Engineering in Materials Engineering"
            },
            {
                "id": 1797,
                "itemName": "BA in Contemporary Studies - combined honours only"
            },
            {
                "id": 1798,
                "itemName": "BA with minor in German"
            },
            {
                "id": 1799,
                "itemName": "BA in French - with major/double major/honours/combined honours"
            },
            {
                "id": 1800,
                "itemName": "BA in English - with major/double major/honours/combined honours"
            },
            {
                "id": 1801,
                "itemName": "BA in Classics - with major/honours/combined honours"
            },
            {
                "id": 1802,
                "itemName": "BA in History - with major/double major/combined honours"
            },
            {
                "id": 1803,
                "itemName": "Bachelor of Engineering in Industrial Engineering"
            },
            {
                "id": 1804,
                "itemName": "Bachelor of Engineering in Electrical Engineering"
            },
            {
                "id": 1805,
                "itemName": "Bachelor of Engineering in Chemical Engineering"
            },
            {
                "id": 1806,
                "itemName": "Bachelor of Engineering in Mechanical Engineering"
            },
            {
                "id": 1807,
                "itemName": "Bachelor of Engineering in Civil Engineering"
            },
            {
                "id": 1808,
                "itemName": "BSc / BA in Computer Science -- major/Double major/combined honours"
            },
            {
                "id": 1809,
                "itemName": "Bachelor of Computer Science (regular/honours)"
            },
            {
                "id": 1810,
                "itemName": "Bachelor of Environmental Design Studies"
            },
            {
                "id": 1811,
                "itemName": "BSc(Agr) in Plant Science - major or minor"
            },
            {
                "id": 1812,
                "itemName": "BSc(Agr) in Environmental Sciences - major or minor"
            },
            {
                "id": 1813,
                "itemName": "BSc (Agr) in Aquaculture - major or minor"
            },
            {
                "id": 1814,
                "itemName": "BSc (Agr) in Agricultural Business - major or minor"
            },
            {
                "id": 1815,
                "itemName": "BSc(Agr) in Animal Science - major or minor"
            },
            {
                "id": 1816,
                "itemName": "BSc(Agr) in Agricultural Economics - major or minor"
            },
            {
                "id": 1817,
                "itemName": "BSc(Agr) in Integrated Environmental Management"
            },
            {
                "id": 1818,
                "itemName": "BA in International Development Studies - major/double major/combined honours"
            },
            {
                "id": 1819,
                "itemName": "BA in Political Science - with major/honours"
            },
            {
                "id": 1820,
                "itemName": "BA in Russian Studies - with major/honours"
            },
            {
                "id": 1821,
                "itemName": "BA in Music - with major/double major/honours/combined honours"
            },
            {
                "id": 1822,
                "itemName": "BA with minor in International Development Studies"
            },
            {
                "id": 1823,
                "itemName": "BA in History of Science and Technology - combined honours only"
            },
            {
                "id": 1824,
                "itemName": "BA with minor in French"
            },
            {
                "id": 1825,
                "itemName": "BA with minor in English"
            },
            {
                "id": 1826,
                "itemName": "BA with minor in Classics"
            },
            {
                "id": 1827,
                "itemName": "BA in Early Modern Studies - combined honours only"
            },
            {
                "id": 1828,
                "itemName": "BA in Spanish - with major/honours"
            },
            {
                "id": 1829,
                "itemName": "BA with minor in Sociology and Social Anthropology"
            },
            {
                "id": 1830,
                "itemName": "BA in Sociology and Social Anthropology - with major"
            },
            {
                "id": 1831,
                "itemName": "BA with minor in Russian Studies"
            },
            {
                "id": 1832,
                "itemName": "BSc in Microbiology and Immunology -- major/honours"
            },
            {
                "id": 1833,
                "itemName": "BSc with minor in Earth Sciences"
            },
            {
                "id": 1834,
                "itemName": "BSc in Biochemistry and Molecular Biology -- major/honours"
            },
            {
                "id": 1835,
                "itemName": "BSc in Marine Biology -- major/honours"
            },
            {
                "id": 1836,
                "itemName": "BSc in Neuroscience -- major/honours"
            },
            {
                "id": 1837,
                "itemName": "BSc in Statistics -- major/honours"
            },
            {
                "id": 1838,
                "itemName": "BSc in Physics -- major/honours"
            },
            {
                "id": 1839,
                "itemName": "BSc with minor in Economics"
            },
            {
                "id": 1840,
                "itemName": "BA with minor in Economics"
            },
            {
                "id": 1841,
                "itemName": "BA with minor in Earth Sciences"
            },
            {
                "id": 1842,
                "itemName": "BA in Earth Sciences -- major/honours"
            },
            {
                "id": 1843,
                "itemName": "BSc in Earth Sciences -- major/honours"
            },
            {
                "id": 1844,
                "itemName": "BSc in Environmental Science -- major/honours"
            },
            {
                "id": 1845,
                "itemName": "BSc in Chemistry -- major/honours"
            },
            {
                "id": 1846,
                "itemName": "BA with minor in Chemistry"
            },
            {
                "id": 1847,
                "itemName": "BSc Kinesiology (regular or honours program)"
            },
            {
                "id": 1848,
                "itemName": "BSc in Pharmacy"
            },
            {
                "id": 1849,
                "itemName": "BSc Health Promotion major and honours"
            },
            {
                "id": 1850,
                "itemName": "BSc in Psychology -- major/honours"
            },
            {
                "id": 1851,
                "itemName": "BA with minor in Physics"
            },
            {
                "id": 1852,
                "itemName": "BA in Physics -- major/honours"
            },
            {
                "id": 1853,
                "itemName": "BSc with minor in Physics"
            },
            {
                "id": 1854,
                "itemName": "BSc Recreation/B.Management"
            },
            {
                "id": 1855,
                "itemName": "BSc Recreation"
            },
            {
                "id": 1856,
                "itemName": "BSc in Mathematics -- major/honours"
            },
            {
                "id": 1857,
                "itemName": "BSc with minor in Statistics"
            },
            {
                "id": 1858,
                "itemName": "BA with minor in Mathematics"
            },
            {
                "id": 1859,
                "itemName": "BA in Mathematics -- major/honours"
            },
            {
                "id": 1860,
                "itemName": "BA with minor in Statistics"
            },
            {
                "id": 1861,
                "itemName": "BA in Statistics -- major/honours"
            },
            {
                "id": 1862,
                "itemName": "BSc with minor in Mathematics"
            },
            {
                "id": 1863,
                "itemName": "BA in Chemistry major/honours"
            },
            {
                "id": 1864,
                "itemName": "BA with minor in Biology"
            },
            {
                "id": 1865,
                "itemName": "BSc in Biology -- major/honours"
            },
            {
                "id": 1866,
                "itemName": "BA in Economics -- major/honours"
            },
            {
                "id": 1867,
                "itemName": "BSc with minor in Psychology"
            },
            {
                "id": 1868,
                "itemName": "BSc with minor in Chemistry"
            },
            {
                "id": 1869,
                "itemName": "BSc with minor in Biology"
            },
            {
                "id": 1870,
                "itemName": "Bachelor of Commerce Co-op with major in International Business"
            },
            {
                "id": 1871,
                "itemName": "Bachelor of Commerce Co-op with major in Entrepreneurship"
            },
            {
                "id": 1872,
                "itemName": "Bachelor of Commerce Co-op with major in Accounting"
            },
            {
                "id": 1873,
                "itemName": "BA in Gender and Women's studies - major/double major/combined honours"
            },
            {
                "id": 1874,
                "itemName": "BA with minor in Political Science"
            },
            {
                "id": 1875,
                "itemName": "BA in Philosophy - with major/honours"
            },
            {
                "id": 1876,
                "itemName": "BA with minor in Music"
            },
            {
                "id": 1877,
                "itemName": "BA with minor in Gender and Women's Studies"
            },
            {
                "id": 1878,
                "itemName": "BA with minor in Spanish"
            },
            {
                "id": 1879,
                "itemName": "BA with minor in Philosophy"
            },
            {
                "id": 1880,
                "itemName": "Bachelor of Commerce Co-op with major in Finance"
            },
            {
                "id": 1881,
                "itemName": "Bachelor of Health Science (diagnostic medical ultrasound)"
            },
            {
                "id": 1882,
                "itemName": "Bachelor of Health Science (medical laboratory technology)"
            },
            {
                "id": 1883,
                "itemName": "Bachelor of Health Science (nuclear medicine technology)"
            },
            {
                "id": 1884,
                "itemName": "Bachelor of Health Science (radiological technology)"
            },
            {
                "id": 1885,
                "itemName": "Bachelor of Health Science (respiratory therapy)"
            },
            {
                "id": 1886,
                "itemName": "BMus"
            },
            {
                "id": 1887,
                "itemName": "Bachelor of Community Design"
            },
            {
                "id": 1888,
                "itemName": "Bachelor of Commerce Co-op with major in Marketing"
            },
            {
                "id": 1889,
                "itemName": "Bachelor of Science in Nursing (Arctic nursing)"
            },
            {
                "id": 1890,
                "itemName": "Bachelor of Technology in Environmental Landscape Horticulture"
            },
            {
                "id": 1891,
                "itemName": "Bachelor of Community Design major/honours"
            },
            {
                "id": 1892,
                "itemName": "BA in European Studies (single Major/Concentrated Honours only)"
            },
            {
                "id": 1893,
                "itemName": "BA in German - major/double major/ combined honours"
            },
            {
                "id": 1894,
                "itemName": "BA in Theatre - major/honours"
            },
            {
                "id": 1895,
                "itemName": "BA in Canadian Studies -- double major/combined honours only"
            },
            {
                "id": 1896,
                "itemName": "BEng/MASc in Materials Engineering"
            },
            {
                "id": 1897,
                "itemName": "Bachelor of Engineering in Mineral Resource engineering"
            },
            {
                "id": 1898,
                "itemName": "Bachelor of Engineering in Environmental Engineering"
            },
            {
                "id": 1899,
                "itemName": "BA in Biology major/honours"
            },
            {
                "id": 1900,
                "itemName": "BA in Biochemistry and Molecular Biology -- major/honours"
            },
            {
                "id": 1901,
                "itemName": "BSc in Economics -- major/honours"
            },
            {
                "id": 1902,
                "itemName": "BA in Marine Biology -- major/honours"
            },
            {
                "id": 1903,
                "itemName": "BSc in Environment, Sustainability and Society (double major/combined honours only)"
            },
            {
                "id": 1904,
                "itemName": "BA with minor in Psychology"
            },
            {
                "id": 1905,
                "itemName": "BA in Psychology -- major/honours"
            },
            {
                "id": 1906,
                "itemName": "JD/MBA"
            },
            {
                "id": 1907,
                "itemName": "BA in Creative Writing (double major and combined honours)"
            },
            {
                "id": 1908,
                "itemName": "Bachelor of Informatics"
            },
            {
                "id": 1909,
                "itemName": "Bachelor of Management with major in Leadership and Organization"
            },
            {
                "id": 1910,
                "itemName": "Bachelor of Management with major in Public Sector Management"
            },
            {
                "id": 1911,
                "itemName": "Bachelor of Management with major in Knowledge Management"
            },
            {
                "id": 1912,
                "itemName": "Bachelor of Management with major in Environment Sustainability and Society"
            },
            {
                "id": 1913,
                "itemName": "BA in Environment, Sustainability and Society (double major/combined honours only)"
            },
            {
                "id": 1914,
                "itemName": "Minor in Film Studies"
            },
            {
                "id": 1915,
                "itemName": "Minor in Law, Justice and Society"
            },
            {
                "id": 1916,
                "itemName": "BA in Religious Studies (major/combined honours)"
            },
            {
                "id": 1917,
                "itemName": "Bachelor of Engineering/Bachelor of Science"
            },
            {
                "id": 1918,
                "itemName": "Bachelor of Engineering/Bachelor of Arts"
            },
            {
                "id": 1919,
                "itemName": "BSc in Ocean Sciences -- major/honours"
            },
            {
                "id": 1920,
                "itemName": "Bachelor of Agriculture - International Food Business"
            },
            {
                "id": 1921,
                "itemName": "Bachelor of Science in Bioveterinary Science"
            },
            {
                "id": 1922,
                "itemName": "Minor in Food Bioscience"
            },
            {
                "id": 1923,
                "itemName": "Bachelor of Technology in Landscape Architecture"
            },
            {
                "id": 1924,
                "itemName": "Community Design (Minor)"
            },
            {
                "id": 1925,
                "itemName": "Minor in Abrahamic Religions"
            },
            {
                "id": 1926,
                "itemName": "American Studies (Minor)"
            },
            {
                "id": 1927,
                "itemName": "Minor in Ancient History"
            },
            {
                "id": 1928,
                "itemName": "Minor in Applied Ethics"
            },
            {
                "id": 1929,
                "itemName": "Minor in Arabic Studies"
            },
            {
                "id": 1930,
                "itemName": "Minor in Bioethics"
            },
            {
                "id": 1931,
                "itemName": "Minor in Chinese Studies"
            },
            {
                "id": 1932,
                "itemName": "Minor in Esoteric and Occult Traditions"
            },
            {
                "id": 1933,
                "itemName": "Minor in Hispanic Cultures"
            },
            {
                "id": 1934,
                "itemName": "Minor in Indigenous Studies (Minor)"
            },
            {
                "id": 1935,
                "itemName": "Minor in Italian Studies"
            },
            {
                "id": 1936,
                "itemName": "Minor in Latin American Studies"
            },
            {
                "id": 1937,
                "itemName": "Minor in Middle East and South Asian Studies"
            },
            {
                "id": 1938,
                "itemName": "Popular Culture Studies (Minor)"
            },
            {
                "id": 1939,
                "itemName": "Security Studies (Minor)"
            },
            {
                "id": 1940,
                "itemName": "BA with minor in Theatre"
            },
            {
                "id": 1941,
                "itemName": "Bachelor of Commerce Co-op"
            },
            {
                "id": 1942,
                "itemName": "Bachelor of Commerce Co-op with major in Managing People and Organizations"
            },
            {
                "id": 1943,
                "itemName": "Business (Minor)"
            },
            {
                "id": 1944,
                "itemName": "BA in Environmental Science -- major/honours"
            },
            {
                "id": 1945,
                "itemName": "BA in Microbiology and Immunology -- major/honours"
            },
            {
                "id": 1946,
                "itemName": "BA in Neuroscience -- major/honours"
            },
            {
                "id": 1947,
                "itemName": "BA in Ocean Sciences -- major/honours"
            },
            {
                "id": 1948,
                "itemName": "BSc in Actuarial Science -- major/honours"
            },
            {
                "id": 1949,
                "itemName": "BSc in Medical Sciences (regular/honours)"
            },
            {
                "id": 1950,
                "itemName": "Minor in Environmental Studies"
            },
            {
                "id": 1951,
                "itemName": "Minor in Geography"
            },
            {
                "id": 1952,
                "itemName": "Health Studies (Minor)"
            },
            {
                "id": 1953,
                "itemName": "Journalism Studies (Minor)"
            },
            {
                "id": 1954,
                "itemName": "Bachelor of Technology in Small Business Management"
            },
            {
                "id": 1955,
                "itemName": "Bachelor of Community Design, Double Major in Community Design and Sustainability"
            },
            {
                "id": 1956,
                "itemName": "BA or BSc Double Major/Combined Honours in Canadian Studies"
            },
            {
                "id": 1957,
                "itemName": "BA Major / Double Major / Combined Honours in Cinema and Media Studies"
            },
            {
                "id": 1958,
                "itemName": "BA in Law, Justice and Society – major/double major/concentrated and combined honours "
            },
            {
                "id": 1959,
                "itemName": "BA with minor in Religious Studies"
            },
            {
                "id": 1960,
                "itemName": "Minor in Classical Literature"
            },
            {
                "id": 1961,
                "itemName": "Minor in Hispanic Literature"
            },
            {
                "id": 1962,
                "itemName": "Bachelor of Applied Computer Science"
            },
            {
                "id": 1963,
                "itemName": "Bachelor of Commerce Co-op with major in Supply Chain & Logistics Management"
            },
            {
                "id": 1964,
                "itemName": "BSc with minor in Biochemistry and Molecular Biology"
            },
            {
                "id": 1965,
                "itemName": "Minor in Bioinformatics"
            },
            {
                "id": 1966,
                "itemName": "BJH Combined Honours in Journalism and Environment, Sustainability, and Society"
            },
            {
                "id": 1967,
                "itemName": "Minor in Agricultural Business Management"
            },
            {
                "id": 1968,
                "itemName": "Minor in Animal Agriculture"
            },
            {
                "id": 1969,
                "itemName": "Minor in Environmental Horticulture"
            },
            {
                "id": 1970,
                "itemName": "Minor in Equine Sciences"
            },
            {
                "id": 1971,
                "itemName": "BA with minor in Environmental Studies"
            },
            {
                "id": 1972,
                "itemName": "Minor in Classics: Ancient Philosophy"
            },
            {
                "id": 1973,
                "itemName": "Minor in Classics: Medieval Philosophy"
            },
            {
                "id": 1974,
                "itemName": "Minor in Creative Writing"
            },
            {
                "id": 1975,
                "itemName": "Minor in French: Linguistics and Translation"
            },
            {
                "id": 1976,
                "itemName": "Minor in French: Literature and Culture"
            },
            {
                "id": 1977,
                "itemName": "Bachelor of Engineering in Computer Engineering"
            },
            {
                "id": 1978,
                "itemName": "Bachelor of Management with major in Managing Data and Information"
            },
            {
                "id": 1979,
                "itemName": "BSc with minor in Bioinformatics"
            },
            {
                "id": 1980,
                "itemName": "BSc with minor in Food Science"
            },
            {
                "id": 1981,
                "itemName": "Minor in Environment, Sustainability, and Society"
            },
            {
                "id": 1982,
                "itemName": "Doctor of veterinary medicine"
            },
            {
                "id": 1983,
                "itemName": "Bachelor of Commerce in Marketing Management"
            },
            {
                "id": 1984,
                "itemName": "BASc in Child Studies"
            },
            {
                "id": 1985,
                "itemName": "BASc in applied human nutrition"
            },
            {
                "id": 1986,
                "itemName": "BASc in Family Studies and Human Development"
            },
            {
                "id": 1987,
                "itemName": "Bachelor of Commerce in Real Estate"
            },
            {
                "id": 1988,
                "itemName": "BA honours international development studies"
            },
            {
                "id": 1989,
                "itemName": "BA honours information systems and human behavior"
            },
            {
                "id": 1990,
                "itemName": "BSc honours mathematics"
            },
            {
                "id": 1991,
                "itemName": "BSc honours statistics"
            },
            {
                "id": 1992,
                "itemName": "BSc honours theoretical physics"
            },
            {
                "id": 1993,
                "itemName": "BSc honours chemical physics"
            },
            {
                "id": 1994,
                "itemName": "BSc honours physics"
            },
            {
                "id": 1995,
                "itemName": "BSc honours biochemistry"
            },
            {
                "id": 1996,
                "itemName": "BSc honours chemistry"
            },
            {
                "id": 1997,
                "itemName": "BSc honours physical science"
            },
            {
                "id": 1998,
                "itemName": "Bachelor of computing honours computer science"
            },
            {
                "id": 1999,
                "itemName": "Bachelor of landscape architecture"
            },
            {
                "id": 2000,
                "itemName": "BSc honours human kinetics"
            },
            {
                "id": 2001,
                "itemName": "BSc honours bio-medical science"
            },
            {
                "id": 2002,
                "itemName": "BSc honours nutritional and nutraceutical sciences"
            },
            {
                "id": 2003,
                "itemName": "BSc in biomedical toxicology"
            },
            {
                "id": 2004,
                "itemName": "BA honours Food, Agriculture and Resource Economics"
            },
            {
                "id": 2005,
                "itemName": "BSc honours animal biology"
            },
            {
                "id": 2006,
                "itemName": "BSc in environmental sciences (BSc Env) with major in environmental sciences"
            },
            {
                "id": 2007,
                "itemName": "BEng in water resources engineering"
            },
            {
                "id": 2008,
                "itemName": "BEng in biological engineering"
            },
            {
                "id": 2009,
                "itemName": "BEng in engineering systems and computing"
            },
            {
                "id": 2010,
                "itemName": "BEng in environmental engineering"
            },
            {
                "id": 2011,
                "itemName": "BSc honours zoology"
            },
            {
                "id": 2012,
                "itemName": "BSc honours wild life biology and conservation"
            },
            {
                "id": 2013,
                "itemName": "BSc honours marine and freshwater biology"
            },
            {
                "id": 2014,
                "itemName": "BSc honours microbiology"
            },
            {
                "id": 2015,
                "itemName": "BSc honours biological science"
            },
            {
                "id": 2016,
                "itemName": "BSc general biological science"
            },
            {
                "id": 2017,
                "itemName": "BSc honours Plant Science"
            },
            {
                "id": 2018,
                "itemName": "BSc honours molecular biology and genetics"
            },
            {
                "id": 2019,
                "itemName": "Bachelor of Commerce in leadership and organizational management"
            },
            {
                "id": 2020,
                "itemName": "Bachelor of Commerce in Government, Economics and Management"
            },
            {
                "id": 2021,
                "itemName": "BA (individual studies)"
            },
            {
                "id": 2022,
                "itemName": "BA honours political science"
            },
            {
                "id": 2023,
                "itemName": "BA honours music"
            },
            {
                "id": 2024,
                "itemName": "BA honours mathematics"
            },
            {
                "id": 2025,
                "itemName": "BA minor in German"
            },
            {
                "id": 2026,
                "itemName": "BA honours history"
            },
            {
                "id": 2027,
                "itemName": "BA honours geography"
            },
            {
                "id": 2028,
                "itemName": "BA honours French Studies"
            },
            {
                "id": 2029,
                "itemName": "BA honours English"
            },
            {
                "id": 2030,
                "itemName": "BA honours economics"
            },
            {
                "id": 2031,
                "itemName": "BA honours Theatre Studies"
            },
            {
                "id": 2032,
                "itemName": "BA general music"
            },
            {
                "id": 2033,
                "itemName": "BA general history"
            },
            {
                "id": 2034,
                "itemName": "BA general geography"
            },
            {
                "id": 2035,
                "itemName": "BA general French"
            },
            {
                "id": 2036,
                "itemName": "BA general English Studies"
            },
            {
                "id": 2037,
                "itemName": "BA general Theatre Studies"
            },
            {
                "id": 2038,
                "itemName": "BSc honours psychology: brain and cognition"
            },
            {
                "id": 2039,
                "itemName": "BA general sociology"
            },
            {
                "id": 2040,
                "itemName": "BA honours psychology"
            },
            {
                "id": 2041,
                "itemName": "BA honours philosophy"
            },
            {
                "id": 2042,
                "itemName": "BA general mathematics"
            },
            {
                "id": 2043,
                "itemName": "BA honours classical studies"
            },
            {
                "id": 2044,
                "itemName": "BA minor in Italian"
            },
            {
                "id": 2045,
                "itemName": "BA honours studio art"
            },
            {
                "id": 2046,
                "itemName": "BA general political science"
            },
            {
                "id": 2047,
                "itemName": "Bachelor of Commerce in Management Economics and Finance"
            },
            {
                "id": 2048,
                "itemName": "BA honours mathematical economics"
            },
            {
                "id": 2049,
                "itemName": "BA honours European studies"
            },
            {
                "id": 2050,
                "itemName": "BSc(Agr) in animal science"
            },
            {
                "id": 2051,
                "itemName": "BSc(Agr) in crop, horticulture and turfgrass science"
            },
            {
                "id": 2052,
                "itemName": "BSc honours food science"
            },
            {
                "id": 2053,
                "itemName": "Bachelor of Commerce in Food and Agricultural Business"
            },
            {
                "id": 2054,
                "itemName": "BA honours art history"
            },
            {
                "id": 2055,
                "itemName": "BA honours anthropology"
            },
            {
                "id": 2056,
                "itemName": "BA general anthropology"
            },
            {
                "id": 2057,
                "itemName": "BA honours criminal justice and public policy"
            },
            {
                "id": 2058,
                "itemName": "BA general statistics"
            },
            {
                "id": 2059,
                "itemName": "BA general philosophy"
            },
            {
                "id": 2060,
                "itemName": "BSc honours biological and pharmaceutical chemistry"
            },
            {
                "id": 2061,
                "itemName": "Bachelor of computing general"
            },
            {
                "id": 2062,
                "itemName": "BA general international development"
            },
            {
                "id": 2063,
                "itemName": "BSc (Agr) in Honours Agriculture"
            },
            {
                "id": 2064,
                "itemName": "BSc minor in biology"
            },
            {
                "id": 2065,
                "itemName": "BSc minor in biotechnology"
            },
            {
                "id": 2066,
                "itemName": "BA minor in business administration"
            },
            {
                "id": 2067,
                "itemName": "BSc minor in Applied Geomatics"
            },
            {
                "id": 2068,
                "itemName": "BSc minor in mathematical science"
            },
            {
                "id": 2069,
                "itemName": "BSc minor in neuroscience"
            },
            {
                "id": 2070,
                "itemName": "BA minor in family and child studies"
            },
            {
                "id": 2071,
                "itemName": "Honours Bachelor of Business Administration (BBA) and Diploma in Business Administration"
            },
            {
                "id": 2072,
                "itemName": "Honours Bachelor of Applied Arts in Media and Communication Studies (BAA) and Diploma in Media Communications"
            },
            {
                "id": 2073,
                "itemName": "Honours Bachelor of Applied Science in Early Childhood Studies (BASc) and Diploma in Early Childhood Education"
            },
            {
                "id": 2074,
                "itemName": "Honours Bachelor of Applied Science in Family and Community Social Services (BASc) and Social Service Worker Diploma"
            },
            {
                "id": 2075,
                "itemName": "Honours Bachelor of Applied Science in Justice Studies (BASc) & Diploma in Community and Justice Services or Police Foundations"
            },
            {
                "id": 2076,
                "itemName": "BA minor in Museum Studies"
            },
            {
                "id": 2077,
                "itemName": "BAS Bachelor of Arts and Sciences"
            },
            {
                "id": 2078,
                "itemName": "BBRM in Environmental Management"
            },
            {
                "id": 2079,
                "itemName": "BBRM in Equine Management"
            },
            {
                "id": 2080,
                "itemName": "BSc general physical science"
            },
            {
                "id": 2081,
                "itemName": "Honours Bachelor of Applied Science in Kinesiology (BASc) and Diploma in Fitness and Health Promotion"
            },
            {
                "id": 2082,
                "itemName": "BA honours Environmental Governance"
            },
            {
                "id": 2083,
                "itemName": "BSc honours nanoscience"
            },
            {
                "id": 2084,
                "itemName": "BEng in mechanical engineering"
            },
            {
                "id": 2085,
                "itemName": "BA minor in Ethics in Life Science"
            },
            {
                "id": 2086,
                "itemName": "BEng in bio-medical engineering"
            },
            {
                "id": 2087,
                "itemName": "Honours Bachelor of Applied Science in Psychology (BASc) and Diploma in General Arts and Science"
            },
            {
                "id": 2088,
                "itemName": "BA minor in Computing and Information Sciences"
            },
            {
                "id": 2089,
                "itemName": "BA honours Applied Mathematics and Statistics"
            },
            {
                "id": 2090,
                "itemName": "BA honours statistics"
            },
            {
                "id": 2091,
                "itemName": "BA minor in European Culture and Civilization"
            },
            {
                "id": 2092,
                "itemName": "BSc honours biodiversity"
            },
            {
                "id": 2093,
                "itemName": "BSc honours environmental biology"
            },
            {
                "id": 2094,
                "itemName": "BSc honours biological and medical physics"
            },
            {
                "id": 2095,
                "itemName": "BSc minor in computing and information science"
            },
            {
                "id": 2096,
                "itemName": "BSc environmental geomatics"
            },
            {
                "id": 2097,
                "itemName": "BSc biomedical toxicology"
            },
            {
                "id": 2098,
                "itemName": "BEng in computer engineering"
            },
            {
                "id": 2099,
                "itemName": "BEng minor in Food Engineering"
            },
            {
                "id": 2100,
                "itemName": "Bachelor of Commerce in Accounting"
            },
            {
                "id": 2101,
                "itemName": "Bachelor of Commerce (BComm)"
            },
            {
                "id": 2102,
                "itemName": "BA honours sociology"
            },
            {
                "id": 2103,
                "itemName": "Bachelor of computing honours software engineering"
            },
            {
                "id": 2104,
                "itemName": "BA minor in Agriculture"
            },
            {
                "id": 2105,
                "itemName": "Bachelor of Commerce in Hospitality and Tourism Management"
            },
            {
                "id": 2106,
                "itemName": "BSc honours Mathematical Science"
            },
            {
                "id": 2107,
                "itemName": "BSc environmental sciences (BSES) - Ecology"
            },
            {
                "id": 2108,
                "itemName": "BSc environmental sciences (BSES) - Environment and Resource Management"
            },
            {
                "id": 2109,
                "itemName": "BSc environmental sciences (BSES) - Environmental Sciences"
            },
            {
                "id": 2110,
                "itemName": "BBRM in Food Industry Management"
            },
            {
                "id": 2111,
                "itemName": "Bachelor of Commerce in Event and Sport Management"
            },
            {
                "id": 2112,
                "itemName": "BA major Culture and Technology Studies"
            },
            {
                "id": 2113,
                "itemName": "BA major Justice and Legal Studies"
            },
            {
                "id": 2114,
                "itemName": "Bachelor of Indigenous Environmental Stewardship"
            },
            {
                "id": 2115,
                "itemName": "JD/MPA (Juris Doctor and Master of Public Administration)"
            },
            {
                "id": 2116,
                "itemName": "Juris Doctor/Master of Business Administration"
            },
            {
                "id": 2117,
                "itemName": "Juris Doctor/Master of Public Administration"
            },
            {
                "id": 2118,
                "itemName": "BSc in Health Information Science"
            },
            {
                "id": 2119,
                "itemName": "Bachelor of Child and Youth Care"
            },
            {
                "id": 2120,
                "itemName": "BA in Art History and Visual Studies"
            },
            {
                "id": 2121,
                "itemName": "BFA in Writing"
            },
            {
                "id": 2122,
                "itemName": "BA in Writing"
            },
            {
                "id": 2123,
                "itemName": "BFA in Visual Arts"
            },
            {
                "id": 2124,
                "itemName": "BFA Theatre"
            },
            {
                "id": 2125,
                "itemName": "Bachelor of Music in Music Education Secondary"
            },
            {
                "id": 2126,
                "itemName": "Bachelor of Music in Performance"
            },
            {
                "id": 2127,
                "itemName": "Bachelor of Music in Musicology and Sound Studies"
            },
            {
                "id": 2128,
                "itemName": "Bachelor of Music in Composition and Theory"
            },
            {
                "id": 2129,
                "itemName": "Juris Doctor in Law"
            },
            {
                "id": 2130,
                "itemName": "BSc Major in Computer Science with Software Systems option"
            },
            {
                "id": 2131,
                "itemName": "Bachelor of Engineering in Computer Engineering with Biomedical Option"
            },
            {
                "id": 2132,
                "itemName": "Bachelor of Science Major or Honours in Computer Science (BSc)"
            },
            {
                "id": 2133,
                "itemName": "Bachelor of Engineering in Electrical Engineering with Biomedical Option"
            },
            {
                "id": 2134,
                "itemName": "BEd Secondary"
            },
            {
                "id": 2135,
                "itemName": "BEd Elementary"
            },
            {
                "id": 2136,
                "itemName": "BEd Elementary Curriculum"
            },
            {
                "id": 2137,
                "itemName": "BA in Recreation and Health Education"
            },
            {
                "id": 2138,
                "itemName": "Bachelor of Commerce with specialization in Service Management"
            },
            {
                "id": 2139,
                "itemName": "Bachelor of Commerce Non-Specialized"
            },
            {
                "id": 2140,
                "itemName": "Bachelor of Commerce with specialization in International Business"
            },
            {
                "id": 2141,
                "itemName": "Bachelor of Commerce with specialization in Entrepreneurship"
            },
            {
                "id": 2142,
                "itemName": "BA in Germanic & Slavic Studies"
            },
            {
                "id": 2143,
                "itemName": "BSc in Linguistics"
            },
            {
                "id": 2144,
                "itemName": "BA in Pacific & Asian studies"
            },
            {
                "id": 2145,
                "itemName": "BA in Greek and Roman Studies"
            },
            {
                "id": 2146,
                "itemName": "BSc in Astronomy"
            },
            {
                "id": 2147,
                "itemName": "Combined BSc in Earth and Ocean Sciences"
            },
            {
                "id": 2148,
                "itemName": "Combined BSc in Physics and Earth Sciences (Geophysics)"
            },
            {
                "id": 2149,
                "itemName": "Combined BSc in Physics and Ocean Sciences (Ocean-Atmosphere Sciences)"
            },
            {
                "id": 2150,
                "itemName": "BSc in Microbiology"
            },
            {
                "id": 2151,
                "itemName": "BA in Political Science"
            },
            {
                "id": 2152,
                "itemName": "BSc in Psychology"
            },
            {
                "id": 2153,
                "itemName": "BA in Anthropology"
            },
            {
                "id": 2154,
                "itemName": "BSc in Environmental Studies"
            },
            {
                "id": 2155,
                "itemName": "BSc in Economics"
            },
            {
                "id": 2156,
                "itemName": "Bachelor of Software Engineering (BSEng)"
            },
            {
                "id": 2157,
                "itemName": "BA in Theatre History (Honours only)"
            },
            {
                "id": 2158,
                "itemName": "BFA Combined Visual Arts/Computer Science"
            },
            {
                "id": 2159,
                "itemName": "BSc Combined Visual Arts/Computer Science"
            },
            {
                "id": 2160,
                "itemName": "BFA Combined Music/Computer Science"
            },
            {
                "id": 2161,
                "itemName": "BSc Combined Music/Computer Science"
            },
            {
                "id": 2162,
                "itemName": "Combined BSc Major in Computer Science and Health Information Science"
            },
            {
                "id": 2163,
                "itemName": "Professional specialization certificate"
            },
            {
                "id": 2164,
                "itemName": "Combined BSc in Geography and Computer Science (Geomatics)"
            },
            {
                "id": 2165,
                "itemName": "Combined BSc in Physics and Computer Science "
            },
            {
                "id": 2166,
                "itemName": "Combined BSc in Psychology and Computer Science"
            },
            {
                "id": 2167,
                "itemName": "BEd Middle Years"
            },
            {
                "id": 2168,
                "itemName": "BSc Major in Computer Science with Computer Communications and Networks option"
            },
            {
                "id": 2169,
                "itemName": "BSc Major in Computer Science with Computer Graphics and Gaming option"
            },
            {
                "id": 2170,
                "itemName": "Bachelor of Engineering in Biomedical Engineering"
            },
            {
                "id": 2171,
                "itemName": "Professional Specialization Certificate in Collections Management"
            },
            {
                "id": 2172,
                "itemName": "BA in Health and Community Services"
            },
            {
                "id": 2173,
                "itemName": "BSc Major in Computer Science with Theory option"
            },
            {
                "id": 2174,
                "itemName": "BA in Religion, Culture and Society"
            },
            {
                "id": 2175,
                "itemName": "BA in Latin American Studies"
            },
            {
                "id": 2176,
                "itemName": "Combined BSc in Financial Mathematics and Economics"
            },
            {
                "id": 2177,
                "itemName": "Combined BSc in Microbiology and Chemistry"
            },
            {
                "id": 2178,
                "itemName": "Combined BSc in Physics and Biochemistry"
            },
            {
                "id": 2179,
                "itemName": "Combined BSc in Physical Geography and Earth and Ocean Sciences"
            },
            {
                "id": 2180,
                "itemName": "Combined BSc in Chemistry and Mathematics"
            },
            {
                "id": 2181,
                "itemName": "Combined BSc in Mathematics and Statistics"
            },
            {
                "id": 2182,
                "itemName": "Combined BSc in Biology and Earth Sciences"
            },
            {
                "id": 2183,
                "itemName": "Combined BSc in Biology and Psychology"
            },
            {
                "id": 2184,
                "itemName": "Combined BSc in Chemistry and Earth Sciences"
            },
            {
                "id": 2185,
                "itemName": "Combined BSc in Physics and Mathematics"
            },
            {
                "id": 2186,
                "itemName": "BEd in Indigenous Language Revitalization"
            },
            {
                "id": 2187,
                "itemName": "Bachelor of Engineering in Computer Engineering with Computer Music Option"
            },
            {
                "id": 2188,
                "itemName": "Bachelor of Engineering in Electrical Engineering with Computer Music Option"
            },
            {
                "id": 2189,
                "itemName": "BA in Mathematics or Statistics"
            },
            {
                "id": 2190,
                "itemName": "Combined BSc in Biochemistry and Chemistry"
            },
            {
                "id": 2191,
                "itemName": "BSc in Chemistry for the Medical Sciences"
            },
            {
                "id": 2192,
                "itemName": "Combined BSc in Physics and Astronomy"
            },
            {
                "id": 2193,
                "itemName": "Bachelor of Music in Musical Arts"
            },
            {
                "id": 2194,
                "itemName": "BA in Applied Linguistics"
            },
            {
                "id": 2195,
                "itemName": "BA in Hispanic & Italian Studies"
            },
            {
                "id": 2196,
                "itemName": "Juris Doctor/Juris Indigenarum Doctor (joint degree)"
            },
            {
                "id": 2197,
                "itemName": "Combined BSc in Biology and Mathematics and Statistics"
            },
            {
                "id": 2198,
                "itemName": "Combined Computer Science and Mathematics"
            },
            {
                "id": 2199,
                "itemName": "Combined Computer Science and Statistics"
            },
            {
                "id": 2200,
                "itemName": "Bachelor of Engineering in Computer Engineering with Quantum Physics Option"
            },
            {
                "id": 2201,
                "itemName": "Bachelor of Engineering in Electrical Engineering with Quantum Physics Option"
            },
            {
                "id": 2202,
                "itemName": "Professional Specialization Certificate in Visitor and Community Engagement"
            },
            {
                "id": 2203,
                "itemName": "Juris Doctor in Civil Law"
            },
            {
                "id": 2204,
                "itemName": "BSc in Earth Sciences"
            },
            {
                "id": 2205,
                "itemName": "BA in Disability Studies"
            },
            {
                "id": 2206,
                "itemName": "BASc in Nutrition and Food"
            },
            {
                "id": 2207,
                "itemName": "Bachelor of Urban and Regional Planning (BURPl)"
            },
            {
                "id": 2208,
                "itemName": "Bachelor of Social Work (BSW)"
            },
            {
                "id": 2209,
                "itemName": "BASc Occupational and Public Health"
            },
            {
                "id": 2210,
                "itemName": "BA in Early Childhood Studies"
            },
            {
                "id": 2211,
                "itemName": "BScN Bachelor of Science in Nursing"
            },
            {
                "id": 2212,
                "itemName": "BHSc - Midwifery"
            },
            {
                "id": 2213,
                "itemName": "Bachelor of Health Administration (BHA)"
            },
            {
                "id": 2214,
                "itemName": "BA in Child and Youth Care"
            },
            {
                "id": 2215,
                "itemName": "Bachelor of Journalism (BJourn)"
            },
            {
                "id": 2216,
                "itemName": "Bachelor of Design (BDes) in Fashion Communication"
            },
            {
                "id": 2217,
                "itemName": "BFA in Image Arts"
            },
            {
                "id": 2218,
                "itemName": "BFA in Performance (Production)"
            },
            {
                "id": 2219,
                "itemName": "Bachelor of Technology (BTech) in Graphic Communications Management"
            },
            {
                "id": 2220,
                "itemName": "BFA in Performance (Dance)"
            },
            {
                "id": 2221,
                "itemName": "Bachelor of Interior Design (BID)"
            },
            {
                "id": 2222,
                "itemName": "BSc (Hons.) in Computer Science"
            },
            {
                "id": 2223,
                "itemName": "BEng in Mechanical Engineering"
            },
            {
                "id": 2224,
                "itemName": "BEng in Electrical Engineering"
            },
            {
                "id": 2225,
                "itemName": "BEng in Aerospace Engineering"
            },
            {
                "id": 2226,
                "itemName": "BEng in Computer Engineering"
            },
            {
                "id": 2227,
                "itemName": "BEng in Civil Engineering"
            },
            {
                "id": 2228,
                "itemName": "BEng in Chemical Engineering (Co-operative Program)"
            },
            {
                "id": 2229,
                "itemName": "BSc (Hons.) in Biology"
            },
            {
                "id": 2230,
                "itemName": "BArchSc in Architectural Science"
            },
            {
                "id": 2231,
                "itemName": "BComm in Business Technology Management"
            },
            {
                "id": 2232,
                "itemName": "BComm (Hons.) in Retail Management"
            },
            {
                "id": 2233,
                "itemName": "BComm in Business Management"
            },
            {
                "id": 2234,
                "itemName": "BComm (Hons.) in Hospitality and Tourism Management"
            },
            {
                "id": 2235,
                "itemName": "BA (Hons.) in Geographic Analysis"
            },
            {
                "id": 2236,
                "itemName": "BA (Hons.) in Public Administration and Governance"
            },
            {
                "id": 2237,
                "itemName": "BFA in Performance (Acting)"
            },
            {
                "id": 2238,
                "itemName": "BSc (Hons.) in Chemistry"
            },
            {
                "id": 2239,
                "itemName": "BA (Hons.) in Arts and Contemporary Studies"
            },
            {
                "id": 2240,
                "itemName": "BEng in Industrial Engineering"
            },
            {
                "id": 2241,
                "itemName": "BA in International Economics and Finance"
            },
            {
                "id": 2242,
                "itemName": "BA (Hons.) in Politics and Governance"
            },
            {
                "id": 2243,
                "itemName": "BA (Hons.) in Sociology"
            },
            {
                "id": 2244,
                "itemName": "BSc (Hons.) in Medical Physics"
            },
            {
                "id": 2245,
                "itemName": "Bachelor of Design (BDes) in Fashion Design"
            },
            {
                "id": 2246,
                "itemName": "Optional Specialization in Management Sciences"
            },
            {
                "id": 2247,
                "itemName": "BA (Hons.) in Criminology"
            },
            {
                "id": 2248,
                "itemName": "BEng in Biomedical Engineering"
            },
            {
                "id": 2249,
                "itemName": "BSc (Hons.) in Mathematics and its Applications"
            },
            {
                "id": 2250,
                "itemName": "BA (Hons.) in English"
            },
            {
                "id": 2251,
                "itemName": "BA (Hons.) in Environment and Urban Sustainability"
            },
            {
                "id": 2252,
                "itemName": "BA (Hons.) in History"
            },
            {
                "id": 2253,
                "itemName": "BA (Hons.) in Philosophy"
            },
            {
                "id": 2254,
                "itemName": "BA in Creative Industries"
            },
            {
                "id": 2255,
                "itemName": "BA (Hons.) in Professional Communication"
            },
            {
                "id": 2256,
                "itemName": "BFA in New Media"
            },
            {
                "id": 2257,
                "itemName": "BSc (Hons.) in Biomedical Sciences"
            },
            {
                "id": 2258,
                "itemName": "BSc (Hons.) in Financial Mathematics"
            },
            {
                "id": 2259,
                "itemName": "BComm in Accounting"
            },
            {
                "id": 2260,
                "itemName": "BComm in Finance"
            },
            {
                "id": 2261,
                "itemName": "BA in Media Production"
            },
            {
                "id": 2262,
                "itemName": "BA in Sport Media"
            },
            {
                "id": 2263,
                "itemName": "BA (Hons.) in Language and Intercultural Relations"
            },
            {
                "id": 2264,
                "itemName": "Optional Specialization in Engineering Innovation and Entrepreneurship"
            },
            {
                "id": 2265,
                "itemName": "BA (Hons.) in English and History (Double Major)"
            },
            {
                "id": 2266,
                "itemName": "BA (Hons.) in English and Philosophy (Double Major)"
            },
            {
                "id": 2267,
                "itemName": "BA (Hons.) in History and Philosophy (Double Major)"
            },
            {
                "id": 2268,
                "itemName": "BA (Hons.) in Criminology and History (Double Major)"
            },
            {
                "id": 2269,
                "itemName": "BA (Hons.) in Criminology and Politics and Governance (Double Major) "
            },
            {
                "id": 2270,
                "itemName": "BA (Hons.) in Criminology and Sociology (Double Major)"
            },
            {
                "id": 2271,
                "itemName": "BA (Hons.) in History and Politics and Governance Major (Double Major)"
            },
            {
                "id": 2272,
                "itemName": "BA (Hons.) in History and Sociology (Double Major) "
            },
            {
                "id": 2273,
                "itemName": "BA (Hons.) in Politics and Governance and Sociology (Double Major)"
            },
            {
                "id": 2274,
                "itemName": "Doctorat en médecine dentaire (D.M.D.)"
            },
            {
                "id": 2275,
                "itemName": "Baccalauréat en physiothérapie (B. Pht.)"
            },
            {
                "id": 2276,
                "itemName": "Doctorat en médecine (M.D.)"
            },
            {
                "id": 2277,
                "itemName": "Baccalauréat en génie géomatique (B. Ing.)"
            },
            {
                "id": 2278,
                "itemName": "Baccalauréat coopératif en génie du bois (B. Ing.)"
            },
            {
                "id": 2279,
                "itemName": "Baccalauréat en aménagement et environnement forestiers (B. Sc. A.)"
            },
            {
                "id": 2280,
                "itemName": "Baccalauréat coopératif en opérations forestières (B. Sc. A.)"
            },
            {
                "id": 2281,
                "itemName": "Baccalauréat en droit (LL. B.)"
            },
            {
                "id": 2282,
                "itemName": "Baccalauréat en enseignement des arts plastiques (B. Ens.)"
            },
            {
                "id": 2283,
                "itemName": "Baccalauréat en arts visuels et médiatiques (B.A.V.)"
            },
            {
                "id": 2284,
                "itemName": "Baccalauréat en design graphique (B.A.V.)"
            },
            {
                "id": 2285,
                "itemName": "Baccalauréat en architecture (B. Sc. Arch.)"
            },
            {
                "id": 2286,
                "itemName": "Baccalauréat en sciences infirmières (formation initiale) (B. Sc.)"
            },
            {
                "id": 2287,
                "itemName": "Baccalauréat en théologie (B. Th.)"
            },
            {
                "id": 2288,
                "itemName": "Baccalauréat coopératif en génie des matériaux et de la métallurgie (B. Ing.)"
            },
            {
                "id": 2289,
                "itemName": "Baccalauréat en actuariat (B. Sc.)"
            },
            {
                "id": 2290,
                "itemName": "Baccalauréat en mathématiques (B. Sc.)"
            },
            {
                "id": 2291,
                "itemName": "Baccalauréat en génie électrique (B. Ing.)"
            },
            {
                "id": 2292,
                "itemName": "Baccalauréat en génie chimique (B. Ing.)"
            },
            {
                "id": 2293,
                "itemName": "Baccalauréat en chimie (B. Sc.)"
            },
            {
                "id": 2294,
                "itemName": "Baccalauréat coopératif en génie des mines et de la minéralurgie (B. Ing.)"
            },
            {
                "id": 2295,
                "itemName": "Baccalauréat en informatique (B. Sc. A.)"
            },
            {
                "id": 2296,
                "itemName": "Baccalauréat en génie informatique (B. Ing.)"
            },
            {
                "id": 2297,
                "itemName": "Baccalauréat en génie physique (B. Ing.)"
            },
            {
                "id": 2298,
                "itemName": "Baccalauréat en génie civil (B. Ing.)"
            },
            {
                "id": 2299,
                "itemName": "Baccalauréat en biologie (B. Sc.)"
            },
            {
                "id": 2300,
                "itemName": "Baccalauréat en génie mécanique (B. Ing.)"
            },
            {
                "id": 2301,
                "itemName": "Baccalauréat en biochimie (B. Sc.)"
            },
            {
                "id": 2302,
                "itemName": "Baccalauréat en géologie (B. Sc.)"
            },
            {
                "id": 2303,
                "itemName": "Baccalauréat en génie industriel (B. Ing.)"
            },
            {
                "id": 2304,
                "itemName": "Baccalauréat en physique (B. Sc.)"
            },
            {
                "id": 2305,
                "itemName": "Baccalauréat en microbiologie (B. Sc.)"
            },
            {
                "id": 2306,
                "itemName": "Baccalauréat en statistique (B. Sc.)"
            },
            {
                "id": 2307,
                "itemName": "Baccalauréat en éducation au préscolaire et en enseignement au primaire (B. Ens.)"
            },
            {
                "id": 2308,
                "itemName": "Baccalauréat en orientation (B. Éd.)"
            },
            {
                "id": 2309,
                "itemName": "Baccalauréat en enseignement de l'éducation physique et à la santé (B. Ens.)"
            },
            {
                "id": 2310,
                "itemName": "Baccalauréat en administration des affaires (B.A.A.)"
            },
            {
                "id": 2311,
                "itemName": "Baccalauréat en philosophie (B.A.)"
            },
            {
                "id": 2312,
                "itemName": "Baccalauréat en kinésiologie (B. Sc.)"
            },
            {
                "id": 2313,
                "itemName": "Baccalauréat en ergothérapie (B. Erg.)"
            },
            {
                "id": 2314,
                "itemName": "Baccalauréat sur mesure"
            },
            {
                "id": 2315,
                "itemName": "Baccalauréat multidisciplinaire"
            },
            {
                "id": 2316,
                "itemName": "Baccalauréat en enseignement de l'anglais, langue seconde (B. Ens.)"
            },
            {
                "id": 2317,
                "itemName": "Baccalauréat en littératures et linguistique anglaises (B.A.)"
            },
            {
                "id": 2318,
                "itemName": "Baccalauréat en études pratiques et littéraires (B.A.)"
            },
            {
                "id": 2319,
                "itemName": "Baccalauréat en sciences du langage (B.A.)"
            },
            {
                "id": 2320,
                "itemName": "Baccalauréat en sociologie (B.A.)"
            },
            {
                "id": 2321,
                "itemName": "Baccalauréat en science politique (B.A.)"
            },
            {
                "id": 2322,
                "itemName": "Baccalauréat en relations industrielles (B.A.)"
            },
            {
                "id": 2323,
                "itemName": "Baccalauréat en psychologie (B.A.)"
            },
            {
                "id": 2324,
                "itemName": "Baccalauréat en économique (B.A.)"
            },
            {
                "id": 2325,
                "itemName": "Baccalauréat en service social (B. Serv. Soc.)"
            },
            {
                "id": 2326,
                "itemName": "Baccalauréat en anthropologie (B.A.)"
            },
            {
                "id": 2327,
                "itemName": "Baccalauréat en sciences et technologie des aliments (B. Sc. A.)"
            },
            {
                "id": 2328,
                "itemName": "Baccalauréat en génie alimentaire (B. Ing.)"
            },
            {
                "id": 2329,
                "itemName": "Baccalauréat en génie agroenvironnemental (B. Ing.)"
            },
            {
                "id": 2330,
                "itemName": "Baccalauréat en nutrition (B. Sc.)"
            },
            {
                "id": 2331,
                "itemName": "Baccalauréat en sciences de la consommation (B.A.)"
            },
            {
                "id": 2332,
                "itemName": "Baccalauréat en agronomie - agronomie générale (B. Sc. A.)"
            },
            {
                "id": 2333,
                "itemName": "Baccalauréat en agroéconomie (B. Sc. A.)"
            },
            {
                "id": 2334,
                "itemName": "Baccalauréat intégré en langue française et révision professionnelle (B.A.)"
            },
            {
                "id": 2335,
                "itemName": "Baccalauréat intégré en études internationales et langues modernes (B.A.)"
            },
            {
                "id": 2336,
                "itemName": "Baccalauréat en études anciennes (B.A.)"
            },
            {
                "id": 2337,
                "itemName": "Baccalauréat en histoire (B.A.)"
            },
            {
                "id": 2338,
                "itemName": "Baccalauréat en géographie (B. Sc.)"
            },
            {
                "id": 2339,
                "itemName": "Baccalauréat en traduction (B.A.)"
            },
            {
                "id": 2340,
                "itemName": "Baccalauréat en histoire de l'art (B.A.)"
            },
            {
                "id": 2341,
                "itemName": "Baccalauréat en sciences géomatiques (B. Sc. A.)"
            },
            {
                "id": 2342,
                "itemName": "Baccalauréat en sciences biomédicales (B. Sc.)"
            },
            {
                "id": 2343,
                "itemName": "Baccalauréat intégré en sciences des religions (B.A.)"
            },
            {
                "id": 2344,
                "itemName": "Baccalauréat en communication publique (B.A.)"
            },
            {
                "id": 2345,
                "itemName": "Baccalauréat en enseignement du français, langue seconde (B. Ens.)"
            },
            {
                "id": 2346,
                "itemName": "Baccalauréat en enseignement au secondaire - français, langue première (B. Ens.)"
            },
            {
                "id": 2347,
                "itemName": "Baccalauréat en enseignement au secondaire - mathématiques (B. Ens.)"
            },
            {
                "id": 2348,
                "itemName": "Baccalauréat en enseignement au secondaire - sciences et technologie (B. Ens.)"
            },
            {
                "id": 2349,
                "itemName": "Baccalauréat en enseignement au secondaire - univers social (histoire et géographie) (B. Ens.)"
            },
            {
                "id": 2350,
                "itemName": "Baccalauréat en intervention sportive (B. Sc.)"
            },
            {
                "id": 2351,
                "itemName": "Baccalauréat en génie logiciel (B. Ing.)"
            },
            {
                "id": 2352,
                "itemName": "Baccalauréat intégré en économie et mathématiques (B.A.)   "
            },
            {
                "id": 2353,
                "itemName": "Baccalauréat intégré en économie et politique (B.A.)"
            },
            {
                "id": 2354,
                "itemName": "Baccalauréat en musique (B. Mus.)"
            },
            {
                "id": 2355,
                "itemName": "Baccalauréat en musique - composition (B. Mus.)"
            },
            {
                "id": 2356,
                "itemName": "Baccalauréat en musique - musicologie (B. Mus.)"
            },
            {
                "id": 2357,
                "itemName": "Baccalauréat en musique - interprétation classique (B. Mus.)"
            },
            {
                "id": 2358,
                "itemName": "Baccalauréat en musique - interprétation jazz, musique populaire (B.  Mus.)"
            },
            {
                "id": 2359,
                "itemName": "Baccalauréat intégré en sciences historiques et études patrimoniales (B.A.)"
            },
            {
                "id": 2360,
                "itemName": "Baccalauréat en enseignement au secondaire - univers social et développement personnel (histoire, éthique et culture religieuse)"
            },
            {
                "id": 2361,
                "itemName": "Baccalauréat intégré en mathématiques et informatique (B. Sc. A.)"
            },
            {
                "id": 2362,
                "itemName": "Baccalauréat en archéologie (B.A.)"
            },
            {
                "id": 2363,
                "itemName": "Baccalauréat intégré en philosophie et science politique (B.A.)"
            },
            {
                "id": 2364,
                "itemName": "Baccalauréat en bio-informatique (B. Sc.)"
            },
            {
                "id": 2365,
                "itemName": "Baccalauréat en génie des eaux (B. Ing.)"
            },
            {
                "id": 2366,
                "itemName": "Baccalauréat intégré en affaires publiques et relations internationales (B.A.)"
            },
            {
                "id": 2367,
                "itemName": "Baccalauréat en sciences infirmières (formation DEC -BAC) (B. Sc.)"
            },
            {
                "id": 2368,
                "itemName": "Baccalauréat intégré en environnements naturels et aménagés (B. Sc. A.)"
            },
            {
                "id": 2369,
                "itemName": "Baccalauréat en enseignement de la musique (B. Ens.)"
            },
            {
                "id": 2370,
                "itemName": "Baccalauréat en théâtre et arts vivants (B.A.)"
            },
            {
                "id": 2371,
                "itemName": "Baccalauréat en psychoéducation (B. Éd.)"
            },
            {
                "id": 2372,
                "itemName": "Baccalauréat en chimie -  biopharmaceutique (B. Sc.)"
            },
            {
                "id": 2373,
                "itemName": "Baccalauréat en chimie - environnement (B. Sc.)"
            },
            {
                "id": 2374,
                "itemName": "Baccalauréat en chimie - matériaux (B. Sc.)"
            },
            {
                "id": 2375,
                "itemName": "Baccalauréat en art et science de l'animation (B.A.V.)"
            },
            {
                "id": 2376,
                "itemName": "Baccalauréat en génie géologique (B. Ing.)"
            },
            {
                "id": 2377,
                "itemName": "Baccalauréat en théologie - grade canonique (B. Th.)"
            },
            {
                "id": 2378,
                "itemName": "Baccalauréat en agronomie - productions animales (B. Sc. A.)"
            },
            {
                "id": 2379,
                "itemName": "Baccalauréat en criminologie (B.A.)"
            },
            {
                "id": 2380,
                "itemName": "Baccalauréat en agronomie - productions végétales  (B. Sc. A.)"
            },
            {
                "id": 2381,
                "itemName": "Baccalauréat en agronomie - sols et environnement (B. Sc. A.)"
            },
            {
                "id": 2382,
                "itemName": "Baccalauréat en chimie - cosméceutique (B. Sc.)"
            },
            {
                "id": 2383,
                "itemName": "Baccalauréat intégré en informatique et gestion (B. Sc. A.)"
            },
            {
                "id": 2384,
                "itemName": "Baccalauréat en design de produits (B.D.)"
            },
            {
                "id": 2385,
                "itemName": "BFA in Photography"
            },
            {
                "id": 2386,
                "itemName": "BFA in Print Media"
            },
            {
                "id": 2387,
                "itemName": "BFA in Film Production"
            },
            {
                "id": 2388,
                "itemName": "BFA in Painting and Drawing"
            },
            {
                "id": 2389,
                "itemName": "BFA in Music"
            },
            {
                "id": 2390,
                "itemName": "BFA in Design"
            },
            {
                "id": 2391,
                "itemName": "BFA in Film Studies"
            },
            {
                "id": 2392,
                "itemName": "BFA in Art History"
            },
            {
                "id": 2393,
                "itemName": "BFA in Art Education, Visual Arts"
            },
            {
                "id": 2394,
                "itemName": "BFA in Art History and Studio Art"
            },
            {
                "id": 2395,
                "itemName": "BFA in Music Composition - specialization BFA Music"
            },
            {
                "id": 2396,
                "itemName": "BFA in Music Performance - specialization BFA Music"
            },
            {
                "id": 2397,
                "itemName": "BFA in Jazz Studies"
            },
            {
                "id": 2398,
                "itemName": "BFA in Intermedia (Video, Performance and Electronic Arts)"
            },
            {
                "id": 2399,
                "itemName": "BFA in Contemporary Dance"
            },
            {
                "id": 2400,
                "itemName": "BFA in Studio Art"
            },
            {
                "id": 2401,
                "itemName": "BFA in Design for the Theatre"
            },
            {
                "id": 2402,
                "itemName": "BFA in Ceramics"
            },
            {
                "id": 2403,
                "itemName": "BFA in Fibres and Material Practices"
            },
            {
                "id": 2404,
                "itemName": "BFA in Computation Arts"
            },
            {
                "id": 2405,
                "itemName": "Bachelor of Computer Science (General Program)"
            },
            {
                "id": 2406,
                "itemName": "BEng in Building Engineering"
            },
            {
                "id": 2407,
                "itemName": "BA in English and History"
            },
            {
                "id": 2408,
                "itemName": "BA in Human Relations"
            },
            {
                "id": 2409,
                "itemName": "BA in Journalism"
            },
            {
                "id": 2410,
                "itemName": "BSc in Environmental Geography"
            },
            {
                "id": 2411,
                "itemName": "BA en Traduction"
            },
            {
                "id": 2412,
                "itemName": "BA in English and Creative Writing"
            },
            {
                "id": 2413,
                "itemName": "BA in English Literature"
            },
            {
                "id": 2414,
                "itemName": "BA in Early Childhood and Elementary Education"
            },
            {
                "id": 2415,
                "itemName": "BA in Spanish, Hispanic Cultures and Literatures"
            },
            {
                "id": 2416,
                "itemName": "BA in Theological Studies"
            },
            {
                "id": 2417,
                "itemName": "BA in Religions and Cultures"
            },
            {
                "id": 2418,
                "itemName": "BEd in Teaching English as a Second Language (TESL)"
            },
            {
                "id": 2419,
                "itemName": "BSc in Exercise Science"
            },
            {
                "id": 2420,
                "itemName": "BA in Urban Studies and Urban Planning"
            },
            {
                "id": 2421,
                "itemName": "BA in Actuarial Mathematics"
            },
            {
                "id": 2422,
                "itemName": "BA in Communication Studies"
            },
            {
                "id": 2423,
                "itemName": "BSc in Cell and Molecular Biology"
            },
            {
                "id": 2424,
                "itemName": "BFA in Sculpture "
            },
            {
                "id": 2425,
                "itemName": "BSc in Ecology"
            },
            {
                "id": 2426,
                "itemName": "BA in Classics"
            },
            {
                "id": 2427,
                "itemName": "BA in Southern Asia Studies"
            },
            {
                "id": 2428,
                "itemName": "BA in Human Environment"
            },
            {
                "id": 2429,
                "itemName": "BA in Creative Writing"
            },
            {
                "id": 2430,
                "itemName": "BSc in Actuarial Mathematics"
            },
            {
                "id": 2431,
                "itemName": "BA in Mathematics and Statistics"
            },
            {
                "id": 2432,
                "itemName": "BSc in Mathematics and Statistics"
            },
            {
                "id": 2433,
                "itemName": "BA in Therapeutic Recreation"
            },
            {
                "id": 2434,
                "itemName": "BA in Anthropology and Sociology"
            },
            {
                "id": 2435,
                "itemName": "BA in Judaic Studies"
            },
            {
                "id": 2436,
                "itemName": "BA in Women's Studies"
            },
            {
                "id": 2437,
                "itemName": "BSc in Pure and Applied Mathematics"
            },
            {
                "id": 2438,
                "itemName": "BA in Pure and Applied Mathematics"
            },
            {
                "id": 2439,
                "itemName": "BComm in Human Resource Management"
            },
            {
                "id": 2440,
                "itemName": "BComm in Marketing"
            },
            {
                "id": 2441,
                "itemName": "BComm in International Business"
            },
            {
                "id": 2442,
                "itemName": "BComm in Economics"
            },
            {
                "id": 2443,
                "itemName": "BComm in Accountancy"
            },
            {
                "id": 2444,
                "itemName": "Bachelor of Administration"
            },
            {
                "id": 2445,
                "itemName": "BA in Actuarial Mathematics/Finance"
            },
            {
                "id": 2446,
                "itemName": "BA in Communication and Cultural Studies"
            },
            {
                "id": 2447,
                "itemName": "BA in Child Studies"
            },
            {
                "id": 2448,
                "itemName": "BSc in Actuarial Mathematics/Finance"
            },
            {
                "id": 2449,
                "itemName": "BSc in Environmental Science"
            },
            {
                "id": 2450,
                "itemName": "BSc in Kinesiology and Clinical Exercise Physiology"
            },
            {
                "id": 2451,
                "itemName": "BComm in Management"
            },
            {
                "id": 2452,
                "itemName": "BComm in Supply Chain Operations Management"
            },
            {
                "id": 2453,
                "itemName": "BFA in Art History and Film Studies"
            },
            {
                "id": 2454,
                "itemName": "BFA in Electroacoustic Studies"
            },
            {
                "id": 2455,
                "itemName": "BFA in Film Animation"
            },
            {
                "id": 2456,
                "itemName": "BA in Community, Public Affairs and Policy Studies"
            },
            {
                "id": 2457,
                "itemName": "BA in Mathematical and Computational Finance"
            },
            {
                "id": 2458,
                "itemName": "BSc in Mathematical and Computational Finance"
            },
            {
                "id": 2459,
                "itemName": "BEng in Software Engineering"
            },
            {
                "id": 2460,
                "itemName": "BSc in Behavioural Neuroscience"
            },
            {
                "id": 2461,
                "itemName": "BA in Irish studies"
            },
            {
                "id": 2462,
                "itemName": "BA in First Peoples Studies"
            },
            {
                "id": 2463,
                "itemName": "Bachelor of Computer Science - Computer Applications with Computation Arts"
            },
            {
                "id": 2464,
                "itemName": "BFA in Acting for the Theatre"
            },
            {
                "id": 2465,
                "itemName": "BFA in Performance Creation"
            },
            {
                "id": 2466,
                "itemName": "BA in Translation"
            },
            {
                "id": 2467,
                "itemName": "BA in Interdisciplinary Studies in Sexuality"
            },
            {
                "id": 2468,
                "itemName": "BA in Recreation and Leisure Studies"
            },
            {
                "id": 2469,
                "itemName": "BFA in Electroacoustic Studies - specialization in Creative Practices"
            },
            {
                "id": 2470,
                "itemName": "BFA in Electroacoustic Studies - specialization in Recording Arts"
            },
            {
                "id": 2471,
                "itemName": "BA in Computer Science – Mathematics and Statistics"
            },
            {
                "id": 2472,
                "itemName": "BA in Études francaises (French Studies)"
            },
            {
                "id": 2473,
                "itemName": "BA in Liberal Arts"
            },
            {
                "id": 2474,
                "itemName": "BSc in Athletic Therapy"
            },
            {
                "id": 2475,
                "itemName": "BSc in Computer Science – Mathematics and Statistics"
            },
            {
                "id": 2476,
                "itemName": "Bachelor of Computer Science – Mathematics and Statistics"
            },
            {
                "id": 2477,
                "itemName": "Minor in Scientific Journalism"
            },
            {
                "id": 2478,
                "itemName": "BA en études françaises"
            },
            {
                "id": 2479,
                "itemName": "Licence en droit civil (L.LL.)"
            },
            {
                "id": 2480,
                "itemName": "B.A. spécialisé avec majeure en lettres françaises"
            },
            {
                "id": 2481,
                "itemName": "B.A. spécialisé en communication"
            },
            {
                "id": 2482,
                "itemName": "B.A. spécialisé avec majeure en anglais langue seconde (ESL)"
            },
            {
                "id": 2483,
                "itemName": "B.A. spécialisé avec majeure en lettres françaises et baccalauréat en éducation"
            },
            {
                "id": 2484,
                "itemName": "Honours BA in English"
            },
            {
                "id": 2485,
                "itemName": "B.A. spécialisé en théâtre"
            },
            {
                "id": 2486,
                "itemName": "B.A. spécialisé en musique"
            },
            {
                "id": 2487,
                "itemName": "B.A. spécialisé avec majeure en linguistique"
            },
            {
                "id": 2488,
                "itemName": "B.A. spécialisé avec majeure en théâtre"
            },
            {
                "id": 2489,
                "itemName": "B.A. spécialisé en sciences des religions"
            },
            {
                "id": 2490,
                "itemName": "Joint Honours BA in Communication and Political Science"
            },
            {
                "id": 2491,
                "itemName": "B.A. spécialisé avec majeure en communication"
            },
            {
                "id": 2492,
                "itemName": "B.A. spécialisé avec majeure en arts visuels"
            },
            {
                "id": 2493,
                "itemName": "B.A. spécialisé en lettres françaises"
            },
            {
                "id": 2494,
                "itemName": "Joint Honours BA in Communication and Sociology"
            },
            {
                "id": 2495,
                "itemName": "B.A. spécialisé avec majeure en études de l'environnement"
            },
            {
                "id": 2496,
                "itemName": "Honours BA in Communication"
            },
            {
                "id": 2497,
                "itemName": "B.A. spécialisé en études de l'environnement"
            },
            {
                "id": 2498,
                "itemName": "Honours BA with major in Communication"
            },
            {
                "id": 2499,
                "itemName": "Honours BA with major in English as a Second Language"
            },
            {
                "id": 2500,
                "itemName": "Honours BA with major in English"
            },
            {
                "id": 2501,
                "itemName": "Honours BA with major in Environmental Studies"
            },
            {
                "id": 2502,
                "itemName": "Honours BA in Environmental Studies"
            },
            {
                "id": 2503,
                "itemName": "B.A. spécialisé avec majeure en histoire"
            },
            {
                "id": 2504,
                "itemName": "B.A. spécialisé avec majeure en sciences des religions"
            },
            {
                "id": 2505,
                "itemName": "Baccalauréat en musique (B.Mus.)"
            },
            {
                "id": 2506,
                "itemName": "B.A. spécialisé avec majeure en français langue seconde (FLS)"
            },
            {
                "id": 2507,
                "itemName": "B.A. spécialisé avec majeure en géographie"
            },
            {
                "id": 2508,
                "itemName": "Baccalauréat en sciences commerciales (option en gestion des technologies d'affaires)"
            },
            {
                "id": 2509,
                "itemName": "Baccalauréat en sciences commerciales (option en comptabilité)"
            },
            {
                "id": 2510,
                "itemName": "Honours BSc in Human Kinetics (emphasis on biophysical sciences)"
            },
            {
                "id": 2511,
                "itemName": "Honours BA in Philosophy"
            },
            {
                "id": 2512,
                "itemName": "Joint Honours BA in Philosophy and  Political Science"
            },
            {
                "id": 2513,
                "itemName": "Honours BA with major in Music Studies"
            },
            {
                "id": 2514,
                "itemName": "Baccalauréat ès sciences en sciences infirmières"
            },
            {
                "id": 2515,
                "itemName": "BASc in Biomedical Mechanical Engineering"
            },
            {
                "id": 2516,
                "itemName": "Honours BA with major in Theatre"
            },
            {
                "id": 2517,
                "itemName": "Honours BA in French-English Translation (two years)"
            },
            {
                "id": 2518,
                "itemName": "B.Sc. spécialisé en géologie"
            },
            {
                "id": 2519,
                "itemName": "B.Sc. spécialisé avec majeure en géologie"
            },
            {
                "id": 2520,
                "itemName": "B.Sc. spécialisé en technologie médicale en ophtalmologie"
            },
            {
                "id": 2521,
                "itemName": "B.Sc. spécialisé avec majeure en biologie"
            },
            {
                "id": 2522,
                "itemName": "B.Sc. spécialisé en psychologie"
            },
            {
                "id": 2523,
                "itemName": "BASc in Electrical Engineering/BSc in Computing Technology"
            },
            {
                "id": 2524,
                "itemName": "B.Sc.Soc. spécialisé bidisciplinaire en science économique et en science politique"
            },
            {
                "id": 2525,
                "itemName": "B.Sc.Soc. spécialisé avec majeure en science économique"
            },
            {
                "id": 2526,
                "itemName": "B.Sc.Soc. spécialisé en science économique"
            },
            {
                "id": 2527,
                "itemName": "B.A. spécialisé en psychologie"
            },
            {
                "id": 2528,
                "itemName": "B.Sc.Soc. spécialisé avec majeure en sociologie"
            },
            {
                "id": 2529,
                "itemName": "Mineure en études des enjeux sociaux du sport, de l'activité physique et du loisir"
            },
            {
                "id": 2530,
                "itemName": "Minor in Computer Science for Scientists"
            },
            {
                "id": 2531,
                "itemName": "Mineure en sciences sociales de la santé"
            },
            {
                "id": 2532,
                "itemName": "B.Sc.Soc. spécialisé avec majeure en science politique"
            },
            {
                "id": 2533,
                "itemName": "Honours BA with major in Visual Arts"
            },
            {
                "id": 2534,
                "itemName": "B.Sc. spécialisé en sciences environnementales"
            },
            {
                "id": 2535,
                "itemName": "B.Sc.A. en génie chimique et B.Sc. en technologie de l'informatique"
            },
            {
                "id": 2536,
                "itemName": "B.Sc.A. en génie logiciel"
            },
            {
                "id": 2537,
                "itemName": "Honours BA with major in History"
            },
            {
                "id": 2538,
                "itemName": "Honours BA in Linguistics"
            },
            {
                "id": 2539,
                "itemName": "Joint Honours BA in Psychology and Linguistics"
            },
            {
                "id": 2540,
                "itemName": "B.Sc.A. en génie civil"
            },
            {
                "id": 2541,
                "itemName": "B.Sc.A. en génie chimique"
            },
            {
                "id": 2542,
                "itemName": "B.Sc.A. en génie mécanique"
            },
            {
                "id": 2543,
                "itemName": "B.Sc.A. en génie informatique"
            },
            {
                "id": 2544,
                "itemName": "B.Sc.A. en génie électrique"
            },
            {
                "id": 2545,
                "itemName": "B.Sc. spécialisé en informatique"
            },
            {
                "id": 2546,
                "itemName": "Teacher Education"
            },
            {
                "id": 2547,
                "itemName": "Minor in Translation into English"
            },
            {
                "id": 2548,
                "itemName": "B.Sc. spécialisé en biochimie"
            },
            {
                "id": 2549,
                "itemName": "B.Sc. spécialisé avec majeure en biochimie"
            },
            {
                "id": 2550,
                "itemName": "B.Sc. spécialisé avec majeure en chimie"
            },
            {
                "id": 2551,
                "itemName": "B.Sc. spécialisé en chimie"
            },
            {
                "id": 2552,
                "itemName": "B.Sc. spécialisé en biologie"
            },
            {
                "id": 2553,
                "itemName": "B.Sc. spécialisé en mathématiques financières et économie"
            },
            {
                "id": 2554,
                "itemName": "B.Sc. spécialisé avec majeure en mathématiques"
            },
            {
                "id": 2555,
                "itemName": "Honours in French-Spanish-English Translation (two years)"
            },
            {
                "id": 2556,
                "itemName": "B.Sc. spécialisé bidisciplinaire en mathématiques et science économique"
            },
            {
                "id": 2557,
                "itemName": "B.Sc. spécialisé en physique-mathématiques"
            },
            {
                "id": 2558,
                "itemName": "B.Sc. spécialisé en géologie-physique"
            },
            {
                "id": 2559,
                "itemName": "Honours BA with major in Second Language Teaching"
            },
            {
                "id": 2560,
                "itemName": "Licence en droit civil (L.LL.) et B.Sc.Soc. spécialisé en développement international et mondialisation"
            },
            {
                "id": 2561,
                "itemName": "B.Sc. spécialisé en sciences de l'activité physique (axé sur les sciences biophysiques)"
            },
            {
                "id": 2562,
                "itemName": "Programme d'études médicales du premier cycle (M.D.)"
            },
            {
                "id": 2563,
                "itemName": "B.A. spécialisé en enseignement des langues sécondes"
            },
            {
                "id": 2564,
                "itemName": "B.A. spécialisé avec majeure en enseignement des langues secondes"
            },
            {
                "id": 2565,
                "itemName": "Joint Honours BA in History and Political Science"
            },
            {
                "id": 2566,
                "itemName": "Honours Bachelor in Health Sciences"
            },
            {
                "id": 2567,
                "itemName": "Honours BA in History"
            },
            {
                "id": 2568,
                "itemName": "B.Sc. spécialisé bidisciplinaire en informatique et mathématiques"
            },
            {
                "id": 2569,
                "itemName": "B.Sc. spécialisé en biochimie (biotechnologie) / B.Sc.A. en génie chimique"
            },
            {
                "id": 2570,
                "itemName": "B.Sc.A. en génie mécanique et B.Sc. en technologie de l'informatique"
            },
            {
                "id": 2571,
                "itemName": "B.Sc.A. en génie mécanique biomédical"
            },
            {
                "id": 2572,
                "itemName": "Honours BA with major in Linguistics"
            },
            {
                "id": 2573,
                "itemName": "Honours BA in Music"
            },
            {
                "id": 2574,
                "itemName": "Honours BA in Spanish"
            },
            {
                "id": 2575,
                "itemName": "Honours BA in French-Spanish-English Translation"
            },
            {
                "id": 2576,
                "itemName": "BASc in Software Engineering"
            },
            {
                "id": 2577,
                "itemName": "B.Sc.A. en génie civil et B.Sc. en technologie de l'informatique"
            },
            {
                "id": 2578,
                "itemName": "B.A. spécialisé en linguistique"
            },
            {
                "id": 2579,
                "itemName": "B.Sc. spécialisé en physique"
            },
            {
                "id": 2580,
                "itemName": "B.Sc. spécialisé en majeure en physique"
            },
            {
                "id": 2581,
                "itemName": "B.Sc.Soc. spécialisé bidisciplinaire en études féministes et de genre et sciences politiques"
            },
            {
                "id": 2582,
                "itemName": "Mineure en études sur le vieillissement"
            },
            {
                "id": 2583,
                "itemName": "Programme conjoint LL.L. - M.B.A."
            },
            {
                "id": 2584,
                "itemName": "Juris Doctor (J.D.) - JD (Michigan State University, College of Law)"
            },
            {
                "id": 2585,
                "itemName": "Honours BA with major in Indigenous Studies"
            },
            {
                "id": 2586,
                "itemName": "Three-year General Bachelor of Arts"
            },
            {
                "id": 2587,
                "itemName": "Honours BA with major in History and Theory of Art"
            },
            {
                "id": 2588,
                "itemName": "Honours BA with major in Medieval and Renaissance Studies"
            },
            {
                "id": 2589,
                "itemName": "Honours BA in Theatre"
            },
            {
                "id": 2590,
                "itemName": "Honours BA in French-English Translation"
            },
            {
                "id": 2591,
                "itemName": "Minor in Studies of Social Issues in Sport, Physical Activity and Leisure"
            },
            {
                "id": 2592,
                "itemName": "Honours BSc in Environmental Science"
            },
            {
                "id": 2593,
                "itemName": "Honours BSc in Statistics"
            },
            {
                "id": 2594,
                "itemName": "Honours BSc in Ophthalmic Medical Technology"
            },
            {
                "id": 2595,
                "itemName": "Honours BSocSc with major in Anthropology"
            },
            {
                "id": 2596,
                "itemName": "Joint Honours BSocSc in Anthropology and Sociology"
            },
            {
                "id": 2597,
                "itemName": "Honours BSocSc in Criminology"
            },
            {
                "id": 2598,
                "itemName": "Minor in Aging Studies"
            },
            {
                "id": 2599,
                "itemName": "Minor in Global Studies"
            },
            {
                "id": 2600,
                "itemName": "Honours BSocSc in International Development and Globalization"
            },
            {
                "id": 2601,
                "itemName": "Honours BSocSc in International Studies and Modern Languages"
            },
            {
                "id": 2602,
                "itemName": "Joint Honours BSocSc in Criminology and Feminist and Gender Studies"
            },
            {
                "id": 2603,
                "itemName": "B.A. spécialisé avec majeure en histoire et théorie de l'art"
            },
            {
                "id": 2604,
                "itemName": "B.A. spécialisé bidisciplinaire en communication et lettres françaises"
            },
            {
                "id": 2605,
                "itemName": "B.A. spécialisé avec majeure en études autochtones"
            },
            {
                "id": 2606,
                "itemName": "B.A. spécialisé avec majeure en études médiévales et de la Renaissance"
            },
            {
                "id": 2607,
                "itemName": "B.A. spécialisé en traduction anglais-espagnol-français"
            },
            {
                "id": 2608,
                "itemName": "B.A. spécialisé en lettres françaises et Baccalauréat en éducation"
            },
            {
                "id": 2609,
                "itemName": "Mineure en informatique pour les sciences"
            },
            {
                "id": 2610,
                "itemName": "B.Sc.A. en génie électrique et B.Sc. en technologie de l'informatique"
            },
            {
                "id": 2611,
                "itemName": "B.Sc. spécialisé en statistique"
            },
            {
                "id": 2612,
                "itemName": "B.Sc. spécialisé avec majeure en statistique"
            },
            {
                "id": 2613,
                "itemName": "B.Sc. spécialisé en sciences biomédicales"
            },
            {
                "id": 2614,
                "itemName": "B.Sc. spécialisé en sciences biopharmaceutiques"
            },
            {
                "id": 2615,
                "itemName": "B.Sc.Soc. spécialisé bidisciplinaire en communication et science politique"
            },
            {
                "id": 2616,
                "itemName": "B.Sc.Soc. spécialisé bidisciplinaire en administration publique et science politique"
            },
            {
                "id": 2617,
                "itemName": "B.Sc.Soc. spécialisé avec majeure en administration publique"
            },
            {
                "id": 2618,
                "itemName": "B.Sc.Soc. spécialisé bidisciplinaire en histoire et science politique"
            },
            {
                "id": 2619,
                "itemName": "B.Sc.Soc. spécialisé avec majeure en anthropologie"
            },
            {
                "id": 2620,
                "itemName": "B.Sc.Soc. spécialisé bidisciplinaire en philosophie et science politique"
            },
            {
                "id": 2621,
                "itemName": "B.Sc.Soc. spécialisé en science politique et Juris Doctor (J.D.)"
            },
            {
                "id": 2622,
                "itemName": "B.Sc.Soc. spécialisé bidisciplinaire en criminologie et féministes et de genre"
            },
            {
                "id": 2623,
                "itemName": "B.Sc.Soc. spécialisé avec majeure en études féministes et de genre"
            },
            {
                "id": 2624,
                "itemName": "Mineure en études en mondialisation"
            },
            {
                "id": 2625,
                "itemName": "B.A. spécialisé bidisciplinaire en psychologie et linguistique"
            },
            {
                "id": 2626,
                "itemName": "B.Sc.Soc. spécialisé en science politique"
            },
            {
                "id": 2627,
                "itemName": "Honours BA with major in French as a Second Language"
            },
            {
                "id": 2628,
                "itemName": "Honours BA in Geography"
            },
            {
                "id": 2629,
                "itemName": "Honours BA with major in Geography"
            },
            {
                "id": 2630,
                "itemName": "Bachelor of Music (BMus)"
            },
            {
                "id": 2631,
                "itemName": "Honours BA with major in Philosophy"
            },
            {
                "id": 2632,
                "itemName": "Honours BA in Religious Studies"
            },
            {
                "id": 2633,
                "itemName": "Honours BA with major in Religious Studies"
            },
            {
                "id": 2634,
                "itemName": "Bachelor of Fine Arts (BFA)"
            },
            {
                "id": 2635,
                "itemName": "BASc in Chemical Engineering/BSc in Computing Technology"
            },
            {
                "id": 2636,
                "itemName": "Honours BSc in Biochemistry (biotechnology)/BASc in Chemical Engineering (biotechnology)"
            },
            {
                "id": 2637,
                "itemName": "Joint Honours BSc in Computer Science and Mathematics"
            },
            {
                "id": 2638,
                "itemName": "Honours BSc in Computer Science"
            },
            {
                "id": 2639,
                "itemName": "BASc in Mechanical Engineering/BSc in Computing Technology"
            },
            {
                "id": 2640,
                "itemName": "Juris Doctor (JD) - JD (American University, Washington College of Law)"
            },
            {
                "id": 2641,
                "itemName": "JD / MA / International Affairs"
            },
            {
                "id": 2642,
                "itemName": "JD/MBA program"
            },
            {
                "id": 2643,
                "itemName": "National Program - LLL-JD"
            },
            {
                "id": 2644,
                "itemName": "Undergraduate Medical Education"
            },
            {
                "id": 2645,
                "itemName": "Honours BSc in Biochemistry"
            },
            {
                "id": 2646,
                "itemName": "Honours BSc with major in Biochemistry"
            },
            {
                "id": 2647,
                "itemName": "Honours BSc in Biology"
            },
            {
                "id": 2648,
                "itemName": "Honours BSc with major in Biology"
            },
            {
                "id": 2649,
                "itemName": "Honours BSc in Biomedical Sciences"
            },
            {
                "id": 2650,
                "itemName": "Honours BSc in Biopharmaceutical Sciences"
            },
            {
                "id": 2651,
                "itemName": "Honours BSc in Chemistry"
            },
            {
                "id": 2652,
                "itemName": "Honours BSc with major in Chemistry"
            },
            {
                "id": 2653,
                "itemName": "Honours BSc in Geology"
            },
            {
                "id": 2654,
                "itemName": "Honours BSc in Geology-Physics"
            },
            {
                "id": 2655,
                "itemName": "Honours BSc with major in Geology"
            },
            {
                "id": 2656,
                "itemName": "Joint Honours BSc in Mathematics and Economics"
            },
            {
                "id": 2657,
                "itemName": "Honours BSc in Mathematics"
            },
            {
                "id": 2658,
                "itemName": "Honours BSc with major in Mathematics"
            },
            {
                "id": 2659,
                "itemName": "Honours BSc with major in Statistics"
            },
            {
                "id": 2660,
                "itemName": "Honours BSc in Physics"
            },
            {
                "id": 2661,
                "itemName": "Honours BSc in Physics-Mathematics"
            },
            {
                "id": 2662,
                "itemName": "Honours BSc with major in Physics"
            },
            {
                "id": 2663,
                "itemName": "Three-year General Bachelor of Science"
            },
            {
                "id": 2664,
                "itemName": "Honours BSocSc with major in Criminology"
            },
            {
                "id": 2665,
                "itemName": "Joint Honours BSocSc in Mathematics and Economics"
            },
            {
                "id": 2666,
                "itemName": "Joint Honours BSocSc in Economics and Political Science"
            },
            {
                "id": 2667,
                "itemName": "Honours BSocSc in Economics"
            },
            {
                "id": 2668,
                "itemName": "Honours BSocSc with major in Economics"
            },
            {
                "id": 2669,
                "itemName": "Joint Honours BSocSc in Communication and Political Science"
            },
            {
                "id": 2670,
                "itemName": "Joint Honours BSocSc in History and Political Science"
            },
            {
                "id": 2671,
                "itemName": "Joint Honours BSocSc in Philosophy and Political Science"
            },
            {
                "id": 2672,
                "itemName": "Joint Honours BSocSc in Political Science and Public Administration"
            },
            {
                "id": 2673,
                "itemName": "Joint Honours BSocSc in Feminist and Gender Studies and Political Science"
            },
            {
                "id": 2674,
                "itemName": "Honours BSocSc in Political Science"
            },
            {
                "id": 2675,
                "itemName": "Honours BSocSc with major in Political Science"
            },
            {
                "id": 2676,
                "itemName": "Honours BA in Psychology"
            },
            {
                "id": 2677,
                "itemName": "Honours BSc in Psychology"
            },
            {
                "id": 2678,
                "itemName": "Honours BSocSc with major in Psychology"
            },
            {
                "id": 2679,
                "itemName": "Honours BSocSc in Public Administration"
            },
            {
                "id": 2680,
                "itemName": "Honours BSocSc with major in Public Administration"
            },
            {
                "id": 2681,
                "itemName": "Three-year General Bachelor of Social Sciences"
            },
            {
                "id": 2682,
                "itemName": "Joint Honours BSocSc in Communications and Sociology"
            },
            {
                "id": 2683,
                "itemName": "Joint Honours BSocSc in Feminist and Gender Studies and Sociology"
            },
            {
                "id": 2684,
                "itemName": "Honours BSocSc in Sociology"
            },
            {
                "id": 2685,
                "itemName": "Honours BSocSc with major in Sociology"
            },
            {
                "id": 2686,
                "itemName": "Honours BSocSc with major in Feminist and Gender Studies"
            },
            {
                "id": 2687,
                "itemName": "Honours Bachelor in Commerce  (Accounting)"
            },
            {
                "id": 2688,
                "itemName": "Honours Bachelor in Commerce (Business Technology Management)"
            },
            {
                "id": 2689,
                "itemName": "Minor in Social Sciences of Health"
            },
            {
                "id": 2690,
                "itemName": "Formation à l'enseignement"
            },
            {
                "id": 2691,
                "itemName": "Programme national - J.D. - LL.L."
            },
            {
                "id": 2692,
                "itemName": "Programme conjoint de J.D. - M.B.A."
            },
            {
                "id": 2693,
                "itemName": "J.D. - M.A. Affaires internationales"
            },
            {
                "id": 2694,
                "itemName": "Programme national - LL.L.-J.D."
            },
            {
                "id": 2695,
                "itemName": "B.A. spécialisé bidisciplinaire en communication et science politique"
            },
            {
                "id": 2696,
                "itemName": "B.A. spécialisé bidisciplinaire en communication et sociologie"
            },
            {
                "id": 2697,
                "itemName": "B.A. spécialisé en géographie"
            },
            {
                "id": 2698,
                "itemName": "B.A. spécialisé en histoire"
            },
            {
                "id": 2699,
                "itemName": "B.A. spécialisé bidisciplinaire en histoire et science politique"
            },
            {
                "id": 2700,
                "itemName": "B.A. spécialisé avec majeure en philosophie"
            },
            {
                "id": 2701,
                "itemName": "B.A. spécialisé en philosophie"
            },
            {
                "id": 2702,
                "itemName": "B.A. spécialisé bidisciplinaire en philosophie et science politique"
            },
            {
                "id": 2703,
                "itemName": "B.A. spécialisé en traduction anglais-espagnol-français (2 ans)"
            },
            {
                "id": 2704,
                "itemName": "B.A. spécialisé en traduction anglais-français (2 ans)"
            },
            {
                "id": 2705,
                "itemName": "B.Sc. spécialisé en biochimie (biotechnologie) / B.Sc.A. en génie chimique (biotechnologie)"
            },
            {
                "id": 2706,
                "itemName": "Mineure en sciences de la vie"
            },
            {
                "id": 2707,
                "itemName": "Baccalauréat spécialisé en sciences des aliments et de la nutrition"
            },
            {
                "id": 2708,
                "itemName": "B.Sc.Soc. spécialisé en criminologie"
            },
            {
                "id": 2709,
                "itemName": "B.Sc.Soc. spécialisé bidisciplinaire en mathématique et science économique"
            },
            {
                "id": 2710,
                "itemName": "B.Sc.Soc. spécialisé en sociologie"
            },
            {
                "id": 2711,
                "itemName": "B.Sc.Soc. spécialisé bidisciplinaire en communication et sociologie"
            },
            {
                "id": 2712,
                "itemName": "B.Sc.Soc. spécialisé bidisciplinaire en féministes et de genre et sociologie"
            },
            {
                "id": 2713,
                "itemName": "B.Sc.Soc. spécialisé en administration publique"
            },
            {
                "id": 2714,
                "itemName": "B.Sc.Soc. spécialisé bidisciplinaire en anthropologie et sociologie"
            },
            {
                "id": 2715,
                "itemName": "B.Sc.Soc. spécialisé en développement international et mondialisation"
            },
            {
                "id": 2716,
                "itemName": "Licence en droit (Droit civil) et B.Sc.Soc. spécialisé en développement international et mondialisation"
            },
            {
                "id": 2717,
                "itemName": "B.Sc.Soc. spécialisé avec majeure en psychologie"
            },
            {
                "id": 2718,
                "itemName": "Baccalauréat ès sciences sociales de 3 ans"
            },
            {
                "id": 2719,
                "itemName": "B.Sc.Soc. spécialisé en service social"
            },
            {
                "id": 2720,
                "itemName": "B.Sc.Soc. spécialisé en études internationales et langues modernes"
            },
            {
                "id": 2721,
                "itemName": "Mineure en traduction vers le français"
            },
            {
                "id": 2722,
                "itemName": "Honours BSocSc in Conflict Studies and Human Rights"
            },
            {
                "id": 2723,
                "itemName": "Honours BSocSc in Economics and Public Policy"
            },
            {
                "id": 2724,
                "itemName": "Honours BSocSc in International Economics and Development"
            },
            {
                "id": 2725,
                "itemName": "Minor in Life Sciences"
            },
            {
                "id": 2726,
                "itemName": "Programme de droit canadien (J.D./LL.L.)"
            },
            {
                "id": 2727,
                "itemName": "Baccalauréat spécialisé en sciences de l'activité physique (axé sur les sciences sociales)"
            },
            {
                "id": 2728,
                "itemName": "Baccalauréat spécialisé en sciences de la santé"
            },
            {
                "id": 2729,
                "itemName": "Mineure en droit"
            },
            {
                "id": 2730,
                "itemName": "B.Sc.Soc. spécialisé en économie et politiques publiques"
            },
            {
                "id": 2731,
                "itemName": "B.Sc.Soc. spécialisé en économie internationale et développement"
            },
            {
                "id": 2732,
                "itemName": "B.Sc.Soc. spécialisé en études des conflits et droits humains"
            },
            {
                "id": 2733,
                "itemName": "Minor in Asian Studies"
            },
            {
                "id": 2734,
                "itemName": "Minor in Celtic Studies"
            },
            {
                "id": 2735,
                "itemName": "Honours BA in Second Language Teaching (ESL or FLS)"
            },
            {
                "id": 2736,
                "itemName": "Honours Bachelor in Human Kinetics (emphasis on social sciences)"
            },
            {
                "id": 2737,
                "itemName": "National Program - JD-LLL"
            },
            {
                "id": 2738,
                "itemName": "Baccalauréat en enseignement de l'anglais langue seconde"
            },
            {
                "id": 2739,
                "itemName": "Baccalauréat en adaptation scolaire et sociale"
            },
            {
                "id": 2740,
                "itemName": "Baccalauréat en enseignement au préscolaire et au primaire"
            },
            {
                "id": 2741,
                "itemName": "Baccalauréat en orientation"
            },
            {
                "id": 2742,
                "itemName": "Baccalauréat en service social"
            },
            {
                "id": 2743,
                "itemName": "Baccalauréat en génie chimique"
            },
            {
                "id": 2744,
                "itemName": "Baccalauréat en génie mécanique"
            },
            {
                "id": 2745,
                "itemName": "Baccalauréat en génie civil"
            },
            {
                "id": 2746,
                "itemName": "Baccalauréat en enseignement en éducation physique et à la santé"
            },
            {
                "id": 2747,
                "itemName": "Baccalauréat en administration des affaires avec cheminement spécialisé en management"
            },
            {
                "id": 2748,
                "itemName": "Baccalauréat en administration des affaires avec cheminement spécialisé en comptabilité"
            },
            {
                "id": 2749,
                "itemName": "Baccalauréat en administration des affaires avec cheminement spécialisé en finance"
            },
            {
                "id": 2750,
                "itemName": "Baccalauréat en administration des affaires avec cheminement spécialisé en marketing"
            },
            {
                "id": 2751,
                "itemName": "Baccalauréat en administration des affaires avec cheminement spécialisé en gestion des ressources humaines"
            },
            {
                "id": 2752,
                "itemName": "Baccalauréat en biochimie de la santé"
            },
            {
                "id": 2753,
                "itemName": "Baccalauréat en biologie"
            },
            {
                "id": 2754,
                "itemName": "Baccalauréat en informatique de gestion"
            },
            {
                "id": 2755,
                "itemName": "Baccalauréat en administration des affaires avec cheminement spécialisé en gestion des technologies d'affaires"
            },
            {
                "id": 2756,
                "itemName": "Baccalauréat en musique"
            },
            {
                "id": 2757,
                "itemName": "Baccalauréat en géomatique appliquée à l'environnement"
            },
            {
                "id": 2758,
                "itemName": "Baccalauréat en études politiques appliquées"
            },
            {
                "id": 2759,
                "itemName": "Baccalauréat en communication appliquée"
            },
            {
                "id": 2760,
                "itemName": "Baccalauréat en études littéraires et culturelles"
            },
            {
                "id": 2761,
                "itemName": "Baccalauréat en études anglaises et interculturelles"
            },
            {
                "id": 2762,
                "itemName": "Baccalauréat en génie informatique"
            },
            {
                "id": 2763,
                "itemName": "Baccalauréat en génie électrique"
            },
            {
                "id": 2764,
                "itemName": "Baccalauréat en biologie moléculaire et cellulaire"
            },
            {
                "id": 2765,
                "itemName": "Baccalauréat en chimie pharmaceutique"
            },
            {
                "id": 2766,
                "itemName": "Baccalauréat en écologie"
            },
            {
                "id": 2767,
                "itemName": "Baccalauréat en sciences du multimédia et du jeu vidéo"
            },
            {
                "id": 2768,
                "itemName": "Baccalauréat en microbiologie"
            },
            {
                "id": 2769,
                "itemName": "Baccalauréat en économique"
            },
            {
                "id": 2770,
                "itemName": "Baccalauréat en génie biotechnologique"
            },
            {
                "id": 2771,
                "itemName": "Baccalauréat en pharmacologie"
            },
            {
                "id": 2772,
                "itemName": "Baccalauréat en traduction professionnelle"
            },
            {
                "id": 2773,
                "itemName": "Baccalauréat en enseignement au secondaire"
            },
            {
                "id": 2774,
                "itemName": "Baccalauréat en études de l'environnement"
            },
            {
                "id": 2775,
                "itemName": "Baccalauréat en administration des affaires avec cheminement spécialisé en entrepreneuriat"
            },
            {
                "id": 2776,
                "itemName": "Baccalauréat en enseignement professionnel"
            },
            {
                "id": 2777,
                "itemName": "Baccalauréat en administration des affaires avec cheminement spécialisé en fiscalité"
            },
            {
                "id": 2778,
                "itemName": "Baccalauréat en génie du bâtiment"
            },
            {
                "id": 2779,
                "itemName": "Baccalauréat en génie robotique"
            },
            {
                "id": 2780,
                "itemName": "B.A. in English and intercultural studies"
            },
            {
                "id": 2781,
                "itemName": "BCom, Bachelor of Commerce"
            },
            {
                "id": 2782,
                "itemName": "BSW, Bachelor of Social Work"
            },
            {
                "id": 2783,
                "itemName": "Bachelor of Business Administration with specialization in International Business"
            },
            {
                "id": 2784,
                "itemName": "BBA, Bachelor of Business Administration with concentration in Organizational Studies"
            },
            {
                "id": 2785,
                "itemName": "BBA, Bachelor of Business Administration with concentration in Finance"
            },
            {
                "id": 2786,
                "itemName": "BBA, Bachelor of Business Administration with concentration in Economics"
            },
            {
                "id": 2787,
                "itemName": "BBA, Bachelor of Business Administration with concentration in Marketing"
            },
            {
                "id": 2788,
                "itemName": "BBA, Bachelor of Business Administration with concentration in Operations Management & Information Systems"
            },
            {
                "id": 2789,
                "itemName": "BBA, Bachelor of Business Administration with specialization in Accounting"
            },
            {
                "id": 2790,
                "itemName": "BCom, Bachelor of Commerce Specialized in Information Technology"
            },
            {
                "id": 2791,
                "itemName": "BA in Humanities"
            },
            {
                "id": 2792,
                "itemName": "BCom, Bachelor of Commerce Specialized in Marketing"
            },
            {
                "id": 2793,
                "itemName": "BCom, Bachelor of Commerce Specialized in Human Resources Management"
            },
            {
                "id": 2794,
                "itemName": "BCom, Bachelor of Commerce Specialized in Management"
            },
            {
                "id": 2795,
                "itemName": "BCom, Bachelor of Commerce Specialized in Accounting"
            },
            {
                "id": 2796,
                "itemName": "BA in Financial & Business Economics"
            },
            {
                "id": 2797,
                "itemName": "BA in Gender & Women's Studies"
            },
            {
                "id": 2798,
                "itemName": "BA in Interdisciplinary Social Science"
            },
            {
                "id": 2799,
                "itemName": "BScN, Bachelor of Science in Nursing"
            },
            {
                "id": 2800,
                "itemName": "BA in Cinema & Media Studies"
            },
            {
                "id": 2801,
                "itemName": "BSc in Kinesiology & Health Science"
            },
            {
                "id": 2802,
                "itemName": "BA en histoire"
            },
            {
                "id": 2803,
                "itemName": "BA en études linguistiques et langagières"
            },
            {
                "id": 2804,
                "itemName": "BA in Canadian Studies"
            },
            {
                "id": 2805,
                "itemName": "BA en philosophie"
            },
            {
                "id": 2806,
                "itemName": "BA in Drama and Creative Arts"
            },
            {
                "id": 2807,
                "itemName": "BA en études des femmes et de genre"
            },
            {
                "id": 2808,
                "itemName": "B.Ed., Baccalauréat en éducation (français langue seconde) au Campus Glendon"
            },
            {
                "id": 2809,
                "itemName": "BEng, Bachelor of Engineering (Undecided)"
            },
            {
                "id": 2810,
                "itemName": "BSc in Biotechnology"
            },
            {
                "id": 2811,
                "itemName": "BSc in Applied Mathematics"
            },
            {
                "id": 2812,
                "itemName": "BA en communications"
            },
            {
                "id": 2813,
                "itemName": "BA in Business Economics"
            },
            {
                "id": 2814,
                "itemName": "BA in Spanish (Hispanic Studies)"
            },
            {
                "id": 2815,
                "itemName": "BA en études canadiennes"
            },
            {
                "id": 2816,
                "itemName": "BA en économie des affaires"
            },
            {
                "id": 2817,
                "itemName": "B.Sc. en psychologie"
            },
            {
                "id": 2818,
                "itemName": "BA en espagnol (études hispaniques)"
            },
            {
                "id": 2819,
                "itemName": "BA en sociologie"
            },
            {
                "id": 2820,
                "itemName": "BA en mathématiques"
            },
            {
                "id": 2821,
                "itemName": "BA en psychologie"
            },
            {
                "id": 2822,
                "itemName": "BA en science politique"
            },
            {
                "id": 2823,
                "itemName": "BA en études internationales"
            },
            {
                "id": 2824,
                "itemName": "BA en études sur la sexualité"
            },
            {
                "id": 2825,
                "itemName": "JD, Juris Doctor"
            },
            {
                "id": 2826,
                "itemName": "BDes in Design"
            },
            {
                "id": 2827,
                "itemName": "BFA or BA in Music"
            },
            {
                "id": 2828,
                "itemName": "BFA or BA in Theatre"
            },
            {
                "id": 2829,
                "itemName": "BA in Kinesiology & Health Science"
            },
            {
                "id": 2830,
                "itemName": "BA in Business & Society"
            },
            {
                "id": 2831,
                "itemName": "Rehabilitation Services Certificate (York-Seneca)"
            },
            {
                "id": 2832,
                "itemName": "BEd, Bachelor of Education in Liberal Arts & Professional Studies"
            },
            {
                "id": 2833,
                "itemName": "BEd, Bachelor of Education in the Arts, Media, Performance & Design"
            },
            {
                "id": 2834,
                "itemName": "BA in Applied Mathematics"
            },
            {
                "id": 2835,
                "itemName": "BA in African Studies"
            },
            {
                "id": 2836,
                "itemName": "BA in Urban Studies"
            },
            {
                "id": 2837,
                "itemName": "BA in Mathematics for Commerce"
            },
            {
                "id": 2838,
                "itemName": "BA in Health & Society"
            },
            {
                "id": 2839,
                "itemName": "BA in Work & Labour Studies"
            },
            {
                "id": 2840,
                "itemName": "BA in Individualized Studies"
            },
            {
                "id": 2841,
                "itemName": "BA in Criminology"
            },
            {
                "id": 2842,
                "itemName": "JD/MES, Juris Doctor/Master in Environmental Studies"
            },
            {
                "id": 2843,
                "itemName": "JD/MBA, Juris Doctor/Master of Business Administration"
            },
            {
                "id": 2844,
                "itemName": "BSc Honours in Undecided Major"
            },
            {
                "id": 2845,
                "itemName": "BSc in Environmental Biology"
            },
            {
                "id": 2846,
                "itemName": "BHS, Bachelor of Health Studies in Health Informatics"
            },
            {
                "id": 2847,
                "itemName": "BHS, Bachelor of Health Studies in Health Management"
            },
            {
                "id": 2848,
                "itemName": "BHS, Bachelor of Health Studies in Health Policy"
            },
            {
                "id": 2849,
                "itemName": "BHRM, Bachelor of Human Resources Management"
            },
            {
                "id": 2850,
                "itemName": "BScN in Nursing Post-RN for Internationally Educated Nurses"
            },
            {
                "id": 2851,
                "itemName": "BA in Hellenic Studies"
            },
            {
                "id": 2852,
                "itemName": "BScN, Bachelor of Science in Nursing (Second-degree entry)"
            },
            {
                "id": 2853,
                "itemName": "BCom, Bachelor of Commerce Specialized in Finance"
            },
            {
                "id": 2854,
                "itemName": "BA in Cognitive Science"
            },
            {
                "id": 2855,
                "itemName": "BES, Bachelor in Environmental Studies w/International Development Diploma"
            },
            {
                "id": 2856,
                "itemName": "BA in International Studies"
            },
            {
                "id": 2857,
                "itemName": "BA in Linguistics & Language Studies"
            },
            {
                "id": 2858,
                "itemName": "BA in Law & Society"
            },
            {
                "id": 2859,
                "itemName": "BA in Professional Writing"
            },
            {
                "id": 2860,
                "itemName": "JD, Juris Doctor (Common Law) / Bachelor of Civil Law (BCL)"
            },
            {
                "id": 2861,
                "itemName": "BSc in Biomedical Science"
            },
            {
                "id": 2862,
                "itemName": "BSc in Biophysics"
            },
            {
                "id": 2863,
                "itemName": "BSc in Mathematics for Education"
            },
            {
                "id": 2864,
                "itemName": "BBA, Bachelor of Business Administration with specialization in Entrepreneurial & Family Business"
            },
            {
                "id": 2865,
                "itemName": "BA en économie"
            },
            {
                "id": 2866,
                "itemName": "BA in Children, Childhood and Youth"
            },
            {
                "id": 2867,
                "itemName": "BA in English & Professional Writing"
            },
            {
                "id": 2868,
                "itemName": "BA in Global Political Studies"
            },
            {
                "id": 2869,
                "itemName": "BA in International Development Studies"
            },
            {
                "id": 2870,
                "itemName": "iBBA, International Bachelor of Business Administration"
            },
            {
                "id": 2871,
                "itemName": "BA in Human Rights & Equity Studies"
            },
            {
                "id": 2872,
                "itemName": "BA in Portuguese & Luso-Brazilian Studies"
            },
            {
                "id": 2873,
                "itemName": "BA in Digital Media"
            },
            {
                "id": 2874,
                "itemName": "BCom, Bachelor of Commerce Specialized in Supply Chain Management"
            },
            {
                "id": 2875,
                "itemName": "BScN, Bachelor of Science in Nursing for Internationally Educated Nurses"
            },
            {
                "id": 2876,
                "itemName": "BA Honours minor in Business"
            },
            {
                "id": 2877,
                "itemName": "BA in Sexuality Studies"
            },
            {
                "id": 2878,
                "itemName": "BDEM, Bachelor of Disaster & Emergency Management"
            },
            {
                "id": 2879,
                "itemName": "BPA, Bachelor of Public Administration"
            },
            {
                "id": 2880,
                "itemName": "BA in Mathematics for Education"
            },
            {
                "id": 2881,
                "itemName": "BEd, Bachelor of Education (French as a Second Language) at Glendon Campus"
            },
            {
                "id": 2882,
                "itemName": "BEd, Bachelor of Education in Health"
            },
            {
                "id": 2883,
                "itemName": "BEd, Bachelor of Education at the Lassonde School of Engineering"
            },
            {
                "id": 2884,
                "itemName": "BEd, Bachelor of Education in Science"
            },
            {
                "id": 2885,
                "itemName": "BEd, Technological Education in Communication Technology"
            },
            {
                "id": 2886,
                "itemName": "BEd, Technological Education in Computer Technology"
            },
            {
                "id": 2887,
                "itemName": "BEd, Technological Education in Construction Technology"
            },
            {
                "id": 2888,
                "itemName": "BEd, Technological Education in Green Industries"
            },
            {
                "id": 2889,
                "itemName": "BEd, Technological Education in Health Care"
            },
            {
                "id": 2890,
                "itemName": "BEd, Technological Education in Hospitality and Tourism"
            },
            {
                "id": 2891,
                "itemName": "BEd, Technological Education in Manufacturing Technology"
            },
            {
                "id": 2892,
                "itemName": "BEd, Technological Education in Technological Design"
            },
            {
                "id": 2893,
                "itemName": "BEd, Technological Education in Transportation Technology"
            },
            {
                "id": 2894,
                "itemName": "BFA in Screenwriting"
            },
            {
                "id": 2895,
                "itemName": "BA in Global Health"
            },
            {
                "id": 2896,
                "itemName": "BHS, Bachelor of Health Studies in Health Studies"
            },
            {
                "id": 2897,
                "itemName": "BSc in Global Health"
            },
            {
                "id": 2898,
                "itemName": "BA in Information Technology"
            },
            {
                "id": 2899,
                "itemName": "BA in Undecided Major"
            },
            {
                "id": 2900,
                "itemName": "iBA, International Bachelor of Arts in Anthropology"
            },
            {
                "id": 2901,
                "itemName": "iBA, International Bachelor of Arts in Communication Studies"
            },
            {
                "id": 2902,
                "itemName": "iBA, International Bachelor of Arts in French Studies"
            },
            {
                "id": 2903,
                "itemName": "iBA, International Bachelor of Arts in Geography"
            },
            {
                "id": 2904,
                "itemName": "iBA, International Bachelor of Arts in German Studies"
            },
            {
                "id": 2905,
                "itemName": "iBA, International Bachelor of Arts in History"
            },
            {
                "id": 2906,
                "itemName": "iBA, International Bachelor of Arts in Humanities"
            },
            {
                "id": 2907,
                "itemName": "iBA, International Bachelor of Arts in Italian Studies"
            },
            {
                "id": 2908,
                "itemName": "iBA, International Bachelor of Arts in Political Science"
            },
            {
                "id": 2909,
                "itemName": "iBA, International Bachelor of Arts in Sexuality Studies"
            },
            {
                "id": 2910,
                "itemName": "iBA, International Bachelor of Arts in Urban Studies"
            },
            {
                "id": 2911,
                "itemName": "BSc in Physics & Astronomy"
            },
            {
                "id": 2912,
                "itemName": "iBSc in Biology"
            },
            {
                "id": 2913,
                "itemName": "BA in Computer Science"
            },
            {
                "id": 2914,
                "itemName": "BA in Computer Security"
            },
            {
                "id": 2915,
                "itemName": "BEng, Bachelor of Engineering in Civil Engineering"
            },
            {
                "id": 2916,
                "itemName": "BEng, Bachelor of Engineering in Computer Engineering"
            },
            {
                "id": 2917,
                "itemName": "BEng, Bachelor of Engineering in Electrical Engineering"
            },
            {
                "id": 2918,
                "itemName": "BEng, Bachelor of Engineering in Geomatics Engineering"
            },
            {
                "id": 2919,
                "itemName": "BEng, Bachelor of Engineering in Mechanical Engineering"
            },
            {
                "id": 2920,
                "itemName": "BEng, Bachelor of Engineering in Software Engineering"
            },
            {
                "id": 2921,
                "itemName": "BEng, Bachelor of Engineering in Space Engineering"
            },
            {
                "id": 2922,
                "itemName": "BEng/BA Dual Degree in Engineering & International Development Studies"
            },
            {
                "id": 2923,
                "itemName": "BSc in Earth & Atmospheric Science"
            },
            {
                "id": 2924,
                "itemName": "iBA, International Bachelor of Arts in Computer Science"
            },
            {
                "id": 2925,
                "itemName": "iBSc, International Bachelor of Science in Computer Science"
            },
            {
                "id": 2926,
                "itemName": "iBA, International Bachelor of Arts in Business Economics"
            },
            {
                "id": 2927,
                "itemName": "iBA, International Bachelor of Arts in Canadian Studies"
            },
            {
                "id": 2928,
                "itemName": "iBA, International Bachelor of Arts in Drama and Creative Arts"
            },
            {
                "id": 2929,
                "itemName": "iBA, International Bachelor of Arts in Economics"
            },
            {
                "id": 2930,
                "itemName": "iBA, International Bachelor of Arts in English"
            },
            {
                "id": 2931,
                "itemName": "iBA, International Bachelor of Arts in Gender & Women's Studies"
            },
            {
                "id": 2932,
                "itemName": "iBA, International Bachelor of Arts in International Studies"
            },
            {
                "id": 2933,
                "itemName": "iBA, International Bachelor of Arts in Linguistics & Language Studies"
            },
            {
                "id": 2934,
                "itemName": "iBA, International Bachelor of Arts in Mathematics"
            },
            {
                "id": 2935,
                "itemName": "iBA, International Bachelor of Arts in Philosophy"
            },
            {
                "id": 2936,
                "itemName": "iBA, International Bachelor of Arts in Psychology"
            },
            {
                "id": 2937,
                "itemName": "iBA, International Bachelor of Arts in Sociology"
            },
            {
                "id": 2938,
                "itemName": "iBA, International Bachelor of Arts in Spanish (Hispanic Studies)"
            },
            {
                "id": 2939,
                "itemName": "iBA, International Bachelor of Arts in Translation"
            },
            {
                "id": 2940,
                "itemName": "BBA, Bachelor of Business Administration with concentration in Responsible Business"
            },
            {
                "id": 2941,
                "itemName": "BBA, Bachelor of Business Administration with concentration in Strategic Management"
            },
            {
                "id": 2942,
                "itemName": "BEd, Bachelor of Education at Glendon Campus"
            },
            {
                "id": 2943,
                "itemName": "BA Computational Arts Honours Minor in Digital Media"
            },
            {
                "id": 2944,
                "itemName": "Accelerated BA in Translation"
            },
            {
                "id": 2945,
                "itemName": "BSc in Computer Science"
            },
            {
                "id": 2946,
                "itemName": "BSc in Computer Security"
            },
            {
                "id": 2947,
                "itemName": "BA en études anglaises"
            },
            {
                "id": 2948,
                "itemName": "BEd, Bachelor of Education in Environmental Studies"
            },
            {
                "id": 2949,
                "itemName": "BA, Bachelor of Educational Studies"
            },
            {
                "id": 2950,
                "itemName": "BEd, Technological Education in Hairstyling & Aesthetics"
            },
            {
                "id": 2951,
                "itemName": "BES, Bachelor in Environmental Studies, Specializing in Environmental Management & Technology w/Advanced Diploma in Civil Engineering Technology"
            },
            {
                "id": 2952,
                "itemName": "BSc in Mathematical Biology"
            },
            {
                "id": 2953,
                "itemName": "iBSc in Biomedical Science"
            },
            {
                "id": 2954,
                "itemName": "iBSc, International Bachelor of Science in Psychology"
            },
            {
                "id": 2955,
                "itemName": "BA spécialisé avec mineure en ressources humaines"
            },
            {
                "id": 2956,
                "itemName": "BA en théâtre et arts visuels"
            },
            {
                "id": 2957,
                "itemName": "BA avec majeure non déterminée"
            },
            {
                "id": 2958,
                "itemName": "BAi, Baccalauréat ès arts international en études françaises"
            },
            {
                "id": 2959,
                "itemName": "BAi, Baccalauréat ès arts international en théâtre et arts visuels"
            },
            {
                "id": 2960,
                "itemName": "BAi, Baccalauréat ès arts international en études canadiennes"
            },
            {
                "id": 2961,
                "itemName": "BAi, Baccalauréat ès arts international en économie et commerce"
            },
            {
                "id": 2962,
                "itemName": "BAi, Baccalauréat ès arts international en histoire"
            },
            {
                "id": 2963,
                "itemName": "BAi, Baccalauréat ès arts international en études anglaises"
            },
            {
                "id": 2964,
                "itemName": "BAi, Baccalauréat ès arts international en études internationales"
            },
            {
                "id": 2965,
                "itemName": "BAi, Baccalauréat ès arts international en mathématiques"
            },
            {
                "id": 2966,
                "itemName": "BAi, Baccalauréat ès arts international en études des femmes et de genre"
            },
            {
                "id": 2967,
                "itemName": "BAi, Baccalauréat ès arts international en philosophie"
            },
            {
                "id": 2968,
                "itemName": "BAi, Baccalauréat ès arts international en science politique"
            },
            {
                "id": 2969,
                "itemName": "BAi, Baccalauréat ès arts international en études sur la sexualité"
            },
            {
                "id": 2970,
                "itemName": "BAi, Baccalauréat ès arts international en études linguistiques et langagières"
            },
            {
                "id": 2971,
                "itemName": "BAi, Baccalauréat ès arts international en psychologie"
            },
            {
                "id": 2972,
                "itemName": "BAi, Baccalauréat ès arts international en sociologie"
            },
            {
                "id": 2973,
                "itemName": "BAi, Baccalauréat ès arts international en économie"
            },
            {
                "id": 2974,
                "itemName": "BAi, Baccalauréat ès arts international en communications"
            },
            {
                "id": 2975,
                "itemName": "BA accéléré en traduction"
            },
            {
                "id": 2976,
                "itemName": "BAi, Baccalauréat ès arts international en espagnol (études hispaniques)"
            },
            {
                "id": 2977,
                "itemName": "BA Honours minor in Human Resource Management"
            },
            {
                "id": 2978,
                "itemName": "BA in Communications"
            },
            {
                "id": 2979,
                "itemName": "iBA, International Bachelor of Arts in Communications"
            },
            {
                "id": 2980,
                "itemName": "iBSc, International Bachelor of Science in Biology"
            },
            {
                "id": 2981,
                "itemName": "BAi, Baccalauréat ès sciences en psychologie"
            },
            {
                "id": 2982,
                "itemName": "iB.Sc, Baccalauréat international ès sciences en biologie"
            },
            {
                "id": 2983,
                "itemName": "BCom, Bachelor of Commerce Specialized in Business Research"
            },
            {
                "id": 2984,
                "itemName": "BA in Actuarial Science"
            },
            {
                "id": 2985,
                "itemName": "BSc Honours in Integrated Science"
            },
            {
                "id": 2986,
                "itemName": "iBA-BBA, Dual Degree Program in International Studies & Business Administration"
            },
            {
                "id": 2987,
                "itemName": "BFA in Media Arts"
            },
            {
                "id": 2988,
                "itemName": "BFA in Dance"
            },
            {
                "id": 2989,
                "itemName": "International Dual Degree In Computer Science (BSc and Honours BSc)"
            },
            {
                "id": 2990,
                "itemName": "BA in Dance Studies"
            },
            {
                "id": 2991,
                "itemName": "York-Sheridan Dual Credential Degree-Diploma in Film and Media Arts"
            },
            {
                "id": 2992,
                "itemName": "B.Sc. en biologie"
            },
            {
                "id": 2993,
                "itemName": "Doctor of Veterinary Medicine DVM"
            },
            {
                "id": 2994,
                "itemName": "Doctor of Dental Medicine DMD"
            },
            {
                "id": 2995,
                "itemName": "Bachelor of Science in Nutrition BSc (Nutr)"
            },
            {
                "id": 2996,
                "itemName": "BE in civil engineering"
            },
            {
                "id": 2997,
                "itemName": "BE in chemical engineering"
            },
            {
                "id": 2998,
                "itemName": "BE in electrical engineering"
            },
            {
                "id": 2999,
                "itemName": "BE in mechanical engineering"
            },
            {
                "id": 3000,
                "itemName": "BE in geological engineering"
            },
            {
                "id": 3001,
                "itemName": "Bachelor of Science in Agriculture BSA in environmental science (4-yr)"
            },
            {
                "id": 3002,
                "itemName": "Bachelor of Science in Agriculture BSA in agricultural economics(4-year/honours)"
            },
            {
                "id": 3003,
                "itemName": "Bachelor of Science in Agriculture BSA in food and bioproduct sciences (4-yr)"
            },
            {
                "id": 3004,
                "itemName": "Bachelor of Science in Agriculture BSA in animal science (4-yr)"
            },
            {
                "id": 3005,
                "itemName": "Doctor of Medicine MD"
            },
            {
                "id": 3006,
                "itemName": "BEd with secondary option"
            },
            {
                "id": 3007,
                "itemName": "BEd with middle years option"
            },
            {
                "id": 3008,
                "itemName": "Bachelor of Commerce BComm in accounting"
            },
            {
                "id": 3009,
                "itemName": "BEd with early years option"
            },
            {
                "id": 3010,
                "itemName": "BEd with aboriginal teacher education option"
            },
            {
                "id": 3011,
                "itemName": "Bachelor of Science in Kinesiology BSc (Kin) (4-year/honours)"
            },
            {
                "id": 3012,
                "itemName": "Bachelor of Science in Nursing BSN"
            },
            {
                "id": 3013,
                "itemName": "JD Juris Doctor"
            },
            {
                "id": 3014,
                "itemName": "BComm in marketing"
            },
            {
                "id": 3015,
                "itemName": "BComm in operations management"
            },
            {
                "id": 3016,
                "itemName": "BComm in finance"
            },
            {
                "id": 3017,
                "itemName": "BComm in human resources"
            },
            {
                "id": 3018,
                "itemName": "BSc in food science (4-year major/honours)"
            },
            {
                "id": 3019,
                "itemName": "BA in business economics (4-year major/honours)"
            },
            {
                "id": 3020,
                "itemName": "BA in drama (4-year major/honours)"
            },
            {
                "id": 3021,
                "itemName": "BSc in archaeology (4-year major/honours)"
            },
            {
                "id": 3022,
                "itemName": "BA in women's and gender studies (3-year major)"
            },
            {
                "id": 3023,
                "itemName": "BSc in statistics (4-year major/honours)"
            },
            {
                "id": 3024,
                "itemName": "BSc in mathematics (3-year major)"
            },
            {
                "id": 3025,
                "itemName": "BSc in computer science (4-year major/honours)"
            },
            {
                "id": 3026,
                "itemName": "BSc in computer science (3-year major)"
            },
            {
                "id": 3027,
                "itemName": "BSc in chemistry (3-year major)"
            },
            {
                "id": 3028,
                "itemName": "BSc in biology (4-year major/honours)"
            },
            {
                "id": 3029,
                "itemName": "BA in english (4-year major/honours)"
            },
            {
                "id": 3030,
                "itemName": "BA in english (3-year major)"
            },
            {
                "id": 3031,
                "itemName": "BA in economics (4-year major/honours)"
            },
            {
                "id": 3032,
                "itemName": "BA in geography (3-year major)"
            },
            {
                "id": 3033,
                "itemName": "BA in french (4-year major/honours)"
            },
            {
                "id": 3034,
                "itemName": "BA in international studies (4-year major/honours)"
            },
            {
                "id": 3035,
                "itemName": "BA in history (4-year major/honours)"
            },
            {
                "id": 3036,
                "itemName": "BSc in geophysics (4-year major/honours)"
            },
            {
                "id": 3037,
                "itemName": "BA in music (4-year major/honours)"
            },
            {
                "id": 3038,
                "itemName": "BA in music (3-year major)"
            },
            {
                "id": 3039,
                "itemName": "BMus (Bachelor of Music)"
            },
            {
                "id": 3040,
                "itemName": "BA in psychology (3-year major)"
            },
            {
                "id": 3041,
                "itemName": "BA in political studies (4-year major/honours)"
            },
            {
                "id": 3042,
                "itemName": "BSc in physics (4-year major/honours)"
            },
            {
                "id": 3043,
                "itemName": "BA in philosophy (3-year major)"
            },
            {
                "id": 3044,
                "itemName": "BA in sociology (3-year major)"
            },
            {
                "id": 3045,
                "itemName": "BA in religion and culture (4-year major/honours)"
            },
            {
                "id": 3046,
                "itemName": "BA in geography (4-year major/honours)"
            },
            {
                "id": 3047,
                "itemName": "BSc in mathematics (4-year major/honours)"
            },
            {
                "id": 3048,
                "itemName": "BA in philosophy (4-year major/honours)"
            },
            {
                "id": 3049,
                "itemName": "BSc in chemistry (4-year major/honours)"
            },
            {
                "id": 3050,
                "itemName": "BA in history (3-year major)"
            },
            {
                "id": 3051,
                "itemName": "BA in french (3-year major)"
            },
            {
                "id": 3052,
                "itemName": "BA in economics (3-year major)"
            },
            {
                "id": 3053,
                "itemName": "BA in religion and culture (3-year major)"
            },
            {
                "id": 3054,
                "itemName": "BA in regional and urban planning development (4-year major/honours)"
            },
            {
                "id": 3055,
                "itemName": "BA in sociology (4-year major/honours)"
            },
            {
                "id": 3056,
                "itemName": "BSc in physics (3-year major)"
            },
            {
                "id": 3057,
                "itemName": "BA in psychology (4-year major/honours)"
            },
            {
                "id": 3058,
                "itemName": "BSc in biology (3-year major)"
            },
            {
                "id": 3059,
                "itemName": "BSc in biochemistry, microbiology and immunology (4-year major/honours)"
            },
            {
                "id": 3060,
                "itemName": "BFA in studio art (honours)"
            },
            {
                "id": 3061,
                "itemName": "BFA in drama"
            },
            {
                "id": 3062,
                "itemName": "BSc in Hydrology (4-year major/honours)"
            },
            {
                "id": 3063,
                "itemName": "BSc in mathematical physics (honours)"
            },
            {
                "id": 3064,
                "itemName": "BA in art (3-year major)"
            },
            {
                "id": 3065,
                "itemName": "BA in art (4-year major/honours)"
            },
            {
                "id": 3066,
                "itemName": "BA in classical, mediaeval and renaissance studies (4-year major/honours)"
            },
            {
                "id": 3067,
                "itemName": "BA in linguistics (3-year major)"
            },
            {
                "id": 3068,
                "itemName": "BA in linguistics (4-year major/honours)"
            },
            {
                "id": 3069,
                "itemName": "BA in political studies (3-year major)"
            },
            {
                "id": 3070,
                "itemName": "BA in drama (3-year major)"
            },
            {
                "id": 3071,
                "itemName": "BA in women's and gender studies (4-year major/honours)"
            },
            {
                "id": 3072,
                "itemName": "BSc in bioinformatics (4-year major/honours)"
            },
            {
                "id": 3073,
                "itemName": "BSc in paleobiology (4-year major/honours)"
            },
            {
                "id": 3074,
                "itemName": "BSc in toxicology (4-year major/honours)"
            },
            {
                "id": 3075,
                "itemName": "BA in aboriginal public administration (4-year major/honours)"
            },
            {
                "id": 3076,
                "itemName": "Bachelor of Science in Agriculture BSA in agricultural biology (4-year/honours)"
            },
            {
                "id": 3077,
                "itemName": "BE in engineering physics"
            },
            {
                "id": 3078,
                "itemName": "Bachelor of Science in Agribusiness BSc (Agbus) (3-yr/4-yr/honours)"
            },
            {
                "id": 3079,
                "itemName": "Bachelor of Science in Agriculture BSA in crop science (4-yr)"
            },
            {
                "id": 3080,
                "itemName": "BComm in management"
            },
            {
                "id": 3081,
                "itemName": "BE in computer engineering"
            },
            {
                "id": 3082,
                "itemName": "BSc (Kin)/BEd Combined Program"
            },
            {
                "id": 3083,
                "itemName": "Bachelor of Science in Renewable Resource Management BSc (RRM) in resource science (4-yr)"
            },
            {
                "id": 3084,
                "itemName": "BE in environmental engineering"
            },
            {
                "id": 3085,
                "itemName": "Bachelor of Science in Agriculture BSA in soil science (4-yr)"
            },
            {
                "id": 3086,
                "itemName": "Bachelor of Science in Agriculture BSA in applied plant ecology (4-yr)"
            },
            {
                "id": 3087,
                "itemName": "Bachelor of Science in Agriculture BSA in agronomy (4-yr)"
            },
            {
                "id": 3088,
                "itemName": "Bachelor of Science in Agriculture BSA in horticulture science (4-yr)"
            },
            {
                "id": 3089,
                "itemName": "Bachelor of Science in Renewable Resource Management BSc (RRM) in resource economics and policy (4-yr)"
            },
            {
                "id": 3090,
                "itemName": "BA in sociology with focus on aboriginal justice and criminology (3-year major)"
            },
            {
                "id": 3091,
                "itemName": "BA in sociology with focus on aboriginal justice and criminology (4-year major/honours)"
            },
            {
                "id": 3092,
                "itemName": "BA in art (4-year major)"
            },
            {
                "id": 3093,
                "itemName": "BA in modern languages (3-year major)"
            },
            {
                "id": 3094,
                "itemName": "BA in modern languages (4-year major)"
            },
            {
                "id": 3095,
                "itemName": "BA & BSc (Bachelor of Arts and Science) in environment and society (4-year major/honours)"
            },
            {
                "id": 3096,
                "itemName": "BA & BSc in interactive systems design (4-year major/honours)"
            },
            {
                "id": 3097,
                "itemName": "BSc in environmental biology (4-year major/honours)"
            },
            {
                "id": 3098,
                "itemName": "BSc in geology (3-year major)"
            },
            {
                "id": 3099,
                "itemName": "BSc in geology (4-year major/honours)"
            },
            {
                "id": 3100,
                "itemName": "Engineering Professional Internship Program"
            },
            {
                "id": 3101,
                "itemName": "BSc in psychology (4-year major/honours)"
            },
            {
                "id": 3102,
                "itemName": "BMus (MusEd) in Music Education"
            },
            {
                "id": 3103,
                "itemName": "Bachelor of Science in Animal Bioscience BSc (AnBiosc) (4-yr)"
            },
            {
                "id": 3104,
                "itemName": "BSc in applied mathematics (3-year major)"
            },
            {
                "id": 3105,
                "itemName": "BSc in applied mathematics (4-year major/honours)"
            },
            {
                "id": 3106,
                "itemName": "BA & BSc in health studies (4-year major/honours)"
            },
            {
                "id": 3107,
                "itemName": "Post-Degree BSN"
            },
            {
                "id": 3108,
                "itemName": "BA in indigenous studies (3 year major)"
            },
            {
                "id": 3109,
                "itemName": "BA in indigenous studies (3 year major/honours)"
            },
            {
                "id": 3110,
                "itemName": "Combined Juris doctor JD and Master of Business Administration MBA"
            },
            {
                "id": 3111,
                "itemName": "BA in Archaeology and Anthropology (3-yr)"
            },
            {
                "id": 3112,
                "itemName": "BA in Archaeology and Anthropology (4-year major/honours)"
            },
            {
                "id": 3113,
                "itemName": "Bachelor of Science in Dentistry BSc (Dent)"
            },
            {
                "id": 3114,
                "itemName": "BEd with early/middle years option"
            },
            {
                "id": 3115,
                "itemName": "Bsc in Biomedical Foundations (3yr)"
            },
            {
                "id": 3116,
                "itemName": "BSc Neurosicence (4yr/honours)"
            },
            {
                "id": 3117,
                "itemName": "BSc Cellular, physiological and pharmacological sciences (4yr/honours)"
            },
            {
                "id": 3118,
                "itemName": "BSc in Environmental Geosicence (4yr/honours)"
            },
            {
                "id": 3119,
                "itemName": "Engineering Co-operative Internship Program"
            },
            {
                "id": 3120,
                "itemName": "BA in folklore"
            },
            {
                "id": 3121,
                "itemName": "BA in pure mathematics"
            },
            {
                "id": 3122,
                "itemName": "BA in computer science"
            },
            {
                "id": 3123,
                "itemName": "BA in medieval studies (interdisciplinary)"
            },
            {
                "id": 3124,
                "itemName": "Bachelor of special education"
            },
            {
                "id": 3125,
                "itemName": "Bachelor of music/ Bachelor of music education"
            },
            {
                "id": 3126,
                "itemName": "Bachelor of education (intermediate/secondary)"
            },
            {
                "id": 3127,
                "itemName": "BA in social/cultural studies (Grenfell Campus)"
            },
            {
                "id": 3128,
                "itemName": "BSc in environmental science (Grenfell Campus)"
            },
            {
                "id": 3129,
                "itemName": "Bachelor of Fine Arts in visual arts (Grenfell Campus)"
            },
            {
                "id": 3130,
                "itemName": "Bachelor of Fine Arts in theatre (Grenfell Campus)"
            },
            {
                "id": 3131,
                "itemName": "BA in psychology (Grenfell Campus)"
            },
            {
                "id": 3132,
                "itemName": "BA in English (Grenfell Campus)"
            },
            {
                "id": 3133,
                "itemName": "Bachelor of Technology"
            },
            {
                "id": 3134,
                "itemName": "BSc in earth sciences"
            },
            {
                "id": 3135,
                "itemName": "BSc in pure mathematics"
            },
            {
                "id": 3136,
                "itemName": "Bachelor of Maritime Studies"
            },
            {
                "id": 3137,
                "itemName": "BSc in statistics"
            },
            {
                "id": 3138,
                "itemName": "BSc in applied mathematics"
            },
            {
                "id": 3139,
                "itemName": "Doctor of pharmacy"
            },
            {
                "id": 3140,
                "itemName": "Bachelor of Nursing (Collaborative) Program"
            },
            {
                "id": 3141,
                "itemName": "Bachelor of music/Bachelor of music education"
            },
            {
                "id": 3142,
                "itemName": "Bachelor of engineering - ocean and naval architecture engineering major"
            },
            {
                "id": 3143,
                "itemName": "Bachelor of engineering - electrical engineering major"
            },
            {
                "id": 3144,
                "itemName": "Bachelor of engineering - civil engineering major"
            },
            {
                "id": 3145,
                "itemName": "Bachelor of engineering - mechanical engineering major"
            },
            {
                "id": 3146,
                "itemName": "Bachelor of Education (primary/elementary)"
            },
            {
                "id": 3147,
                "itemName": "Bachelor of music education"
            },
            {
                "id": 3148,
                "itemName": "Bachelor of commerce (co-op)/Bachelor of arts"
            },
            {
                "id": 3149,
                "itemName": "Bachelor of business administration"
            },
            {
                "id": 3150,
                "itemName": "BSc in computer science (software engineering) option"
            },
            {
                "id": 3151,
                "itemName": "Bachelor of Education (post-secondary)"
            },
            {
                "id": 3152,
                "itemName": "MD-PhD"
            },
            {
                "id": 3153,
                "itemName": "Bachelor of Nursing (Collaborative) Program - Accelerated option"
            },
            {
                "id": 3154,
                "itemName": "BA in historical studies (Grenfell Campus)"
            },
            {
                "id": 3155,
                "itemName": "BSc in general science (Grenfell Campus)"
            },
            {
                "id": 3156,
                "itemName": "Bachelor of engineering - computer engineering major"
            },
            {
                "id": 3157,
                "itemName": "Bachelor of Education (Intermediate/Secondary) Conjoint with Diploma in Technology Education"
            },
            {
                "id": 3158,
                "itemName": "BSc in economics"
            },
            {
                "id": 3159,
                "itemName": "BSc in Environmental Physics"
            },
            {
                "id": 3160,
                "itemName": "Bachelor of kinesiology"
            },
            {
                "id": 3161,
                "itemName": "Bachelor of Physical Education"
            },
            {
                "id": 3162,
                "itemName": "Bachelor of Recreation"
            },
            {
                "id": 3163,
                "itemName": "International Bachelor of Business Administration (iBBA)"
            },
            {
                "id": 3164,
                "itemName": "BA in statistics"
            },
            {
                "id": 3165,
                "itemName": "BSc in biochemistry (Nutrition)"
            },
            {
                "id": 3166,
                "itemName": "BSc in behavioural neuroscience"
            },
            {
                "id": 3167,
                "itemName": "Bachelor of Business Administration (Grenfell Campus)"
            },
            {
                "id": 3168,
                "itemName": "BSc in psychology (Grenfell Campus)"
            },
            {
                "id": 3169,
                "itemName": "Bachelor of engineering - process engineering major"
            },
            {
                "id": 3170,
                "itemName": "PhD in archaeology"
            },
            {
                "id": 3171,
                "itemName": "BA in communications studies (interdisciplinary)"
            },
            {
                "id": 3172,
                "itemName": "BA in police studies (interdisciplinary)"
            },
            {
                "id": 3173,
                "itemName": "BA in law and society (interdisciplinary)"
            },
            {
                "id": 3174,
                "itemName": "BA in gender studies"
            },
            {
                "id": 3175,
                "itemName": "Bachelor of social work - second degree option"
            },
            {
                "id": 3176,
                "itemName": "Bachelor of Nursing (Western Regional School of Nursing/Grenfell Campus)"
            },
            {
                "id": 3177,
                "itemName": "BSc Joint Major in Marine Biology"
            },
            {
                "id": 3178,
                "itemName": "BSc in computational chemistry"
            },
            {
                "id": 3179,
                "itemName": "BSc in physics (Grenfell Campus)"
            },
            {
                "id": 3180,
                "itemName": "Bachelor of music (joint major in performance and musicologies)"
            },
            {
                "id": 3181,
                "itemName": "Bachelor of music (major in music performance with a minor in composition)"
            },
            {
                "id": 3182,
                "itemName": "BSc in computational mathematics (Grenfell Campus)"
            },
            {
                "id": 3183,
                "itemName": "Bachelor of commerce (co-op)"
            },
            {
                "id": 3184,
                "itemName": "International Bachelor of Arts"
            },
            {
                "id": 3185,
                "itemName": "Joint Bachelor of Arts/Bachelor of Science"
            },
            {
                "id": 3186,
                "itemName": "BSc in chemistry (biological)"
            },
            {
                "id": 3187,
                "itemName": "BSc in computer science (visual computing and games)"
            },
            {
                "id": 3188,
                "itemName": "BSc in computer science (smart systems)"
            },
            {
                "id": 3189,
                "itemName": "BSc in ocean sciences"
            },
            {
                "id": 3190,
                "itemName": "BSc in ocean sciences (environmental systems)"
            },
            {
                "id": 3191,
                "itemName": "Bachelor of Education (Primary/Elementary) Conjoint with Certificate in STEM Education"
            },
            {
                "id": 3192,
                "itemName": "Bachelor of Environment and Sustainability (Grenfell Campus)"
            },
            {
                "id": 3193,
                "itemName": "Bachelor of human kinetics and recreation (co-op)"
            },
            {
                "id": 3194,
                "itemName": "Doctor of pharmacy (PharmD) for Working Professionals"
            },
            {
                "id": 3195,
                "itemName": "Bachelor of business administration/Bachelor of music"
            },
            {
                "id": 3196,
                "itemName": "Joint Bachelor of Arts and Bachelor of Commerce (Co-operative)"
            },
            {
                "id": 3197,
                "itemName": "BSc in ocean physics"
            },
            {
                "id": 3198,
                "itemName": "Joint Degrees of Bachelor of Music and Bachelor of Business Administration"
            },
            {
                "id": 3199,
                "itemName": "Bachelor of music (major in composition with minor in jazz studies)"
            },
            {
                "id": 3200,
                "itemName": "Bachelor of music (major in general musical studies with minor in jazz studies)"
            },
            {
                "id": 3201,
                "itemName": "Bachelor of music (major in performance with a minor in jazz studies)"
            },
            {
                "id": 3202,
                "itemName": "BSc in Earth Sciences (honours)"
            },
            {
                "id": 3203,
                "itemName": "BSc in Earth Sciences (general)"
            },
            {
                "id": 3204,
                "itemName": "BSc in Chemistry (honours)"
            },
            {
                "id": 3205,
                "itemName": "BSc in Chemistry (general)"
            },
            {
                "id": 3206,
                "itemName": "BSc in Biotechnology (honours)"
            },
            {
                "id": 3207,
                "itemName": "BSc in Biochemistry (honours)"
            },
            {
                "id": 3208,
                "itemName": "BSc in Biochemistry (major)"
            },
            {
                "id": 3209,
                "itemName": "BSc in Biology (general)"
            },
            {
                "id": 3210,
                "itemName": "BA in Environmental Studies (honours)"
            },
            {
                "id": 3211,
                "itemName": "BMath in Computational and Applied Mathematics and Statistics (honours)"
            },
            {
                "id": 3212,
                "itemName": "BSc in Computational Biochemistry (honours)"
            },
            {
                "id": 3213,
                "itemName": "BSc in Psychology (honours)"
            },
            {
                "id": 3214,
                "itemName": "Bachelor of Journalism (honours)"
            },
            {
                "id": 3215,
                "itemName": "Bachelor in Communication and Media Studies (honours)"
            },
            {
                "id": 3216,
                "itemName": "Bachelor of Computer Science (honours)"
            },
            {
                "id": 3217,
                "itemName": "Bachelor of Social Work (honours)"
            },
            {
                "id": 3218,
                "itemName": "BSc in Biology (honours)"
            },
            {
                "id": 3219,
                "itemName": "BSc in Mathematics and Physics (double honours)"
            },
            {
                "id": 3220,
                "itemName": "Bachelor of Public Affairs and Policy Management (honours)"
            },
            {
                "id": 3221,
                "itemName": "BA in History and Theory of Architecture (honours)"
            },
            {
                "id": 3222,
                "itemName": "BA in Music (general)"
            },
            {
                "id": 3223,
                "itemName": "Bachelor of Music (honours)"
            },
            {
                "id": 3224,
                "itemName": "BA in Film Studies (general)"
            },
            {
                "id": 3225,
                "itemName": "BA in Philosophy (honours)"
            },
            {
                "id": 3226,
                "itemName": "BA in Women's and Gender Studies (honours)"
            },
            {
                "id": 3227,
                "itemName": "Bachelor in Economics (honours)"
            },
            {
                "id": 3228,
                "itemName": "BA in Psychology (honours)"
            },
            {
                "id": 3229,
                "itemName": "BA in Criminology and Criminal Justice (honours)"
            },
            {
                "id": 3230,
                "itemName": "Bachelor of Cognitive Science (general)"
            },
            {
                "id": 3231,
                "itemName": "BA in Geography (honours)"
            },
            {
                "id": 3232,
                "itemName": "BA in English (honours)"
            },
            {
                "id": 3233,
                "itemName": "BA in Women's and Gender Studies (general)"
            },
            {
                "id": 3234,
                "itemName": "BA in Psychology (general)"
            },
            {
                "id": 3235,
                "itemName": "BA in Philosophy (general)"
            },
            {
                "id": 3236,
                "itemName": "BA in Linguistics (honours)"
            },
            {
                "id": 3237,
                "itemName": "BA in Human Rights and Social Justice (honours)"
            },
            {
                "id": 3238,
                "itemName": "BA in Sociology (honours)"
            },
            {
                "id": 3239,
                "itemName": "BA in French (honours)"
            },
            {
                "id": 3240,
                "itemName": "BA in Geography (general)"
            },
            {
                "id": 3241,
                "itemName": "BA in Biology (general)"
            },
            {
                "id": 3242,
                "itemName": "BA in History (honours)"
            },
            {
                "id": 3243,
                "itemName": "BA in Linguistics (general)"
            },
            {
                "id": 3244,
                "itemName": "BA in Political Science (honours)"
            },
            {
                "id": 3245,
                "itemName": "BA in English (general)"
            },
            {
                "id": 3246,
                "itemName": "BA in Biology (honours)"
            },
            {
                "id": 3247,
                "itemName": "BA in Political Science (general)"
            },
            {
                "id": 3248,
                "itemName": "BA in History (general)"
            },
            {
                "id": 3249,
                "itemName": "BA in French (general)"
            },
            {
                "id": 3250,
                "itemName": "BA in Geomatics (honours)"
            },
            {
                "id": 3251,
                "itemName": "BA in Film Studies (honours)"
            },
            {
                "id": 3252,
                "itemName": "BA in Music (honours)"
            },
            {
                "id": 3253,
                "itemName": "Bachelor of Commerce with Concentration in Accounting (honours)"
            },
            {
                "id": 3254,
                "itemName": "Bachelor of Commerce with Concentration in Information Systems (honours)"
            },
            {
                "id": 3255,
                "itemName": "BA in Canadian studies (honours)"
            },
            {
                "id": 3256,
                "itemName": "Bachelor of Commerce with Concentration in Marketing (honours)"
            },
            {
                "id": 3257,
                "itemName": "Bachelor of Commerce with Concentration in International Business (honours)"
            },
            {
                "id": 3258,
                "itemName": "Bachelor of Commerce with Concentration in Finance (honours)"
            },
            {
                "id": 3259,
                "itemName": "Bachelor of International Business (honours)"
            },
            {
                "id": 3260,
                "itemName": "BA in Applied Linguistics and Discourse Studies (general)"
            },
            {
                "id": 3261,
                "itemName": "BA in Applied Linguistics and Discourse Studies (honours)"
            },
            {
                "id": 3262,
                "itemName": "BA in Childhood and Youth Studies (general)"
            },
            {
                "id": 3263,
                "itemName": "BA in Childhood and Youth Studies (honours)"
            },
            {
                "id": 3264,
                "itemName": "BA in Greek and Roman Studies (general)"
            },
            {
                "id": 3265,
                "itemName": "BA in Greek and Roman Studies (honours)"
            },
            {
                "id": 3266,
                "itemName": "BA in Criminology and Criminal Justice (general)"
            },
            {
                "id": 3267,
                "itemName": "BA in Environmental Studies (general)"
            },
            {
                "id": 3268,
                "itemName": "BA in European and Russian studies (honours)"
            },
            {
                "id": 3269,
                "itemName": "BA in Law (honours)"
            },
            {
                "id": 3270,
                "itemName": "Bachelor of Commerce (honours)"
            },
            {
                "id": 3271,
                "itemName": "Bachelor of Mathematics (general)"
            },
            {
                "id": 3272,
                "itemName": "Bachelor of Mathematics (honours)"
            },
            {
                "id": 3273,
                "itemName": "Bachelor of Mathematics in Computer Mathematics (general)"
            },
            {
                "id": 3274,
                "itemName": "Bachelor of Mathematics in Statistics (general)"
            },
            {
                "id": 3275,
                "itemName": "Bachelor of Mathematics in Statistics (honours)"
            },
            {
                "id": 3276,
                "itemName": "BSc in Neuroscience (combined honours)"
            },
            {
                "id": 3277,
                "itemName": "BA in Canadian studies (general)"
            },
            {
                "id": 3278,
                "itemName": "BSc in Environmental Science (honours)"
            },
            {
                "id": 3279,
                "itemName": "Bachelor of Mathematics in Computer Science and Mathematics (honours)"
            },
            {
                "id": 3280,
                "itemName": "BA in Anthropology (honours)"
            },
            {
                "id": 3281,
                "itemName": "BA in History and Theory of Architecture (general)"
            },
            {
                "id": 3282,
                "itemName": "BA in Sociology (general)"
            },
            {
                "id": 3283,
                "itemName": "Bachelor of Commerce with Concentration in Management (honours)"
            },
            {
                "id": 3284,
                "itemName": "Bachelor of Commerce with Concentration in Supply Chain Management (honours)"
            },
            {
                "id": 3285,
                "itemName": "BSc in Physics (honours)"
            },
            {
                "id": 3286,
                "itemName": "BSc in Physics (major)"
            },
            {
                "id": 3287,
                "itemName": "Bachelor of Mathematics in Mathematics (fast track to M.Sc.)"
            },
            {
                "id": 3288,
                "itemName": "Bachelor of Mathematics in Statistics (fast track to M.Sc.)"
            },
            {
                "id": 3289,
                "itemName": "BA in Religion (honours)"
            },
            {
                "id": 3290,
                "itemName": "BA in Religion (general)"
            },
            {
                "id": 3291,
                "itemName": "Minor in Sexuality Studies"
            },
            {
                "id": 3292,
                "itemName": "Minor in Technology, Society, Environment Studies"
            },
            {
                "id": 3293,
                "itemName": "BA in Anthropology (general)"
            },
            {
                "id": 3294,
                "itemName": "BA in Art History (general)"
            },
            {
                "id": 3295,
                "itemName": "BA in Art History (honours)"
            },
            {
                "id": 3296,
                "itemName": "BMath in Mathematics & Economics (combined honours)"
            },
            {
                "id": 3297,
                "itemName": "BMath in Statistics & Economics (combined honours)"
            },
            {
                "id": 3298,
                "itemName": "BSc in Earth Sciences & Physical Geography (combined honours)"
            },
            {
                "id": 3299,
                "itemName": "BSc in Bioinformatics (honours)"
            },
            {
                "id": 3300,
                "itemName": "BSc in Biology & Earth Sciences (combined honours)"
            },
            {
                "id": 3301,
                "itemName": "BSc in Biology & Physics (combined honours)"
            },
            {
                "id": 3302,
                "itemName": "BSc in Chemistry & Earth Sciences"
            },
            {
                "id": 3303,
                "itemName": "BSc in Chemistry & Physics (combined honours)"
            },
            {
                "id": 3304,
                "itemName": "BSc in Food Science (honours)"
            },
            {
                "id": 3305,
                "itemName": "Bachelor of Computer Science (major)"
            },
            {
                "id": 3306,
                "itemName": "BA in Human Rights and Social Justice (general)"
            },
            {
                "id": 3307,
                "itemName": "Minor in Industrial Economics"
            },
            {
                "id": 3308,
                "itemName": "Minor in Modern Languages"
            },
            {
                "id": 3309,
                "itemName": "Minor in Islamic Studies"
            },
            {
                "id": 3310,
                "itemName": "BA in African Studies (combined honours)"
            },
            {
                "id": 3311,
                "itemName": "BSc in Nanoscience (honours)"
            },
            {
                "id": 3312,
                "itemName": "BSc in Applied Physics (honours)"
            },
            {
                "id": 3313,
                "itemName": "Minor in African Studies"
            },
            {
                "id": 3314,
                "itemName": "Bachelor of Engineering in Architectural Conservation and Sustainability"
            },
            {
                "id": 3315,
                "itemName": "Bachelor of Engineering in Aerospace Engineering"
            },
            {
                "id": 3316,
                "itemName": "Bachelor of Engineering in Biomedical and Electrical Engineering"
            },
            {
                "id": 3317,
                "itemName": "Bachelor of Engineering in Biomedical and Mechanical Engineering"
            },
            {
                "id": 3318,
                "itemName": "Bachelor of Engineering in Communications Engineering"
            },
            {
                "id": 3319,
                "itemName": "Bachelor of Engineering in Engineering Physics"
            },
            {
                "id": 3320,
                "itemName": "Bachelor of Engineering in Software Engineering"
            },
            {
                "id": 3321,
                "itemName": "Bachelor of Engineering in Sustainable and Renewable Energy Engineering"
            },
            {
                "id": 3322,
                "itemName": "Bachelor of Industrial Design"
            },
            {
                "id": 3323,
                "itemName": "Bachelor of Architectural Studies (Honours)"
            },
            {
                "id": 3324,
                "itemName": "Bachelor of Information Technology"
            },
            {
                "id": 3325,
                "itemName": "Minor in Applied Linguistics and Discourse Studies"
            },
            {
                "id": 3326,
                "itemName": "BSc in Earth Sciences (major)"
            },
            {
                "id": 3327,
                "itemName": "BSc in Environmental Science (major)"
            },
            {
                "id": 3328,
                "itemName": "BSc in Neuroscience and Mental Health (general)"
            },
            {
                "id": 3329,
                "itemName": "BSc in Neuroscience and Mental Health (honours)"
            },
            {
                "id": 3330,
                "itemName": "BSc in Neuroscience and Mental Health (major)"
            },
            {
                "id": 3331,
                "itemName": "Bachelor of Commerce with Concentration in Entrepreneurship (honours)"
            },
            {
                "id": 3332,
                "itemName": "Bachelor of Cognitive Science (honours)"
            },
            {
                "id": 3333,
                "itemName": "Minor in Business"
            },
            {
                "id": 3334,
                "itemName": "Minor in Communication and Media Studies"
            },
            {
                "id": 3335,
                "itemName": "Minor in Design"
            },
            {
                "id": 3336,
                "itemName": "Minor in Disability Studies"
            },
            {
                "id": 3337,
                "itemName": "Minor in Drama Studies"
            },
            {
                "id": 3338,
                "itemName": "Minor in Earth Sciences, Earth Resources and Processes"
            },
            {
                "id": 3339,
                "itemName": "Minor in European and Russian Studies"
            },
            {
                "id": 3340,
                "itemName": "Minor in Food Science"
            },
            {
                "id": 3341,
                "itemName": "Minor in Law"
            },
            {
                "id": 3342,
                "itemName": "Minor in Neuroscience and Mental Health"
            },
            {
                "id": 3343,
                "itemName": "Minor in Physical Geography"
            },
            {
                "id": 3344,
                "itemName": "Bachelor of Engineering in Computer Systems Engineering"
            },
            {
                "id": 3345,
                "itemName": "BA in European and Russian studies (general)"
            },
            {
                "id": 3346,
                "itemName": "BA in Law (general)"
            },
            {
                "id": 3347,
                "itemName": "BSc in Geomatics (honours)"
            },
            {
                "id": 3348,
                "itemName": "Bachelor of Health Sciences"
            },
            {
                "id": 3349,
                "itemName": "BSc in Physical Geography (honours)"
            },
            {
                "id": 3350,
                "itemName": "Bachelor of Journalism and Humanities (honours)"
            },
            {
                "id": 3351,
                "itemName": "Bachelor of Humanities"
            },
            {
                "id": 3352,
                "itemName": "Bachelor of Global and International Studies (honours)"
            },
            {
                "id": 3353,
                "itemName": "BA in African Studies (general)"
            },
            {
                "id": 3354,
                "itemName": "Minor in Arts Management"
            },
            {
                "id": 3355,
                "itemName": "Minor in Latin American and Caribbean Studies"
            },
            {
                "id": 3356,
                "itemName": "Minor in Medieval and Early Modern Studies"
            },
            {
                "id": 3357,
                "itemName": "BA in Indigenous Studies (combined honours) "
            },
            {
                "id": 3358,
                "itemName": "Minor in Christianity Studies"
            },
            {
                "id": 3359,
                "itemName": "Minor in South Asian Studies"
            },
            {
                "id": 3360,
                "itemName": "Bachelor of Media Production and Design"
            },
            {
                "id": 3361,
                "itemName": "Minor in Archaeology"
            },
            {
                "id": 3362,
                "itemName": "Minor in Digital Humanities"
            },
            {
                "id": 3363,
                "itemName": "Minor in Professional Writing"
            },
            {
                "id": 3364,
                "itemName": "BSc Psychology and Neuroscience (Hons/4-year BSc)"
            },
            {
                "id": 3365,
                "itemName": "BA in Political Science (Hons/4-year BA)"
            },
            {
                "id": 3366,
                "itemName": "BA in Psychology or BA in Psychology: Research Specialist (Hons/4-year BA)"
            },
            {
                "id": 3367,
                "itemName": "BA in Mathematics with Finance and Accounting Option (Hons/4-year BA)"
            },
            {
                "id": 3368,
                "itemName": "BA in Geography (Hons/4-year BA)"
            },
            {
                "id": 3369,
                "itemName": "BSc in Biology (Hons/4-year BSc)"
            },
            {
                "id": 3370,
                "itemName": "BA in Biology (Hons/4-year BA)"
            },
            {
                "id": 3371,
                "itemName": "BA in Philosophy (Hons/4-year BA)"
            },
            {
                "id": 3372,
                "itemName": "BA in Mathematics (Hons/4-year BA)"
            },
            {
                "id": 3373,
                "itemName": "BA in History (Hons/4-year BA)"
            },
            {
                "id": 3374,
                "itemName": "BA in Spanish (Hons/4-year BA)"
            },
            {
                "id": 3375,
                "itemName": "BSc in Geography (Hons/4-year BA)"
            },
            {
                "id": 3376,
                "itemName": "BSc in Mathematics (Hons/4-year BSc)"
            },
            {
                "id": 3377,
                "itemName": "BA in Religion and Culture (Hons/4-year BA)"
            },
            {
                "id": 3378,
                "itemName": "BA in Financial Mathematics (Hons/4-year BA)"
            },
            {
                "id": 3379,
                "itemName": "BA in French (Hons/4-year BA)"
            },
            {
                "id": 3380,
                "itemName": "BSc in Physics (Hons/4-year BSc)"
            },
            {
                "id": 3381,
                "itemName": "BSc in Computer Science (Hons/4-year BSc)"
            },
            {
                "id": 3382,
                "itemName": "BMusic Therapy (Hons/4-year BMusTh)"
            },
            {
                "id": 3383,
                "itemName": "BMus: Music History, Theory and Critical Analysis (Hons/4-year BMus)"
            },
            {
                "id": 3384,
                "itemName": "BMus: Composition (Hons/4-year BMus)"
            },
            {
                "id": 3385,
                "itemName": "MA in Theology"
            },
            {
                "id": 3386,
                "itemName": "Bachelor of Business Administration and Bachelor of Mathematics"
            },
            {
                "id": 3387,
                "itemName": "Bachelor of Business Administration and Bachelor of Science in Computer Science"
            },
            {
                "id": 3388,
                "itemName": "BA in Economics and Accounting (Hons/4-year BA)"
            },
            {
                "id": 3389,
                "itemName": "BA in Economics and Financial Management (Hons/4-year BA)"
            },
            {
                "id": 3390,
                "itemName": "Bachelor of Business Administration (Hons/4-year BBA)"
            },
            {
                "id": 3391,
                "itemName": "BA in Economics (Hons/4-year BA)"
            },
            {
                "id": 3392,
                "itemName": "BA in Environmental Studies (Hons/4-year BA)"
            },
            {
                "id": 3393,
                "itemName": "BSc in Environmental Science (Hons/4-year BSc)"
            },
            {
                "id": 3394,
                "itemName": "BA in Communication Studies (Hons/4-year BA)"
            },
            {
                "id": 3395,
                "itemName": "BA in Computer Science (Hons/4-year BA)"
            },
            {
                "id": 3396,
                "itemName": "BA in Anthropology (Hons/4-year BA)"
            },
            {
                "id": 3397,
                "itemName": "BSc in Chemistry (Hons/4-year BSc)"
            },
            {
                "id": 3398,
                "itemName": "BA in Global Studies (Hons/4-year BA)"
            },
            {
                "id": 3399,
                "itemName": "BA in English (Hons/4-year BA)"
            },
            {
                "id": 3400,
                "itemName": "BA in Sociology (Hons/4-year BA)"
            },
            {
                "id": 3401,
                "itemName": "BA in Archaeology + Heritage Studies (Hons/4-year BA)"
            },
            {
                "id": 3402,
                "itemName": "BA in Film Studies (Hons/4-year BA)"
            },
            {
                "id": 3403,
                "itemName": "BA in Women and Gender Studies (Hons/4-year BA)"
            },
            {
                "id": 3404,
                "itemName": "BSc in Financial Mathematics (Hons/4-year BSc)"
            },
            {
                "id": 3405,
                "itemName": "BA in Cultural Studies (Hons/4-year BA)"
            },
            {
                "id": 3406,
                "itemName": "BA in Languages (Hons/4-year BA)"
            },
            {
                "id": 3407,
                "itemName": "BA in Medieval and Medievalism Studies (Hons/4-year BA)"
            },
            {
                "id": 3408,
                "itemName": "BA in North American Studies (Hons/4-year BA)"
            },
            {
                "id": 3409,
                "itemName": "BSc in Geography and Geomatics (Hons/4-year BA)"
            },
            {
                "id": 3410,
                "itemName": "BA in Criminology (Hons/4-year BA)"
            },
            {
                "id": 3411,
                "itemName": "BA in Health Administration (Hons/4-year BA)"
            },
            {
                "id": 3412,
                "itemName": "BA in History (Hons/4-year BA) Brantford Campus"
            },
            {
                "id": 3413,
                "itemName": "BA in Human Rights and Human Diversity (Hons/4-year BA)"
            },
            {
                "id": 3414,
                "itemName": "BA in Digital Media & Journalism (Hons/4-year BA)"
            },
            {
                "id": 3415,
                "itemName": "BA in Law & Society (Hons/4-year BA)"
            },
            {
                "id": 3416,
                "itemName": "BA in Psychology (Hons/4-year BA)"
            },
            {
                "id": 3417,
                "itemName": "BMus: Music Education (Hons/4-year BMus)"
            },
            {
                "id": 3418,
                "itemName": "BSc in Biochemistry/Biotechnology (Hons/4-year BSc)"
            },
            {
                "id": 3419,
                "itemName": "BSc in Health Sciences (Hons/4-year BSc)"
            },
            {
                "id": 3420,
                "itemName": "Bachelor of Education: Primary/Junior Stream and Junior/Intermediate Stream (2-year)"
            },
            {
                "id": 3421,
                "itemName": "Master of Divinity"
            },
            {
                "id": 3422,
                "itemName": "Master of Divinity and Master of Social Work"
            },
            {
                "id": 3423,
                "itemName": "BA in Youth and Children's Studies (Hons/4-year BA)"
            },
            {
                "id": 3424,
                "itemName": "BA in Ancient Studies (Hons/4-year BA)"
            },
            {
                "id": 3425,
                "itemName": "Bachelor of Business Administration and Bachelor of Arts in Financial Mathematics (Hons/5-year BBA/BA)"
            },
            {
                "id": 3426,
                "itemName": "General BA (without any major/minor/option designations) (3-year)"
            },
            {
                "id": 3427,
                "itemName": "Bachelor of Business Technology Management, Brantford Campus (Hons/4-year BBTM)"
            },
            {
                "id": 3428,
                "itemName": "BMus: Performance (Hons/4-year BMus)"
            },
            {
                "id": 3429,
                "itemName": "Bachelor of Arts and Science in Community Health"
            },
            {
                "id": 3430,
                "itemName": "Minor in Education"
            },
            {
                "id": 3431,
                "itemName": "Bachelor of Social Work, Brantford Campus (Hons/4-year BSW)"
            },
            {
                "id": 3432,
                "itemName": "General BSc (without any major/minor/option designations) (3-year)"
            },
            {
                "id": 3433,
                "itemName": "Minor in German Studies"
            },
            {
                "id": 3434,
                "itemName": "Minor in Business German"
            },
            {
                "id": 3435,
                "itemName": "Minor in History & Philosophy of Science"
            },
            {
                "id": 3436,
                "itemName": "Minor in Italian"
            },
            {
                "id": 3437,
                "itemName": "BSc in Biochemistry/Biotechnology with Thesis (Hons/4-year BSc)"
            },
            {
                "id": 3438,
                "itemName": "BSc in Chemistry with Thesis (Hons/4-year BSc)"
            },
            {
                "id": 3439,
                "itemName": "Bachelor of Business Administration and Bachelor of Computer Science (Hons/5-year BBA/BSc)"
            },
            {
                "id": 3440,
                "itemName": "Minor in Writing for Career & Community"
            },
            {
                "id": 3441,
                "itemName": "Bachelor of Fine and Applied Arts in Game Design & Development (Hons/4-year BFAA)"
            },
            {
                "id": 3442,
                "itemName": "BA in Community Health"
            },
            {
                "id": 3443,
                "itemName": "BA in Indigenous Studies (Hons/4-year BA)"
            },
            {
                "id": 3444,
                "itemName": "Bachelor of Kinesiology (Hons/4-year BKin)"
            },
            {
                "id": 3445,
                "itemName": "BA in Political Science/LLB Sussex University UK (hons. 6-years BA/LLB joint program)"
            },
            {
                "id": 3446,
                "itemName": "Minor in Youth and Children: Texts and Contexts"
            },
            {
                "id": 3447,
                "itemName": "BA in Policing"
            },
            {
                "id": 3448,
                "itemName": "BA in Human Rights and Human Diversity/LLB Sussex University UK (hons/6-year BA/LLB joint program)"
            },
            {
                "id": 3449,
                "itemName": "BA in Law and Society/LLB Sussex University - UK (6-year BA/LLB joint program)"
            },
            {
                "id": 3450,
                "itemName": "BMus: Community Music (Hons/4-year BMus)"
            },
            {
                "id": 3451,
                "itemName": "BSc in Applied Water Science (Hons/4-year BSc)"
            },
            {
                "id": 3452,
                "itemName": "Minor in Public Policy"
            },
            {
                "id": 3453,
                "itemName": "Bachelor of Design, User Experience Design (Hons./4-year BDes)"
            },
            {
                "id": 3454,
                "itemName": "Minor in Public History"
            },
            {
                "id": 3455,
                "itemName": "Minor in Social Innovation"
            },
            {
                "id": 3456,
                "itemName": "BSc in Applied Water Science"
            },
            {
                "id": 3457,
                "itemName": "BMus: Integrated Musical Arts (Hons/4-year BMus)"
            },
            {
                "id": 3458,
                "itemName": "BA in English and/or Film Studies combined with Vancouver Film School Diploma Program(Hons/4-year BA)"
            },
            {
                "id": 3459,
                "itemName": "BA in International Education Studies (Hons/4-year BA)"
            },
            {
                "id": 3460,
                "itemName": "BA in Philosophy/LLB Sussex University UK (hons. 6-years BA/LLB joint program)"
            },
            {
                "id": 3461,
                "itemName": "BA in Criminology and LLB (Sussex University UK) (Hons 6-year BA/LLB joint program)"
            },
            {
                "id": 3462,
                "itemName": "BA in Criminology and Policing (Hons/4-year BA)"
            },
            {
                "id": 3463,
                "itemName": "BA in Humanities with Leadership Foundations (Hons/4-year BA)"
            },
            {
                "id": 3464,
                "itemName": "BA in Humanities with Leadership Foundations and LLB (Sussex University UK) (Hons 6-year BA/LLB joint program)"
            },
            {
                "id": 3465,
                "itemName": "BMus: Self-Directed Studies (Hons/4-year BMus)"
            },
            {
                "id": 3466,
                "itemName": "Honours BMus and Randolf College for the Performing Arts, Performing Arts Diploma Program (Hons/4-year BMus)"
            },
            {
                "id": 3467,
                "itemName": "BSc in Science (Hons/4-year BSc)"
            },
            {
                "id": 3468,
                "itemName": "BA in Christian Studies and Global Citizenship"
            },
            {
                "id": 3469,
                "itemName": "Judaism Minor"
            },
            {
                "id": 3470,
                "itemName": "Spirituality and Global Music Minor"
            },
            {
                "id": 3471,
                "itemName": "Human Relationships Minor"
            },
            {
                "id": 3472,
                "itemName": "Applied Digital Minor"
            },
            {
                "id": 3473,
                "itemName": "BA in Public Safety"
            },
            {
                "id": 3474,
                "itemName": "Minor in Physical Forensics"
            },
            {
                "id": 3475,
                "itemName": "BSc in Psychology (Hons/4-year BSc)"
            },
            {
                "id": 3476,
                "itemName": "Community Psychology and Social Change Minor"
            },
            {
                "id": 3477,
                "itemName": "Statistics Minor"
            },
            {
                "id": 3478,
                "itemName": "BA/BSc Options"
            },
            {
                "id": 3479,
                "itemName": "Baccalauréat en gestion et design de la mode (B.A. ou B.Sc.G.)"
            },
            {
                "id": 3480,
                "itemName": "Baccalauréat en musique (B.A.)"
            },
            {
                "id": 3481,
                "itemName": "Baccalauréat en développement de carrière (B.A.)"
            },
            {
                "id": 3482,
                "itemName": "Baccalauréat en enseignement secondaire (B.Ed.)"
            },
            {
                "id": 3483,
                "itemName": "Baccalauréat en enseignement en adaptation scolaire et sociale (B.Ed.)"
            },
            {
                "id": 3484,
                "itemName": "Baccalauréat en communication (journalisme) (B.A.)"
            },
            {
                "id": 3485,
                "itemName": "Baccalauréat en communication humaine et organisationnelle (B.A.)"
            },
            {
                "id": 3486,
                "itemName": "Baccalauréat d'enseignement en formation professionnelle et technique (B.Ed.)"
            },
            {
                "id": 3487,
                "itemName": "Baccalauréat en droit (LL.B.)"
            },
            {
                "id": 3488,
                "itemName": "Baccalauréat en travail social (B.T.S.)"
            },
            {
                "id": 3489,
                "itemName": "Baccalauréat en sexologie (B.A.)"
            },
            {
                "id": 3490,
                "itemName": "Baccalauréat en sciences des religions (B.A.)"
            },
            {
                "id": 3491,
                "itemName": "Baccalauréat en psychologie (B.Sc.)"
            },
            {
                "id": 3492,
                "itemName": "Baccalauréat en géographie (B.Sc.)"
            },
            {
                "id": 3493,
                "itemName": "Baccalauréat en communication (relations publiques) (B.A.)"
            },
            {
                "id": 3494,
                "itemName": "Baccalauréat en art dramatique (B.A.)"
            },
            {
                "id": 3495,
                "itemName": "Baccalauréat en design graphique (B.A.)"
            },
            {
                "id": 3496,
                "itemName": "Baccalauréat en design de l'environnement (B.A.)"
            },
            {
                "id": 3497,
                "itemName": "Baccalauréat en danse (B.A.)"
            },
            {
                "id": 3498,
                "itemName": "Baccalauréat en arts visuels et médiatiques (B.A.)"
            },
            {
                "id": 3499,
                "itemName": "Baccalauréat en biologie en apprentissage par problèmes (B.Sc.)"
            },
            {
                "id": 3500,
                "itemName": "Baccalauréat en chimie (B.Sc.)"
            },
            {
                "id": 3501,
                "itemName": "Baccalauréat en biochimie (B.Sc.)"
            },
            {
                "id": 3502,
                "itemName": "Baccalauréat en mathématiques (B.Sc.)"
            },
            {
                "id": 3503,
                "itemName": "Baccalauréat en informatique  et génie logiciel (B.Sc.A.)"
            },
            {
                "id": 3504,
                "itemName": "Baccalauréat en actuariat (B.Sc.)"
            },
            {
                "id": 3505,
                "itemName": "Baccalauréat en action culturelle (B.A.)"
            },
            {
                "id": 3506,
                "itemName": "Majeure en communication"
            },
            {
                "id": 3507,
                "itemName": "Majeure en science politique"
            },
            {
                "id": 3508,
                "itemName": "Baccalauréat en relations internationales et droit international (B.A.)"
            },
            {
                "id": 3509,
                "itemName": "Baccalauréat en communication, politique et société (B.A.)"
            },
            {
                "id": 3510,
                "itemName": "Baccalauréat en communication (stratégies de production culturelle et médiatique) (B.A.)"
            },
            {
                "id": 3511,
                "itemName": "Baccalauréat en gestion publique (B.A.)"
            },
            {
                "id": 3512,
                "itemName": "Baccalauréat en sciences de l'éducation (B.A.)"
            },
            {
                "id": 3513,
                "itemName": "Baccalauréat en communication (création médias - cinéma) (B.A.)"
            },
            {
                "id": 3514,
                "itemName": "Baccalauréat en communication (création médias - médias interactifs) (B.A.)"
            },
            {
                "id": 3515,
                "itemName": "Baccalauréat en communication (création médias - télévision) (B.A.)"
            },
            {
                "id": 3516,
                "itemName": "Baccalauréat en études littéraires (B.A.)"
            },
            {
                "id": 3517,
                "itemName": "Baccalauréat en sciences de la Terre et de l'atmosphère (B.Sc.)"
            },
            {
                "id": 3518,
                "itemName": "Majeure en géologie"
            },
            {
                "id": 3519,
                "itemName": "Mineure en linguistique"
            },
            {
                "id": 3520,
                "itemName": "Baccalauréat en communication marketing (B.A.)"
            },
            {
                "id": 3521,
                "itemName": "Majeure en études urbaines"
            },
            {
                "id": 3522,
                "itemName": "Baccalauréat en gestion des ressources humaines (B.A.A.)"
            },
            {
                "id": 3523,
                "itemName": "Baccalauréat en gestion du tourisme et de l'hôtellerie (B.A.A.)"
            },
            {
                "id": 3524,
                "itemName": "Baccalauréat en administration (B.A.A.)"
            },
            {
                "id": 3525,
                "itemName": "Baccalauréat en sciences comptables (B.A.A.)"
            },
            {
                "id": 3526,
                "itemName": "Baccalauréat en urbanisme (B.Sc.)"
            },
            {
                "id": 3527,
                "itemName": "Baccalauréat en économique (B.Sc.)"
            },
            {
                "id": 3528,
                "itemName": "Baccalauréat d'intervention en activité physique (B.Sc.)"
            },
            {
                "id": 3529,
                "itemName": "Baccalauréat en linguistique (B.A.)"
            },
            {
                "id": 3530,
                "itemName": "Majeure en études théâtrales"
            },
            {
                "id": 3531,
                "itemName": "Baccalauréat en enseignement de l'anglais langue seconde (B.Ed.)"
            },
            {
                "id": 3532,
                "itemName": "Mineure en études médiévales"
            },
            {
                "id": 3533,
                "itemName": "Mineure en urbanisme opérationnel"
            },
            {
                "id": 3534,
                "itemName": "Mineure en patrimoine urbain"
            },
            {
                "id": 3535,
                "itemName": "Mineure en études urbaines"
            },
            {
                "id": 3536,
                "itemName": "Majeure en musique"
            },
            {
                "id": 3537,
                "itemName": "Baccalauréat en communication (médias numériques) (B.A.)"
            },
            {
                "id": 3538,
                "itemName": "Majeure en statistique"
            },
            {
                "id": 3539,
                "itemName": "Baccalauréat en sciences naturelles appliquées à l'environnement (B.Sc.)"
            },
            {
                "id": 3540,
                "itemName": "Majeure en actuariat"
            },
            {
                "id": 3541,
                "itemName": "Mineure en mathématiques actuarielles et financières"
            },
            {
                "id": 3542,
                "itemName": "Baccalauréat en systèmes informatiques et électroniques (B.Sc.A.)"
            },
            {
                "id": 3543,
                "itemName": "Majeure en développement de carrière"
            },
            {
                "id": 3544,
                "itemName": "Majeure en anglais et culture anglophone"
            },
            {
                "id": 3545,
                "itemName": "Majeure en langues et cultures modernes"
            },
            {
                "id": 3546,
                "itemName": "Majeure en interprétation français-langue des signes québécoise"
            },
            {
                "id": 3547,
                "itemName": "Bachelor of Social Work - 4 year"
            },
            {
                "id": 3548,
                "itemName": "BSc (4 year honours) in Psychology"
            },
            {
                "id": 3549,
                "itemName": "BSc (4 year major) in Mathematics"
            },
            {
                "id": 3550,
                "itemName": "BSc (4 year major) in Chemistry"
            },
            {
                "id": 3551,
                "itemName": "BSc (4 year major) in Microbiology"
            },
            {
                "id": 3552,
                "itemName": "BSc (4 year major) in Statistics"
            },
            {
                "id": 3553,
                "itemName": "BSc (4 year major) in Physics and Astronomy"
            },
            {
                "id": 3554,
                "itemName": "BSc (4 year honours) in Actuarial Mathematics"
            },
            {
                "id": 3555,
                "itemName": "BSc (4 year joint honours) in Biochemistry"
            },
            {
                "id": 3556,
                "itemName": "BSc (4 year major) in Applied Mathematics"
            },
            {
                "id": 3557,
                "itemName": "Bachelor of Computer Science (4 year honours)"
            },
            {
                "id": 3558,
                "itemName": "Bachelor of Nursing (RRC)"
            },
            {
                "id": 3559,
                "itemName": "Bachelor of Nursing BN - 4 year"
            },
            {
                "id": 3560,
                "itemName": "Bachelor of Medical Rehabilitation Medicine (Respiratory Therapy) - 4 year"
            },
            {
                "id": 3561,
                "itemName": "BSc in Medicine"
            },
            {
                "id": 3562,
                "itemName": "Juris Doctor JD - 3 years following minimum 2 years of university studies"
            },
            {
                "id": 3563,
                "itemName": "Bachelor of Education (senior years) 2-year"
            },
            {
                "id": 3564,
                "itemName": "Bachelor of Education (middle years) 2-year"
            },
            {
                "id": 3565,
                "itemName": "Bachelor of Education (early years) 2-year"
            },
            {
                "id": 3566,
                "itemName": "Bachelor of Environmental Design - 4 year"
            },
            {
                "id": 3567,
                "itemName": "BSc (Agriculture) - in Agronomy - 4 year"
            },
            {
                "id": 3568,
                "itemName": "BSc (Food Science) - 4 year"
            },
            {
                "id": 3569,
                "itemName": "BSc (Agroecology) - 4 year"
            },
            {
                "id": 3570,
                "itemName": "BSc (Agribusiness) - 4 year"
            },
            {
                "id": 3571,
                "itemName": "B.Comm.(Hons.) 4 year - Entrepreneurship/Small Business"
            },
            {
                "id": 3572,
                "itemName": "B.Comm.(Hons.) 4 year - Marketing"
            },
            {
                "id": 3573,
                "itemName": "B.Comm.(Hons.) 4 year - Leadership and Organizations"
            },
            {
                "id": 3574,
                "itemName": "B.Comm.(Hons.) 4 year - Generalist"
            },
            {
                "id": 3575,
                "itemName": "BSc in Engineering (Biosystems) 4 year"
            },
            {
                "id": 3576,
                "itemName": "BSc in Engineering (Electrical) 4 year"
            },
            {
                "id": 3577,
                "itemName": "BSc in Engineering (Mechanical) 4 year"
            },
            {
                "id": 3578,
                "itemName": "BSc in Engineering (Computer) 4 year"
            },
            {
                "id": 3579,
                "itemName": "BSc in Engineering (Civil) 4 year"
            },
            {
                "id": 3580,
                "itemName": "BSc in Dentistry"
            },
            {
                "id": 3581,
                "itemName": "BA (4 year honours) in Women's and Gender Studies"
            },
            {
                "id": 3582,
                "itemName": "BA (4 year advanced) in Global Political Economy"
            },
            {
                "id": 3583,
                "itemName": "BA (3 year general) in Global Political Economy"
            },
            {
                "id": 3584,
                "itemName": "BA (3 year general) in Ukrainian Canadian Heritage Studies"
            },
            {
                "id": 3585,
                "itemName": "BA (4 year honours) in Sociology"
            },
            {
                "id": 3586,
                "itemName": "BA (4 years honours) in Religion"
            },
            {
                "id": 3587,
                "itemName": "BA (4 year honours) in Psychology"
            },
            {
                "id": 3588,
                "itemName": "BA (4 year honours) in Political Studies"
            },
            {
                "id": 3589,
                "itemName": "BA (3 year general) in Philosophy"
            },
            {
                "id": 3590,
                "itemName": "BA (3 year general) in Mathematics"
            },
            {
                "id": 3591,
                "itemName": "BA (3 year general) in Linguistics"
            },
            {
                "id": 3592,
                "itemName": "BA (3 year general) in Labour Studies"
            },
            {
                "id": 3593,
                "itemName": "BA (3 year general) in Icelandic"
            },
            {
                "id": 3594,
                "itemName": "BA (4 year honours) in History"
            },
            {
                "id": 3595,
                "itemName": "BA (3 year general) in German"
            },
            {
                "id": 3596,
                "itemName": "BA (3 year general) in Russian"
            },
            {
                "id": 3597,
                "itemName": "BA (3 year general) in French"
            },
            {
                "id": 3598,
                "itemName": "BA (3 year general) in Spanish"
            },
            {
                "id": 3599,
                "itemName": "BA (3 year general) in Film Studies"
            },
            {
                "id": 3600,
                "itemName": "BA (3 year general) in English"
            },
            {
                "id": 3601,
                "itemName": "BA (4 year honours) in Economics"
            },
            {
                "id": 3602,
                "itemName": "BA (4 year advanced) in Theatre"
            },
            {
                "id": 3603,
                "itemName": "BA (4 year advanced) in Classical Studies"
            },
            {
                "id": 3604,
                "itemName": "BA (3 year general) in Central and East European Studies"
            },
            {
                "id": 3605,
                "itemName": "BA (3 year general) in Canadian Studies"
            },
            {
                "id": 3606,
                "itemName": "BA (3 year general) in Anthropology"
            },
            {
                "id": 3607,
                "itemName": "BA (3 year general) in Women's and Gender Studies"
            },
            {
                "id": 3608,
                "itemName": "BA (4 year advanced) in Ukrainian Canadian Heritage Studies"
            },
            {
                "id": 3609,
                "itemName": "BA (4 year honours) in Canadian Studies"
            },
            {
                "id": 3610,
                "itemName": "BA (3 year general) in Asian Studies"
            },
            {
                "id": 3611,
                "itemName": "BA (3 year general) in Theatre"
            },
            {
                "id": 3612,
                "itemName": "BA (4 year advanced) in Central and East European Studies"
            },
            {
                "id": 3613,
                "itemName": "BA (3 year general) in Medieval and Early Modern Studies"
            },
            {
                "id": 3614,
                "itemName": "BA (4 year advanced) in Mathematics"
            },
            {
                "id": 3615,
                "itemName": "BA (4 year advanced) in Linguistics"
            },
            {
                "id": 3616,
                "itemName": "BA (4 year advanced) in Labour Studies"
            },
            {
                "id": 3617,
                "itemName": "BA (4 year honours) in Icelandic"
            },
            {
                "id": 3618,
                "itemName": "BA (3 year general) in History"
            },
            {
                "id": 3619,
                "itemName": "BA (3 year general) in Ukrainian"
            },
            {
                "id": 3620,
                "itemName": "BA (4 year advanced) in Geography"
            },
            {
                "id": 3621,
                "itemName": "BA (4 year honours) in French"
            },
            {
                "id": 3622,
                "itemName": "BA (4 year advanced) in Film Studies"
            },
            {
                "id": 3623,
                "itemName": "BA (4 year advanced) in English"
            },
            {
                "id": 3624,
                "itemName": "BA (3 year general) in Economics"
            },
            {
                "id": 3625,
                "itemName": "BA (4 year advanced) in Anthropology"
            },
            {
                "id": 3626,
                "itemName": "BA (3 year general) in Criminology"
            },
            {
                "id": 3627,
                "itemName": "BA (3 year general) in Political Studies"
            },
            {
                "id": 3628,
                "itemName": "BA (4 year honours) in Philosophy"
            },
            {
                "id": 3629,
                "itemName": "BA (4 year advanced) in Spanish"
            },
            {
                "id": 3630,
                "itemName": "BA (3 year general) in Sociology"
            },
            {
                "id": 3631,
                "itemName": "BA (3 year general) in Religion"
            },
            {
                "id": 3632,
                "itemName": "BA (3 year general) in Classical Studies"
            },
            {
                "id": 3633,
                "itemName": "BA (3 year general) in Psychology"
            },
            {
                "id": 3634,
                "itemName": "Bachelor of Physical Education - 3 year"
            },
            {
                "id": 3635,
                "itemName": "Bachelor of Kinesiology - 4 year"
            },
            {
                "id": 3636,
                "itemName": "Bachelor of Recreation Management and Community Development - 4 year"
            },
            {
                "id": 3637,
                "itemName": "Bachelor of Music - 4 year"
            },
            {
                "id": 3638,
                "itemName": "B.Comm.(Hons.) 4 year - International Business"
            },
            {
                "id": 3639,
                "itemName": "B.Comm.(Hons.) 4 year - Management Information Systems"
            },
            {
                "id": 3640,
                "itemName": "B.Comm.(Hons.) 4 year - Indigenous Business Studies"
            },
            {
                "id": 3641,
                "itemName": "B.Comm.(Hons.) 4 year - Human Resources Management/Industrial Relations"
            },
            {
                "id": 3642,
                "itemName": "B.Comm.(Hons.) 4 year - Actuarial Mathematics"
            },
            {
                "id": 3643,
                "itemName": "B.Comm.(Hons.) 4 year - Finance"
            },
            {
                "id": 3644,
                "itemName": "B.Comm.(Hons.) 4 year - Accounting"
            },
            {
                "id": 3645,
                "itemName": "Doctor of Medicine MD - 4 years following first undergraduate degree"
            },
            {
                "id": 3646,
                "itemName": "BSc (4 year honours) in Genetics"
            },
            {
                "id": 3647,
                "itemName": "BA (4 year advanced) in Greek"
            },
            {
                "id": 3648,
                "itemName": "BA (4 year advanced) in Latin"
            },
            {
                "id": 3649,
                "itemName": "BA (4 year honours) in German"
            },
            {
                "id": 3650,
                "itemName": "BA (3 year general) in Native Studies"
            },
            {
                "id": 3651,
                "itemName": "BA (4 year advanced) in Native Studies"
            },
            {
                "id": 3652,
                "itemName": "BA (4 year advanced) in Medieval and Early Modern Studies"
            },
            {
                "id": 3653,
                "itemName": "BA (3 year general) in Art History"
            },
            {
                "id": 3654,
                "itemName": "B.Comm.(Hons.) 4 year - Operational Research/Operations Management"
            },
            {
                "id": 3655,
                "itemName": "BSc (3 year general)"
            },
            {
                "id": 3656,
                "itemName": "BSc (4 year honours) in Chemistry"
            },
            {
                "id": 3657,
                "itemName": "BSc (4 year major) in Computer Science"
            },
            {
                "id": 3658,
                "itemName": "BSc (4 year joint honours) in Mathematics/Computer Science"
            },
            {
                "id": 3659,
                "itemName": "BSc (4 year joint honours) Mathematics/Actuarial Mathematics"
            },
            {
                "id": 3660,
                "itemName": "BSc 4 year (joint honours) Mathematics/Physics and Astronomy"
            },
            {
                "id": 3661,
                "itemName": "BSc (4 year joint major) in Biochemistry"
            },
            {
                "id": 3662,
                "itemName": "BSc (4 year joint honours) in Statistics/Actuarial Mathematics"
            },
            {
                "id": 3663,
                "itemName": "BSc (4 year joint honours) in Statistics/Mathematics"
            },
            {
                "id": 3664,
                "itemName": "BFA (4 year honours) Studio Program"
            },
            {
                "id": 3665,
                "itemName": "BFA (4 year honours) in Art History"
            },
            {
                "id": 3666,
                "itemName": "BA (4 year advanced) in Aboriginal Governance"
            },
            {
                "id": 3667,
                "itemName": "Bachelor of Environmental Science (3 year general)"
            },
            {
                "id": 3668,
                "itemName": "Bachelor of Environmental Studies (3 year general)"
            },
            {
                "id": 3669,
                "itemName": "BSc (3 year general) in Geological Sciences"
            },
            {
                "id": 3670,
                "itemName": "BA (4 year honours) in Economics and Mathematics"
            },
            {
                "id": 3671,
                "itemName": "BA (4 year honours) in Economics and Statistics"
            },
            {
                "id": 3672,
                "itemName": "BSc (4 year joint honours) in Computer Science/Physics and Astronomy"
            },
            {
                "id": 3673,
                "itemName": "BSc (4 year joint honours) in Mathematics/Statistics"
            },
            {
                "id": 3674,
                "itemName": "BSc (4 year joint honours) in Statistics/Economics"
            },
            {
                "id": 3675,
                "itemName": "Minor in Polish"
            },
            {
                "id": 3676,
                "itemName": "Minor in Indigenous Languages"
            },
            {
                "id": 3677,
                "itemName": "B.Comm.(Hons) 4 year - Logistics and Supply Chain Management"
            },
            {
                "id": 3678,
                "itemName": "Bachelor of Jazz Studies - 4 year"
            },
            {
                "id": 3679,
                "itemName": "BSc (4 year major)  in Geology"
            },
            {
                "id": 3680,
                "itemName": "BSc (4 year major) in Geophysics"
            },
            {
                "id": 3681,
                "itemName": "Bachelor of Environmental Science (4 year major)"
            },
            {
                "id": 3682,
                "itemName": "Bachelor of Environmental Studies (4 year major)"
            },
            {
                "id": 3683,
                "itemName": "BA (3 year general) in Geography"
            },
            {
                "id": 3684,
                "itemName": "BSc (Agriculture) - in Animal Systems - 4 year"
            },
            {
                "id": 3685,
                "itemName": "BSc (Agriculture) - in Plant Biotechnology - 4 year"
            },
            {
                "id": 3686,
                "itemName": "BSc (4 year honours) in Biological Sciences"
            },
            {
                "id": 3687,
                "itemName": "Minor in Judaic Studies"
            },
            {
                "id": 3688,
                "itemName": "BA in Integrated Studies (3 year)"
            },
            {
                "id": 3689,
                "itemName": "DMD International Dental Degree Program"
            },
            {
                "id": 3690,
                "itemName": "Bachelor of Kinesiology -- Athletic Therapy - 4 year"
            },
            {
                "id": 3691,
                "itemName": "BSc (4 year major) in Genetics"
            },
            {
                "id": 3692,
                "itemName": "BA (4 year honours) in Geography"
            },
            {
                "id": 3693,
                "itemName": "BSc (4 year honours) in Geology"
            },
            {
                "id": 3694,
                "itemName": "BSc (4 year honours) in Geophysics"
            },
            {
                "id": 3695,
                "itemName": "BSc (4 year major) in Physical Geography"
            },
            {
                "id": 3696,
                "itemName": "BSc (4 year honours) in Physical Geography"
            },
            {
                "id": 3697,
                "itemName": "Bachelor of Environmental Science (4 year honours)"
            },
            {
                "id": 3698,
                "itemName": "Bachelor of Environmental Studies (4 year honours)"
            },
            {
                "id": 3699,
                "itemName": "BA (4 year advanced) in Italian Studies"
            },
            {
                "id": 3700,
                "itemName": "BA (4 year honours) in English"
            },
            {
                "id": 3701,
                "itemName": "BA (4 year advanced) in French"
            },
            {
                "id": 3702,
                "itemName": "BA (4 year advanced) in Economics"
            },
            {
                "id": 3703,
                "itemName": "BA (4 year advanced) in History"
            },
            {
                "id": 3704,
                "itemName": "BA (4 year advanced) in Philosophy"
            },
            {
                "id": 3705,
                "itemName": "BA (4 year advanced) in Political Studies"
            },
            {
                "id": 3706,
                "itemName": "BA (4 year advanced) in Psychology"
            },
            {
                "id": 3707,
                "itemName": "BA (4 year advanced) in Religion"
            },
            {
                "id": 3708,
                "itemName": "BA (4 year advanced) in Women's and Gender Studies"
            },
            {
                "id": 3709,
                "itemName": "BA (3 year general) in Italian Studies"
            },
            {
                "id": 3710,
                "itemName": "BSc (4 year major) in Biological Sciences"
            },
            {
                "id": 3711,
                "itemName": "BSc (4 year honours) in Mathematics"
            },
            {
                "id": 3712,
                "itemName": "BSc (4 year honours) in Microbiology"
            },
            {
                "id": 3713,
                "itemName": "BSc (4 year honours) in Physics and Astronomy"
            },
            {
                "id": 3714,
                "itemName": "BSc (4 year honours) in Astronomy"
            },
            {
                "id": 3715,
                "itemName": "BSc (4 year honours) in Medical and Biological Physics"
            },
            {
                "id": 3716,
                "itemName": "BSc (4 year joint honours) in Computer Science/Statistics"
            },
            {
                "id": 3717,
                "itemName": "BSc (4 year major) in Psychology"
            },
            {
                "id": 3718,
                "itemName": "BSc (4 year honours) in Statistics"
            },
            {
                "id": 3719,
                "itemName": "Minor in Entomology"
            },
            {
                "id": 3720,
                "itemName": "Minor in Soil Science"
            },
            {
                "id": 3721,
                "itemName": "BA (4 year honours) in Anthropology"
            },
            {
                "id": 3722,
                "itemName": "BA (4 year honours) in Criminology"
            },
            {
                "id": 3723,
                "itemName": "BSc (4 year joint honours) in Chemistry/Physics and Astronomy"
            },
            {
                "id": 3724,
                "itemName": "Minor in Crop Protection"
            },
            {
                "id": 3725,
                "itemName": "BSc (4 year joint honours) in Mathematics/Economics"
            },
            {
                "id": 3726,
                "itemName": "Bachelor of Health Sciences - 4 year"
            },
            {
                "id": 3727,
                "itemName": "Bachelor of Health Studies - 4 year"
            },
            {
                "id": 3728,
                "itemName": "Bachelor of Science (Human Nutritional Sciences - RRC) - 4 year"
            },
            {
                "id": 3729,
                "itemName": "Bachelor of Science (Human Nutritional Sciences) - 4 year"
            },
            {
                "id": 3730,
                "itemName": "BA (4 year advanced) in Sociology"
            },
            {
                "id": 3731,
                "itemName": "Minor in Arts"
            },
            {
                "id": 3732,
                "itemName": "Minor in ASL/English Interpretation"
            },
            {
                "id": 3733,
                "itemName": "Minor in Business (Aboriginal Governance Stream)"
            },
            {
                "id": 3734,
                "itemName": "BFA Studio Program - 4 year"
            },
            {
                "id": 3735,
                "itemName": "Bachelor of Music - Music Education"
            },
            {
                "id": 3736,
                "itemName": "Minor in Agronomy"
            },
            {
                "id": 3737,
                "itemName": "BSc in Human Kinetics"
            },
            {
                "id": 3738,
                "itemName": "BSc advanced major in a Science with Business Administration"
            },
            {
                "id": 3739,
                "itemName": "Bachelor of Business Administration (BBA) with major, advanced major or honours in Accounting"
            },
            {
                "id": 3740,
                "itemName": "Bachelor of Business Administration (BBA) with major, advanced major or honours in Finance"
            },
            {
                "id": 3741,
                "itemName": "Bachelor of Business Administration (BBA) with major, advanced major or honours in Enterprise Systems"
            },
            {
                "id": 3742,
                "itemName": "Bachelor of Business Administration (BBA) with major, advanced major or honours in Marketing"
            },
            {
                "id": 3743,
                "itemName": "BA in Women's and Gender Studies"
            },
            {
                "id": 3744,
                "itemName": "BA in Aquatic Resources"
            },
            {
                "id": 3745,
                "itemName": "BA in Human Kinetics"
            },
            {
                "id": 3746,
                "itemName": "Bachelor of Business Administration (BBA) with major, advanced major or honours in Management and Leadership"
            },
            {
                "id": 3747,
                "itemName": "BA in Celtic studies"
            },
            {
                "id": 3748,
                "itemName": "Bachelor of Music (Jazz Studies)"
            },
            {
                "id": 3749,
                "itemName": "BSc in Aquatic Resources"
            },
            {
                "id": 3750,
                "itemName": "BA in Catholic studies"
            },
            {
                "id": 3751,
                "itemName": "BSc in Human Nutrition"
            },
            {
                "id": 3752,
                "itemName": "BSc in Nursing"
            },
            {
                "id": 3753,
                "itemName": "BA in Development Studies"
            },
            {
                "id": 3754,
                "itemName": "BSc in Nursing (post RN)"
            },
            {
                "id": 3755,
                "itemName": "Bachelor of Business Administration (BBA) with joint honours in Business Administration and Economics"
            },
            {
                "id": 3756,
                "itemName": "BSc in Environmental Sciences"
            },
            {
                "id": 3757,
                "itemName": "BA Minor"
            },
            {
                "id": 3758,
                "itemName": "Bachelor of Arts - Humanities Colloquium - First-year option"
            },
            {
                "id": 3759,
                "itemName": "Bachelor of Arts - Social Justice Colloquium - First-year option"
            },
            {
                "id": 3760,
                "itemName": "Bachelor of Business Administration (BBA) with major, advanced major or honours in Entrepreneurship"
            },
            {
                "id": 3761,
                "itemName": "BA in Public Policy and Governance"
            },
            {
                "id": 3762,
                "itemName": "Bachelor of Arts and Science in Health"
            },
            {
                "id": 3763,
                "itemName": "Bachelor of Business Administration (BBA) with major, advanced major or honours in International Business"
            },
            {
                "id": 3764,
                "itemName": "BSc Minor"
            },
            {
                "id": 3765,
                "itemName": "Bachelor of Arts and Science in Climate and Environment"
            },
            {
                "id": 3766,
                "itemName": "Bachelor of Science with Diploma in Engineering"
            },
            {
                "id": 3767,
                "itemName": "Post-Baccalaureate Diploma in Artificial Intelligence"
            },
            {
                "id": 3768,
                "itemName": "Bachelor of Climate and Environment"
            },
            {
                "id": 3769,
                "itemName": "BA in Applied Forensic Psychology"
            },
            {
                "id": 3770,
                "itemName": "Post-Baccalaureate Diploma in Enterprise IT Management"
            },
            {
                "id": 3771,
                "itemName": "BSc in Applied Forensic Psychology"
            },
            {
                "id": 3772,
                "itemName": "Bachelor of Theology"
            },
            {
                "id": 3773,
                "itemName": "Bachelor of Computer Science"
            },
            {
                "id": 3774,
                "itemName": "BA in Politics"
            },
            {
                "id": 3775,
                "itemName": "BA in Theater"
            },
            {
                "id": 3776,
                "itemName": "Bachelor of Kinesiology (with Nutrition, Biology or Psychology)"
            },
            {
                "id": 3777,
                "itemName": "Bachelor of Business Administration"
            },
            {
                "id": 3778,
                "itemName": "Bachelor of Applied Computer Science, Software Development Option"
            },
            {
                "id": 3779,
                "itemName": "BSc with option in Health Care"
            },
            {
                "id": 3780,
                "itemName": "Bachelor of Business Administration with major in French"
            },
            {
                "id": 3781,
                "itemName": "Bachelor of Business Administration with major in German"
            },
            {
                "id": 3782,
                "itemName": "Bachelor of Business Administration with major in Spanish"
            },
            {
                "id": 3783,
                "itemName": "Bachelor of Science in Nutrition (BSN)"
            },
            {
                "id": 3784,
                "itemName": "Bachelor of Applied Science"
            },
            {
                "id": 3785,
                "itemName": "Bachelor of Computer Science with Honours"
            },
            {
                "id": 3786,
                "itemName": "BSc in Environmental Geoscience"
            },
            {
                "id": 3787,
                "itemName": "BA in Environmental and Sustainability Studies"
            },
            {
                "id": 3788,
                "itemName": "Bachelor of Music Therapy"
            },
            {
                "id": 3789,
                "itemName": "Bachelor of Business Administration with major in Employment Relations"
            },
            {
                "id": 3790,
                "itemName": "Bachelor of Business Administration with major in Finance"
            },
            {
                "id": 3791,
                "itemName": "Bachelor of Business Administration with major in Accounting"
            },
            {
                "id": 3792,
                "itemName": "Bachelor of Business Administration with major in Marketing"
            },
            {
                "id": 3793,
                "itemName": "Bachelor of Applied Computer Science, Mobile and Ubiquitous Computing Option"
            },
            {
                "id": 3794,
                "itemName": "Bachelor of Applied Computer Science, Game Development Option"
            },
            {
                "id": 3795,
                "itemName": "Bachelor of Business Administration with major in Business Technology Management"
            },
            {
                "id": 3796,
                "itemName": "Bachelor of Business Administration with major in Entrepreneurship and Innovation"
            },
            {
                "id": 3797,
                "itemName": "Bachelor of Community Development"
            },
            {
                "id": 3798,
                "itemName": "Bachelor of Community Development with Environmental and Sustainability Studies"
            },
            {
                "id": 3799,
                "itemName": "Bachelor of Music with concentration in Education"
            },
            {
                "id": 3800,
                "itemName": "Bachelor of Business Administration with major in English"
            },
            {
                "id": 3801,
                "itemName": "Bachelor of Community Development with Psychology"
            },
            {
                "id": 3802,
                "itemName": "Integrated BSc in Mathematics & Statistics and Bachelor of Education (5-yr)"
            },
            {
                "id": 3803,
                "itemName": "Bachelor of Arts in Music"
            },
            {
                "id": 3804,
                "itemName": "Bachelor of Applied Computer Science, Data Analytics Option"
            },
            {
                "id": 3805,
                "itemName": "Bachelor of Applied Computer Science with Interdisciplinary Study"
            },
            {
                "id": 3806,
                "itemName": "Bachelor of Applied Computer Science with Second Major"
            },
            {
                "id": 3807,
                "itemName": "BSC in biochemistry"
            },
            {
                "id": 3808,
                "itemName": "BA in American studies"
            },
            {
                "id": 3809,
                "itemName": "BA in drama"
            },
            {
                "id": 3810,
                "itemName": "BSc in cognitive sciences"
            },
            {
                "id": 3811,
                "itemName": "Bachelor of fine arts"
            },
            {
                "id": 3812,
                "itemName": "BA in mathematics"
            },
            {
                "id": 3813,
                "itemName": "Bachelor of commerce with honours in economics"
            },
            {
                "id": 3814,
                "itemName": "BA in environmental studies"
            },
            {
                "id": 3815,
                "itemName": "BA in commerce"
            },
            {
                "id": 3816,
                "itemName": "BA in fine arts"
            },
            {
                "id": 3817,
                "itemName": "BA in modern languages, literatures, and cultures"
            },
            {
                "id": 3818,
                "itemName": "BA in Canadian Public Policy"
            },
            {
                "id": 3819,
                "itemName": "BSc in Aviation"
            },
            {
                "id": 3820,
                "itemName": "Minor in Japanese Studies"
            },
            {
                "id": 3821,
                "itemName": "Minor in astronomy"
            },
            {
                "id": 3822,
                "itemName": "Minor in geographic information systems"
            },
            {
                "id": 3823,
                "itemName": "Minor in international economics and business"
            },
            {
                "id": 3824,
                "itemName": "Minor in women's and gender studies"
            },
            {
                "id": 3825,
                "itemName": "BA in philosophy, politics and economics"
            },
            {
                "id": 3826,
                "itemName": "Minor in Museum and Curatorial Studies"
            },
            {
                "id": 3827,
                "itemName": "BA in women’s and gender studies"
            },
            {
                "id": 3828,
                "itemName": "BA/BSc"
            },
            {
                "id": 3829,
                "itemName": "Bachelor of Medical Laboratory Science"
            },
            {
                "id": 3830,
                "itemName": "Bachelor of Recreation and Sports Studies"
            },
            {
                "id": 3831,
                "itemName": "BA in Comparative and Cultural Studies"
            },
            {
                "id": 3832,
                "itemName": "Bachelor of Science in Geomatics Engineering"
            },
            {
                "id": 3833,
                "itemName": "Bachelor of Education for First Nations Students"
            },
            {
                "id": 3834,
                "itemName": "Bachelor of Education in Adult Education (4-Year)"
            },
            {
                "id": 3835,
                "itemName": "BAA Bachelor of Applied Arts in Craft and Design"
            },
            {
                "id": 3836,
                "itemName": "BA in Media Art & Cultures (MACC)"
            },
            {
                "id": 3837,
                "itemName": "BSc in Environmental Geochemistry"
            },
            {
                "id": 3838,
                "itemName": "Bachelor of Nursing"
            },
            {
                "id": 3839,
                "itemName": "Bachelor of Science in Software Engineering"
            },
            {
                "id": 3840,
                "itemName": "Bachelor of Science in Geological Engineering"
            },
            {
                "id": 3841,
                "itemName": "BBA with departmental Major"
            },
            {
                "id": 3842,
                "itemName": "Bachelor of Education (School Years Pattern)"
            },
            {
                "id": 3843,
                "itemName": "BSc in General Science"
            },
            {
                "id": 3844,
                "itemName": "Bachelor of Science in Environment and Natural Resources"
            },
            {
                "id": 3845,
                "itemName": "BBA with Honours in a major subject"
            },
            {
                "id": 3846,
                "itemName": "BA/BCS"
            },
            {
                "id": 3847,
                "itemName": "BA/Bachelor of Computer Science (BCS)"
            },
            {
                "id": 3848,
                "itemName": "BSc in Math"
            },
            {
                "id": 3849,
                "itemName": "BCS/BSc"
            },
            {
                "id": 3850,
                "itemName": "BAS"
            },
            {
                "id": 3851,
                "itemName": "BSc in Biology/Chemistry"
            },
            {
                "id": 3852,
                "itemName": "BSc in Biology/Physics"
            },
            {
                "id": 3853,
                "itemName": "BSc in Biology/Math-Stats"
            },
            {
                "id": 3854,
                "itemName": "BSc in Chemistry/Physics"
            },
            {
                "id": 3855,
                "itemName": "BSc in Earth Sciences/Economics"
            },
            {
                "id": 3856,
                "itemName": "BSc in Earth Sciences/Physics"
            },
            {
                "id": 3857,
                "itemName": "BSc in Math-Stats/Economics"
            },
            {
                "id": 3858,
                "itemName": "BSc in Biology-Psychology"
            },
            {
                "id": 3859,
                "itemName": "Bachelor of Health Sciences in Radiation Therapy"
            },
            {
                "id": 3860,
                "itemName": "Bachelor of Business Administration with concentration"
            },
            {
                "id": 3861,
                "itemName": "Bachelor of Applied Management"
            },
            {
                "id": 3862,
                "itemName": "BA minor"
            },
            {
                "id": 3863,
                "itemName": "BSc in Minors"
            },
            {
                "id": 3864,
                "itemName": "Bachelor of Science in Computer Science (BScCS)"
            },
            {
                "id": 3865,
                "itemName": "BSc in Engineering"
            },
            {
                "id": 3866,
                "itemName": "BA or BBA in Law in Society (double major/joint honours/minor)"
            },
            {
                "id": 3867,
                "itemName": "BSc in Applied Physics"
            },
            {
                "id": 3868,
                "itemName": "Bachelor of Integrated Studies"
            },
            {
                "id": 3869,
                "itemName": "BSc in Medicinal Chemistry"
            },
            {
                "id": 3870,
                "itemName": "Business Administration Certificate Level II"
            },
            {
                "id": 3871,
                "itemName": "BA in Gender and Women's Studies"
            },
            {
                "id": 3872,
                "itemName": "BSc in Mathematics-Physics"
            },
            {
                "id": 3873,
                "itemName": "BA/Bachelor of Education"
            },
            {
                "id": 3874,
                "itemName": "BA in Economics Studies"
            },
            {
                "id": 3875,
                "itemName": "BA in Media Art & Cultures (MAAC) (Film Production)"
            },
            {
                "id": 3876,
                "itemName": "BA in Neuroscience"
            },
            {
                "id": 3877,
                "itemName": "BA in Law in Society"
            },
            {
                "id": 3878,
                "itemName": "Bachelor of Arts & Science (BAS)"
            },
            {
                "id": 3879,
                "itemName": "BSc/Bachelor of Computer Science BCS"
            },
            {
                "id": 3880,
                "itemName": "Bachelor of Geomatics"
            },
            {
                "id": 3881,
                "itemName": "BA in Psychology (Neuroscience)"
            },
            {
                "id": 3882,
                "itemName": "Bachelor of Philosophy in Interdisciplinary Leadership"
            },
            {
                "id": 3883,
                "itemName": "Bachelor of Applied Management - Accounting (BAMAC)"
            },
            {
                "id": 3884,
                "itemName": "Bachelor of Applied Management - Electronic Commerce (BAMEC)"
            },
            {
                "id": 3885,
                "itemName": "Bachelor of Applied Management - Hospitality & Tourism (BAMHT)"
            },
            {
                "id": 3886,
                "itemName": "BBA in Accounting"
            },
            {
                "id": 3887,
                "itemName": "BBA in Electronic Commerce"
            },
            {
                "id": 3888,
                "itemName": "BBA in Human Resource Management\\"
            },
            {
                "id": 3889,
                "itemName": "BBA in Marketing"
            },
            {
                "id": 3890,
                "itemName": "Bachelor of Health Sciences in Radiography"
            },
            {
                "id": 3891,
                "itemName": "Bachelor in Health Sciences in Respiratory Therapy"
            },
            {
                "id": 3892,
                "itemName": "Licensed Practical Nurse Bridge"
            },
            {
                "id": 3893,
                "itemName": "BSc in Mathematics-Economics"
            },
            {
                "id": 3894,
                "itemName": "BSc in Marine Biology"
            },
            {
                "id": 3895,
                "itemName": "Bachelor of Health"
            },
            {
                "id": 3896,
                "itemName": "Bachelor of Commerce (Honours) - Commerce"
            },
            {
                "id": 3897,
                "itemName": "Bachelor of Commerce (Honours) - Commerce - Bridge"
            },
            {
                "id": 3898,
                "itemName": "Bachelor of Information Technology (Honours) - Networking and Information Technology Security"
            },
            {
                "id": 3899,
                "itemName": "Bachelor of Arts (Honours) - Criminology and Justice"
            },
            {
                "id": 3900,
                "itemName": "Bachelor of Arts (Honours) - Criminology and Justice - Advanced Entry (Comprehensive)"
            },
            {
                "id": 3901,
                "itemName": "Bachelor of Education - Education, Consecutive program, Intermediate/Senior program"
            },
            {
                "id": 3902,
                "itemName": "Bachelor of Education - Education, Consecutive program, Primary/Junior program"
            },
            {
                "id": 3903,
                "itemName": "Bachelor of Engineering (Honours) - Automotive Engineering"
            },
            {
                "id": 3904,
                "itemName": "Bachelor of Engineering (Honours) - Electrical Engineering"
            },
            {
                "id": 3905,
                "itemName": "Bachelor of Engineering (Honours) - Manufacturing Engineering"
            },
            {
                "id": 3906,
                "itemName": "Bachelor of Engineering (Honours) - Mechanical Engineering"
            },
            {
                "id": 3907,
                "itemName": "Bachelor of Engineering (Honours) - Software Engineering"
            },
            {
                "id": 3908,
                "itemName": "Bachelor of Health Sciences (Honours) - Health Sciences"
            },
            {
                "id": 3909,
                "itemName": "Bachelor of Health Science (Honours) - Medical Laboratory Science"
            },
            {
                "id": 3910,
                "itemName": "Bachelor of Science in Nursing (Honours) - Nursing - Collaborative"
            },
            {
                "id": 3911,
                "itemName": "Bachelor of Engineering and Management (Honours) - Electrical Engineering"
            },
            {
                "id": 3912,
                "itemName": "Bachelor of Science in Nursing (Honours) - Post Registered Practical Nurse (RPN) Bridge"
            },
            {
                "id": 3913,
                "itemName": "Bachelor of Science (Honours) - Applied and Industrial Mathematics"
            },
            {
                "id": 3914,
                "itemName": "Bachelor of Science (Honours) - Biological Science"
            },
            {
                "id": 3915,
                "itemName": "Bachelor of Science (Honours) - Chemistry"
            },
            {
                "id": 3916,
                "itemName": "Bachelor of Science (Honours) - Computer Science"
            },
            {
                "id": 3917,
                "itemName": "Bachelor of Science (Honours) - Forensic Science"
            },
            {
                "id": 3918,
                "itemName": "Bachelor of Science (Honours) - Physics"
            },
            {
                "id": 3919,
                "itemName": "Bachelor of Engineering (Honours) - Nuclear Engineering"
            },
            {
                "id": 3920,
                "itemName": "Bachelor of Science (Honours) - Health Physics and Radiation Science"
            },
            {
                "id": 3921,
                "itemName": "Bachelor of Engineering and Management (Honours) - Automotive Engineering"
            },
            {
                "id": 3922,
                "itemName": "Bachelor of Engineering and Management (Honours) - Mechanical Engineering"
            },
            {
                "id": 3923,
                "itemName": "Bachelor of Engineering and Management (Honours) - Manufacturing Engineering"
            },
            {
                "id": 3924,
                "itemName": "Bachelor of Information Technology (Honours) - Game Development and Entrepreneurship"
            },
            {
                "id": 3925,
                "itemName": "Bachelor of Arts (Honours) - Legal Studies"
            },
            {
                "id": 3926,
                "itemName": "Bachelor of Science (Honours) - Life Sciences"
            },
            {
                "id": 3927,
                "itemName": "Bachelor of Science and Management (Honours) - Biological Science"
            },
            {
                "id": 3928,
                "itemName": "Bachelor of Science and Management (Honours) - Life Sciences"
            },
            {
                "id": 3929,
                "itemName": "Bachelor of Engineering and Management (Honours) - Nuclear Engineering"
            },
            {
                "id": 3930,
                "itemName": "Bachelor of Allied Health Sciences (Honours) - Allied Health Sciences - Bridge"
            },
            {
                "id": 3931,
                "itemName": "Bachelor of Arts (Honours) - Legal Studies - Advanced Entry"
            },
            {
                "id": 3932,
                "itemName": "Bachelor of Engineering and Management (Honours) - Software Engineering"
            },
            {
                "id": 3933,
                "itemName": "Bachelor of Science (Honours) - Pharmaceutical Chemistry"
            },
            {
                "id": 3934,
                "itemName": "Bachelor of Information Technology (Honours) - Information Technology Security - Bridge"
            },
            {
                "id": 3935,
                "itemName": "Bachelor of Information Technology (Honours) - Game Development and Entrepreneurship - Bridge"
            },
            {
                "id": 3936,
                "itemName": "Bachelor of Arts (Honours) - Communication and Digital Media Studies"
            },
            {
                "id": 3937,
                "itemName": "Bachelor of Arts (Honours) - Forensic Psychology"
            },
            {
                "id": 3938,
                "itemName": "Bachelor of Arts (Honours) - Political Science"
            },
            {
                "id": 3939,
                "itemName": "Bachelor of Health Science (Honours) - Kinesiology"
            },
            {
                "id": 3940,
                "itemName": "Bachelor of Commerce (Honours) - Commerce - Advanced Entry"
            },
            {
                "id": 3941,
                "itemName": "Bachelor of Arts (Honours) in Educational Studies and Digital Technology - Advanced Entry"
            },
            {
                "id": 3942,
                "itemName": "Bachelor of Arts (Honours) - Communication and Digital Media Studies - Advanced Entry"
            },
            {
                "id": 3943,
                "itemName": "Bachelor of Science (Honours) - Biological Science - Advanced Entry"
            },
            {
                "id": 3944,
                "itemName": "Bachelor of Health Science (Honours) - Kinesiology Advanced Entry - Fitness and Health Promotion"
            },
            {
                "id": 3945,
                "itemName": "Bachelor of Science and Management (Honours) - Applied and Industrial Mathematics"
            },
            {
                "id": 3946,
                "itemName": "Bachelor of Science and Management (Honours) - Chemistry"
            },
            {
                "id": 3947,
                "itemName": "Bachelor of Science and Management (Honours) - Computer Science"
            },
            {
                "id": 3948,
                "itemName": "Bachelor of Science and Management (Honours) - Forensic Science"
            },
            {
                "id": 3949,
                "itemName": "Bachelor of Science and Management (Honours) - Pharmaceutical Chemistry"
            },
            {
                "id": 3950,
                "itemName": "Bachelor of Science and Management (Honours) - Physics"
            },
            {
                "id": 3951,
                "itemName": "Bachelor of Information Technology (Honours) - Networking and Information Technology Security - Advanced Entry"
            },
            {
                "id": 3952,
                "itemName": "Bachelor of Science (Honours) - Computer Science - Advanced Entry"
            },
            {
                "id": 3953,
                "itemName": "Bachelor of Arts (Honours) - Forensic Psychology - Advanced Entry"
            },
            {
                "id": 3954,
                "itemName": "Bachelor of Engineering (Honours) - Mechatronics Engineering"
            },
            {
                "id": 3955,
                "itemName": "Bachelor of Engineering and Management (Honours) - Mechatronics Engineering"
            },
            {
                "id": 3956,
                "itemName": "Bachelor of Arts (Honours) - Political Science - Advanced Entry"
            },
            {
                "id": 3957,
                "itemName": "Undergraduate Diploma - Designing Adult Learning for the Digital Age"
            },
            {
                "id": 3958,
                "itemName": "Bachelor of Health Sciences (Honours) - Medical Laboratory Science Bridge"
            },
            {
                "id": 3959,
                "itemName": "Bachelor of Science (Honours) - Chemical Biology"
            },
            {
                "id": 3960,
                "itemName": "Bachelor of Information Technology (Honours) - Technology Management"
            },
            {
                "id": 3961,
                "itemName": "Bachelor of Technology (Honours) - Sustainable Energy Systems"
            },
            {
                "id": 3962,
                "itemName": "Bachelor of Science (Honours) - Environmental Biology"
            },
            {
                "id": 3963,
                "itemName": "Bachelor of Arts (Honours) - Liberal Studies"
            },
            {
                "id": 3964,
                "itemName": "Bachelor of Technology (Honours) - Sustainable Energy Systems - Bridge"
            },
            {
                "id": 3965,
                "itemName": "Bachelor of Health Science (Honours) - Kinesiology Advanced Entry - OTA and PTA"
            },
            {
                "id": 3966,
                "itemName": "Bachelor of Science (Honours) - Data Science"
            },
            {
                "id": 3967,
                "itemName": "Bachelor of Science (Honours) - Integrative Neuroscience"
            },
            {
                "id": 3968,
                "itemName": "Bachelor of Science and Management (Honours) - Integrative Neuroscience"
            },
            {
                "id": 3969,
                "itemName": "Bachelor of Arts (Honours) - Psychology"
            },
            {
                "id": 3970,
                "itemName": "Bachelor of Science (Honours) - Psychology"
            },
            {
                "id": 3971,
                "itemName": "Undergraduate Diploma - Teaching English to Speakers of Other Languages (TESOL)"
            },
            {
                "id": 3972,
                "itemName": "Bachelor of Engineering (Honours) – Comprehensive Engineering"
            },
            {
                "id": 3973,
                "itemName": "Bachelor of Science (Honours) - Integrated Mathematics and Computer Science"
            },
            {
                "id": 3974,
                "itemName": "Bachelor of Science and Management (Honours) - Astrophysics"
            },
            {
                "id": 3975,
                "itemName": "Bachelor of Science and Management (Honours) – Data Science"
            },
            {
                "id": 3976,
                "itemName": "Bachelor of Science and Management (Honours) – Environmental Biology"
            },
            {
                "id": 3977,
                "itemName": "Bachelor of Science and Management (Honours) – Nanotechnology and Clean Energy "
            },
            {
                "id": 3978,
                "itemName": "BA in Popular Culture"
            },
            {
                "id": 3979,
                "itemName": "BA in Hispanic and Latin American Studies"
            },
            {
                "id": 3980,
                "itemName": "BA in Tourism and Environment"
            },
            {
                "id": 3981,
                "itemName": "Bachelor of Business Economics (BBE)"
            },
            {
                "id": 3982,
                "itemName": "BA in General Humanities"
            },
            {
                "id": 3983,
                "itemName": "BA in Studio Art"
            },
            {
                "id": 3984,
                "itemName": "BA in Child and Youth Studies"
            },
            {
                "id": 3985,
                "itemName": "BA in Labour Studies"
            },
            {
                "id": 3986,
                "itemName": "BA in Canadian Studies (3-year)"
            },
            {
                "id": 3987,
                "itemName": "BA in History of Art and Visual Culture"
            },
            {
                "id": 3988,
                "itemName": "BEd in Aboriginal Adult Education"
            },
            {
                "id": 3989,
                "itemName": "BA in Child and Youth Studies/Bachelor of Education"
            },
            {
                "id": 3990,
                "itemName": "BEd Primary/Junior"
            },
            {
                "id": 3991,
                "itemName": "BEd in Adult Education"
            },
            {
                "id": 3992,
                "itemName": "BEd in Technological Education"
            },
            {
                "id": 3993,
                "itemName": "Bachelor of Physical Education (BPhEd)/Bachelor of Education (BEd)"
            },
            {
                "id": 3994,
                "itemName": "Bachelor of Business Administration (BBA)"
            },
            {
                "id": 3995,
                "itemName": "Bachelor of Science (BSc)"
            },
            {
                "id": 3996,
                "itemName": "BSc in Oenology and Viticulture (Honours)"
            },
            {
                "id": 3997,
                "itemName": "BSc in Computing and Solid State Device Technology"
            },
            {
                "id": 3998,
                "itemName": "BSc in Neuroscience"
            },
            {
                "id": 3999,
                "itemName": "BSc in Biological Sciences"
            },
            {
                "id": 4000,
                "itemName": "Bachelor of Sport Management (BSM)"
            },
            {
                "id": 4001,
                "itemName": "BSc in Medical Sciences"
            },
            {
                "id": 4002,
                "itemName": "Bachelor of Physical Education (BPhEd)"
            },
            {
                "id": 4003,
                "itemName": "Bachelor of Recreation and Leisure Studies (BRLS) (3-year)"
            },
            {
                "id": 4004,
                "itemName": "Bachelor of Recreation and Leisure Studies (BRLS)"
            },
            {
                "id": 4005,
                "itemName": "BA in Child Health"
            },
            {
                "id": 4006,
                "itemName": "Bachelor of Kinesiology (BKin)"
            },
            {
                "id": 4007,
                "itemName": "Bachelor of Science in Nursing (BScN)"
            },
            {
                "id": 4008,
                "itemName": "BEd Junior/Intermediate"
            },
            {
                "id": 4009,
                "itemName": "BEd Intermediate/Senior"
            },
            {
                "id": 4010,
                "itemName": "BA in Integrated Studies (Honours)/Bachelor of Education"
            },
            {
                "id": 4011,
                "itemName": "BSc in Integrated Studies/Bachelor of Education"
            },
            {
                "id": 4012,
                "itemName": "BSc/BEd"
            },
            {
                "id": 4013,
                "itemName": "BA in Dramatic Arts"
            },
            {
                "id": 4014,
                "itemName": "Minor in Intercultural Studies"
            },
            {
                "id": 4015,
                "itemName": "BA in Studies in Arts and Culture"
            },
            {
                "id": 4016,
                "itemName": "BSc in Biomedical Sciences"
            },
            {
                "id": 4017,
                "itemName": "Bachelor of Computing and Business (BCB)"
            },
            {
                "id": 4018,
                "itemName": "BSc in Computer Science and Mathematics"
            },
            {
                "id": 4019,
                "itemName": "BA in Human Geography"
            },
            {
                "id": 4020,
                "itemName": "BA in International Political Economy"
            },
            {
                "id": 4021,
                "itemName": "BA in Applied Economic Analysis"
            },
            {
                "id": 4022,
                "itemName": "BSc in Computing and Network Communications"
            },
            {
                "id": 4023,
                "itemName": "Bachelor of Accounting (BAcc)"
            },
            {
                "id": 4024,
                "itemName": "BA in Interactive Arts and Science"
            },
            {
                "id": 4025,
                "itemName": "BA in Medieval and Renaissance Studies"
            },
            {
                "id": 4026,
                "itemName": "Minor in Applied Computing"
            },
            {
                "id": 4027,
                "itemName": "Bachelor of Public Health (BPH)"
            },
            {
                "id": 4028,
                "itemName": "Business Administration (BBA) International Dual Degree Co-op"
            },
            {
                "id": 4029,
                "itemName": "BA in Game Design"
            },
            {
                "id": 4030,
                "itemName": "BA in Studies in Comparative Literatures and Cultures"
            },
            {
                "id": 4031,
                "itemName": "BSc in Game Programming"
            },
            {
                "id": 4032,
                "itemName": "BA in Business Communication"
            },
            {
                "id": 4033,
                "itemName": "BSc in Physical Geography"
            },
            {
                "id": 4034,
                "itemName": "BA in Media and Communication Studies"
            },
            {
                "id": 4035,
                "itemName": "BA in Social Sciences"
            },
            {
                "id": 4036,
                "itemName": "Minor in Environmental Sustainability"
            },
            {
                "id": 4037,
                "itemName": "Bachelor of Kinesiology (BKin) and Master of Professional Kinesiology (MPK)"
            },
            {
                "id": 4038,
                "itemName": "Bachelor of Public Health (BPH) and Master of Public Health (MPH)"
            },
            {
                "id": 4039,
                "itemName": "Minor in Engineering Science"
            },
            {
                "id": 4040,
                "itemName": "BA in Critical Criminology"
            },
            {
                "id": 4041,
                "itemName": "BA with major in Atlantic Canada Studies"
            },
            {
                "id": 4042,
                "itemName": "BA with major in International Development Studies"
            },
            {
                "id": 4043,
                "itemName": "BA with major in Mathematics"
            },
            {
                "id": 4044,
                "itemName": "BA with major in Sociology"
            },
            {
                "id": 4045,
                "itemName": "BA with major in Religious Studies"
            },
            {
                "id": 4046,
                "itemName": "BA with major in Psychology"
            },
            {
                "id": 4047,
                "itemName": "BA with major in Geography"
            },
            {
                "id": 4048,
                "itemName": "BA with major in Political Science"
            },
            {
                "id": 4049,
                "itemName": "BA with major in Philosophy"
            },
            {
                "id": 4050,
                "itemName": "BA with major in History"
            },
            {
                "id": 4051,
                "itemName": "BA with major in English"
            },
            {
                "id": 4052,
                "itemName": "BA with major in Economics"
            },
            {
                "id": 4053,
                "itemName": "BA with major in Asian Studies"
            },
            {
                "id": 4054,
                "itemName": "BA with major in Anthropology"
            },
            {
                "id": 4055,
                "itemName": "BA General (Double Arts Concentrations)"
            },
            {
                "id": 4056,
                "itemName": "BA with major in Criminology"
            },
            {
                "id": 4057,
                "itemName": "BComm with major in General Business Studies"
            },
            {
                "id": 4058,
                "itemName": "BComm with major in Economics"
            },
            {
                "id": 4059,
                "itemName": "BComm with major in Global Business Management"
            },
            {
                "id": 4060,
                "itemName": "BComm with major in Accounting"
            },
            {
                "id": 4061,
                "itemName": "BComm with major in Entrepreneurship"
            },
            {
                "id": 4062,
                "itemName": "BComm with major in Computing and Information Systems"
            },
            {
                "id": 4063,
                "itemName": "BComm with major in Finance"
            },
            {
                "id": 4064,
                "itemName": "BComm with major in Marketing"
            },
            {
                "id": 4065,
                "itemName": "BComm with major in Management"
            },
            {
                "id": 4066,
                "itemName": "BComm with major in Human Resource Management and Industrial Relations"
            },
            {
                "id": 4067,
                "itemName": "BComm with honours in Economics"
            },
            {
                "id": 4068,
                "itemName": "BA with major in Classics"
            },
            {
                "id": 4069,
                "itemName": "BA with major in French"
            },
            {
                "id": 4070,
                "itemName": "BA with major in Irish Studies"
            },
            {
                "id": 4071,
                "itemName": "BA with major in Linguistics"
            },
            {
                "id": 4072,
                "itemName": "BA with major in Spanish and Hispanic Culture"
            },
            {
                "id": 4073,
                "itemName": "BA with honours in Atlantic Canada Studies"
            },
            {
                "id": 4074,
                "itemName": "BA with honours in Anthropology"
            },
            {
                "id": 4075,
                "itemName": "BA with honours in Asian Studies"
            },
            {
                "id": 4076,
                "itemName": "BA with honours in Criminology"
            },
            {
                "id": 4077,
                "itemName": "BA with honours in Economics"
            },
            {
                "id": 4078,
                "itemName": "BA with honours in English"
            },
            {
                "id": 4079,
                "itemName": "BA with honours in French"
            },
            {
                "id": 4080,
                "itemName": "BA with honours in Geography"
            },
            {
                "id": 4081,
                "itemName": "BA with honours in History"
            },
            {
                "id": 4082,
                "itemName": "BA with honours in International Development Studies"
            },
            {
                "id": 4083,
                "itemName": "BA with honours in Linguistics"
            },
            {
                "id": 4084,
                "itemName": "BA with honours in Mathematics"
            },
            {
                "id": 4085,
                "itemName": "BA with honours in Philosophy"
            },
            {
                "id": 4086,
                "itemName": "BA with honours in Political Science"
            },
            {
                "id": 4087,
                "itemName": "BA with honours in Psychology"
            },
            {
                "id": 4088,
                "itemName": "BA with honours in Religious Studies"
            },
            {
                "id": 4089,
                "itemName": "BA with honours in Sociology"
            },
            {
                "id": 4090,
                "itemName": "Bachelor of Science - General (With a Concentration)"
            },
            {
                "id": 4091,
                "itemName": "BSc with major and Diploma in Engineering"
            },
            {
                "id": 4092,
                "itemName": "BSc with honours in Astrophysics"
            },
            {
                "id": 4093,
                "itemName": "BSc with honours in Biology"
            },
            {
                "id": 4094,
                "itemName": "BSc with honours in Chemistry"
            },
            {
                "id": 4095,
                "itemName": "BSc with honours in Computing Science"
            },
            {
                "id": 4096,
                "itemName": "BSc with honours in Environmental Science"
            },
            {
                "id": 4097,
                "itemName": "BSc with honours in Geology"
            },
            {
                "id": 4098,
                "itemName": "BSc with honours in Geography"
            },
            {
                "id": 4099,
                "itemName": "BSc with honours in Mathematics"
            },
            {
                "id": 4100,
                "itemName": "BSc with honours in Physics"
            },
            {
                "id": 4101,
                "itemName": "BSc with honours in Psychology"
            },
            {
                "id": 4102,
                "itemName": "BSc with major in Astrophysics"
            },
            {
                "id": 4103,
                "itemName": "BSc with major in Biology"
            },
            {
                "id": 4104,
                "itemName": "BSc with major in Chemistry"
            },
            {
                "id": 4105,
                "itemName": "BSc with major in Computing Science"
            },
            {
                "id": 4106,
                "itemName": "BSc with major in Computing Science/Business Administration"
            },
            {
                "id": 4107,
                "itemName": "BSc with major in Environmental Science"
            },
            {
                "id": 4108,
                "itemName": "BSc with major in Geology"
            },
            {
                "id": 4109,
                "itemName": "BSc with major in Geography"
            },
            {
                "id": 4110,
                "itemName": "BSc with major in Mathematics"
            },
            {
                "id": 4111,
                "itemName": "BSc with major in Physics"
            },
            {
                "id": 4112,
                "itemName": "BSc with major in Psychology"
            },
            {
                "id": 4113,
                "itemName": "BSC with honours Geology/Geography"
            },
            {
                "id": 4114,
                "itemName": "BSC with honours Geology/Business Studies"
            },
            {
                "id": 4115,
                "itemName": "BSC with major Geology/Geography"
            },
            {
                "id": 4116,
                "itemName": "BSC with major Geology/Business Studies"
            },
            {
                "id": 4117,
                "itemName": "BComm without major"
            },
            {
                "id": 4118,
                "itemName": "BA with major in Entrepreneurship"
            },
            {
                "id": 4119,
                "itemName": "Bachelor of Environmental Studies"
            },
            {
                "id": 4120,
                "itemName": "BSc (General) with Diploma in Engineering"
            },
            {
                "id": 4121,
                "itemName": "BA with honours in Social Justice & Community Studies"
            },
            {
                "id": 4122,
                "itemName": "BA with major in Social Justice & Community Studies"
            },
            {
                "id": 4123,
                "itemName": "Bachelor of Arts in French"
            },
            {
                "id": 4124,
                "itemName": "Bachelor of Arts in Kinesiology"
            },
            {
                "id": 4125,
                "itemName": "Bachelor of Arts in Political Science"
            },
            {
                "id": 4126,
                "itemName": "Bachelor of Science in Mathematics"
            },
            {
                "id": 4127,
                "itemName": "Bachelor of Arts in Sociology"
            },
            {
                "id": 4128,
                "itemName": "Bachelor of Arts in Religious Studies"
            },
            {
                "id": 4129,
                "itemName": "Bachelor of Arts in Philosophy"
            },
            {
                "id": 4130,
                "itemName": "Bachelor of Arts in Indigenous Studies"
            },
            {
                "id": 4131,
                "itemName": "Bachelor of Arts in History"
            },
            {
                "id": 4132,
                "itemName": "Bachelor of Arts in English"
            },
            {
                "id": 4133,
                "itemName": "Bachelor of Arts in Economics"
            },
            {
                "id": 4134,
                "itemName": "Bachelor of Arts in Anthropology"
            },
            {
                "id": 4135,
                "itemName": "Bachelor of Science in Physics"
            },
            {
                "id": 4136,
                "itemName": "Bachelor of Science in Psychology"
            },
            {
                "id": 4137,
                "itemName": "Bachelor of Arts in Canadian Studies"
            },
            {
                "id": 4138,
                "itemName": "Bachelor of Science in Biochemistry"
            },
            {
                "id": 4139,
                "itemName": "Bachelor of Science in Biological Sciences"
            },
            {
                "id": 4140,
                "itemName": "Bachelor of Arts in Psychology"
            },
            {
                "id": 4141,
                "itemName": "Bachelor of Arts in Geography"
            },
            {
                "id": 4142,
                "itemName": "Bachelor of Arts in Urban and Regional Studies"
            },
            {
                "id": 4143,
                "itemName": "Bachelor of Science in Sciences (General major)"
            },
            {
                "id": 4144,
                "itemName": "Bachelor of Arts and Science"
            },
            {
                "id": 4145,
                "itemName": "Bachelor of Arts in Humanities (General major)"
            },
            {
                "id": 4146,
                "itemName": "Bachelor of Science in Agricultural Studies"
            },
            {
                "id": 4147,
                "itemName": "Bachelor of Science in Agricultural Biotechnology"
            },
            {
                "id": 4148,
                "itemName": "Bachelor of Science in Geography"
            },
            {
                "id": 4149,
                "itemName": "Bachelor of Science in Environmental Science"
            },
            {
                "id": 4150,
                "itemName": "Bachelor of Education in Social Studies Education/Bachelor of Arts"
            },
            {
                "id": 4151,
                "itemName": "Bachelor of Education (After Degree)"
            },
            {
                "id": 4152,
                "itemName": "Bachelor of Education in Science Education/Bachelor of Science"
            },
            {
                "id": 4153,
                "itemName": "Bachelor of Management in International Management"
            },
            {
                "id": 4154,
                "itemName": "Bachelor of Fine Arts - New Media"
            },
            {
                "id": 4155,
                "itemName": "Bachelor of Fine Arts - Multidisciplinary"
            },
            {
                "id": 4156,
                "itemName": "Bachelor of Fine Arts - Dramatic Arts"
            },
            {
                "id": 4157,
                "itemName": "Bachelor of Fine Arts - Art"
            },
            {
                "id": 4158,
                "itemName": "Bachelor of Health Sciences in Addictions Counselling"
            },
            {
                "id": 4159,
                "itemName": "Bachelor of Management in General Management"
            },
            {
                "id": 4160,
                "itemName": "Bachelor of Management in Indigenous Governance and Business Management"
            },
            {
                "id": 4161,
                "itemName": "Bachelor of Management in Economics"
            },
            {
                "id": 4162,
                "itemName": "Bachelor of Management in Human Resource Management and Labour Relations"
            },
            {
                "id": 4163,
                "itemName": "Bachelor of Management in Finance"
            },
            {
                "id": 4164,
                "itemName": "Bachelor of Management in Accounting"
            },
            {
                "id": 4165,
                "itemName": "Bachelor of Management in Marketing"
            },
            {
                "id": 4166,
                "itemName": "Bachelor of Music in Music"
            },
            {
                "id": 4167,
                "itemName": "Bachelor of Arts in Dramatic Arts"
            },
            {
                "id": 4168,
                "itemName": "Bachelor of Science in Computer Science"
            },
            {
                "id": 4169,
                "itemName": "Bachelor of Arts in Social Sciences (General major)"
            },
            {
                "id": 4170,
                "itemName": "Bachelor of Science in Chemistry"
            },
            {
                "id": 4171,
                "itemName": "Bachelor of Arts in Agricultural Studies"
            },
            {
                "id": 4172,
                "itemName": "Bachelor of Arts in French/Spanish"
            },
            {
                "id": 4173,
                "itemName": "Bachelor of Science in Neuroscience"
            },
            {
                "id": 4174,
                "itemName": "Bachelor of Education in Modern Languages Education/Bachelor of Arts"
            },
            {
                "id": 4175,
                "itemName": "Bachelor of Education in English Language Arts Education/Bachelor of Arts"
            },
            {
                "id": 4176,
                "itemName": "Bachelor of Education in Mathematics Education/Bachelor of Science"
            },
            {
                "id": 4177,
                "itemName": "Bachelor of Education in Physical Education/Bachelor of Arts"
            },
            {
                "id": 4178,
                "itemName": "Bachelor of Education in Career and Technology Studies: Business Focus/Bachelor of Management"
            },
            {
                "id": 4179,
                "itemName": "Bachelor of Education in Drama Education/Bachelor of Fine Arts - Dramatic Arts"
            },
            {
                "id": 4180,
                "itemName": "Dentistry (pre-professional)"
            },
            {
                "id": 4181,
                "itemName": "Journalism (pre-professional)"
            },
            {
                "id": 4182,
                "itemName": "Law (pre-professional)"
            },
            {
                "id": 4183,
                "itemName": "Medicine (pre-professional)"
            },
            {
                "id": 4184,
                "itemName": "Social Work (pre-professional)"
            },
            {
                "id": 4185,
                "itemName": "Veterinary Medicine (pre-professional)"
            },
            {
                "id": 4186,
                "itemName": "Bachelor of Arts in Art"
            },
            {
                "id": 4187,
                "itemName": "Bachelor of Science in Computer Science and Geographical Information Science"
            },
            {
                "id": 4188,
                "itemName": "Bachelor of Education in Indigenous Education/Bachelor of Arts"
            },
            {
                "id": 4189,
                "itemName": "Bachelor of Education in Art Education/Bachelor of Fine Arts - Art"
            },
            {
                "id": 4190,
                "itemName": "Bachelor of Education in Art Education/Bachelor of Arts"
            },
            {
                "id": 4191,
                "itemName": "Bachelor of Education in Music Education/Bachelor of Music"
            },
            {
                "id": 4192,
                "itemName": "Bachelor of Education in Drama Education/Bachelor of Arts"
            },
            {
                "id": 4193,
                "itemName": "Bachelor of Arts in Archaeology and Geography"
            },
            {
                "id": 4194,
                "itemName": "Bachelor of Arts in Women and Gender Studies"
            },
            {
                "id": 4195,
                "itemName": "Bachelor of Science in Archaeology and Geography"
            },
            {
                "id": 4196,
                "itemName": "Bachelor of Management in Computer Science"
            },
            {
                "id": 4197,
                "itemName": "Bachelor of Education in Music Education/Bachelor of Arts"
            },
            {
                "id": 4198,
                "itemName": "Bachelor of Management in Political Science"
            },
            {
                "id": 4199,
                "itemName": "Bachelor of Music in Digital Audio Arts"
            },
            {
                "id": 4200,
                "itemName": "Bachelor of Health Sciences in Public Health"
            },
            {
                "id": 4201,
                "itemName": "Bachelor of Science in Remote Sensing"
            },
            {
                "id": 4202,
                "itemName": "Bachelor of Nursing After Degree"
            },
            {
                "id": 4203,
                "itemName": "Bachelor of Education in Physical Education/Bachelor of Science"
            },
            {
                "id": 4204,
                "itemName": "Bachelor of Fine Arts - Native American Art"
            },
            {
                "id": 4205,
                "itemName": "Bachelor of Science in Applied Statistics"
            },
            {
                "id": 4206,
                "itemName": "Pre-Professional Transfer Program in Engineering"
            },
            {
                "id": 4207,
                "itemName": "Bachelor of Education in Career and Technology Studies: New Media Focus/Bachelor  of Fine Arts - New Media"
            },
            {
                "id": 4208,
                "itemName": "Bachelor of Health Sciences in Aboriginal Health"
            },
            {
                "id": 4209,
                "itemName": "Bachelor of Therapeutic Recreation"
            },
            {
                "id": 4210,
                "itemName": "Bachelor of Management in Agricultural Enterprise Management"
            },
            {
                "id": 4211,
                "itemName": "Indigenous Student Success Cohort"
            },
            {
                "id": 4212,
                "itemName": "BEd in Secondary Education"
            },
            {
                "id": 4213,
                "itemName": "Bachelor of Music Education (BMusEd)/Bachelor of Music (BMus)/Bachelor of Music (BMus) Joint Degree Program"
            },
            {
                "id": 4214,
                "itemName": "BA (Honours) in Visual Arts"
            },
            {
                "id": 4215,
                "itemName": "BA in Media, Art, and Performance (Cultures of Display)"
            },
            {
                "id": 4216,
                "itemName": "Bachelor of Fine Arts (Visual Arts)"
            },
            {
                "id": 4217,
                "itemName": "Certificate in Advanced Studio Art"
            },
            {
                "id": 4218,
                "itemName": "BA (Honours) in Media, Art, and Performance (Music)"
            },
            {
                "id": 4219,
                "itemName": "BEd in Elementary Education"
            },
            {
                "id": 4220,
                "itemName": "Bachelor of Indigenous Elementary Education"
            },
            {
                "id": 4221,
                "itemName": "BEd Arts Education"
            },
            {
                "id": 4222,
                "itemName": "Bachelor of Music Education (BMusEd)/Bachelor of Music (BMUS) Joint Degree Program"
            },
            {
                "id": 4223,
                "itemName": "Bachelor of Applied Science in Petroleum Systems Engineering"
            },
            {
                "id": 4224,
                "itemName": "Bachelor of Applied Science in Industrial Systems Engineering"
            },
            {
                "id": 4225,
                "itemName": "Bachelor of Applied Science in Environmental Systems Engineering"
            },
            {
                "id": 4226,
                "itemName": "Bachelor of Applied Science in Electronic Systems Engineering"
            },
            {
                "id": 4227,
                "itemName": "BA (Major/Honours) in Women's & Gender Studies"
            },
            {
                "id": 4228,
                "itemName": "BA in Police Studies"
            },
            {
                "id": 4229,
                "itemName": "BA (Major/Honours) in Sociology"
            },
            {
                "id": 4230,
                "itemName": "BA (Major/Honours) in Political Science"
            },
            {
                "id": 4231,
                "itemName": "BA (Major/Honours) in Philosophy"
            },
            {
                "id": 4232,
                "itemName": "BA (Major/Honours) in English"
            },
            {
                "id": 4233,
                "itemName": "BA (Major/Honours) in Economics"
            },
            {
                "id": 4234,
                "itemName": "BA (Major/Honours) in Anthropology"
            },
            {
                "id": 4235,
                "itemName": "Bachelor of Journalism"
            },
            {
                "id": 4236,
                "itemName": "BA (Major/Honours) in Religious Studies"
            },
            {
                "id": 4237,
                "itemName": "BA (Major/Honours) in Psychology"
            },
            {
                "id": 4238,
                "itemName": "BA (Major/Honours) in History"
            },
            {
                "id": 4239,
                "itemName": "BA (Major/Honours) in Geography"
            },
            {
                "id": 4240,
                "itemName": "Bachelor of Human Justice"
            },
            {
                "id": 4241,
                "itemName": "Baccalauréat en éducation élémentaire, française"
            },
            {
                "id": 4242,
                "itemName": "BA in Japanese"
            },
            {
                "id": 4243,
                "itemName": "Bachelor of Applied Science in Software Systems Engineering"
            },
            {
                "id": 4244,
                "itemName": "BA in Theatre and Performance"
            },
            {
                "id": 4245,
                "itemName": "Bachelor of Medical Radiation Technology"
            },
            {
                "id": 4246,
                "itemName": "BSc in Actuarial Science"
            },
            {
                "id": 4247,
                "itemName": "BSc in Software Systems Development"
            },
            {
                "id": 4248,
                "itemName": "BA (Major/Honours) in International Studies"
            },
            {
                "id": 4249,
                "itemName": "Bachelor of Health Studies"
            },
            {
                "id": 4250,
                "itemName": "BEd Elementary (YNTEP)"
            },
            {
                "id": 4251,
                "itemName": "BEd After Degree (BEAD) in Elementary Education"
            },
            {
                "id": 4252,
                "itemName": "BEd After Degree (BEAD) in Secondary Education"
            },
            {
                "id": 4253,
                "itemName": "Baccalauréat en éducation élémentaire, française (BEAD)"
            },
            {
                "id": 4254,
                "itemName": "Baccalauréat en éducation secondaire, française"
            },
            {
                "id": 4255,
                "itemName": "Baccalauréat en éducation secondaire, française (BEAD)"
            },
            {
                "id": 4256,
                "itemName": "BEd After Degree (BEAD) Arts Education"
            },
            {
                "id": 4257,
                "itemName": "BSc in Chemical Technology"
            },
            {
                "id": 4258,
                "itemName": "Post-Diploma BSc in Computer Science"
            },
            {
                "id": 4259,
                "itemName": "BA (Honours) in Media, Art, and Performance (Film Studies)"
            },
            {
                "id": 4260,
                "itemName": "BA (Honours) in Media, Art, and Performance (Art History)"
            },
            {
                "id": 4261,
                "itemName": "Bachelor of Sports and Recreation Studies"
            },
            {
                "id": 4262,
                "itemName": "BSc (Honours) in Computer Science"
            },
            {
                "id": 4263,
                "itemName": "BSc (Honours) in Geography and Environmental Studies"
            },
            {
                "id": 4264,
                "itemName": "BSc (Honours) Combined Major in Computer Science and Mathematics"
            },
            {
                "id": 4265,
                "itemName": "BSc in Applied Mathematics and Statistics"
            },
            {
                "id": 4266,
                "itemName": "BSc Combined Major in Statistics and Economics"
            },
            {
                "id": 4267,
                "itemName": "Baccalauréat en Education"
            },
            {
                "id": 4268,
                "itemName": "Baccalauréat en éducation"
            },
            {
                "id": 4269,
                "itemName": "BSc (Major/Honours) in Economics"
            },
            {
                "id": 4270,
                "itemName": "BA in Visual Arts (3-Year Special)"
            },
            {
                "id": 4271,
                "itemName": "BA in Music (3-Year Special)"
            },
            {
                "id": 4272,
                "itemName": "BA in Classic and Medieval Studies"
            },
            {
                "id": 4273,
                "itemName": "BA in Cree Language Literacy"
            },
            {
                "id": 4274,
                "itemName": "BA (Major/Honours) in Indigenous Studies"
            },
            {
                "id": 4275,
                "itemName": "BA (Major/Honours) in Linguistics"
            },
            {
                "id": 4276,
                "itemName": "Bachelor of Indigenous Education Elementary After Degree"
            },
            {
                "id": 4277,
                "itemName": "Bachelor of Indigenous Education Secondary After Degree"
            },
            {
                "id": 4278,
                "itemName": "BEd Elementary (SUNTEP)"
            },
            {
                "id": 4279,
                "itemName": "Bachelor of Indigenous Secondary Education"
            },
            {
                "id": 4280,
                "itemName": "BEd/BSc Secondary Mathematics Program"
            },
            {
                "id": 4281,
                "itemName": "BEd/BKin Joint Degree Program"
            },
            {
                "id": 4282,
                "itemName": "BA in Indigenous Art"
            },
            {
                "id": 4283,
                "itemName": "BA in Indigenous Art History"
            },
            {
                "id": 4284,
                "itemName": "BA in Indigenous Art (3-Year Special)"
            },
            {
                "id": 4285,
                "itemName": "Bachelor of Fine Arts in Indigenous Art"
            },
            {
                "id": 4286,
                "itemName": "Bachelor of Education/Bachelor of Kinesiology Joint Degrees Program"
            },
            {
                "id": 4287,
                "itemName": "Bachelor of Kinesiology Honours"
            },
            {
                "id": 4288,
                "itemName": "Bachelor of Indigenous Social Work"
            },
            {
                "id": 4289,
                "itemName": "Bachelor of Geographic Information Science (BGISC)"
            },
            {
                "id": 4290,
                "itemName": "BA in Resource & Environmental Studies"
            },
            {
                "id": 4291,
                "itemName": "BA in Saulteaux Language Literacy"
            },
            {
                "id": 4292,
                "itemName": "BSc (Major/Honours) in Psychology"
            },
            {
                "id": 4293,
                "itemName": "BA Combined Major in Economics and Business Administration"
            },
            {
                "id": 4294,
                "itemName": "BA Combined Major in Economics and History"
            },
            {
                "id": 4295,
                "itemName": "BA Combined Major in Economics and Society"
            },
            {
                "id": 4296,
                "itemName": "Bachelor of Indigenous Education Elementary Cree Immersion"
            },
            {
                "id": 4297,
                "itemName": "BA in Fine Arts (Film Production)"
            },
            {
                "id": 4298,
                "itemName": "BA in Media, Art, and Performance Major, Creative Technologies Concentration"
            },
            {
                "id": 4299,
                "itemName": "Bachelor of Sport and Recreation Studies (Honours)"
            },
            {
                "id": 4300,
                "itemName": "BSc (Honours) in Economics"
            },
            {
                "id": 4301,
                "itemName": "BA in (Honours) French and Francophone Intercultural Studies"
            },
            {
                "id": 4302,
                "itemName": "BEd After Degree (BEAD) in Elementary Education for Cert. Teachers with Approved Degrees (BA + a Professional Year or Equivalent"
            },
            {
                "id": 4303,
                "itemName": "BEd After Degree (BEAD) in Elementary Education (Community Based)"
            },
            {
                "id": 4304,
                "itemName": "Baccalauréat en éducation après diplôme (BEAD)"
            },
            {
                "id": 4305,
                "itemName": "BA in Media, Art, and Performance (Special Three-year)"
            },
            {
                "id": 4306,
                "itemName": "BA in Media, Art, and Performance Major, Pop Culture Concentration"
            },
            {
                "id": 4307,
                "itemName": "Post Baccalaureate Certificate in Visual Arts"
            },
            {
                "id": 4308,
                "itemName": "BSc in Nursing After Degree"
            },
            {
                "id": 4309,
                "itemName": "BSc in Indigenous Environmental Science"
            },
            {
                "id": 4310,
                "itemName": "BSc (Honours) in Environmental Geoscience"
            },
            {
                "id": 4311,
                "itemName": "BSc (Major/Honours) in Environmental Geoscience"
            },
            {
                "id": 4312,
                "itemName": "Bachelor of Indigenous Education Elementary Dene Immersion"
            },
            {
                "id": 4313,
                "itemName": "BEd Elementary (YNTEP) After Degree"
            },
            {
                "id": 4314,
                "itemName": "Certificate in Media, Art, and Performance"
            },
            {
                "id": 4315,
                "itemName": "BA in Philosophy, Politics and Economics"
            },
            {
                "id": 4316,
                "itemName": "BSc in Nursing (Bilingual Option)"
            },
            {
                "id": 4317,
                "itemName": "Bachelor of Social Work (Yukon College)"
            },
            {
                "id": 4318,
                "itemName": "BA in Geography and Environmental Studies"
            },
            {
                "id": 4319,
                "itemName": "Bachelor of Business Administration/Bachelor of Sport and Recreation Studies Internship"
            },
            {
                "id": 4320,
                "itemName": "BA in Theatre (3-year Special)"
            },
            {
                "id": 4321,
                "itemName": "Integrated Pathway in French and Francophone Studies and Law – Bachelor of Arts and Juris Doctor"
            },
            {
                "id": 4322,
                "itemName": "Mineure bilingue en études africaines"
            },
            {
                "id": 4323,
                "itemName": "BSc Honours in Chemical Physics"
            },
            {
                "id": 4324,
                "itemName": "BSc in Chemical Physics"
            },
            {
                "id": 4325,
                "itemName": "BSc in Biochemistry & Molecular Biology"
            },
            {
                "id": 4326,
                "itemName": "BSc Honours in Geography"
            },
            {
                "id": 4327,
                "itemName": "BA Honours in French Studies"
            },
            {
                "id": 4328,
                "itemName": "BA Honours in International Development Studies"
            },
            {
                "id": 4329,
                "itemName": "BA Honours in Geography"
            },
            {
                "id": 4330,
                "itemName": "BSc Honours in Economics"
            },
            {
                "id": 4331,
                "itemName": "BA in Cultural Studies"
            },
            {
                "id": 4332,
                "itemName": "BSc in Computing Systems"
            },
            {
                "id": 4333,
                "itemName": "BA Honours in Cultural Studies"
            },
            {
                "id": 4334,
                "itemName": "BSc Honours in Mathematics"
            },
            {
                "id": 4335,
                "itemName": "BA Honours in Indigenous Studies"
            },
            {
                "id": 4336,
                "itemName": "BSc Honours in Environmental Chemistry"
            },
            {
                "id": 4337,
                "itemName": "BSc in Environmental Chemistry"
            },
            {
                "id": 4338,
                "itemName": "BSc Honours in Environmental and Resource Science"
            },
            {
                "id": 4339,
                "itemName": "BA in Computing Systems"
            },
            {
                "id": 4340,
                "itemName": "BA in Ancient Greek and Roman Studies"
            },
            {
                "id": 4341,
                "itemName": "BA Honours in Ancient Greek and Roman Studies"
            },
            {
                "id": 4342,
                "itemName": "BA Honours in Canadian Studies"
            },
            {
                "id": 4343,
                "itemName": "BSc Honours in Anthropology"
            },
            {
                "id": 4344,
                "itemName": "BA Honours in Gender and Women's Studies"
            },
            {
                "id": 4345,
                "itemName": "BA Honours in Political Studies"
            },
            {
                "id": 4346,
                "itemName": "BA Honours in Environmental and Resource Studies"
            },
            {
                "id": 4347,
                "itemName": "BA Honours in International Political Economy"
            },
            {
                "id": 4348,
                "itemName": "BSc Honours in Computer Science"
            },
            {
                "id": 4349,
                "itemName": "BSc Honours in Computer Science and Physics"
            },
            {
                "id": 4350,
                "itemName": "BA Honours Teacher Education Stream"
            },
            {
                "id": 4351,
                "itemName": "BSc Honours Teacher Education Stream"
            },
            {
                "id": 4352,
                "itemName": "BA Honours in Individualized Studies"
            },
            {
                "id": 4353,
                "itemName": "BSc Honours in Individualized Studies"
            },
            {
                "id": 4354,
                "itemName": "BBA Bachelor of Business Administration Honours"
            },
            {
                "id": 4355,
                "itemName": "BEd Bachelor of Education"
            },
            {
                "id": 4356,
                "itemName": "BScFS Bachelor of Science Honours in Forensic Science"
            },
            {
                "id": 4357,
                "itemName": "BScN Bachelor of Science Honours in Nursing"
            },
            {
                "id": 4358,
                "itemName": "BSc Honours in Biochemistry & Molecular Biology"
            },
            {
                "id": 4359,
                "itemName": "BSc Honours in Mathematical Economics"
            },
            {
                "id": 4360,
                "itemName": "BA Honours in Indigenous Environmental Studies"
            },
            {
                "id": 4361,
                "itemName": "BA in Indigenous Environmental Studies"
            },
            {
                "id": 4362,
                "itemName": "BSc Honours in Ecological Restoration"
            },
            {
                "id": 4363,
                "itemName": "BSc Honours in Indigenous Environmental Science"
            },
            {
                "id": 4364,
                "itemName": "BAS Bachelor of Arts and Science Honours"
            },
            {
                "id": 4365,
                "itemName": "BA Honours in Archaeology"
            },
            {
                "id": 4366,
                "itemName": "BA Honours in Media Studies"
            },
            {
                "id": 4367,
                "itemName": "BA Honours in Sustainable Agriculture and Food Systems"
            },
            {
                "id": 4368,
                "itemName": "BSc Honours in Sustainable Agriculture and Food Systems"
            },
            {
                "id": 4369,
                "itemName": "BA Honours in Business Administration"
            },
            {
                "id": 4370,
                "itemName": "BA Honours in Forensic Science"
            },
            {
                "id": 4371,
                "itemName": "BESS Bachelor of Environmental Science/Studies Honours"
            },
            {
                "id": 4372,
                "itemName": "BSc Honours in Archaeology"
            },
            {
                "id": 4373,
                "itemName": "BSc Honours in Forensic Science"
            },
            {
                "id": 4374,
                "itemName": "BSW Bachelor of Social Work Honours"
            },
            {
                "id": 4375,
                "itemName": "BHSc Bachelor of Health Science Honours in Kinesiology"
            },
            {
                "id": 4376,
                "itemName": "BA Honours in Information Systems"
            },
            {
                "id": 4377,
                "itemName": "BA in Communications and Critical Thinking"
            },
            {
                "id": 4378,
                "itemName": "BA in Information Systems"
            },
            {
                "id": 4379,
                "itemName": "BSc Honours in Business Administration"
            },
            {
                "id": 4380,
                "itemName": "BSc Honours in Information Systems"
            },
            {
                "id": 4381,
                "itemName": "BSc Honours in Water Sciences"
            },
            {
                "id": 4382,
                "itemName": "BSc in Information Systems"
            },
            {
                "id": 4383,
                "itemName": "BA Honours/LLB (Swansea University)"
            },
            {
                "id": 4384,
                "itemName": "BA Honours in Child and Youth Studies"
            },
            {
                "id": 4385,
                "itemName": "BA Honours in Computing Systems"
            },
            {
                "id": 4386,
                "itemName": "BEd Bachelor of Education - Indigenous"
            },
            {
                "id": 4387,
                "itemName": "BSc Honours in Biomedical Science"
            },
            {
                "id": 4388,
                "itemName": "BSc Honours in Environmental Geoscience"
            },
            {
                "id": 4389,
                "itemName": "BSc Honours in Conservation Biology"
            },
            {
                "id": 4390,
                "itemName": "BA Honours in Policing and Community Well-being"
            },
            {
                "id": 4391,
                "itemName": "BSc Honours in Forensic Biology"
            },
            {
                "id": 4392,
                "itemName": "BSc Honours in Forensic Chemistry"
            },
            {
                "id": 4393,
                "itemName": "BSc in Chemistry/BEng (Swansea University)"
            },
            {
                "id": 4394,
                "itemName": "BA in Psychology (major, honours)"
            },
            {
                "id": 4395,
                "itemName": "BA in Sociology (major, honours)"
            },
            {
                "id": 4396,
                "itemName": "BA in Political studies (major, honours)"
            },
            {
                "id": 4397,
                "itemName": "BSc in Biology (major, honours)"
            },
            {
                "id": 4398,
                "itemName": "BSc in Mathematics (major, honours)"
            },
            {
                "id": 4399,
                "itemName": "BSc in Computer Science (major, honours)"
            },
            {
                "id": 4400,
                "itemName": "BA in Mathematics (major, honours)"
            },
            {
                "id": 4401,
                "itemName": "BSc in Biochemistry (honours, major)"
            },
            {
                "id": 4402,
                "itemName": "BSc in Physics (major, honours)"
            },
            {
                "id": 4403,
                "itemName": "BA in Biology (major)"
            },
            {
                "id": 4404,
                "itemName": "BSc in Chemistry (major, honours)"
            },
            {
                "id": 4405,
                "itemName": "BA in Liberal Arts (major, honours)"
            },
            {
                "id": 4406,
                "itemName": "BA in Religion (major, honours)"
            },
            {
                "id": 4407,
                "itemName": "BA in Music (major, honours)"
            },
            {
                "id": 4408,
                "itemName": "BA in History (major, honours)"
            },
            {
                "id": 4409,
                "itemName": "BA in Fine Arts with honors in art history"
            },
            {
                "id": 4410,
                "itemName": "BA in English (major, honours)"
            },
            {
                "id": 4411,
                "itemName": "BA in Drama (major, honours)"
            },
            {
                "id": 4412,
                "itemName": "BA in classical studies (major, honours)"
            },
            {
                "id": 4413,
                "itemName": "BA in Music with option in performance"
            },
            {
                "id": 4414,
                "itemName": "BA in Music with option in Music history"
            },
            {
                "id": 4415,
                "itemName": "BA in Music with option in Music theory and composition"
            },
            {
                "id": 4416,
                "itemName": "BA in Modern Languages, Hispanic studies (major)"
            },
            {
                "id": 4417,
                "itemName": "BA in Modern Languages, German studies (major)"
            },
            {
                "id": 4418,
                "itemName": "BA in Fine Arts (major)"
            },
            {
                "id": 4419,
                "itemName": "BA in Fine Arts with honors in studio"
            },
            {
                "id": 4420,
                "itemName": "BA in Philosophy (major, honours)"
            },
            {
                "id": 4421,
                "itemName": "Bachelor of Education (elementary)"
            },
            {
                "id": 4422,
                "itemName": "Bachelor of Education (secondary"
            },
            {
                "id": 4423,
                "itemName": "BA in international political economy (major, honours)"
            },
            {
                "id": 4424,
                "itemName": "Bachelor of Business Administration (pass degree, honours degree) with concentration in General Business Management"
            },
            {
                "id": 4425,
                "itemName": "BA in Business (major)"
            },
            {
                "id": 4426,
                "itemName": "BBA with concentration in International Business"
            },
            {
                "id": 4427,
                "itemName": "BBA with concentration in Accounting"
            },
            {
                "id": 4428,
                "itemName": "BBA with concentration in Human Resources"
            },
            {
                "id": 4429,
                "itemName": "BBA with concentration in Finance"
            },
            {
                "id": 4430,
                "itemName": "BBA with concentration in Marketing"
            },
            {
                "id": 4431,
                "itemName": "BBA with concentration in Entrepreneurship"
            },
            {
                "id": 4432,
                "itemName": "BA in Modern Languages, English language studies"
            },
            {
                "id": 4433,
                "itemName": "BA in Modern Languages, Italian studies"
            },
            {
                "id": 4434,
                "itemName": "BSc Science Teaching Major"
            },
            {
                "id": 4435,
                "itemName": "BA in Environmental Studies and Geography (major, honours)"
            },
            {
                "id": 4436,
                "itemName": "BA in Psychology (applied) (major, honours)"
            },
            {
                "id": 4437,
                "itemName": "BSc in Psychology, neuroscience concentration (major, honours)"
            },
            {
                "id": 4438,
                "itemName": "BA in Sociology, Gender, Diversity and Equity Studies concentration"
            },
            {
                "id": 4439,
                "itemName": "BA in Sociology, Gerontology concentration (minor)"
            },
            {
                "id": 4440,
                "itemName": "BA en études françaises et québécoises (major, honours)"
            },
            {
                "id": 4441,
                "itemName": "BA in English, Film studies concentration"
            },
            {
                "id": 4442,
                "itemName": "BA Economics (major, honours)"
            },
            {
                "id": 4443,
                "itemName": "BA in Arts Administration (major/honours)"
            },
            {
                "id": 4444,
                "itemName": "BA in Modern Languages, Japanese studies"
            },
            {
                "id": 4445,
                "itemName": "BA in Public History (major)"
            },
            {
                "id": 4446,
                "itemName": "BA in Popular Music Studies (major, honours)"
            },
            {
                "id": 4447,
                "itemName": "BA in Divisional Major in Popular Culture (major)"
            },
            {
                "id": 4448,
                "itemName": "BA in Information Technology (major)"
            },
            {
                "id": 4449,
                "itemName": "BA in French Language Studies (major)"
            },
            {
                "id": 4450,
                "itemName": "BA in Educational Studies"
            },
            {
                "id": 4451,
                "itemName": "BA or BSc Double Major Education"
            },
            {
                "id": 4452,
                "itemName": "BA in International Studies (Major)"
            },
            {
                "id": 4453,
                "itemName": "BA Indigenous Studies (Minor)"
            },
            {
                "id": 4454,
                "itemName": "BSc in Pre-Medical Studies"
            },
            {
                "id": 4455,
                "itemName": "BA general in family and social relations"
            },
            {
                "id": 4456,
                "itemName": "BA honours in family and social relations"
            },
            {
                "id": 4457,
                "itemName": "Bachelor of music honours"
            },
            {
                "id": 4458,
                "itemName": "BA honours in drama"
            },
            {
                "id": 4459,
                "itemName": "BA honours in communication, media and film"
            },
            {
                "id": 4460,
                "itemName": "BA honours  in classical civilization (Greek or Latin option)"
            },
            {
                "id": 4461,
                "itemName": "BA honours (combined) in visual arts"
            },
            {
                "id": 4462,
                "itemName": "BA honours in international relations and development studies"
            },
            {
                "id": 4463,
                "itemName": "Bachelor of music therapy honours"
            },
            {
                "id": 4464,
                "itemName": "BA general in French Studies"
            },
            {
                "id": 4465,
                "itemName": "BA general in history"
            },
            {
                "id": 4466,
                "itemName": "BA general in communication, media and film"
            },
            {
                "id": 4467,
                "itemName": "BFA honours in dramatic art: acting"
            },
            {
                "id": 4468,
                "itemName": "BA general in psychology"
            },
            {
                "id": 4469,
                "itemName": "BA honours in philosophy"
            },
            {
                "id": 4470,
                "itemName": "BA general in English Language and Literature"
            },
            {
                "id": 4471,
                "itemName": "BA general in drama"
            },
            {
                "id": 4472,
                "itemName": "BA honours in history"
            },
            {
                "id": 4473,
                "itemName": "BA general in anthropology"
            },
            {
                "id": 4474,
                "itemName": "BA honours in women's and gender studies"
            },
            {
                "id": 4475,
                "itemName": "BA general in women's and gender studies"
            },
            {
                "id": 4476,
                "itemName": "BA general in liberal arts and professional studies"
            },
            {
                "id": 4477,
                "itemName": "BA honours in sociology"
            },
            {
                "id": 4478,
                "itemName": "BA general in sociology"
            },
            {
                "id": 4479,
                "itemName": "BA honours in economics"
            },
            {
                "id": 4480,
                "itemName": "BA honours in French studies"
            },
            {
                "id": 4481,
                "itemName": "BA honours in English literature and creative writing"
            },
            {
                "id": 4482,
                "itemName": "BA honours in psychology"
            },
            {
                "id": 4483,
                "itemName": "BA honours in criminology"
            },
            {
                "id": 4484,
                "itemName": "BA general in philosophy"
            },
            {
                "id": 4485,
                "itemName": "BA honours in English language and literature"
            },
            {
                "id": 4486,
                "itemName": "BA honours in political science"
            },
            {
                "id": 4487,
                "itemName": "BA general in political science"
            },
            {
                "id": 4488,
                "itemName": "BFA honours in visual arts"
            },
            {
                "id": 4489,
                "itemName": "BA honours in drama in education and community"
            },
            {
                "id": 4490,
                "itemName": "BSc honours in biological sciences"
            },
            {
                "id": 4491,
                "itemName": "BSc in general science"
            },
            {
                "id": 4492,
                "itemName": "Bachelor of computer science honours"
            },
            {
                "id": 4493,
                "itemName": "BSc honours in computer information systems"
            },
            {
                "id": 4494,
                "itemName": "Bachelor of Human Kinetics (Honours Sport - Movement Science)"
            },
            {
                "id": 4495,
                "itemName": "Bachelor of Human Kinetics (Honours Kinesiology) with major in movement science"
            },
            {
                "id": 4496,
                "itemName": "BSc honours in chemistry"
            },
            {
                "id": 4497,
                "itemName": "Bachelor of computer science general"
            },
            {
                "id": 4498,
                "itemName": "BMath honours in mathematics and computer science"
            },
            {
                "id": 4499,
                "itemName": "BScN (Honours)"
            },
            {
                "id": 4500,
                "itemName": "BSc honours (combined) in physics"
            },
            {
                "id": 4501,
                "itemName": "BSc honours in computer science with software engineering specialization"
            },
            {
                "id": 4502,
                "itemName": "BASc honours in mechanical engineering"
            },
            {
                "id": 4503,
                "itemName": "BASc honours in industrial engineering"
            },
            {
                "id": 4504,
                "itemName": "BASc honours in environmental engineering"
            },
            {
                "id": 4505,
                "itemName": "BASc honours in civil engineering"
            },
            {
                "id": 4506,
                "itemName": "BASc honours in electrical engineering"
            },
            {
                "id": 4507,
                "itemName": "Bachelor of education with intermediate-senior specialization (pre-service program)"
            },
            {
                "id": 4508,
                "itemName": "Bachelor of education with junior-intermediate specialization (pre-service program)"
            },
            {
                "id": 4509,
                "itemName": "Bachelor of education with primary-junior specialization (pre-service program)"
            },
            {
                "id": 4510,
                "itemName": "BComm honours in business administration and computer science"
            },
            {
                "id": 4511,
                "itemName": "BComm honours in business administration and economics"
            },
            {
                "id": 4512,
                "itemName": "BComm honours in business administration"
            },
            {
                "id": 4513,
                "itemName": "BA general in labour studies"
            },
            {
                "id": 4514,
                "itemName": "BA honours in developmental psychology"
            },
            {
                "id": 4515,
                "itemName": "BA honours in anthropology"
            },
            {
                "id": 4516,
                "itemName": "BA honours (combined) in modern languages - German option"
            },
            {
                "id": 4517,
                "itemName": "BA honours (combined) in modern languages - Italian option"
            },
            {
                "id": 4518,
                "itemName": "BA honours (combined) in modern languages - Spanish option"
            },
            {
                "id": 4519,
                "itemName": "BA honours (combined) in music"
            },
            {
                "id": 4520,
                "itemName": "BA general in visual arts"
            },
            {
                "id": 4521,
                "itemName": "Bachelor of environmental studies (BES) -- honours"
            },
            {
                "id": 4522,
                "itemName": "Bachelor of forensic science (BFS) -- honours"
            },
            {
                "id": 4523,
                "itemName": "Bachelor of Interdisciplinary Arts and Science (BAS) honours"
            },
            {
                "id": 4524,
                "itemName": "BComm honours in business administration for university graduates"
            },
            {
                "id": 4525,
                "itemName": "BASc honours in mechanical engineering with engineering materials option"
            },
            {
                "id": 4526,
                "itemName": "Juris doctor/Doctor of Jurisprudence (JD/JD)"
            },
            {
                "id": 4527,
                "itemName": "BMath general"
            },
            {
                "id": 4528,
                "itemName": "BMath honours"
            },
            {
                "id": 4529,
                "itemName": "BMath honours in mathematics and statistics"
            },
            {
                "id": 4530,
                "itemName": "BSc honours in biology and biochemistry"
            },
            {
                "id": 4531,
                "itemName": "BSc honours in biochemistry"
            },
            {
                "id": 4532,
                "itemName": "BSc honours in environmental science"
            },
            {
                "id": 4533,
                "itemName": "BSW honours in social work"
            },
            {
                "id": 4534,
                "itemName": "BSW honours in social work (for university graduates)"
            },
            {
                "id": 4535,
                "itemName": "BSW honours in social work and women's studies"
            },
            {
                "id": 4536,
                "itemName": "Ontario Primary Health Care Practitioner Certificate Program for Degree-Prepared Nurses (Post Bachelaureate certificate)"
            },
            {
                "id": 4537,
                "itemName": "Concurrent Bachelor of Arts General in French Studies and Bachelor of Education"
            },
            {
                "id": 4538,
                "itemName": "BA (honours) in modern languages with second-language education certificate"
            },
            {
                "id": 4539,
                "itemName": "BA general in economics"
            },
            {
                "id": 4540,
                "itemName": "BA honours (combined) in diaspora studies"
            },
            {
                "id": 4541,
                "itemName": "BA honours (combined) in social justice"
            },
            {
                "id": 4542,
                "itemName": "BA honours in medial arts and culture"
            },
            {
                "id": 4543,
                "itemName": "BASc honours in industrial and manufacturing systems engineering with automotive manufacturing systems engineering option"
            },
            {
                "id": 4544,
                "itemName": "BASc honours in industrial engineering with minor in business administration option"
            },
            {
                "id": 4545,
                "itemName": "BSc honours in Behaviour, Cognition and Neuroscience"
            },
            {
                "id": 4546,
                "itemName": "BSc honours in chemistry and physics"
            },
            {
                "id": 4547,
                "itemName": "BSc honours (combined) in Computer Science"
            },
            {
                "id": 4548,
                "itemName": "Bachelor of computer science general for university graduates"
            },
            {
                "id": 4549,
                "itemName": "BA (honours) in music"
            },
            {
                "id": 4550,
                "itemName": "BSW honours in social work and diaspora studies"
            },
            {
                "id": 4551,
                "itemName": "BASc honours in mechanical engineering with environmental option"
            },
            {
                "id": 4552,
                "itemName": "BA honours (combined) in classical civilization"
            },
            {
                "id": 4553,
                "itemName": "BA honours (combined) in English language and literature"
            },
            {
                "id": 4554,
                "itemName": "BA honours (combined) in English literature and creative writing"
            },
            {
                "id": 4555,
                "itemName": "BA honours (combined) in French  studies"
            },
            {
                "id": 4556,
                "itemName": "BA honours (combined) in anthropology"
            },
            {
                "id": 4557,
                "itemName": "BA honours (combined) in communication, media and film"
            },
            {
                "id": 4558,
                "itemName": "BA honours (combined) in criminology"
            },
            {
                "id": 4559,
                "itemName": "BA honours (combined) in developmental psychology"
            },
            {
                "id": 4560,
                "itemName": "BA honours in developmental psychology with thesis"
            },
            {
                "id": 4561,
                "itemName": "BA honours (combined) in developmental psychology with thesis"
            },
            {
                "id": 4562,
                "itemName": "BA honours (combined) in drama"
            },
            {
                "id": 4563,
                "itemName": "BA honours (combined) in family and social relations"
            },
            {
                "id": 4564,
                "itemName": "BA honours (combined) in history"
            },
            {
                "id": 4565,
                "itemName": "BA honours (combined) in philosophy"
            },
            {
                "id": 4566,
                "itemName": "BA honours (combined) in political science"
            },
            {
                "id": 4567,
                "itemName": "BA honours in psychology with thesis"
            },
            {
                "id": 4568,
                "itemName": "BA honours (combined) in psychology"
            },
            {
                "id": 4569,
                "itemName": "BA honours (combined) in psychology with thesis"
            },
            {
                "id": 4570,
                "itemName": "BA honours (combined) in sociology"
            },
            {
                "id": 4571,
                "itemName": "BA honours (combined) in women's and gender studies"
            },
            {
                "id": 4572,
                "itemName": "Concurrent Bachelor of Mathematics General and Bachelor of Education"
            },
            {
                "id": 4573,
                "itemName": "Bachelor of Education / Diploma in Technological Education"
            },
            {
                "id": 4574,
                "itemName": "BA honours (combined) in economics"
            },
            {
                "id": 4575,
                "itemName": "BMath honours (combined)"
            },
            {
                "id": 4576,
                "itemName": "BSc honours in biochemistry with thesis"
            },
            {
                "id": 4577,
                "itemName": "BSc honours (combined) in biochemistry"
            },
            {
                "id": 4578,
                "itemName": "BSc honours in chemistry with thesis"
            },
            {
                "id": 4579,
                "itemName": "BSc honours (combined) in chemistry"
            },
            {
                "id": 4580,
                "itemName": "BSc honours in environmental science with thesis"
            },
            {
                "id": 4581,
                "itemName": "BSc in general science (degree completion program)"
            },
            {
                "id": 4582,
                "itemName": "BSc honours in chemistry and physics with thesis"
            },
            {
                "id": 4583,
                "itemName": "BA general in child psychology"
            },
            {
                "id": 4584,
                "itemName": "BA honours (combined) in modern languages with two language options"
            },
            {
                "id": 4585,
                "itemName": "BA honours in disabilities studies"
            },
            {
                "id": 4586,
                "itemName": "BFA honours in visual arts and the built environment"
            },
            {
                "id": 4587,
                "itemName": "BSc honours in economics"
            },
            {
                "id": 4588,
                "itemName": "BSc honours in biological sciences with thesis"
            },
            {
                "id": 4589,
                "itemName": "BSc honours (combined) in biological sciences"
            },
            {
                "id": 4590,
                "itemName": "Bachelor of computer science (honours applied computing)"
            },
            {
                "id": 4591,
                "itemName": "BA honours in modern languages - with year abroad"
            },
            {
                "id": 4592,
                "itemName": "BSc honours in Applied Computing"
            },
            {
                "id": 4593,
                "itemName": "BA General in Psychology for St. Clair College Child and Youth Worker Program Graduates"
            },
            {
                "id": 4594,
                "itemName": "BA general in Child Psychology for St. Clair College Child and Youth Worker Program Graduates"
            },
            {
                "id": 4595,
                "itemName": "BA Honours Psychology for St. Clair College Child and Youth Work Program Graduates"
            },
            {
                "id": 4596,
                "itemName": "BA Honours Developmental Psychology for St. Clair College Child and Youth Worker Program Graduates"
            },
            {
                "id": 4597,
                "itemName": "BA Honours in Disability Studies for St. Clair College Child and Youth Worker Program Graduates"
            },
            {
                "id": 4598,
                "itemName": "Honours Bachelor of Social Work for St. Clair College Child and Youth Worker Program Graduates"
            },
            {
                "id": 4599,
                "itemName": "Concurrent Bachelor of Science Honours Biological Sciences and Bachelor of Education"
            },
            {
                "id": 4600,
                "itemName": "Bachelor of Engineering Technology"
            },
            {
                "id": 4601,
                "itemName": "BSc Honours Physics"
            },
            {
                "id": 4602,
                "itemName": "BSc Honours Physics (Physics and High Technology)"
            },
            {
                "id": 4603,
                "itemName": "BSc Honours Physics (Medical Physics)"
            },
            {
                "id": 4604,
                "itemName": "Bachelor of computer science honours applied computing for University Graduates"
            },
            {
                "id": 4605,
                "itemName": "BA Honours in liberal arts and professional studies"
            },
            {
                "id": 4606,
                "itemName": "BA honours in political science with French specialization"
            },
            {
                "id": 4607,
                "itemName": "BA honours in Law and Politics"
            },
            {
                "id": 4608,
                "itemName": "BA Combined Honours Digital Journalism and Communication, Media and Film"
            },
            {
                "id": 4609,
                "itemName": "BA Combined Honours Digital Journalism and English Language and Literature"
            },
            {
                "id": 4610,
                "itemName": "BA Combined Honours Digital Journalism and English  Literature and Creative Writing"
            },
            {
                "id": 4611,
                "itemName": "BA Combined Honours Digital Journalism and Political Science"
            },
            {
                "id": 4612,
                "itemName": "BASc Honours in mechanical engineering with aerospace engineering option (with and without Co-op)"
            },
            {
                "id": 4613,
                "itemName": "Bachelor of Computer Science (General) - Degree Completion Program"
            },
            {
                "id": 4614,
                "itemName": "Bachelor of Computer Science (Honours Applied Computing) - Degree Completion Program"
            },
            {
                "id": 4615,
                "itemName": "BA Honours Liberal Arts and Professional Studies - with Aeronautics Leadership stream"
            },
            {
                "id": 4616,
                "itemName": "Bachelor of Arts (General) Liberal Arts and Professional Studies for Lambton College GASU graduates"
            },
            {
                "id": 4617,
                "itemName": "Bachelor of Arts (Honours) Liberal Arts and Professional Studies for Lambton College GASU graduates"
            },
            {
                "id": 4618,
                "itemName": "BASc in Civil Engineering for St. Mary's University Pre-Engineering Diploma Graduates"
            },
            {
                "id": 4619,
                "itemName": "BASc in Mechanical Engineering (Gnr'l Auto., Envir. or Materials Option) for Saint Mary's Univ. Pre-Engin. Dipl. Graduates"
            },
            {
                "id": 4620,
                "itemName": "BA honours in disabilities studies and psychology"
            },
            {
                "id": 4621,
                "itemName": "BSW honours in social work and disabilities studies"
            },
            {
                "id": 4622,
                "itemName": "Bachelor of Human Kinetics (Honours Sport Management and Leadership) for Durham College Sport Management Diploma Graduates"
            },
            {
                "id": 4623,
                "itemName": "BA general in political science for graduates of the Hebei Law and Politics College in China"
            },
            {
                "id": 4624,
                "itemName": "BA honours in political science for graduates of the Hebei Law and Politics College in China"
            },
            {
                "id": 4625,
                "itemName": "BScN (Honours) for Graduates of Lambton College Practical Nursing Program"
            },
            {
                "id": 4626,
                "itemName": "BA General in Liberal Arts and Professional Studies for Career Professionals"
            },
            {
                "id": 4627,
                "itemName": "Concurrent Bachelor of Arts General in English Language and Literature and Bachelor of Education"
            },
            {
                "id": 4628,
                "itemName": "Concurrent Bachelor of Arts General in Communication, Media and Film and Bachelor of Education"
            },
            {
                "id": 4629,
                "itemName": "BA honours (combined) in sociology and criminology"
            },
            {
                "id": 4630,
                "itemName": "BA honours in developmental psychology with Autism and Behavioural Sciences (ABS) Post-Graduate Certificate"
            },
            {
                "id": 4631,
                "itemName": "BA honours in psychology with Autism and Behavioural Sciences (ABS) Post-Graduate Certificate"
            },
            {
                "id": 4632,
                "itemName": "BA honours in visual arts and communication, media and film (film production and media arts)"
            },
            {
                "id": 4633,
                "itemName": "Concurrent Bachelor of Science in General Science and Bachelor of Education"
            },
            {
                "id": 4634,
                "itemName": "Concurrent Bachelor of Music Honours in Music Education and Bachelor of Education"
            },
            {
                "id": 4635,
                "itemName": "Concurrent Bachelor of Arts General in Visual Arts and Bachelor of Education"
            },
            {
                "id": 4636,
                "itemName": "Concurrent Bachelor of Arts General in Drama and Bachelor of Education"
            },
            {
                "id": 4637,
                "itemName": "Concurrent Bachelor of Arts General in English Language and Bachelor of Education"
            },
            {
                "id": 4638,
                "itemName": "Concurrent Bachelor of Arts General in Psychology/Bachelor of Education/Early Childhood Education Diploma"
            },
            {
                "id": 4639,
                "itemName": "Concurrent Bachelor of Arts General in History and Bachelor of Education"
            },
            {
                "id": 4640,
                "itemName": "BMath honours with Finance Concentration"
            },
            {
                "id": 4641,
                "itemName": "BComm Honours in Business Administration and Mathematics with or without thesis"
            },
            {
                "id": 4642,
                "itemName": "BComm Honours in Business Administration and Psychology (with/without thesis)"
            },
            {
                "id": 4643,
                "itemName": "Concurrent Bachelor of Arts Honours in Modern Languages and Second Language Acquisition (Intercultural Stream) and Bachelor of Education"
            },
            {
                "id": 4644,
                "itemName": "Concurrent Bachelor of Arts General in Political Science and Bachelor of Education"
            },
            {
                "id": 4645,
                "itemName": "Combined BA in Forensics"
            },
            {
                "id": 4646,
                "itemName": "Combined BA in Forensics (Applied Forensic Science Stream)"
            },
            {
                "id": 4647,
                "itemName": "BSc honours in Biochemistry and Biomedical Science (Health Stream)"
            },
            {
                "id": 4648,
                "itemName": "BComm Honours in Business Administration and Political Science (with/without thesis)"
            },
            {
                "id": 4649,
                "itemName": "BComm honours in business administration for students from Chitkara University"
            },
            {
                "id": 4650,
                "itemName": "Bachelor of Education (Two-Year)"
            },
            {
                "id": 4651,
                "itemName": "Honours Bachelor of Science in Forestry"
            },
            {
                "id": 4652,
                "itemName": "Bachelor of Engineering (Civil Engineering)"
            },
            {
                "id": 4653,
                "itemName": "Bachelor of Science in Nursing (in Collaboration with Confederation College)"
            },
            {
                "id": 4654,
                "itemName": "HBComm (Business Economics)"
            },
            {
                "id": 4655,
                "itemName": "HBComm (Marketing)"
            },
            {
                "id": 4656,
                "itemName": "HBComm (Information Systems)"
            },
            {
                "id": 4657,
                "itemName": "HBComm (Accounting)"
            },
            {
                "id": 4658,
                "itemName": "HBComm (General Management)"
            },
            {
                "id": 4659,
                "itemName": "HBComm (Business Finance)"
            },
            {
                "id": 4660,
                "itemName": "HBComm (Human Resource Management/Industrial Relations)"
            },
            {
                "id": 4661,
                "itemName": "HBSc (Geoarchaeology)"
            },
            {
                "id": 4662,
                "itemName": "BSc (Geoarchaeology)"
            },
            {
                "id": 4663,
                "itemName": "HBSc (Applied Life Sciences)"
            },
            {
                "id": 4664,
                "itemName": "BSc (Mathematics & Physics)"
            },
            {
                "id": 4665,
                "itemName": "BSc (Computer Science)"
            },
            {
                "id": 4666,
                "itemName": "BSc (Mathematics)"
            },
            {
                "id": 4667,
                "itemName": "BA (Mathematics)"
            },
            {
                "id": 4668,
                "itemName": "BSc (Geography)"
            },
            {
                "id": 4669,
                "itemName": "BA (Economics)"
            },
            {
                "id": 4670,
                "itemName": "BSc (Natural Science)"
            },
            {
                "id": 4671,
                "itemName": "BA (Anthropology)"
            },
            {
                "id": 4672,
                "itemName": "Honours Bachelor of Fine Arts"
            },
            {
                "id": 4673,
                "itemName": "BA (Sociology)"
            },
            {
                "id": 4674,
                "itemName": "BA (Political Science)"
            },
            {
                "id": 4675,
                "itemName": "BSc (Physics)"
            },
            {
                "id": 4676,
                "itemName": "BA (Philosophy)"
            },
            {
                "id": 4677,
                "itemName": "BA (French)"
            },
            {
                "id": 4678,
                "itemName": "BA (History)"
            },
            {
                "id": 4679,
                "itemName": "HBSc (Geography)"
            },
            {
                "id": 4680,
                "itemName": "HBA (Geography)"
            },
            {
                "id": 4681,
                "itemName": "BA (Indigenous Learning)"
            },
            {
                "id": 4682,
                "itemName": "BA (Geography)"
            },
            {
                "id": 4683,
                "itemName": "BA (English)"
            },
            {
                "id": 4684,
                "itemName": "BSc (Biology)"
            },
            {
                "id": 4685,
                "itemName": "HBA (Anthropology)"
            },
            {
                "id": 4686,
                "itemName": "HBA (French)"
            },
            {
                "id": 4687,
                "itemName": "HBA (Sociology)"
            },
            {
                "id": 4688,
                "itemName": "HBSc (Biology)"
            },
            {
                "id": 4689,
                "itemName": "HBSc (Anthropology)"
            },
            {
                "id": 4690,
                "itemName": "BSc (Anthropology)"
            },
            {
                "id": 4691,
                "itemName": "HBA (Music)"
            },
            {
                "id": 4692,
                "itemName": "HBSc (Computer Science)"
            },
            {
                "id": 4693,
                "itemName": "HBA (Mathematics)"
            },
            {
                "id": 4694,
                "itemName": "HBSc (Geology)"
            },
            {
                "id": 4695,
                "itemName": "Honours Bachelor of Social Work (HBSW)"
            },
            {
                "id": 4696,
                "itemName": "HBA (Political Science)"
            },
            {
                "id": 4697,
                "itemName": "HBA (Philosophy)"
            },
            {
                "id": 4698,
                "itemName": "Honours Bachelor of Kinesiology"
            },
            {
                "id": 4699,
                "itemName": "BA (Women's Studies)"
            },
            {
                "id": 4700,
                "itemName": "HBComm (International Business)"
            },
            {
                "id": 4701,
                "itemName": "Bachelor of Engineering (Electrical Engineering)"
            },
            {
                "id": 4702,
                "itemName": "Honours BASc (Interdisciplinary Studies)"
            },
            {
                "id": 4703,
                "itemName": "Bachelor of Engineering (Mechanical Engineering)"
            },
            {
                "id": 4704,
                "itemName": "Bachelor of Engineering (Software Engineering)"
            },
            {
                "id": 4705,
                "itemName": "HBES (Geography)"
            },
            {
                "id": 4706,
                "itemName": "HBA (Indigenous Learning)"
            },
            {
                "id": 4707,
                "itemName": "BA (Gerontology)"
            },
            {
                "id": 4708,
                "itemName": "HBSc (Mathematical Physics)"
            },
            {
                "id": 4709,
                "itemName": "BA (Visual Arts)"
            },
            {
                "id": 4710,
                "itemName": "HBSc (Physics)"
            },
            {
                "id": 4711,
                "itemName": "HBSc (Chemistry)"
            },
            {
                "id": 4712,
                "itemName": "HBSc (Mathematics)"
            },
            {
                "id": 4713,
                "itemName": "HBA (History)"
            },
            {
                "id": 4714,
                "itemName": "HBA (Women's Studies)"
            },
            {
                "id": 4715,
                "itemName": "HBA (English)"
            },
            {
                "id": 4716,
                "itemName": "Honours Bachelor of Arts and Science (Environmental Sustainability major)"
            },
            {
                "id": 4717,
                "itemName": "Honours Bachelor of Fine Arts/Bachelor of Education (Intermediate/Senior)"
            },
            {
                "id": 4718,
                "itemName": "HBComm (Business Administration)"
            },
            {
                "id": 4719,
                "itemName": "Honours Bachelor of Education (Aboriginal Education) Primary/Junior"
            },
            {
                "id": 4720,
                "itemName": "HBSc (Bioinformatics)"
            },
            {
                "id": 4721,
                "itemName": "HBSc (Biology & Chemistry)"
            },
            {
                "id": 4722,
                "itemName": "Honours Bachelor of Environmental Management"
            },
            {
                "id": 4723,
                "itemName": "HBSc (Resource and Environmental Economics)"
            },
            {
                "id": 4724,
                "itemName": "Bachelor of Science in Nursing (Compressed Program)"
            },
            {
                "id": 4725,
                "itemName": "HBA/Bachelor of Education (Primary/Junior)"
            },
            {
                "id": 4726,
                "itemName": "Honours Bachelor of Kinesiology/Bachelor of Education (Primary/Junior)"
            },
            {
                "id": 4727,
                "itemName": "Honours Bachelor of Kinesiology/Bachelor of Education (Intermediate/Senior)"
            },
            {
                "id": 4728,
                "itemName": "Honours Bachelor of Outdoor Recreation/Bachelor of Education (Primary/Junior)"
            },
            {
                "id": 4729,
                "itemName": "HBASc/Bachelor of Education (Primary/Junior)"
            },
            {
                "id": 4730,
                "itemName": "HBSc/Bachelor of Education (Primary/Junior)"
            },
            {
                "id": 4731,
                "itemName": "HBA/Bachelor of Education (Intermediate/Senior)"
            },
            {
                "id": 4732,
                "itemName": "HBESc/Bachelor of Education (Intermediate/Senior)"
            },
            {
                "id": 4733,
                "itemName": "Honours Bachelor of Outdoor Recreation/Bachelor of Education (Intermediate/Senior)"
            },
            {
                "id": 4734,
                "itemName": "HBSc/Bachelor of Education (Intermediate/Senior)"
            },
            {
                "id": 4735,
                "itemName": "Honours Bachelor of Environmental Management Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4736,
                "itemName": "Honours Bachelor of Science in Forestry Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4737,
                "itemName": "HBA (Gerontology & Psychology)"
            },
            {
                "id": 4738,
                "itemName": "HBA (Sociology) with Major Concentration in Gerontology"
            },
            {
                "id": 4739,
                "itemName": "HBA (Gerontology & Women's Studies)"
            },
            {
                "id": 4740,
                "itemName": "HBSW with Major Concentration in Gerontology"
            },
            {
                "id": 4741,
                "itemName": "HBSW with Major Concentration in Indigenous Learning"
            },
            {
                "id": 4742,
                "itemName": "BA (Psychology)"
            },
            {
                "id": 4743,
                "itemName": "HBA (Psychology)"
            },
            {
                "id": 4744,
                "itemName": "HBA (Specialized Honours in Psychology)"
            },
            {
                "id": 4745,
                "itemName": "BSc (Psychology)"
            },
            {
                "id": 4746,
                "itemName": "HBSc (Psychology)"
            },
            {
                "id": 4747,
                "itemName": "HBSc (Specialized Honours in Psychology)"
            },
            {
                "id": 4748,
                "itemName": "HBA (Psychology & Women' Studies)"
            },
            {
                "id": 4749,
                "itemName": "Honours BA (Specialized Honours in Psychology) with Major Concentration in Women's Studies"
            },
            {
                "id": 4750,
                "itemName": "HBA (English & French)"
            },
            {
                "id": 4751,
                "itemName": "HBA (English & History)"
            },
            {
                "id": 4752,
                "itemName": "HBA (English & Women's Studies)"
            },
            {
                "id": 4753,
                "itemName": "BA (General Arts)"
            },
            {
                "id": 4754,
                "itemName": "HBA (History & Philosophy)"
            },
            {
                "id": 4755,
                "itemName": "BA (History)/ Honours Bachelor of Outdoor Recreation"
            },
            {
                "id": 4756,
                "itemName": "HBA (History & Political Science)"
            },
            {
                "id": 4757,
                "itemName": "HBA (History & Women's Studies)"
            },
            {
                "id": 4758,
                "itemName": "Honours BASc (Criminology)"
            },
            {
                "id": 4759,
                "itemName": "Honours BASc (Environmental Sustainability)"
            },
            {
                "id": 4760,
                "itemName": "Honours BASc (Media Studies)"
            },
            {
                "id": 4761,
                "itemName": "HBESc (Earth Science)"
            },
            {
                "id": 4762,
                "itemName": "Honours BSc (Water Resource Science)"
            },
            {
                "id": 4763,
                "itemName": "HBA (Indigenous Learning and Philosophy)"
            },
            {
                "id": 4764,
                "itemName": "HBA (Indigenous Learning and Women's Studies)"
            },
            {
                "id": 4765,
                "itemName": "HBA (Philosophy and French)"
            },
            {
                "id": 4766,
                "itemName": "Honours Bachelor of Music"
            },
            {
                "id": 4767,
                "itemName": "Honours Bachelor of Outdoor Recreation"
            },
            {
                "id": 4768,
                "itemName": "BA (Women's Studies)/Honours Bachelor of Outdoor Recreation"
            },
            {
                "id": 4769,
                "itemName": "BA (Psychology & Philosophy)"
            },
            {
                "id": 4770,
                "itemName": "HBA (Philosophy & Political Science)"
            },
            {
                "id": 4771,
                "itemName": "HBA (Philosophy & Psychology)"
            },
            {
                "id": 4772,
                "itemName": "BA (Political Science) - Pre-Law"
            },
            {
                "id": 4773,
                "itemName": "HBA (Political Science) - Pre-Law"
            },
            {
                "id": 4774,
                "itemName": "HBA (Sociology & Women's Studies)"
            },
            {
                "id": 4775,
                "itemName": "Honours BASc (Interdisciplinary Studies) Diploma to Degree Pathway - Multilateral Agreeement with All Colleges"
            },
            {
                "id": 4776,
                "itemName": "Honours BASc (Anthropology)"
            },
            {
                "id": 4777,
                "itemName": "Honours BASc (English)"
            },
            {
                "id": 4778,
                "itemName": "Honours BASc (Geography)"
            },
            {
                "id": 4779,
                "itemName": "Honours BASc (Media Studies) Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4780,
                "itemName": "BASc (Interdisciplinary Studies) Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4781,
                "itemName": "Bachelor of Administration Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4782,
                "itemName": "HBComm Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4783,
                "itemName": "HBSc (Applied Life Sciences) Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4784,
                "itemName": "BSc (Natural Science) Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4785,
                "itemName": "BSc (Natural Science) /Honours Bachelor of Outdoor Recreation"
            },
            {
                "id": 4786,
                "itemName": "HBESc (Geography) Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4787,
                "itemName": "HBES (Geography) Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4788,
                "itemName": "HBESc (Biology)"
            },
            {
                "id": 4789,
                "itemName": "HBSc (Chemistry) with Specialization in Medical Sciences"
            },
            {
                "id": 4790,
                "itemName": "BA (Mathematics & Economics)"
            },
            {
                "id": 4791,
                "itemName": "HBA (Economics)"
            },
            {
                "id": 4792,
                "itemName": "HBA (Economics & Political Science)"
            },
            {
                "id": 4793,
                "itemName": "BA (Geography)/Honours Bachelor of Outdoor Recreation"
            },
            {
                "id": 4794,
                "itemName": "HBSc (Geography with Geology Minor)"
            },
            {
                "id": 4795,
                "itemName": "HBSc (Physics) with Major Concentration in Biomedical Physics"
            },
            {
                "id": 4796,
                "itemName": "BSc (Earth Science)"
            },
            {
                "id": 4797,
                "itemName": "Honours BSc (Water Resource Science Major) Specialization in Applied Environmental Water Management"
            },
            {
                "id": 4798,
                "itemName": "Honours BSc (Water Resource Science) Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4799,
                "itemName": "Bachelor of Engineering (Chemical Engineering)"
            },
            {
                "id": 4800,
                "itemName": "BA (Economics & Political Science) Double Major"
            },
            {
                "id": 4801,
                "itemName": "HBESc (Geography)"
            },
            {
                "id": 4802,
                "itemName": "HBSc (Biology with Major Concentration in Animal Sciences)"
            },
            {
                "id": 4803,
                "itemName": "Honours BASc (Criminology) Diploma to Degree Pathway - Multilateral Agreement with All Colleges"
            },
            {
                "id": 4804,
                "itemName": "Honours BASc (Environmental Sustainability) Diploma to Degree Pathway - Articulation Agreement with Georgian College"
            },
            {
                "id": 4805,
                "itemName": "HBComm (Global Entrepreneurship)"
            },
            {
                "id": 4806,
                "itemName": "HBSW with Major Concentration in Women's Studies"
            },
            {
                "id": 4807,
                "itemName": "HBSc (Applied Life Sciences) with Concentration in Biomedical Sciences"
            },
            {
                "id": 4808,
                "itemName": "HBSc (Biology with Major Concentration in Plant Sciences)"
            },
            {
                "id": 4809,
                "itemName": "HBSc (Biology with Major Concentration in Biodiversity and Conservation)"
            },
            {
                "id": 4810,
                "itemName": "HBSc (Biology with Major Concentration in Neuroscience)"
            },
            {
                "id": 4811,
                "itemName": "Lakehead Science One - Undecided Major"
            },
            {
                "id": 4812,
                "itemName": "HBA (English & Philosophy)"
            },
            {
                "id": 4813,
                "itemName": "HBA (History & Sociology)"
            },
            {
                "id": 4814,
                "itemName": "Honours Bachelor of Outdoor Recreation with Major Concentration in Nature-Based Therapeutic Recreation"
            },
            {
                "id": 4815,
                "itemName": "Lakehead Arts One - Undecided Major"
            },
            {
                "id": 4816,
                "itemName": "Honours BASc (Interdisciplinary Studies with Major Concentration in Environment in Politics and Culture)"
            },
            {
                "id": 4817,
                "itemName": "Honours BASc (Interdisciplinary Studies with Major Concentration in International Conflict and Human Rights)"
            },
            {
                "id": 4818,
                "itemName": "Honours BASc (Interdisciplinary Studies with Major Concentration in Social Justice)"
            },
            {
                "id": 4819,
                "itemName": "Honours BASc (Interdisciplinary Studies with Major Concentration in Human Nature)"
            },
            {
                "id": 4820,
                "itemName": "BScN in Nursing"
            },
            {
                "id": 4821,
                "itemName": "BA in First Nations Studies"
            },
            {
                "id": 4822,
                "itemName": "BSc (Integrated)"
            },
            {
                "id": 4823,
                "itemName": "BA in Global & International Studies"
            },
            {
                "id": 4824,
                "itemName": "BA in Northern Studies"
            },
            {
                "id": 4825,
                "itemName": "BA (Joint Major)"
            },
            {
                "id": 4826,
                "itemName": "BSc (Joint Majors)"
            },
            {
                "id": 4827,
                "itemName": "Bachelor of Applied Science in Environmental Engineering"
            },
            {
                "id": 4828,
                "itemName": "MD - Northern Medical Program"
            },
            {
                "id": 4829,
                "itemName": "BSc in Biochemistry and Molecular Biology"
            },
            {
                "id": 4830,
                "itemName": "Bachelor of Planning (BPl) in Environmental Planning"
            },
            {
                "id": 4831,
                "itemName": "BComm in General Business"
            },
            {
                "id": 4832,
                "itemName": "BA Nature-Based Tourism Management"
            },
            {
                "id": 4833,
                "itemName": "BHSc Health Sciences"
            },
            {
                "id": 4834,
                "itemName": "BComm in Human Resources Management"
            },
            {
                "id": 4835,
                "itemName": "Bachelor of Applied Science in Civil Engineering"
            },
            {
                "id": 4836,
                "itemName": "BSc in Conservation Science and Practice"
            },
            {
                "id": 4837,
                "itemName": "BSc in Forest Ecology and Management"
            },
            {
                "id": 4838,
                "itemName": "BSc in Wildlife and Fisheries"
            },
            {
                "id": 4839,
                "itemName": "BComm in Management Information Systems"
            },
            {
                "id": 4840,
                "itemName": "Doctor of Veterinary Medicine"
            },
            {
                "id": 4841,
                "itemName": "BSc Computer Science"
            },
            {
                "id": 4842,
                "itemName": "BSc Chemistry"
            },
            {
                "id": 4843,
                "itemName": "Bachelor of Education Degree"
            },
            {
                "id": 4844,
                "itemName": "Bachelor of Business in Tourism & Hospitality"
            },
            {
                "id": 4845,
                "itemName": "BA German"
            },
            {
                "id": 4846,
                "itemName": "BA Sociology and Anthropology"
            },
            {
                "id": 4847,
                "itemName": "BA Psychology"
            },
            {
                "id": 4848,
                "itemName": "BA Political Science"
            },
            {
                "id": 4849,
                "itemName": "BA  French"
            },
            {
                "id": 4850,
                "itemName": "BA Economics"
            },
            {
                "id": 4851,
                "itemName": "BA Canadian studies"
            },
            {
                "id": 4852,
                "itemName": "Bachelor of Music Education"
            },
            {
                "id": 4853,
                "itemName": "BA Music"
            },
            {
                "id": 4854,
                "itemName": "BA Sociology"
            },
            {
                "id": 4855,
                "itemName": "BA Anthropology"
            },
            {
                "id": 4856,
                "itemName": "BA History"
            },
            {
                "id": 4857,
                "itemName": "BA Religious Studies"
            },
            {
                "id": 4858,
                "itemName": "BA Philosophy"
            },
            {
                "id": 4859,
                "itemName": "BA  English"
            },
            {
                "id": 4860,
                "itemName": "BSc Major in Physics with Engineering Diploma"
            },
            {
                "id": 4861,
                "itemName": "BSc Psychology"
            },
            {
                "id": 4862,
                "itemName": "BSc Physics"
            },
            {
                "id": 4863,
                "itemName": "BSc Mathematics"
            },
            {
                "id": 4864,
                "itemName": "BSc Major in Biology"
            },
            {
                "id": 4865,
                "itemName": "Bachelor of Applied Science in Radiography"
            },
            {
                "id": 4866,
                "itemName": "BSc Family Science"
            },
            {
                "id": 4867,
                "itemName": "Bachelor of Business Administration (Honours)"
            },
            {
                "id": 4868,
                "itemName": "Bachelor of Applied Arts in Print Journalism"
            },
            {
                "id": 4869,
                "itemName": "BA with fine arts minor"
            },
            {
                "id": 4870,
                "itemName": "BA Minor in Classics"
            },
            {
                "id": 4871,
                "itemName": "BA with island studies minor"
            },
            {
                "id": 4872,
                "itemName": "BA with theatre studies minor"
            },
            {
                "id": 4873,
                "itemName": "BA with university writing minor"
            },
            {
                "id": 4874,
                "itemName": "BA Spanish"
            },
            {
                "id": 4875,
                "itemName": "BA Music in conjunction with Certificate in Highland Bagpipes"
            },
            {
                "id": 4876,
                "itemName": "BA Women's Studies"
            },
            {
                "id": 4877,
                "itemName": "BA Honours Anthropology"
            },
            {
                "id": 4878,
                "itemName": "BA Honours English"
            },
            {
                "id": 4879,
                "itemName": "BA Honours History"
            },
            {
                "id": 4880,
                "itemName": "BA Honours Psychology"
            },
            {
                "id": 4881,
                "itemName": "BA Honours Sociology"
            },
            {
                "id": 4882,
                "itemName": "BA Honours Sociology/Anthropology"
            },
            {
                "id": 4883,
                "itemName": "Accelerated Bachelor of Business in conjunction with Holland College"
            },
            {
                "id": 4884,
                "itemName": "BEd - Enseignement en français langue seconde"
            },
            {
                "id": 4885,
                "itemName": "BSc Major in Foods and Nutrition"
            },
            {
                "id": 4886,
                "itemName": "BSc Honours Biology"
            },
            {
                "id": 4887,
                "itemName": "BSc Honours Chemistry"
            },
            {
                "id": 4888,
                "itemName": "BSc Honours Computer Sciences"
            },
            {
                "id": 4889,
                "itemName": "BSc Honours Foods & Nutrition"
            },
            {
                "id": 4890,
                "itemName": "BSc Honours Mathematics"
            },
            {
                "id": 4891,
                "itemName": "BSc Honours Psychology"
            },
            {
                "id": 4892,
                "itemName": "Bachelor of Integrated Studies (BIS)"
            },
            {
                "id": 4893,
                "itemName": "BA with International Development Studies minor"
            },
            {
                "id": 4894,
                "itemName": "BA with Catholic Studies minor"
            },
            {
                "id": 4895,
                "itemName": "BA with Minor in Business Administration"
            },
            {
                "id": 4896,
                "itemName": "Bachelor of Business Studies"
            },
            {
                "id": 4897,
                "itemName": "Accelerated Bachelor of Science in Nursing (BScN) Program"
            },
            {
                "id": 4898,
                "itemName": "BA minor in Acadian Studies"
            },
            {
                "id": 4899,
                "itemName": "BSc Major in Kinesiology"
            },
            {
                "id": 4900,
                "itemName": "BA Minor in Asian Studies"
            },
            {
                "id": 4901,
                "itemName": "Bachelor of Wildlife Conservation"
            },
            {
                "id": 4902,
                "itemName": "BA Honours Philosophy"
            },
            {
                "id": 4903,
                "itemName": "BA with Christian Studies minor"
            },
            {
                "id": 4904,
                "itemName": "BA minor in environmental studies"
            },
            {
                "id": 4905,
                "itemName": "BA with minor in Asian studies"
            },
            {
                "id": 4906,
                "itemName": "Bachelor of Science in Sustainable Design Engineering"
            },
            {
                "id": 4907,
                "itemName": "BSc Major in Statistics"
            },
            {
                "id": 4908,
                "itemName": "BSc Major in Financial Mathematics"
            },
            {
                "id": 4909,
                "itemName": "BSc Major in Actuarial Sciences"
            },
            {
                "id": 4910,
                "itemName": "BSc Major in Analytics, Specialization in Data Analytics"
            },
            {
                "id": 4911,
                "itemName": "BSc Major in Analytics, Specialization in Business Analytics"
            },
            {
                "id": 4912,
                "itemName": "BA Applied Communication, Leadership and Culture"
            },
            {
                "id": 4913,
                "itemName": "BSc in Applied Climate Change and Adaptation"
            },
            {
                "id": 4914,
                "itemName": "BSc Honours Kinesiology"
            },
            {
                "id": 4915,
                "itemName": "BSc in Paramedicine"
            },
            {
                "id": 4916,
                "itemName": "Juris doctor"
            },
            {
                "id": 4917,
                "itemName": "Juris doctor et Maîtrise en administration des affaires"
            },
            {
                "id": 4918,
                "itemName": "Baccalauréat ès sciences (majeure en informatique)"
            },
            {
                "id": 4919,
                "itemName": "Baccalauréat en éducation (2 ans)"
            },
            {
                "id": 4920,
                "itemName": "Baccalauréat ès sciences (majeure en physique)"
            },
            {
                "id": 4921,
                "itemName": "Baccalauréat ès sciences (majeure en biologie)"
            },
            {
                "id": 4922,
                "itemName": "Baccalauréat ès sciences en (majeure en biochimie et biologie moléculaire)"
            },
            {
                "id": 4923,
                "itemName": "Baccalauréat ès sciences (majeure en chimie)"
            },
            {
                "id": 4924,
                "itemName": "Baccalauréat en informatique appliquée"
            },
            {
                "id": 4925,
                "itemName": "Baccalauréat ès sciences (majeure en mathématiques)"
            },
            {
                "id": 4926,
                "itemName": "Baccalauréat ès arts (majeure en psychologie)"
            },
            {
                "id": 4927,
                "itemName": "Baccalauréat ès sciences sociales (majeure en sociologie)"
            },
            {
                "id": 4928,
                "itemName": "Baccalauréat ès sciences sociales (majeure en science politique)"
            },
            {
                "id": 4929,
                "itemName": "Baccalauréat ès sciences sociales (majeure en économie)"
            },
            {
                "id": 4930,
                "itemName": "Baccalauréat en science infirmière"
            },
            {
                "id": 4931,
                "itemName": "Baccalauréat ès arts - Baccalauréat en éducation (primaire)"
            },
            {
                "id": 4932,
                "itemName": "Baccalauréat appliqué en techniques radiologiques"
            },
            {
                "id": 4933,
                "itemName": "Baccalauréat en ingénierie en génie mécanique"
            },
            {
                "id": 4934,
                "itemName": "Baccalauréat en ingénierie en génie civil"
            },
            {
                "id": 4935,
                "itemName": "Baccalauréat en ingénierie en génie électrique"
            },
            {
                "id": 4936,
                "itemName": "Baccalauréat ès arts en études françaises"
            },
            {
                "id": 4937,
                "itemName": "Baccalauréat ès arts (majeure en anglais)"
            },
            {
                "id": 4938,
                "itemName": "Baccalauréat ès arts en philosophie"
            },
            {
                "id": 4939,
                "itemName": "Baccalauréat ès arts (majeure en histoire)"
            },
            {
                "id": 4940,
                "itemName": "Baccalauréat ès arts (majeure en géographie)"
            },
            {
                "id": 4941,
                "itemName": "Baccalauréat en arts visuels (orientation enseignement)"
            },
            {
                "id": 4942,
                "itemName": "Baccalauréat en arts visuels"
            },
            {
                "id": 4943,
                "itemName": "Baccalauréat ès arts multidisciplinaire"
            },
            {
                "id": 4944,
                "itemName": "Baccalauréat en arts dramatiques"
            },
            {
                "id": 4945,
                "itemName": "Baccalauréat ès arts en information-communication"
            },
            {
                "id": 4946,
                "itemName": "Baccalauréat en administration des affaires multidisciplinaire"
            },
            {
                "id": 4947,
                "itemName": "Baccalauréat en études individualisées"
            },
            {
                "id": 4948,
                "itemName": "Baccalauréat en aménagement des forêts"
            },
            {
                "id": 4949,
                "itemName": "Baccalauréat ès sciences (nutrition) avec internat"
            },
            {
                "id": 4950,
                "itemName": "Baccalauréat en sciences de kinésiologie"
            },
            {
                "id": 4951,
                "itemName": "Baccalauréat en gestion du loisir, sport et tourisme"
            },
            {
                "id": 4952,
                "itemName": "Baccalauréat en administration des affaires"
            },
            {
                "id": 4953,
                "itemName": "Baccalauréat en administration des affaires gestion internationale"
            },
            {
                "id": 4954,
                "itemName": "Baccalauréat en administration des affaires en marketing"
            },
            {
                "id": 4955,
                "itemName": "Baccalauréat en administration des affaires en finance"
            },
            {
                "id": 4956,
                "itemName": "Baccalauréat en administration des affaires en gestion des opérations"
            },
            {
                "id": 4957,
                "itemName": "Baccalauréat en administration des affaires en comptabilité"
            },
            {
                "id": 4958,
                "itemName": "Baccalauréat en administration des affaires en management"
            },
            {
                "id": 4959,
                "itemName": "Baccalauréat en administration des affaires en systèmes d'information organisationnels"
            },
            {
                "id": 4960,
                "itemName": "Juris doctor et Maîtrise en études de l'environnement"
            },
            {
                "id": 4961,
                "itemName": "Baccalauréat ès sciences multidisciplinaire"
            },
            {
                "id": 4962,
                "itemName": "Baccalauréat appliqué en thérapie respiratoire"
            },
            {
                "id": 4963,
                "itemName": "BEP-BEd"
            },
            {
                "id": 4964,
                "itemName": "Baccalauréat ès arts - Baccalauréat en éducation"
            },
            {
                "id": 4965,
                "itemName": "Baccalauréat ès sciences - Baccalauréat en éducation"
            },
            {
                "id": 4966,
                "itemName": "Baccalauréat en gestion d l'information"
            },
            {
                "id": 4967,
                "itemName": "Baccalauréat en développement durable et zone côtière"
            },
            {
                "id": 4968,
                "itemName": "Baccalauréat appliqué en design d'intérieur"
            },
            {
                "id": 4969,
                "itemName": "Juris doctor et Maîtrise en administration publique"
            },
            {
                "id": 4970,
                "itemName": "Baccalauréat en musique - Baccalauréat en éducation"
            },
            {
                "id": 4971,
                "itemName": "Baccalauréat appliqué en gestion des services financiers"
            },
            {
                "id": 4972,
                "itemName": "Baccalauréat appliqué en gestion des réseaux de distribution"
            },
            {
                "id": 4973,
                "itemName": "Baccalauréat appliqué en marketing"
            },
            {
                "id": 4974,
                "itemName": "Baccalauréat ès sciences sociales (majeure en criminologie)"
            },
            {
                "id": 4975,
                "itemName": "Baccalauréat appliqué en technologie, information et leadership"
            },
            {
                "id": 4976,
                "itemName": "Baccalauréat ès sciences (majeure en biochimie)"
            },
            {
                "id": 4977,
                "itemName": "Mineure en finance"
            },
            {
                "id": 4978,
                "itemName": "Mineure en gestion des opérations"
            },
            {
                "id": 4979,
                "itemName": "Mineure en sciences de la gestion"
            },
            {
                "id": 4980,
                "itemName": "Mineure en allemand"
            },
            {
                "id": 4981,
                "itemName": "Mineure en espagnol"
            },
            {
                "id": 4982,
                "itemName": "Mineure en éthique appliquée"
            },
            {
                "id": 4983,
                "itemName": "Mineure en études acadiennes"
            },
            {
                "id": 4984,
                "itemName": "Mineure en français langue seconde (avancé)"
            },
            {
                "id": 4985,
                "itemName": "Mineure en journalisme"
            },
            {
                "id": 4986,
                "itemName": "Mineure en langues étrangères"
            },
            {
                "id": 4987,
                "itemName": "Mineure en politique publique"
            },
            {
                "id": 4988,
                "itemName": "Mineure en relations publiques"
            },
            {
                "id": 4989,
                "itemName": "Mineure en sciences sociales"
            },
            {
                "id": 4990,
                "itemName": "Mineure en sciences de l'environnement"
            },
            {
                "id": 4991,
                "itemName": "Mineure en statistique appliquée"
            },
            {
                "id": 4992,
                "itemName": "Mineure en développement personnel et social"
            },
            {
                "id": 4993,
                "itemName": "Mineure en linguistique et littérature"
            },
            {
                "id": 4994,
                "itemName": "Mineure en traduction"
            },
            {
                "id": 4995,
                "itemName": "Baccalauréat appliqué en biotechnologies"
            },
            {
                "id": 4996,
                "itemName": "Baccalauréat en éducation primaire (immersion/inclusion scolaire)"
            },
            {
                "id": 4997,
                "itemName": "Baccalauréat en éducation secondaire (immersion/inclusion scolaire)"
            },
            {
                "id": 4998,
                "itemName": "Baccalauréat ès arts (spécialisation en psychologie)"
            },
            {
                "id": 4999,
                "itemName": "BA in Sociology/Anthropology"
            },
            {
                "id": 5000,
                "itemName": "BA (Honours) in French"
            },
            {
                "id": 5001,
                "itemName": "Bachelor of Business Administration - General"
            },
            {
                "id": 5002,
                "itemName": "BA (Honours) in Sociology/Anthropology"
            },
            {
                "id": 5003,
                "itemName": "BA (Honours) in English"
            },
            {
                "id": 5004,
                "itemName": "Bachelor of Public Relations"
            },
            {
                "id": 5005,
                "itemName": "Bachelor of Arts (Child and Youth Study)"
            },
            {
                "id": 5006,
                "itemName": "Bachelor of Business Administration with a Major"
            },
            {
                "id": 5007,
                "itemName": "BA (Honours) in Women's Studies"
            },
            {
                "id": 5008,
                "itemName": "Bachelor of Business Administration with a Concentration"
            },
            {
                "id": 5009,
                "itemName": "BSc in Applied Human Nutrition"
            },
            {
                "id": 5010,
                "itemName": "Bachelor of Tourism and Hospitality Management"
            },
            {
                "id": 5011,
                "itemName": "BA in Public Policy Studies"
            },
            {
                "id": 5012,
                "itemName": "BA (Combined Major)"
            },
            {
                "id": 5013,
                "itemName": "BA (General Studies)"
            },
            {
                "id": 5014,
                "itemName": "BSc in Applied Human Nutrition (Honours)"
            },
            {
                "id": 5015,
                "itemName": "BSc (Joint Honours) Chemistry"
            },
            {
                "id": 5016,
                "itemName": "BSc (Combined Major)"
            },
            {
                "id": 5017,
                "itemName": "BSc (General Studies)"
            },
            {
                "id": 5018,
                "itemName": "BSc (Science Communication)"
            },
            {
                "id": 5019,
                "itemName": "Bachelor of Arts (Child and Youth Study) (Honours)"
            },
            {
                "id": 5020,
                "itemName": "Bachelor of Tourism and Hospitality Management (Honours)"
            },
            {
                "id": 5021,
                "itemName": "Bachelor of Business Administration (International option)"
            },
            {
                "id": 5022,
                "itemName": "BA in Communication"
            },
            {
                "id": 5023,
                "itemName": "BA en droit et politique (4-ans majeure)"
            },
            {
                "id": 5024,
                "itemName": "BA en sociologie (4-ans)"
            },
            {
                "id": 5025,
                "itemName": "BA en philosophie (4-ans)"
            },
            {
                "id": 5026,
                "itemName": "BA in Classical Studies (3 or 4-year)"
            },
            {
                "id": 5027,
                "itemName": "BA in Religious Studies (4-year)"
            },
            {
                "id": 5028,
                "itemName": "BA in History (4-year)"
            },
            {
                "id": 5029,
                "itemName": "BA in English (4-year)"
            },
            {
                "id": 5030,
                "itemName": "BA in Women's and Gender Studies (4 -year, major)"
            },
            {
                "id": 5031,
                "itemName": "BA in Law and Justice (4-year)"
            },
            {
                "id": 5032,
                "itemName": "BA in Geography (4-year, major)"
            },
            {
                "id": 5033,
                "itemName": "BA en études françaises (4 ans)"
            },
            {
                "id": 5034,
                "itemName": "Bachelor of Engineering in Mining Engineering"
            },
            {
                "id": 5035,
                "itemName": "BSW in Indigenous Social Work"
            },
            {
                "id": 5036,
                "itemName": "Baccalauréat en sciences de la santé formation des sages-femmes"
            },
            {
                "id": 5037,
                "itemName": "Bachelor of Health Sciences in Midwifery"
            },
            {
                "id": 5038,
                "itemName": "BSc en sciences interdisciplinaires (3-ans)"
            },
            {
                "id": 5039,
                "itemName": "BSc in Interdisciplinary Science (4 year)"
            },
            {
                "id": 5040,
                "itemName": "BSc in Environmental Science (4-year)"
            },
            {
                "id": 5041,
                "itemName": "Bachelor of Computer Science (4-year)"
            },
            {
                "id": 5042,
                "itemName": "BSc in Biomedical Physics"
            },
            {
                "id": 5043,
                "itemName": "BSc in Biochemistry (Honours)"
            },
            {
                "id": 5044,
                "itemName": "BSc in Mathematics (4-year)"
            },
            {
                "id": 5045,
                "itemName": "BA in Mathematics (4-year)"
            },
            {
                "id": 5046,
                "itemName": "Bachelor of Physical Education in Health Promotion (4-year)"
            },
            {
                "id": 5047,
                "itemName": "BSc in Physics (4-year)"
            },
            {
                "id": 5048,
                "itemName": "BSc in Earth Sciences (4-year)"
            },
            {
                "id": 5049,
                "itemName": "BSc in Biology (4-year)"
            },
            {
                "id": 5050,
                "itemName": "Bachelor of Social Work (Honours)"
            },
            {
                "id": 5051,
                "itemName": "BSc en chimie pharmaceutique (3-ans)"
            },
            {
                "id": 5052,
                "itemName": "Baccalauréat ès sciences infirmières"
            },
            {
                "id": 5053,
                "itemName": "Bachelor of Commerce in Sports Administration (Honours)"
            },
            {
                "id": 5054,
                "itemName": "BSc in Behavioural Neuroscience (Honours)"
            },
            {
                "id": 5055,
                "itemName": "BSc in Chemistry (4-year)"
            },
            {
                "id": 5056,
                "itemName": "BSc en biologie (4-ans)"
            },
            {
                "id": 5057,
                "itemName": "BSc en biochimie (4 ans)"
            },
            {
                "id": 5058,
                "itemName": "BSc in Kinesiology (4-year)"
            },
            {
                "id": 5059,
                "itemName": "Administration des affaires (B.A.A.)"
            },
            {
                "id": 5060,
                "itemName": "Baccalauréat spécialisé en éducation physique (4 ans)"
            },
            {
                "id": 5061,
                "itemName": "BA in Indigenous Studies (4-year)"
            },
            {
                "id": 5062,
                "itemName": "BA in Economics (4-year)"
            },
            {
                "id": 5063,
                "itemName": "BA in Sociology (4-year)"
            },
            {
                "id": 5064,
                "itemName": "BA in Psychology (4-year)"
            },
            {
                "id": 5065,
                "itemName": "BA in Political Science (4-year)"
            },
            {
                "id": 5066,
                "itemName": "BA in Philosophy (4-year)"
            },
            {
                "id": 5067,
                "itemName": "BA en psychologie (4-ans)"
            },
            {
                "id": 5068,
                "itemName": "BA en droit et justice (4-ans)"
            },
            {
                "id": 5069,
                "itemName": "BA en science économique (4-ans majeure)"
            },
            {
                "id": 5070,
                "itemName": "BA en sciences religieuses (4-ans)"
            },
            {
                "id": 5071,
                "itemName": "BA in French as a Second Language (4-year)"
            },
            {
                "id": 5072,
                "itemName": "BA in Environmental Studies (4-year)"
            },
            {
                "id": 5073,
                "itemName": "BA in Gerontology (4-year)"
            },
            {
                "id": 5074,
                "itemName": "BA in Workplace and Labour Studies (4-year)"
            },
            {
                "id": 5075,
                "itemName": "BA in Music (4-year, major)"
            },
            {
                "id": 5076,
                "itemName": "BA in Computer Science (3-year)"
            },
            {
                "id": 5077,
                "itemName": "BSc in Archaeology (4-year, major)"
            },
            {
                "id": 5078,
                "itemName": "BSc in Computer Science (4-year)"
            },
            {
                "id": 5079,
                "itemName": "BSc in Forensic Science"
            },
            {
                "id": 5080,
                "itemName": "BSc in Psychology (4-year)"
            },
            {
                "id": 5081,
                "itemName": "BSc in Radiation Therapy"
            },
            {
                "id": 5082,
                "itemName": "Bachelor of Concurrent Education (B.Ed)"
            },
            {
                "id": 5083,
                "itemName": "BA (Honours) in Sport Psychology"
            },
            {
                "id": 5084,
                "itemName": "BA en géographie (4-ans majeure)"
            },
            {
                "id": 5085,
                "itemName": "BA en histoire (4-ans)"
            },
            {
                "id": 5086,
                "itemName": "BA études journalistiques (4 ans)"
            },
            {
                "id": 5087,
                "itemName": "BA en folklore et ethnologie (3 ans)"
            },
            {
                "id": 5088,
                "itemName": "BSc en psychologie (4-ans)"
            },
            {
                "id": 5089,
                "itemName": "BA en psychologie du sport (4-ans)"
            },
            {
                "id": 5090,
                "itemName": "Doctor of Medicine (MD)"
            },
            {
                "id": 5091,
                "itemName": "BA in Anthropology (4-year)"
            },
            {
                "id": 5092,
                "itemName": "Bachelor of Physical Education in Outdoor Adventure Leadership (4-year)"
            },
            {
                "id": 5093,
                "itemName": "Bachelor of Physical Education in Sport and Physical Education (4-year)"
            },
            {
                "id": 5094,
                "itemName": "Baccalauréat ès sciences infirmières (pour les infirmières et les infirmiers autorisés)"
            },
            {
                "id": 5095,
                "itemName": "Baccalauréat spécialisé en éducation physique - promotion de la santé"
            },
            {
                "id": 5096,
                "itemName": "Baccalauréat ès sciences spécialisé en kinésiologie (4 ans)"
            },
            {
                "id": 5097,
                "itemName": "Baccalauréat spécialisé en éducation physique - leadership : activités physiques de plein air (4 ans)"
            },
            {
                "id": 5098,
                "itemName": "B.Sc.S - Sciences de la santé en orthophonie (4 ans)"
            },
            {
                "id": 5099,
                "itemName": "BA in Ancient Studies (4-year)"
            },
            {
                "id": 5100,
                "itemName": "BA Environnement et développement durable (4 ans majeure)"
            },
            {
                "id": 5101,
                "itemName": "Bachelor of Architecture"
            },
            {
                "id": 5102,
                "itemName": "BA in Archaeology (4-year, major)"
            },
            {
                "id": 5103,
                "itemName": "BACS (major/honours) in Anthropology"
            },
            {
                "id": 5104,
                "itemName": "BACS (major/honours) in Psychology"
            },
            {
                "id": 5105,
                "itemName": "BACS (major/honours) in History"
            },
            {
                "id": 5106,
                "itemName": "BA Minor in Celtic Culture"
            },
            {
                "id": 5107,
                "itemName": "BBA with major in Marketing"
            },
            {
                "id": 5108,
                "itemName": "BACS (major/honours) in Communication"
            },
            {
                "id": 5109,
                "itemName": "BACS (major/honours) in Sociology"
            },
            {
                "id": 5110,
                "itemName": "BSc with Major in Chemistry"
            },
            {
                "id": 5111,
                "itemName": "Bachelor of Engineering Technology (Environmental Studies)"
            },
            {
                "id": 5112,
                "itemName": "BSc with Major in Psychology"
            },
            {
                "id": 5113,
                "itemName": "BSc with Major in Biology"
            },
            {
                "id": 5114,
                "itemName": "BSc (Hon.) in Mathematics"
            },
            {
                "id": 5115,
                "itemName": "Bachelor of Science Nursing"
            },
            {
                "id": 5116,
                "itemName": "Bachelor of Health Sciences (Public Health)"
            },
            {
                "id": 5117,
                "itemName": "BBA with concentration in Economics"
            },
            {
                "id": 5118,
                "itemName": "BBA with concentration in Tourism Marketing and Management"
            },
            {
                "id": 5119,
                "itemName": "BBA with Major in Accounting"
            },
            {
                "id": 5120,
                "itemName": "BACS (major) in Mi'kmaq Studies"
            },
            {
                "id": 5121,
                "itemName": "BACS (major/honours) in Political Science"
            },
            {
                "id": 5122,
                "itemName": "BACS (major) in Philosophy"
            },
            {
                "id": 5123,
                "itemName": "BACS (major/honours) in English"
            },
            {
                "id": 5124,
                "itemName": "Bachelor of Engineering Technology (Electronics and Controls)"
            },
            {
                "id": 5125,
                "itemName": "BA (major, honours) in Communication"
            },
            {
                "id": 5126,
                "itemName": "BA (major, honours) in English"
            },
            {
                "id": 5127,
                "itemName": "BA in Mi'kmaq Studies"
            },
            {
                "id": 5128,
                "itemName": "BA (major/honours) in Political Science"
            },
            {
                "id": 5129,
                "itemName": "BA (major/honours) in Psychology"
            },
            {
                "id": 5130,
                "itemName": "BA Minor in Religious Studies"
            },
            {
                "id": 5131,
                "itemName": "BSc (Hon.) in Biology"
            },
            {
                "id": 5132,
                "itemName": "BSc (Hons.) in Psychology"
            },
            {
                "id": 5133,
                "itemName": "Bachelor of Engineering Technology (Manufacturing)"
            },
            {
                "id": 5134,
                "itemName": "Bachelor of Engineering Technology (Petroleum)"
            },
            {
                "id": 5135,
                "itemName": "BSc with Major in Mathematics"
            },
            {
                "id": 5136,
                "itemName": "Bachelor of Arts (BA) - general program"
            },
            {
                "id": 5137,
                "itemName": "Bachelor of Arts Community Studies (BACS) - general program"
            },
            {
                "id": 5138,
                "itemName": "Bachelor of Hospitality and Tourism Management"
            },
            {
                "id": 5139,
                "itemName": "Bachelor of Education (Elementary & Secondary Studies program)"
            },
            {
                "id": 5140,
                "itemName": "BBA with concentration in Legal Studies"
            },
            {
                "id": 5141,
                "itemName": "BA in Folklore"
            },
            {
                "id": 5142,
                "itemName": "BA (major, honours) in Anthropology"
            },
            {
                "id": 5143,
                "itemName": "BA (major, honours) in History"
            },
            {
                "id": 5144,
                "itemName": "BA (major/honours) in Sociology"
            },
            {
                "id": 5145,
                "itemName": "BA minor in Drama"
            },
            {
                "id": 5146,
                "itemName": "BA minor in Gaelic"
            },
            {
                "id": 5147,
                "itemName": "BA minor in Gender & Women's Studies"
            },
            {
                "id": 5148,
                "itemName": "BACS (major) in Music"
            },
            {
                "id": 5149,
                "itemName": "BA in Dramatic Literature"
            },
            {
                "id": 5150,
                "itemName": "BA Minor in Ethnomusicology"
            },
            {
                "id": 5151,
                "itemName": "BA Minor in French"
            },
            {
                "id": 5152,
                "itemName": "BACS (major/honours) in bachelor's degree in community studies with an honours/major in Sport and Physical Activity Leadership"
            },
            {
                "id": 5153,
                "itemName": "Bachelor of Arts and Science in Environment"
            },
            {
                "id": 5154,
                "itemName": "Bachelor of Business Administration Major in Supply Chain Management"
            },
            {
                "id": 5155,
                "itemName": "Bachelor of Emergency Management"
            },
            {
                "id": 5156,
                "itemName": "Bachelor of Emergency Management Major in Health Emergency Management"
            },
            {
                "id": 5157,
                "itemName": "Bachelor of Engineering Technology (Chemistry)"
            },
            {
                "id": 5158,
                "itemName": "Bachelor of Science Human Nutrition (Transfer)"
            },
            {
                "id": 5159,
                "itemName": "Bachelor of applied arts in gerontology"
            },
            {
                "id": 5160,
                "itemName": "Bachelor of applied arts in criminal justice"
            },
            {
                "id": 5161,
                "itemName": "Bachelor of education with concentration in second language education (French)"
            },
            {
                "id": 5162,
                "itemName": "Bachelor of education with concentration in secondary school education"
            },
            {
                "id": 5163,
                "itemName": "BA in criminology"
            },
            {
                "id": 5164,
                "itemName": "Bachelor of education with concentration in elementary education"
            },
            {
                "id": 5165,
                "itemName": "BA in Native studies"
            },
            {
                "id": 5166,
                "itemName": "BA in English language and literature"
            },
            {
                "id": 5167,
                "itemName": "BA in gerontology"
            },
            {
                "id": 5168,
                "itemName": "BA in Women's Studies and Gender Studies"
            },
            {
                "id": 5169,
                "itemName": "BA in human rights"
            },
            {
                "id": 5170,
                "itemName": "BA in media studies"
            },
            {
                "id": 5171,
                "itemName": "BA with interdisciplinary major"
            },
            {
                "id": 5172,
                "itemName": "BA in Catholic Studies"
            },
            {
                "id": 5173,
                "itemName": "BA in Great Books"
            },
            {
                "id": 5174,
                "itemName": "BA in Communications and Public Policy"
            },
            {
                "id": 5175,
                "itemName": "BA in Environment and Society"
            },
            {
                "id": 5176,
                "itemName": "BA in Fine Arts (Interdisciplinary)"
            },
            {
                "id": 5177,
                "itemName": "BA in International Relations"
            },
            {
                "id": 5178,
                "itemName": "BA in English with a concentration in creative writing"
            },
            {
                "id": 5179,
                "itemName": "Bachelor of Science in Psychiatric Nursing (4 yr)"
            },
            {
                "id": 5180,
                "itemName": "BA in Sociology (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5181,
                "itemName": "BA in English (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5182,
                "itemName": "BMus/BEd (After Degree) (Music Education) (5 yr concurrent)"
            },
            {
                "id": 5183,
                "itemName": "BMus (Specialist) (School Music) (4 yr)"
            },
            {
                "id": 5184,
                "itemName": "Bachelor of Music (Honours) (4 yr)"
            },
            {
                "id": 5185,
                "itemName": "Bachelor of Music (Performance) (4 yr)"
            },
            {
                "id": 5186,
                "itemName": "BMus/BEd (After Degree)  (Music Education) (5 yr concurrent)"
            },
            {
                "id": 5187,
                "itemName": "Bachelor of Education (After Degree) (2 yr)"
            },
            {
                "id": 5188,
                "itemName": "BA in Music Arts (3 yr)"
            },
            {
                "id": 5189,
                "itemName": "BSc in Chemistry (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5190,
                "itemName": "BA in Religion (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5191,
                "itemName": "BA/BEd (After Degree) (Liberal Arts/Education) (5 yr integrated)"
            },
            {
                "id": 5192,
                "itemName": "BA in Philosophy (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5193,
                "itemName": "BA in History (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5194,
                "itemName": "BA in Geography (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5195,
                "itemName": "BA in Economics (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5196,
                "itemName": "Bachelor of Business Administration (BBA) (4 yr or 4 yr Hon))"
            },
            {
                "id": 5197,
                "itemName": "BSc in Psychology (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5198,
                "itemName": "BSc in Geography (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5199,
                "itemName": "BSc in Mathematics (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5200,
                "itemName": "BSc in Physics (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5201,
                "itemName": "BA in Political Science (3-yr, 4-yr or 4-yr Hon)"
            },
            {
                "id": 5202,
                "itemName": "BSc in Computer Science (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5203,
                "itemName": "BSc in Geology (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5204,
                "itemName": "BA in Psychology (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5205,
                "itemName": "BA in Native Studies (3 yr , 4 yr or 4 yr Hon)"
            },
            {
                "id": 5206,
                "itemName": "BA in French (3 yr or 4 yr)"
            },
            {
                "id": 5207,
                "itemName": "BA in Rural and Community Studies (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5208,
                "itemName": "BSc in Environmental Science (Land & Water Stream) (4 yr)"
            },
            {
                "id": 5209,
                "itemName": "BA in Anthropology (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5210,
                "itemName": "BA in Business Administration (3 yr or 4 yr)"
            },
            {
                "id": 5211,
                "itemName": "BA in Canadian Studies (3 yr)"
            },
            {
                "id": 5212,
                "itemName": "BA in Creative Arts (4 yr)"
            },
            {
                "id": 5213,
                "itemName": "Bachelor of Fine Arts (BFA) (4 yr or 4 yr Hon)"
            },
            {
                "id": 5214,
                "itemName": "BA in Applied Disaster and Emergency Studies (4 yr or 4 yr Hon)"
            },
            {
                "id": 5215,
                "itemName": "BA in Gender and Women's Studies (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5216,
                "itemName": "Bachelor of Physical Education (3 yr)"
            },
            {
                "id": 5217,
                "itemName": "BSc in Applied Disaster and Emergency Studies (4 yr or 4 yr Hon)"
            },
            {
                "id": 5218,
                "itemName": "Bachelor of Science in Mental Health (2-year Post-RPN)"
            },
            {
                "id": 5219,
                "itemName": "BA in Native Studies (Clinical Specialization Stream) (4 yr or 4 yr Hon)"
            },
            {
                "id": 5220,
                "itemName": "BA in Sociology (Crime and Community Stream) (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5221,
                "itemName": "BSc in Biomedical Science Stream (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5222,
                "itemName": "BSc in Geography (Environmental Studies Stream) (4 yr or 4 yr Hon)"
            },
            {
                "id": 5223,
                "itemName": "BSc in Geography (Geomatics Stream) (4 yr or 4 yr Hon)"
            },
            {
                "id": 5224,
                "itemName": "BSc in Geography (Land & Water Management)"
            },
            {
                "id": 5225,
                "itemName": "BSc in Geography (Environmental Technology GIS)"
            },
            {
                "id": 5226,
                "itemName": "BA in Geography (Environmental Technology GIS)"
            },
            {
                "id": 5227,
                "itemName": "BA in Liberal Arts (Distributed Major) (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5228,
                "itemName": "BA in Sociology (Cultural Diversity and Equity Stream) (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5229,
                "itemName": "Bachelor of Business Administration (Accounting Stream) (4 yr or 4 yr Hon)"
            },
            {
                "id": 5230,
                "itemName": "BSc in Physics (Applied) (3 yr)"
            },
            {
                "id": 5231,
                "itemName": "BSc in Psychology (Applied Stream) (3 yr, 4 yr or 4 yr Hon)"
            },
            {
                "id": 5232,
                "itemName": "BA in Public History (History Intensive Stream) (4 yr or 4 yr Hon)"
            },
            {
                "id": 5233,
                "itemName": "BA in Public History (Media Arts Stream) (4 yr or 4 yr Hon)"
            },
            {
                "id": 5234,
                "itemName": "BSc in Environmental Science (4 yr)"
            },
            {
                "id": 5235,
                "itemName": "Bachelor of Education with concentration in intermediate/senior division"
            },
            {
                "id": 5236,
                "itemName": "Bachelor of Education with concentration in junior/intermediate division"
            },
            {
                "id": 5237,
                "itemName": "Bachelor of Education with concentration in primary/junior division"
            },
            {
                "id": 5238,
                "itemName": "BA in Native Studies (Major, Minor)"
            },
            {
                "id": 5239,
                "itemName": "BSc in Computer Science (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5240,
                "itemName": "BSc in Psychology (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5241,
                "itemName": "BA in Social Welfare and Social Development (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5242,
                "itemName": "BA in Psychology (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5243,
                "itemName": "BSc in Mathematics (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5244,
                "itemName": "BA in Mathematics (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5245,
                "itemName": "BA in Liberal Arts (Three-year Liberal)"
            },
            {
                "id": 5246,
                "itemName": "BA in History (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5247,
                "itemName": "BA in Geography (Honours, Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5248,
                "itemName": "BA in English (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5249,
                "itemName": "BA in Classical Studies (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5250,
                "itemName": "BA in Sociology (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5251,
                "itemName": "BSc in Environment/Physical Geography (Honours Specialization, Specialization, Major)"
            },
            {
                "id": 5252,
                "itemName": "BSc in Liberal Science (Three-year Liberal)"
            },
            {
                "id": 5253,
                "itemName": "BA in Computer Science (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5254,
                "itemName": "BA in Criminal Justice (Honours or Four-year BA)"
            },
            {
                "id": 5255,
                "itemName": "BA in Environmental Geography (Honours Specialization, Specialization, Major)"
            },
            {
                "id": 5256,
                "itemName": "BA in Fine Arts (Specialization, Major, Minor)"
            },
            {
                "id": 5257,
                "itemName": "BA in Gender Equality and Social Justice (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5258,
                "itemName": "BSc in Biology (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5259,
                "itemName": "BSc in Environmental Biology and Technology (Honours Specialization, Specialization)"
            },
            {
                "id": 5260,
                "itemName": "BScN - Bachelor of Science in Nursing"
            },
            {
                "id": 5261,
                "itemName": "BA in Child and Family Studies (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5262,
                "itemName": "BA in Religions and Cultures (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5263,
                "itemName": "Concurrent Bachelor of Arts/Bachelor of Education"
            },
            {
                "id": 5264,
                "itemName": "Professional Development for Teachers"
            },
            {
                "id": 5265,
                "itemName": "BA in Political Science (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5266,
                "itemName": "Concurrent Bachelor of Science/Bachelor of Education"
            },
            {
                "id": 5267,
                "itemName": "Bachelor of Physical and Health Education (Honours or Four-year BPHE)"
            },
            {
                "id": 5268,
                "itemName": "Bachelor of Commerce (Three-year or Four-year)"
            },
            {
                "id": 5269,
                "itemName": "BFA - Bachelor of Fine Arts  (Honours)"
            },
            {
                "id": 5270,
                "itemName": "BScN - RPN Bridging Program"
            },
            {
                "id": 5271,
                "itemName": "Teacher of Indigenous Language as a Second Language"
            },
            {
                "id": 5272,
                "itemName": "BBA in Business Administration (Honours or Four-year BBA)"
            },
            {
                "id": 5273,
                "itemName": "BA in Economics  (Major, Minor)"
            },
            {
                "id": 5274,
                "itemName": "BScN - Bachelor of Science in Nursing Scholar Practitioner Program"
            },
            {
                "id": 5275,
                "itemName": "BA in Anthropology (Specialization, Major, Minor)"
            },
            {
                "id": 5276,
                "itemName": "Concurrent Bachelor of Business Administration/Bachelor of Education"
            },
            {
                "id": 5277,
                "itemName": "Concurrent Bachelor of Fine Arts/Bachelor of Education"
            },
            {
                "id": 5278,
                "itemName": "Concurrent Bachelor of Physical and Health Education/Bachelor of Education"
            },
            {
                "id": 5279,
                "itemName": "BSc in Data Science (Honours Specialization, Specialization)"
            },
            {
                "id": 5280,
                "itemName": "BA/BSc/BCom"
            },
            {
                "id": 5281,
                "itemName": "BA (major) in Sociology"
            },
            {
                "id": 5282,
                "itemName": "BA (concentration) in History"
            },
            {
                "id": 5283,
                "itemName": "BA (minor) in Humanities"
            },
            {
                "id": 5284,
                "itemName": "BA (major) in Women's and Gender Studies"
            },
            {
                "id": 5285,
                "itemName": "BA (major) in Labour Studies"
            },
            {
                "id": 5286,
                "itemName": "BA (minor) in Political Economy"
            },
            {
                "id": 5287,
                "itemName": "BA (major) in Humanities"
            },
            {
                "id": 5288,
                "itemName": "BA (major) in Psychology"
            },
            {
                "id": 5289,
                "itemName": "BA (concentration) in French"
            },
            {
                "id": 5290,
                "itemName": "BA (concentration) in English"
            },
            {
                "id": 5291,
                "itemName": "BA (concentration) in Sociology"
            },
            {
                "id": 5292,
                "itemName": "BA (concentration) in Psychology"
            },
            {
                "id": 5293,
                "itemName": "BA (minor) in Women's and Gender Studies"
            },
            {
                "id": 5294,
                "itemName": "BA (minor) in Labour Studies"
            },
            {
                "id": 5295,
                "itemName": "BA (major) in History"
            },
            {
                "id": 5296,
                "itemName": "BA (major) in French"
            },
            {
                "id": 5297,
                "itemName": "BSc (post-diploma) General"
            },
            {
                "id": 5298,
                "itemName": "BSc (major, minor, post diploma) in Human Science"
            },
            {
                "id": 5299,
                "itemName": "Bachelor of Commerce (major) Business Technology Management"
            },
            {
                "id": 5300,
                "itemName": "Bachelor of Management (post-diploma)"
            },
            {
                "id": 5301,
                "itemName": "Bachelor of Professional Arts - (major) Governance, Law and Management"
            },
            {
                "id": 5302,
                "itemName": "Bachelor of Nursing, Post LPN"
            },
            {
                "id": 5303,
                "itemName": "Bachelor of Human Resources and Labour Relations"
            },
            {
                "id": 5304,
                "itemName": "Bachelor of Commerce (post-diploma major) Finance"
            },
            {
                "id": 5305,
                "itemName": "Bachelor of Commerce (major) Accounting"
            },
            {
                "id": 5306,
                "itemName": "Bachelor of Commerce (post-diploma) General"
            },
            {
                "id": 5307,
                "itemName": "Bachelor of Commerce (post-diploma major) Business Technology Management"
            },
            {
                "id": 5308,
                "itemName": "Bachelor of Commerce (post-diploma major) Accounting"
            },
            {
                "id": 5309,
                "itemName": "Bachelor of Health Administration"
            },
            {
                "id": 5310,
                "itemName": "Bachelor of Health Administration (post-diploma)"
            },
            {
                "id": 5311,
                "itemName": "Bachelor of Nursing, Post RN"
            },
            {
                "id": 5312,
                "itemName": "Bachelor of Professional Arts - (major) Human Services"
            },
            {
                "id": 5313,
                "itemName": "Bachelor of Professional Arts - (major) Criminal Justice"
            },
            {
                "id": 5314,
                "itemName": "Bachelor of Professional Arts - (major) Communications Studies"
            },
            {
                "id": 5315,
                "itemName": "BA (minor) in Anthropology"
            },
            {
                "id": 5316,
                "itemName": "BA (major) in Anthropology"
            },
            {
                "id": 5317,
                "itemName": "BA (major) in English"
            },
            {
                "id": 5318,
                "itemName": "BA (minor) in Canadian Studies"
            },
            {
                "id": 5319,
                "itemName": "BSc General"
            },
            {
                "id": 5320,
                "itemName": "Bachelor of Human Resources and Labour Relations (post diploma)"
            },
            {
                "id": 5321,
                "itemName": "BA (minor) in Political Science"
            },
            {
                "id": 5322,
                "itemName": "BA (major) in Political Economy"
            },
            {
                "id": 5323,
                "itemName": "BA (major) in Political Science"
            },
            {
                "id": 5324,
                "itemName": "Bachelor of Commerce - General"
            },
            {
                "id": 5325,
                "itemName": "Bachelor of Commerce (major) Finance"
            },
            {
                "id": 5326,
                "itemName": "Bachelor of General Studies (designation) Arts and Science"
            },
            {
                "id": 5327,
                "itemName": "Bachelor of General Studies (designation) Applied Studies"
            },
            {
                "id": 5328,
                "itemName": "BA - General"
            },
            {
                "id": 5329,
                "itemName": "BA (minor) in Global Studies"
            },
            {
                "id": 5330,
                "itemName": "BA (minor) in Inclusive Education"
            },
            {
                "id": 5331,
                "itemName": "BA (minor) in Philosophy"
            },
            {
                "id": 5332,
                "itemName": "BA (minor) in Public Administration"
            },
            {
                "id": 5333,
                "itemName": "BSc (major, minor, post-diploma) in Architecture"
            },
            {
                "id": 5334,
                "itemName": "BSc (major, minor, post-diploma) in Applied Mathematics"
            },
            {
                "id": 5335,
                "itemName": "BSc (minor) in Computing"
            },
            {
                "id": 5336,
                "itemName": "BSc (minor) in Information Systems"
            },
            {
                "id": 5337,
                "itemName": "BSc (minor) in Human Science"
            },
            {
                "id": 5338,
                "itemName": "BSc (minor) in Biology"
            },
            {
                "id": 5339,
                "itemName": "BSc (minor) in Geoscience"
            },
            {
                "id": 5340,
                "itemName": "BSc (minor) in Physical Sciences"
            },
            {
                "id": 5341,
                "itemName": "BSc (minor) in Learning Technology"
            },
            {
                "id": 5342,
                "itemName": "BSc (minor) in Game Design and Development"
            },
            {
                "id": 5343,
                "itemName": "BSc (minor) in Web Development"
            },
            {
                "id": 5344,
                "itemName": "BSc (minor) in Psychology"
            },
            {
                "id": 5345,
                "itemName": "BSc (minor) in Business Administration"
            },
            {
                "id": 5346,
                "itemName": "BSc (minor) in Finance Management"
            },
            {
                "id": 5347,
                "itemName": "BA (minor) in Heritage Resource Management"
            },
            {
                "id": 5348,
                "itemName": "BSc (minor) in Game Programming"
            },
            {
                "id": 5349,
                "itemName": "BSc (minor) in Information Systems Management"
            },
            {
                "id": 5350,
                "itemName": "BA (minor) in English"
            },
            {
                "id": 5351,
                "itemName": "BA (minor) in French"
            },
            {
                "id": 5352,
                "itemName": "BA (minor) in History"
            },
            {
                "id": 5353,
                "itemName": "BA (minor) in Psychology"
            },
            {
                "id": 5354,
                "itemName": "BA (minor) in Sociology"
            },
            {
                "id": 5355,
                "itemName": "Bachelor of Commerce (major) Marketing"
            },
            {
                "id": 5356,
                "itemName": "Bachelor of Commerce (major) Human Resources Management"
            },
            {
                "id": 5357,
                "itemName": "Bachelor of Commerce (major) Indigenous Business"
            },
            {
                "id": 5358,
                "itemName": "Bachelor of Commerce (post-diploma major) Marketing"
            },
            {
                "id": 5359,
                "itemName": "Bachelor of Commerce (post-diploma major) Human Resources Management"
            },
            {
                "id": 5360,
                "itemName": "Bachelor of Commerce (post-diploma major) Indigenous Business"
            },
            {
                "id": 5361,
                "itemName": "BSc (major, post diploma) in Computing and Information Systems"
            },
            {
                "id": 5362,
                "itemName": "BEd (Bachelor of Education (After Degree) in Elementary Education)"
            },
            {
                "id": 5363,
                "itemName": "BA in Psychology (Applied Emphasis)"
            },
            {
                "id": 5364,
                "itemName": "BA in Political Economy"
            },
            {
                "id": 5365,
                "itemName": "BEH (Bachelor of Environmental Health)"
            },
            {
                "id": 5366,
                "itemName": "BMgt (Bachelor of Management)"
            },
            {
                "id": 5367,
                "itemName": "BSc in Information Technology"
            },
            {
                "id": 5368,
                "itemName": "Bachelor of Commerce - Accounting (major/honours)"
            },
            {
                "id": 5369,
                "itemName": "Bachelor of Communication Studies"
            },
            {
                "id": 5370,
                "itemName": "Bachelor of Music in Jazz and Contemporary Popular Music"
            },
            {
                "id": 5371,
                "itemName": "BA in Anthropology (major/honours)"
            },
            {
                "id": 5372,
                "itemName": "BA in Economics (major/honours)"
            },
            {
                "id": 5373,
                "itemName": "BA in English (major/honours)"
            },
            {
                "id": 5374,
                "itemName": "BA in History (major)"
            },
            {
                "id": 5375,
                "itemName": "BA in Philosophy (major)"
            },
            {
                "id": 5376,
                "itemName": "BA in Political Science (major/honours)"
            },
            {
                "id": 5377,
                "itemName": "BA in Psychology (major/honours)"
            },
            {
                "id": 5378,
                "itemName": "BA in Sociology (major/honours)"
            },
            {
                "id": 5379,
                "itemName": "Bachelor of Physical Education (Transfer Program)"
            },
            {
                "id": 5380,
                "itemName": "BSc in  Biological Sciences (major/honours)"
            },
            {
                "id": 5381,
                "itemName": "BSc in Mathematics (major honours)"
            },
            {
                "id": 5382,
                "itemName": "BSc in Mathematical Sciences (major)"
            },
            {
                "id": 5383,
                "itemName": "BSc in Physical Sciences (major)"
            },
            {
                "id": 5384,
                "itemName": "BSc in Psychology (major/honours)"
            },
            {
                "id": 5385,
                "itemName": "BSc in Engineering Transfer Program"
            },
            {
                "id": 5386,
                "itemName": "Bachelor of Applied Human Service Administration"
            },
            {
                "id": 5387,
                "itemName": "Bachelor of Psychiatric Nursing"
            },
            {
                "id": 5388,
                "itemName": "BSc in  Computer Science (major)"
            },
            {
                "id": 5389,
                "itemName": "Bachelor of Commerce - Human Resources Management (major/honours)"
            },
            {
                "id": 5390,
                "itemName": "Bachelor of Commerce - International Business (major/honours)"
            },
            {
                "id": 5391,
                "itemName": "Bachelor of Commerce - Management (major/honours)"
            },
            {
                "id": 5392,
                "itemName": "Bachelor of Commerce - Marketing (major/honours)"
            },
            {
                "id": 5393,
                "itemName": "Bachelor of Commerce - Supply Chain Management (major)"
            },
            {
                "id": 5394,
                "itemName": "Bachelor of Commerce - Legal Studies in Business (major)"
            },
            {
                "id": 5395,
                "itemName": "BSc in Chemistry (major)"
            },
            {
                "id": 5396,
                "itemName": "Gerontology"
            },
            {
                "id": 5397,
                "itemName": "Hospice Palliative Care"
            },
            {
                "id": 5398,
                "itemName": "Perioperative Program for Registered Nurses and Licences "
            },
            {
                "id": 5399,
                "itemName": "Practical Nurses"
            },
            {
                "id": 5400,
                "itemName": "Bachelor of Interior Design"
            },
            {
                "id": 5401,
                "itemName": "Bachelor of Arts - Anthropology"
            },
            {
                "id": 5402,
                "itemName": "Bachelor of Arts - English"
            },
            {
                "id": 5403,
                "itemName": "Bachelor of Arts - History"
            },
            {
                "id": 5404,
                "itemName": "Bachelor of Arts - Policy Studies"
            },
            {
                "id": 5405,
                "itemName": "Bachelor of Arts - Psychology"
            },
            {
                "id": 5406,
                "itemName": "Bachelor of Arts - Undeclared"
            },
            {
                "id": 5407,
                "itemName": "Bachelor of Communication - Information Design"
            },
            {
                "id": 5408,
                "itemName": "Bachelor of Communication - Journalism"
            },
            {
                "id": 5409,
                "itemName": "Bachelor of Communication - Public Relations"
            },
            {
                "id": 5410,
                "itemName": "Bachelor of Communication - Broadcast Media Studies"
            },
            {
                "id": 5411,
                "itemName": "Bachelor of Arts - Criminal Justice"
            },
            {
                "id": 5412,
                "itemName": "Bachelor of Computer Information Systems"
            },
            {
                "id": 5413,
                "itemName": "Bachelor of Science - Biology"
            },
            {
                "id": 5414,
                "itemName": "Bachelor of Science - Computer Science"
            },
            {
                "id": 5415,
                "itemName": "Bachelor of Science - General Science"
            },
            {
                "id": 5416,
                "itemName": "Bachelor of Science - Geology"
            },
            {
                "id": 5417,
                "itemName": "Bachelor of Business Administration - Accounting"
            },
            {
                "id": 5418,
                "itemName": "Bachelor of Business Administration - General Management"
            },
            {
                "id": 5419,
                "itemName": "Bachelor of Business Administration - Human Resources"
            },
            {
                "id": 5420,
                "itemName": "Bachelor of Business Administration - Marketing"
            },
            {
                "id": 5421,
                "itemName": "Bachelor of Midwifery"
            },
            {
                "id": 5422,
                "itemName": "Bachelor of Science - Environmental Science"
            },
            {
                "id": 5423,
                "itemName": "Bachelor of Health and Physical Education - Athletic Therapy"
            },
            {
                "id": 5424,
                "itemName": "Bachelor of Health and Physical Education - Ecotourism and Outdoor Leadership"
            },
            {
                "id": 5425,
                "itemName": "Bachelor of Health and Physical Education - Physical Literacy"
            },
            {
                "id": 5426,
                "itemName": "Bachelor of Health and Physical Education - Sports and Recreation Management"
            },
            {
                "id": 5427,
                "itemName": "Bachelor of Child Studies - Early Learning and Child Care"
            },
            {
                "id": 5428,
                "itemName": "Bachelor of Child Studies - Child and Youth Care Counsellor"
            },
            {
                "id": 5429,
                "itemName": "Bachelor of Science - Chemistry"
            },
            {
                "id": 5430,
                "itemName": "Bachelor of Business Administration - Finance"
            },
            {
                "id": 5431,
                "itemName": "Bachelor of Business Administration - International Business"
            },
            {
                "id": 5432,
                "itemName": "Bachelor of Business Administration - Supply Chain Management"
            },
            {
                "id": 5433,
                "itemName": "4-year BA in environmental studies"
            },
            {
                "id": 5434,
                "itemName": "4-year BA in psychology"
            },
            {
                "id": 5435,
                "itemName": "4-year BA in English"
            },
            {
                "id": 5436,
                "itemName": "3-year BA with a concentration in sociology"
            },
            {
                "id": 5437,
                "itemName": "4-year BSc in environmental studies"
            },
            {
                "id": 5438,
                "itemName": "3-year BA with a concentration in biology"
            },
            {
                "id": 5439,
                "itemName": "3-year BA with a concentration in music"
            },
            {
                "id": 5440,
                "itemName": "BEd (After Degree) in Elementary Education"
            },
            {
                "id": 5441,
                "itemName": "4-year BSc in chemistry"
            },
            {
                "id": 5442,
                "itemName": "4-year BSc in biology"
            },
            {
                "id": 5443,
                "itemName": "3-year BA with a concentration in history"
            },
            {
                "id": 5444,
                "itemName": "3-year BA with a concentration in philosophy"
            },
            {
                "id": 5445,
                "itemName": "3-year BA with a concentration in chemistry"
            },
            {
                "id": 5446,
                "itemName": "3-year BA with a concentration in psychology"
            },
            {
                "id": 5447,
                "itemName": "3-year BA with a concentration in the social science group"
            },
            {
                "id": 5448,
                "itemName": "3-year BA with a concentration in English"
            },
            {
                "id": 5449,
                "itemName": "4-year BSc in computing science"
            },
            {
                "id": 5450,
                "itemName": "4-year BA in Music"
            },
            {
                "id": 5451,
                "itemName": "4-year BMus"
            },
            {
                "id": 5452,
                "itemName": "3-year BA with a concentration in theology"
            },
            {
                "id": 5453,
                "itemName": "3-year BSc with a concentration in biology"
            },
            {
                "id": 5454,
                "itemName": "3-year BSc with a concentration in chemistry"
            },
            {
                "id": 5455,
                "itemName": "4-year BA in politics, history and economics"
            },
            {
                "id": 5456,
                "itemName": "4-year BA in History"
            },
            {
                "id": 5457,
                "itemName": "BEd (After Degree) in Secondary Education"
            },
            {
                "id": 5458,
                "itemName": "4-year BA in sociology"
            },
            {
                "id": 5459,
                "itemName": "Bachelor of Arts - Applied Behaviour Analysis (Autism)"
            },
            {
                "id": 5460,
                "itemName": "Bachelor of Arts with a Major in Interdisciplinary Studies"
            },
            {
                "id": 5461,
                "itemName": "Bachelor of Legal Studies (Paralegal)"
            },
            {
                "id": 5462,
                "itemName": "Bachelor of Early Childhood Care and Education"
            },
            {
                "id": 5463,
                "itemName": "Bachelor of Music Therapy Degree"
            },
            {
                "id": 5464,
                "itemName": "Bachelor of Design in Visual Communication"
            },
            {
                "id": 5465,
                "itemName": "Bachelor of Music in Jazz Studies - Education"
            },
            {
                "id": 5466,
                "itemName": "Bachelor of Music in Jazz Studies - Performance/Composition"
            },
            {
                "id": 5467,
                "itemName": "Bachelor of Motion Picture Arts"
            },
            {
                "id": 5468,
                "itemName": "Bachelor of Performing Arts"
            },
            {
                "id": 5469,
                "itemName": "Bachelor of Tourism Management"
            },
            {
                "id": 5470,
                "itemName": "College and University Preparation Citation"
            },
            {
                "id": 5471,
                "itemName": "Adult Basic Education - General Upgrading"
            },
            {
                "id": 5472,
                "itemName": "Business Administration Pathway"
            },
            {
                "id": 5473,
                "itemName": "Career Access"
            },
            {
                "id": 5474,
                "itemName": "Bachelor of Arts - Double Minor"
            },
            {
                "id": 5475,
                "itemName": "Bachelor of Applied Arts in Psychology"
            },
            {
                "id": 5476,
                "itemName": "Bachelor of Business Administration in Accounting"
            },
            {
                "id": 5477,
                "itemName": "Bachelor of Business Administration in Entrepreneurial Leadership"
            },
            {
                "id": 5478,
                "itemName": "Bachelor of Business Administration in Human Resources Management"
            },
            {
                "id": 5479,
                "itemName": "Bachelor of Technology in Information Technology"
            },
            {
                "id": 5480,
                "itemName": "Bachelor of Design, Fashion and Technology"
            },
            {
                "id": 5481,
                "itemName": "Bachelor of Design, Graphic Design for Marketing"
            },
            {
                "id": 5482,
                "itemName": "Associate of Science"
            },
            {
                "id": 5483,
                "itemName": "Bachelor of Fine Arts, Visual Arts"
            },
            {
                "id": 5484,
                "itemName": "Bachelor of Design, Product Design"
            },
            {
                "id": 5485,
                "itemName": "Bachelor of Horticulture Science"
            },
            {
                "id": 5486,
                "itemName": "Bachelor of Science, Applied Psychology"
            },
            {
                "id": 5487,
                "itemName": "Bachelor of Business Administration in Marketing Management"
            },
            {
                "id": 5488,
                "itemName": "Bachelor of Applied Science in Sustainable Agriculture"
            },
            {
                "id": 5489,
                "itemName": "Bachelor of Science in Nursing, Post-Baccalaureate"
            },
            {
                "id": 5490,
                "itemName": "Minors"
            },
            {
                "id": 5491,
                "itemName": "BCom in Entrepreneurial Management"
            },
            {
                "id": 5492,
                "itemName": "BA in Professional Communication"
            },
            {
                "id": 5493,
                "itemName": "BA in Justice Studies"
            },
            {
                "id": 5494,
                "itemName": "BA in International Hotel Management"
            },
            {
                "id": 5495,
                "itemName": "BA in Global Tourism Management"
            },
            {
                "id": 5496,
                "itemName": "BA in Environmental Practice"
            },
            {
                "id": 5497,
                "itemName": "Bachelor of Business Administration in Business and Sustainability"
            },
            {
                "id": 5498,
                "itemName": "BA in Interdisciplinary Studies"
            },
            {
                "id": 5499,
                "itemName": "BSc in Environmental Practice"
            },
            {
                "id": 5500,
                "itemName": "Bachelor of Computing Science "
            },
            {
                "id": 5501,
                "itemName": "Bachelor of Arts - Psychology Major"
            },
            {
                "id": 5502,
                "itemName": "Bachelor of Arts - History Major"
            },
            {
                "id": 5503,
                "itemName": "Bachelor of Arts - English Major"
            },
            {
                "id": 5504,
                "itemName": "Bachelor of Education (Elementary)"
            },
            {
                "id": 5505,
                "itemName": "Bachelor of Arts - Philosophy Major"
            },
            {
                "id": 5506,
                "itemName": "Bachelor of Arts - Mathematics Major"
            },
            {
                "id": 5507,
                "itemName": "Bachelor of Arts - Economic Major"
            },
            {
                "id": 5508,
                "itemName": "Bachelor of Arts - Sociology Major"
            },
            {
                "id": 5509,
                "itemName": "Bachelor of Science - Computing Science Major"
            },
            {
                "id": 5510,
                "itemName": "Bachelor of Natural Resource Science"
            },
            {
                "id": 5511,
                "itemName": "Bachelor of Science - Physics Major"
            },
            {
                "id": 5512,
                "itemName": "Bachelor of Science - Environmental Chemistry Major"
            },
            {
                "id": 5513,
                "itemName": "Bachelor of Science - Chemistry Major"
            },
            {
                "id": 5514,
                "itemName": "Bachelor of Science - Biology Major"
            },
            {
                "id": 5515,
                "itemName": "Bachelor of Science - Ecology and Environmental Biology Major"
            },
            {
                "id": 5516,
                "itemName": "Bachelor of Science - Animal Biology Major"
            },
            {
                "id": 5517,
                "itemName": "BA in Creative &  Performing Arts"
            },
            {
                "id": 5518,
                "itemName": "BA in Visual Arts"
            },
            {
                "id": 5519,
                "itemName": "Bachelor of Health Science"
            },
            {
                "id": 5520,
                "itemName": "Bachelor of Arts,  Major Program, English"
            },
            {
                "id": 5521,
                "itemName": "Bachelor of Music (Performance)"
            },
            {
                "id": 5522,
                "itemName": "Bachelor of Fine Arts, Georgian College (Open Learning)"
            },
            {
                "id": 5523,
                "itemName": "Bachelor of General Studies"
            },
            {
                "id": 5524,
                "itemName": "Bachelor of Arts, General Program"
            },
            {
                "id": 5525,
                "itemName": "Bachelor of Arts, Major Program, History"
            },
            {
                "id": 5526,
                "itemName": "Bachelor of Arts, Major Program, Sociology"
            },
            {
                "id": 5527,
                "itemName": "Bachelor of Arts, Major Program, Psychology"
            },
            {
                "id": 5528,
                "itemName": "Bachelor of Arts, General Studies"
            },
            {
                "id": 5529,
                "itemName": "Bachelor of Science - Cellular, Molecular and Microbial Biology Major"
            },
            {
                "id": 5530,
                "itemName": "Bachelor of Arts (BA) General Arts"
            },
            {
                "id": 5531,
                "itemName": "Bachelor of Arts - Economics and Political Studies Major"
            },
            {
                "id": 5532,
                "itemName": "Bachelor of Arts - Theatre Arts Major"
            },
            {
                "id": 5533,
                "itemName": "Bachelor of Science - Chemical Biology Major"
            },
            {
                "id": 5534,
                "itemName": "BSc in Archeology and Geology Minor"
            },
            {
                "id": 5535,
                "itemName": "Engineering Transfer Program"
            },
            {
                "id": 5536,
                "itemName": "Bachelor of Business Administration - Accounting Major"
            },
            {
                "id": 5537,
                "itemName": "Bachelor of Business Administration - Economics Major"
            },
            {
                "id": 5538,
                "itemName": "Bachelor of Business Administration - Finance Major"
            },
            {
                "id": 5539,
                "itemName": "Bachelor of Business Administration - Human Resource Management Major"
            },
            {
                "id": 5540,
                "itemName": "Bachelor of Business Administration - Marketing Major"
            },
            {
                "id": 5541,
                "itemName": "Bachelor of Interdisciplinary Studies"
            },
            {
                "id": 5542,
                "itemName": "Bachelor of Business Administration - International Business Major"
            },
            {
                "id": 5543,
                "itemName": "Pre-Professional Health Sciences (BSc)"
            },
            {
                "id": 5544,
                "itemName": "Bachelor of Science - Mathematical Sciences Major"
            },
            {
                "id": 5545,
                "itemName": "Bachelor of Education (Trades and Technology Education)"
            },
            {
                "id": 5546,
                "itemName": "BA in Management"
            },
            {
                "id": 5547,
                "itemName": "Bachelor of Technology, Trades and Technology Leadership"
            },
            {
                "id": 5548,
                "itemName": "BA in Environmental Economics and Sustainable Development"
            },
            {
                "id": 5549,
                "itemName": "BSc in Environmental Economics and Sustainable Development"
            },
            {
                "id": 5550,
                "itemName": "Bachelor of Business Administration and Bachelor of Computing Science: Double Degree"
            },
            {
                "id": 5551,
                "itemName": "Bachelor of Arts - Mathematics and Economics Major"
            },
            {
                "id": 5552,
                "itemName": "Bachelor of Science - Economics and Mathematics Major"
            },
            {
                "id": 5553,
                "itemName": "Bachelor of Science - Mathematics and Computing Science Major"
            },
            {
                "id": 5554,
                "itemName": "Bachelor of Arts - Communication Major"
            },
            {
                "id": 5555,
                "itemName": "Bachelor of Arts - Geography and Environmental Studies Major"
            },
            {
                "id": 5556,
                "itemName": "Bachelor of Fine Arts"
            },
            {
                "id": 5557,
                "itemName": "Bachelor of Education (Secondary) Science -Technology, Engineering and Mathematics"
            },
            {
                "id": 5558,
                "itemName": "Respiratory Therapy Program"
            },
            {
                "id": 5559,
                "itemName": "Anesthesia Assistant Post-Diploma"
            },
            {
                "id": 5560,
                "itemName": "BSc - Computing Science Minor"
            },
            {
                "id": 5561,
                "itemName": "Bachelor of Business Administration - Entrepreneurship Major"
            },
            {
                "id": 5562,
                "itemName": "Bachelor of Business Administration – Supply Chain Management Major"
            },
            {
                "id": 5563,
                "itemName": "BA in Biblical Studies"
            },
            {
                "id": 5564,
                "itemName": "BA in Inter-Cultural Religious Studies"
            },
            {
                "id": 5565,
                "itemName": "BA in Christianity and Culture"
            },
            {
                "id": 5566,
                "itemName": "BA in Theatre"
            },
            {
                "id": 5567,
                "itemName": "BA in Art and Design"
            },
            {
                "id": 5568,
                "itemName": "BA in Media and Communication"
            },
            {
                "id": 5569,
                "itemName": "BA in Arts, Media and Culture"
            },
            {
                "id": 5570,
                "itemName": "BA in Business Administration"
            },
            {
                "id": 5571,
                "itemName": "BSc in Biology (Honours)"
            },
            {
                "id": 5572,
                "itemName": "BSc in Natural and Applied Sciences"
            },
            {
                "id": 5573,
                "itemName": "BSc in Mathematics with Computing Science"
            },
            {
                "id": 5574,
                "itemName": "BA in English (Honours)"
            },
            {
                "id": 5575,
                "itemName": "BA in Biblical Studies (Honours)"
            },
            {
                "id": 5576,
                "itemName": "BA in History (Honours)"
            },
            {
                "id": 5577,
                "itemName": "BA in Psychology (Honours)"
            },
            {
                "id": 5578,
                "itemName": "BA/BEd or BSc/BEd (K-7)"
            },
            {
                "id": 5579,
                "itemName": "BA/BEd or BSc/BEd (secondary)"
            },
            {
                "id": 5580,
                "itemName": "Bachelor of Human Kinetics"
            },
            {
                "id": 5581,
                "itemName": "Post-degree BEd (K-12)"
            },
            {
                "id": 5582,
                "itemName": "BA in Sport and Leisure Management"
            },
            {
                "id": 5583,
                "itemName": "BA in European Studies"
            },
            {
                "id": 5584,
                "itemName": "BA in General Studies"
            },
            {
                "id": 5585,
                "itemName": "BA in International Studies (Honours)"
            },
            {
                "id": 5586,
                "itemName": "BA in World Languages and Cultures"
            },
            {
                "id": 5587,
                "itemName": "BA in Political Studies (Honours)"
            },
            {
                "id": 5588,
                "itemName": "BSc in General Studies"
            },
            {
                "id": 5589,
                "itemName": "BSc in Chemistry (Honours)"
            },
            {
                "id": 5590,
                "itemName": "BSc in Biotechnology (Honours)"
            },
            {
                "id": 5591,
                "itemName": "BSc in Biotechnology (Multidisciplinary)"
            },
            {
                "id": 5592,
                "itemName": "BSc in Biotechnology (Interdisciplinary)"
            },
            {
                "id": 5593,
                "itemName": "BA in Leadership"
            },
            {
                "id": 5594,
                "itemName": "BA in Philosophy (Honours)"
            },
            {
                "id": 5595,
                "itemName": "Post-Degree BEd (K-7)"
            },
            {
                "id": 5596,
                "itemName": "BFA in Acting"
            },
            {
                "id": 5597,
                "itemName": "BA in Corporate Communication"
            },
            {
                "id": 5598,
                "itemName": "BSc in Computing Science"
            },
            {
                "id": 5599,
                "itemName": "BA in Art and Design (Honours)"
            },
            {
                "id": 5600,
                "itemName": "Bachelor of science in physical geography (major, minor)"
            },
            {
                "id": 5601,
                "itemName": "Bachelor of arts in adult education"
            },
            {
                "id": 5602,
                "itemName": "Bachelor of arts in child and youth care"
            },
            {
                "id": 5603,
                "itemName": "Bachelor of business administration in aviation"
            },
            {
                "id": 5604,
                "itemName": "BA in geography (major, extended minor, minor)"
            },
            {
                "id": 5605,
                "itemName": "BA in psychology (major, extended minor)"
            },
            {
                "id": 5606,
                "itemName": "BA in sociology/anthropology (major)"
            },
            {
                "id": 5607,
                "itemName": "BA in history (major, extended minor, minor)"
            },
            {
                "id": 5608,
                "itemName": "BA in English (major, extended minor, minor)"
            },
            {
                "id": 5609,
                "itemName": "BA in mathematics (major, extended minor, minor)"
            },
            {
                "id": 5610,
                "itemName": "Bachelor of science in chemistry (major, minor)"
            },
            {
                "id": 5611,
                "itemName": "Bachelor of science in mathematics (major, minor, extended minor)"
            },
            {
                "id": 5612,
                "itemName": "Bachelor of science in physics (major, minor)"
            },
            {
                "id": 5613,
                "itemName": "Bachelor of science"
            },
            {
                "id": 5614,
                "itemName": "BA in sociology (major, extended minor, minor)"
            },
            {
                "id": 5615,
                "itemName": "Bachelor of Arts in Criminal Justice"
            },
            {
                "id": 5616,
                "itemName": "Bachelor of Fine Arts in Visual Arts (major, extended minor, minor)"
            },
            {
                "id": 5617,
                "itemName": "Bachelor of business administration (honours)"
            },
            {
                "id": 5618,
                "itemName": "Bachelor of business administration in aviation (honours)"
            },
            {
                "id": 5619,
                "itemName": "Bachelor of science in physics (honours)"
            },
            {
                "id": 5620,
                "itemName": "Bachelor of business administration for trades management"
            },
            {
                "id": 5621,
                "itemName": "BA in psychology (honours)"
            },
            {
                "id": 5622,
                "itemName": "Bachelor of business administration for agriculture management"
            },
            {
                "id": 5623,
                "itemName": "Bachelor of computer information systems"
            },
            {
                "id": 5624,
                "itemName": "BA in geography (honours)"
            },
            {
                "id": 5625,
                "itemName": "BA in philosophy (major, extended minor, minor)"
            },
            {
                "id": 5626,
                "itemName": "BA in political science (major, extended minor, minor)"
            },
            {
                "id": 5627,
                "itemName": "Bachelor of Fine Arts in Art History (extended minor, minor)"
            },
            {
                "id": 5628,
                "itemName": "Bachelor of Fine Arts in Creative Writing (extended minor, minor)"
            },
            {
                "id": 5629,
                "itemName": "Bachelor of Fine Arts in Fashion (extended minor, minor)"
            },
            {
                "id": 5630,
                "itemName": "Bachelor of Fine Arts in Graphic and Digital Design (extended minor, minor)"
            },
            {
                "id": 5631,
                "itemName": "Bachelor of Fine Arts in Media and Communications (extended minor, minor)"
            },
            {
                "id": 5632,
                "itemName": "Bachelor of Fine Arts in Theatre (extended minor, minor)"
            },
            {
                "id": 5633,
                "itemName": "BA in anthropology (extended minor, minor)"
            },
            {
                "id": 5634,
                "itemName": "BA in art history (extended minor, minor)"
            },
            {
                "id": 5635,
                "itemName": "BA in biology (extended minor)"
            },
            {
                "id": 5636,
                "itemName": "BA in computer information systems (extended minor, minor)"
            },
            {
                "id": 5637,
                "itemName": "BA in Global Development (Extended minor, minor)"
            },
            {
                "id": 5638,
                "itemName": "BA in French (major, extended minor, minor)"
            },
            {
                "id": 5639,
                "itemName": "BA in Latin American studies (extended minor, minor)"
            },
            {
                "id": 5640,
                "itemName": "BA in mathematics (statistics option) (extended minor, minor)"
            },
            {
                "id": 5641,
                "itemName": "BA in media and communication studies (extended minor, minor)"
            },
            {
                "id": 5642,
                "itemName": "BA in theatre (major, extended minor, minor)"
            },
            {
                "id": 5643,
                "itemName": "BA in visual arts (major, extended minor, minor)"
            },
            {
                "id": 5644,
                "itemName": "Bachelor of science in physical geography (honours)"
            },
            {
                "id": 5645,
                "itemName": "BA in applied ethical and political philosophy (minor)"
            },
            {
                "id": 5646,
                "itemName": "BA in business administration (minor)"
            },
            {
                "id": 5647,
                "itemName": "BA in criminal justice (extended minor, minor)"
            },
            {
                "id": 5648,
                "itemName": "BA in communications (minor)"
            },
            {
                "id": 5649,
                "itemName": "BA in Economics (major, minor)"
            },
            {
                "id": 5650,
                "itemName": "BA in kinesiology (minor)"
            },
            {
                "id": 5651,
                "itemName": "Bachelor of Arts in Criminal Justice Honours"
            },
            {
                "id": 5652,
                "itemName": "BA in Indigenous Studies (major, minor)"
            },
            {
                "id": 5653,
                "itemName": "BA in Media Arts"
            },
            {
                "id": 5654,
                "itemName": "BA in English - Creative Writing (honours)"
            },
            {
                "id": 5655,
                "itemName": "BA in Peace and Conflict Studies (major, minor)"
            },
            {
                "id": 5656,
                "itemName": "Kinesiology minor (for BGS, BA, and BSC students)"
            },
            {
                "id": 5657,
                "itemName": "Bachelor of science in biology (major, minor)"
            },
            {
                "id": 5658,
                "itemName": "Bachelor of science in computing science (major, minor)"
            },
            {
                "id": 5659,
                "itemName": "BA in applied statistics (minor)"
            },
            {
                "id": 5660,
                "itemName": "Bachelor of Integrated Studies - Aviation Theme"
            },
            {
                "id": 5661,
                "itemName": "Bachelor of Arts (Major/Minor) Criminology"
            },
            {
                "id": 5662,
                "itemName": "Bachelor of Arts (Major/Minor) Creative Writing"
            },
            {
                "id": 5663,
                "itemName": "Bachelor of Arts (Major/Honours/Minor) Anthropology"
            },
            {
                "id": 5664,
                "itemName": "Bachelor of Arts (Major/Honours/Minor) Psychology"
            },
            {
                "id": 5665,
                "itemName": "Bachelor of Arts (Major/Minor) Liberal Studies"
            },
            {
                "id": 5666,
                "itemName": "Bachelor of Arts (Major/Honours/Minor) History"
            },
            {
                "id": 5667,
                "itemName": "Bachelor of Arts (Major/Minor) Indigenous/Xwulmuxw Studies"
            },
            {
                "id": 5668,
                "itemName": "Bachelor of Arts (Major/Honours/Minor) English"
            },
            {
                "id": 5669,
                "itemName": "Bachelor of Science (Major/Honours/Minor) Biology"
            },
            {
                "id": 5670,
                "itemName": "Bachelor of Arts in Child and Youth Care"
            },
            {
                "id": 5671,
                "itemName": "Bachelor of Science (Major/Minor) Mathematics"
            },
            {
                "id": 5672,
                "itemName": "Bachelor of Science (Minor) Earth Science"
            },
            {
                "id": 5673,
                "itemName": "Bachelor of Science (Major/Honours/Minor) Computer Science"
            },
            {
                "id": 5674,
                "itemName": "Bachelor of Arts (Major/Honours/Minor) Geography"
            },
            {
                "id": 5675,
                "itemName": "Bachelor of Arts (Major/Minor) Visual Art"
            },
            {
                "id": 5676,
                "itemName": "Bachelor of Arts (Major/Minor) Studies in Women and Gender"
            },
            {
                "id": 5677,
                "itemName": "Bachelor of Arts (Major/Minor) Global Studies"
            },
            {
                "id": 5678,
                "itemName": "Bachelor of Arts (Major/Honours/Minor) Sociology"
            },
            {
                "id": 5679,
                "itemName": "Bachelor of Education Post Baccalaureate"
            },
            {
                "id": 5680,
                "itemName": "Bachelor of Business Administration (Major) Accounting"
            },
            {
                "id": 5681,
                "itemName": "Bachelor of Music in Jazz Studies"
            },
            {
                "id": 5682,
                "itemName": "Bachelor of Natural Resource Protection"
            },
            {
                "id": 5683,
                "itemName": "Bachelor of Science (Major/Minor) Psychology"
            },
            {
                "id": 5684,
                "itemName": "Bachelor of Science (Major/Minor) Chemistry"
            },
            {
                "id": 5685,
                "itemName": "Bachelor of Science (Minor) Geography"
            },
            {
                "id": 5686,
                "itemName": "Bachelor of Arts (Minor) Business"
            },
            {
                "id": 5687,
                "itemName": "Bachelor of Arts (Minor) Computer Science"
            },
            {
                "id": 5688,
                "itemName": "Bachelor of Arts (Major/Minor) Economics"
            },
            {
                "id": 5689,
                "itemName": "Bachelor of Arts (Major/Honours/Minor) Philosophy"
            },
            {
                "id": 5690,
                "itemName": "Bachelor of Arts (Major/Minor) Political Studies"
            },
            {
                "id": 5691,
                "itemName": "Bachelor of Hospitality Management"
            },
            {
                "id": 5692,
                "itemName": "Bachelor of Arts Major/Minor in Digital Media"
            },
            {
                "id": 5693,
                "itemName": "Bachelor of Science (Major) Geoscience"
            },
            {
                "id": 5694,
                "itemName": "Bachelor of Design in Graphic Design"
            },
            {
                "id": 5695,
                "itemName": "Bachelor of Arts (Major/Minor) Mathematics"
            },
            {
                "id": 5696,
                "itemName": "Bachelor of Arts (Minor) Journalism"
            },
            {
                "id": 5697,
                "itemName": "Bachelor of Arts (Minor) Earth Science"
            },
            {
                "id": 5698,
                "itemName": "Bachelor of Arts (Minor) Language & Culture (Romance Languages)"
            },
            {
                "id": 5699,
                "itemName": "Bachelor of Arts (Minor) Biology"
            },
            {
                "id": 5700,
                "itemName": "Bachelor of Arts (Minor) Chemistry"
            },
            {
                "id": 5701,
                "itemName": "Bachelor of Business Administration (Major) Economics"
            },
            {
                "id": 5702,
                "itemName": "Bachelor of Business Administration (Major) Financial Services"
            },
            {
                "id": 5703,
                "itemName": "Bachelor of Business Administration (Major) Human Resources Management"
            },
            {
                "id": 5704,
                "itemName": "Bachelor of Business Administration (Major) International Business"
            },
            {
                "id": 5705,
                "itemName": "Bachelor of Business Administration (Major) Management"
            },
            {
                "id": 5706,
                "itemName": "Bachelor of Arts (Minor) Kinesiology"
            },
            {
                "id": 5707,
                "itemName": "Bachelor of Business Administration (Major) Marketing"
            },
            {
                "id": 5708,
                "itemName": "BA General (3-year)"
            },
            {
                "id": 5709,
                "itemName": "BA Major in Biblical and Theological Studies (3-year)"
            },
            {
                "id": 5710,
                "itemName": "BA Major in Biblical and Theological Studies (4-year)"
            },
            {
                "id": 5711,
                "itemName": "BA Major in Social Science (3-year)"
            },
            {
                "id": 5712,
                "itemName": "BA Major in Social Science (4-year)"
            },
            {
                "id": 5713,
                "itemName": "BA Major in English (4-year)"
            },
            {
                "id": 5714,
                "itemName": "BA Major in English (3-year)"
            },
            {
                "id": 5715,
                "itemName": "BA Minor in Economics"
            },
            {
                "id": 5716,
                "itemName": "BA Major in Geography (3-year)"
            },
            {
                "id": 5717,
                "itemName": "BA Major in History (3-year)"
            },
            {
                "id": 5718,
                "itemName": "BA Major in History (4-year)"
            },
            {
                "id": 5719,
                "itemName": "BA Major in International Development Studies (4-year)"
            },
            {
                "id": 5720,
                "itemName": "BA Major in International Development Studies (3-year)"
            },
            {
                "id": 5721,
                "itemName": "BA Major in Mathematics (3-year)"
            },
            {
                "id": 5722,
                "itemName": "BA Major in Mathematics (4-year)"
            },
            {
                "id": 5723,
                "itemName": "BA Major in Philosophy (4-year)"
            },
            {
                "id": 5724,
                "itemName": "BA Major in Philosophy (3-year)"
            },
            {
                "id": 5725,
                "itemName": "BA Major in Political Studies (4-year)"
            },
            {
                "id": 5726,
                "itemName": "BA Major in Political Studies (3-year)"
            },
            {
                "id": 5727,
                "itemName": "BA Major in Psychology (3-year)"
            },
            {
                "id": 5728,
                "itemName": "BA Major in Psychology (4-year)"
            },
            {
                "id": 5729,
                "itemName": "BA Minor in Sociology"
            },
            {
                "id": 5730,
                "itemName": "Bachelor of Music (4-year)"
            },
            {
                "id": 5731,
                "itemName": "Bachelor of Music Therapy (4-year)"
            },
            {
                "id": 5732,
                "itemName": "BA Major in Music (4-year)"
            },
            {
                "id": 5733,
                "itemName": "BA Major in Music (3-year)"
            },
            {
                "id": 5734,
                "itemName": "BA Major in Peace and Conflict Transformation Studies (4-year)"
            },
            {
                "id": 5735,
                "itemName": "BA Major in Peace and Conflict Transformation Studies (3-year)"
            },
            {
                "id": 5736,
                "itemName": "BA Honours, Major in Biblical and Theological Studies (4-year)"
            },
            {
                "id": 5737,
                "itemName": "BA Major in Communications and Media (4-year)"
            },
            {
                "id": 5738,
                "itemName": "BA Major in Communications and Media (3-year)"
            },
            {
                "id": 5739,
                "itemName": "BA Major in Business and Organizational Administration (3-year)"
            },
            {
                "id": 5740,
                "itemName": "BA Major in Business and Organizational Administration (4-year)"
            },
            {
                "id": 5741,
                "itemName": "Bachelor of Business Administration (4-year)"
            },
            {
                "id": 5742,
                "itemName": "BA Major in Arts and Science (4-year)"
            },
            {
                "id": 5743,
                "itemName": "BA Major in Humanities (4-year)"
            },
            {
                "id": 5744,
                "itemName": "BA Honours, Major in Interdisciplinary Studies (4-year)"
            },
            {
                "id": 5745,
                "itemName": "BA Major in Interdisciplinary Studies (4-year)"
            },
            {
                "id": 5746,
                "itemName": "BA Major in Environmental Studies (4-year)"
            },
            {
                "id": 5747,
                "itemName": "BSc Major in Biology (4-year)"
            },
            {
                "id": 5748,
                "itemName": "BSc Major in General Studies (3-year)"
            },
            {
                "id": 5749,
                "itemName": "BSc Major in Mathematics (4-year)"
            },
            {
                "id": 5750,
                "itemName": "BSc Major in Mathematics (3-year)"
            },
            {
                "id": 5751,
                "itemName": "BA Major in Communication and English"
            },
            {
                "id": 5752,
                "itemName": "Baccalauréat en éducation secondaire"
            },
            {
                "id": 5753,
                "itemName": "Baccalauréat en éducation élémentaire"
            },
            {
                "id": 5754,
                "itemName": "BSc en mathématiques"
            },
            {
                "id": 5755,
                "itemName": "BA en géographie"
            },
            {
                "id": 5756,
                "itemName": "BA en sciences politiques"
            },
            {
                "id": 5757,
                "itemName": "BA en anthropologie"
            },
            {
                "id": 5758,
                "itemName": "BA en anglais"
            },
            {
                "id": 5759,
                "itemName": "BA en sciences religieuses"
            },
            {
                "id": 5760,
                "itemName": "BSc avec majeure conjointe en biochimie/microbiologie"
            },
            {
                "id": 5761,
                "itemName": "BSc en sciences naturelles"
            },
            {
                "id": 5762,
                "itemName": "BA spécialisé en traduction"
            },
            {
                "id": 5763,
                "itemName": "BA en espagnol"
            },
            {
                "id": 5764,
                "itemName": "BA avec double spécialisation en histoire/leadership interculturel"
            },
            {
                "id": 5765,
                "itemName": "BA avec double spécialisation en histoire/études internationales"
            },
            {
                "id": 5766,
                "itemName": "Bachelor of design with major in interdisciplinary design"
            },
            {
                "id": 5767,
                "itemName": "Bachelor of fine arts with major in fine art"
            },
            {
                "id": 5768,
                "itemName": "Bachelor of fine arts with major in film"
            },
            {
                "id": 5769,
                "itemName": "Bachelor of fine arts with major in photography"
            },
            {
                "id": 5770,
                "itemName": "Bachelor of fine arts with major in jewellery design and metal-smithing"
            },
            {
                "id": 5771,
                "itemName": "Bachelor of fine arts with major in textiles/fashion"
            },
            {
                "id": 5772,
                "itemName": "Bachelor of fine arts with major in ceramics"
            },
            {
                "id": 5773,
                "itemName": "BA with major in art history"
            },
            {
                "id": 5774,
                "itemName": "Bachelor of fine arts with major in intermedia"
            },
            {
                "id": 5775,
                "itemName": "Bachelor of fine arts (interdisciplinary)"
            },
            {
                "id": 5776,
                "itemName": "Baccalauréat ès arts avec spécialisation en français"
            },
            {
                "id": 5777,
                "itemName": "Baccalauréat ès arts avec majeure"
            },
            {
                "id": 5778,
                "itemName": "Baccalauréat en administration des affaires/Coop"
            },
            {
                "id": 5779,
                "itemName": "Baccalauréat ès arts sans majeure"
            },
            {
                "id": 5780,
                "itemName": "Diplôme ès sciences pré-vétérinaires (2 ans)"
            },
            {
                "id": 5781,
                "itemName": "BSc sans majeure (3 ans)"
            },
            {
                "id": 5782,
                "itemName": "Diplôme préparations en sciences de la santé (2 ans)"
            },
            {
                "id": 5783,
                "itemName": "B.Éd.: programme de 2 ans après le B.A. ou le B.Sc. (secondaire ou élémentaire)"
            },
            {
                "id": 5784,
                "itemName": "B.A. majeure en français (avec 2ième volet)/B.Éd. secondaire (FLM ou FLS) (5 ans)"
            },
            {
                "id": 5785,
                "itemName": "B.A. majeure en français/B.Éd. enseignement à l'élémentaire (FLM ou FLS) (5 ans)"
            },
            {
                "id": 5786,
                "itemName": "B.A. majeure en anglais (avec 2ième volet)/ B.Éd. enseignement au secondaire (5 ans)"
            },
            {
                "id": 5787,
                "itemName": "B.A. majeure en histoire (avec un 2ième volet)/ B.Éd. enseignement au secondaire (5 ans)"
            },
            {
                "id": 5788,
                "itemName": "B.Sc./B.Éd. enseignement au secondaire (5 ans)"
            },
            {
                "id": 5789,
                "itemName": "BSc majeure en biologie (4 ans)"
            },
            {
                "id": 5790,
                "itemName": "B.Sc. majeure en biologie (avec un 2ième volet/B.Éd. enseignement au secondaire (5 ans)"
            },
            {
                "id": 5791,
                "itemName": "BSc majeure en biologie (4 1/2 ans) / coop"
            },
            {
                "id": 5792,
                "itemName": "Bachelor of Journalism (post-baccalaureate)"
            },
            {
                "id": 5793,
                "itemName": "Bachelor of Journalism (Honours)"
            },
            {
                "id": 5794,
                "itemName": "BA (combined honours) in Contemporary Studies"
            },
            {
                "id": 5795,
                "itemName": "BA (combined honours) in Early Modern Studies"
            },
            {
                "id": 5796,
                "itemName": "BA (combined honours) in History of Science and Technology"
            },
            {
                "id": 5797,
                "itemName": "Foundation Year Program"
            },
            {
                "id": 5798,
                "itemName": "BA, BSc and BMus programs (with Dalhousie University)"
            },
            {
                "id": 5799,
                "itemName": "BSc (Combined Honours) in Contemporary Studies (second subject)"
            },
            {
                "id": 5800,
                "itemName": "BSc (Combined Honours) in Early Modern Studies (second subject)"
            },
            {
                "id": 5801,
                "itemName": "BSc (Combined Honours) in History of Science and Technology (second subject)"
            },
            {
                "id": 5802,
                "itemName": "Minor in Journalism"
            },
            {
                "id": 5803,
                "itemName": "BA (combined honours) in History of Science and Technology Program (HOST)"
            },
            {
                "id": 5804,
                "itemName": "BA (General) in Accounting"
            },
            {
                "id": 5805,
                "itemName": "BA (General) in Community Development"
            },
            {
                "id": 5806,
                "itemName": "BA (General) in Economics"
            },
            {
                "id": 5807,
                "itemName": "BA (four-year) in Finance and Economics"
            },
            {
                "id": 5808,
                "itemName": "BA (General) in English"
            },
            {
                "id": 5809,
                "itemName": "BA (four-year)  in English"
            },
            {
                "id": 5810,
                "itemName": "BA (General) in Fine Art Studies"
            },
            {
                "id": 5811,
                "itemName": "BA (General) in Geography"
            },
            {
                "id": 5812,
                "itemName": "BA (General) in History"
            },
            {
                "id": 5813,
                "itemName": "BA (four-year) in History"
            },
            {
                "id": 5814,
                "itemName": "BA (General) in Law and Justice"
            },
            {
                "id": 5815,
                "itemName": "BA (four-year) in Law and Justice"
            },
            {
                "id": 5816,
                "itemName": "BSc (four-year) in Biology"
            },
            {
                "id": 5817,
                "itemName": "BA (General) in Ojibway"
            },
            {
                "id": 5818,
                "itemName": "BA (General) in Music"
            },
            {
                "id": 5819,
                "itemName": "BA (four-year) in Political Science"
            },
            {
                "id": 5820,
                "itemName": "BA (General) in Political Science"
            },
            {
                "id": 5821,
                "itemName": "BA (General) in Psychology"
            },
            {
                "id": 5822,
                "itemName": "BA (four-year) in Psychology"
            },
            {
                "id": 5823,
                "itemName": "BA (General) in Sociology"
            },
            {
                "id": 5824,
                "itemName": "BSc (General) in Computer Science"
            },
            {
                "id": 5825,
                "itemName": "BSc (four-year) in Computer Science"
            },
            {
                "id": 5826,
                "itemName": "Bachelor of Computer Science (three-year/four-year)"
            },
            {
                "id": 5827,
                "itemName": "BSc (General) in Biology"
            },
            {
                "id": 5828,
                "itemName": "BA (four-year) in Community Economic and Social Development"
            },
            {
                "id": 5829,
                "itemName": "BSc (four-year) in Psychology"
            },
            {
                "id": 5830,
                "itemName": "BA (General) in Community Economic and Social Development"
            },
            {
                "id": 5831,
                "itemName": "BA (four-year) in Sociology"
            },
            {
                "id": 5832,
                "itemName": "BA (four-year) in Geography"
            },
            {
                "id": 5833,
                "itemName": "BSc (four-year) in Environmental Science"
            },
            {
                "id": 5834,
                "itemName": "BA (General) in Anishinaabe Studies"
            },
            {
                "id": 5835,
                "itemName": "BSc in Foods and Nutrition (Major/Specialization/Honours Specialization)"
            },
            {
                "id": 5836,
                "itemName": "BA in Sociology (Major/Specialization/Honors Specialization)"
            },
            {
                "id": 5837,
                "itemName": "BA in History (Major/Specialization/Honors Specialization)"
            },
            {
                "id": 5838,
                "itemName": "BA in Psychology (Major/Specialization/Honors Specialization)"
            },
            {
                "id": 5839,
                "itemName": "BA in Kinesiology (Major)"
            },
            {
                "id": 5840,
                "itemName": "BA in Religious Studies (Major)"
            },
            {
                "id": 5841,
                "itemName": "BA in French (Major/Specialization/Honors Specialization)"
            },
            {
                "id": 5842,
                "itemName": "BA in English Language and Literature (Major/Specialization/Honors Specialization)"
            },
            {
                "id": 5843,
                "itemName": "BSc (Human Ecology) in Family Studies and Human Development (Specialization/Honors Specialization)"
            },
            {
                "id": 5844,
                "itemName": "Bachelor of Management and Organizational Studies (Major)"
            },
            {
                "id": 5845,
                "itemName": "BA in Criminal Justice (Honours/Specialization/Major)"
            },
            {
                "id": 5846,
                "itemName": "BA in Philosophy (Major)"
            },
            {
                "id": 5847,
                "itemName": "BA in Political Science (Major)"
            },
            {
                "id": 5848,
                "itemName": "Bachelor of Arts in Health Sciences (Major)"
            },
            {
                "id": 5849,
                "itemName": "BA (Human Ecology) in Family Studies and Human Development (Major/ Specialization/ Honors Specialization)"
            },
            {
                "id": 5850,
                "itemName": "BA in Community Development (Major)"
            },
            {
                "id": 5851,
                "itemName": "Bachelor of Management and Organizational Studies (Honors Specialization/Specialization/Major) in Food Management and Marketing"
            },
            {
                "id": 5852,
                "itemName": "BA in Leadership Studies (Major)"
            },
            {
                "id": 5853,
                "itemName": "BA in Spirituality and Community Leadership (Major)"
            },
            {
                "id": 5854,
                "itemName": "Bachelor of Management and Organizational Studies in Nonprofit Management"
            },
            {
                "id": 5855,
                "itemName": "Bachelor of Arts in Kinesiology (Major)"
            },
            {
                "id": 5856,
                "itemName": "Bachelor of Management and Organizational Studies (Honors Specialization/Specialization) in Nonprofit Management"
            },
            {
                "id": 5857,
                "itemName": "BA in East Asian Studies (Major, Minor)"
            },
            {
                "id": 5858,
                "itemName": "BA in Centre for Global Studies-Global Cultural Studies (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5859,
                "itemName": "BA in Contemporary English Literature (Minor)"
            },
            {
                "id": 5860,
                "itemName": "BA in Economic Theory (Minor)"
            },
            {
                "id": 5861,
                "itemName": "BA in French Language (Minor)"
            },
            {
                "id": 5862,
                "itemName": "BA in French Studies (Major, Specialization, Minor)"
            },
            {
                "id": 5863,
                "itemName": "BA in French Language and Literature (Honours Specialization, Major)"
            },
            {
                "id": 5864,
                "itemName": "BA in Economics (Honours Specialization, Major)"
            },
            {
                "id": 5865,
                "itemName": "BA in Japan Studies (Minor)"
            },
            {
                "id": 5866,
                "itemName": "BA in Centre for Global Studies-Globalization Studies (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5867,
                "itemName": "BA in Centre for Global Studies-Global Development Studies (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5868,
                "itemName": "BA in China Studies (Minor)"
            },
            {
                "id": 5869,
                "itemName": "Bachelor of Management and Organizational Studies-Finance and Administration (Honours Specialization, Specialization)"
            },
            {
                "id": 5870,
                "itemName": "Bachelor of Management and Organizational Studies-Management and Organizational Studies (Major)"
            },
            {
                "id": 5871,
                "itemName": "Bachelor of Management and Organizational Studies-Accounting (Honours Specialization, Major)"
            },
            {
                "id": 5872,
                "itemName": "BA in Psychology (Honours Specialization, Major,Minor)"
            },
            {
                "id": 5873,
                "itemName": "BA in Philosophy-Reasoning and Ethics (Honours Specialization, Specialization)"
            },
            {
                "id": 5874,
                "itemName": "BA in Philosophy-Ethics (Minor)"
            },
            {
                "id": 5875,
                "itemName": "BA in Philosophy-Religious Studies and Philosophy (Minor)"
            },
            {
                "id": 5876,
                "itemName": "BA in Political Science-Globalization and Governance (Major, Minor)"
            },
            {
                "id": 5877,
                "itemName": "BA in Political Science - Ideology and Identity (Major, Minor)"
            },
            {
                "id": 5878,
                "itemName": "BA in Political Science-State and Policy (Major, Minor)"
            },
            {
                "id": 5879,
                "itemName": "Bachelor of Management and Organizational Studies (Honours Double Major)"
            },
            {
                "id": 5880,
                "itemName": "Bachelor of Management and Organizational Studies - Organizational Studies, Policy & Ethics (Hon. Special. , Specialization)"
            },
            {
                "id": 5881,
                "itemName": "BA in English and Cultural Studies (Honours Specialization, Specialization, Major, Minor)"
            },
            {
                "id": 5882,
                "itemName": "BA in Japanese Studies (Major, Minor)"
            },
            {
                "id": 5883,
                "itemName": "BA in Centre for Global Studies-Global Gender Studies (Honours Specialization, Specialization)"
            },
            {
                "id": 5884,
                "itemName": "BA in Centre for Global Studies-Global Health Studies (Honours Specialization, Specialization)"
            },
            {
                "id": 5885,
                "itemName": "BA in Centre for Global Studies-Global Rights Studies (Honours Specialization, Specialization)"
            },
            {
                "id": 5886,
                "itemName": "BA in Chinese Business Communications (Minor)"
            },
            {
                "id": 5887,
                "itemName": "BA in Chinese Studies (Minor)"
            },
            {
                "id": 5888,
                "itemName": "BA in Philosophy-Philosophy in Commerce (Major)"
            },
            {
                "id": 5889,
                "itemName": "BA in Governance, Leadership & Ethics (Major)"
            },
            {
                "id": 5890,
                "itemName": "BA in Religion & Theology (Major, Minor)"
            },
            {
                "id": 5891,
                "itemName": "BA in Histories of African and the African Diasporas (Minor)"
            },
            {
                "id": 5892,
                "itemName": "BA in History of the Book (Minor)"
            },
            {
                "id": 5893,
                "itemName": "BA in Pacific Rim Studies (Minor)"
            },
            {
                "id": 5894,
                "itemName": "BA in Studies in Imperialism (Minor)"
            },
            {
                "id": 5895,
                "itemName": "BA in The Atlantic World (Minor)"
            },
            {
                "id": 5896,
                "itemName": "BA in World History (Minor)"
            },
            {
                "id": 5897,
                "itemName": "BA in Communicating Cultures (Minor)"
            },
            {
                "id": 5898,
                "itemName": "BA in Finance (Major)"
            },
            {
                "id": 5899,
                "itemName": "BA in Global Great Books (Major)"
            },
            {
                "id": 5900,
                "itemName": "BA in Chinese History (Minor)"
            },
            {
                "id": 5901,
                "itemName": "Bachelor of Fine Arts - Criticism & Curatorial Practice"
            },
            {
                "id": 5902,
                "itemName": "Bachelor of Fine Arts - Drawing & Painting"
            },
            {
                "id": 5903,
                "itemName": "Bachelor of Fine Arts - Integrated Media"
            },
            {
                "id": 5904,
                "itemName": "Bachelor of Fine Arts - Photography"
            },
            {
                "id": 5905,
                "itemName": "Bachelor of Fine Arts - Printmaking & Publications"
            },
            {
                "id": 5906,
                "itemName": "Bachelor of Fine Arts - Sculpture/Installation"
            },
            {
                "id": 5907,
                "itemName": "Bachelor of Design - Advertising"
            },
            {
                "id": 5908,
                "itemName": "Bachelor of Design - Graphic Design"
            },
            {
                "id": 5909,
                "itemName": "Bachelor of Design - Illustration"
            },
            {
                "id": 5910,
                "itemName": "Bachelor of Design - Environmental Design"
            },
            {
                "id": 5911,
                "itemName": "Bachelor of Design - Industrial Design"
            },
            {
                "id": 5912,
                "itemName": "Bachelor of Design - Material Art & Design"
            },
            {
                "id": 5913,
                "itemName": "Bachelor of Fine Arts, Bachelor of Design in Digital Futures"
            },
            {
                "id": 5914,
                "itemName": "Bachelor of Fine Arts - Drawing and Painting with Specialization in Digital Painting & Expanded Animation"
            },
            {
                "id": 5915,
                "itemName": "Bachelor of Fine Arts - Integrated Media with Specialization in Digital Painting & Expanded Animation"
            },
            {
                "id": 5916,
                "itemName": "Bachelor of Fine Arts in Indigenous Visual Culture"
            },
            {
                "id": 5917,
                "itemName": "Bachelor of Arts (Honours) in Visual and Critical Studies: Art History"
            },
            {
                "id": 5918,
                "itemName": "Bachelor of Fine arts - Cross-Disciplinary Art with Specialization in Life Studies"
            },
            {
                "id": 5919,
                "itemName": "Bachelor of Design - Environmental Design with Specialization in Interior Design"
            },
            {
                "id": 5920,
                "itemName": "Bachelor of Fine Arts in Creative Writing"
            },
            {
                "id": 5921,
                "itemName": "BA in Music (General)"
            },
            {
                "id": 5922,
                "itemName": "BA in Business - Accounting Stream (Honours)"
            },
            {
                "id": 5923,
                "itemName": "BEd - Consecutive (Junior/Intermediate)"
            },
            {
                "id": 5924,
                "itemName": "BA in Theatre Arts (General)"
            },
            {
                "id": 5925,
                "itemName": "BA in Art (General)"
            },
            {
                "id": 5926,
                "itemName": "BA in Psychology (General)"
            },
            {
                "id": 5927,
                "itemName": "BA in Environmental Studies: Humanities Stream (General)"
            },
            {
                "id": 5928,
                "itemName": "BSc in Environmental Science (Four-Year Major)"
            },
            {
                "id": 5929,
                "itemName": "BA in Religion and Theology - Youth Ministry (General)"
            },
            {
                "id": 5930,
                "itemName": "BA in Philosophy (General)"
            },
            {
                "id": 5931,
                "itemName": "BSc in Mathematics (Four-Year Major)"
            },
            {
                "id": 5932,
                "itemName": "BA in Psychology - Clinical Stream (Honours)"
            },
            {
                "id": 5933,
                "itemName": "BA in Kinesiology (Honours)"
            },
            {
                "id": 5934,
                "itemName": "BA in English - Literature Stream (Honours)"
            },
            {
                "id": 5935,
                "itemName": "BSc in Kinesiology (Honours)"
            },
            {
                "id": 5936,
                "itemName": "BEd - Consecutive (Primary/Junior)"
            },
            {
                "id": 5937,
                "itemName": "BA in English - Literature Stream (General)"
            },
            {
                "id": 5938,
                "itemName": "BA in French (General)"
            },
            {
                "id": 5939,
                "itemName": "BA in Business - Accounting Stream (Four-Year Major)"
            },
            {
                "id": 5940,
                "itemName": "BA in Business - Management Stream (Honours)"
            },
            {
                "id": 5941,
                "itemName": "BA in Business - Management Stream (Four-Year Major)"
            },
            {
                "id": 5942,
                "itemName": "BA in Business - Marketing Stream (Honours)"
            },
            {
                "id": 5943,
                "itemName": "BA in Business - Marketing Stream (Four-Year Major)"
            },
            {
                "id": 5944,
                "itemName": "BA in Business (General)"
            },
            {
                "id": 5945,
                "itemName": "BA in History (General)"
            },
            {
                "id": 5946,
                "itemName": "BA in Physical Education (General)"
            },
            {
                "id": 5947,
                "itemName": "BA in Psychology - Experimental Stream (Honours)"
            },
            {
                "id": 5948,
                "itemName": "BA in Theatre Arts - Performance Stream (General)"
            },
            {
                "id": 5949,
                "itemName": "BSc in Biology (General)"
            },
            {
                "id": 5950,
                "itemName": "BSc in Chemistry (General)"
            },
            {
                "id": 5951,
                "itemName": "BSc in Environmental Science (General)"
            },
            {
                "id": 5952,
                "itemName": "BSc in Mathematics (Honours)"
            },
            {
                "id": 5953,
                "itemName": "BA Minor in Art"
            },
            {
                "id": 5954,
                "itemName": "BA Minor in Geography"
            },
            {
                "id": 5955,
                "itemName": "BA Minor in Ministry"
            },
            {
                "id": 5956,
                "itemName": "BA Minor in Social Justice"
            },
            {
                "id": 5957,
                "itemName": "BA Minor in Criminal Justice"
            },
            {
                "id": 5958,
                "itemName": "BA Minor in Disability Studies"
            },
            {
                "id": 5959,
                "itemName": "BA Minor in Dramatic Art"
            },
            {
                "id": 5960,
                "itemName": "BA Minor in Physical Education - Coaching"
            },
            {
                "id": 5961,
                "itemName": "BA Minor in Social Work"
            },
            {
                "id": 5962,
                "itemName": "BA in English - Writing Stream (General)"
            },
            {
                "id": 5963,
                "itemName": "BA in English - Writing Stream (Honours)"
            },
            {
                "id": 5964,
                "itemName": "BA in International Studies - International Relations Stream (Honours)"
            },
            {
                "id": 5965,
                "itemName": "BA in International Studies - International Development Stream (Honours)"
            },
            {
                "id": 5966,
                "itemName": "BA in Politics and International Studies (General)"
            },
            {
                "id": 5967,
                "itemName": "BSc in Health Sciences - Pre-Medicine Stream (Honours)"
            },
            {
                "id": 5968,
                "itemName": "BSc in Mathematics (General)"
            },
            {
                "id": 5969,
                "itemName": "BA in Media & Communication Studies - Media & Culture Stream (General)"
            },
            {
                "id": 5970,
                "itemName": "BSc in Environmental Science (Honours)"
            },
            {
                "id": 5971,
                "itemName": "BSc in Health Sciences (General)"
            },
            {
                "id": 5972,
                "itemName": "BA in Environmental Studies: Humanities Stream (Four-Year Major)"
            },
            {
                "id": 5973,
                "itemName": "BA in Media & Communication Studies - Digital Media & Production Stream (Honours)"
            },
            {
                "id": 5974,
                "itemName": "BA in Media & Communication Studies - Digital Media & Production Stream (General)"
            },
            {
                "id": 5975,
                "itemName": "BA in Religion and Theology - Urban & Intercultural Ministry (Honours)"
            },
            {
                "id": 5976,
                "itemName": "BA in Applied Social Sciencers - Social Work Stream (Honours)"
            },
            {
                "id": 5977,
                "itemName": "BA in Applied Social Sciences (General)"
            },
            {
                "id": 5978,
                "itemName": "BA in Business - Not for Profit Stream (Four-Year Major)"
            },
            {
                "id": 5979,
                "itemName": "BA in Business - Not for Profit Stream (Honours)"
            },
            {
                "id": 5980,
                "itemName": "BA in Biblical and Theological Studies (General)"
            },
            {
                "id": 5981,
                "itemName": "BSc in Health Sciences - Professional Stream (Honours)"
            },
            {
                "id": 5982,
                "itemName": "BIng en génie informatique"
            },
            {
                "id": 5983,
                "itemName": "BEng in civil engineering"
            },
            {
                "id": 5984,
                "itemName": "BEng in chemical engineering"
            },
            {
                "id": 5985,
                "itemName": "BEng in electrical engineering"
            },
            {
                "id": 5986,
                "itemName": "BIng en génie civil"
            },
            {
                "id": 5987,
                "itemName": "BIng en génie chimique"
            },
            {
                "id": 5988,
                "itemName": "BSc en chimie"
            },
            {
                "id": 5989,
                "itemName": "BIng en génie électrique"
            },
            {
                "id": 5990,
                "itemName": "BSc en science informatique"
            },
            {
                "id": 5991,
                "itemName": "BSc en sciences spatiales"
            },
            {
                "id": 5992,
                "itemName": "BSc in space science"
            },
            {
                "id": 5993,
                "itemName": "BIng en génie mécanique"
            },
            {
                "id": 5994,
                "itemName": "BA en langue françaises, littérature et culture"
            },
            {
                "id": 5995,
                "itemName": "BA en politique"
            },
            {
                "id": 5996,
                "itemName": "BA en anglais, culture et communication"
            },
            {
                "id": 5997,
                "itemName": "BA en administration des affaires"
            },
            {
                "id": 5998,
                "itemName": "BA in business administration"
            },
            {
                "id": 5999,
                "itemName": "BA in military and strategic studies"
            },
            {
                "id": 6000,
                "itemName": "BA en études militaires et stratégiques"
            },
            {
                "id": 6001,
                "itemName": "BSc en physique"
            },
            {
                "id": 6002,
                "itemName": "BA in English, Culture and Communication"
            },
            {
                "id": 6003,
                "itemName": "BA (General)"
            },
            {
                "id": 6004,
                "itemName": "BA in French, Literature, and Culture"
            },
            {
                "id": 6005,
                "itemName": "BA (Hons) in business administration"
            },
            {
                "id": 6006,
                "itemName": "BA (Hons) in English, Culture and Communication"
            },
            {
                "id": 6007,
                "itemName": "BA (Hons) in French, Literature, and Culture"
            },
            {
                "id": 6008,
                "itemName": "BA (Hons) in history"
            },
            {
                "id": 6009,
                "itemName": "BA (Hons) in military and strategic studies"
            },
            {
                "id": 6010,
                "itemName": "BA (Hons) in politics"
            },
            {
                "id": 6011,
                "itemName": "BA (Hons) in economics"
            },
            {
                "id": 6012,
                "itemName": "Bachelor of military arts and science"
            },
            {
                "id": 6013,
                "itemName": "BSc (General)"
            },
            {
                "id": 6014,
                "itemName": "BSc (Hons) in Chemistry"
            },
            {
                "id": 6015,
                "itemName": "BSc (Hons) in mathematics"
            },
            {
                "id": 6016,
                "itemName": "BSc (Hons) in physics"
            },
            {
                "id": 6017,
                "itemName": "BSc (Hons) in space science"
            },
            {
                "id": 6018,
                "itemName": "BA (Général)"
            },
            {
                "id": 6019,
                "itemName": "BA (avec spécialisation) en administration des affaires"
            },
            {
                "id": 6020,
                "itemName": "BA (avec spécialisation) en Anglais, culture et communication"
            },
            {
                "id": 6021,
                "itemName": "BA (avec spécialisation) en langue françaises, littérature et culture"
            },
            {
                "id": 6022,
                "itemName": "BA (avec spécialisation) en histoire"
            },
            {
                "id": 6023,
                "itemName": "BA (avec spécialisation) en études militaires et stratégiques"
            },
            {
                "id": 6024,
                "itemName": "BA (avec spécialisation) en politique"
            },
            {
                "id": 6025,
                "itemName": "BA (avec spécialisation) en économie"
            },
            {
                "id": 6026,
                "itemName": "Baccalauréat ès arts et ès sciences militaires"
            },
            {
                "id": 6027,
                "itemName": "BSc (Général)"
            },
            {
                "id": 6028,
                "itemName": "BSc (avec spécialisation) en chimie"
            },
            {
                "id": 6029,
                "itemName": "BSc (avec spécialisation) en mathématiques"
            },
            {
                "id": 6030,
                "itemName": "BSc (avec spécialisation) en physique"
            },
            {
                "id": 6031,
                "itemName": "BSc (avec spécialisation) en sciences spatiales"
            },
            {
                "id": 6032,
                "itemName": "Bachelor of military arts and science (Hons) in Military Studies"
            },
            {
                "id": 6033,
                "itemName": "BSc (Hons) in computer science"
            },
            {
                "id": 6034,
                "itemName": "Baccalauréat ès arts et ès sciences militaires (avec spécialisation) en études militaires"
            },
            {
                "id": 6035,
                "itemName": "BSc (avec spécialisation) en science informatique"
            },
            {
                "id": 6036,
                "itemName": "BEng in aeronautical engineering"
            },
            {
                "id": 6037,
                "itemName": "Bing en génie aéronautique"
            },
            {
                "id": 6038,
                "itemName": "BA (Hons) in psychology"
            },
            {
                "id": 6039,
                "itemName": "BA (avec spécialisation) en psychologie"
            },
            {
                "id": 6040,
                "itemName": "BA (University of Waterloo)"
            },
            {
                "id": 6041,
                "itemName": "Master of Divinity (MDiv)"
            },
            {
                "id": 6042,
                "itemName": "HBA in Mediaeval Studies"
            },
            {
                "id": 6043,
                "itemName": "HBA in Celtic Studies"
            },
            {
                "id": 6044,
                "itemName": "HBA in Christianity and Culture"
            },
            {
                "id": 6045,
                "itemName": "HBA in Book and Media Studies"
            },
            {
                "id": 6046,
                "itemName": "BA (général) en philosophie"
            },
            {
                "id": 6047,
                "itemName": "BA (general) in religious studies"
            },
            {
                "id": 6048,
                "itemName": "BA (general) in Indigenous studies"
            },
            {
                "id": 6049,
                "itemName": "BA (general) in philosophy"
            },
            {
                "id": 6050,
                "itemName": "BA (général) en sciences religieuses"
            },
            {
                "id": 6051,
                "itemName": "BA (specialized/honours) in Indigenous studies"
            },
            {
                "id": 6052,
                "itemName": "BA (général) en folklore et ethnologie"
            },
            {
                "id": 6053,
                "itemName": "BA (spécialisé) en sciences religieuses"
            },
            {
                "id": 6054,
                "itemName": "BA (specialized/honours) in religious studies"
            },
            {
                "id": 6055,
                "itemName": "BA (specialized/honours) in philosophy"
            },
            {
                "id": 6056,
                "itemName": "BA (spécialisé) en philosophie"
            },
            {
                "id": 6057,
                "itemName": "BA (spécialisé) en études journalistiques"
            },
            {
                "id": 6058,
                "itemName": "BA (général) en études journalistiques"
            },
            {
                "id": 6059,
                "itemName": "EMBA McGill - HEC Montréal"
            },
            {
                "id": 6060,
                "itemName": "B.Ing. en Génie physique"
            },
            {
                "id": 6061,
                "itemName": "B.Ing. en Génie chimique"
            },
            {
                "id": 6062,
                "itemName": "B.Ing. en Génie industriel"
            },
            {
                "id": 6063,
                "itemName": "B.Ing. en Génie informatique"
            },
            {
                "id": 6064,
                "itemName": "B.Ing. en Génie géologique"
            },
            {
                "id": 6065,
                "itemName": "B.Ing. en Génie civil"
            },
            {
                "id": 6066,
                "itemName": "B.Ing. en Génie électrique"
            },
            {
                "id": 6067,
                "itemName": "B.Ing. en Génie des mines"
            },
            {
                "id": 6068,
                "itemName": "B.Ing. en Génie mécanique"
            },
            {
                "id": 6069,
                "itemName": "B.Ing. en Génie logiciel"
            },
            {
                "id": 6070,
                "itemName": "Certificat en Cyberenquête"
            },
            {
                "id": 6071,
                "itemName": "B.Ing. en Génie aérospatial"
            },
            {
                "id": 6072,
                "itemName": "B.Ing. en Génie biomédical"
            },
            {
                "id": 6073,
                "itemName": "BA en enseignement de l'anglais langue seconde"
            },
            {
                "id": 6074,
                "itemName": "BA interdisciplinaire en arts"
            },
            {
                "id": 6075,
                "itemName": "BA en études littéraires françaises"
            },
            {
                "id": 6076,
                "itemName": "BA en enseignement des arts"
            },
            {
                "id": 6077,
                "itemName": "BA en linguistique et langue française"
            },
            {
                "id": 6078,
                "itemName": "BSc en géologie"
            },
            {
                "id": 6079,
                "itemName": "BIng en génie géologique"
            },
            {
                "id": 6080,
                "itemName": "BScA en informatique de la science des données et de l’intelligence d’affaires"
            },
            {
                "id": 6081,
                "itemName": "BScA avec majeure en conception de jeux vidéo"
            },
            {
                "id": 6082,
                "itemName": "BA en sociologie appliquée"
            },
            {
                "id": 6083,
                "itemName": "BSc en chimie des produits naturels"
            },
            {
                "id": 6084,
                "itemName": "BSc en biologie"
            },
            {
                "id": 6085,
                "itemName": "BÉd en enseignement secondaire"
            },
            {
                "id": 6086,
                "itemName": "BÉd en éducation préscolaire et en enseignement primaire"
            },
            {
                "id": 6087,
                "itemName": "BSc en géographie et en aménagement durable"
            },
            {
                "id": 6088,
                "itemName": "BÉd en enseignement professionnel"
            },
            {
                "id": 6089,
                "itemName": "BÉd en enseignement en adaptation scolaire et sociale"
            },
            {
                "id": 6090,
                "itemName": "BAA en sciences comptables"
            },
            {
                "id": 6091,
                "itemName": "BAA en administration"
            },
            {
                "id": 6092,
                "itemName": "BSc avec majeure en mathématique"
            },
            {
                "id": 6093,
                "itemName": "BA en langues modernes"
            },
            {
                "id": 6094,
                "itemName": "BScA en informatique"
            },
            {
                "id": 6095,
                "itemName": "BA avec majeure en études littéraires françaises"
            },
            {
                "id": 6096,
                "itemName": "BSc en science politique"
            },
            {
                "id": 6097,
                "itemName": "BA en éducation préscolaire et en enseignement au primaire"
            },
            {
                "id": 6098,
                "itemName": "BSc en intervention plein air"
            },
            {
                "id": 6099,
                "itemName": "BSc en sciences infirmières"
            },
            {
                "id": 6100,
                "itemName": "BTS en travail social"
            },
            {
                "id": 6101,
                "itemName": "BÉd en enseignement de l'éducation physique et à la santé"
            },
            {
                "id": 6102,
                "itemName": "BSc en kinésiologie"
            },
            {
                "id": 6103,
                "itemName": "BA en animation 3D et en design numérique"
            },
            {
                "id": 6104,
                "itemName": "Bing en génie civil"
            },
            {
                "id": 6105,
                "itemName": "Baccalauréat en sciences (réadaptation)"
            },
            {
                "id": 6106,
                "itemName": "BA en communication interculturelle et médiatique"
            },
            {
                "id": 6107,
                "itemName": "Baccalauréat en administration"
            },
            {
                "id": 6108,
                "itemName": "BA en développement des sociétés et territoires"
            },
            {
                "id": 6109,
                "itemName": "BA en psychosociologie des relations humaines"
            },
            {
                "id": 6110,
                "itemName": "BSc en géographie"
            },
            {
                "id": 6111,
                "itemName": "BA en lettres et création littéraire"
            },
            {
                "id": 6112,
                "itemName": "BIng en génie des systèmes électromécaniques"
            },
            {
                "id": 6113,
                "itemName": "BSc en chimie de l'environnement et des bioressources"
            },
            {
                "id": 6114,
                "itemName": "BSc en informatique"
            },
            {
                "id": 6115,
                "itemName": "BScA avec majeure en transport maritime et mineure en administration"
            },
            {
                "id": 6116,
                "itemName": "BA avec majeure en lettres"
            },
            {
                "id": 6117,
                "itemName": "Baccalauréat avec majeure en géographie"
            },
            {
                "id": 6118,
                "itemName": "BA avec majeure en histoire"
            },
            {
                "id": 6119,
                "itemName": "BSc avec majeure en biologie"
            },
            {
                "id": 6120,
                "itemName": "B.Sc en kinésiologie"
            },
            {
                "id": 6121,
                "itemName": "Baccalauréat en biologie médicale"
            },
            {
                "id": 6122,
                "itemName": "Baccalauréat en sciences biologiques et écologiques"
            },
            {
                "id": 6123,
                "itemName": "Baccalauréat en génie éléctrique"
            },
            {
                "id": 6124,
                "itemName": "Baccalauréat en biochimie et biotechnologie"
            },
            {
                "id": 6125,
                "itemName": "Baccalauréat en génie industriel"
            },
            {
                "id": 6126,
                "itemName": "Baccalauréat en pratique sage-femme"
            },
            {
                "id": 6127,
                "itemName": "Baccalauréat en études françaises"
            },
            {
                "id": 6128,
                "itemName": "Baccalauréat en communication sociale"
            },
            {
                "id": 6129,
                "itemName": "Baccalauréat en loisir, culture et tourisme"
            },
            {
                "id": 6130,
                "itemName": "Baccalauréat en enseignement des langues secondes"
            },
            {
                "id": 6131,
                "itemName": "Baccalauréat en enseignement de l'éducation physique et à la santé"
            },
            {
                "id": 6132,
                "itemName": "Baccalauréat en enseignement en arts"
            },
            {
                "id": 6133,
                "itemName": "Baccalauréat en sciences comptables"
            },
            {
                "id": 6134,
                "itemName": "Baccalauréat d'éducation au préscolaire et d'enseignement au primaire"
            },
            {
                "id": 6135,
                "itemName": "Double baccalauréat en mathématiques et informatique"
            },
            {
                "id": 6136,
                "itemName": "Double baccalauréat en physique et en informatique"
            },
            {
                "id": 6137,
                "itemName": "Double baccalauréat en mathématiques et en enseignement au secondaire : mathématiques"
            },
            {
                "id": 6138,
                "itemName": "Doctorat de premier cycle en chiropratique"
            },
            {
                "id": 6139,
                "itemName": "Doctorat de premier cycle en médicine podiatrique"
            },
            {
                "id": 6140,
                "itemName": "Baccalauréat en sécurité publique"
            },
            {
                "id": 6141,
                "itemName": "Baccalauréat en enseignement en adaptation scolaire et sociale"
            },
            {
                "id": 6142,
                "itemName": "Doctorat en sciences biomédicales"
            },
            {
                "id": 6143,
                "itemName": "Baccalauréat en science forensique"
            },
            {
                "id": 6144,
                "itemName": "Baccalauréat en enseignement secondaire"
            },
            {
                "id": 6145,
                "itemName": "Baccalauréat en éducation préscolaire et en enseignement primaire"
            },
            {
                "id": 6146,
                "itemName": "Baccalauréat en génie électromécanique"
            },
            {
                "id": 6147,
                "itemName": "Baccalauréat en sciences infirmières (DEC-BAC)"
            },
            {
                "id": 6148,
                "itemName": "Baccalauréat en génie des mines"
            },
            {
                "id": 6149,
                "itemName": "Baccalauréat en création numérique"
            },
            {
                "id": 6150,
                "itemName": "Baccalauréat en création de jeux vidéo"
            },
            {
                "id": 6151,
                "itemName": "Baccalauréat en création 3D"
            },
            {
                "id": 6152,
                "itemName": "Baccalauréat avec majeure en gestion des ressources humaines"
            },
            {
                "id": 6153,
                "itemName": "Mineure en psychologie du travail et des organisations"
            },
            {
                "id": 6154,
                "itemName": "Baccalauréat en développement durable du territoire (B. Sc.)"
            },
            {
                "id": 6155,
                "itemName": "B.A. en langues et cultures du monde"
            },
            {
                "id": 6156,
                "itemName": "Baccalauréat en traduction et en rédaction"
            },
            {
                "id": 6157,
                "itemName": "Baccalauréat en arts et en design"
            },
            {
                "id": 6158,
                "itemName": "Baccalauréat en enseignement en adaptation scolaire"
            },
            {
                "id": 6159,
                "itemName": "Majeure en développement international"
            },
            {
                "id": 6160,
                "itemName": "Mineure en pratiques administratives"
            },
            {
                "id": 6161,
                "itemName": "Mineure en approches éducatives"
            },
            {
                "id": 6162,
                "itemName": "Majeure en arts visuels"
            },
            {
                "id": 6163,
                "itemName": "Majeure en rédaction professionnelle"
            },
            {
                "id": 6164,
                "itemName": "Majeure en traduction professionnelle"
            },
            {
                "id": 6165,
                "itemName": "Baccalauréat en relations industrielles et en ressources humaines"
            },
            {
                "id": 6166,
                "itemName": "Baccalauréat en relations industrielles et en ressources humaines (cheminement coopératif)"
            },
            {
                "id": 6167,
                "itemName": "Majeure en muséologie et patrimoines"
            },
            {
                "id": 6168,
                "itemName": "Baccalauréat en informatique (cheminement coopératif)"
            },
            {
                "id": 6169,
                "itemName": "Baccalauréat en enseignement des arts"
            },
            {
                "id": 6170,
                "itemName": "Mineure en géographie et environnement"
            },
            {
                "id": 6171,
                "itemName": "Baccalauréat en écologie et environnement"
            },
            {
                "id": 6172,
                "itemName": "Baccalauréat en sciences infirmières (formation initiale)"
            },
            {
                "id": 6173,
                "itemName": "Baccalauréat en sciences sociales, concentration communication"
            },
            {
                "id": 6174,
                "itemName": "Baccalauréat en sciences sociales, concentration développement international"
            },
            {
                "id": 6175,
                "itemName": "Baccalauréat en sciences sociales, concentration histoire"
            },
            {
                "id": 6176,
                "itemName": "Baccalauréat en sciences sociales, concentration science politique"
            },
            {
                "id": 6177,
                "itemName": "Baccalauréat en sciences sociales, concentration sociologie"
            },
            {
                "id": 6178,
                "itemName": "Mineure en information et médias numériques"
            },
            {
                "id": 6179,
                "itemName": "Baccalauréat en sciences sociales, concentration économique"
            },
            {
                "id": 6180,
                "itemName": "Baccalauréat en sciences infirmières (formation initiale - cheminement DEC-Bac)"
            },
            {
                "id": 6181,
                "itemName": "Baccalauréat en communication"
            },
            {
                "id": 6182,
                "itemName": "Baccalauréat ès arts"
            },
            {
                "id": 6183,
                "itemName": "Baccalauréat en administration des affaires par cumul de certificats ou mineures"
            },
            {
                "id": 6184,
                "itemName": "Baccalauréat ès sciences par cumul de certificats ou mineures"
            },
            {
                "id": 6185,
                "itemName": "Baccalauréat ès arts par cumul de certificats ou mineures"
            },
            {
                "id": 6186,
                "itemName": "Baccalauréat en éducation avec majeure(s)"
            },
            {
                "id": 6187,
                "itemName": "Majeure en langue et linguistique"
            },
            {
                "id": 6188,
                "itemName": "Majeure en culture et expression écrites"
            },
            {
                "id": 6189,
                "itemName": "Majeure en sciences de l'environnement"
            },
            {
                "id": 6190,
                "itemName": "Baccalauréat ès arts avec majeure(s)"
            },
            {
                "id": 6191,
                "itemName": "Baccalauréat ès sciences avec majeure(s)"
            },
            {
                "id": 6192,
                "itemName": "BA/BA Honours (University of Regina/Campion College)"
            },
            {
                "id": 6193,
                "itemName": "Bachelor of Journalism (University of Regina/Campion College)"
            },
            {
                "id": 6194,
                "itemName": "BFA (University of Regina/Campion College)"
            },
            {
                "id": 6195,
                "itemName": "BMus/BMusEd (University of Regina/Campion College)"
            },
            {
                "id": 6196,
                "itemName": "BSc/BSc Honours (University of Regina/Campion College)"
            },
            {
                "id": 6197,
                "itemName": "BMLS (University of Regina/Campion College)"
            },
            {
                "id": 6198,
                "itemName": "BMI (University of Regina/Campion College)"
            },
            {
                "id": 6199,
                "itemName": "BGISc (University of Regina/Campion College)"
            },
            {
                "id": 6200,
                "itemName": "BHS (University of Regina/Campion College)"
            },
            {
                "id": 6201,
                "itemName": "BHJ (University of Regina/Campion College)"
            },
            {
                "id": 6202,
                "itemName": "BA (major/honours) in Indigenous Studies"
            },
            {
                "id": 6203,
                "itemName": "Bachelor of Education - Elementary (Indigenous Education)"
            },
            {
                "id": 6204,
                "itemName": "BA (major/honours) in English"
            },
            {
                "id": 6205,
                "itemName": "BSc (general)"
            },
            {
                "id": 6206,
                "itemName": "BFA in Indian Art"
            },
            {
                "id": 6207,
                "itemName": "BA in Indian Art History"
            },
            {
                "id": 6208,
                "itemName": "BA in Indian Art (3 year Special)"
            },
            {
                "id": 6209,
                "itemName": "BA (major/honours) in Linguistics"
            },
            {
                "id": 6210,
                "itemName": "Bachelor of Education - Secondary (Indigenous Education)"
            },
            {
                "id": 6211,
                "itemName": "BA in Indian Art"
            },
            {
                "id": 6212,
                "itemName": "BA in Resource and Environmental Studies"
            },
            {
                "id": 6213,
                "itemName": "Bachelor of Education After Degree (BEAD) - Elementary (Indigenous Education)"
            },
            {
                "id": 6214,
                "itemName": "Bachelor of Education After Degree (BEAD) - Secondary (Indigenous Education)"
            },
            {
                "id": 6215,
                "itemName": "Minor in Dakota Language Studies"
            },
            {
                "id": 6216,
                "itemName": "Minor in Dene Language Studies"
            },
            {
                "id": 6217,
                "itemName": "Minor in Nakota Language Studies"
            },
            {
                "id": 6218,
                "itemName": "Bachelor of Health Studies with a Concentration in Indigenous Health & Well-being (optional)"
            },
            {
                "id": 6219,
                "itemName": "Minor in Cree Language Studies"
            },
            {
                "id": 6220,
                "itemName": "Minor in Saulteaux Language Studies"
            },
            {
                "id": 6221,
                "itemName": "Bachelor of Education After Degree (BEAD) - Secondary (Visual Arts Major)"
            },
            {
                "id": 6222,
                "itemName": "Bachelor of Education - Secondary (Major - Indigenous Studies/Minor - First Nations Languages, Indigenous Studies)"
            },
            {
                "id": 6223,
                "itemName": "Bachelor of (Indigenous) Education Elementary Degree Program - Cree Immersion"
            },
            {
                "id": 6224,
                "itemName": "BA/BA Honours (University of Regina/Luther College)"
            },
            {
                "id": 6225,
                "itemName": "BFA/BFA Honours (University of Regina/Luther College)"
            },
            {
                "id": 6226,
                "itemName": "BMus/BMus Honours (University of Regina/Luther College)"
            },
            {
                "id": 6227,
                "itemName": "BSc/BSc Honours (University of Regina/Luther College)"
            },
            {
                "id": 6228,
                "itemName": "BAJ - Bachelor of Arts in Journalism (University of Regina/Luther College)"
            },
            {
                "id": 6229,
                "itemName": "BAPS - Bachelor of Arts in Police Studies (University of Regina/Luther College)"
            },
            {
                "id": 6230,
                "itemName": "BHS- Bachelor of Health Studies (University of Regina/Luther College)"
            },
            {
                "id": 6231,
                "itemName": "BHJ - Bachelor of Human Justice (University of Regina/Luther College)"
            },
            {
                "id": 6232,
                "itemName": "BJ - Bachelor of Journalism (University of Regina/Luther College)"
            },
            {
                "id": 6233,
                "itemName": "Bachelor of Medical Laboratory Science (University of Regina/Luther College with Saskatchewan Polytechnic)"
            },
            {
                "id": 6234,
                "itemName": "BMusEd (University of Regina/Luther College)"
            },
            {
                "id": 6235,
                "itemName": "BSc/BSc Hon. in Envir. Biology (Univ. of Regina/Luther Coll. - joint program with Saskat. Polytechnic, Lethbridge or Lakehead)"
            },
            {
                "id": 6236,
                "itemName": "BSc in Chemical Technology (University of Regina/Luther College with Saskatchewan Polytechnic)"
            },
            {
                "id": 6237,
                "itemName": "BA (3-year program)"
            },
            {
                "id": 6238,
                "itemName": "BSc (3-year program)"
            },
            {
                "id": 6239,
                "itemName": "BA (4-year major/honours)"
            },
            {
                "id": 6240,
                "itemName": "BSc (4-year major/honours)"
            },
            {
                "id": 6241,
                "itemName": "BFA"
            }
        ]
        return bachelorList;
    }


    masterListUniversity() {
        const masterList = [
            {
                "id": 1,
                "itemName": "M.Sc. in Anthropology"
            },
            {
                "id": 2,
                "itemName": "Executive (M.B.A)"
            },
            {
                "id": 3,
                "itemName": "Master of Landscape Architecture (M.L.A)"
            },
            {
                "id": 4,
                "itemName": "Master of Architecture (M.Arch.)"
            },
            {
                "id": 5,
                "itemName": "M.Sc. in Forestry"
            },
            {
                "id": 6,
                "itemName": "M.Sc. in Astronomy and Astrophysics"
            },
            {
                "id": 7,
                "itemName": "M.A.Sc. in Biomedical Engineering"
            },
            {
                "id": 8,
                "itemName": "M.A. in Criminology"
            },
            {
                "id": 9,
                "itemName": "Master of Laws (L.L.M.)"
            },
            {
                "id": 10,
                "itemName": "M.Sc. in Physics"
            },
            {
                "id": 11,
                "itemName": "MA in Germanic Languages and Literatures"
            },
            {
                "id": 12,
                "itemName": "M.Sc. in Molecular Genetics"
            },
            {
                "id": 13,
                "itemName": "M.Sc. in Medical Biophysics"
            },
            {
                "id": 14,
                "itemName": "M.Sc. in Nutritional Sciences"
            },
            {
                "id": 15,
                "itemName": "M.H.Sc. in Speech-Language Pathology"
            },
            {
                "id": 16,
                "itemName": "M.Sc. in Physiology"
            },
            {
                "id": 17,
                "itemName": "M.Sc. in Pharmacology"
            },
            {
                "id": 18,
                "itemName": "M.Sc. in Laboratory Medicine and Pathobiology"
            },
            {
                "id": 19,
                "itemName": "M.A.Sc. in Civil Engineering"
            },
            {
                "id": 20,
                "itemName": "M.A.Sc. in Chemical Engineering and Applied Chemistry"
            },
            {
                "id": 21,
                "itemName": "M.Eng. in Chemical Engineering and Applied Chemistry"
            },
            {
                "id": 22,
                "itemName": "M.Eng. in Electrical and Computer Engineering"
            },
            {
                "id": 23,
                "itemName": "M.A.Sc. in Electrical and Computer Engineering"
            },
            {
                "id": 24,
                "itemName": "M.Eng. in Aerospace Science and Engineering"
            },
            {
                "id": 25,
                "itemName": "M.Eng. in Materials Science and Engineering"
            },
            {
                "id": 26,
                "itemName": "M.A.Sc in Materials Science and Engineering"
            },
            {
                "id": 27,
                "itemName": "M.Eng. in Mechanical and Industrial Engineering"
            },
            {
                "id": 28,
                "itemName": "M.A.Sc in Mechanical and Industrial Engineering"
            },
            {
                "id": 29,
                "itemName": "M.A. in Anthropology"
            },
            {
                "id": 30,
                "itemName": "M.Sc. in Chemistry"
            },
            {
                "id": 31,
                "itemName": "M.A. in English"
            },
            {
                "id": 32,
                "itemName": "M.A. in Art History"
            },
            {
                "id": 33,
                "itemName": "M.Sc. in Planning"
            },
            {
                "id": 34,
                "itemName": "M.Sc. in Geography"
            },
            {
                "id": 35,
                "itemName": "M.A. in French Language and Literature"
            },
            {
                "id": 36,
                "itemName": "M.Sc. in Earth Sciences"
            },
            {
                "id": 37,
                "itemName": "M.A. in Italian Studies"
            },
            {
                "id": 38,
                "itemName": "M.A. in Classics"
            },
            {
                "id": 39,
                "itemName": "M.A. in History and Philosophy of Science and Technology"
            },
            {
                "id": 40,
                "itemName": "M.A. in Medieval Studies"
            },
            {
                "id": 41,
                "itemName": "M.A. in Political Science"
            },
            {
                "id": 42,
                "itemName": "M.A. in European and Russian Affairs"
            },
            {
                "id": 43,
                "itemName": "M.A. in the Study of Religion"
            },
            {
                "id": 44,
                "itemName": "M.A. in Spanish"
            },
            {
                "id": 45,
                "itemName": "M.A. in Sociology"
            },
            {
                "id": 46,
                "itemName": "M.A.Sc. in Aerospace Science and Engineering"
            },
            {
                "id": 47,
                "itemName": "Master of Management and Professional Accounting (M.M.P.A.)"
            },
            {
                "id": 48,
                "itemName": "Master of Social Work (M.S.W.)"
            },
            {
                "id": 49,
                "itemName": "Master of Forest Conservation (M.F.C.)"
            },
            {
                "id": 50,
                "itemName": "Master of Nursing (M.N.)"
            },
            {
                "id": 51,
                "itemName": "M.Sc. in Pharmaceutical Sciences"
            },
            {
                "id": 52,
                "itemName": "M.Sc. in Rehabilitation Science"
            },
            {
                "id": 53,
                "itemName": "M.Sc. in Dentistry"
            },
            {
                "id": 54,
                "itemName": "M.H.Sc. in Clinical Engineering"
            },
            {
                "id": 55,
                "itemName": "M.Sc. in Kinesiology"
            },
            {
                "id": 56,
                "itemName": "M.H.Sc.in Health Administration"
            },
            {
                "id": 57,
                "itemName": "M.A. in Slavic Languages and Literatures"
            },
            {
                "id": 58,
                "itemName": "M.A. in Drama"
            },
            {
                "id": 59,
                "itemName": "M.Sc. in Statistics"
            },
            {
                "id": 60,
                "itemName": "M.Sc. in Medical Science"
            },
            {
                "id": 61,
                "itemName": "M.Eng. in Civil Engineering"
            },
            {
                "id": 62,
                "itemName": "M.Sc. in Biochemistry"
            },
            {
                "id": 63,
                "itemName": "M.A. in Geography"
            },
            {
                "id": 64,
                "itemName": "M.Sc. in Immunology"
            },
            {
                "id": 65,
                "itemName": "M.Ed. in Sociology Justice Education"
            },
            {
                "id": 66,
                "itemName": "M.A. in School and Clinical Child Psychology"
            },
            {
                "id": 67,
                "itemName": "M.Ed. in Higher Education"
            },
            {
                "id": 68,
                "itemName": "M.A. in Higher Education"
            },
            {
                "id": 69,
                "itemName": "M.A. in Child Study and Education"
            },
            {
                "id": 70,
                "itemName": "M.Ed. in Curriculum and Pedagogy"
            },
            {
                "id": 71,
                "itemName": "M.Ed. in Counselling Psychology"
            },
            {
                "id": 72,
                "itemName": "M.A. in Adult Education and Community Development"
            },
            {
                "id": 73,
                "itemName": "M.A. in East Asian Studies"
            },
            {
                "id": 74,
                "itemName": "M.A. in Economics"
            },
            {
                "id": 75,
                "itemName": "M.A. in History"
            },
            {
                "id": 76,
                "itemName": "M.A. in Linguistics"
            },
            {
                "id": 77,
                "itemName": "M.A. in Philosophy"
            },
            {
                "id": 78,
                "itemName": "M.A. in Psychology"
            },
            {
                "id": 79,
                "itemName": "M.Sc. in Computer Science"
            },
            {
                "id": 80,
                "itemName": "M.Sc. in Genetic Counselling"
            },
            {
                "id": 81,
                "itemName": "M.Sc. in Mathematics"
            },
            {
                "id": 82,
                "itemName": "M.Sc. in Occupational Therapy"
            },
            {
                "id": 83,
                "itemName": "M.Sc. in Speech-Language Pathology"
            },
            {
                "id": 84,
                "itemName": "Master of Biotechnology (M.Biotech.)"
            },
            {
                "id": 85,
                "itemName": "Master of Financial Economics (M.F.E)"
            },
            {
                "id": 86,
                "itemName": "Master of Mathematical Finance (M.M.F.)"
            },
            {
                "id": 87,
                "itemName": "M.Sc. in Physical Therapy"
            },
            {
                "id": 88,
                "itemName": "Master of Urban Design (M.U.D.)"
            },
            {
                "id": 89,
                "itemName": "Collaborative Specialization in Contemporary East and Southeast Asian Studies"
            },
            {
                "id": 90,
                "itemName": "M.H.Sc. in Bioethics"
            },
            {
                "id": 91,
                "itemName": "Dual Degree Program: Bachelor of Engineering (South China University of Technology)/Master of Engineering (University of Toronto)"
            },
            {
                "id": 92,
                "itemName": "Master of Teaching "
            },
            {
                "id": 93,
                "itemName": "M.A. in Counselling and Clinical Psychology"
            },
            {
                "id": 94,
                "itemName": "M.A. in Developmental Psychology and Education"
            },
            {
                "id": 95,
                "itemName": "M.Ed. in Developmental Psychology and Education"
            },
            {
                "id": 96,
                "itemName": "M.Sc. in Biomedical Communications"
            },
            {
                "id": 97,
                "itemName": "M.A. in Curriculum and Pedagogy"
            },
            {
                "id": 98,
                "itemName": "M.A.Sc. in Earth Sciences"
            },
            {
                "id": 99,
                "itemName": "M.Sc. in Public Health Science"
            },
            {
                "id": 100,
                "itemName": "Master of Business Administration (M.B.A.)"
            },
            {
                "id": 101,
                "itemName": "Master of Industrial Relations and Human Resources (M.I.R.H.R.)"
            },
            {
                "id": 102,
                "itemName": "Master of Information (M.I.)"
            },
            {
                "id": 103,
                "itemName": "Master of Museum Studies (M.M.St.)"
            },
            {
                "id": 104,
                "itemName": "Master of Visual Studies (M.V.S.)"
            },
            {
                "id": 105,
                "itemName": "Collaborative Specialization in Community Development"
            },
            {
                "id": 106,
                "itemName": "M.A. in Cinema Studies"
            },
            {
                "id": 107,
                "itemName": "M.A. in Comparative Literature"
            },
            {
                "id": 108,
                "itemName": "M.A. in Near and Middle Eastern Civilizations"
            },
            {
                "id": 109,
                "itemName": "M.A.in Women and Gender Studies"
            },
            {
                "id": 110,
                "itemName": "M.Sc. in Cell and Systems Biology"
            },
            {
                "id": 111,
                "itemName": "M.Sc. in Ecology and Evolutionary Biology"
            },
            {
                "id": 112,
                "itemName": "Master of Management Innovation (M.M.I.)"
            },
            {
                "id": 113,
                "itemName": "Master of Public Policy (M.P.P.)"
            },
            {
                "id": 114,
                "itemName": "Master of Health Informatics (M.H.I.)"
            },
            {
                "id": 115,
                "itemName": "MSc in Parasitology"
            },
            {
                "id": 116,
                "itemName": "MSc in Otolaryngology"
            },
            {
                "id": 117,
                "itemName": "MSc in Neuroscience"
            },
            {
                "id": 118,
                "itemName": "MSc in Medical Radiation Physics"
            },
            {
                "id": 119,
                "itemName": "MSc in Atmospheric and Oceanic Sciences"
            },
            {
                "id": 120,
                "itemName": "MSc in Genetic Counselling"
            },
            {
                "id": 121,
                "itemName": "MA in Medical Sociology"
            },
            {
                "id": 122,
                "itemName": "MSc in Psychology"
            },
            {
                "id": 123,
                "itemName": "MSc in Geography"
            },
            {
                "id": 124,
                "itemName": "MSc in Chemistry"
            },
            {
                "id": 125,
                "itemName": "MSc in Biology"
            },
            {
                "id": 126,
                "itemName": "MSc in Physics"
            },
            {
                "id": 127,
                "itemName": "MMus in Composition"
            },
            {
                "id": 128,
                "itemName": "MA in Music Theory"
            },
            {
                "id": 129,
                "itemName": "MMus in Sound Recording"
            },
            {
                "id": 130,
                "itemName": "Master of Business Administration/Law"
            },
            {
                "id": 131,
                "itemName": "MA Kinesiology and Physical Education"
            },
            {
                "id": 132,
                "itemName": "MA in Educational Psychology"
            },
            {
                "id": 133,
                "itemName": "MA in Counselling Psychology"
            },
            {
                "id": 134,
                "itemName": "MA in Musicology"
            },
            {
                "id": 135,
                "itemName": "MA in Anthropology"
            },
            {
                "id": 136,
                "itemName": "MA in Political Science"
            },
            {
                "id": 137,
                "itemName": "MA in History"
            },
            {
                "id": 138,
                "itemName": "Master of Social Work (MSW)"
            },
            {
                "id": 139,
                "itemName": "Master of Sacred Theology in Religious Studies (STM)"
            },
            {
                "id": 140,
                "itemName": "MSc in Physiology"
            },
            {
                "id": 141,
                "itemName": "MSc in Earth and Planetary Sciences"
            },
            {
                "id": 142,
                "itemName": "Master of Laws (LLM)"
            },
            {
                "id": 143,
                "itemName": "MSc in Pathology"
            },
            {
                "id": 144,
                "itemName": "Master of Urban Planning (MUP)"
            },
            {
                "id": 145,
                "itemName": "MSc in Biochemistry"
            },
            {
                "id": 146,
                "itemName": "MSc in Microbiology and Immunology"
            },
            {
                "id": 147,
                "itemName": "MA in Second Language Education"
            },
            {
                "id": 148,
                "itemName": "MA in Classics"
            },
            {
                "id": 149,
                "itemName": "Master of Business Administration (MBA)"
            },
            {
                "id": 150,
                "itemName": "MA in Religious Studies"
            },
            {
                "id": 151,
                "itemName": "MSc in Rehabilitation Science"
            },
            {
                "id": 152,
                "itemName": "MScA in Nursing"
            },
            {
                "id": 153,
                "itemName": "MSc in Food Science and Agricultural Chemistry"
            },
            {
                "id": 154,
                "itemName": "MA in Music Technology"
            },
            {
                "id": 155,
                "itemName": "MA in Music Education"
            },
            {
                "id": 156,
                "itemName": "MMus in Performance"
            },
            {
                "id": 157,
                "itemName": "MA in East Asian Studies"
            },
            {
                "id": 158,
                "itemName": "Master of Architecture"
            },
            {
                "id": 159,
                "itemName": "MSc in Human Nutrition"
            },
            {
                "id": 160,
                "itemName": "MEd in Educational Psychology"
            },
            {
                "id": 161,
                "itemName": "MSc in Agricultural Economics"
            },
            {
                "id": 162,
                "itemName": "MA in Art History"
            },
            {
                "id": 163,
                "itemName": "MSc in Plant Science"
            },
            {
                "id": 164,
                "itemName": "MSc in Entomology, Microbiology or Renewable Resources"
            },
            {
                "id": 165,
                "itemName": "MA in German Studies"
            },
            {
                "id": 166,
                "itemName": "MSc in Animal Science"
            },
            {
                "id": 167,
                "itemName": "MA in Geography"
            },
            {
                "id": 168,
                "itemName": "MA en langue et littérature françaises"
            },
            {
                "id": 169,
                "itemName": "MA in English"
            },
            {
                "id": 170,
                "itemName": "MA in Economics"
            },
            {
                "id": 171,
                "itemName": "MA in Italian Studies"
            },
            {
                "id": 172,
                "itemName": "MA in Linguistics"
            },
            {
                "id": 173,
                "itemName": "MA in Islamic Studies"
            },
            {
                "id": 174,
                "itemName": "MA in Jewish Studies"
            },
            {
                "id": 175,
                "itemName": "MA in Hispanic Studies"
            },
            {
                "id": 176,
                "itemName": "MA in Sociology"
            },
            {
                "id": 177,
                "itemName": "MA in Psychology"
            },
            {
                "id": 178,
                "itemName": "MSc in Dental Sciences"
            },
            {
                "id": 179,
                "itemName": "MSc in Computer Science"
            },
            {
                "id": 180,
                "itemName": "MEng in Civil Engineering"
            },
            {
                "id": 181,
                "itemName": "MEng in Mechanical Engineering with Aerospace Option"
            },
            {
                "id": 182,
                "itemName": "MEng in Mechanical Engineering"
            },
            {
                "id": 183,
                "itemName": "MEng in Electrical Engineering"
            },
            {
                "id": 184,
                "itemName": "MSc in Civil Engineering"
            },
            {
                "id": 185,
                "itemName": "MSc in Communication Sciences and Disorders"
            },
            {
                "id": 186,
                "itemName": "MSc in Cell Biology and Anatomy"
            },
            {
                "id": 187,
                "itemName": "MEng in Chemical Engineering"
            },
            {
                "id": 188,
                "itemName": "MSc in Pharmacology"
            },
            {
                "id": 189,
                "itemName": "MSc in Psychiatry"
            },
            {
                "id": 190,
                "itemName": "MSc in Experimental Surgery"
            },
            {
                "id": 191,
                "itemName": "Master of Social Work/Law"
            },
            {
                "id": 192,
                "itemName": "MA in Communication Studies"
            },
            {
                "id": 193,
                "itemName": "MA in Philosophy"
            },
            {
                "id": 194,
                "itemName": "MA in Medical Anthropology"
            },
            {
                "id": 195,
                "itemName": "MSc in Experimental Medicine"
            },
            {
                "id": 196,
                "itemName": "MSc in Bioresource Engineering"
            },
            {
                "id": 197,
                "itemName": "MSc in Kinesiology and Physical Education"
            },
            {
                "id": 198,
                "itemName": "MScA in Occupational Health"
            },
            {
                "id": 199,
                "itemName": "MSc in Renewable Resources"
            },
            {
                "id": 200,
                "itemName": "MA in Russian"
            },
            {
                "id": 201,
                "itemName": "MScA in Biotechnology"
            },
            {
                "id": 202,
                "itemName": "MSc in Mathematics and Statistics"
            },
            {
                "id": 203,
                "itemName": "MSc in Human Genetics"
            },
            {
                "id": 204,
                "itemName": "MSc in Mechanical Engineering"
            },
            {
                "id": 205,
                "itemName": "MA in Mathematics and Statistics"
            },
            {
                "id": 206,
                "itemName": "MA in Educational Leadership"
            },
            {
                "id": 207,
                "itemName": "MScA in Occupational Therapy"
            },
            {
                "id": 208,
                "itemName": "MScA in Physical Therapy"
            },
            {
                "id": 209,
                "itemName": "Joint Executive Master of Business Administration (EMBA)"
            },
            {
                "id": 210,
                "itemName": "Air and Space Law LL.M."
            },
            {
                "id": 211,
                "itemName": "MSc in Nursing"
            },
            {
                "id": 212,
                "itemName": "MA in Education and Society"
            },
            {
                "id": 213,
                "itemName": "Master of Arts in Teaching and Learning (MATL)"
            },
            {
                "id": 214,
                "itemName": "MScA in Animal Science"
            },
            {
                "id": 215,
                "itemName": "MScA in Communication Sciences and Disorders"
            },
            {
                "id": 216,
                "itemName": "MScA in Bioresource Engineering"
            },
            {
                "id": 217,
                "itemName": "MSc in Biostatistics"
            },
            {
                "id": 218,
                "itemName": "MSc of Public Health"
            },
            {
                "id": 219,
                "itemName": "MScA in Human Nutrition"
            },
            {
                "id": 220,
                "itemName": "Professional Part-time MBA"
            },
            {
                "id": 221,
                "itemName": "International Masters for Health Leadership (Master of Management)"
            },
            {
                "id": 222,
                "itemName": "MSc in Family Medicine"
            },
            {
                "id": 223,
                "itemName": "MScA in Couple and Family Therapy"
            },
            {
                "id": 224,
                "itemName": "Master of Information Studies (M.I.St.); Information Studies"
            },
            {
                "id": 225,
                "itemName": "M.M. in Retail Management"
            },
            {
                "id": 226,
                "itemName": "MEng in Biological and Biomedical Engineering"
            },
            {
                "id": 227,
                "itemName": "M.M. in Analytics"
            },
            {
                "id": 228,
                "itemName": "Master of Public Policy"
            },
            {
                "id": 229,
                "itemName": "MEng in Mining Engineering"
            },
            {
                "id": 230,
                "itemName": "MEng in Materials Engineering"
            },
            {
                "id": 231,
                "itemName": "MSc in Mining Engineering"
            },
            {
                "id": 232,
                "itemName": "MSc in Materials Engineering"
            },
            {
                "id": 233,
                "itemName": "MASc in Chemical Engineering"
            },
            {
                "id": 234,
                "itemName": "MASc in Civil Engineering"
            },
            {
                "id": 235,
                "itemName": "Master of Management Sciences (MMSc) - Management of Technology"
            },
            {
                "id": 236,
                "itemName": "MASc in Systems Design Engineering"
            },
            {
                "id": 237,
                "itemName": "MMath in Biostatistics"
            },
            {
                "id": 238,
                "itemName": "MA in Planning"
            },
            {
                "id": 239,
                "itemName": "Master of Environmental Studies (MES) in Planning"
            },
            {
                "id": 240,
                "itemName": "MMath in Actuarial Science"
            },
            {
                "id": 241,
                "itemName": "MMath in Computer Science"
            },
            {
                "id": 242,
                "itemName": "MMath in Applied Mathematics"
            },
            {
                "id": 243,
                "itemName": "Master of Environmental Studies (MES) in Geography"
            },
            {
                "id": 244,
                "itemName": "Master of Environmental Studies (MES) in Social and Ecological Sustainability"
            },
            {
                "id": 245,
                "itemName": "MSc in Public Health and Health Systems"
            },
            {
                "id": 246,
                "itemName": "MA in German"
            },
            {
                "id": 247,
                "itemName": "Master of Accounting (MAcc)"
            },
            {
                "id": 248,
                "itemName": "MA in Recreation and Leisure Studies"
            },
            {
                "id": 249,
                "itemName": "MFA in Studio Art"
            },
            {
                "id": 250,
                "itemName": "MA in French Studies"
            },
            {
                "id": 251,
                "itemName": "MMath in Statistics"
            },
            {
                "id": 252,
                "itemName": "MSc in Earth Sciences"
            },
            {
                "id": 253,
                "itemName": "MMath in Pure Mathematics"
            },
            {
                "id": 254,
                "itemName": "MASc in Mechanical and Mechatronics Engineering"
            },
            {
                "id": 255,
                "itemName": "MASc in Electrical and Computer Engineering"
            },
            {
                "id": 256,
                "itemName": "MASc in Industrial and Organizational Psychology"
            },
            {
                "id": 257,
                "itemName": "MSc in Vision Science"
            },
            {
                "id": 258,
                "itemName": "Master of Economic Development and Innovation (MEDI)"
            },
            {
                "id": 259,
                "itemName": "MSc in Kinesiology"
            },
            {
                "id": 260,
                "itemName": "Master of Architecture (MArch)"
            },
            {
                "id": 261,
                "itemName": "Master of Engineering (MEng) in Chemical Engineering"
            },
            {
                "id": 262,
                "itemName": "Master of Engineering (MEng) in Civil Engineering"
            },
            {
                "id": 263,
                "itemName": "Master of Engineering (MEng) in Electrical and Computer Engineering"
            },
            {
                "id": 264,
                "itemName": "MASc in Management Sciences"
            },
            {
                "id": 265,
                "itemName": "Master of Engineering (MEng) in Mechanical and Mechatronics Engineering"
            },
            {
                "id": 266,
                "itemName": "Master of Engineering (MEng) in Systems Design Engineering"
            },
            {
                "id": 267,
                "itemName": "Master of Taxation (MTax)"
            },
            {
                "id": 268,
                "itemName": "MMath in Combinatorics and Optimization"
            },
            {
                "id": 269,
                "itemName": "Master of Business, Entrepreneurship and Technology (MBET)"
            },
            {
                "id": 270,
                "itemName": "Master of Management Sciences (MMSc)"
            },
            {
                "id": 271,
                "itemName": "MASc in Psychology - Developmental and Communication Science"
            },
            {
                "id": 272,
                "itemName": "Master of Public Health (MPH)"
            },
            {
                "id": 273,
                "itemName": "Master of Engineering (MEng) in Electrical and Computer Engineering - Electric Power Engineering"
            },
            {
                "id": 274,
                "itemName": "Master of Engineering (MEng) in Civil Engineering - Nuclear Engineering"
            },
            {
                "id": 275,
                "itemName": "MA in Global Governance"
            },
            {
                "id": 276,
                "itemName": "MA in Public Issues Anthropology"
            },
            {
                "id": 277,
                "itemName": "MMath in Computational Mathematics"
            },
            {
                "id": 278,
                "itemName": "Master of Theological Studies (MTS)"
            },
            {
                "id": 279,
                "itemName": "Master of Quantitative Finance (MQF)"
            },
            {
                "id": 280,
                "itemName": "MA in Classical Studies"
            },
            {
                "id": 281,
                "itemName": "MA in English - Experimental Digital Media"
            },
            {
                "id": 282,
                "itemName": "MSc in Chemistry - Quantum Information"
            },
            {
                "id": 283,
                "itemName": "Master of Environment and Business (MEB)"
            },
            {
                "id": 284,
                "itemName": "Master of Health Informatics (MHI)"
            },
            {
                "id": 285,
                "itemName": "Master of Public Service (MPS)"
            },
            {
                "id": 286,
                "itemName": "Master of Actuarial Science (MActSc)"
            },
            {
                "id": 287,
                "itemName": "MASc in Chemical Engineering - Nanotechnology"
            },
            {
                "id": 288,
                "itemName": "MASc in Electrical and Computer Engineering - Quantum Information"
            },
            {
                "id": 289,
                "itemName": "Master of Mathematics for Teachers (MMT)"
            },
            {
                "id": 290,
                "itemName": "MSc in Pharmacy"
            },
            {
                "id": 291,
                "itemName": "MA in Intercultural German Studies"
            },
            {
                "id": 292,
                "itemName": "Master of Digital Experience Innovation (MDEI)"
            },
            {
                "id": 293,
                "itemName": "Master of Peace and Conflict Studies (MPACS)"
            },
            {
                "id": 294,
                "itemName": "MA in Geography - Water"
            },
            {
                "id": 295,
                "itemName": "Master of Environmental Studies (MES) in Geography - Water"
            },
            {
                "id": 296,
                "itemName": "MSc in Geography - Water"
            },
            {
                "id": 297,
                "itemName": "Master of Environmental Studies (MES) in Sustainability Management"
            },
            {
                "id": 298,
                "itemName": "Master of Environmental Studies (MES) in Social and Ecological Sustainability - Water"
            },
            {
                "id": 299,
                "itemName": "MMath in Applied Mathematics - Water"
            },
            {
                "id": 300,
                "itemName": "MASc in Civil Engineering - Water"
            },
            {
                "id": 301,
                "itemName": "MSc in Biology - Water"
            },
            {
                "id": 302,
                "itemName": "MSc in Earth Sciences-Water"
            },
            {
                "id": 303,
                "itemName": "Master of Catholic Thought (MCT)"
            },
            {
                "id": 304,
                "itemName": "Master of Climate Change (MCC)"
            },
            {
                "id": 305,
                "itemName": "Master of Health Evaluation (MHE)"
            },
            {
                "id": 306,
                "itemName": "Master of Planning (MPlan)"
            },
            {
                "id": 307,
                "itemName": "MASc in Electrical and Computer Engineering - Nanotechnology"
            },
            {
                "id": 308,
                "itemName": "MASc in Systems Design Engineering - Nanotechnology"
            },
            {
                "id": 309,
                "itemName": "MASc in Mechanical and Mechatronics Engineering - Nanotechnology"
            },
            {
                "id": 310,
                "itemName": "MMath in Applied Mathematics: Quantum Information"
            },
            {
                "id": 311,
                "itemName": "MMath in Combinatorics and Optimization - Quantum Information"
            },
            {
                "id": 312,
                "itemName": "MMath in Computer Science - Quantum Information"
            },
            {
                "id": 313,
                "itemName": "MSc in Chemistry - Nanotechnology"
            },
            {
                "id": 314,
                "itemName": "MSc in Physics - Nanotechnology"
            },
            {
                "id": 315,
                "itemName": "MSc in Physics - Quantum Information"
            },
            {
                "id": 316,
                "itemName": "Master of Environmental Studies (MES) in Sustainability Management - Water"
            },
            {
                "id": 317,
                "itemName": "MA in Economics - Water"
            },
            {
                "id": 318,
                "itemName": "MA in English - Literary Studies"
            },
            {
                "id": 319,
                "itemName": "MA in English - Rhetoric and Communication Design"
            },
            {
                "id": 320,
                "itemName": "MASc in Chemical Engineering - Water"
            },
            {
                "id": 321,
                "itemName": "Master of Architecture (MArch) - Water"
            },
            {
                "id": 322,
                "itemName": "MSc in Public Health and Health Systems - Water"
            },
            {
                "id": 323,
                "itemName": "MA in Social and Legal Studies"
            },
            {
                "id": 324,
                "itemName": "Master of Data Science and Artificial Intelligence (MDSAI)"
            },
            {
                "id": 325,
                "itemName": "Master of Pharmacy (MPharm) in Advanced Pharmacy Practice"
            },
            {
                "id": 326,
                "itemName": "MMath in Data Science"
            },
            {
                "id": 327,
                "itemName": "MMath in Pure Mathematics - Quantum Information"
            },
            {
                "id": 328,
                "itemName": "MSc in Statistics"
            },
            {
                "id": 329,
                "itemName": "MSc in Earth and Environmental Sciences"
            },
            {
                "id": 330,
                "itemName": "MBA"
            },
            {
                "id": 331,
                "itemName": "MSc in Physics and Astronomy"
            },
            {
                "id": 332,
                "itemName": "MSc in Mathematics"
            },
            {
                "id": 333,
                "itemName": "MSc in Health Research Methodology"
            },
            {
                "id": 334,
                "itemName": "M.Sc. in Medical Sciences"
            },
            {
                "id": 335,
                "itemName": "MA in French"
            },
            {
                "id": 336,
                "itemName": "M.S.W. Master of social work"
            },
            {
                "id": 337,
                "itemName": "MA in Work and Society"
            },
            {
                "id": 338,
                "itemName": "M.Eng. in Manufacturing"
            },
            {
                "id": 339,
                "itemName": "MSc in Health and Radiation Physics"
            },
            {
                "id": 340,
                "itemName": "M.A.Sc in Chemical Engineering"
            },
            {
                "id": 341,
                "itemName": "M.A.Sc in Civil Engineering"
            },
            {
                "id": 342,
                "itemName": "M.A.Sc in Electrical and Computer Engineering"
            },
            {
                "id": 343,
                "itemName": "M.A.Sc in Engineering Physics"
            },
            {
                "id": 344,
                "itemName": "M.A.Sc in Mechanical Engineering"
            },
            {
                "id": 345,
                "itemName": "M.A.Sc in Software Engineering"
            },
            {
                "id": 346,
                "itemName": "M.Sc.(OT) -- Master of Science in Occupational Therapy"
            },
            {
                "id": 347,
                "itemName": "M.Sc.(PT) -- Master of Science in Physiotherapy"
            },
            {
                "id": 348,
                "itemName": "M.Eng in Civil Engineering"
            },
            {
                "id": 349,
                "itemName": "M.Eng in Electrical and Computer Engineering"
            },
            {
                "id": 350,
                "itemName": "M.Eng in Engineering Physics"
            },
            {
                "id": 351,
                "itemName": "M.A. in Cultural Studies and Critical Theory"
            },
            {
                "id": 352,
                "itemName": "M.A. in Globalization"
            },
            {
                "id": 353,
                "itemName": "M.A. in Economic Policy"
            },
            {
                "id": 354,
                "itemName": "M.E.E.I. in Engineering Entrepreneurship and Innovation"
            },
            {
                "id": 355,
                "itemName": "M.Eng with designation in Nuclear Engineering"
            },
            {
                "id": 356,
                "itemName": "M.Eng. in Electrical and Biomedical Engineering"
            },
            {
                "id": 357,
                "itemName": "M.A.Sc in Biomedical Engineering"
            },
            {
                "id": 358,
                "itemName": "MSc in Chemical Biology"
            },
            {
                "id": 359,
                "itemName": "MASc in Computational Engineering and Science"
            },
            {
                "id": 360,
                "itemName": "MEng in Computational Science and Engineering"
            },
            {
                "id": 361,
                "itemName": "MSc in Computational Science and Engineering"
            },
            {
                "id": 362,
                "itemName": "Master of Communications Management (MCM)"
            },
            {
                "id": 363,
                "itemName": "MSc/PhD in Cognitive Science of Language"
            },
            {
                "id": 364,
                "itemName": "MD/PhD in Medical Sciences"
            },
            {
                "id": 365,
                "itemName": "MA in Health and Aging"
            },
            {
                "id": 366,
                "itemName": "MA in Communication and New Media"
            },
            {
                "id": 367,
                "itemName": "M.Sc. in eHealth"
            },
            {
                "id": 368,
                "itemName": "MSc in Radiation Sciences"
            },
            {
                "id": 369,
                "itemName": "M.H.M. Master of Health Management"
            },
            {
                "id": 370,
                "itemName": "M.A.Sc in Computational Science and Engineering"
            },
            {
                "id": 371,
                "itemName": "M.T.E.I. in Technology Entrepreneurship and Innovation"
            },
            {
                "id": 372,
                "itemName": "M.E.M.E. in Manufacturing Engineering"
            },
            {
                "id": 373,
                "itemName": "M.E.P.P. in Engineering and Public Policy"
            },
            {
                "id": 374,
                "itemName": "M.Eng.D. in Engineering Design"
            },
            {
                "id": 375,
                "itemName": "M.Eng in Computational Science and Engineering"
            },
            {
                "id": 376,
                "itemName": "MA in Gender Studies and Feminist Research"
            },
            {
                "id": 377,
                "itemName": "MA in Global Politics"
            },
            {
                "id": 378,
                "itemName": "Master of Finance (M.Fin)"
            },
            {
                "id": 379,
                "itemName": "MSc in Global Health"
            },
            {
                "id": 380,
                "itemName": "M.Sc. Child Life and Pediatric Psychosocial Care"
            },
            {
                "id": 381,
                "itemName": "Master of Biomedical Discovery and Commercialization"
            },
            {
                "id": 382,
                "itemName": "Executive MBA"
            },
            {
                "id": 383,
                "itemName": "MD/PhD in Chemical Biology"
            },
            {
                "id": 384,
                "itemName": "MD/PhD in Health Policy"
            },
            {
                "id": 385,
                "itemName": "M.Sc.(SLP) -- Master of Science in Speech Language Pathology"
            },
            {
                "id": 386,
                "itemName": "M.Eng. in Computing and Software"
            },
            {
                "id": 387,
                "itemName": "Master of Financial Math"
            },
            {
                "id": 388,
                "itemName": "MSc in Psychotherapy"
            },
            {
                "id": 389,
                "itemName": "M.Eng. in Systems and Technology"
            },
            {
                "id": 390,
                "itemName": "Master of Library and Information Studies (MLIS)"
            },
            {
                "id": 391,
                "itemName": "MSc in Computing Science"
            },
            {
                "id": 392,
                "itemName": "MSc in Agricultural and Resource Economics"
            },
            {
                "id": 393,
                "itemName": "MSc in Radiology and Diagnostic Imaging"
            },
            {
                "id": 394,
                "itemName": "MA in Kinesiology, Sport, and Recreation"
            },
            {
                "id": 395,
                "itemName": "MA in Drama"
            },
            {
                "id": 396,
                "itemName": "Master of Fine Arts"
            },
            {
                "id": 397,
                "itemName": "MBA with specialization in Natural Resources, Energy and Environment"
            },
            {
                "id": 398,
                "itemName": "MBA/MF combined Program"
            },
            {
                "id": 399,
                "itemName": "MA in the History of Art, Design, & Visual Culture"
            },
            {
                "id": 400,
                "itemName": "MSc in chemical and materials engineering"
            },
            {
                "id": 401,
                "itemName": "MSc in Biomedical Engineering"
            },
            {
                "id": 402,
                "itemName": "Master of Nursing"
            },
            {
                "id": 403,
                "itemName": "MEng in Electrical and Computer Engineering"
            },
            {
                "id": 404,
                "itemName": "MEng in Chemical and Materials Engineering"
            },
            {
                "id": 405,
                "itemName": "MSc in Kinesiology, Sport, and Recreation"
            },
            {
                "id": 406,
                "itemName": "MSc in Pharmacy and Pharmaceutical Sciences"
            },
            {
                "id": 407,
                "itemName": "MSc in Paediatrics"
            },
            {
                "id": 408,
                "itemName": "MSc in Occupational Therapy"
            },
            {
                "id": 409,
                "itemName": "MSc in Linguistics"
            },
            {
                "id": 410,
                "itemName": "MSc in Medical Microbiology and Immunology"
            },
            {
                "id": 411,
                "itemName": "MSc in Electrical and Computer Engineering"
            },
            {
                "id": 412,
                "itemName": "MSc in Medicine"
            },
            {
                "id": 413,
                "itemName": "MSc in Orthodontics"
            },
            {
                "id": 414,
                "itemName": "MA in Classical Archeology"
            },
            {
                "id": 415,
                "itemName": "MSc in Engineering Management"
            },
            {
                "id": 416,
                "itemName": "MSc in Civil and Environmental Engineering"
            },
            {
                "id": 417,
                "itemName": "Master of Forestry"
            },
            {
                "id": 418,
                "itemName": "MEng in Engineering Management"
            },
            {
                "id": 419,
                "itemName": "MA in Classical Languages"
            },
            {
                "id": 420,
                "itemName": "MSc in Rural Sociology"
            },
            {
                "id": 421,
                "itemName": "Master of Music"
            },
            {
                "id": 422,
                "itemName": "MA in Music"
            },
            {
                "id": 423,
                "itemName": "MSc in Medical Sciences"
            },
            {
                "id": 424,
                "itemName": "MA in Earth and Atmospheric Sciences"
            },
            {
                "id": 425,
                "itemName": "MSc in Surgery"
            },
            {
                "id": 426,
                "itemName": "MSc in Ophthalmology and Visual Sciences"
            },
            {
                "id": 427,
                "itemName": "MSc in Physical Therapy"
            },
            {
                "id": 428,
                "itemName": "MSc in Family Ecology and Practice"
            },
            {
                "id": 429,
                "itemName": "MSc in Textiles and Clothing"
            },
            {
                "id": 430,
                "itemName": "MA in Sociology with a specialization in Criminal Justice"
            },
            {
                "id": 431,
                "itemName": "MA in Sociology with a specialization in Population and Life Course Dynamics"
            },
            {
                "id": 432,
                "itemName": "MSc in Earth and Atmospheric Sciences"
            },
            {
                "id": 433,
                "itemName": "MSc in Speech Language Pathology"
            },
            {
                "id": 434,
                "itemName": "MEng in Civil and Environmental Engineering"
            },
            {
                "id": 435,
                "itemName": "MSc in Medical Genetics"
            },
            {
                "id": 436,
                "itemName": "MA in Material Culture"
            },
            {
                "id": 437,
                "itemName": "Maîtrise en sciences de l'éducation - Études en langue et culture"
            },
            {
                "id": 438,
                "itemName": "MEd in Elementary Education"
            },
            {
                "id": 439,
                "itemName": "MEd in Secondary Education"
            },
            {
                "id": 440,
                "itemName": "MSc in Public Health"
            },
            {
                "id": 441,
                "itemName": "MSc in Cancer Sciences"
            },
            {
                "id": 442,
                "itemName": "Master of Agriculture(MAg) in Agricultural, Food and Nutritional Science"
            },
            {
                "id": 443,
                "itemName": "MSc in Agricultural, Food and Nutritional Science"
            },
            {
                "id": 444,
                "itemName": "MSc in Biological Sciences"
            },
            {
                "id": 445,
                "itemName": "MBA with specialization in Public Policy and Management"
            },
            {
                "id": 446,
                "itemName": "MBA with specialization in International Business"
            },
            {
                "id": 447,
                "itemName": "MA in Communications and Technology"
            },
            {
                "id": 448,
                "itemName": "MA in Transnational and Comparative Literatures"
            },
            {
                "id": 449,
                "itemName": "MA in Applied Linguistics"
            },
            {
                "id": 450,
                "itemName": "MEd in Counselling Psychology"
            },
            {
                "id": 451,
                "itemName": "MEd School and Clinical Child Psychology"
            },
            {
                "id": 452,
                "itemName": "MSc Anesthesia & Pain Medicine"
            },
            {
                "id": 453,
                "itemName": "MSc in Dentistry"
            },
            {
                "id": 454,
                "itemName": "MEd in Educational Policy Studies"
            },
            {
                "id": 455,
                "itemName": "MA en études canadiennes"
            },
            {
                "id": 456,
                "itemName": "MEd en études en langue et culture"
            },
            {
                "id": 457,
                "itemName": "MA in Digital Humanities"
            },
            {
                "id": 458,
                "itemName": "MEd in Psychological Studies in Education"
            },
            {
                "id": 459,
                "itemName": "MEd in Measurement, Evaluation, and Data Science"
            },
            {
                "id": 460,
                "itemName": "MEd in Special Education"
            },
            {
                "id": 461,
                "itemName": "MEd in Teaching English as a Second Language"
            },
            {
                "id": 462,
                "itemName": "MSc in Forest Economics"
            },
            {
                "id": 463,
                "itemName": "MBA/MAg combined Program"
            },
            {
                "id": 464,
                "itemName": "MBA with specialization in Innovation and Entrepreneurship"
            },
            {
                "id": 465,
                "itemName": "MBA/MEng combined Program"
            },
            {
                "id": 466,
                "itemName": "MEd in Technology in Education"
            },
            {
                "id": 467,
                "itemName": "MEd in School Counselling"
            },
            {
                "id": 468,
                "itemName": "Master of Design (MDes)"
            },
            {
                "id": 469,
                "itemName": "MSc in Physiology, Cell and Developmental Biology"
            },
            {
                "id": 470,
                "itemName": "MA in Native Studies"
            },
            {
                "id": 471,
                "itemName": "MBA with specialization in Finance"
            },
            {
                "id": 472,
                "itemName": "General MBA"
            },
            {
                "id": 473,
                "itemName": "MBA/MD combined Program"
            },
            {
                "id": 474,
                "itemName": "MSc in Cell Biology"
            },
            {
                "id": 475,
                "itemName": "MFA in Drama"
            },
            {
                "id": 476,
                "itemName": "MSc in Integrated Petroleum Geosciences"
            },
            {
                "id": 477,
                "itemName": "MEd in Health Sciences Education"
            },
            {
                "id": 478,
                "itemName": "MSc in Internet Working"
            },
            {
                "id": 479,
                "itemName": "MA in Ancient Societies and Cultures"
            },
            {
                "id": 480,
                "itemName": "MA in Digital Humanities/MLIS"
            },
            {
                "id": 481,
                "itemName": "MA in Translation Studies"
            },
            {
                "id": 482,
                "itemName": "MSc in Obstetrics and Gynecology"
            },
            {
                "id": 483,
                "itemName": "MSc in Medical Physics"
            },
            {
                "id": 484,
                "itemName": "MSc in Risk and Community Resilience"
            },
            {
                "id": 485,
                "itemName": "Master of Agriculture(MAg) in Agricultural and Rural Sociology"
            },
            {
                "id": 486,
                "itemName": "PhD in Orthodontics"
            },
            {
                "id": 487,
                "itemName": "Postgraduate Dental Education"
            },
            {
                "id": 488,
                "itemName": "Postgraduate Medical Education"
            },
            {
                "id": 489,
                "itemName": "Master of Business Administration/Bachelor of Science in Pharmacy"
            },
            {
                "id": 490,
                "itemName": "Master of Coaching"
            },
            {
                "id": 491,
                "itemName": "MA in Community Engagement"
            },
            {
                "id": 492,
                "itemName": "MA in Gender and Social Justice Studies"
            },
            {
                "id": 493,
                "itemName": "MA in Media and Cultural Studies"
            },
            {
                "id": 494,
                "itemName": "MBA with specialization in Sustainability"
            },
            {
                "id": 495,
                "itemName": "MEd in Educational Studies"
            },
            {
                "id": 496,
                "itemName": "MFA in Art and Design"
            },
            {
                "id": 497,
                "itemName": "MSc in Surgery with a specialization in Surgical Education"
            },
            {
                "id": 498,
                "itemName": "MSc in Oral Biology"
            },
            {
                "id": 499,
                "itemName": "MSc in Oral Medicine"
            },
            {
                "id": 500,
                "itemName": "MSc in Periodontology"
            },
            {
                "id": 501,
                "itemName": "MSc in Urban and Regional Planning"
            },
            {
                "id": 502,
                "itemName": "Master of Financial Management"
            },
            {
                "id": 503,
                "itemName": "MBA/JD combined Program"
            },
            {
                "id": 504,
                "itemName": "MBA/MLIS combined Program"
            },
            {
                "id": 505,
                "itemName": "MBA/PharmD combined Program"
            },
            {
                "id": 506,
                "itemName": "MA en arts en études canadiennes"
            },
            {
                "id": 507,
                "itemName": "Master of Accounting"
            },
            {
                "id": 508,
                "itemName": "MA in History of Art, Design, and Visual Culture"
            },
            {
                "id": 509,
                "itemName": "MA in Policy Studies"
            },
            {
                "id": 510,
                "itemName": "Master of Agriculture (MAg) in Renewable Resources"
            },
            {
                "id": 511,
                "itemName": "MBA/MSc in Physical Therapy Combined Program"
            },
            {
                "id": 512,
                "itemName": "MSc in Multimedia"
            },
            {
                "id": 513,
                "itemName": "MSc in Physical Therapy/PhD combined program"
            },
            {
                "id": 514,
                "itemName": "MSc in Speech Language Pathology/PhD combined program"
            },
            {
                "id": 515,
                "itemName": "Master of Business Administration/Doctor of Pharmacy"
            },
            {
                "id": 516,
                "itemName": "MSc in Pathology and Molecular Medicine"
            },
            {
                "id": 517,
                "itemName": "Master of Industrial Relations (MIR)"
            },
            {
                "id": 518,
                "itemName": "M.A.Sc in Geological Sciences and Geological Engineering"
            },
            {
                "id": 519,
                "itemName": "MNSc in Nursing"
            },
            {
                "id": 520,
                "itemName": "Master of Urban and Regional Planning (MPL)"
            },
            {
                "id": 521,
                "itemName": "MSc in Public Health Sciences"
            },
            {
                "id": 522,
                "itemName": "MSc in Biomedical and Molecular Sciences"
            },
            {
                "id": 523,
                "itemName": "Master of Art Conservation (MAC)"
            },
            {
                "id": 524,
                "itemName": "M.A.Sc in Mining Engineering"
            },
            {
                "id": 525,
                "itemName": "MSc in Computing"
            },
            {
                "id": 526,
                "itemName": "Full-time MBA"
            },
            {
                "id": 527,
                "itemName": "M.A.Sc in Physics, Engineering Physics and Astronomy"
            },
            {
                "id": 528,
                "itemName": "MA in Political Studies"
            },
            {
                "id": 529,
                "itemName": "MA in Kinesiology and Health Studies"
            },
            {
                "id": 530,
                "itemName": "MSc in Kinesiology and Health Studies"
            },
            {
                "id": 531,
                "itemName": "Master of Public Administration (MPA)"
            },
            {
                "id": 532,
                "itemName": "M.A.Sc in Mechanical and Materials Engineering"
            },
            {
                "id": 533,
                "itemName": "M.A.Sc in Mathematics & Engineering"
            },
            {
                "id": 534,
                "itemName": "Master of Education (MEd)"
            },
            {
                "id": 535,
                "itemName": "M.A.Sc in Chemistry"
            },
            {
                "id": 536,
                "itemName": "MSc in Management"
            },
            {
                "id": 537,
                "itemName": "Collaborative M.A.Sc degree in Geo-engineering"
            },
            {
                "id": 538,
                "itemName": "Accelerated MBA for Business Graduates"
            },
            {
                "id": 539,
                "itemName": "Master of Environmental Studies (MES)"
            },
            {
                "id": 540,
                "itemName": "MEng in Mechanical and Materials Engineering"
            },
            {
                "id": 541,
                "itemName": "MA in Cultural Studies"
            },
            {
                "id": 542,
                "itemName": "MA in Global Development Studies"
            },
            {
                "id": 543,
                "itemName": "MA in Gender Studies"
            },
            {
                "id": 544,
                "itemName": "Combined Master of Industrial Relations/JD"
            },
            {
                "id": 545,
                "itemName": "Combined Master of Public Administration/JD"
            },
            {
                "id": 546,
                "itemName": "Combined MA Economics/JD"
            },
            {
                "id": 547,
                "itemName": "Collaborative M.Sc. degree in Biostatistics"
            },
            {
                "id": 548,
                "itemName": "Collaborative M.Sc. degree in Cancer Research"
            },
            {
                "id": 549,
                "itemName": "Collaborative M.A.Sc. degree in Biomedical Engineering"
            },
            {
                "id": 550,
                "itemName": "M.Sc in Anatomical Sciences"
            },
            {
                "id": 551,
                "itemName": "Collaborative M.A.Sc. program in Applied Sustainability"
            },
            {
                "id": 552,
                "itemName": "M.Sc. in Geological Sciences and Geological Engineering"
            },
            {
                "id": 553,
                "itemName": "M.Sc. in Applied Geology"
            },
            {
                "id": 554,
                "itemName": "M.Sc. in Physics, Engineering Physics and Astronomy"
            },
            {
                "id": 555,
                "itemName": "M.Ed. in World Indigenous Studies in Education (WISE)"
            },
            {
                "id": 556,
                "itemName": "MN-PHCNP in Nursing"
            },
            {
                "id": 557,
                "itemName": "Professional Masters in Industrial Relations (PMIR)"
            },
            {
                "id": 558,
                "itemName": "Professional Master of Public Administration (PMPA)"
            },
            {
                "id": 559,
                "itemName": "Executive MBA Americas - A Cornell-Queen's Partnership"
            },
            {
                "id": 560,
                "itemName": "Master of International Business"
            },
            {
                "id": 561,
                "itemName": "Master of Management Analytics"
            },
            {
                "id": 562,
                "itemName": "Master of Finance - Beijing"
            },
            {
                "id": 563,
                "itemName": "MSc in Healthcare Quality"
            },
            {
                "id": 564,
                "itemName": "Combined program MSc in Healthcare Quality/MBA"
            },
            {
                "id": 565,
                "itemName": "Professional Master of Education (PME)"
            },
            {
                "id": 566,
                "itemName": "Master of Entrepreneurship and Innovation"
            },
            {
                "id": 567,
                "itemName": "MSc in Aging and Health"
            },
            {
                "id": 568,
                "itemName": "Master of Earth and Energy Resources Leadership (MEERL)"
            },
            {
                "id": 569,
                "itemName": "Collaborative M.Eng. program in Applied Sustainability"
            },
            {
                "id": 570,
                "itemName": "Collaborative Master's program in Political and Legal Thought"
            },
            {
                "id": 571,
                "itemName": "Collaborative M.A.Sc degree in Computational Science and Engineering"
            },
            {
                "id": 572,
                "itemName": "M.Sc. in Aging and Health"
            },
            {
                "id": 573,
                "itemName": "Master in Arts (Arts Leadership)"
            },
            {
                "id": 574,
                "itemName": "Collaborative degree in Computational Science and Engineering"
            },
            {
                "id": 575,
                "itemName": "M.Sc. in Psychology"
            },
            {
                "id": 576,
                "itemName": "Master of Finance - Toronto"
            },
            {
                "id": 577,
                "itemName": "Master of Management in Artificial Intelligence"
            },
            {
                "id": 578,
                "itemName": "Master of Science in Management (MSc)"
            },
            {
                "id": 579,
                "itemName": "MSc in Translational Medicine"
            },
            {
                "id": 580,
                "itemName": "MA in Screen Cultures and Curatorial Studies"
            },
            {
                "id": 581,
                "itemName": "Professional Masters in Biomedical Informatics"
            },
            {
                "id": 582,
                "itemName": "Master in Health Professions Education"
            },
            {
                "id": 583,
                "itemName": "Master of Philosophy"
            },
            {
                "id": 584,
                "itemName": "MA in Theory and Criticism"
            },
            {
                "id": 585,
                "itemName": "MA in Comparative Literature"
            },
            {
                "id": 586,
                "itemName": "MSc in Epidemiology and Biostatistics"
            },
            {
                "id": 587,
                "itemName": "MSc in Pathology and Laboratory Medicine"
            },
            {
                "id": 588,
                "itemName": "MSc in Physiology and Pharmacology"
            },
            {
                "id": 589,
                "itemName": "MSc in Astronomy"
            },
            {
                "id": 590,
                "itemName": "MScN in Nursing"
            },
            {
                "id": 591,
                "itemName": "MSc in Geophysics"
            },
            {
                "id": 592,
                "itemName": "MSc in Geology"
            },
            {
                "id": 593,
                "itemName": "Master of Library and Information Science (MLIS)"
            },
            {
                "id": 594,
                "itemName": "Master of Journalism & Communication (MMJC)"
            },
            {
                "id": 595,
                "itemName": "MClD in Orthodontics"
            },
            {
                "id": 596,
                "itemName": "MEng in Nuclear Engineering"
            },
            {
                "id": 597,
                "itemName": "MA in Kinesiology"
            },
            {
                "id": 598,
                "itemName": "MFA in Visual Arts"
            },
            {
                "id": 599,
                "itemName": "MSc in Anatomy and Cell Biology"
            },
            {
                "id": 600,
                "itemName": "Master of Business Administration/(MBA)/Juris Doctor (JD)"
            },
            {
                "id": 601,
                "itemName": "MSc in Medical Biophysics"
            },
            {
                "id": 602,
                "itemName": "MClSc in Advanced Health Care Practice"
            },
            {
                "id": 603,
                "itemName": "Master of Clinical Science (MClSc) - Communication Sciences and Disorders (Audiology)"
            },
            {
                "id": 604,
                "itemName": "MA in Media Studies"
            },
            {
                "id": 605,
                "itemName": "Master in Physical Therapy (MPT)"
            },
            {
                "id": 606,
                "itemName": "MA in Theology"
            },
            {
                "id": 607,
                "itemName": "MESc in Biomedical Engineering"
            },
            {
                "id": 608,
                "itemName": "MSc in Applied Mathematics"
            },
            {
                "id": 609,
                "itemName": "Master of Clinical Science (MCISc) in Family Medicine"
            },
            {
                "id": 610,
                "itemName": "MSc (OT) in Occupational Therapy"
            },
            {
                "id": 611,
                "itemName": "MA in Women's Studies and Feminist Research"
            },
            {
                "id": 612,
                "itemName": "MSc in Health and Rehabilitation Sciences"
            },
            {
                "id": 613,
                "itemName": "Master of Clinical Science (MClSc) - Communication Sciences and Disorders (Speech-Language Pathology)"
            },
            {
                "id": 614,
                "itemName": "MEng in Design and Manufacturing Engineering"
            },
            {
                "id": 615,
                "itemName": "MSc(FN) in Foods and Nutrition"
            },
            {
                "id": 616,
                "itemName": "Executive Master of Business Administration (EMBA)"
            },
            {
                "id": 617,
                "itemName": "MA in Popular Music and Culture"
            },
            {
                "id": 618,
                "itemName": "MEng in Chemical and Biochemical Engineering"
            },
            {
                "id": 619,
                "itemName": "MESc in Chemical and Biochemical Engineering"
            },
            {
                "id": 620,
                "itemName": "MESc in Civil and Environmental Engineering"
            },
            {
                "id": 621,
                "itemName": "MESc in Electrical and Computer Engineering"
            },
            {
                "id": 622,
                "itemName": "MESc in Mechanical and Materials Engineering"
            },
            {
                "id": 623,
                "itemName": "MEng in Engineering in Medicine"
            },
            {
                "id": 624,
                "itemName": "MSc(OT) Occupational Therapy/PhD Health and Rehabilitation Sciences"
            },
            {
                "id": 625,
                "itemName": "MClSc in Pathologists' Assistant"
            },
            {
                "id": 626,
                "itemName": "Master in Physical Therapy (MPT)/PhD Health and Rehabilitation Sciences"
            },
            {
                "id": 627,
                "itemName": "Master of Environment and Sustainability (MES)"
            },
            {
                "id": 628,
                "itemName": "Master of Financial Economics (MFE)"
            },
            {
                "id": 629,
                "itemName": "Master of Health Information Science (MHIS)"
            },
            {
                "id": 630,
                "itemName": "MA in Education"
            },
            {
                "id": 631,
                "itemName": "MA in Visual Arts"
            },
            {
                "id": 632,
                "itemName": "MCISc/PhD in Health and Rehabilitation Sciences"
            },
            {
                "id": 633,
                "itemName": "MSc in Clinical Medical Biophysics"
            },
            {
                "id": 634,
                "itemName": "Master of Music (MMus)"
            },
            {
                "id": 635,
                "itemName": "Master of Studies in Law (MSL)"
            },
            {
                "id": 636,
                "itemName": "Master of Professional Education (MPEd) (online)"
            },
            {
                "id": 637,
                "itemName": "Master in Management of Applied Science (MMASc)"
            },
            {
                "id": 638,
                "itemName": "Master of Professional Education (MPEd)"
            },
            {
                "id": 639,
                "itemName": "MEng/MBA Combined Degree Program"
            },
            {
                "id": 640,
                "itemName": "Master of Data Analytics (MDA)"
            },
            {
                "id": 641,
                "itemName": "Maîtrise en musique, option compostion (MMus)"
            },
            {
                "id": 642,
                "itemName": "Maîtrise en sciences de la vision"
            },
            {
                "id": 643,
                "itemName": "Maîtrise en sciences bucco-dentaires"
            },
            {
                "id": 644,
                "itemName": "Maîtrise en musique, option interprétation (MMus)"
            },
            {
                "id": 645,
                "itemName": "Maîtrise en microbiologie et immunologie"
            },
            {
                "id": 646,
                "itemName": "Maîtrise en anthropologie"
            },
            {
                "id": 647,
                "itemName": "Maîtrise en géographie"
            },
            {
                "id": 648,
                "itemName": "Maîtrise en études françaises"
            },
            {
                "id": 649,
                "itemName": "Maîtrise en études anglaises"
            },
            {
                "id": 650,
                "itemName": "Maîtrise en médecine dentaire"
            },
            {
                "id": 651,
                "itemName": "Maîtrise en santé environnementale et santé au travail"
            },
            {
                "id": 652,
                "itemName": "Maîtrise en finance mathématique et computationnelle"
            },
            {
                "id": 653,
                "itemName": "Maîtrise en traduction"
            },
            {
                "id": 654,
                "itemName": "Maîtrise en criminologie"
            },
            {
                "id": 655,
                "itemName": "Maîtrise en pharmacothérapie avancée"
            },
            {
                "id": 656,
                "itemName": "Maîtrise en psychologie"
            },
            {
                "id": 657,
                "itemName": "Maîtrise en mathématiques"
            },
            {
                "id": 658,
                "itemName": "Maîtrise en sciences de l'activité physique"
            },
            {
                "id": 659,
                "itemName": "Maîtrise en sciences biomédicales"
            },
            {
                "id": 660,
                "itemName": "Maîtrise en santé publique"
            },
            {
                "id": 661,
                "itemName": "Maîtrise en physiologie moléculaire, cellulaire et intégrative"
            },
            {
                "id": 662,
                "itemName": "Maîtrise en pharmacologie"
            },
            {
                "id": 663,
                "itemName": "Maîtrise en nutrition"
            },
            {
                "id": 664,
                "itemName": "Maîtrise en sciences vétérinaires"
            },
            {
                "id": 665,
                "itemName": "Maîtrise en administration des services de santé"
            },
            {
                "id": 666,
                "itemName": "Maîtrise en génie biomédical"
            },
            {
                "id": 667,
                "itemName": "Maîtrise en études cinématographiques"
            },
            {
                "id": 668,
                "itemName": "Maîtrise en études allemandes"
            },
            {
                "id": 669,
                "itemName": "Maîtrise en pathologie et biologie cellulaires"
            },
            {
                "id": 670,
                "itemName": "Maîtrise en histoire de l'art"
            },
            {
                "id": 671,
                "itemName": "Maîtrise en histoire"
            },
            {
                "id": 672,
                "itemName": "Maîtrise en chimie"
            },
            {
                "id": 673,
                "itemName": "Maîtrise en sciences de l'information"
            },
            {
                "id": 674,
                "itemName": "Maîtrise en urbanisme"
            },
            {
                "id": 675,
                "itemName": "Maîtrise en études hispaniques"
            },
            {
                "id": 676,
                "itemName": "Maîtrise en démographie"
            },
            {
                "id": 677,
                "itemName": "Maîtrise en aménagement"
            },
            {
                "id": 678,
                "itemName": "Maîtrise en sciences de la communication"
            },
            {
                "id": 679,
                "itemName": "Maîtrise en linguistique"
            },
            {
                "id": 680,
                "itemName": "Maîtrise en informatique"
            },
            {
                "id": 681,
                "itemName": "Maîtrise en philosophie"
            },
            {
                "id": 682,
                "itemName": "Maîtrise en statistique"
            },
            {
                "id": 683,
                "itemName": "Maîtrise en psychoéducation"
            },
            {
                "id": 684,
                "itemName": "Maîtrise en physique"
            },
            {
                "id": 685,
                "itemName": "Maîtrise en relations industrielles"
            },
            {
                "id": 686,
                "itemName": "Maîtrise en sciences biologiques"
            },
            {
                "id": 687,
                "itemName": "Maîtrise en science politique"
            },
            {
                "id": 688,
                "itemName": "Maîtrise en sociologie"
            },
            {
                "id": 689,
                "itemName": "Maîtrise en sciences économiques"
            },
            {
                "id": 690,
                "itemName": "Maîtrise en droit"
            },
            {
                "id": 691,
                "itemName": "Maîtrise en sciences de l'éducation"
            },
            {
                "id": 692,
                "itemName": "Maîtrise en sciences infirmières"
            },
            {
                "id": 693,
                "itemName": "Maîtrise en littérature comparée"
            },
            {
                "id": 694,
                "itemName": "Maîtrise en études classiques"
            },
            {
                "id": 695,
                "itemName": "Maîtrise en éducation"
            },
            {
                "id": 696,
                "itemName": "Maîtrise en sciences pharmaceutiques"
            },
            {
                "id": 697,
                "itemName": "Maîtrise en théologie pratique"
            },
            {
                "id": 698,
                "itemName": "Maîtrise en théologie"
            },
            {
                "id": 699,
                "itemName": "Maîtrise en muséologie"
            },
            {
                "id": 700,
                "itemName": "Maîtrise en musique"
            },
            {
                "id": 701,
                "itemName": "Maîtrise en architecture"
            },
            {
                "id": 702,
                "itemName": "Maîtrise professionnelle en audiologie"
            },
            {
                "id": 703,
                "itemName": "Maîtrise professionnelle en orthophonie"
            },
            {
                "id": 704,
                "itemName": "Maîtrise en biochimie"
            },
            {
                "id": 705,
                "itemName": "Maîtrise en bioinformatique"
            },
            {
                "id": 706,
                "itemName": "Maîtrise en biologie moléculaire"
            },
            {
                "id": 707,
                "itemName": "Maîtrise en évaluation des technologies de la santé"
            },
            {
                "id": 708,
                "itemName": "Maîtrise en sciences des religions"
            },
            {
                "id": 709,
                "itemName": "Maîtrise en commerce électronique"
            },
            {
                "id": 710,
                "itemName": "Maîtrise en études internationales"
            },
            {
                "id": 711,
                "itemName": "Maîtrise en droit - Fiscalité"
            },
            {
                "id": 712,
                "itemName": "Maîtrise en droit - Notariat"
            },
            {
                "id": 713,
                "itemName": "Maîtrise en kinésiologie"
            },
            {
                "id": 714,
                "itemName": "Maîtrise individualisée (M.Sc.)"
            },
            {
                "id": 715,
                "itemName": "Maîtrise individualisée (M.A.)"
            },
            {
                "id": 716,
                "itemName": "Maîtrise professionnelle en physiothérapie"
            },
            {
                "id": 717,
                "itemName": "Maîtrise professionnelle en ergothérapie"
            },
            {
                "id": 718,
                "itemName": "Maîtrise individualisée (M.Sc.A.)"
            },
            {
                "id": 719,
                "itemName": "Maîtrise en sciences de la réadaptation"
            },
            {
                "id": 720,
                "itemName": "Maitrise en common law Nord-américaine"
            },
            {
                "id": 721,
                "itemName": "Maitrise en droit des affaires"
            },
            {
                "id": 722,
                "itemName": "Maitrise en droit des technologies de l'information"
            },
            {
                "id": 723,
                "itemName": "Maitrise en droit international"
            },
            {
                "id": 724,
                "itemName": "Maîtrise en physiothérapie"
            },
            {
                "id": 725,
                "itemName": "Maîtrise en architecture de paysage"
            },
            {
                "id": 726,
                "itemName": "Maîtrise en bioéthique"
            },
            {
                "id": 727,
                "itemName": "Maîtrise en technologie éducationnelle"
            },
            {
                "id": 728,
                "itemName": "Maîtrise en neurosciences"
            },
            {
                "id": 729,
                "itemName": "Maîtrise en aménagement, option ville, territoire, paysage"
            },
            {
                "id": 730,
                "itemName": "Maîtrise en travail social"
            },
            {
                "id": 731,
                "itemName": "Maîtrise en musique - option composition pour l'écran"
            },
            {
                "id": 732,
                "itemName": "Maîtrise en environnement et développement durable"
            },
            {
                "id": 733,
                "itemName": "Maîtrise en développement du médicament"
            },
            {
                "id": 734,
                "itemName": "Maîtrise en administration des services de santé, option gestion des services infirmiers"
            },
            {
                "id": 735,
                "itemName": "Maîtrise en aménagement, option design urbain"
            },
            {
                "id": 736,
                "itemName": "Maîtrise en sciences pharmaceutiques, option médicament et santé des populations"
            },
            {
                "id": 737,
                "itemName": "MSc in Applied and Computational Mathematics"
            },
            {
                "id": 738,
                "itemName": "MSc in Molecular Biology and Biochemistry"
            },
            {
                "id": 739,
                "itemName": "MA in Gender, Sexuality and Women's Studies"
            },
            {
                "id": 740,
                "itemName": "MEd/MSc in Secondary Mathematics Education"
            },
            {
                "id": 741,
                "itemName": "MBA in Management of Technology"
            },
            {
                "id": 742,
                "itemName": "Master of Publishing (MPub)"
            },
            {
                "id": 743,
                "itemName": "MA in Gerontology"
            },
            {
                "id": 744,
                "itemName": "MEd/MA in Educational Leadership"
            },
            {
                "id": 745,
                "itemName": "MEd/MA in Counselling Psychology"
            },
            {
                "id": 746,
                "itemName": "MFA in Interdisciplinary Studies"
            },
            {
                "id": 747,
                "itemName": "MA in Archaeology"
            },
            {
                "id": 748,
                "itemName": "MA in Criminology"
            },
            {
                "id": 749,
                "itemName": "Master of Resource Management"
            },
            {
                "id": 750,
                "itemName": "MA in Liberal Studies"
            },
            {
                "id": 751,
                "itemName": "MEd/MA Curriculum and Instruction"
            },
            {
                "id": 752,
                "itemName": "MASc in Engineering Science"
            },
            {
                "id": 753,
                "itemName": "MEng in Engineering Science"
            },
            {
                "id": 754,
                "itemName": "MSc in Biomedical Physiology and Kinesiology"
            },
            {
                "id": 755,
                "itemName": "MA in Communication"
            },
            {
                "id": 756,
                "itemName": "Master of Public Policy (MPP)"
            },
            {
                "id": 757,
                "itemName": "MEd/MA in Arts Education"
            },
            {
                "id": 758,
                "itemName": "MA or MSc in Interactive Arts and Technology"
            },
            {
                "id": 759,
                "itemName": "MEd in Educational Practice"
            },
            {
                "id": 760,
                "itemName": "MEd/MA in Educational Psychology"
            },
            {
                "id": 761,
                "itemName": "MEd/MA in Teaching English as an Additional Language"
            },
            {
                "id": 762,
                "itemName": "Master of Environmental Toxicology"
            },
            {
                "id": 763,
                "itemName": "Master of Urban Studies (MUrb)"
            },
            {
                "id": 764,
                "itemName": "MSc in Finance"
            },
            {
                "id": 765,
                "itemName": "Executive MBA "
            },
            {
                "id": 766,
                "itemName": "MSc in Computing Science SFU/Master of Software Engineering Zhejiang University"
            },
            {
                "id": 767,
                "itemName": "MA for Teachers of English (MATE)"
            },
            {
                "id": 768,
                "itemName": "MA in Humanities"
            },
            {
                "id": 769,
                "itemName": "MA in International Studies"
            },
            {
                "id": 770,
                "itemName": "MA in Applied Legal Studies"
            },
            {
                "id": 771,
                "itemName": "Master of Digital Media (MDM)"
            },
            {
                "id": 772,
                "itemName": "MEd/MA in Educational Technology and Learning Design"
            },
            {
                "id": 773,
                "itemName": "MEd/MA Education Individual Program"
            },
            {
                "id": 774,
                "itemName": "Master of Resource Management Planning"
            },
            {
                "id": 775,
                "itemName": "Master of Pest Management"
            },
            {
                "id": 776,
                "itemName": "MSc in Health Sciences"
            },
            {
                "id": 777,
                "itemName": "MSc in Actuarial Science"
            },
            {
                "id": 778,
                "itemName": "MASc in Mechatronic Systems Engineering"
            },
            {
                "id": 779,
                "itemName": "MA in Global Communication Double Degree"
            },
            {
                "id": 780,
                "itemName": "MA in Contemporary Arts"
            },
            {
                "id": 781,
                "itemName": "Maîtrise en éducation en français M.A., M.Ed."
            },
            {
                "id": 782,
                "itemName": "Part-time MBA Program"
            },
            {
                "id": 783,
                "itemName": "MSc in Ecological Restoration"
            },
            {
                "id": 784,
                "itemName": "MA in Terrorism, Risk, and Security Studies"
            },
            {
                "id": 785,
                "itemName": "MA in Heritage Resource Management"
            },
            {
                "id": 786,
                "itemName": "MA in Linguistics of a First Nations Language"
            },
            {
                "id": 787,
                "itemName": "MSc in Accounting with Cognitive Analytics"
            },
            {
                "id": 788,
                "itemName": "MASc in Sustainable Energy Engineering"
            },
            {
                "id": 789,
                "itemName": "MSc in Professional Computer Science"
            },
            {
                "id": 790,
                "itemName": "MSc in chemical and petroleum engineering"
            },
            {
                "id": 791,
                "itemName": "MSc in biomedical engineering"
            },
            {
                "id": 792,
                "itemName": "MEng in biomedical engineering"
            },
            {
                "id": 793,
                "itemName": "MSc in Educational Research"
            },
            {
                "id": 794,
                "itemName": "MEd in educational psychology"
            },
            {
                "id": 795,
                "itemName": "MSc in educational psychology"
            },
            {
                "id": 796,
                "itemName": "MA in English with creative writing option"
            },
            {
                "id": 797,
                "itemName": "MA in communication and media studies"
            },
            {
                "id": 798,
                "itemName": "MA in philosophy"
            },
            {
                "id": 799,
                "itemName": "MA in anthropology"
            },
            {
                "id": 800,
                "itemName": "MSc in chemistry"
            },
            {
                "id": 801,
                "itemName": "MA in history"
            },
            {
                "id": 802,
                "itemName": "MSc in computer science"
            },
            {
                "id": 803,
                "itemName": "MA in linguistics"
            },
            {
                "id": 804,
                "itemName": "MSc in geoscience"
            },
            {
                "id": 805,
                "itemName": "MA in geography"
            },
            {
                "id": 806,
                "itemName": "MEng in chemical and petroleum engineering"
            },
            {
                "id": 807,
                "itemName": "MA in religious studies"
            },
            {
                "id": 808,
                "itemName": "MA in political science"
            },
            {
                "id": 809,
                "itemName": "MSc in physics and astronomy"
            },
            {
                "id": 810,
                "itemName": "Master of Nursing (MN)"
            },
            {
                "id": 811,
                "itemName": "MA in archaeology"
            },
            {
                "id": 812,
                "itemName": "MSc in geography"
            },
            {
                "id": 813,
                "itemName": "MA in Spanish"
            },
            {
                "id": 814,
                "itemName": "MEng in geomatics engineering"
            },
            {
                "id": 815,
                "itemName": "MSc in electrical and computer engineering"
            },
            {
                "id": 816,
                "itemName": "MSc in civil engineering"
            },
            {
                "id": 817,
                "itemName": "MA in economics"
            },
            {
                "id": 818,
                "itemName": "MA in Greek and Roman studies"
            },
            {
                "id": 819,
                "itemName": "MSc in geomatics engineering"
            },
            {
                "id": 820,
                "itemName": "MFA in drama"
            },
            {
                "id": 821,
                "itemName": "MSc in cardiovascular and respiratory sciences"
            },
            {
                "id": 822,
                "itemName": "MSc in community health sciences"
            },
            {
                "id": 823,
                "itemName": "MSc in gastrointestinal sciences"
            },
            {
                "id": 824,
                "itemName": "MSc in clinical psychology"
            },
            {
                "id": 825,
                "itemName": "MSc in medical science"
            },
            {
                "id": 826,
                "itemName": "MSc in neuroscience"
            },
            {
                "id": 827,
                "itemName": "MSc in microbiology and infectious diseases"
            },
            {
                "id": 828,
                "itemName": "MSc in biological sciences"
            },
            {
                "id": 829,
                "itemName": "MA in sociology"
            },
            {
                "id": 830,
                "itemName": "Master of Strategic Studies (MSS)"
            },
            {
                "id": 831,
                "itemName": "Master of Geographic Information Systems (MGIS)"
            },
            {
                "id": 832,
                "itemName": "Master of Biomedical Technology (MBT)"
            },
            {
                "id": 833,
                "itemName": "MSc in sustainable energy development"
            },
            {
                "id": 834,
                "itemName": "Master of Kinesiology (MKin)"
            },
            {
                "id": 835,
                "itemName": "Master of Environmental Design (MEDes) "
            },
            {
                "id": 836,
                "itemName": "MFA in art"
            },
            {
                "id": 837,
                "itemName": "MEng in civil engineering"
            },
            {
                "id": 838,
                "itemName": "MEng in electrical and computer engineering"
            },
            {
                "id": 839,
                "itemName": "MEng in mechanical and manufacturing engineering"
            },
            {
                "id": 840,
                "itemName": "MSc in kinesiology"
            },
            {
                "id": 841,
                "itemName": "Master of Community Medicine (MCM)"
            },
            {
                "id": 842,
                "itemName": "Master of Business Administration"
            },
            {
                "id": 843,
                "itemName": "Juris Doctor (JD)/Master of Business Administration"
            },
            {
                "id": 844,
                "itemName": "Master of Biomedical Technology/Master of Business (MBT/MBA)"
            },
            {
                "id": 845,
                "itemName": "Executive MBA (Master of Business Administration)"
            },
            {
                "id": 846,
                "itemName": "MA in Educational Research"
            },
            {
                "id": 847,
                "itemName": "MEd in Educational Research"
            },
            {
                "id": 848,
                "itemName": "MEng in environmental engineering"
            },
            {
                "id": 849,
                "itemName": "MSc in biochemistry and molecular biology"
            },
            {
                "id": 850,
                "itemName": "MSc in mechanical and manufacturing engineering"
            },
            {
                "id": 851,
                "itemName": "MSc in immunology"
            },
            {
                "id": 852,
                "itemName": "MSc in veterinary medical sciences"
            },
            {
                "id": 853,
                "itemName": "Master of Disability and Community Studies (MDCS)"
            },
            {
                "id": 854,
                "itemName": "Master of Nursing/Nurse Practitioner (MN/NP)"
            },
            {
                "id": 855,
                "itemName": "Masters programs - interdisciplinary specializations"
            },
            {
                "id": 856,
                "itemName": "MSc in Computational Media Design"
            },
            {
                "id": 857,
                "itemName": "Master of Pathologists' Assistant"
            },
            {
                "id": 858,
                "itemName": "Master of Nursing/MBA combined degree"
            },
            {
                "id": 859,
                "itemName": "Master of Planning/MBA combined degree"
            },
            {
                "id": 860,
                "itemName": "Master of Pathologists' Assistant (MPath)"
            },
            {
                "id": 861,
                "itemName": "Master of Landscape Architecture (MLA)"
            },
            {
                "id": 862,
                "itemName": "Master of Public Policy/Master of Business Administration (MPP/MBA)"
            },
            {
                "id": 863,
                "itemName": "Master of Management (MMgmt)"
            },
            {
                "id": 864,
                "itemName": "MSc in Food Science"
            },
            {
                "id": 865,
                "itemName": "MEng in Industrial Engineering"
            },
            {
                "id": 866,
                "itemName": "Master of Architecture (Post-Professional)"
            },
            {
                "id": 867,
                "itemName": "MSc in Physiotherapy"
            },
            {
                "id": 868,
                "itemName": "MEng in Biological Engineering"
            },
            {
                "id": 869,
                "itemName": "MASc in Mechanical Engineering"
            },
            {
                "id": 870,
                "itemName": "MASc in Industrial Engineering"
            },
            {
                "id": 871,
                "itemName": "MSc in Pharmaceutical Sciences"
            },
            {
                "id": 872,
                "itemName": "Master of Environmental Studies"
            },
            {
                "id": 873,
                "itemName": "MA in Health Promotion"
            },
            {
                "id": 874,
                "itemName": "JD/Master of Public Administration"
            },
            {
                "id": 875,
                "itemName": "JD/Master of Library Information Studies"
            },
            {
                "id": 876,
                "itemName": "MA in Leisure Studies"
            },
            {
                "id": 877,
                "itemName": "Master of Business Administration (Corporate Residency)"
            },
            {
                "id": 878,
                "itemName": "Master of Public Administration"
            },
            {
                "id": 879,
                "itemName": "Master of Marine Management"
            },
            {
                "id": 880,
                "itemName": "MSc in Psychology and Neuroscience"
            },
            {
                "id": 881,
                "itemName": "Master of Nursing/Master of Health Administration"
            },
            {
                "id": 882,
                "itemName": "MSc in Engineering Mathematics"
            },
            {
                "id": 883,
                "itemName": "Master of Library and Information Studies"
            },
            {
                "id": 884,
                "itemName": "MSc in Anatomy and Neurobiology"
            },
            {
                "id": 885,
                "itemName": "JD/Master of Health Services Administration"
            },
            {
                "id": 886,
                "itemName": "Master of Health Administration"
            },
            {
                "id": 887,
                "itemName": "MA in Sociology and Social Anthropology"
            },
            {
                "id": 888,
                "itemName": "MD/MSc in Oral and Maxillofacial Surgery"
            },
            {
                "id": 889,
                "itemName": "MSc in Epidemiology and Applied Health Research"
            },
            {
                "id": 890,
                "itemName": "Master of Development Economics"
            },
            {
                "id": 891,
                "itemName": "MSc in Physiology and Biophysics"
            },
            {
                "id": 892,
                "itemName": "MSc in Biochemistry and Molecular Biology"
            },
            {
                "id": 893,
                "itemName": "MEng/Master of Planning"
            },
            {
                "id": 894,
                "itemName": "Master of Planning"
            },
            {
                "id": 895,
                "itemName": "MASc/Master of Planning"
            },
            {
                "id": 896,
                "itemName": "Master of Electronic Commerce"
            },
            {
                "id": 897,
                "itemName": "MA in International Development Studies"
            },
            {
                "id": 898,
                "itemName": "MSc in Agriculture"
            },
            {
                "id": 899,
                "itemName": "MSc in Clinical Vision Science and Graduate Diploma in Orthoptics and Ophthalmic Medical Technology"
            },
            {
                "id": 900,
                "itemName": "MSc in Medical Research"
            },
            {
                "id": 901,
                "itemName": "MASc in Biomedical Engineering"
            },
            {
                "id": 902,
                "itemName": "MASc in Environmental Engineering"
            },
            {
                "id": 903,
                "itemName": "MASc in Mining Engineering"
            },
            {
                "id": 904,
                "itemName": "MEng in Environmental Engineering"
            },
            {
                "id": 905,
                "itemName": "MEng in Internetworking"
            },
            {
                "id": 906,
                "itemName": "Master of Health Informatics"
            },
            {
                "id": 907,
                "itemName": "Master of Information Management"
            },
            {
                "id": 908,
                "itemName": "Master of Library and Information Studies/Master of Public Administration"
            },
            {
                "id": 909,
                "itemName": "Masters of Public Administration in Management"
            },
            {
                "id": 910,
                "itemName": "Masters of Social Work"
            },
            {
                "id": 911,
                "itemName": "MBA/Master of Health Services Administration"
            },
            {
                "id": 912,
                "itemName": "Master of Applied Computer Science"
            },
            {
                "id": 913,
                "itemName": "Master of Computer Science"
            },
            {
                "id": 914,
                "itemName": "MEng in Mineral Resource Engineering"
            },
            {
                "id": 915,
                "itemName": "Master of Resource and Environmental Management"
            },
            {
                "id": 916,
                "itemName": "MSc in Oceanography"
            },
            {
                "id": 917,
                "itemName": "Master of Library and Information Studies/Master of Resource & Environmental Management"
            },
            {
                "id": 918,
                "itemName": "MSc in Audiology"
            },
            {
                "id": 919,
                "itemName": "MSc Occupational Therapy (Post Professional)"
            },
            {
                "id": 920,
                "itemName": "MSc in Physiotherapy (Rehabilitation Research)"
            },
            {
                "id": 921,
                "itemName": "Master of Planning Studies"
            },
            {
                "id": 922,
                "itemName": "Master of Environmental Design Studies"
            },
            {
                "id": 923,
                "itemName": "MSc in Atmospheric Science"
            },
            {
                "id": 924,
                "itemName": "Corporate Residency Master of Business Administration / Master of Engineering"
            },
            {
                "id": 925,
                "itemName": "MBA Financial Services"
            },
            {
                "id": 926,
                "itemName": "MSc in Computational Biology and Bioinformatics"
            },
            {
                "id": 927,
                "itemName": "Master of Journalism"
            },
            {
                "id": 928,
                "itemName": "Master of Periodontics"
            },
            {
                "id": 929,
                "itemName": "MASc in Materials Engineering"
            },
            {
                "id": 930,
                "itemName": "MSc in Human Communication Disorders"
            },
            {
                "id": 931,
                "itemName": "Combined Corporate Residency MBA and Juris Doctor"
            },
            {
                "id": 932,
                "itemName": "Master of Digital Innovation"
            },
            {
                "id": 933,
                "itemName": "MSc in Business (MScB)"
            },
            {
                "id": 934,
                "itemName": "MSc in Medical Neuroscience"
            },
            {
                "id": 935,
                "itemName": "MA Philosophy"
            },
            {
                "id": 936,
                "itemName": "MSc Geography"
            },
            {
                "id": 937,
                "itemName": "MA Political Science"
            },
            {
                "id": 938,
                "itemName": "MEng in Engineering"
            },
            {
                "id": 939,
                "itemName": "MASc Engineering"
            },
            {
                "id": 940,
                "itemName": "MSc Marketing and Consumer Studies"
            },
            {
                "id": 941,
                "itemName": "MSc Pathobiology"
            },
            {
                "id": 942,
                "itemName": "MSc International Development Studies"
            },
            {
                "id": 943,
                "itemName": "MA International Development Studies"
            },
            {
                "id": 944,
                "itemName": "MSc Mathematics and Statistics"
            },
            {
                "id": 945,
                "itemName": "MSc Computer science"
            },
            {
                "id": 946,
                "itemName": "MSc Human Health and Nutritional Sciences"
            },
            {
                "id": 947,
                "itemName": "MSc Capacity Development and Extension"
            },
            {
                "id": 948,
                "itemName": "MSc Biomedical Sciences"
            },
            {
                "id": 949,
                "itemName": "MSc Food Safety and Quality Assurance"
            },
            {
                "id": 950,
                "itemName": "MFA Studio Art"
            },
            {
                "id": 951,
                "itemName": "MA Sociology"
            },
            {
                "id": 952,
                "itemName": "MSc in Molecular & Cellular Biology"
            },
            {
                "id": 953,
                "itemName": "MSc Biophysics"
            },
            {
                "id": 954,
                "itemName": "MA History"
            },
            {
                "id": 955,
                "itemName": "MA Geography"
            },
            {
                "id": 956,
                "itemName": "MA Economics"
            },
            {
                "id": 957,
                "itemName": "MA Theatre Studies"
            },
            {
                "id": 958,
                "itemName": "MSc Chemistry"
            },
            {
                "id": 959,
                "itemName": "MSc Toxicology"
            },
            {
                "id": 960,
                "itemName": "MSc (Planning) Rural Planning and Development"
            },
            {
                "id": 961,
                "itemName": "MA Psychology"
            },
            {
                "id": 962,
                "itemName": "MA English"
            },
            {
                "id": 963,
                "itemName": "MSc in Integrative Biology"
            },
            {
                "id": 964,
                "itemName": "MSc Food, Agricultural and Resource Economics"
            },
            {
                "id": 965,
                "itemName": "MSc Food Science"
            },
            {
                "id": 966,
                "itemName": "MSc Population Medicine"
            },
            {
                "id": 967,
                "itemName": "MSc Physics"
            },
            {
                "id": 968,
                "itemName": "MA (Leadership)"
            },
            {
                "id": 969,
                "itemName": "MAN Master of Applied Nutrition"
            },
            {
                "id": 970,
                "itemName": "MBA in Business Administration"
            },
            {
                "id": 971,
                "itemName": "MSc Clinical Studies"
            },
            {
                "id": 972,
                "itemName": "MSc Plant Agriculture"
            },
            {
                "id": 973,
                "itemName": "MA Art History and Visual Culture"
            },
            {
                "id": 974,
                "itemName": "MA Criminology & Criminal Justice Policy"
            },
            {
                "id": 975,
                "itemName": "MA European Studies"
            },
            {
                "id": 976,
                "itemName": "MA Latin American & Caribbean Studies"
            },
            {
                "id": 977,
                "itemName": "MA Public Issues Anthropology"
            },
            {
                "id": 978,
                "itemName": "Master of Bioinformatics (MBNF)"
            },
            {
                "id": 979,
                "itemName": "Master of Biomedical Sciences (MBS)"
            },
            {
                "id": 980,
                "itemName": "Master of Environmental Sciences (MES)"
            },
            {
                "id": 981,
                "itemName": "MFA Creative Writing"
            },
            {
                "id": 982,
                "itemName": "Master of Planning (MPLAN)"
            },
            {
                "id": 983,
                "itemName": "MSc Bioinformatics"
            },
            {
                "id": 984,
                "itemName": "MSc Family Relations and Applied Nutrition"
            },
            {
                "id": 985,
                "itemName": "MSc Neuroscience"
            },
            {
                "id": 986,
                "itemName": "MA Management"
            },
            {
                "id": 987,
                "itemName": "MSc Environmental Sciences"
            },
            {
                "id": 988,
                "itemName": "MSc Tourism & Hospitality"
            },
            {
                "id": 989,
                "itemName": "MSc Psychology"
            },
            {
                "id": 990,
                "itemName": "Master of Biotechnology (MBIOT)"
            },
            {
                "id": 991,
                "itemName": "Master of Food, Agricultural and Resource Economics (MFARE)"
            },
            {
                "id": 992,
                "itemName": "MA Neuroscience"
            },
            {
                "id": 993,
                "itemName": "MSc Animal Bioscience"
            },
            {
                "id": 994,
                "itemName": "MSc Rural Planning and Development"
            },
            {
                "id": 995,
                "itemName": "Master of Cybersecurity and Threat Intelligence (MCTI)"
            },
            {
                "id": 996,
                "itemName": "MA Critical Studies in Improvisation"
            },
            {
                "id": 997,
                "itemName": "MASc Artificial Intelligence"
            },
            {
                "id": 998,
                "itemName": "MSc Artificial Intelligence"
            },
            {
                "id": 999,
                "itemName": "MA One Healthy"
            },
            {
                "id": 1000,
                "itemName": "MASc One Health"
            },
            {
                "id": 1001,
                "itemName": "MES One Health"
            },
            {
                "id": 1002,
                "itemName": "MEng One Healthy"
            },
            {
                "id": 1003,
                "itemName": "MSc in Health Informatics"
            },
            {
                "id": 1004,
                "itemName": "MA in Greek and Roman Studies"
            },
            {
                "id": 1005,
                "itemName": "MSc in Earth and Ocean Science"
            },
            {
                "id": 1006,
                "itemName": "MA in Theatre "
            },
            {
                "id": 1007,
                "itemName": "MPA (Master of Public Administration)"
            },
            {
                "id": 1008,
                "itemName": "MA in French Literature, Language, and Culture"
            },
            {
                "id": 1009,
                "itemName": "MSc in Microbiology"
            },
            {
                "id": 1010,
                "itemName": "MSW (Social Work)"
            },
            {
                "id": 1011,
                "itemName": "MA in Musicology (with Performance)"
            },
            {
                "id": 1012,
                "itemName": "MMus in Music Composition"
            },
            {
                "id": 1013,
                "itemName": "MA in Germanic and Slavic Studies"
            },
            {
                "id": 1014,
                "itemName": "MA in Statistics"
            },
            {
                "id": 1015,
                "itemName": "MA in Mathematics"
            },
            {
                "id": 1016,
                "itemName": "MBA/EDHEC MSc"
            },
            {
                "id": 1017,
                "itemName": "MEd in Coaching Studies"
            },
            {
                "id": 1018,
                "itemName": "MEd in Art Education"
            },
            {
                "id": 1019,
                "itemName": "MA in Leadership Studies"
            },
            {
                "id": 1020,
                "itemName": "MEd in Curriculum Studies"
            },
            {
                "id": 1021,
                "itemName": "MEd in Social, Cultural and Foundational Studies"
            },
            {
                "id": 1022,
                "itemName": "MEd in Mathematics, Science, Social Studies or Educational Technology"
            },
            {
                "id": 1023,
                "itemName": "MEd in Language and Literacy"
            },
            {
                "id": 1024,
                "itemName": "LLM - Master of Law"
            },
            {
                "id": 1025,
                "itemName": "MA in Child and Youth Care"
            },
            {
                "id": 1026,
                "itemName": "MA in Indigenous Governance"
            },
            {
                "id": 1027,
                "itemName": "MA in Hispanic and Italian Studies"
            },
            {
                "id": 1028,
                "itemName": "MA in Pacific and Asian Studies"
            },
            {
                "id": 1029,
                "itemName": "MN in Nursing"
            },
            {
                "id": 1030,
                "itemName": "MEd in Early Childhood Education"
            },
            {
                "id": 1031,
                "itemName": "MA in Environmental Studies"
            },
            {
                "id": 1032,
                "itemName": "MSc in Environmental Studies"
            },
            {
                "id": 1033,
                "itemName": "MA in Social Dimensions of Health"
            },
            {
                "id": 1034,
                "itemName": "MPH (Master of Public Health)"
            },
            {
                "id": 1035,
                "itemName": "MSc in Social Dimensions of Health"
            },
            {
                "id": 1036,
                "itemName": "MFA in Writing"
            },
            {
                "id": 1037,
                "itemName": "MGB (Master of Global Business)"
            },
            {
                "id": 1038,
                "itemName": "MA in Interdisciplinary Studies"
            },
            {
                "id": 1039,
                "itemName": "MSc in Interdisciplinary Studies"
            },
            {
                "id": 1040,
                "itemName": "MA in Physical Education"
            },
            {
                "id": 1041,
                "itemName": "MBA/MEng in Electrical and Computer Engineering"
            },
            {
                "id": 1042,
                "itemName": "MBA/MSc in Computer Science"
            },
            {
                "id": 1043,
                "itemName": "MA in Indigenous Communities Counselling"
            },
            {
                "id": 1044,
                "itemName": "MA in Indigenous Language Revitalization"
            },
            {
                "id": 1045,
                "itemName": "MBA/JD (Master of Business Administration and Juris Doctor)"
            },
            {
                "id": 1046,
                "itemName": "MEd in Indigenous Language Revitalization"
            },
            {
                "id": 1047,
                "itemName": "MFA in Theatre"
            },
            {
                "id": 1048,
                "itemName": "MN/MSc in Nursing and Health Informatics"
            },
            {
                "id": 1049,
                "itemName": "MSc in Clinical Psychology"
            },
            {
                "id": 1050,
                "itemName": "MGB (Master of Global Business) / MIM (International Business Management)"
            },
            {
                "id": 1051,
                "itemName": "MEng in Telecommunications and Information Security"
            },
            {
                "id": 1052,
                "itemName": "MA in Community Development"
            },
            {
                "id": 1053,
                "itemName": "MEd in Leadership Studies"
            },
            {
                "id": 1054,
                "itemName": "MA in Art History and Visual Studies"
            },
            {
                "id": 1055,
                "itemName": "MA in Educational Studies"
            },
            {
                "id": 1056,
                "itemName": "MBA/MEng in Mechanical Engineering"
            },
            {
                "id": 1057,
                "itemName": "MMus in Music Performance"
            },
            {
                "id": 1058,
                "itemName": "MMus in Music Technology"
            },
            {
                "id": 1059,
                "itemName": "MEng in Applied Data Science"
            },
            {
                "id": 1060,
                "itemName": "MASc in Environmental Applied Science and Management"
            },
            {
                "id": 1061,
                "itemName": "Master of Spatial Analysis (MSA)"
            },
            {
                "id": 1062,
                "itemName": "MA in Communication and Culture"
            },
            {
                "id": 1063,
                "itemName": "MASc/MEng in Chemical Engineering"
            },
            {
                "id": 1064,
                "itemName": "MASc/MEng in Civil Engineering"
            },
            {
                "id": 1065,
                "itemName": "MASc/MEng in Electrical and Computer Engineering"
            },
            {
                "id": 1066,
                "itemName": "MASc/MEng in Mechanical and Industrial Engineering"
            },
            {
                "id": 1067,
                "itemName": "MASc/MEng in Computer Networks"
            },
            {
                "id": 1068,
                "itemName": "MA in Immigration and Settlement Studies"
            },
            {
                "id": 1069,
                "itemName": "MA in International Economics and Finance"
            },
            {
                "id": 1070,
                "itemName": "MA in Film and Photograph Preservation and Collections Management"
            },
            {
                "id": 1071,
                "itemName": "MA in Public Policy and Administration"
            },
            {
                "id": 1072,
                "itemName": "MA in Early Childhood Studies"
            },
            {
                "id": 1073,
                "itemName": "MBA in Management of Technology and Innovation"
            },
            {
                "id": 1074,
                "itemName": "Master of Science in Management (MScM)"
            },
            {
                "id": 1075,
                "itemName": "MSc in Molecular Science"
            },
            {
                "id": 1076,
                "itemName": "MA in Media Production"
            },
            {
                "id": 1077,
                "itemName": "MASc/MEng in Aerospace Engineering"
            },
            {
                "id": 1078,
                "itemName": "MFA in Documentary Media"
            },
            {
                "id": 1079,
                "itemName": "MHSc in Nutrition Communication"
            },
            {
                "id": 1080,
                "itemName": "Master of Journalism (MJ)"
            },
            {
                "id": 1081,
                "itemName": "MA in Literatures of Modernity"
            },
            {
                "id": 1082,
                "itemName": "Master of Planning (MPl) in Urban Development"
            },
            {
                "id": 1083,
                "itemName": "MBSc in Building Science"
            },
            {
                "id": 1084,
                "itemName": "MA in Fashion"
            },
            {
                "id": 1085,
                "itemName": "Master of Professional Communication (MPC)"
            },
            {
                "id": 1086,
                "itemName": "MASc in Building Science"
            },
            {
                "id": 1087,
                "itemName": "MASc/MEng in Biomedical Engineering"
            },
            {
                "id": 1088,
                "itemName": "Master of Engineering Innovation and Entrepreneurship (MEIE)"
            },
            {
                "id": 1089,
                "itemName": "MSc in Data Science and Analytics"
            },
            {
                "id": 1090,
                "itemName": "MA in Criminology and Social Justice"
            },
            {
                "id": 1091,
                "itemName": "Master of Health Administration in Community Care"
            },
            {
                "id": 1092,
                "itemName": "Maîtrise en psychopédagogie (M.A.)"
            },
            {
                "id": 1093,
                "itemName": "Maîtrise en didactique (M.A.)"
            },
            {
                "id": 1094,
                "itemName": "Maîtrise en physique - avec mémoire (M. Sc.)"
            },
            {
                "id": 1095,
                "itemName": "Maîtrise en microbiologie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1096,
                "itemName": "Maîtrise en génie des matériaux et de la métallurgie - avec mémoire  (M. Sc.)"
            },
            {
                "id": 1097,
                "itemName": "Maîtrise en travail social - avec mémoire (M. Trav. soc.)"
            },
            {
                "id": 1098,
                "itemName": "Maîtrise en statistique (M. Sc.)"
            },
            {
                "id": 1099,
                "itemName": "Maîtrise en génie mécanique - avec mémoire (M. Sc.)"
            },
            {
                "id": 1100,
                "itemName": "Maîtrise en chimie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1101,
                "itemName": "Maîtrise en biologie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1102,
                "itemName": "Maîtrise en biochimie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1103,
                "itemName": "Maîtrise en aménagement du territoire et développement régional (M. ATDR)"
            },
            {
                "id": 1104,
                "itemName": "Maîtrise en sciences infirmières - avec mémoire (M. Sc.)"
            },
            {
                "id": 1105,
                "itemName": "Maîtrise en théologie - études pastorales (M.A.)"
            },
            {
                "id": 1106,
                "itemName": "Maîtrise en sociologie - avec mémoire (M.A.)"
            },
            {
                "id": 1107,
                "itemName": "Maîtrise en anthropologie - avec mémoire (M.A.)"
            },
            {
                "id": 1108,
                "itemName": "Maîtrise en architecture (M. Arch.)"
            },
            {
                "id": 1109,
                "itemName": "Maîtrise en épidémiologie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1110,
                "itemName": "Maîtrise en microbiologie-immunologie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1111,
                "itemName": "Maîtrise en sciences forestières (M. Sc.)"
            },
            {
                "id": 1112,
                "itemName": "Maîtrise en sciences du bois génie du bois et des matériaux biosourcés - avec mémoire (M. Sc.)"
            },
            {
                "id": 1113,
                "itemName": "Maîtrise en sciences de l'orientation - avec mémoire (M.A.)"
            },
            {
                "id": 1114,
                "itemName": "Maîtrise en études internationales - avec mémoire (M.A.)"
            },
            {
                "id": 1115,
                "itemName": "Maîtrise en littérature et arts de la scène et de l'écran - avec mémoire (M.A.)"
            },
            {
                "id": 1116,
                "itemName": "Maîtrise en agroforesterie (M. Sc.)"
            },
            {
                "id": 1117,
                "itemName": "Maîtrise en linguistique - didactiques des langues (M.A.)"
            },
            {
                "id": 1118,
                "itemName": "Maîtrise en histoire - archivistique (M.A.)"
            },
            {
                "id": 1119,
                "itemName": "Maîtrise en sciences géomatiques - géomatique appliquée (M. Sc.)"
            },
            {
                "id": 1120,
                "itemName": "Maîtrise en administration des affaires - gestion des affaires numériques (M.B.A.)"
            },
            {
                "id": 1121,
                "itemName": "Maîtrise en philosophie - avec mémoire (M.A.)"
            },
            {
                "id": 1122,
                "itemName": "Maîtrise en santé publique (M. Sc.)"
            },
            {
                "id": 1123,
                "itemName": "Maîtrise en neurobiologie neurosciences - avec mémoire (M. Sc.)"
            },
            {
                "id": 1124,
                "itemName": "Maîtrise en traduction et terminologie - avec mémoire (M.A.)"
            },
            {
                "id": 1125,
                "itemName": "Maîtrise en littératures d'expression espagnole - avec mémoire (M.A.)"
            },
            {
                "id": 1126,
                "itemName": "Maîtrise en littératures d'expression anglaise - avec mémoire (M.A.)"
            },
            {
                "id": 1127,
                "itemName": "Maîtrise en nutrition - avec mémoire (M. Sc.)"
            },
            {
                "id": 1128,
                "itemName": "Maîtrise en sols et environnement - avec mémoire (M. Sc.)"
            },
            {
                "id": 1129,
                "itemName": "Maîtrise en génie des mines - avec mémoire (M. Sc.)"
            },
            {
                "id": 1130,
                "itemName": "Maîtrise en mathématiques (M. Sc.)"
            },
            {
                "id": 1131,
                "itemName": "Maîtrise en science politique - avec mémoire (M.A.)"
            },
            {
                "id": 1132,
                "itemName": "Maîtrise en médecine moléculaire - avec mémoire (M. Sc.)"
            },
            {
                "id": 1133,
                "itemName": "Maîtrise interuniversitaire en génie aérospatial - environnement virtuel (M. Sc.)"
            },
            {
                "id": 1134,
                "itemName": "Maîtrise en biologie cellulaire et moléculaire - avec mémoire (M. Sc.)"
            },
            {
                "id": 1135,
                "itemName": "Maîtrise en histoire de l'art - avec mémoire (M.A.)"
            },
            {
                "id": 1136,
                "itemName": "Maîtrise en économique (M.A.)"
            },
            {
                "id": 1137,
                "itemName": "Maîtrise en études anciennes - avec mémoire (M.A.)"
            },
            {
                "id": 1138,
                "itemName": "Maîtrise en archéologie - avec mémoire (M.A.)"
            },
            {
                "id": 1139,
                "itemName": "Maîtrise en sciences cliniques et biomédicales - avec mémoire (M. Sc.)"
            },
            {
                "id": 1140,
                "itemName": "Maîtrise en affaires publiques - analyse et évaluation (M.A.)"
            },
            {
                "id": 1141,
                "itemName": "Maîtrise en relations industrielles (M.A.)"
            },
            {
                "id": 1142,
                "itemName": "Maîtrise en informatique - avec mémoire (M. Sc.)"
            },
            {
                "id": 1143,
                "itemName": "Maîtrise en sciences géographiques - avec mémoire (M. Sc. géogr.)"
            },
            {
                "id": 1144,
                "itemName": "Maîtrise en génie civil (M. Sc.)"
            },
            {
                "id": 1145,
                "itemName": "Maîtrise en génie chimique - avec mémoire (M. Sc.)"
            },
            {
                "id": 1146,
                "itemName": "Maîtrise en sciences des aliments (M. Sc.)"
            },
            {
                "id": 1147,
                "itemName": "Maîtrise en génie électrique (M. Sc.)"
            },
            {
                "id": 1148,
                "itemName": "Maîtrise en pharmacothérapie avancée (M. Sc.)"
            },
            {
                "id": 1149,
                "itemName": "Maîtrise en arts visuels - avec mémoire (M.A.)"
            },
            {
                "id": 1150,
                "itemName": "Maîtrise en ethnologie et patrimoine - avec mémoire (M.A.)"
            },
            {
                "id": 1151,
                "itemName": "Maîtrise en études littéraires - avec mémoire (M.A.)"
            },
            {
                "id": 1152,
                "itemName": "Maîtrise en biologie végétale - avec mémoire (M. Sc.)"
            },
            {
                "id": 1153,
                "itemName": "Maîtrise en technologie éducative (M.A.)"
            },
            {
                "id": 1154,
                "itemName": "Maîtrise en droit - droit des affaires (LL. M.)"
            },
            {
                "id": 1155,
                "itemName": "Maîtrise en sciences des religions (M.A.)"
            },
            {
                "id": 1156,
                "itemName": "Maîtrise en aménagement du territoire et développement régional - avec mémoire (M. ATDR)"
            },
            {
                "id": 1157,
                "itemName": "Maîtrise en sciences de l'architecture - avec mémoire (M. Sc.)"
            },
            {
                "id": 1158,
                "itemName": "Maîtrise en sciences de l'architecture - design urbain (M. Sc.)"
            },
            {
                "id": 1159,
                "itemName": "Maîtrise en droit - avec mémoire (LL. M.)"
            },
            {
                "id": 1160,
                "itemName": "Maîtrise en agroforesterie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1161,
                "itemName": "Maîtrise en sciences forestières - avec mémoire (M. Sc.)"
            },
            {
                "id": 1162,
                "itemName": "Maîtrise en sciences géomatiques - avec mémoire (M. Sc.)"
            },
            {
                "id": 1163,
                "itemName": "Maîtrise en kinésiologie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1164,
                "itemName": "Maîtrise en orthophonie (M. Sc.)"
            },
            {
                "id": 1165,
                "itemName": "Maîtrise en santé publique - avec mémoire (M. Sc.)"
            },
            {
                "id": 1166,
                "itemName": "Maîtrise en santé publique - promotion de la santé (M. Sc.)"
            },
            {
                "id": 1167,
                "itemName": "Maîtrise en sciences dentaires - avec mémoire (M. Sc.)"
            },
            {
                "id": 1168,
                "itemName": "Maîtrise en sciences dentaires - chirurgie buccale et maxillo-faciale - avec mémoire (M. Sc.)"
            },
            {
                "id": 1169,
                "itemName": "Maîtrise en sciences dentaires - parodontie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1170,
                "itemName": "Maîtrise en sciences des religions - avec mémoire (M.A.)"
            },
            {
                "id": 1171,
                "itemName": "Maîtrise en théologie - avec mémoire (M.A.)"
            },
            {
                "id": 1172,
                "itemName": "Maîtrise en communication publique - avec mémoire (M.A.)"
            },
            {
                "id": 1173,
                "itemName": "Maîtrise en histoire - avec mémoire (M.A.)"
            },
            {
                "id": 1174,
                "itemName": "Maîtrise en linguistique - avec mémoire (M.A.)"
            },
            {
                "id": 1175,
                "itemName": "Maîtrise en littératures d'expression anglaise (M.A.)"
            },
            {
                "id": 1176,
                "itemName": "Maîtrise en traduction et terminologie (M.A.)"
            },
            {
                "id": 1177,
                "itemName": "Maîtrise en administration des affaires - gestion de la santé et de la sécurité du travail (M.B.A.)"
            },
            {
                "id": 1178,
                "itemName": "Maîtrise en administration des affaires - comptabilité (M.B.A.)"
            },
            {
                "id": 1179,
                "itemName": "Maîtrise en administration des affaires - finance (M.B.A.)"
            },
            {
                "id": 1180,
                "itemName": "Maîtrise en administration des affaires - gestion des entreprises (M.B.A.)"
            },
            {
                "id": 1181,
                "itemName": "Maîtrise en administration des affaires - gestion internationale (M.B.A.)"
            },
            {
                "id": 1182,
                "itemName": "Maîtrise en administration des affaires - gestion pharmaceutique (M.B.A.)"
            },
            {
                "id": 1183,
                "itemName": "Maîtrise en sciences de l'administration - ingénierie financière (M. Sc.)"
            },
            {
                "id": 1184,
                "itemName": "Maîtrise en biologie végétale (M. Sc.)"
            },
            {
                "id": 1185,
                "itemName": "Maîtrise en génie agroalimentaire - avec mémoire (M. Sc.)"
            },
            {
                "id": 1186,
                "itemName": "Maîtrise en sciences des aliments - avec mémoire (M. Sc.)"
            },
            {
                "id": 1187,
                "itemName": "Maîtrise en sols et environnement (M. Sc.)"
            },
            {
                "id": 1188,
                "itemName": "Maîtrise en administration et politiques de l'éducation - avec mémoire (M.A.)"
            },
            {
                "id": 1189,
                "itemName": "Maîtrise en didactique - avec mémoire (M.A.)"
            },
            {
                "id": 1190,
                "itemName": "Maîtrise en psychopédagogie - difficultés d'apprentissage et d'adapatation (M.A.)"
            },
            {
                "id": 1191,
                "itemName": "Maîtrise en psychopédagogie - adaptation scolaire - avec mémoire (M.A.)"
            },
            {
                "id": 1192,
                "itemName": "Maîtrise en psychopédagogie - avec mémoire (M.A.)"
            },
            {
                "id": 1193,
                "itemName": "Maîtrise en sciences de l'orientation (M.A.)"
            },
            {
                "id": 1194,
                "itemName": "Maîtrise en technologie éducative - avec mémoire (M.A.)"
            },
            {
                "id": 1195,
                "itemName": "Maîtrise en génie civil - avec mémoire (M. Sc.)"
            },
            {
                "id": 1196,
                "itemName": "Maîtrise en génie électrique - avec mémoire (M. Sc.)"
            },
            {
                "id": 1197,
                "itemName": "Maîtrise en mathématiques - avec mémoire (M. Sc.)"
            },
            {
                "id": 1198,
                "itemName": "Maîtrise interuniversitaire en génie aérospatial (M. Sc.)"
            },
            {
                "id": 1199,
                "itemName": "Maîtrise interuniversitaire en sciences de la Terre - avec mémoire  (M. Sc.)"
            },
            {
                "id": 1200,
                "itemName": "Maîtrise en sciences infirmières (M. Sc.)"
            },
            {
                "id": 1201,
                "itemName": "Maîtrise en affaires publiques - gestion publique (M.A.)"
            },
            {
                "id": 1202,
                "itemName": "Maîtrise en affaires publiques - finances publiques (M.A.)"
            },
            {
                "id": 1203,
                "itemName": "Maîtrise en économique - avec mémoire (M.A.)"
            },
            {
                "id": 1204,
                "itemName": "Maîtrise en relations industrielles - avec mémoire (M.A.)"
            },
            {
                "id": 1205,
                "itemName": "Maîtrise en science politique (M.A.)"
            },
            {
                "id": 1206,
                "itemName": "Maîtrise en travail social (M. Trav. soc.)"
            },
            {
                "id": 1207,
                "itemName": "Maîtrise en musique - composition - avec mémoire (M. Mus.)"
            },
            {
                "id": 1208,
                "itemName": "Maîtrise en musique - didactique instrumentale (M. Mus.)"
            },
            {
                "id": 1209,
                "itemName": "Maîtrise en musique - éducation musicale (M. Mus.)"
            },
            {
                "id": 1210,
                "itemName": "Maîtrise en musique - éducation musicale - avec mémoire (M. Mus.)"
            },
            {
                "id": 1211,
                "itemName": "Maîtrise en musique - musicologie - avec mémoire (M. Mus.)"
            },
            {
                "id": 1212,
                "itemName": "Maîtrise en musique - interprétation (M. Mus.)"
            },
            {
                "id": 1213,
                "itemName": "Maîtrise en sciences animales - avec mémoire (M. Sc.)"
            },
            {
                "id": 1214,
                "itemName": "Maîtrise sur mesure"
            },
            {
                "id": 1215,
                "itemName": "Maîtrise en sciences de l'administration - marketing analytique (M. Sc.)"
            },
            {
                "id": 1216,
                "itemName": "Maîtrise en génie civil - technologies environnementales (M. Sc.)"
            },
            {
                "id": 1217,
                "itemName": "Maîtrise en sciences de la Terre - technologies environnementales (M. Sc.)"
            },
            {
                "id": 1218,
                "itemName": "Maîtrise en sciences géographiques - géographie appliquée (M. Sc. géogr.)"
            },
            {
                "id": 1219,
                "itemName": "Maîtrise en épidémiologie - épidémiologie clinique - avec mémoire (M. Sc.)"
            },
            {
                "id": 1220,
                "itemName": "Maîtrise en communication publique (M.A.)"
            },
            {
                "id": 1221,
                "itemName": "Maîtrise en administration des affaires - responsabilité sociale et environnementale des organisations (M.B.A.)"
            },
            {
                "id": 1222,
                "itemName": "Maîtrise en biogéosciences de l'environnement (M. Sc.)"
            },
            {
                "id": 1223,
                "itemName": "Maîtrise en statistique - biostatistique (M. Sc.)"
            },
            {
                "id": 1224,
                "itemName": "Maîtrise en santé publique - évaluation (M. Sc.)"
            },
            {
                "id": 1225,
                "itemName": "Maîtrise en sciences de l'administration - gestion du développement international et de l'action humanitaire (M. Sc.)"
            },
            {
                "id": 1226,
                "itemName": "Maîtrise en sciences de l'administration - finance (M. Sc.)"
            },
            {
                "id": 1227,
                "itemName": "Maîtrise en informatique (M. Sc.)"
            },
            {
                "id": 1228,
                "itemName": "Maîtrise en affaires publiques - avec mémoire (M.A.)"
            },
            {
                "id": 1229,
                "itemName": "Maîtrise en ergothérapie (M. Erg.)"
            },
            {
                "id": 1230,
                "itemName": "Maîtrise en physiothérapie (M. Pht.)"
            },
            {
                "id": 1231,
                "itemName": "Maîtrise en santé publique - santé mondiale (M. Sc.)"
            },
            {
                "id": 1232,
                "itemName": "Maîtrise en sciences dentaires - gérodontologie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1233,
                "itemName": "Maîtrise en communication publique - relations publiques (M.A.)"
            },
            {
                "id": 1234,
                "itemName": "Maîtrise en linguistique - didactiques des langues - avec mémoire (M.A.)"
            },
            {
                "id": 1235,
                "itemName": "Maîtrise en actuariat (M. Sc.)"
            },
            {
                "id": 1236,
                "itemName": "Maîtrise en actuariat - avec mémoire (M. Sc.)"
            },
            {
                "id": 1237,
                "itemName": "Maîtrise en biophotonique - avec mémoire (M. Sc.)"
            },
            {
                "id": 1238,
                "itemName": "Maîtrise en génie des eaux - avec mémoire (M. Sc.)"
            },
            {
                "id": 1239,
                "itemName": "Maîtrise en droit - droit international et transnational (LL. M.)"
            },
            {
                "id": 1240,
                "itemName": "Maîtrise en droit - droit de l'environnement, développement durable et sécurité alimentaire (LL. M.)"
            },
            {
                "id": 1241,
                "itemName": "Maîtrise en droit - droits fondamentaux (LL. M.)"
            },
            {
                "id": 1242,
                "itemName": "Maîtrise en sciences pharmaceutiques - avec mémoire (M. Sc.)"
            },
            {
                "id": 1243,
                "itemName": "Maîtrise en théologie (M.A.)"
            },
            {
                "id": 1244,
                "itemName": "Maîtrise en communication publique - journalisme international (M.A.)"
            },
            {
                "id": 1245,
                "itemName": "Maîtrise en administration des affaires - gestion pour cadres en exercice (M.B.A.)"
            },
            {
                "id": 1246,
                "itemName": "Maîtrise en administration des affaires - Global Business (M.B.A.)"
            },
            {
                "id": 1247,
                "itemName": "Maîtrise en agroéconomie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1248,
                "itemName": "Maîtrise en psychoéducation (M. Psy. éd.)"
            },
            {
                "id": 1249,
                "itemName": "Maîtrise en sciences de l'orientation - recherche et intervention - avec mémoire (M.A.)"
            },
            {
                "id": 1250,
                "itemName": "Maîtrise en physique - physique médicale - avec mémoire (M. Sc.)"
            },
            {
                "id": 1251,
                "itemName": "Maîtrise en statistique - avec mémoire (M. Sc.)"
            },
            {
                "id": 1252,
                "itemName": "Maîtrise en sciences infirmières - soins de première ligne (M. Sc.)"
            },
            {
                "id": 1253,
                "itemName": "Maîtrise en administration des affaires - gestion urbaine et immobilière (M.B.A.)"
            },
            {
                "id": 1254,
                "itemName": "Maîtrise en sciences de l'administration - comptabilité - avec mémoire (M. Sc.)"
            },
            {
                "id": 1255,
                "itemName": "Maîtrise en sciences de l'administration - finance - avec mémoire (M. Sc.)"
            },
            {
                "id": 1256,
                "itemName": "Maîtrise en sciences de l'administration - gestion des technologies de l'information (M. Sc.)"
            },
            {
                "id": 1257,
                "itemName": "Maîtrise en sciences de l'administration - gestion internationale - avec mémoire (M. Sc.)"
            },
            {
                "id": 1258,
                "itemName": "Maîtrise en sciences de l'administration - management - avec mémoire (M. Sc.)"
            },
            {
                "id": 1259,
                "itemName": "Maîtrise en sciences de l'administration - marketing - avec mémoire (M. Sc.)"
            },
            {
                "id": 1260,
                "itemName": "Maîtrise en sciences de l'administration - opérations et systèmes de décision - avec mémoire (M. Sc.)"
            },
            {
                "id": 1261,
                "itemName": "Maîtrise en sciences de l'administration - prise de décision immobilière (M. Sc.)"
            },
            {
                "id": 1262,
                "itemName": "Maîtrise en sciences de l'administration - systèmes d'information organisationnels - avec mémoire (M. Sc.)"
            },
            {
                "id": 1263,
                "itemName": "MBA Global Business"
            },
            {
                "id": 1264,
                "itemName": "Maîtrise en sociologie (M.A.)"
            },
            {
                "id": 1265,
                "itemName": "Maîtrise en design - avec mémoire (M.A.)"
            },
            {
                "id": 1266,
                "itemName": "Maîtrise en administration des affaires - gestion stratégique de projets (M.B.A.)"
            },
            {
                "id": 1267,
                "itemName": "Maîtrise en administration des affaires - gestion des services de santé (M.B.A.)"
            },
            {
                "id": 1268,
                "itemName": "Maîtrise en administration des affaires - gestion de la chaîne d'approvisionnement (M.B.A.)"
            },
            {
                "id": 1269,
                "itemName": "Maîtrise en administration des affaires - gestion marketing (M.B.A.)"
            },
            {
                "id": 1270,
                "itemName": "Maîtrise en administration des affaires - marketing stratégique (M.B.A.)"
            },
            {
                "id": 1271,
                "itemName": "Maîtrise en administration des affaires - planification financière (M.B.A.)"
            },
            {
                "id": 1272,
                "itemName": "Maîtrise en sciences de l'administration - développement des personnes et des organisations (M. Sc.)"
            },
            {
                "id": 1273,
                "itemName": "Maîtrise en sciences de l'administration - logistique et analytique (M. Sc.)"
            },
            {
                "id": 1274,
                "itemName": "Maîtrise en design - design d'interaction (M.A.)"
            },
            {
                "id": 1275,
                "itemName": "Maîtrise en droit notarial (LL. M.)"
            },
            {
                "id": 1276,
                "itemName": "Maîtrise en santé publique - santé environmentale (M. Sc.)"
            },
            {
                "id": 1277,
                "itemName": "Maîtrise en sciences dentaires - endodontie - avec mémoire (M. Sc.)"
            },
            {
                "id": 1278,
                "itemName": "Maîtrise en administration des affaires - stratégie et innovation (M.B.A.)"
            },
            {
                "id": 1279,
                "itemName": "Maîtrise en psychologie - avec mémoire (M.A.)"
            },
            {
                "id": 1280,
                "itemName": "Maîtrise en administration et politiques de l'éducation (M.A.)"
            },
            {
                "id": 1281,
                "itemName": "Maîtrise en mesure et évaluation - avec mémoire (M.A.)"
            },
            {
                "id": 1282,
                "itemName": "Maîtrise en administration des affaires - analytique d'affaires (M.B.A.)"
            },
            {
                "id": 1283,
                "itemName": "Maîtrise en sciences de l'administration - marketing numérique (M. Sc.)"
            },
            {
                "id": 1284,
                "itemName": "Maîtrise en mesure et évaluation (M.A.)"
            },
            {
                "id": 1285,
                "itemName": "MA in Theological Studies"
            },
            {
                "id": 1286,
                "itemName": "MA (Individualized Program)"
            },
            {
                "id": 1287,
                "itemName": "MA in Human Systems Intervention"
            },
            {
                "id": 1288,
                "itemName": "Master of Fine Arts in Studio Arts with concentration in Fibres and Material Practices"
            },
            {
                "id": 1289,
                "itemName": "MBA (Executive Option)"
            },
            {
                "id": 1290,
                "itemName": "MA in Public Policy and Public Administration"
            },
            {
                "id": 1291,
                "itemName": "MA in Judaic Studies"
            },
            {
                "id": 1292,
                "itemName": "MA in Art Therapy, Creative Arts Therapies"
            },
            {
                "id": 1293,
                "itemName": "MA in Child Studies"
            },
            {
                "id": 1294,
                "itemName": "MA in Art Education"
            },
            {
                "id": 1295,
                "itemName": "MA in Social and Cultural Anthropology"
            },
            {
                "id": 1296,
                "itemName": "MEng in Building Engineering"
            },
            {
                "id": 1297,
                "itemName": "M.A.Sc. in Building Engineering"
            },
            {
                "id": 1298,
                "itemName": "MEng in Aerospace Engineering"
            },
            {
                "id": 1299,
                "itemName": "M.Comp.Sci. in Computer Science"
            },
            {
                "id": 1300,
                "itemName": "M.A.Sc. in Mechanical Engineering"
            },
            {
                "id": 1301,
                "itemName": "MA in Film Studies"
            },
            {
                "id": 1302,
                "itemName": "Master of Fine Arts in Studio Arts with Concentration in Painting & Drawing"
            },
            {
                "id": 1303,
                "itemName": "Master of Fine Arts in Studio Arts with Concentration in Sculpture and Ceramics"
            },
            {
                "id": 1304,
                "itemName": "Master of Fine Arts in Studio Arts with Concentration in Photography"
            },
            {
                "id": 1305,
                "itemName": "Master of Fine Arts in Studio Arts with Concentration in Print Media"
            },
            {
                "id": 1306,
                "itemName": "MBA (Investment Management Option)"
            },
            {
                "id": 1307,
                "itemName": "M.Ap.Comp.Sc. in Computer Science"
            },
            {
                "id": 1308,
                "itemName": "M.A.Sc. in Information Systems Security"
            },
            {
                "id": 1309,
                "itemName": "M.A.Sc. in Quality Systems Engineering"
            },
            {
                "id": 1310,
                "itemName": "MEng in Information Systems Security"
            },
            {
                "id": 1311,
                "itemName": "MEng in Quality Systems engineering"
            },
            {
                "id": 1312,
                "itemName": "Master of Fine Arts in Studio Arts with concentration in Film Production"
            },
            {
                "id": 1313,
                "itemName": "MSc in Geography, Urban and Environmental Studies"
            },
            {
                "id": 1314,
                "itemName": "M.A.Sc. in Industrial Engineering"
            },
            {
                "id": 1315,
                "itemName": "M.A.Sc. in Software Engineering"
            },
            {
                "id": 1316,
                "itemName": "MEng in Software Engineering"
            },
            {
                "id": 1317,
                "itemName": "MA in English - Creative Writing"
            },
            {
                "id": 1318,
                "itemName": "MEnv. in Environmental Assessment"
            },
            {
                "id": 1319,
                "itemName": "MA in Traductology"
            },
            {
                "id": 1320,
                "itemName": "MA in English - Literature"
            },
            {
                "id": 1321,
                "itemName": "MA in Music Therapy, Creative Arts Therapies"
            },
            {
                "id": 1322,
                "itemName": "MA in Drama Therapy, Creative Arts Therapies"
            },
            {
                "id": 1323,
                "itemName": "MEnv in Environmental Assessment"
            },
            {
                "id": 1324,
                "itemName": "Master of Supply Chain Management (MSCM)"
            },
            {
                "id": 1325,
                "itemName": "MA in Educational Technology"
            },
            {
                "id": 1326,
                "itemName": "MA in Religions and Cultures"
            },
            {
                "id": 1327,
                "itemName": "MEng in Construction Engineering and Management"
            },
            {
                "id": 1328,
                "itemName": "MA en Traductologie"
            },
            {
                "id": 1329,
                "itemName": "MSc in Health and Exercise Science"
            },
            {
                "id": 1330,
                "itemName": "MA in Digital Innovation in Journalism Studies"
            },
            {
                "id": 1331,
                "itemName": "MSc in the Individualized Program"
            },
            {
                "id": 1332,
                "itemName": "MA in the Individualized Program"
            },
            {
                "id": 1333,
                "itemName": "Master of Fine Arts in Studio Arts with concentration in Intermedia"
            },
            {
                "id": 1334,
                "itemName": "Master of Science in Management"
            },
            {
                "id": 1335,
                "itemName": "Master of Science in Marketing"
            },
            {
                "id": 1336,
                "itemName": "M.A. en lettres françaises"
            },
            {
                "id": 1337,
                "itemName": "Master of Business Administration/Bachelor of Laws (LLB or LLL)"
            },
            {
                "id": 1338,
                "itemName": "M.Sc. en sciences de la terre"
            },
            {
                "id": 1339,
                "itemName": "M.Sc. en chimie"
            },
            {
                "id": 1340,
                "itemName": "Maîtrise en administration des affaires (M.B.A.)"
            },
            {
                "id": 1341,
                "itemName": "M.A. en sociologie"
            },
            {
                "id": 1342,
                "itemName": "M.Sc. en médecine cellulaire et moléculaire"
            },
            {
                "id": 1343,
                "itemName": "M.A. en musique"
            },
            {
                "id": 1344,
                "itemName": "M.A. en éducation"
            },
            {
                "id": 1345,
                "itemName": "Maîtrise en administration des affaires pour cadres (E.M.B.A.)"
            },
            {
                "id": 1346,
                "itemName": "M.Sc.S. en audiologie"
            },
            {
                "id": 1347,
                "itemName": "M.Sc.Inf. en sciences infirmières"
            },
            {
                "id": 1348,
                "itemName": "M.Ing. en génie de l'environnement"
            },
            {
                "id": 1349,
                "itemName": "M.Sc.A. en génie de l'environnement"
            },
            {
                "id": 1350,
                "itemName": "MSc in Cellular and Molecular Medicine"
            },
            {
                "id": 1351,
                "itemName": "Maîtrise en administration des affaires/Droit (JD ou L.L.L.)"
            },
            {
                "id": 1352,
                "itemName": "MA in English Literature"
            },
            {
                "id": 1353,
                "itemName": "MSc in Epidemiology"
            },
            {
                "id": 1354,
                "itemName": "MSc in Systems Science"
            },
            {
                "id": 1355,
                "itemName": "Master of Health Administration "
            },
            {
                "id": 1356,
                "itemName": "M.Ing. en génie électrique et génie informatique"
            },
            {
                "id": 1357,
                "itemName": "M.Ing. en génie mécanique"
            },
            {
                "id": 1358,
                "itemName": "M.A. en science politique"
            },
            {
                "id": 1359,
                "itemName": "M.A. en science économique"
            },
            {
                "id": 1360,
                "itemName": "M.A. en criminologie"
            },
            {
                "id": 1361,
                "itemName": "M.A. en traductologie"
            },
            {
                "id": 1362,
                "itemName": "M.A. en philosophie"
            },
            {
                "id": 1363,
                "itemName": "M.A. en linguistique"
            },
            {
                "id": 1364,
                "itemName": "M.A. en histoire"
            },
            {
                "id": 1365,
                "itemName": "M.A. en géographie"
            },
            {
                "id": 1366,
                "itemName": "M.A. en espagnol"
            },
            {
                "id": 1367,
                "itemName": "M.Sc.A. en génie civil"
            },
            {
                "id": 1368,
                "itemName": "M.Sc. en physique"
            },
            {
                "id": 1369,
                "itemName": "Master of Laws"
            },
            {
                "id": 1370,
                "itemName": "MSc in Human Kinetics"
            },
            {
                "id": 1371,
                "itemName": "M.Ing. en gestion en ingénierie"
            },
            {
                "id": 1372,
                "itemName": "M.Sc. en neuroscience"
            },
            {
                "id": 1373,
                "itemName": "M.Sc. en microbiologie et immunologie"
            },
            {
                "id": 1374,
                "itemName": "M.Sc. en épidémiologie"
            },
            {
                "id": 1375,
                "itemName": "M.Sc. en biochimie"
            },
            {
                "id": 1376,
                "itemName": "Maîtrise en gestion des services de santé "
            },
            {
                "id": 1377,
                "itemName": "M.A. en sciences des religions"
            },
            {
                "id": 1378,
                "itemName": "M.A. en études anciennes"
            },
            {
                "id": 1379,
                "itemName": "M.Sc. en biologie"
            },
            {
                "id": 1380,
                "itemName": "Master of Music "
            },
            {
                "id": 1381,
                "itemName": "M.Sc. en science des systèmes"
            },
            {
                "id": 1382,
                "itemName": "Master in Conference Interpreting (MCI)"
            },
            {
                "id": 1383,
                "itemName": "Master of Human Kinetics"
            },
            {
                "id": 1384,
                "itemName": "Maîtrise en interprétation de conférence (M.C.I.)"
            },
            {
                "id": 1385,
                "itemName": "M.Sc. en géographie"
            },
            {
                "id": 1386,
                "itemName": "M.Sc. en sciences de l'activité physique"
            },
            {
                "id": 1387,
                "itemName": "Master of Fine Arts in Theatre Directing"
            },
            {
                "id": 1388,
                "itemName": "M.Ing. en génie civil"
            },
            {
                "id": 1389,
                "itemName": "M.Ing. en génie chimique"
            },
            {
                "id": 1390,
                "itemName": "M.Sc.A. en génie mécanique"
            },
            {
                "id": 1391,
                "itemName": "M.Sc.A. en génie chimique"
            },
            {
                "id": 1392,
                "itemName": "M.Sc.A. en génie électrique et génie informatique"
            },
            {
                "id": 1393,
                "itemName": "Master of Electronic Business Technologies "
            },
            {
                "id": 1394,
                "itemName": "M.A. en communication"
            },
            {
                "id": 1395,
                "itemName": "M.A. en mondialisation et développement international"
            },
            {
                "id": 1396,
                "itemName": "M.Sc.A. en génie biomédical"
            },
            {
                "id": 1397,
                "itemName": "M.Sc. en technologies des affaires électroniques"
            },
            {
                "id": 1398,
                "itemName": "Master of Education "
            },
            {
                "id": 1399,
                "itemName": "MA in Globalization and International Development"
            },
            {
                "id": 1400,
                "itemName": "Maîtrise en communication"
            },
            {
                "id": 1401,
                "itemName": "M.Sc.S. en ergothérapie"
            },
            {
                "id": 1402,
                "itemName": "M.Sc.S. en physiothérapie"
            },
            {
                "id": 1403,
                "itemName": "MA in Feminist and Gender Studies"
            },
            {
                "id": 1404,
                "itemName": "MSc in Electronic-Business Technologies"
            },
            {
                "id": 1405,
                "itemName": "Graduate Diploma in Chartered Professional Accountancy"
            },
            {
                "id": 1406,
                "itemName": "MSc in Health Systems"
            },
            {
                "id": 1407,
                "itemName": "MA in Public Administration"
            },
            {
                "id": 1408,
                "itemName": "MA in Public and International Affairs"
            },
            {
                "id": 1409,
                "itemName": "MA in Theatre"
            },
            {
                "id": 1410,
                "itemName": "Master of Fine Arts (MFA) Visual Arts"
            },
            {
                "id": 1411,
                "itemName": "M.A. en administration publique"
            },
            {
                "id": 1412,
                "itemName": "M.A. en affaires publiques et internationales"
            },
            {
                "id": 1413,
                "itemName": "M.A. en arts visuels"
            },
            {
                "id": 1414,
                "itemName": "M.I. avec spécialisation en bioinformatique"
            },
            {
                "id": 1415,
                "itemName": "M.A. en études des femmes"
            },
            {
                "id": 1416,
                "itemName": "M.Sc. en gestion"
            },
            {
                "id": 1417,
                "itemName": "M.A. en sciences de l'activité physique"
            },
            {
                "id": 1418,
                "itemName": "M.Sc. en systèmes de santé"
            },
            {
                "id": 1419,
                "itemName": "Maîtrise en technologies des affaires électroniques"
            },
            {
                "id": 1420,
                "itemName": "M.A. en théâtre"
            },
            {
                "id": 1421,
                "itemName": "Maîtrise en pratique théâtrale "
            },
            {
                "id": 1422,
                "itemName": "MASc in Advanced Materials and Manufacturing"
            },
            {
                "id": 1423,
                "itemName": "MEng in Advanced Materials and Manufacturing"
            },
            {
                "id": 1424,
                "itemName": "Master of Information Studies"
            },
            {
                "id": 1425,
                "itemName": "Master of Systems Science "
            },
            {
                "id": 1426,
                "itemName": "Maîtrise en fiscalité"
            },
            {
                "id": 1427,
                "itemName": "Maîtrise en droit et politiques de la santé"
            },
            {
                "id": 1428,
                "itemName": "Maîtrise en études du religieux contemporain"
            },
            {
                "id": 1429,
                "itemName": "Maîtrise en biologie"
            },
            {
                "id": 1430,
                "itemName": "Maîtrise en génie logiciel"
            },
            {
                "id": 1431,
                "itemName": "Maîtrise en économique"
            },
            {
                "id": 1432,
                "itemName": "Maîtrise en sciences géographiques"
            },
            {
                "id": 1433,
                "itemName": "Maîtrise en littérature canadienne comparée"
            },
            {
                "id": 1434,
                "itemName": "Maîtrise en gestion de l'éducation et de la formation"
            },
            {
                "id": 1435,
                "itemName": "Maîtrise en orientation"
            },
            {
                "id": 1436,
                "itemName": "Maîtrise en adaptation scolaire et sociale"
            },
            {
                "id": 1437,
                "itemName": "Maîtrise en service social"
            },
            {
                "id": 1438,
                "itemName": "Maîtrise en gestion de l'ingénierie"
            },
            {
                "id": 1439,
                "itemName": "Maîtrise en génie électrique"
            },
            {
                "id": 1440,
                "itemName": "Maîtrise en génie civil"
            },
            {
                "id": 1441,
                "itemName": "Maîtrise en génie mécanique"
            },
            {
                "id": 1442,
                "itemName": "Maîtrise en administration"
            },
            {
                "id": 1443,
                "itemName": "Maîtrise en administration des affaires (MBA)"
            },
            {
                "id": 1444,
                "itemName": "Maîtrise en administration des affaires (MBA) pour cadres en exercice"
            },
            {
                "id": 1445,
                "itemName": "Maîtrise en génie aérospatial"
            },
            {
                "id": 1446,
                "itemName": "Maîtrise en prévention et règlement des différends"
            },
            {
                "id": 1447,
                "itemName": "Maîtrise en intervention en toxicomanie"
            },
            {
                "id": 1448,
                "itemName": "Maîtrise en enseignement au préscolaire et au primaire"
            },
            {
                "id": 1449,
                "itemName": "Maîtrise en enseignement au secondaire"
            },
            {
                "id": 1450,
                "itemName": "Maîtrise en enseignement au collégial"
            },
            {
                "id": 1451,
                "itemName": "Maîtrise en génie chimique"
            },
            {
                "id": 1452,
                "itemName": "Maîtrise en direction chorale"
            },
            {
                "id": 1453,
                "itemName": "Maîtrise en ergothérapie"
            },
            {
                "id": 1454,
                "itemName": "Maîtrise en immunologie"
            },
            {
                "id": 1455,
                "itemName": "Maîtrise en microbiologie"
            },
            {
                "id": 1456,
                "itemName": "Maîtrise en physiologie"
            },
            {
                "id": 1457,
                "itemName": "Maîtrise en pratiques de la réadaptation"
            },
            {
                "id": 1458,
                "itemName": "Maîtrise en sciences des radiations et imagerie biomédicale"
            },
            {
                "id": 1459,
                "itemName": "Maîtrise recherche en sciences de la santé"
            },
            {
                "id": 1460,
                "itemName": "Maîtrise en environnement"
            },
            {
                "id": 1461,
                "itemName": "Maîtrise en études politiques appliquées"
            },
            {
                "id": 1462,
                "itemName": "Maîtrise en médiation interculturelle"
            },
            {
                "id": 1463,
                "itemName": "Maîtrise en gérontologie"
            },
            {
                "id": 1464,
                "itemName": "Maîtrise qualifiante en enseignement au secondaire"
            },
            {
                "id": 1465,
                "itemName": "Maîtrise en droit notarial"
            },
            {
                "id": 1466,
                "itemName": "Masters in College Teaching"
            },
            {
                "id": 1467,
                "itemName": "Maîtrise en pratique du droit criminel et pénal"
            },
            {
                "id": 1468,
                "itemName": "Maîtrise de professionnalisation en écologie appliquée"
            },
            {
                "id": 1469,
                "itemName": "Maîtrise en biologie cellulaire"
            },
            {
                "id": 1470,
                "itemName": "MES/JD, Master of Environmental Studies/Juris Doctor"
            },
            {
                "id": 1471,
                "itemName": "MBA/JD, Master of Business Administration/Juris Doctor"
            },
            {
                "id": 1472,
                "itemName": "MSc in Physics & Astronomy"
            },
            {
                "id": 1473,
                "itemName": "MA in Communication & Culture"
            },
            {
                "id": 1474,
                "itemName": "MFA/MBA in Visual Arts"
            },
            {
                "id": 1475,
                "itemName": "MA in Linguistics & Applied Linguistics"
            },
            {
                "id": 1476,
                "itemName": "MA in Dance"
            },
            {
                "id": 1477,
                "itemName": "IMBA, International Master of Business Administration"
            },
            {
                "id": 1478,
                "itemName": "MA in Mathematics & Statistics"
            },
            {
                "id": 1479,
                "itemName": "MA in Social & Political Thought"
            },
            {
                "id": 1480,
                "itemName": "MA in Gender, Feminist & Women's Studies"
            },
            {
                "id": 1481,
                "itemName": "MES in Environmental Studies"
            },
            {
                "id": 1482,
                "itemName": "MEd in Education - Language, Culture & Teaching"
            },
            {
                "id": 1483,
                "itemName": "MSc in Earth & Space Science"
            },
            {
                "id": 1484,
                "itemName": "MHRM in Human Resources Management"
            },
            {
                "id": 1485,
                "itemName": "MA in Critical Disability Studies"
            },
            {
                "id": 1486,
                "itemName": "MA in Kinesiology & Health Science"
            },
            {
                "id": 1487,
                "itemName": "MSW, Master of Social Work (Advanced Standing)"
            },
            {
                "id": 1488,
                "itemName": "MDes in Design"
            },
            {
                "id": 1489,
                "itemName": "MA in Theatre & Performance Studies"
            },
            {
                "id": 1490,
                "itemName": "MA in Development Studies"
            },
            {
                "id": 1491,
                "itemName": "MDEM in Disaster & Emergency Management"
            },
            {
                "id": 1492,
                "itemName": "MPPAL, Master of Public Policy, Administration & Law"
            },
            {
                "id": 1493,
                "itemName": "MA in Science & Technology Studies"
            },
            {
                "id": 1494,
                "itemName": "MA in Socio-Legal Studies"
            },
            {
                "id": 1495,
                "itemName": "MScN, Primary Health Care Nurse Practitioner (PHCNP)"
            },
            {
                "id": 1496,
                "itemName": "MA in Information Systems & Technology"
            },
            {
                "id": 1497,
                "itemName": "MF, Master of Finance"
            },
            {
                "id": 1498,
                "itemName": "MASc in Electrical Engineering & Computer Science"
            },
            {
                "id": 1499,
                "itemName": "MFA in Dance: Choreography / Collaboration / Creation"
            },
            {
                "id": 1500,
                "itemName": "MFAc in Financial Accountability"
            },
            {
                "id": 1501,
                "itemName": "MPA, Master of Public Administration"
            },
            {
                "id": 1502,
                "itemName": "MA in Cinema & Media Studies"
            },
            {
                "id": 1503,
                "itemName": "MA in Health"
            },
            {
                "id": 1504,
                "itemName": "JD/MA in Philosophy"
            },
            {
                "id": 1505,
                "itemName": "Professional LLM"
            },
            {
                "id": 1506,
                "itemName": "Professional LLM, Master of Laws in International Business Law"
            },
            {
                "id": 1507,
                "itemName": "LLM, MRP Research Stream"
            },
            {
                "id": 1508,
                "itemName": "MA/JD in Philosophy"
            },
            {
                "id": 1509,
                "itemName": "MA/MBA in Art History"
            },
            {
                "id": 1510,
                "itemName": "MA/MBA in Dance"
            },
            {
                "id": 1511,
                "itemName": "MA/MBA in Film - Cinema & Media Studies"
            },
            {
                "id": 1512,
                "itemName": "MA/MBA in Music"
            },
            {
                "id": 1513,
                "itemName": "MA/MBA in Theatre Studies"
            },
            {
                "id": 1514,
                "itemName": "MFA in Film"
            },
            {
                "id": 1515,
                "itemName": "MFA/MBA in Dance"
            },
            {
                "id": 1516,
                "itemName": "MFA/MBA in Film: Production & Screenwriting"
            },
            {
                "id": 1517,
                "itemName": "MFA/MBA in Theatre"
            },
            {
                "id": 1518,
                "itemName": "MFSc, Masters of Fitness Science in Kinesiology & Health Science"
            },
            {
                "id": 1519,
                "itemName": "MScN in Alternate Admission from RN"
            },
            {
                "id": 1520,
                "itemName": "BAi, Baccalauréat ès arts international en traduction"
            },
            {
                "id": 1521,
                "itemName": "Professional LLM, Master of Laws in Canadian Common Law"
            },
            {
                "id": 1522,
                "itemName": "LLM, Research Stream"
            },
            {
                "id": 1523,
                "itemName": "MAcc, Master of Accounting"
            },
            {
                "id": 1524,
                "itemName": "MBA (India), Master of Business Administration"
            },
            {
                "id": 1525,
                "itemName": "MBAN, Master of Business Analytics"
            },
            {
                "id": 1526,
                "itemName": "MA in Anthropology - Social "
            },
            {
                "id": 1527,
                "itemName": "MCI in Conference Interpreting"
            },
            {
                "id": 1528,
                "itemName": "MPIA in Public & International Affairs"
            },
            {
                "id": 1529,
                "itemName": "MSW, Master of Social Work"
            },
            {
                "id": 1530,
                "itemName": "MSc in Mathematics & Statistics (Applied & Industrial)"
            },
            {
                "id": 1531,
                "itemName": "Professional LLM, General Master of Laws"
            },
            {
                "id": 1532,
                "itemName": "Professional LLM, Master of Laws in Tax Law"
            },
            {
                "id": 1533,
                "itemName": "MBA, Master of Business Administration"
            },
            {
                "id": 1534,
                "itemName": "Maîtrise en traductologie"
            },
            {
                "id": 1535,
                "itemName": "MLCE, Master of Leadership and Community Engagement"
            },
            {
                "id": 1536,
                "itemName": "MREI, Master of Real Estate and Infrastructure"
            },
            {
                "id": 1537,
                "itemName": "MAPI maîtrise bilingue en affaires publiques et internationales"
            },
            {
                "id": 1538,
                "itemName": "MMgt in Management"
            },
            {
                "id": 1539,
                "itemName": "MSc in Kinesiology & Health Science"
            },
            {
                "id": 1540,
                "itemName": "MScN, Primary Health Care Nurse Practitioner (PHCNP) - Nurse Practitioner (PHCNP) MScN"
            },
            {
                "id": 1541,
                "itemName": "MScN, Primary Health Care Nurse Practitioner (PHCNP) from RN"
            },
            {
                "id": 1542,
                "itemName": "MScN in Alternate Admission from RN - Nurse Practitioner (PHCNP)"
            },
            {
                "id": 1543,
                "itemName": "MSc in pathology and laboratory medicine"
            },
            {
                "id": 1544,
                "itemName": "MSc in food science"
            },
            {
                "id": 1545,
                "itemName": "MSc in electrical engineering"
            },
            {
                "id": 1546,
                "itemName": "MSc in veterinary small animal clinical sciences"
            },
            {
                "id": 1547,
                "itemName": "MSc in soil science"
            },
            {
                "id": 1548,
                "itemName": "MSc in biochemistry"
            },
            {
                "id": 1549,
                "itemName": "MSc in mechanical engineering"
            },
            {
                "id": 1550,
                "itemName": "Master of Mathematics MMath"
            },
            {
                "id": 1551,
                "itemName": "MSc in agricultural extension"
            },
            {
                "id": 1552,
                "itemName": "MA in political studies"
            },
            {
                "id": 1553,
                "itemName": "MSc in anatomy and cell biology"
            },
            {
                "id": 1554,
                "itemName": "MSc in surgery"
            },
            {
                "id": 1555,
                "itemName": "Master of Agriculture MAgr in applied microbiology"
            },
            {
                "id": 1556,
                "itemName": "MSc in animal and poultry science"
            },
            {
                "id": 1557,
                "itemName": "Master of Business Administration MBA"
            },
            {
                "id": 1558,
                "itemName": "MSc in biology"
            },
            {
                "id": 1559,
                "itemName": "Master of Fine Arts MFA in studio arts"
            },
            {
                "id": 1560,
                "itemName": "Master of Agriculture MAgr in agricultural economics"
            },
            {
                "id": 1561,
                "itemName": "MSc in agricultural economics"
            },
            {
                "id": 1562,
                "itemName": "MSc in accounting"
            },
            {
                "id": 1563,
                "itemName": "Master of Education MEd in educational psychology and special education"
            },
            {
                "id": 1564,
                "itemName": "Master of Education MEd in educational foundations"
            },
            {
                "id": 1565,
                "itemName": "Master of Nursing MN"
            },
            {
                "id": 1566,
                "itemName": "Master of Veterinary Science MVetSc in large animal clinical sciences"
            },
            {
                "id": 1567,
                "itemName": "Master of Veterinary Science in veterinary anesthesiology, radiology, and surgery"
            },
            {
                "id": 1568,
                "itemName": "MEng in electrical engineering"
            },
            {
                "id": 1569,
                "itemName": "MEng in mechanical engineering"
            },
            {
                "id": 1570,
                "itemName": "MSc in nutrition"
            },
            {
                "id": 1571,
                "itemName": "MSc in biological engineering"
            },
            {
                "id": 1572,
                "itemName": "MSc in mathematics and statistics"
            },
            {
                "id": 1573,
                "itemName": "Master of Education MEd in continuing education"
            },
            {
                "id": 1574,
                "itemName": "MSc in large animal clinical sciences"
            },
            {
                "id": 1575,
                "itemName": "MSc in geological sciences"
            },
            {
                "id": 1576,
                "itemName": "Master of Education MEd in educational administration"
            },
            {
                "id": 1577,
                "itemName": "MA in psychology"
            },
            {
                "id": 1578,
                "itemName": "MSc in physics and engineering physics"
            },
            {
                "id": 1579,
                "itemName": "MSc in toxicology"
            },
            {
                "id": 1580,
                "itemName": "MSc in pharmacology"
            },
            {
                "id": 1581,
                "itemName": "MSc in physiology"
            },
            {
                "id": 1582,
                "itemName": "MEng in civil and geological engineering"
            },
            {
                "id": 1583,
                "itemName": "Master of Agriculture MAgr in food science"
            },
            {
                "id": 1584,
                "itemName": "MSc in chemical engineering"
            },
            {
                "id": 1585,
                "itemName": "MSc in civil and geological engineering"
            },
            {
                "id": 1586,
                "itemName": "Master of Education MEd in measurement and evaluation"
            },
            {
                "id": 1587,
                "itemName": "Master of Education MEd in school and counseling psychology"
            },
            {
                "id": 1588,
                "itemName": "MSc in veterinary pathology"
            },
            {
                "id": 1589,
                "itemName": "Master of Veterinary Science MVetSc in veterinary pathology"
            },
            {
                "id": 1590,
                "itemName": "Master of Veterinary Science MVetSc in veterinary small animal clinical sciences"
            },
            {
                "id": 1591,
                "itemName": "Master of Education MEd in curriculum studies"
            },
            {
                "id": 1592,
                "itemName": "Master of Education MEd in Indian and Northern education"
            },
            {
                "id": 1593,
                "itemName": "Master of Music MMus"
            },
            {
                "id": 1594,
                "itemName": "MSc in veterinary microbiology"
            },
            {
                "id": 1595,
                "itemName": "MSc in environmental engineering"
            },
            {
                "id": 1596,
                "itemName": "Master of Public Administration MPA"
            },
            {
                "id": 1597,
                "itemName": "MSc in veterinary biomedical sciences"
            },
            {
                "id": 1598,
                "itemName": "Master of Agriculture MAgr in plant science"
            },
            {
                "id": 1599,
                "itemName": "MSc in plant science"
            },
            {
                "id": 1600,
                "itemName": "Master of Professional Accounting MPAcc"
            },
            {
                "id": 1601,
                "itemName": "Master of Laws LLM"
            },
            {
                "id": 1602,
                "itemName": "Master of Physical Therapy MPT"
            },
            {
                "id": 1603,
                "itemName": "MSc in pharmacy"
            },
            {
                "id": 1604,
                "itemName": "MSc in finance and management science"
            },
            {
                "id": 1605,
                "itemName": "MSc in microbiology and immunology"
            },
            {
                "id": 1606,
                "itemName": "MIT Master of International Trade"
            },
            {
                "id": 1607,
                "itemName": "MPT Master of Physical Therapy"
            },
            {
                "id": 1608,
                "itemName": "MPH Master of Public Health"
            },
            {
                "id": 1609,
                "itemName": "Master of Sustainable Environmental Management MSEM"
            },
            {
                "id": 1610,
                "itemName": "Master of Music in Music Education MMus [MusEd]"
            },
            {
                "id": 1611,
                "itemName": "MA in music"
            },
            {
                "id": 1612,
                "itemName": "MA in Slavic studies"
            },
            {
                "id": 1613,
                "itemName": "MA in vaccinology and immunotherapeutics"
            },
            {
                "id": 1614,
                "itemName": "MEng in biological engineering"
            },
            {
                "id": 1615,
                "itemName": "MEng in chemical engineering"
            },
            {
                "id": 1616,
                "itemName": "MSc in biological psychiatry"
            },
            {
                "id": 1617,
                "itemName": "MSc in biostatistics"
            },
            {
                "id": 1618,
                "itemName": "MSc in community and population health science"
            },
            {
                "id": 1619,
                "itemName": "MSc in health sciences"
            },
            {
                "id": 1620,
                "itemName": "MSc in rehabilitation medicine"
            },
            {
                "id": 1621,
                "itemName": "MSc in vaccinology and immunotherapeutics"
            },
            {
                "id": 1622,
                "itemName": "Master of Environment and Sustainability MES"
            },
            {
                "id": 1623,
                "itemName": "Master of Veterinary Science MVetSc in veterinary microbiology"
            },
            {
                "id": 1624,
                "itemName": "Master of Public Policy MPP"
            },
            {
                "id": 1625,
                "itemName": "MA in religion and culture"
            },
            {
                "id": 1626,
                "itemName": "Master of Northern Governance and Development MNGD"
            },
            {
                "id": 1627,
                "itemName": "Master of Education MEd in educational technology and design"
            },
            {
                "id": 1628,
                "itemName": "MA in indigenous studies"
            },
            {
                "id": 1629,
                "itemName": "MSc in marketing"
            },
            {
                "id": 1630,
                "itemName": "MA in applied linguistics"
            },
            {
                "id": 1631,
                "itemName": "MA in teaching English to speakers of other languages"
            },
            {
                "id": 1632,
                "itemName": "Master of Education MEd in leadership in post-secondary education"
            },
            {
                "id": 1633,
                "itemName": "Master of Governance and Entrepreneurship in Northern and Indigenous Areas MGENIA"
            },
            {
                "id": 1634,
                "itemName": "Master of Water Security MWS"
            },
            {
                "id": 1635,
                "itemName": "Combined Master of Business Administration MBA and Juris Doctor JD"
            },
            {
                "id": 1636,
                "itemName": "Combined Master of Business Administration MBA and Doctor of Pharmacy Pharm.D."
            },
            {
                "id": 1637,
                "itemName": "Master of Education MEd in Health Professions Education"
            },
            {
                "id": 1638,
                "itemName": "MSc in field epidemiology"
            },
            {
                "id": 1639,
                "itemName": "MA in International Public Management and Administration "
            },
            {
                "id": 1640,
                "itemName": "Master of Philosophy in German language and literature"
            },
            {
                "id": 1641,
                "itemName": "Master of Philosophy in humanities"
            },
            {
                "id": 1642,
                "itemName": "MSc in aquaculture"
            },
            {
                "id": 1643,
                "itemName": "MSc in cognitive and behavioural ecology"
            },
            {
                "id": 1644,
                "itemName": "MSc in mathematics"
            },
            {
                "id": 1645,
                "itemName": "MEd (curriculum, teaching and learning studies)"
            },
            {
                "id": 1646,
                "itemName": "MEd (post-secondary studies)"
            },
            {
                "id": 1647,
                "itemName": "MEd (educational leadership studies)"
            },
            {
                "id": 1648,
                "itemName": "Master of Marine Studies (fisheries resource management)"
            },
            {
                "id": 1649,
                "itemName": "Master of Physical Education (administration, curriculum and supervision)"
            },
            {
                "id": 1650,
                "itemName": "Master of Applied Statistics"
            },
            {
                "id": 1651,
                "itemName": "MA in classics"
            },
            {
                "id": 1652,
                "itemName": "MSc in environmental science"
            },
            {
                "id": 1653,
                "itemName": "Master of Engineering (ocean and naval architectural engineering)"
            },
            {
                "id": 1654,
                "itemName": "Master of Engineering (civil engineering)"
            },
            {
                "id": 1655,
                "itemName": "Master of Engineering (mechanical engineering)"
            },
            {
                "id": 1656,
                "itemName": "MA in French studies"
            },
            {
                "id": 1657,
                "itemName": "MA in English language and literature"
            },
            {
                "id": 1658,
                "itemName": "Master of Social Work"
            },
            {
                "id": 1659,
                "itemName": "Master of Science in Pharmacy"
            },
            {
                "id": 1660,
                "itemName": "MSc in physics"
            },
            {
                "id": 1661,
                "itemName": "MSc in earth sciences (geology)"
            },
            {
                "id": 1662,
                "itemName": "MA in folklore"
            },
            {
                "id": 1663,
                "itemName": "MEd (counselling psychology)"
            },
            {
                "id": 1664,
                "itemName": "Master of Gender Studies"
            },
            {
                "id": 1665,
                "itemName": "MA in German language and literature"
            },
            {
                "id": 1666,
                "itemName": "MEd (educational technology)"
            },
            {
                "id": 1667,
                "itemName": "MSc in scientific computing"
            },
            {
                "id": 1668,
                "itemName": "MSc in marine biology"
            },
            {
                "id": 1669,
                "itemName": "MSc in medicine"
            },
            {
                "id": 1670,
                "itemName": "Master of Applied Psychological Science (co-operative)"
            },
            {
                "id": 1671,
                "itemName": "Master of Employment Relations"
            },
            {
                "id": 1672,
                "itemName": "Master of Music (conducting)"
            },
            {
                "id": 1673,
                "itemName": "Master of Music (performance)"
            },
            {
                "id": 1674,
                "itemName": "Master of Music (performance/pedagogy)"
            },
            {
                "id": 1675,
                "itemName": "MA in ethnomusicology"
            },
            {
                "id": 1676,
                "itemName": "MSc in earth sciences (geophysics)"
            },
            {
                "id": 1677,
                "itemName": "MSc in experimental psychology"
            },
            {
                "id": 1678,
                "itemName": "MSc in instrumental analysis"
            },
            {
                "id": 1679,
                "itemName": "MSc in physical oceanography"
            },
            {
                "id": 1680,
                "itemName": "MSc in statistics"
            },
            {
                "id": 1681,
                "itemName": "Master of Applied Science in computer engineering"
            },
            {
                "id": 1682,
                "itemName": "Master of Engineering (electrical engineering)"
            },
            {
                "id": 1683,
                "itemName": "MSc (Kin) exercise and work physiology"
            },
            {
                "id": 1684,
                "itemName": "MSc (Kin) biomechanics/ergonomics"
            },
            {
                "id": 1685,
                "itemName": "MSc (Kin) psychology of sport, exercise and recreation"
            },
            {
                "id": 1686,
                "itemName": "Master of Applied Science in environmental systems engineering and management"
            },
            {
                "id": 1687,
                "itemName": "Master of Engineering (oil and gas engineering)"
            },
            {
                "id": 1688,
                "itemName": "Master of Engineering (computer engineering)"
            },
            {
                "id": 1689,
                "itemName": "Master of Applied Science in oil and gas engineering"
            },
            {
                "id": 1690,
                "itemName": "Master of Environmental Science"
            },
            {
                "id": 1691,
                "itemName": "Master of Public Health"
            },
            {
                "id": 1692,
                "itemName": "Master of Arts and Education (education and Francophone literatures and cultures)"
            },
            {
                "id": 1693,
                "itemName": "Master of Technology Management (aquaculture technology)"
            },
            {
                "id": 1694,
                "itemName": "MA in environmental policy"
            },
            {
                "id": 1695,
                "itemName": "Master of Maritime Management"
            },
            {
                "id": 1696,
                "itemName": "MSc (Kin) socio-cultural studies of physical activity and health"
            },
            {
                "id": 1697,
                "itemName": "Master of Health Ethics"
            },
            {
                "id": 1698,
                "itemName": "MSc in management"
            },
            {
                "id": 1699,
                "itemName": "MSc in boreal ecosystems and agricultural sciences"
            },
            {
                "id": 1700,
                "itemName": "Master of Marine Studies (marine spatial planning & management)"
            },
            {
                "id": 1701,
                "itemName": "Master of Technology Management (engineering/applied science technology)"
            },
            {
                "id": 1702,
                "itemName": "MSc in fisheries science"
            },
            {
                "id": 1703,
                "itemName": "Master of Applied Science in energy systems engineering"
            },
            {
                "id": 1704,
                "itemName": "Master of Applied Science in safety risk engineering"
            },
            {
                "id": 1705,
                "itemName": "Master of Business Administration in social enterprise and entrepreneurship"
            },
            {
                "id": 1706,
                "itemName": "Master of Engineering (process engineering)"
            },
            {
                "id": 1707,
                "itemName": "Master of Fine Arts in Studio Arts"
            },
            {
                "id": 1708,
                "itemName": "Master of Occupational Health and Safety"
            },
            {
                "id": 1709,
                "itemName": "Master of Applied Ocean Technology (Ocean Mapping)"
            },
            {
                "id": 1710,
                "itemName": "MA in Applied Linguistics and Discourse Studies"
            },
            {
                "id": 1711,
                "itemName": "MA in Canadian Studies"
            },
            {
                "id": 1712,
                "itemName": "Master of Engineering in Electrical and Computer Engineering"
            },
            {
                "id": 1713,
                "itemName": "MA in International Affairs/Juris Doctor (Law)"
            },
            {
                "id": 1714,
                "itemName": "MA in International Affairs"
            },
            {
                "id": 1715,
                "itemName": "MA in European, Russian and Eurasian Studies"
            },
            {
                "id": 1716,
                "itemName": "MA in Political Economy"
            },
            {
                "id": 1717,
                "itemName": "Master of Engineering in Mechanical Engineering"
            },
            {
                "id": 1718,
                "itemName": "Master of Engineering in Civil Engineering"
            },
            {
                "id": 1719,
                "itemName": "MA in Legal Studies"
            },
            {
                "id": 1720,
                "itemName": "Master of Applied Science in Civil Engineering"
            },
            {
                "id": 1721,
                "itemName": "Master of Applied Science in Electrical and Computer Engineering"
            },
            {
                "id": 1722,
                "itemName": "Master of Applied Science in Environmental Engineering"
            },
            {
                "id": 1723,
                "itemName": "Master of Applied Science in Mechanical Engineering"
            },
            {
                "id": 1724,
                "itemName": "Master of Engineering in Environmental Engineering"
            },
            {
                "id": 1725,
                "itemName": "Master of Engineering in Technology Innovation Management"
            },
            {
                "id": 1726,
                "itemName": "Master of Applied Science in Aerospace Engineering"
            },
            {
                "id": 1727,
                "itemName": "Master of Applied Science in Biomedical Engineering"
            },
            {
                "id": 1728,
                "itemName": "Master of Applied Science in Technology Innovation Management"
            },
            {
                "id": 1729,
                "itemName": "MA in Public History"
            },
            {
                "id": 1730,
                "itemName": "MA in Music and Culture"
            },
            {
                "id": 1731,
                "itemName": "Master of Design"
            },
            {
                "id": 1732,
                "itemName": "Master of Infrastructure Protection and International Security (MIPIS)"
            },
            {
                "id": 1733,
                "itemName": "MA in Human-Computer Interaction"
            },
            {
                "id": 1734,
                "itemName": "MA in Religion and Public Life"
            },
            {
                "id": 1735,
                "itemName": "MA in Sustainable Energy"
            },
            {
                "id": 1736,
                "itemName": "MA in Women's and Gender Studies"
            },
            {
                "id": 1737,
                "itemName": "Master of Political Management"
            },
            {
                "id": 1738,
                "itemName": "M.Cog.Sci. in Cognitive Science"
            },
            {
                "id": 1739,
                "itemName": "Master of Applied Science in Human-Computer Interaction"
            },
            {
                "id": 1740,
                "itemName": "Master of Applied Science in Sustainable Energy"
            },
            {
                "id": 1741,
                "itemName": "Master of Computer Science in Human-Computer Interaction"
            },
            {
                "id": 1742,
                "itemName": "Master of Engineering in Sustainable Energy Engineering and Policy"
            },
            {
                "id": 1743,
                "itemName": "MSc in Health, Science, Technology and Policy"
            },
            {
                "id": 1744,
                "itemName": "Master of Architectural Studies"
            },
            {
                "id": 1745,
                "itemName": "MA collaborative specialization in African Studies"
            },
            {
                "id": 1746,
                "itemName": "MA collaborative specialization in Digital Humanities"
            },
            {
                "id": 1747,
                "itemName": "Master of Engineering in Aerospace Engineering"
            },
            {
                "id": 1748,
                "itemName": "Master of Philanthropy and Nonprofit Leadership (MPNL)"
            },
            {
                "id": 1749,
                "itemName": "MA, MEng, MASc, MBA (collaborative only) in Data Science"
            },
            {
                "id": 1750,
                "itemName": "PhD in Ethics and Public Affairs"
            },
            {
                "id": 1751,
                "itemName": "Master of Engineering in Infrastructure Protection and International Security"
            },
            {
                "id": 1752,
                "itemName": "Master of Information Technology"
            },
            {
                "id": 1753,
                "itemName": "MA in Northern Studies"
            },
            {
                "id": 1754,
                "itemName": "MSc Biochemistry (collaborative specialization)"
            },
            {
                "id": 1755,
                "itemName": "MSc in Northern Studies"
            },
            {
                "id": 1756,
                "itemName": "MA collaborative specialization in Latin American and Caribbean Studies"
            },
            {
                "id": 1757,
                "itemName": "MA in Migration and Diaspora Studies"
            },
            {
                "id": 1758,
                "itemName": "MA in English and Film Studies"
            },
            {
                "id": 1759,
                "itemName": "Master of Applied Politics (MAP)"
            },
            {
                "id": 1760,
                "itemName": "MA in Business Economics"
            },
            {
                "id": 1761,
                "itemName": "MA in Religion, Culture and Global Justice"
            },
            {
                "id": 1762,
                "itemName": "Master of Environmental Studies in Geography"
            },
            {
                "id": 1763,
                "itemName": "Master of Music Therapy"
            },
            {
                "id": 1764,
                "itemName": "MA in Cultural Analysis and Social Theory"
            },
            {
                "id": 1765,
                "itemName": "Master of Finance"
            },
            {
                "id": 1766,
                "itemName": "Master of International Public Policy"
            },
            {
                "id": 1767,
                "itemName": "MA in Social Justice and Community Engagement"
            },
            {
                "id": 1768,
                "itemName": "Master of Arts in Community Music"
            },
            {
                "id": 1769,
                "itemName": "MA in Theology and Master of Divinity"
            },
            {
                "id": 1770,
                "itemName": "Master of Applied Computing (MAC)"
            },
            {
                "id": 1771,
                "itemName": "Master of Education"
            },
            {
                "id": 1772,
                "itemName": "Master of Public Safety"
            },
            {
                "id": 1773,
                "itemName": "MSc in Management Analytics"
            },
            {
                "id": 1774,
                "itemName": "Maîtrise en sciences de l'atmosphère (M.Sc.)"
            },
            {
                "id": 1775,
                "itemName": "Maîtrise en génie logiciel (M.Ing.)"
            },
            {
                "id": 1776,
                "itemName": "Maîtrise en chimie (M.Sc.)"
            },
            {
                "id": 1777,
                "itemName": "Maîtrise en mathématiques (M.Sc.)"
            },
            {
                "id": 1778,
                "itemName": "Maîtrise en sciences de la Terre (M.Sc.)"
            },
            {
                "id": 1779,
                "itemName": "Maîtrise en sciences de l'environnement (M.Sc.)"
            },
            {
                "id": 1780,
                "itemName": "Maîtrise en informatique (M.Sc.)"
            },
            {
                "id": 1781,
                "itemName": "Maîtrise en théâtre (M.A.)"
            },
            {
                "id": 1782,
                "itemName": "Maîtrise en développement du tourisme (M.Sc.)"
            },
            {
                "id": 1783,
                "itemName": "Maîtrise en études urbaines (M.Sc.)"
            },
            {
                "id": 1784,
                "itemName": "Maîtrise en administration des affaires - MBA pour cadres"
            },
            {
                "id": 1785,
                "itemName": "Maîtrise en comptabilité, contrôle et audit (M.Sc.)"
            },
            {
                "id": 1786,
                "itemName": "Maîtrise en économique (M.Sc.)"
            },
            {
                "id": 1787,
                "itemName": "Maîtrise en philosophie (M.A.)"
            },
            {
                "id": 1788,
                "itemName": "Maîtrise en histoire (M.A.)"
            },
            {
                "id": 1789,
                "itemName": "Maîtrise en sexologie (M.A.)"
            },
            {
                "id": 1790,
                "itemName": "Maîtrise en géographie (M.Sc.)"
            },
            {
                "id": 1791,
                "itemName": "Maîtrise en linguistique (M.A.)"
            },
            {
                "id": 1792,
                "itemName": "Maîtrise en travail social (M.A.)"
            },
            {
                "id": 1793,
                "itemName": "Maîtrise ès sciences, finance appliquée (M.Sc.)"
            },
            {
                "id": 1794,
                "itemName": "Maîtrise en communication (M.A.)"
            },
            {
                "id": 1795,
                "itemName": "Maîtrise en éducation (M.A. ou M.Ed.)"
            },
            {
                "id": 1796,
                "itemName": "Maîtrise en arts visuels et médiatiques (M.A.)"
            },
            {
                "id": 1797,
                "itemName": "Maîtrise en muséologie (M.A.)"
            },
            {
                "id": 1798,
                "itemName": "Maîtrise en danse (M.A.)"
            },
            {
                "id": 1799,
                "itemName": "Maîtrise en biologie (M.Sc.)"
            },
            {
                "id": 1800,
                "itemName": "Maîtrise en sciences de la gestion (M.Sc.)"
            },
            {
                "id": 1801,
                "itemName": "Maîtrise en droit (LL.M.)"
            },
            {
                "id": 1802,
                "itemName": "Maîtrise en gestion de projet (M.Sc.)"
            },
            {
                "id": 1803,
                "itemName": "Maîtrise en science, technologie et société (M.A.)"
            },
            {
                "id": 1804,
                "itemName": "Maîtrise en études littéraires (M.A.)"
            },
            {
                "id": 1805,
                "itemName": "Maîtrise en orthopédagogie (M.Ed.)"
            },
            {
                "id": 1806,
                "itemName": "Maîtrise en administration des affaires (MBA) en conseil en management"
            },
            {
                "id": 1807,
                "itemName": "Maîtrise en counseling de carrière (M.A. ou M.Ed.)"
            },
            {
                "id": 1808,
                "itemName": "Maîtrise en didactique des langues (M.A.)"
            },
            {
                "id": 1809,
                "itemName": "Maîtrise en enseignement (M.A. ou M.Ed.)"
            },
            {
                "id": 1810,
                "itemName": "Maîtrise en biochimie (M.Sc.)"
            },
            {
                "id": 1811,
                "itemName": "Maîtrise en enseignement des arts (M.A.)"
            },
            {
                "id": 1812,
                "itemName": "Maîtrise en histoire de l'art (M.A.)"
            },
            {
                "id": 1813,
                "itemName": "Maîtrise en administration des affaires (MBA) en sciences comptables"
            },
            {
                "id": 1814,
                "itemName": "Maîtrise en design de l'environnement (M.A.)"
            },
            {
                "id": 1815,
                "itemName": "Maîtrise en gestion durable des écosystèmes forestiers (M.Sc.A.)"
            },
            {
                "id": 1816,
                "itemName": "Maîtrise en informatique pour l'intelligence et la gestion des données (M.Sc.A.)"
            },
            {
                "id": 1817,
                "itemName": "Maîtrise en sciences de l'activité physique (M.Sc.)"
            },
            {
                "id": 1818,
                "itemName": "MA in Religion"
            },
            {
                "id": 1819,
                "itemName": "Master of City Planning (MCP)"
            },
            {
                "id": 1820,
                "itemName": "MEd in Curriculum, Teaching and Learning"
            },
            {
                "id": 1821,
                "itemName": "MSc in Medical Microbiology and Infectious Disease"
            },
            {
                "id": 1822,
                "itemName": "MSc in Immunology"
            },
            {
                "id": 1823,
                "itemName": "MSc in Community Health Sciences"
            },
            {
                "id": 1824,
                "itemName": "MSc in Human Anatomy and Cell Science"
            },
            {
                "id": 1825,
                "itemName": "MEd in Educational Administration, Foundations and Psychology"
            },
            {
                "id": 1826,
                "itemName": "Master of Public Administration (M.P.Admin.)"
            },
            {
                "id": 1827,
                "itemName": "MSc in Agribusiness and Agricultural Economics"
            },
            {
                "id": 1828,
                "itemName": "MSc in Entomology"
            },
            {
                "id": 1829,
                "itemName": "MSc in Physiology and Pathophysiology"
            },
            {
                "id": 1830,
                "itemName": "MSc in Pharmacology and Therapeutics"
            },
            {
                "id": 1831,
                "itemName": "MSc in Kinesiology and Recreation"
            },
            {
                "id": 1832,
                "itemName": "MSc in Biochemistry and Medical Genetics"
            },
            {
                "id": 1833,
                "itemName": "MSc in Geological Sciences"
            },
            {
                "id": 1834,
                "itemName": "Master of Landscape Architecture (M.L.Arch.)"
            },
            {
                "id": 1835,
                "itemName": "MSc in Soil Science"
            },
            {
                "id": 1836,
                "itemName": "MSc in Biosystems Engineering"
            },
            {
                "id": 1837,
                "itemName": "MEng in Biosystems Engineering"
            },
            {
                "id": 1838,
                "itemName": "Master of Natural Resources Management (MNRM)"
            },
            {
                "id": 1839,
                "itemName": "Master of Dentistry (Preventive Dental Sciences)"
            },
            {
                "id": 1840,
                "itemName": "MSc in Human Nutritional Sciences"
            },
            {
                "id": 1841,
                "itemName": "MA in Disability Studies"
            },
            {
                "id": 1842,
                "itemName": "MSc in Disability Studies"
            },
            {
                "id": 1843,
                "itemName": "Master of Environmental Science (MEnv)"
            },
            {
                "id": 1844,
                "itemName": "Master of Occupational Therapy (MOT)"
            },
            {
                "id": 1845,
                "itemName": "MA in School Psychology"
            },
            {
                "id": 1846,
                "itemName": "MSc in Environment and Geography"
            },
            {
                "id": 1847,
                "itemName": "MSc in Management (interdisciplinary)"
            },
            {
                "id": 1848,
                "itemName": "MA in Individual Interdisciplinary Studies"
            },
            {
                "id": 1849,
                "itemName": "MSc in Preventable Dental Sciences (Orthodontics Speciality)"
            },
            {
                "id": 1850,
                "itemName": "Master of Fine Arts (MFA)"
            },
            {
                "id": 1851,
                "itemName": "MA in Kinesiology and Recreation"
            },
            {
                "id": 1852,
                "itemName": "MSc in Individual Interdisciplinary Studies"
            },
            {
                "id": 1853,
                "itemName": "Master of Dentistry (Dental Diag and Surg Sciences)"
            },
            {
                "id": 1854,
                "itemName": "MA Joint Masters in Peace and Conflict Studies"
            },
            {
                "id": 1855,
                "itemName": "Master of Physical Therapy (MPT)"
            },
            {
                "id": 1856,
                "itemName": "Master of Physician Assistant Studies (MPAS)"
            },
            {
                "id": 1857,
                "itemName": "MA in Slavic Studies"
            },
            {
                "id": 1858,
                "itemName": "MSc in Rehabilitation Sciences"
            },
            {
                "id": 1859,
                "itemName": "Master of Interior Design (MID)"
            },
            {
                "id": 1860,
                "itemName": "Master of Finance (MFin)"
            },
            {
                "id": 1861,
                "itemName": "MSc in Genetic Counseling"
            },
            {
                "id": 1862,
                "itemName": "MSc in Prosthodontics"
            },
            {
                "id": 1863,
                "itemName": "Master of Human Rights"
            },
            {
                "id": 1864,
                "itemName": "MA in Celtic studies"
            },
            {
                "id": 1865,
                "itemName": "Master of Adult Education"
            },
            {
                "id": 1866,
                "itemName": "MASc in Computer Science"
            },
            {
                "id": 1867,
                "itemName": "MEd in Leadership"
            },
            {
                "id": 1868,
                "itemName": "MEd in Inclusive Education"
            },
            {
                "id": 1869,
                "itemName": "MEd in Counselling"
            },
            {
                "id": 1870,
                "itemName": "Master of Community Development"
            },
            {
                "id": 1871,
                "itemName": "MSc in Applied Geomatics"
            },
            {
                "id": 1872,
                "itemName": "MA in Social and Political Thought"
            },
            {
                "id": 1873,
                "itemName": "Master of Arts in Theology"
            },
            {
                "id": 1874,
                "itemName": "Master of Science in Forestry"
            },
            {
                "id": 1875,
                "itemName": "Master of Science in Engineering in Chemical Engineering"
            },
            {
                "id": 1876,
                "itemName": "Master of Science in Engineering in Civil Engineering"
            },
            {
                "id": 1877,
                "itemName": "Master of Science in Engineering in Mechanical Engineering"
            },
            {
                "id": 1878,
                "itemName": "Master of Engineering in Geodesy and Geomatics Engineering"
            },
            {
                "id": 1879,
                "itemName": "Master of Engineering in Electrical Engineering"
            },
            {
                "id": 1880,
                "itemName": "Master of Engineering in Chemical Engineering"
            },
            {
                "id": 1881,
                "itemName": "MSc in Mathematics/Statistics"
            },
            {
                "id": 1882,
                "itemName": "Master of Science in Forest Engineering"
            },
            {
                "id": 1883,
                "itemName": "Master of Forest Engineering"
            },
            {
                "id": 1884,
                "itemName": "MEd in Critical Studies in Education"
            },
            {
                "id": 1885,
                "itemName": "MEd in Curriculum & Instruction Studies"
            },
            {
                "id": 1886,
                "itemName": "MEd in Adult Education"
            },
            {
                "id": 1887,
                "itemName": "MSc in Exercise and Sport Science"
            },
            {
                "id": 1888,
                "itemName": "Master of Science in Biology"
            },
            {
                "id": 1889,
                "itemName": "MA in Classics and Ancient History"
            },
            {
                "id": 1890,
                "itemName": "Master of Science in Engineering in Geodesy and Geomatics Engineering"
            },
            {
                "id": 1891,
                "itemName": "MA in Experimental Psychology"
            },
            {
                "id": 1892,
                "itemName": "Master of Science in Engineering in Electrical Engineering"
            },
            {
                "id": 1893,
                "itemName": "MEd in Instructional Design"
            },
            {
                "id": 1894,
                "itemName": "MEd in Educational Administration and Leadership"
            },
            {
                "id": 1895,
                "itemName": "MEd in Exceptional Learners"
            },
            {
                "id": 1896,
                "itemName": "MA/PhD, PhD in Psychology"
            },
            {
                "id": 1897,
                "itemName": "Masters in Applied Health Services Research"
            },
            {
                "id": 1898,
                "itemName": "Masters in Interdisciplinary Studies"
            },
            {
                "id": 1899,
                "itemName": "MBA in Business at UNBSJ"
            },
            {
                "id": 1900,
                "itemName": "MBA in Sport and Recreation Management"
            },
            {
                "id": 1901,
                "itemName": "MBA/JD (Joint Law Program)"
            },
            {
                "id": 1902,
                "itemName": "Master of Environmental Management"
            },
            {
                "id": 1903,
                "itemName": "MA in Sport and Recreation Studies"
            },
            {
                "id": 1904,
                "itemName": "MBA - Concentration in Entrepreneurship"
            },
            {
                "id": 1905,
                "itemName": "MSc in Sport Science"
            },
            {
                "id": 1906,
                "itemName": "Master of Technology Management & Entrepreneurship"
            },
            {
                "id": 1907,
                "itemName": "Combined MBA/JD Program"
            },
            {
                "id": 1908,
                "itemName": "Master in Quantitative Investment Management (MQIM)"
            },
            {
                "id": 1909,
                "itemName": "Master of Information Technology Security - (MITS)"
            },
            {
                "id": 1910,
                "itemName": "Master of Arts (MA) - Education"
            },
            {
                "id": 1911,
                "itemName": "Master of Applied Science (MASc) - Mechanical Engineering"
            },
            {
                "id": 1912,
                "itemName": "Master of Engineering (MEng) - Mechanical Engineering"
            },
            {
                "id": 1913,
                "itemName": "Master of Science (MSc) - Modelling and Computational Science"
            },
            {
                "id": 1914,
                "itemName": "Master of Arts (MA) Criminology"
            },
            {
                "id": 1915,
                "itemName": "Master of Engineering (MEng) - Electrical  and Computer Engineering"
            },
            {
                "id": 1916,
                "itemName": "Master of Science (MSc) - Materials Science"
            },
            {
                "id": 1917,
                "itemName": "Master of Science (MSc) - Applied Bioscience"
            },
            {
                "id": 1918,
                "itemName": "Master of Applied Science (MASc) - Nuclear Engineering"
            },
            {
                "id": 1919,
                "itemName": "Master of Engineering (MEng) - Nuclear Engineering"
            },
            {
                "id": 1920,
                "itemName": "Master of Engineering (MEng) - Automotive Engineering"
            },
            {
                "id": 1921,
                "itemName": "Master of Applied Science (MASc) - Electrical and Computer Engineering"
            },
            {
                "id": 1922,
                "itemName": "Master of Health Sciences (MHSc)"
            },
            {
                "id": 1923,
                "itemName": "Master of Science (MSc) - Computer Science"
            },
            {
                "id": 1924,
                "itemName": "Master of Applied Science (MASc) - Automotive Engineering"
            },
            {
                "id": 1925,
                "itemName": "Master of Engineering Management (MEngM)"
            },
            {
                "id": 1926,
                "itemName": "University Network of Excellence in Nuclear Engineering (UNENE) Master of Engineering (MEng) - Nuclear Engineering"
            },
            {
                "id": 1927,
                "itemName": "Master of Science (MSc) - Forensic Psychology"
            },
            {
                "id": 1928,
                "itemName": "Master of Science in Nursing (MScN)"
            },
            {
                "id": 1929,
                "itemName": "MA in Applied Health Sciences"
            },
            {
                "id": 1930,
                "itemName": "Master of Accountancy (MAcc)"
            },
            {
                "id": 1931,
                "itemName": "MSc in Biotechnology"
            },
            {
                "id": 1932,
                "itemName": "MA in Child and Youth Studies"
            },
            {
                "id": 1933,
                "itemName": "MA in Social Justice and Equity Studies"
            },
            {
                "id": 1934,
                "itemName": "MSc in Applied Health Sciences"
            },
            {
                "id": 1935,
                "itemName": "MA in Popular Culture"
            },
            {
                "id": 1936,
                "itemName": "International Master of Accountancy"
            },
            {
                "id": 1937,
                "itemName": "Master of Business Administration (MBA) - International Student Program"
            },
            {
                "id": 1938,
                "itemName": "MA in Applied Linguistics (TESL)"
            },
            {
                "id": 1939,
                "itemName": "Master of Business Economics (MBE)"
            },
            {
                "id": 1940,
                "itemName": "MA in Studies in Comparative Literature and Arts"
            },
            {
                "id": 1941,
                "itemName": "Master of Education (MEd) (International Student Program)"
            },
            {
                "id": 1942,
                "itemName": "Master of Applied Disability Studies (MADS)"
            },
            {
                "id": 1943,
                "itemName": "MA in Applied Disability Studies"
            },
            {
                "id": 1944,
                "itemName": "MA in Canadian-American Studies Joint Program"
            },
            {
                "id": 1945,
                "itemName": "MA in Critical Sociology"
            },
            {
                "id": 1946,
                "itemName": "Master of Sustainability: Science and Society"
            },
            {
                "id": 1947,
                "itemName": "MA in Women and Gender Studies"
            },
            {
                "id": 1948,
                "itemName": "MA in Atlantic Canada Studies"
            },
            {
                "id": 1949,
                "itemName": "MSc in Applied Psychology (Industrial/Organizational Psychology)"
            },
            {
                "id": 1950,
                "itemName": "Master of Management - Cooperatives and Credit Unions"
            },
            {
                "id": 1951,
                "itemName": "MSc Applied Science"
            },
            {
                "id": 1952,
                "itemName": "MA in Theology and Religious Studies"
            },
            {
                "id": 1953,
                "itemName": "Master of Applied Economics"
            },
            {
                "id": 1954,
                "itemName": "Master of Technology Entrepreneurship and Innovation"
            },
            {
                "id": 1955,
                "itemName": "MSc in Computing and Data Analytics"
            },
            {
                "id": 1956,
                "itemName": "MBA (CPA Stream)"
            },
            {
                "id": 1957,
                "itemName": "Master of Applied Health Services Research"
            },
            {
                "id": 1958,
                "itemName": "MSc in Applied Psychology (Forensic Psychology)"
            },
            {
                "id": 1959,
                "itemName": "Master of Arts in Urban and Regional Studies"
            },
            {
                "id": 1960,
                "itemName": "Master of Arts in Anthropology"
            },
            {
                "id": 1961,
                "itemName": "Master of Science in Computer Science"
            },
            {
                "id": 1962,
                "itemName": "Master of Arts in History"
            },
            {
                "id": 1963,
                "itemName": "Master of Arts in Indigenous Studies"
            },
            {
                "id": 1964,
                "itemName": "Master of Arts in Kinesiology"
            },
            {
                "id": 1965,
                "itemName": "Master of Arts in Philosophy"
            },
            {
                "id": 1966,
                "itemName": "Master of Arts in Political Science"
            },
            {
                "id": 1967,
                "itemName": "Master of Science in Physics"
            },
            {
                "id": 1968,
                "itemName": "Master of Arts in Canadian Studies"
            },
            {
                "id": 1969,
                "itemName": "Master of Science in Biological Sciences"
            },
            {
                "id": 1970,
                "itemName": "Master of Science in Chemistry"
            },
            {
                "id": 1971,
                "itemName": "Master of Arts in Economics"
            },
            {
                "id": 1972,
                "itemName": "Master of Arts in English"
            },
            {
                "id": 1973,
                "itemName": "Master of Arts in Geography"
            },
            {
                "id": 1974,
                "itemName": "Master of Arts in Sociology"
            },
            {
                "id": 1975,
                "itemName": "Master of Science in Psychology"
            },
            {
                "id": 1976,
                "itemName": "Master of Arts in French"
            },
            {
                "id": 1977,
                "itemName": "Master of Arts in German"
            },
            {
                "id": 1978,
                "itemName": "Master of Education (General)"
            },
            {
                "id": 1979,
                "itemName": "Master of Science in Kinesiology"
            },
            {
                "id": 1980,
                "itemName": "Master of Science in Agricultural Biotechnology"
            },
            {
                "id": 1981,
                "itemName": "Master of Arts in French/German"
            },
            {
                "id": 1982,
                "itemName": "Master of Science in Mathematics"
            },
            {
                "id": 1983,
                "itemName": "Master of Arts in Psychology"
            },
            {
                "id": 1984,
                "itemName": "Master of Arts in Religious Studies"
            },
            {
                "id": 1985,
                "itemName": "Master of Science in Geography"
            },
            {
                "id": 1986,
                "itemName": "Master of Arts in Archaeology"
            },
            {
                "id": 1987,
                "itemName": "Master of Arts in Dramatic Arts"
            },
            {
                "id": 1988,
                "itemName": "Master of Arts in French/Spanish"
            },
            {
                "id": 1989,
                "itemName": "Master of Arts in Music"
            },
            {
                "id": 1990,
                "itemName": "Master of Science in Agricultural Studies"
            },
            {
                "id": 1991,
                "itemName": "Master of Science in Biochemistry"
            },
            {
                "id": 1992,
                "itemName": "Master of Science in Environmental Science"
            },
            {
                "id": 1993,
                "itemName": "Master of Science in Exercise Science"
            },
            {
                "id": 1994,
                "itemName": "Master of Science in Health Sciences"
            },
            {
                "id": 1995,
                "itemName": "Master of Science in Neuroscience"
            },
            {
                "id": 1996,
                "itemName": "Master of Science (Management) in Accounting"
            },
            {
                "id": 1997,
                "itemName": "Master of Education (Counselling Psychology)"
            },
            {
                "id": 1998,
                "itemName": "Master of Education (Educational Leadership)"
            },
            {
                "id": 1999,
                "itemName": "Master of Arts in Agricultural Studies"
            },
            {
                "id": 2000,
                "itemName": "Master of Arts in Individualized Multidisciplinary"
            },
            {
                "id": 2001,
                "itemName": "Master of Science in Nursing"
            },
            {
                "id": 2002,
                "itemName": "Master of Science in Individualized Multidisciplinary"
            },
            {
                "id": 2003,
                "itemName": "Master of Arts in Art"
            },
            {
                "id": 2004,
                "itemName": "Master of Arts in New Media"
            },
            {
                "id": 2005,
                "itemName": "Master of Science in Addictions Counselling"
            },
            {
                "id": 2006,
                "itemName": "Master of Science in Computer Science and Geographical Information Science"
            },
            {
                "id": 2007,
                "itemName": "Master of Counselling in Applied Psychology"
            },
            {
                "id": 2008,
                "itemName": "Master of Arts in Women and Gender Studies"
            },
            {
                "id": 2009,
                "itemName": "Master of Fine Arts in Art"
            },
            {
                "id": 2010,
                "itemName": "Master of Science in Archaeology"
            },
            {
                "id": 2011,
                "itemName": "Master of Arts in Education"
            },
            {
                "id": 2012,
                "itemName": "Master of Fine Arts in New Media"
            },
            {
                "id": 2013,
                "itemName": "Master of Fine Arts in Theatre and Dramatic Arts"
            },
            {
                "id": 2014,
                "itemName": "Master of Science (Management) in Finance"
            },
            {
                "id": 2015,
                "itemName": "Master of Science (Management) in Human Resource Management and Labour Relations"
            },
            {
                "id": 2016,
                "itemName": "Master of Science (Management) in Information Systems"
            },
            {
                "id": 2017,
                "itemName": "Master of Science (Management) in International Management"
            },
            {
                "id": 2018,
                "itemName": "Master of Science (Management) in Marketing"
            },
            {
                "id": 2019,
                "itemName": "Master of Science (Management) in Policy and Strategy"
            },
            {
                "id": 2020,
                "itemName": "Master of Arts in Cultural, Social, and Political Thought"
            },
            {
                "id": 2021,
                "itemName": "Master of Arts in Cultural Resource Management"
            },
            {
                "id": 2022,
                "itemName": "Master of Health Services Management"
            },
            {
                "id": 2023,
                "itemName": "Master of Science in Remote Sensing"
            },
            {
                "id": 2024,
                "itemName": "MASc in Environmental Systems Engineering"
            },
            {
                "id": 2025,
                "itemName": "MEd in Curriculum and Instruction"
            },
            {
                "id": 2026,
                "itemName": "MA in Clinical Psychology"
            },
            {
                "id": 2027,
                "itemName": "MA in French and Francophone Intercultural Studies"
            },
            {
                "id": 2028,
                "itemName": "MA in Indigenous Studies"
            },
            {
                "id": 2029,
                "itemName": "MA in Social Studies"
            },
            {
                "id": 2030,
                "itemName": "MEng in Petroleum Systems Engineering"
            },
            {
                "id": 2031,
                "itemName": "MASc in Industrial System Engineering"
            },
            {
                "id": 2032,
                "itemName": "MASc in Electronic Systems Engineering"
            },
            {
                "id": 2033,
                "itemName": "MA in Interdisciplinary Studies in Fine Arts"
            },
            {
                "id": 2034,
                "itemName": "Master of Human Resources Management"
            },
            {
                "id": 2035,
                "itemName": "Master of Adult Education and Community Engagement"
            },
            {
                "id": 2036,
                "itemName": "MEng in Electronic Systems Engineering"
            },
            {
                "id": 2037,
                "itemName": "MEng in Industrial Systems Engineering"
            },
            {
                "id": 2038,
                "itemName": "MEng in Environmental Systems Engineering"
            },
            {
                "id": 2039,
                "itemName": "MASc in Petroleum Systems Engineering"
            },
            {
                "id": 2040,
                "itemName": "MA/Sc in Gerontology"
            },
            {
                "id": 2041,
                "itemName": "MPA in Public Administration"
            },
            {
                "id": 2042,
                "itemName": "MA in Justice Studies"
            },
            {
                "id": 2043,
                "itemName": "MA in Police Studies"
            },
            {
                "id": 2044,
                "itemName": "MFA (Studio Arts Practice)"
            },
            {
                "id": 2045,
                "itemName": "MA in Experimental and Applied Psychology"
            },
            {
                "id": 2046,
                "itemName": "MA in Creative Writing and English"
            },
            {
                "id": 2047,
                "itemName": "MBA-- Executive Management (EMBA)"
            },
            {
                "id": 2048,
                "itemName": "Master of Indigenous Social Work"
            },
            {
                "id": 2049,
                "itemName": "MEng in Process Systems Engineering"
            },
            {
                "id": 2050,
                "itemName": "MASc in Process Systems Engineering"
            },
            {
                "id": 2051,
                "itemName": "MASc in Software Systems Engineering"
            },
            {
                "id": 2052,
                "itemName": "MEng in Software Systems Engineering"
            },
            {
                "id": 2053,
                "itemName": "Master of Administration - Leadership"
            },
            {
                "id": 2054,
                "itemName": "MEd in Adult Education and Community Engagement"
            },
            {
                "id": 2055,
                "itemName": "MFA in Media Production and Studies"
            },
            {
                "id": 2056,
                "itemName": "MA in Media Production and Studies"
            },
            {
                "id": 2057,
                "itemName": "MPP in Public Policy"
            },
            {
                "id": 2058,
                "itemName": "MBA-General Management"
            },
            {
                "id": 2059,
                "itemName": "MEd in Human Resource Development"
            },
            {
                "id": 2060,
                "itemName": "Master of Health Administration (MHA)"
            },
            {
                "id": 2061,
                "itemName": "Master of Nursing (Nurse Practitioner)"
            },
            {
                "id": 2062,
                "itemName": "Maîtrise en éducation française"
            },
            {
                "id": 2063,
                "itemName": "Master of Indigenous Education"
            },
            {
                "id": 2064,
                "itemName": "MMus in Conducting"
            },
            {
                "id": 2065,
                "itemName": "MBA - Public Safety Management"
            },
            {
                "id": 2066,
                "itemName": "Master of Kinesiology in Clinical and Applied Movement Science"
            },
            {
                "id": 2067,
                "itemName": "MEd in Educational Leadership"
            },
            {
                "id": 2068,
                "itemName": "MSc in Organizational Studies"
            },
            {
                "id": 2069,
                "itemName": "Med in Indigenous Language Education"
            },
            {
                "id": 2070,
                "itemName": "Master of Health Informatics and Information Management "
            },
            {
                "id": 2071,
                "itemName": "MSc in Applied Modelling and Quantitative Methods"
            },
            {
                "id": 2072,
                "itemName": "MSc in Environmental and Life Sciences"
            },
            {
                "id": 2073,
                "itemName": "MA in Canadian Studies and Indigenous Studies"
            },
            {
                "id": 2074,
                "itemName": "MA in Applied Modelling and Quantitative Methods"
            },
            {
                "id": 2075,
                "itemName": "MA in English Literature (Public Texts)"
            },
            {
                "id": 2076,
                "itemName": "MSc in Materials Science"
            },
            {
                "id": 2077,
                "itemName": "MA in Sustainability Studies"
            },
            {
                "id": 2078,
                "itemName": "MScFS in Forensic Science"
            },
            {
                "id": 2079,
                "itemName": "MBEMA in Bioenvironmental Monitoring and Assessment"
            },
            {
                "id": 2080,
                "itemName": "MICA in Instrumental Chemical Analysis"
            },
            {
                "id": 2081,
                "itemName": "MSc in Bioenvironmental Monitoring and Assessment"
            },
            {
                "id": 2082,
                "itemName": "Master of Management (M.Mgt) - Strategic Change Management"
            },
            {
                "id": 2083,
                "itemName": "MA in education (curriculum studies)"
            },
            {
                "id": 2084,
                "itemName": "MA with concentration in educational leadership studies"
            },
            {
                "id": 2085,
                "itemName": "MEd with concentration in educational leadership studies"
            },
            {
                "id": 2086,
                "itemName": "MEd concentration in Art Education"
            },
            {
                "id": 2087,
                "itemName": "MFA in visual arts"
            },
            {
                "id": 2088,
                "itemName": "Master of business administration/Juris doctor"
            },
            {
                "id": 2089,
                "itemName": "MASc in industrial engineering"
            },
            {
                "id": 2090,
                "itemName": "Master of human kinetics (kinesiology)"
            },
            {
                "id": 2091,
                "itemName": "MSc in earth sciences"
            },
            {
                "id": 2092,
                "itemName": "MSc in chemistry and biochemistry"
            },
            {
                "id": 2093,
                "itemName": "Master of Management"
            },
            {
                "id": 2094,
                "itemName": "MA in communication and social justice"
            },
            {
                "id": 2095,
                "itemName": "MASc in engineering materials"
            },
            {
                "id": 2096,
                "itemName": "MASc in electrical engineering"
            },
            {
                "id": 2097,
                "itemName": "MSc in nursing"
            },
            {
                "id": 2098,
                "itemName": "MASc in civil engineering"
            },
            {
                "id": 2099,
                "itemName": "MASc in environmental engineering"
            },
            {
                "id": 2100,
                "itemName": "MASc in mechanical engineering"
            },
            {
                "id": 2101,
                "itemName": "MEng in engineering materials"
            },
            {
                "id": 2102,
                "itemName": "Master of nursing"
            },
            {
                "id": 2103,
                "itemName": "Master of social work"
            },
            {
                "id": 2104,
                "itemName": "Master of business administration for managers and professionals"
            },
            {
                "id": 2105,
                "itemName": "MEng in industrial engineering"
            },
            {
                "id": 2106,
                "itemName": "MA in political science and MPP in Public Policy - articulation agreement"
            },
            {
                "id": 2107,
                "itemName": "Master of business administration"
            },
            {
                "id": 2108,
                "itemName": "MA in criminology"
            },
            {
                "id": 2109,
                "itemName": "Master of social work/Juris Doctor (JD)"
            },
            {
                "id": 2110,
                "itemName": "MA in Social Data Analysis"
            },
            {
                "id": 2111,
                "itemName": "MA in political science with internship"
            },
            {
                "id": 2112,
                "itemName": "Master of Actuarial Science (MAcSc)"
            },
            {
                "id": 2113,
                "itemName": "MFA in Film and Media Arts"
            },
            {
                "id": 2114,
                "itemName": "Master of Medical Biotechnology"
            },
            {
                "id": 2115,
                "itemName": "Master of Education (International Cohort)"
            },
            {
                "id": 2116,
                "itemName": "MEng in automotive engineering"
            },
            {
                "id": 2117,
                "itemName": "Master in Applied Computing"
            },
            {
                "id": 2118,
                "itemName": "Master of Applied Economics and Policy"
            },
            {
                "id": 2119,
                "itemName": "Master of Engineering Management"
            },
            {
                "id": 2120,
                "itemName": "Master of Business Administration (Professional Accounting Specialization)"
            },
            {
                "id": 2121,
                "itemName": "International MASc in Civil Engineering and Laurea Magistrate in Civil Engineering"
            },
            {
                "id": 2122,
                "itemName": "International MASc in Automotive Engineering and Laurea Magistrate in Automotive Engineering"
            },
            {
                "id": 2123,
                "itemName": "Master of Materials Chemistry and Engineering"
            },
            {
                "id": 2124,
                "itemName": "MSc in Psychological Science"
            },
            {
                "id": 2125,
                "itemName": "MSc in Forestry"
            },
            {
                "id": 2126,
                "itemName": "Master of Science in Engineering in Control Engineering"
            },
            {
                "id": 2127,
                "itemName": "Collaborative Master's Program with Specialization in Gerontology"
            },
            {
                "id": 2128,
                "itemName": "Collaborative Master's Program with Specialization in Women's Studies"
            },
            {
                "id": 2129,
                "itemName": "MSc in Mathematical Sciences"
            },
            {
                "id": 2130,
                "itemName": "Master of Science in Environmental Engineering"
            },
            {
                "id": 2131,
                "itemName": "Master of Environmental Studies in Nature-Based Recreation and Tourism"
            },
            {
                "id": 2132,
                "itemName": "Master of Science in Engineering in Electrical & Computer Engineering"
            },
            {
                "id": 2133,
                "itemName": "Master of Environmental Studies - Northern Environments & Cultures"
            },
            {
                "id": 2134,
                "itemName": "Master of Health Sciences"
            },
            {
                "id": 2135,
                "itemName": "MEd in Education for Change"
            },
            {
                "id": 2136,
                "itemName": "Master of Science in Mechanical Engineering"
            },
            {
                "id": 2137,
                "itemName": "Master of Science in Civil Engineering"
            },
            {
                "id": 2138,
                "itemName": "MA in Social Justice"
            },
            {
                "id": 2139,
                "itemName": "MA in Natural Resources and Environmental Studies"
            },
            {
                "id": 2140,
                "itemName": "MEd"
            },
            {
                "id": 2141,
                "itemName": "MSc in Community Health Science"
            },
            {
                "id": 2142,
                "itemName": "MSc in Natural Resources and Environmental Studies"
            },
            {
                "id": 2143,
                "itemName": "MA in Disability Management"
            },
            {
                "id": 2144,
                "itemName": "MA in First Nations Studies"
            },
            {
                "id": 2145,
                "itemName": "Master of Natural Resources and Environmental Studies (MNRES)"
            },
            {
                "id": 2146,
                "itemName": "MSc in Mathematical, Computer, and Physical Sciences"
            },
            {
                "id": 2147,
                "itemName": "MA in Development Economics"
            },
            {
                "id": 2148,
                "itemName": "MSc in Business Administration"
            },
            {
                "id": 2149,
                "itemName": "Master of Engineering (MEng) - Integrated Wood Design"
            },
            {
                "id": 2150,
                "itemName": "Master of Science-Pathology and Microbiology"
            },
            {
                "id": 2151,
                "itemName": "Master of Science-Companion Animals"
            },
            {
                "id": 2152,
                "itemName": "Master of Science-Environmental Sciences"
            },
            {
                "id": 2153,
                "itemName": "MEd in Leadership in Learning"
            },
            {
                "id": 2154,
                "itemName": "Master of Science - Biomedical Sciences"
            },
            {
                "id": 2155,
                "itemName": "Master of Science - Health Management"
            },
            {
                "id": 2156,
                "itemName": "Master of Arts (Island Studies)"
            },
            {
                "id": 2157,
                "itemName": "Master of Veterinary Science"
            },
            {
                "id": 2158,
                "itemName": "Master of Science-Human Biology"
            },
            {
                "id": 2159,
                "itemName": "Master of Science-Molecular and Macromolecular Sciences"
            },
            {
                "id": 2160,
                "itemName": "Master of Arts in Island Studies (Specializations)"
            },
            {
                "id": 2161,
                "itemName": "Master of Science - Sustainable Design Engineering"
            },
            {
                "id": 2162,
                "itemName": "Master of Business Administration in Global Leadership"
            },
            {
                "id": 2163,
                "itemName": "Maîtrise en administration des affaires et juris doctor"
            },
            {
                "id": 2164,
                "itemName": "Maîtrise ès arts (travail social)"
            },
            {
                "id": 2165,
                "itemName": "Maîtrise ès sciences (biochimie)"
            },
            {
                "id": 2166,
                "itemName": "Maîtrise en science infirmière"
            },
            {
                "id": 2167,
                "itemName": "Maîtrise ès sciences forestières"
            },
            {
                "id": 2168,
                "itemName": "Maîtrise en administration des affaires"
            },
            {
                "id": 2169,
                "itemName": "Maîtrise ès sciences (chimie)"
            },
            {
                "id": 2170,
                "itemName": "Maîtrise ès arts (histoire)"
            },
            {
                "id": 2171,
                "itemName": "Maîtrise en éducation (Enseignement)"
            },
            {
                "id": 2172,
                "itemName": "Maîtrise ès sciences en nutrition-alimentation"
            },
            {
                "id": 2173,
                "itemName": "Maîtrise ès arts en orientation"
            },
            {
                "id": 2174,
                "itemName": "Maîtrise en éducation (Administration de l'éducation)"
            },
            {
                "id": 2175,
                "itemName": "Maîtrise ès sciences (physique)"
            },
            {
                "id": 2176,
                "itemName": "Maîtrise ès sciences (biologie)"
            },
            {
                "id": 2177,
                "itemName": "Maîtrise ès sciences appliquées"
            },
            {
                "id": 2178,
                "itemName": "Maîtrise ès arts en éducation"
            },
            {
                "id": 2179,
                "itemName": "Maîtrise en administration publique"
            },
            {
                "id": 2180,
                "itemName": "Maîtrise en éducation (Enseignement-ressource)"
            },
            {
                "id": 2181,
                "itemName": "Maîtrise ès arts (études littéraires)"
            },
            {
                "id": 2182,
                "itemName": "Maîtrise ès arts (sciences du langage)"
            },
            {
                "id": 2183,
                "itemName": "Maîtrise en droit (LLM)"
            },
            {
                "id": 2184,
                "itemName": "Maîtrise ès sciences (mathématiques)"
            },
            {
                "id": 2185,
                "itemName": "Maîtrise en administration des affaires, option coop"
            },
            {
                "id": 2186,
                "itemName": "Maîtrise en gestion des services de santé"
            },
            {
                "id": 2187,
                "itemName": "Maîtrise ès arts (littérature canadienne comparée)"
            },
            {
                "id": 2188,
                "itemName": "Maîtrise ès sciences (informatique)"
            },
            {
                "id": 2189,
                "itemName": "Maîtrise en études de l'environnement"
            },
            {
                "id": 2190,
                "itemName": "Maîtrise ès arts (psychologie)"
            },
            {
                "id": 2191,
                "itemName": "Maîtrise ès sciences (gestion)"
            },
            {
                "id": 2192,
                "itemName": "Doctorat ès sciences appliquées"
            },
            {
                "id": 2193,
                "itemName": "Maîtrise ès arts (science sociales)"
            },
            {
                "id": 2194,
                "itemName": "Maîtrise ès sciences interdisciplinaire en santé"
            },
            {
                "id": 2195,
                "itemName": "Master of Arts in Child and Youth Study"
            },
            {
                "id": 2196,
                "itemName": "Master of Arts in Family Studies and Gerontology"
            },
            {
                "id": 2197,
                "itemName": "Master of Education in Literacy Education"
            },
            {
                "id": 2198,
                "itemName": "Master of Education in Elementary and Middle School Education"
            },
            {
                "id": 2199,
                "itemName": "Master of Education in Educational Foundations"
            },
            {
                "id": 2200,
                "itemName": "Research Master of Arts"
            },
            {
                "id": 2201,
                "itemName": "Master of Arts in School Psychology"
            },
            {
                "id": 2202,
                "itemName": "Master of Education in Curriculum Studies"
            },
            {
                "id": 2203,
                "itemName": "Master of Arts in Education (Studies in Life-Long Learning)"
            },
            {
                "id": 2204,
                "itemName": "Master of Arts in Education (Educational Foundations)"
            },
            {
                "id": 2205,
                "itemName": "Master of Arts in Education (Elementary and Middle School Education)"
            },
            {
                "id": 2206,
                "itemName": "Master of Arts in Education (Literacy Education)"
            },
            {
                "id": 2207,
                "itemName": "Master of Arts in Education (Educational Psychology)"
            },
            {
                "id": 2208,
                "itemName": "Master of Applied Human Nutrition"
            },
            {
                "id": 2209,
                "itemName": "Master of Science in Applied Human Nutrition"
            },
            {
                "id": 2210,
                "itemName": "Master of Arts in Education (Curriculum Studies)"
            },
            {
                "id": 2211,
                "itemName": "Master of Public Relations"
            },
            {
                "id": 2212,
                "itemName": "Master of Arts in Communication"
            },
            {
                "id": 2213,
                "itemName": "Master of Education in Educational Psychology"
            },
            {
                "id": 2214,
                "itemName": "Master of Education in Studies in Life Long Learning"
            },
            {
                "id": 2215,
                "itemName": "Master of Family Studies and Gerontology"
            },
            {
                "id": 2216,
                "itemName": "Master of Applied Science in Engineering Science"
            },
            {
                "id": 2217,
                "itemName": "Maîtrise en service social (MSS)"
            },
            {
                "id": 2218,
                "itemName": "MA in Interdisciplinary Health"
            },
            {
                "id": 2219,
                "itemName": "MA en santé interdisciplinaire"
            },
            {
                "id": 2220,
                "itemName": "Maîtrise ès arts en histoire"
            },
            {
                "id": 2221,
                "itemName": "Masters of Science in Nursing Primary Care Nurse Practitioner Program"
            },
            {
                "id": 2222,
                "itemName": "MSc in Interdisciplinary Health"
            },
            {
                "id": 2223,
                "itemName": "MSc in Chemical Sciences"
            },
            {
                "id": 2224,
                "itemName": "MSc en santé interdisciplinaire"
            },
            {
                "id": 2225,
                "itemName": "MA en sociologie"
            },
            {
                "id": 2226,
                "itemName": "Maîtrise en formation des infirmières et infirmiers praticiens en soins de santé primaires"
            },
            {
                "id": 2227,
                "itemName": "MSc in Computational Sciences"
            },
            {
                "id": 2228,
                "itemName": "MA in Applied Psychology"
            },
            {
                "id": 2229,
                "itemName": "Master of Engineering in Science"
            },
            {
                "id": 2230,
                "itemName": "MA en psychologie"
            },
            {
                "id": 2231,
                "itemName": "MA en humanités"
            },
            {
                "id": 2232,
                "itemName": "M.Sc.S en orthophonie"
            },
            {
                "id": 2233,
                "itemName": "M.Sc.Inf. sciences infirmières"
            },
            {
                "id": 2234,
                "itemName": "Master of Indigenous Relations"
            },
            {
                "id": 2235,
                "itemName": "Masters of Architecture"
            },
            {
                "id": 2236,
                "itemName": "MSc Forensic Science "
            },
            {
                "id": 2237,
                "itemName": "Masters of Science Communication "
            },
            {
                "id": 2238,
                "itemName": "Master of Business Administration (Community Economic Development)"
            },
            {
                "id": 2239,
                "itemName": "Master of Education in Information Technology"
            },
            {
                "id": 2240,
                "itemName": "MEd in Educational Administration"
            },
            {
                "id": 2241,
                "itemName": "MMus (Performance and Literature) (2 yr)"
            },
            {
                "id": 2242,
                "itemName": "MMus (Music Education) (2 yr)"
            },
            {
                "id": 2243,
                "itemName": "M.Ed in Curriculum and Pedagogy"
            },
            {
                "id": 2244,
                "itemName": "Master of Psychiatric Nursing (1 yr)"
            },
            {
                "id": 2245,
                "itemName": "Master of Rural Development (1 yr)"
            },
            {
                "id": 2246,
                "itemName": "MEd in Guidance and Counselling"
            },
            {
                "id": 2247,
                "itemName": "MSc in Environmental & Life Science"
            },
            {
                "id": 2248,
                "itemName": "MEd - Master of Education"
            },
            {
                "id": 2249,
                "itemName": "MESc in Environmental Sciences"
            },
            {
                "id": 2250,
                "itemName": "Master of Pastoral Studies"
            },
            {
                "id": 2251,
                "itemName": "Master of Divinity"
            },
            {
                "id": 2252,
                "itemName": "Master of Theological Studies"
            },
            {
                "id": 2253,
                "itemName": "Master of Divinity/Master of Pastoral Studies"
            },
            {
                "id": 2254,
                "itemName": "Master of Sacred Music"
            },
            {
                "id": 2255,
                "itemName": "Master of Theology"
            },
            {
                "id": 2256,
                "itemName": "Master of Arts in Interdisciplinary Studies"
            },
            {
                "id": 2257,
                "itemName": "Master of Science in Information Systems"
            },
            {
                "id": 2258,
                "itemName": "Master of Health Studies"
            },
            {
                "id": 2259,
                "itemName": "Master of Counselling "
            },
            {
                "id": 2260,
                "itemName": "Master of Education in Distance Education"
            },
            {
                "id": 2261,
                "itemName": "Master of Information Systems Security Management"
            },
            {
                "id": 2262,
                "itemName": "Master of Information Systems Assurance Management"
            },
            {
                "id": 2263,
                "itemName": "Master of Science in Information Technology"
            },
            {
                "id": 2264,
                "itemName": "Master of Education in Educational Leadership"
            },
            {
                "id": 2265,
                "itemName": "Bachelor of Arts (French) International Double Degrees"
            },
            {
                "id": 2266,
                "itemName": "Bachelor of Management & Bachelor of Science in Chemistry (Dual Degrees)"
            },
            {
                "id": 2267,
                "itemName": "MBA in Executive Management"
            },
            {
                "id": 2268,
                "itemName": "MSc in Environment and Management"
            },
            {
                "id": 2269,
                "itemName": "MA in Environment and Management"
            },
            {
                "id": 2270,
                "itemName": "MA in Conflict Analysis and Management"
            },
            {
                "id": 2271,
                "itemName": "MA in Learning and Technology"
            },
            {
                "id": 2272,
                "itemName": "MBA in Executive Management with specialization in Management Consulting"
            },
            {
                "id": 2273,
                "itemName": "MA in Leadership"
            },
            {
                "id": 2274,
                "itemName": "MA in Professional Communication"
            },
            {
                "id": 2275,
                "itemName": "MA in Intercultural and International Communication"
            },
            {
                "id": 2276,
                "itemName": "MA in Human Security and Peacebuilding"
            },
            {
                "id": 2277,
                "itemName": "MA in Environmental Education and Communication"
            },
            {
                "id": 2278,
                "itemName": "MA in Disaster and Emergency Management"
            },
            {
                "id": 2279,
                "itemName": "MA in Tourism Management (Blended Stream)"
            },
            {
                "id": 2280,
                "itemName": "MA in Leadership (Health)"
            },
            {
                "id": 2281,
                "itemName": "MSc in Environmental Practice"
            },
            {
                "id": 2282,
                "itemName": "Master of Global Management"
            },
            {
                "id": 2283,
                "itemName": "MA in Educational Leadership and Management"
            },
            {
                "id": 2284,
                "itemName": "MA in Tourism Management (On Campus Stream)"
            },
            {
                "id": 2285,
                "itemName": "MA in Environmental Practice"
            },
            {
                "id": 2286,
                "itemName": "MA in Global Leadership"
            },
            {
                "id": 2287,
                "itemName": "MA in Higher Education Administration and Leadership"
            },
            {
                "id": 2288,
                "itemName": "MBA in Executive Management with specialization in Leadership"
            },
            {
                "id": 2289,
                "itemName": "MBA in Executive Management with specialization in International Business - Asia Pacific Trade and Investment"
            },
            {
                "id": 2290,
                "itemName": "MBA in Executive Management with specialization in International Business & Innovation - Europe"
            },
            {
                "id": 2291,
                "itemName": "MA in Climate Action Leadership"
            },
            {
                "id": 2292,
                "itemName": "MA in Executive and Organizational Coaching"
            },
            {
                "id": 2293,
                "itemName": "Master of Environmental Economics and Management"
            },
            {
                "id": 2294,
                "itemName": "Master of Science in Environmental economics and Management"
            },
            {
                "id": 2295,
                "itemName": "MSc in Data Science"
            },
            {
                "id": 2296,
                "itemName": "Master of Science in Data Science"
            },
            {
                "id": 2297,
                "itemName": "Master of Christian Studies in Marriage and Family Therapy"
            },
            {
                "id": 2298,
                "itemName": "Master of Arts in Christian Studies"
            },
            {
                "id": 2299,
                "itemName": "MA in Biblical Studies"
            },
            {
                "id": 2300,
                "itemName": "Master of Applied Linguistics and Exegesis"
            },
            {
                "id": 2301,
                "itemName": "MA in Teaching English to Speakers of Other Languages (TESOL)"
            },
            {
                "id": 2302,
                "itemName": "MA in Interdisciplinary Humanities"
            },
            {
                "id": 2303,
                "itemName": "MA in Educational Studies - Special Education"
            },
            {
                "id": 2304,
                "itemName": "Master of Arts - Worldview Studies in Korean"
            },
            {
                "id": 2305,
                "itemName": "Master of arts in Criminal Justice"
            },
            {
                "id": 2306,
                "itemName": "Master in Social Work"
            },
            {
                "id": 2307,
                "itemName": "Master of Education in Special Education"
            },
            {
                "id": 2308,
                "itemName": "Master of Arts in Sustainable Leisure Management"
            },
            {
                "id": 2309,
                "itemName": "Master of Community Planning"
            },
            {
                "id": 2310,
                "itemName": "Master of Geographic Information Systems (GIS) Applications"
            },
            {
                "id": 2311,
                "itemName": "MA in Christian Ministry"
            },
            {
                "id": 2312,
                "itemName": "MA in Peace and Collaborative Development"
            },
            {
                "id": 2313,
                "itemName": "Joint MA Program in Peace and Conflict Studies"
            },
            {
                "id": 2314,
                "itemName": "MÉd - spécialisation administration scolaire"
            },
            {
                "id": 2315,
                "itemName": "MÉd - spécialisation éducation inclusive"
            },
            {
                "id": 2316,
                "itemName": "MÉd spécialisation langue, littératie et curriculum"
            },
            {
                "id": 2317,
                "itemName": "MÉd - Spécialisation counselling scolaire"
            },
            {
                "id": 2318,
                "itemName": "MFA in craft"
            },
            {
                "id": 2319,
                "itemName": "MFA in fine and media arts"
            },
            {
                "id": 2320,
                "itemName": "Maîtrise ès arts en cultures et espaces francophones"
            },
            {
                "id": 2321,
                "itemName": "Master of Fine Arts in Creative Non Fiction"
            },
            {
                "id": 2322,
                "itemName": "MSc in Foods and Nutrition"
            },
            {
                "id": 2323,
                "itemName": "Master of Fine Arts in Criticism & Curatorial Practices"
            },
            {
                "id": 2324,
                "itemName": "Master of Arts, Master of Design, Master of Fine Arts: Interdisciplinary Master's in Art, Media, and Design"
            },
            {
                "id": 2325,
                "itemName": "Master of Arts in Contemporary Art, Design and New Media Art Histories"
            },
            {
                "id": 2326,
                "itemName": "Master of Design in Strategic Foresight and Innovation"
            },
            {
                "id": 2327,
                "itemName": "Master of Design in Inclusive Design"
            },
            {
                "id": 2328,
                "itemName": "Master of Design in Digital Futures"
            },
            {
                "id": 2329,
                "itemName": "Master of Arts in Digital Futures"
            },
            {
                "id": 2330,
                "itemName": "Master of Fine Arts in Digital Futures"
            },
            {
                "id": 2331,
                "itemName": "Master of Design in Design for Health"
            },
            {
                "id": 2332,
                "itemName": "Master of Engineering in electrical engineering"
            },
            {
                "id": 2333,
                "itemName": "Maîtrise en génie en génie informatique"
            },
            {
                "id": 2334,
                "itemName": "Maîtrise en génie en génie du logiciel"
            },
            {
                "id": 2335,
                "itemName": "Master of Engineering  in chemical and materials engineering"
            },
            {
                "id": 2336,
                "itemName": "Master of Engineering in civil engineering"
            },
            {
                "id": 2337,
                "itemName": "Maîtrise en génie en génie civil"
            },
            {
                "id": 2338,
                "itemName": "Maîtrise en génie en génie nucléaire"
            },
            {
                "id": 2339,
                "itemName": "Maîtrise en génie en génie électrique"
            },
            {
                "id": 2340,
                "itemName": "Maîtrise en génie en génie chimique et des matériaux"
            },
            {
                "id": 2341,
                "itemName": "Master of engineering in mechanical engineering"
            },
            {
                "id": 2342,
                "itemName": "Maîtrise en génie en génie de l'environnement"
            },
            {
                "id": 2343,
                "itemName": "Maîtrise ès sciences en sciences chimiques et des matériaux"
            },
            {
                "id": 2344,
                "itemName": "Master of Engineering in computer engineering"
            },
            {
                "id": 2345,
                "itemName": "Master of Arts in war studies"
            },
            {
                "id": 2346,
                "itemName": "Maîtrise en arts en études sur la guerre"
            },
            {
                "id": 2347,
                "itemName": "Maîtrise ès sciences en sciences informatiques"
            },
            {
                "id": 2348,
                "itemName": "Master of engineering in nuclear engineering"
            },
            {
                "id": 2349,
                "itemName": "Master of defence studies"
            },
            {
                "id": 2350,
                "itemName": "Master of Science in chemical and materials science"
            },
            {
                "id": 2351,
                "itemName": "Master of Science in computer science"
            },
            {
                "id": 2352,
                "itemName": "Master of Science in environmental science"
            },
            {
                "id": 2353,
                "itemName": "Master of Science in mathematics"
            },
            {
                "id": 2354,
                "itemName": "Master of Science in nuclear science"
            },
            {
                "id": 2355,
                "itemName": "Master of Science in physics"
            },
            {
                "id": 2356,
                "itemName": "Master of Engineering in environmental engineering"
            },
            {
                "id": 2357,
                "itemName": "Master of Engineering in software engineering"
            },
            {
                "id": 2358,
                "itemName": "Master of Applied Science in chemical and materials engineering"
            },
            {
                "id": 2359,
                "itemName": "Master of Applied Science in civil engineering"
            },
            {
                "id": 2360,
                "itemName": "Master of Applied Science in electrical engineering"
            },
            {
                "id": 2361,
                "itemName": "Master of Applied Science in environmental engineering"
            },
            {
                "id": 2362,
                "itemName": "Master of Applied Science in mechanical engineering"
            },
            {
                "id": 2363,
                "itemName": "Master of Applied Science in nuclear engineering"
            },
            {
                "id": 2364,
                "itemName": "Master of Applied Science in software engineering"
            },
            {
                "id": 2365,
                "itemName": "Maîtrise ès sciences en sciences de l'environnement"
            },
            {
                "id": 2366,
                "itemName": "Maîtrise ès sciences en mathématiques"
            },
            {
                "id": 2367,
                "itemName": "Maîtrise ès sciences en sciences nucléaires"
            },
            {
                "id": 2368,
                "itemName": "Maîtrise ès sciences en physique"
            },
            {
                "id": 2369,
                "itemName": "Maîtrise en génie en génie mécanique"
            },
            {
                "id": 2370,
                "itemName": "Maîtrise ès sciences appliquées en génie chimique et des matériaux"
            },
            {
                "id": 2371,
                "itemName": "Maîtrise ès sciences appliquées en génie civil"
            },
            {
                "id": 2372,
                "itemName": "Maîtrise ès sciences appliquées en génie informatique"
            },
            {
                "id": 2373,
                "itemName": "Maîtrise ès sciences appliquées en génie électrique"
            },
            {
                "id": 2374,
                "itemName": "Maîtrise ès sciences appliquées en génie de l'environnement"
            },
            {
                "id": 2375,
                "itemName": "Maîtrise ès sciences appliquées en génie mécanique"
            },
            {
                "id": 2376,
                "itemName": "Maîtrise ès sciences appliquées en génie nucléaire"
            },
            {
                "id": 2377,
                "itemName": "Maîtrise ès sciences appliquées en génie du logiciel"
            },
            {
                "id": 2378,
                "itemName": "Maîtrise en études de la défense"
            },
            {
                "id": 2379,
                "itemName": "Maîtrise en génie en génie aéronautique"
            },
            {
                "id": 2380,
                "itemName": "Maîtrise ès sciences appliquées en génie aéronautique"
            },
            {
                "id": 2381,
                "itemName": "Master of Applied Science in aeronautical engineering"
            },
            {
                "id": 2382,
                "itemName": "Master of Engineering in aeronautical engineering"
            },
            {
                "id": 2383,
                "itemName": "Master of Catholic Thought"
            },
            {
                "id": 2384,
                "itemName": "Master of Arts in Theological Studies (MA)"
            },
            {
                "id": 2385,
                "itemName": "Master of Religious Education (MRE)"
            },
            {
                "id": 2386,
                "itemName": "Master of Theology (ThM)"
            },
            {
                "id": 2387,
                "itemName": "Master of divinity"
            },
            {
                "id": 2388,
                "itemName": "Master of theology"
            },
            {
                "id": 2389,
                "itemName": "Master of theological studies"
            },
            {
                "id": 2390,
                "itemName": "Master of Arts in Theological Studies"
            },
            {
                "id": 2391,
                "itemName": "Maîtrise en droit (L.L.M.), option fiscalité"
            },
            {
                "id": 2392,
                "itemName": "Maîtrise ès sciences de la gestion (M.Sc.)"
            },
            {
                "id": 2393,
                "itemName": "MBA à temps plein"
            },
            {
                "id": 2394,
                "itemName": "MBA à temps partiel"
            },
            {
                "id": 2395,
                "itemName": "Maîtrise ès sciences en commerce électronique"
            },
            {
                "id": 2396,
                "itemName": "MBA (Full-Time Program)"
            },
            {
                "id": 2397,
                "itemName": "Maîtrise en management des entreprises culturelles (M.M.E.C.)"
            },
            {
                "id": 2398,
                "itemName": "Master of Management in International Arts Management (MMIAM)"
            },
            {
                "id": 2399,
                "itemName": "Master of Science (MSC)"
            },
            {
                "id": 2400,
                "itemName": "Maîtrise en management et développement durable"
            },
            {
                "id": 2401,
                "itemName": "Maîtrise en Génie énergétique"
            },
            {
                "id": 2402,
                "itemName": "Maîtrise en Génie civil"
            },
            {
                "id": 2403,
                "itemName": "Maîtrise en Génie minéral"
            },
            {
                "id": 2404,
                "itemName": "Maîtrise en Génie industriel"
            },
            {
                "id": 2405,
                "itemName": "Maîtrise en Génie biomédical"
            },
            {
                "id": 2406,
                "itemName": "Maîtrise en Génie aérospatial"
            },
            {
                "id": 2407,
                "itemName": "Maîtrise en Génie physique"
            },
            {
                "id": 2408,
                "itemName": "Maîtrise en Génie mécanique"
            },
            {
                "id": 2409,
                "itemName": "Maîtrise en Génie chimique"
            },
            {
                "id": 2410,
                "itemName": "Maîtrise en Génie informatique"
            },
            {
                "id": 2411,
                "itemName": "Maîtrise en Mathématiques appliquées"
            },
            {
                "id": 2412,
                "itemName": "Maîtrise en Génie électrique"
            },
            {
                "id": 2413,
                "itemName": "MA en lettres"
            },
            {
                "id": 2414,
                "itemName": "MSc en sciences cliniques et biomédicales"
            },
            {
                "id": 2415,
                "itemName": "MSc en ressources renouvelables"
            },
            {
                "id": 2416,
                "itemName": "MScA en géologie et génie géologique"
            },
            {
                "id": 2417,
                "itemName": "MA en linguistique"
            },
            {
                "id": 2418,
                "itemName": "MA en administration des affaires"
            },
            {
                "id": 2419,
                "itemName": "MSc en informatique"
            },
            {
                "id": 2420,
                "itemName": "MA en études et interventions régionales"
            },
            {
                "id": 2421,
                "itemName": "MA en art"
            },
            {
                "id": 2422,
                "itemName": "MSc en gestion de projet"
            },
            {
                "id": 2423,
                "itemName": "MSc en gestion des organisations"
            },
            {
                "id": 2424,
                "itemName": "MScA en ingénierie"
            },
            {
                "id": 2425,
                "itemName": "MSc en sciences infirmières"
            },
            {
                "id": 2426,
                "itemName": "MÉd en éducation"
            },
            {
                "id": 2427,
                "itemName": "MA en travail social"
            },
            {
                "id": 2428,
                "itemName": "MSc en sciences infirmières (santé mentale et soins psychiatriques)"
            },
            {
                "id": 2429,
                "itemName": "MSc ès sciences appliquées en physiothérapie"
            },
            {
                "id": 2430,
                "itemName": "MA en informatique (jeux vidéo)"
            },
            {
                "id": 2431,
                "itemName": "MSc en sciences infirmières (iPS soins de première ligne)"
            },
            {
                "id": 2432,
                "itemName": "MSc en ingénierie"
            },
            {
                "id": 2433,
                "itemName": "Maîtrise en administration (MBA) pour cadres"
            },
            {
                "id": 2434,
                "itemName": "MSc en gestion des personnes en milieu de travail"
            },
            {
                "id": 2435,
                "itemName": "MSc en gestion des ressources maritimes"
            },
            {
                "id": 2436,
                "itemName": "MGP en gestion de projet"
            },
            {
                "id": 2437,
                "itemName": "MSc en biologie"
            },
            {
                "id": 2438,
                "itemName": "MA en développement régional et territorial"
            },
            {
                "id": 2439,
                "itemName": "MSc en océanographie"
            },
            {
                "id": 2440,
                "itemName": "MA en éthique"
            },
            {
                "id": 2441,
                "itemName": "MA en études des pratiques psychosociales"
            },
            {
                "id": 2442,
                "itemName": "MA en éducation"
            },
            {
                "id": 2443,
                "itemName": "MSc en géographie"
            },
            {
                "id": 2444,
                "itemName": "MA en histoire"
            },
            {
                "id": 2445,
                "itemName": "MSC en sciences infirmières, concentration santé mentale avec stage"
            },
            {
                "id": 2446,
                "itemName": "(M.Adm.) par cumul en sciences comptables"
            },
            {
                "id": 2447,
                "itemName": "Maîtrise en sciences infirmières (iPS santé mentale)"
            },
            {
                "id": 2448,
                "itemName": "Maîtrise en sciences de l'énergie et des matériaux"
            },
            {
                "id": 2449,
                "itemName": "Maîtrise en ingénierie"
            },
            {
                "id": 2450,
                "itemName": "Maîtrise en mathématiques et informatique appliquées"
            },
            {
                "id": 2451,
                "itemName": "Maîtrise en études québécoises"
            },
            {
                "id": 2452,
                "itemName": "Maîtrise en sciences de la gestion"
            },
            {
                "id": 2453,
                "itemName": "Maîtrise en sciences de l'environnement"
            },
            {
                "id": 2454,
                "itemName": "Maîtrise en sciences et génie des matériaux lignocellulosiques"
            },
            {
                "id": 2455,
                "itemName": "Maîtrise en lettres"
            },
            {
                "id": 2456,
                "itemName": "Maitrise en orthophonie"
            },
            {
                "id": 2457,
                "itemName": "Maitrise en sciences infirmières (santé mentale et soins psychiatriques)"
            },
            {
                "id": 2458,
                "itemName": "Maîtrise en enseignement"
            },
            {
                "id": 2459,
                "itemName": "Maîtrise en sciences infirmières (infirmière praticienne spécialisée en soins de première ligne)"
            },
            {
                "id": 2460,
                "itemName": "Maîtrise en sécurité et hygiène industrielle"
            },
            {
                "id": 2461,
                "itemName": "Maitrise en sciences infirmières (infirmière praticienne spécialisée en santé mentale)"
            },
            {
                "id": 2462,
                "itemName": "Maîtrise en biologie cellulaire et moléculaire"
            },
            {
                "id": 2463,
                "itemName": "Maîtrise en gestion de projet"
            },
            {
                "id": 2464,
                "itemName": "Maîtrise en loisir, culture et tourisme"
            },
            {
                "id": 2465,
                "itemName": "Maîtrise en gestion des organisations"
            },
            {
                "id": 2466,
                "itemName": "Maîtrise en génie minéral"
            },
            {
                "id": 2467,
                "itemName": "Maîtrise en art-thérapie"
            },
            {
                "id": 2468,
                "itemName": "Maîtrise en gestion durable des écosystèmes forestiers"
            },
            {
                "id": 2469,
                "itemName": "Maîtrise en création numérique"
            },
            {
                "id": 2470,
                "itemName": "Maîtrise en écologie"
            },
            {
                "id": 2471,
                "itemName": "Maîtrise en sciences sociales du développement (avec essai)"
            },
            {
                "id": 2472,
                "itemName": "Maîtrise en sciences sociales du développement (avec mémoire)"
            },
            {
                "id": 2473,
                "itemName": "M.A. en études du bilinguisme"
            },
            {
                "id": 2474,
                "itemName": "M.A. en littératures et cultures du monde"
            },
            {
                "id": 2475,
                "itemName": "Maîtrise en économie financière"
            },
            {
                "id": 2476,
                "itemName": "Maîtrise en études langagières"
            },
            {
                "id": 2477,
                "itemName": "Maîtrise en relations industrielles et en ressources humaines (avec essai)"
            },
            {
                "id": 2478,
                "itemName": "Maîtrise en relations industrielles et en ressources humaines (avec mémoire)"
            },
            {
                "id": 2479,
                "itemName": "Maîtrise en sciences infirmières (infirmière praticienne spécialisée en santé mentale)"
            },
            {
                "id": 2480,
                "itemName": "Maîtrise en sciences infirmières (infirmière praticienne spécialisée en soins pédiatriques)"
            },
            {
                "id": 2481,
                "itemName": "Maîtrise en sciences infirmières ((infirmière praticienne spécialisée en néonatalogie)"
            },
            {
                "id": 2482,
                "itemName": "Maîtrise en psychoéducation (avec mémoire)"
            },
            {
                "id": 2483,
                "itemName": "Maîtrise en psychoéducation (avec stage)"
            },
            {
                "id": 2484,
                "itemName": "Maîtrise en sciences infirmières, concentration en santé mentale et en soins psychiatriques"
            },
            {
                "id": 2485,
                "itemName": "Maîtrise en travail social (avec mémoire)"
            },
            {
                "id": 2486,
                "itemName": "Maîtrise en psychoéducation (avec mémoire et stage)"
            },
            {
                "id": 2487,
                "itemName": "Maîtrise en sciences infirmières (avec mémoire)"
            },
            {
                "id": 2488,
                "itemName": "Maîtrise en gestion de projet (avec mémoire)"
            },
            {
                "id": 2489,
                "itemName": "Maîtrise en travail social (avec stage)"
            },
            {
                "id": 2490,
                "itemName": "Maîtrise en éducation (avec mémoire) (M.A.)"
            },
            {
                "id": 2491,
                "itemName": "Maîtrise en sciences infirmières (profil clinique)"
            },
            {
                "id": 2492,
                "itemName": "Maîtrise en administration des affaires (MBA) (avec essai, cheminement régulier) (concentration en comptabilité)"
            },
            {
                "id": 2493,
                "itemName": "Maîtrise en éducation (avec essai ou rapport de stage) (M.Ed.)"
            },
            {
                "id": 2494,
                "itemName": "Maîtrise en sciences et technologies de l'information (profil mémoire)"
            },
            {
                "id": 2495,
                "itemName": "Maîtrise en sciences et technologies de l'information (profil professionnel)"
            },
            {
                "id": 2496,
                "itemName": "Maîtrise en administration des affaires (MBA) (avec essai, cheminement professionnel)"
            },
            {
                "id": 2497,
                "itemName": "Maîtrise en administration des affaires (MBA) (avec essai, cheminement régulier)"
            },
            {
                "id": 2498,
                "itemName": "Maîtrise en administration des affaires (MBA) (avec mémoire, cheminement régulier)"
            },
            {
                "id": 2499,
                "itemName": "Maîtrise en muséologie et pratique des arts (concentration muséologie) (avec mémoire) et (sans mémoire)"
            },
            {
                "id": 2500,
                "itemName": "Maîtrise en muséologie et pratique des arts (concentration pratiques des arts) (avec mémoire) et (sans mémoire)"
            },
            {
                "id": 2501,
                "itemName": "Maîtrise en éducation concentration en formation à distance"
            },
            {
                "id": 2502,
                "itemName": "Maîtrise en éducation concentration en technologie éducative"
            },
            {
                "id": 2503,
                "itemName": "Maîtrise par cumul en technologie éducative"
            },
            {
                "id": 2504,
                "itemName": "Maîtrise ès sciences (technologie de l'information)"
            },
            {
                "id": 2505,
                "itemName": "Maîtrise par cumul en santé mentale"
            },
            {
                "id": 2506,
                "itemName": "Master of Indigenous Social Work (MISW)"
            },
            {
                "id": 2507,
                "itemName": "Special Case Masters in Indigenous Linguistics"
            }
        ]
        return masterList
    }
}