<div class="row" [formGroup]="serviceFormParent">
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Admission</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="admission" formControlName="admission"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Banking (Required for Study Permit)</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="banking" formControlName="banking"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Immigration support (legal and clerical- Study
        Permit)</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="immigration" formControlName="immigration"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Accommodation near your University/College</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="accommodation" formControlName="accommodation"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Food catering service (Available in selected
        cities)</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="food" formControlName="food"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Financing for your education (Loans)</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="financing" formControlName="financing"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Travel arrangements (flights)</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="travel" formControlName="travel"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Local conveyance (Airport to University)?</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="localConveyance" formControlName="localConveyance"><span
            class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Local Sim card</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="cellInternet" formControlName="cellInternet"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Part time Student jobs</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="studentJob" formControlName="studentJob"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Tutoring (English or course specific)</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="tutoring" formControlName="tutoring"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Medical Insurance (Highly Recommended)</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="medicalInsurance" formControlName="medicalInsurance"><span
            class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Connect with other students travelling to the same
        university/college</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="peernetwork" formControlName="peernetwork"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Local tours, sightseeing and networking events (get
        familiar with the town and ecosystem during your holidays)</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="holidayEvents" formControlName="holidayEvents"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="media">
      <label class="col-form-label m-r-10">Permanent Residence Immigration Application</label>
      <div class="media-body text-right">
        <label class="switch">
          <input type="checkbox" name="primmigration" formControlName="primmigration"><span class="switch-state"></span>
        </label>
      </div>
    </div>
  </div>
</div>
<div class="text-center">
  <div class="media selectAll">
    <div class="checkbox checkbox-dark m-squar">
      <input [checked]="selectAllServiceValue" (click)="selectAllService()" id="insurance" type="checkbox">
      <label for="insurance">Select All Services</label>
    </div>
  </div>
</div>
