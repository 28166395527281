<div class="dashboard-box">

  <app-navbar></app-navbar>

  <app-sidebar></app-sidebar>

  <div class="dashboard-body">
    <div class="container-fluid">
      <div class="page-header banking">
        <div class="row">
          <div class="col-lg-12 main-header">
            <h2>Banking</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="top-box m-space">
        <app-banking-questions *ngIf="step === 0"></app-banking-questions>
        <app-banking-info *ngIf="step === 1" [bankService]="bankService"></app-banking-info>
      </div>
    </div>
    <app-site-footer></app-site-footer>

  </div>
</div>
