<div (scroll)="onScroll()">
  <router-outlet></router-outlet>
  <div class="overlay">
    <div class="wrapper">
      <div class="content">
        <header>Cookies Consent</header>
        <p>This website use cookies to ensure you get the best experience on our website.</p>
        <div class="buttons">
          <button class="item" (click)="accepted()">I understand</button>
          <a href="/privacy-policy" class="item">Learn more</a>
        </div>
      </div>
    </div>
  </div>
  <!-- <app-notification-bar></app-notification-bar> -->

  <div class="notification-bottom-bar" *ngIf="showBottomHeader">
    <p>Scholarships of up to C$1000 available. Register today to find out if you are eligible.
      <a class="glow-button" href="/register">Register Now</a>
    </p>
  </div>
</div>
