import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-food-main',
  templateUrl: './food-main.component.html',
  styleUrls: ['./food-main.component.scss']
})
export class FoodMainComponent implements OnInit {

  step: Number = 0;
  foodService;

  constructor(
    private _profile: ProfileService
  ) { }

  ngOnInit(): void {
    this._profile.getStudentDetails().subscribe((res) => {
      if (res.selectedService) {
        for (var i = 0; i < res.selectedService.servicesName.length; i++) {
          if (res.selectedService.servicesName[i].name === 'food') {
            if (res.selectedService.servicesName[i].statusStep !== 0) {
              this.step = 1
            }
          }
        }
      }
      if (res.foodService) {
        this.foodService = res.foodService;
      }
    });

  }



}
