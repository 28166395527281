import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-buy-products-dialog',
  templateUrl: './buy-products-dialog.component.html',
  styleUrls: ['./buy-products-dialog.component.scss']
})
export class BuyProductsDialogComponent implements OnInit {

  success;
  error;

  buyProdForm: FormGroup;
  loading = false;
  prod;
  safeDescription;

  constructor(
    public dialogRef: MatDialogRef<BuyProductsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _products: ProductsService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.prod = this.data.prod;
    this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(
      this.data.prod.description
    )
    this.buyProdForm = this._formBuilder.group({
      'productId': new FormControl(this.prod._id, [Validators.required]),
      'quantity': new FormControl('', [Validators.required]),
      'studentId': new FormControl(this.data.studentId, [Validators.required]),
      'address': new FormControl('', [Validators.required]),
    });
  }

  buyProdSubmit(form: FormGroup) {
    this._products.sentProductRequest(form.value).subscribe((res) => {
      this.success = 'Product Buying request sended.';
      setTimeout(() => {
        this.success = '';
        this.onDialogClose()
      }, 3000);
    },
      (err) => {
        this.error = err.error;
        setTimeout(() => {
          this.error = '';
        }, 3000);
      });
  }

  onDialogClose(): void {
    this.dialogRef.close();
  }

}
