<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1>Privacy Policy</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">Privacy Policy</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="extra-page-section">
    <div class="container">
      <div [innerHTML]="privacy"></div>
    </div>
  </div>

  <app-site-footer></app-site-footer>

</main>
