<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeInUp">STUDY PERMIT</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li><a href="/">Services</a></li>
              <li class="active">Study Permit</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-detail">
    <div class="container">
      <h2 class="wow animate__animated animate__fadeInUp">Study Permit Support</h2>
      <p class="wow animate__animated animate__fadeInUp">Going through the detailed immigration requirements and
        preparing all the documentation can sometimes be very challenging. A small error can lead to the
        rejection of your application. Spending too much time also on your application in your final exam year
        could effect your academic performance, which could affect your university admission.
        <strong>Student-Stop</strong> will
        take away all your worries from your application process to help you focus on your academic performance.
        We offer both application preparation support as well as legal services to secure your study permit.
        Our team will work with you to make your submission quick and smooth.</p>
      <p class="wow animate__animated animate__fadeInUp">Being a one stop shop for all your requirements,
        <strong>Student-Stop</strong> will support you in
        your study permit applications, keeping your end objective as our highest priority. Should you decide to make
        Canada your future home, we will be there to support you all the way.</p>

      <div class="challange-solution-section clearfix">
        <div>
          <h3>What are our legal services?</h3>
          <p>We offer a wide range of legal services customized for each student, based on
            their individual needs through our specialized partner law firm. <strong>Student-Stop</strong> can support
            you with any of the following services during your journey:</p>
          <ul>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Study Permit</li>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Post-Graduate Work Permit</li>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Permanent Residence Guidance and
              legal support</li>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Citizenship </li>
          </ul>
        </div>
        <div>
          <h3>Our services</h3>
          <p>We offer the following services:</p>
          <ul>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Application preparation support
              (without lawyer)</li>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Legal support for filling
              application (with lawyer)</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="service-inner-top-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="service-inner-content">
            <h2 class="wow animate__animated animate__fadeInUp">Why should you use <strong>Student-Stop</strong> for
              your application?</h2>
            <div class="sec-title">
              <div class="separator"><span></span></div>
            </div>
            <p class="wow animate__animated animate__fadeInUp">Reasons why clients choose us:</p>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Top immigration lawyers in Canada</h4>
              <p>We have partnered with carefully selected top immigration lawyers, who are based in Canada. From within
                Canada,
                they will know the latest and upcomming trends and can support in submitting your application. Our team
                in India
                can help in ensuring you get top advice and support from Canada, while being in the comfort of your
                home.</p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Affordable</h4>
              <p>Students are being defrauded by many immigration agents. We understand
                this problem and are here to help you access
                such services at very reasonable cost and avoid any pitfalls.
              </p>
            </div>

            <div class="features-text wow animate__animated animate__fadeInUp">
              <h4><i class="fas fa-check"></i> Reliable</h4>
              <p>Our highly experienced team both in India and Canada are here to hand-hold you and will never let you
                down.
                Your success is our success! </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="why-choose-image wow animate__animated animate__fadeInUp">
            <img class="p-5" src="/assets/images/canada-immigration.png" alt="image">
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-site-footer></app-site-footer>

</main>
