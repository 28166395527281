import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BuyProductsDialogComponent } from 'src/app/components/dialogs/buy-products-dialog/buy-products-dialog.component';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {

  dialogRef;
  products: any = [];
  searchForm: FormGroup;
  productSearch = false;
  allProducts;
  showMoreF = false;
  idClass = null;
  @Input() profile;
  @Input() requestedProducts = [];

  constructor(
    private _products: ProductsService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      'name': new FormControl('', Validators.required)
    });
    this._products.getProducts().subscribe(res => {
      this.products = res;
      this.allProducts = res;
    });
  }

  viewItems(dialog) {
    this.dialogRef = this.dialog.open(dialog, {
      data: {},
      width: '800px',
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  buyProd(prod) {
    this.dialogRef = this.dialog.open(BuyProductsDialogComponent, {
      data: { prod, studentId: this.profile._id },
      width: '800px',
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  showMore(id) {
    if (this.idClass === id) {
      this.idClass = null;
    } else {
      this.idClass = id;
    }
  }

  searchJob() {
    this.productSearch = true;
    const products = this.searchKey(this.allProducts, this.searchForm.value.name)
    this.products = products
  }

  searchKey(arr, value) {
    return arr.filter((obj) => JSON.stringify(obj).toLowerCase().includes(value.toLowerCase()))
  }

  onDialogClose(): void {
    this.dialogRef.close();
  }

}
