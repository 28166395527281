<div class="dashboard-box">

  <app-navbar></app-navbar>

  <app-sidebar></app-sidebar>

  <div class="dashboard-body">
    <div class="container-fluid">
      <div class="page-header prepare-for-canada">
        <div class="row">
          <div class="col-lg-12 main-header">
            <h2>Prepare For Canada</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">

      <div *ngIf="cellInternet === true">
        <div class="row">
          <div class="col-md-6 text">
            <h3>Call/Internet</h3>
            <p>Don't be surprised, cell phone plans in Canada are one of the most expensive
              in the world. However, because they are extremely important, this is one of
              the first things that a student gets when they arrive. The largest cell phone
              privders in Canada are Rogers and Bell. However, sometime due to cost, students
              prefer other options such as Telus, Wind, Koodo, Fido etc. Contact us if you need any advice.
              Regarding internet connection, most residences will provide wifi. If not, Rogers and Bell are
              your go to options.
            </p>
          </div>
          <div class="col-md-6">
            <img src="../../../../../../assets/images/telecom.png" alt="">
          </div>
        </div>
      </div>

      <div *ngIf="studentJob === true">
        <div class="row dark-bg">
          <div class="col-md-6">
            <img src="../../../../../../assets/images/studentjob.png" alt="">
          </div>
          <div class="col-md-6 text">
            <h3>Student Jobs</h3>
            <p>Minimum wage part-time Jobs for students are widely available. This helps students earn some
              extra
              money
              to keep themselves running. In addition to these, internships are also widely available.
              However, this
              will
              vary based on your program of study, location and university program. In case you have selected
              Student Jobs
              as an interest, we will contact you with opportunities that come up through our network.
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="localConveyance === true">
        <div class="row">
          <div class="col-md-6 text">
            <h3>Local Conveyance</h3>
            <p>There are various options available for local travel. Taxis, Uber and Lyft are widely available
              in
              major cities.
              Most cities also have a robust public transit network, consiting one or multiple modes such as
              Bus,
              Commuter Train, Subway etc.
            </p>
          </div>
          <div class="col-md-6">
            <img src="../../../../../../assets/images/local-conveyance-image.jpg" alt="">
          </div>
        </div>
      </div>

      <div *ngIf="tutoring === true">
        <div class="row dark-bg">
          <div class="col-md-6">
            <img src="../../../../../../assets/images/tutoring.jpg" alt="">
          </div>
          <div class="col-md-6 text">
            <h3>Tutoring</h3>
            <p>In case you are having trouble with certain courses, the best form of support is usually
              attending office hours with your Professor/TAs. In case that is not working well enough,
              there will be tutoring options available within your city. Most of the time, this will be
              advertised within your university/college. In case you have selected tutoring on your Student
              Stop
              profile, we will reach out to you with news on tutoring options within our networm, as they
              become
              available. </p>
          </div>
        </div>
      </div>

      <div *ngIf="peernetwork === true">
        <div class="row">
          <div class="col-md-6 text">
            <h3>Peer Network</h3>
            <p>Networking and connecting with students is more important that you think.
              This not only helps you in getting acquainted with peers with a similar
              background as you, but it also makes solving hurdles together a lot easier.
              You never know, you may end up making friends for life. Some ways to network
              include the use of facebook groups. Students from some institutions usually
              create these groups to network with other incoming students. We have taken this
              a step forward to make your life easier. In case you have selected Peer Networking
              in your <strong>Student-Stop</strong> profile, we will help you in getting connected with peers, who may
              be
              travelling
              with
              you to the same institution/city and are also insterested in networking.
            </p>
          </div>
          <div class="col-md-6">
            <img src="../../../../../../assets/images/peer-network-image.gif" alt="">
          </div>
        </div>
      </div>

      <div *ngIf="holidayEvents === true">
        <div class="row dark-bg">
          <div class="col-md-6">
            <img src="../../../../../../assets/images/holiday-events-image.jpg" alt="">
          </div>
          <div class="col-md-6 text">
            <h3>Holiday Events</h3>
            <p>Canada and the world in general is a beautiful place to explore. When you have a short
              holiday, it may not always be feasible to travel back home. In such cases, <strong>Student-Stop</strong>
              with its partners brings to you unique and exclusive learning + travelling experiences. Through
              our
              travel plans, you get to travel both within Canada and abroad. You get to learn, explore
              cities and meet with local industries. Join us in this adventure and explore the world.
            </p>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="row">
          <div class="col-md-6 text">
            <h3>Weather</h3>
            <p>Canada is a big country, where weather can vary significantly between cities. Canada has 4
              seasons,
              Fall, Winter, Spring and Summer. Usually summer is beautiful and warm, fall and spring are
              comfortable
              and winter can be freezing, snowy and cold(Vancouver is milder). For Indian standards(unless you
              come
              from the mountain regions), winters can be considered very cold and you should be prepared.
              Temperatures in some parts of Canada can dip to -40 degrees Celcius. Therefore, we would suggest
              packing good quality winter clothing, including jackets, sweaters, winter boots, beanies etc.
              However,
              do not worry if you do not find something before your trip. These items are usually easily
              available
              in most cities, including special -40 degree jackets. Still confused? Don't worry, our team can
              also
              support in helping you get the items before your trip, through our special Canada preparation
              basket(limited stocks available). Register for more details.</p>
          </div>
          <div class="col-md-6">
            <img src="../../../../../../assets/images/weather-image.jpg " alt="">
          </div>
        </div>
      </div>


    </div>

    <app-site-footer></app-site-footer>
  </div>
</div>
