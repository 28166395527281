import { RecommendedUniversityListComponent } from './pages/site/recommended-university/recommended-university-list/recommended-university-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

import { JourneyBeginComponent } from './pages/dashboard/journey-begin/journey-begin.component';
import { ProfileComponent } from './pages/dashboard/profile/profile.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { StartingDocumentsComponent } from './pages/dashboard/starting-documents/starting-documents.component';
import { PrepareForCanadaInfoComponent } from './pages/dashboard/services/prepare-for-canada/prepare-for-canada-info/prepare-for-canada-info.component';
import { HomeComponent } from './pages/site/home/home.component';
import { AboutUsComponent } from './pages/site/about-us/about-us.component';
import { ContactUsComponent } from './pages/site/contact-us/contact-us.component';
import { OurServicesComponent } from './pages/site/our-services/our-services.component';
import { AdmissionComponent } from './pages/site/services-pages/admission/admission.component';
import { StudyPermitComponent } from './pages/site/services-pages/study-permit/study-permit.component';
import { BankingComponent } from './pages/site/services-pages/banking/banking.component';
import { FoodComponent } from './pages/site/services-pages/food/food.component';
import { TravelComponent } from './pages/site/services-pages/travel/travel.component';
import { HousingComponent } from './pages/site/services-pages/housing/housing.component';
import { StudentJobComponent } from './pages/site/services-pages/student-job/student-job.component';
import { MedicalIncuranceComponent } from './pages/site/services-pages/medical-incurance/medical-incurance.component';
import { ImportantInformationComponent } from './pages/site/services-pages/important-information/important-information.component';
import { FaqsComponent } from './pages/site/faqs/faqs.component';
import { TermsComponent } from './pages/site/terms/terms.component';
import { PrivacyPolicyComponent } from './pages/site/privacy-policy/privacy-policy.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ConfirmMailComponent } from './pages/confirm-mail/confirm-mail.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { PreDepartureComponent } from './pages/dashboard/services/pre-departure/pre-departure.component';
import { ErrorPageComponent } from './pages/site/error-page/error-page.component';
import { AdvisorComponent } from './pages/site/microservices/advisor/advisor.component';
import { RecommendedFormComponent } from './pages/site/recommended-university/recommended-form/recommended-form.component';
import { JobsListComponent } from './pages/dashboard/services/jobs-listing/jobs-list/jobs-list.component';;
import { BankingMainComponent } from './pages/dashboard/services/banking/banking-main/banking-main.component';
import { TravelMainComponent } from './pages/dashboard/services/travel/travel-main/travel-main.component';
import { HousingMainComponent } from './pages/dashboard/services/housing/housing-main/housing-main.component';
import { FoodMainComponent } from './pages/dashboard/services/food/food-main/food-main.component';
import { AdmissionMainComponent } from './pages/dashboard/services/admission/admission-main/admission-main.component';
import { StudyPermitMainComponent } from './pages/dashboard/services/study-permit/study-permit-main/study-permit-main.component';
import { ArticlePageComponent } from './pages/site/articles/article-page/article-page.component';
import { AllArticlesComponent } from './pages/site/articles/all-articles/all-articles.component';
import { FeedbackFormComponent } from './pages/site/feedback-form/feedback-form.component';
import { WebinerRegistrationComponent } from './pages/site/webiner-registration/webiner-registration.component';
import { ProductsListMainComponent } from './pages/dashboard/services/products-sale/products-list-main/products-list-main.component';
import { MobilePlansComponent } from './pages/dashboard/services/mobile-plans/mobile-plans.component';
import { PermanentResidentMainComponent } from './pages/dashboard/services/permanent-resident/permanent-resident-main/permanent-resident-main.component';
import { ScholarshipApplyComponent } from './pages/site/scholarship-apply/scholarship-apply.component';

//canActivate: [AuthGuard]

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'services', component: OurServicesComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'terms-and-conditions', component: TermsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'services/admission', component: AdmissionComponent },
  { path: 'services/study-permit', component: StudyPermitComponent },
  { path: 'services/banking', component: BankingComponent },
  { path: 'services/food', component: FoodComponent },
  { path: 'services/travel', component: TravelComponent },
  { path: 'services/housing', component: HousingComponent },
  { path: 'services/student-job', component: StudentJobComponent },
  { path: 'services/medical-insurance', component: MedicalIncuranceComponent },
  { path: 'services/important-information', component: ImportantInformationComponent },
  { path: 'feedback-form', component: FeedbackFormComponent },
  { path: 'webinar', component: WebinerRegistrationComponent },
  { path: 'partner', component: AdvisorComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'confirm-mail/:id', component: ConfirmMailComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'student-university-match', component: RecommendedFormComponent },
  { path: 'student-university-match-list', component: RecommendedUniversityListComponent },
  { path: 'journey-begin', component: JourneyBeginComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'starting-documents', component: StartingDocumentsComponent, canActivate: [AuthGuard] },
  { path: 'banking', component: BankingMainComponent, canActivate: [AuthGuard] },
  { path: 'travel', component: TravelMainComponent, canActivate: [AuthGuard] },
  { path: 'housing', component: HousingMainComponent, canActivate: [AuthGuard] },
  { path: 'food', component: FoodMainComponent, canActivate: [AuthGuard] },
  { path: 'products', component: ProductsListMainComponent, canActivate: [AuthGuard] },
  { path: 'mobile-plans', component: MobilePlansComponent, canActivate: [AuthGuard] },
  { path: 'prepare-for-canada', component: PrepareForCanadaInfoComponent, canActivate: [AuthGuard] },
  { path: 'admission', component: AdmissionMainComponent, canActivate: [AuthGuard] },
  { path: 'study-permit', component: StudyPermitMainComponent, canActivate: [AuthGuard] },
  { path: 'pre-departure', component: PreDepartureComponent, canActivate: [AuthGuard] },
  { path: 'jobs', component: JobsListComponent, canActivate: [AuthGuard] },
  { path: 'permanent-resident', component: PermanentResidentMainComponent, canActivate: [AuthGuard] },
  { path: 'articles', component: AllArticlesComponent },
  { path: 'article/:title', component: ArticlePageComponent },
  // { path: 'scholarship', component: ScholarshipApplyComponent },
  { path: '**', component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
