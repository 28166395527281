<div class="dashboard-box">

  <app-navbar></app-navbar>

  <div class="dashboard-body no-banner full-page">

    <div class="container-fluid">
      <div class="page-header">
        <h2 class="dark-color">Submit Form</h2>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <ng-template #notMailBox>
            <div class="card">
              <div class="card-body">
                <mat-horizontal-stepper linear #stepper ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}"
                  labelPosition="bottom">
                  <mat-step [stepControl]="personalform">
                    <ng-template matStepLabel>Personal Details</ng-template>
                    <form [formGroup]="personalform">
                      <app-personal-stepper [personalformParent]="personalform"></app-personal-stepper>
                      <div>
                        <div class="text-center mt-2">
                          <button [disabled]="personalform.invalid" (click)="firstStepData()"
                            class="btn btn-primary btn-next" matStepperNext>Next</button>
                        </div>
                      </div>
                    </form>
                  </mat-step>

                  <mat-step>
                    <ng-template matStepLabel>Services Required</ng-template>
                    <form [formGroup]="serviceForm">
                      <app-services-stepper [serviceFormParent]="serviceForm"></app-services-stepper>
                      <div class="text-center mt-2">
                        <button class="btn btn-primary btn-next mr-2" matStepperPrevious>Back</button>
                        <button class="btn btn-primary btn-next" [disabled]="serviceForm.invalid"
                          (click)="onSubmit()">Submit</button>
                      </div>
                    </form>
                  </mat-step>

                  <!-- <mat-step [stepControl]="admissionForm">
                    <ng-template matStepLabel>Admission Details</ng-template>
                    <form [formGroup]="admissionForm">
                      <app-admission-stepper [admissionFormParent]="admissionForm"></app-admission-stepper>
                      <div class="text-center mt-2">
                        <button class="btn btn-primary btn-next mr-2" matStepperPrevious>Back</button>
                        <button class="btn btn-primary btn-next" [disabled]="admissionForm.invalid"
                          (click)="onSubmit()">Submit</button>
                      </div>
                    </form>
                  </mat-step> -->
                </mat-horizontal-stepper>
              </div>
            </div>
          </ng-template>

          <div class="mail-box" *ngIf="mailNotConfirm === true; else notMailBox">
            <div class="card">
              <div class="card-body">
                <i class="fas fa-envelope-open-text"></i>
                <div class="title">Verify your email address</div>
                <div class="description"> A confirmation email with instructions has been sent to your email address.
                  Follow the instructions to confirm your email-id and activate your account. <br>In case you have
                  not received the email within a few minutes, please check your Junk folder. </div>
                <button (click)="resendConfirm()" class="btn btn-primary mt-3">Resend Email</button>
                <ngb-alert type="success" *ngIf="successClosed" (close)="successClosed=false">{{ success }}
                </ngb-alert>
                <ngb-alert type="danger" *ngIf="errorClosed" (close)="errorClosed=false">{{ error }}</ngb-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-site-footer></app-site-footer>
  </div>
</div>
