import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _Url = environment.server;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  registerUser(user: User) {
    return this.http.post<any>(this._Url + '/auth/register', user);
  }

  loginUser(user) {
    return this.http.post<any>(this._Url + '/auth/login', user);
  }

  changePassword(data) {
    return this.http.put<any>(this._Url + '/auth/change-password', data);
  }

  forgotUser(user) {
    return this.http.post<any>(this._Url + '/auth/forgot', user, { responseType: 'text' as 'json' });
  }

  resetPassword(data) {
    return this.http.post<any>(this._Url + '/auth/password-reset', data, { responseType: 'text' as 'json' })
  }

  checkToken(token) {
    return this.http.get<any>(this._Url + '/auth/check-token/' + token);
  }

  loggedIn() {
    return !!this.cookieService.get('token')
  }

  getToken() {
    return this.cookieService.get('token')
  }
}
