import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-edit-school-dialog',
  templateUrl: './edit-school-dialog.component.html',
  styleUrls: ['./edit-school-dialog.component.scss']
})
export class EditSchoolDialogComponent implements OnInit {

  schoolEditFormData: FormGroup;
  years = [];

  totalBoardValue = '';
  subjectMarkVal = '';

  constructor(
    public dialogRef: MatDialogRef<EditSchoolDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.schoolEditFormData = new FormGroup({
      'class': new FormControl(''),
      'boardName': new FormControl('', Validators.required),
      'passedYear': new FormControl('', Validators.required),
      'subjects': this.fb.array([]),
      'percentage': new FormControl('', [Validators.required, Validators.maxLength(3)])
    });

    this.data.editFormDetails.subjects.forEach(t => {
      var subject: FormGroup = this.createItem();
      this.subjects().push(subject);
    });

    this.schoolEditFormData.patchValue(this.data.editFormDetails)
    for (var year = 1960; year <= 2030; year++) {
      this.years.push(year);
    }
  }

  boardSelect(value) {
    if (value === "CBSE" || "ICSE") {
      this.schoolEditFormData.controls.percentage.enable();
      this.schoolEditFormData.controls.percentage.setValidators([Validators.required, Validators.maxLength(3)]);
      this.totalBoardValue = '/100';
      this.subjectMarkVal = '';
    }
    if (value === "IB Diploma") {
      this.schoolEditFormData.controls.percentage.enable();
      this.schoolEditFormData.controls.percentage.setValidators([Validators.required, Validators.maxLength(1)]);
      this.totalBoardValue = '/45';
      this.subjectMarkVal = '/7';
    }
    if (value === "IB Certificate") {
      this.schoolEditFormData.controls.percentage.disable();
      this.totalBoardValue = '';
      this.subjectMarkVal = '/7';
    }
    if (value === "State Board") {
      this.schoolEditFormData.controls.percentage.enable();
      this.totalBoardValue = '/100';
      this.subjectMarkVal = '';
    }
  }

  editGrade(form) {
    this.dialogRef.close(form.value);
    this.schoolEditFormData.reset();
  }

  // Form Functions
  subjects(): FormArray {
    return this.schoolEditFormData.get('subjects') as FormArray
  }

  addSubject(): void {
    this.subjects().push(this.createItem());
  }

  removeSubject(index): void {
    this.subjects().removeAt(index)
  }

  createItem(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      marks: ['', Validators.required],
    });
  }

  closeDegreeDialog() {
    this.subjects().controls = [];
    this.dialogRef.close();
  }
}
