<main class="box-container">
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <article class='content'>
    <p>Damnit stranger,</p>
    <p>You got lost in the <strong>404</strong> galaxy.</p>
    <p>
      <a href="/">Go back to Home Page.</a>
    </p>
  </article>
</main>
