<div (click)="openAppSummaryDialog()" class="study-btn">My Application Summary</div>

<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <h6>
      Provide information about all institutions where you have registered in 1 or more secondary or
      postsecondary courses. Institutions include, but are not limited to, regular day schools, night schools,
      summer schools, virtual schools.
    </h6>
  </div>
</div>

<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">

    <h2>Secondary and Post Secondary Institutes (High Schools, Colleges, Universities) attended</h2>
    <div class="table-responsive">
      <table class="table table-style-1 small-text">
        <thead>
          <tr>
            <th>#</th>
            <th>Institute Type</th>
            <th>Attended From</th>
            <th>Attended To</th>
            <th>Country</th>
            <th>Name Of Institution/School/College</th>
            <th>Degree/Diploma/Certificate Name</th>
            <th>Graduation Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="tr-shadow" *ngFor="let item of studyList; let i = index">
            <td>{{i + 1}}</td>
            <td>{{item?.studyType[0].itemName}}</td>
            <td>{{item?.attendedFrom | date}}</td>
            <td>{{item?.attendedTo | date}}</td>
            <td>{{item?.country[0].itemName}}</td>
            <td>{{item?.nameOfInstitute}}</td>
            <td>{{item?.degreeName}}</td>
            <td>{{item?.indicateDate | date}}</td>
            <td>
              <div class="table-data-feature">
                <button (click)="editIntituteDialog(i)" class="item">
                  <i class="fas fa-edit"></i>
                </button>
                <button (click)="deleteItem(i, 'studyList')" class="item">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center mt-3 mb-4">
      <button (click)="addInstitute()" class="btn btn-square btn-primary">Add New Institute</button>
    </div>

    <hr>

    <!-- College/School -->
    <h2>Transcript/Grade Information</h2>
    <div class="overflow">
      <table class="table table-style-1 small-text">
        <thead>
          <tr>
            <th>#</th>
            <th>Class</th>
            <th>Board Name</th>
            <th>Year of Completion</th>
            <th>Subjects</th>
            <th>Cumulative Percentage/Grade</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="tr-shadow" *ngFor="let item of gradeList; let i = index">
            <td>{{i + 1}}</td>
            <td>{{item?.class}}</td>
            <td>{{item?.boardName}}</td>
            <td>{{item?.passedYear}}</td>
            <td>
              <span *ngFor="let subjects of item.subjects">
                <span class="info-btn">{{subjects.name}}: {{subjects.marks}}</span>
              </span>
            </td>
            <td>{{item?.percentage}}</td>
            <td>
              <div class="table-data-feature">
                <button (click)="editDegreeDialog(i)" class="item">
                  <i class="fas fa-edit"></i>
                </button>
                <button (click)="deleteItem(i, 'gradeList')" class="item">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-center mt-3 mb-3">
      <button (click)="openDegreeForm()" class="btn btn-square btn-primary">Add New School</button>
    </div>

    <hr>

    <h2>English Test</h2>

    <div class="form-group">
      <label for="">Have you taken any English Language test?</label>
      <select class="form-control" [(ngModel)]="testValue" (change)="selectTestOption($event.target.value)">
        <option value="">Select Option</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
    </div>

    <div *ngIf="showTestTable">
      <div class="overflow">
        <table class="table table-style-1 small-text">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Passed Year</th>
              <th>Reading Score</th>
              <th>Writing Score</th>
              <th>Speaking Score</th>
              <th>Listening Score</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="tr-shadow" *ngFor="let englishTest of englishTestList; let i = index">
              <td>{{i + 1}}</td>
              <td>{{englishTest?.testName[0].itemName}}</td>
              <td>{{englishTest?.passedYear}}</td>
              <td>{{englishTest?.reading}}</td>
              <td>{{englishTest?.writing}}</td>
              <td>{{englishTest?.speaking}}</td>
              <td>{{englishTest?.listening}}</td>
              <td>
                <div class="table-data-feature">
                  <!-- <button (click)="editDegreeDialog(i)" class="item">
                    <i class="fas fa-edit"></i>
                  </button> -->
                  <button (click)="deleteItem(i, 'englishTest')" class="item">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="text-center mt-3 mb-3">
        <button (click)="openEnglishTestForm()" class="btn btn-square btn-primary">Add New English Test</button>
      </div>
    </div>

    <div class="text-center mt-5">
      <button [disabled]="!nextBtnValidation || !submitValid" (click)="onSubmit()"
        class="btn btn-square btn-primary">Next</button>
    </div>
  </div>
</div>
