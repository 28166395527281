<div (click)="openAppSummaryDialog()" class="study-btn">My Application Summary</div>


<div class="card wow animate__animated animate__fadeInUp">
  <!-- <div class="card-body text-center" *ngIf="taxInvoice === true">
    <h3 class="mt-2 mb-3">Academic guidance and application preparation support</h3>
    <h6>Our team will be contact you soon regarding for Academic guidance and application preparation support</h6>
  </div>
 -->

  <div class="card-body">
    <h3 class="mt-2 mb-4">Academic guidance and application preparation support</h3>

    <table class="price-table mb-5">
      <tbody>
        <tr class="price-table-head">
          <td>Sr. No</td>
          <td>Description</td>
          <td>Amount</td>
        </tr>
        <tr *ngIf="partnerCollegesList.length !== 0">
          <td>1</td>
          <td>Academic guidance and application preparation support fee for Partner Universities & Colleges</td>
          <td>${{partner_fee}}</td>
        </tr>
        <tr *ngIf="nonPartnerCollegesList.length !== 0">
          <td *ngIf="partnerCollegesList.length === 0">1</td>
          <td *ngIf="partnerCollegesList.length !== 0">2</td>
          <td>Academic guidance and application preparation support fee for Non-Partner Universities & Colleges</td>
          <td>${{non_partner_fee}}</td>
        </tr>
        <tr class="big-space">
          <td></td>
          <td><strong>Total Payable Fee</strong></td>
          <td class="price"><span>${{totalPay}}</span></td>
        </tr>
      </tbody>
    </table>

    <h6 *ngIf="partnerCollegesList.length !== 0">Selected Partner Universities & Colleges List: </h6>
    <table class="price-table mb-5" *ngIf="partnerCollegesList.length !== 0">
      <tbody>
        <tr class="price-table-head">
          <td>Sr. No</td>
          <td>Name</td>
        </tr>
        <tr *ngFor="let partnerCollege of partnerCollegesList; let i = index">
          <td>{{i + 1}}</td>
          <td *ngIf="partnerCollege.universityName" class="strong">{{partnerCollege.universityName}} (University)</td>
          <td *ngIf="!partnerCollege.universityName" class="strong">{{partnerCollege.collegeName}} (College)</td>
        </tr>
      </tbody>
    </table>

    <h6 *ngIf="nonPartnerCollegesList.length !== 0">Selected Non-Partner Universities & Colleges List: </h6>
    <table class="price-table" *ngIf="nonPartnerCollegesList.length !== 0">
      <tbody>
        <tr class="price-table-head">
          <td>Sr. No</td>
          <td>Name</td>
        </tr>
        <tr *ngFor="let nonPartnerCollege of nonPartnerCollegesList; let i = index">
          <td>{{i + 1}}</td>
          <td *ngIf="nonPartnerCollege.universityName" class="strong">{{nonPartnerCollege.universityName}} (University)
          </td>
          <td *ngIf="!nonPartnerCollege.universityName" class="strong">{{nonPartnerCollege.collegeName}} (College)</td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="taxInvoice === false">
      <form [formGroup]="checkBoxForm">
        <div class="form-check my-4">
          <input class="form-check-input" type="checkbox" formControlName="checkboxinput" id="flexCheckChecked">
          <label class="form-check-label" for="flexCheckChecked">
            I authorize Student-Stop registered as Grintex Technologies Inc. under the laws of Ontario, Canada to submit
            applications on my behalf to all the universities and colleges as selected by me on Student-Stop Online and
            Offline.
          </label>
        </div>

        <div class="form-check my-4">
          <input class="form-check-input" type="checkbox" formControlName="checkboxinput2" id="flexCheckChecked2">
          <label class=" form-check-label" for="flexCheckChecked2">I hereby certify that the information provided is
            true and correct and the documents uploaded or transmitted to Student-Stop by any other method are
            original or scanned copies of the original. I also understand that any dishonesty may render for refusal
            of my applications and can have other serious consequences.</label>
        </div>
        <div class="select-btn text-center mt-3">
          <button class="pay-btn" (click)="payAmount()">Pay ${{finalTotalPay}}</button>
        </div>
        <p class="text-center mt-2"><strong>6.5% transaction fee included</strong></p>
        <p *ngIf="formError" class="error">Please Accept the all conditions which given above before payment.</p>
      </form>

      <p class="mt-3"><strong>Note: </strong> Student-Stop (Grintex Technologies Inc.) cannot guarantee
        admission into any institution. Each institution will assess the application and determine whether to offer
        admission based on the assessment.</p>
      <p class="mt-3"><strong>Note: </strong> All fees paid are non-refundable.</p>
    </div>

    <div *ngIf="taxInvoice === true" class="text-center mt-4">
      <h4>Our team will be contact you soon for payment process.</h4>
    </div>
  </div>
</div>
