import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { SelectOptionData } from '../../../../../sharedata/selectOptionData';
import { environment } from 'src/environments/environment';
import { HouseService } from 'src/app/services/house.service';
import { LabelType, Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-housing-info',
  templateUrl: './housing-info.component.html',
  styleUrls: ['./housing-info.component.scss']
})
export class HousingInfoComponent implements OnInit {

  @Input() housingService;

  galleryOptions: NgxGalleryOptions[];
  galleryOptions2: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  public isCollapsed = -1;
  isCollapsedOne = false;

  housesList = [];
  dialogRef;
  editHousingForm: FormGroup;

  url;
  canadaCity;
  universityList;
  collegeList;
  allUniversityAndCollege;
  years = [];

  universitySettings = {
    singleSelection: true,
    text: "Select University Or College",
    selectAllText: false,
    unSelectAllText: false,
    enableSearchFilter: true,
    lazyLoading: true
  };

  minDate = new Date();
  minValue: number = 450;
  maxValue: number = 1200;
  options: Options = {
    floor: 200,
    ceil: 1500,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min Price:</b> C$ ' + value;
        case LabelType.High:
          return '<b>Max Price:</b> C$ ' + value;
        default:
          return 'C$ ' + value;
      }
    }
  };

  constructor(
    private _house: HouseService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '100%',
        height: '220px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        thumbnails: false
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '220px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnails: false
      },
      // max-width 400
      {
        breakpoint: 400,
        height: '230px',
      }
    ];

    this.galleryOptions2 = [
      {
        width: '100%',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        thumbnails: true
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnails: true
      },
      // max-width 400
      {
        breakpoint: 400,
        height: '230px',
      }
    ];
    this.url = environment.url;

    this.editHousingForm = new FormGroup({
      'university': new FormControl([this.housingService.university], Validators.required),
      'city': new FormControl(this.housingService.city, Validators.required),
      'startSemesterYear': new FormControl(this.housingService.startSemesterYear, [Validators.required]),
      'semesterType': new FormControl(this.housingService.semesterType, [Validators.required]),
    });

    if (this.housingService.budgetRange) {
      this.minValue = this.housingService.budgetRange.minimum;
      this.maxValue = this.housingService.budgetRange.maximum;
    }

    if (this.housingService.selectedHouse) {
      this._house.getHouseById(this.housingService.selectedHouse).subscribe(res => {
        this.housingService.selectedHouse = res;
        var imagesList = [];
        for (let img of this.housingService.selectedHouse.image) {
          imagesList.push({
            small: img,
            medium: img,
            big: img
          });
          if (imagesList.length === this.housingService.selectedHouse.image.length) {
            this.housingService.selectedHouse.image = imagesList;
          }
        }
      })
    }

    this._house.getAvailableHouses(this.housingService).subscribe(
      data => {
        this.housesList = JSON.parse(data);
        for (let house of this.housesList) {
          var imagesList = [];
          house.selectPlan = 0;
          for (let img of house.image) {
            imagesList.push({
              small: img,
              medium: img,
              big: img
            });
            if (imagesList.length === house.image.length) {
              house.image = imagesList;
            }
          }
        }
      },
      err => {
        console.log(err)
      }
    )

    const currentYear = new Date().getFullYear()
    for (var year = currentYear; year <= currentYear + 3; year++) {
      this.years.push(year);
    }

    const serviceData = new SelectOptionData;
    this.canadaCity = serviceData.canadaCity();
    this.universityList = serviceData.university();
    this.collegeList = serviceData.collegeList();

    this.allUniversityAndCollege = this.universityList.concat(this.collegeList);

    this.allUniversityAndCollege.sort(function (a, b) {
      if (a.itemName < b.itemName) { return -1; }
      if (a.itemName > b.itemName) { return 1; }
      return 0;
    })
  }

  selectHouse(index) {
    const selectHouse = this.housesList[index];
    const data = {
      id: selectHouse._id,
      selectedPlan: selectHouse.selectPlan
    }

    this._house.selectHouse(data).subscribe(
      res => {
        window.location.reload();
      },
      err => {
        console.log(err)
      }
    )
  }

  editDialog(dialog) {
    this.dialogRef = this.dialog.open(dialog, {
      width: '1000px',
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  closeDegreeDialog() {
    this.dialogRef.close();
  }

  submitEditHouse(form) {
    const data = {
      ...form.value,
      budgetRange: {
        minimum: this.minValue,
        maximum: this.maxValue
      }
    }
    this._house.editHousingSubmit(data).subscribe(
      res => {
        window.location.reload()
      }
    )
  }

  selectUniversity(item) {
    const city = item[0].city;
    if (city) {
      this.editHousingForm.patchValue({
        city
      });
    }
  }

  planChange(value, i) {
    for (let planIndex in this.housesList[i].plans) {
      if (this.housesList[i].plans[planIndex].roomType === value) {
        this.housesList[i].selectPlan = +planIndex;
      }
    }
  }

}
