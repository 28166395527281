<div class="main">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeIn">Article</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">Article</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="article-author-item-area article-author-item-layout-1 pb-20" *ngIf="article">
    <div class="container-fluid">
      <div class="row">
        <div class=" col-lg-12">
          <div class="article-author-item mb-40">
            <div class="article-content">
              <div class="article-meta-item">
                <div class="article-meta-categories">
                  <a href="">{{article.category}}</a>
                </div>
                <div class="article-meta-date">
                  <span><i class="fas fa-calendar-alt"></i>{{article.publishon | date: 'mediumDate'}}</span>
                </div>
              </div>
              <img class="mt-2" [src]="article.image" alt="">
              <h3 class="article-title">{{article.title}}</h3>
            </div>
            <div class="row">
              <div class=" col-md-12">
                <div class="article-details-box" [innerHTML]="article.description"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-site-footer></app-site-footer>

</div>
