<div class="card info-container" mat-dialog-content>
  <div class="card-header">Important Information
    <button class="close" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">

    <div class="faq-wrapper">
      <ngb-accordion class="mb-0" #a="ngbAccordion">
        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                1. Document Checklist ***IMPORTANT
                <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <h5>Before you apply for a study permit, you need: </h5>
            <ul>
              <li>An acceptance letter from a designated learning institution (DLI)</li>
              <li>A valid passport</li>
              <li>Proof of funds showing you can support yourself, and any family members who come with you,
                while you’re in Canada.(Check our Banking section for more details).</li>
              <li>A valid credit or debit card to pay the fees.(Note: Alternatively, you can pay us and we can
                pay the online fees)</li>

            </ul>

            <hr>

            <h5 class="mt-3">When you apply, you will need to submit: </h5>
            <ul>
              <li><strong>The following application forms</strong> (Note: If you choose our services, we will
                help you in preparing this): </li>
              <ol>
                <li>IMM 1294</li>
                <li>IMM 5707(if you are 18 years or older)/IMM 5464 (If you are 17 years or younger)</li>
                <li>IMM 5409</li>
                <li>IMM 5475 & IMM 5476 (If you are using our legal services)</li>
              </ol>
              <li><strong>All Fees</strong> in acceptable form</li>
              <li><strong>Original, valid passport,</strong> and a photocopy of the biodata (photo) page.
                Include all cancelled or
                expired passports, if applicable. The passport should be valid for the duration of proposed
                study,
                as the validity of the study permit cannot exceed the validity of the passport. Photocopy
                required of pages
                showing the Passport Number, Issuance and Expiry Date, Your Photo, Name, Date and Place of
                Birth.
              </li>
              <li><strong>Study Permit Questionnaire</strong></li>
              <li><strong>Two photographs</strong> meeting the photograph specifications for yourself and each
                family
                member requesting a visa. The physical photographs should have your name printed on the back,
                be
                dated
                and taken in the past 6 months.(Note: Photos are not required if you need to give biometrics)
              </li>
              <li><strong>Letter of Acceptance</strong> from a designated learining institution in Canada,
                stating the
                following:
                <ol class="mt-2">
                  <li>unconditional admission;</li>
                  <li>course title;</li>
                  <li>duration of study;</li>
                  <li>level of study;</li>
                  <li>start date (multiple start dates, if applicable) and latest possible date of admission.
                  <li>End date</li>
                  <li>Date to register</li>
                  <li>Course fee</li>

                </ol>
              </li>
              <li>Confirmation letter from MIDI- Certificat d’acceptation du Québec (only if studying in
                Quebec)
              </li>
              <li>Proof of financial support (Check our GIC section below and Banking section for more detail)
              </li>

              <li>Note: Additional documents may be required by the visa office. You will most likely be
                required to get a medical exam from an authorized panel physician.
                If you are an Indian citizen applying for a Study Permit
                and have not given biometrics in the past, you will be required to(US valid Green card holders
                are exempt from this). Instructions
                will be provided by CIC Canada after you pay the Biometric fees. You may also be required to
                attend an interview. </li>
            </ul>

            <hr>

            <h5 class="mt-3">The following documents are STRONGLY recommended: </h5>
            <ul>
              <li><strong>Test results</strong> taken within the last 24 months to demonstrate ability in
                language of proposed
                study</li>
              <li>Photocopy of the most recent <strong>diploma or leaving</strong> certificate, and most
                recent
                mark sheets (for
                entire program; consolidated mark sheets are NOT acceptable)
              </li>
              <li><strong>Other educational certificates</strong> diplomas or degrees (including all
                marksheets)
              </li>
              <li>Preferred Option for Proof of Funds: Evidence of purchase of a special Guaranteed Investment
                Certificate (GIC) from a participating Canadian financial institution, in the amount of
                $10,000
                CAD to cover living expenses for your first year in Canada and evidence of payment for your
                tuition.
              </li>
              <li>In the alternative, you can fulfill the financial requirement by submitting a copy of the
                receipt for your tuition fee) AND a copy of an Educational Loan from an Indian Chartered Bank
                equivalent to
                $10 000 CAD.</li>
              <li><strong>Income Tax Returns</strong> for you and your parents for the past two years</li>
              <li><strong>Scholarships or any financial assistance or award</strong> from a government or
                other
                funding
                agency must be presented on official letter stating the full amount of the award, its duration
                and any governing conditions.</li>
              <li><strong>Reference letter</strong> from the individuals providing you financial assistance
                for
                your program. The
                letter must indicate their occupation and relationship to you, the number of dependents they
                have
                and their full contact details. You should also provide a letter from the employer of the
                individuals
                providing you financial assistance for your program, clearly indicating employer contact
                details
                and full street address of businesslocation.</li>
            </ul>

            <hr>

            <h5 class="mt-3">If applicable, you must also submit:</h5>
            <ul>
              <li>Photocopy of Marriage Certificate/License</li>
              <li><strong>Proof of employment</strong> - letter from your current or past employer and pay
                slips
                (last 3 months).
                Employment letter dated within 2 months of the application indicating occupation, job
                description, position, salary, allowances, years of employment, and authorized leave dates.
              </li>
              <li><strong>Previous passports</strong> showing travel history.</li>
              <li><strong>Birth Certificates</strong> for each accompanying dependent child.</li>
            </ul>

            <hr>

            <h5 class="mt-3">Minor children must submit the following documents:</h5>
            <ul>
              <li><strong>Minors travelling alone</strong> or a person other than their parents or legal
                guardians should have a [
                letter of authorization signed by both parents and legal guardians. It should also include the
                name
                of the adult who will be responsible for the children in Canada.</li>
              <li><strong>Minors travelling with only one parent or legal guardian</strong> should have a
                letter
                of authorization from the non-accompanying parent or guardian.</li>
              <li><strong>A copy of the page of the passport</strong> or other government-issued
                identification
                document
                showing the name, photo and signature of each non-accompanying parent should be
                submitted with the application.</li>
              <li><strong>These documents should be available for presentation at the Canadian Port of
                  Entry.</strong></li>
            </ul>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                2. How to obtain a guaranteed investment certificate(GIC)
                <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>According to Citizenship and Immigration Canada guidelines, you will require approximately
              $10,000 CAD per year, not including tuition fees, to cover your living expenses. This
              requirementis
              fulfilled through the purchase of a participating GIC, and evidence that you have paid your
              tuition.
            </p>
            <p>Check our banking section for more details. Through our partner authorized bank, we can help
              you
              in meeting
              this criteria in a smooth and trustworthy manner.
            </p>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                3. Student Direct Stream Eligibility criteria IRCC
                <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>To be eligible for faster processing through the Student Direct Stream, you must:</p>
            <ul>
              <li>be a legal resident living in 1 of the following countries:
                <ul>
                  <li>China</li>
                  <li>India</li>
                  <li>Morocco</li>
                  <li>Pakistan</li>
                  <li>the Philippines</li>
                  <li>Senegal</li>
                  <li>Vietnam</li>
                </ul>
              </li>
              <li>have an acceptance letter from a post-secondary
                <a
                  href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/student-direct-stream/apply.html#instructions">designated
                  learning institution</a>
              </li>
              <li>prove that you’ve paid the tuition fees for your first year of study</li>
              <li>live outside of Canada when you apply</li>
              <li>have a
                <a
                  href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/student-direct-stream/eligibility.html#GIC">Guaranteed
                  Investment Certificate</a> (GIC) of CAN$10,000
              </li>
              <li>have a <span lang="fr">Certificat d’acceptation du Québec</span> (CAQ) from the <span
                  lang="fr">Ministère de l’Immigration, de la Diversité et de l’Inclusion</span>
                <ul>
                  <li>This is only if you’re planning to study in Quebec</li>
                </ul>
              </li>
              <li>get a
                <a
                  href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/student-direct-stream/eligibility.html#medical">medical
                  exam</a> before you apply (if you need one)
              </li>
              <li>get a
                <a
                  href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/student-direct-stream/eligibility.html#police">police
                  certificate</a> before you apply (if you need one)
              </li>
              <li>have your most recent secondary or post-secondary school transcript(s)</li>
              <li>have a language test result that shows:
                <ul>
                  <li>a score of 6.0 or higher in each skill (reading, writing, speaking and listening) on the
                    <a href="http://www.ielts.org/" rel="external">International English Language Testing
                      System</a> (IELTS) or
                  </li>
                  <li>a <span lang="fr">Test d’évaluation de français</span> (TEF) score that is equal to a
                    Canadian Language Benchmark (CLB) score of at least 7 in each skill (reading, writing,
                    speaking and listening)</li>
                </ul>
              </li>
            </ul>
            <p>Depending on where you’re applying from, you may also need to provide other documents. Make
              sure
              you include all the
              <a
                href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/student-direct-stream/apply.html#instructions">documents
                required by the visa office</a> that processes your application.
            </p>
            <p><strong>We may ask you to give additional documents to support your application. In this
                situation, your application will take longer to process.</strong></p>
          </ng-template>
        </ngb-panel>

        <ngb-panel class="card-header">
          <ng-template ngbPanelHeader>
            <h5 class="mb-0">
              <button class="btn-link collapsed" ngbPanelToggle>
                4. Medical Exam
                <span>
                  <i class="fas fa-plus-circle"></i>
                  <i class="fas fa-minus-circle"></i> </span>
              </button>
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>Depending on where you’re applying from, you may need to get a medical exam or police
              certificate. To get faster processing through the Student Direct Stream, you must include these
              with your application (if you need them). If you don’t, we won’t process your application
              faster.
            </p>
            <h4>Who needs a medical exam</h4>
            <p>You may need to get a medical exam if:</p>
            <ul>
              <li>you lived or travelled in
                <a
                  href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/medical-police/medical-exams/requirements-temporary-residents/country-requirements.html">certain
                  countries or territories</a> for 6 or more months in the year before you come to Canada, or
              </li>
              <li>you plan on studying or working in:
                <ul>
                  <li>the health field</li>
                  <li>primary or secondary education, or</li>
                  <li>child or elder care</li>
                </ul>
              </li>
            </ul>
            <p>To get a medical exam, you must see a
              <a href="http://www.cic.gc.ca/pp-md/pp-list.aspx">doctor that has been approved by IRCC</a>.
            </p>
            <p>
              <a style="text-decoration: underline;"
                href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/medical-police/medical-exams.html">Find
                out more about medical exams</a>.
            </p>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</div>
