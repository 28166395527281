<form #services="ngForm" (ngSubmit)="updateServices()" novalidate>
  <div class="row">
    <div class="col-md-6" *ngFor="let services of userSelectedService?.servicesName">
      <div class="profile-card-inf__title mt-2">
        <div class="row">
          <div class="col-8 col-md-8 my-auto">
            <div class="profile-card-inf__item">{{services.heading}}:</div>
          </div>
          <div class="col-4 col-md-4">
            <label class="switch my-auto">
              <input type="checkbox" [id]="services.name" [name]="services.name" [checked]='services.need'
                (change)="getCheckboxValues($event,services.name)">
              <span class="switch-state"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mt-3">
    <button type="submit" class="btn btn-square btn-primary">Update</button>
    <div (click)="editServicePopup()" class="btn btn-square btn-danger ml-2">Cancel</div>
  </div>
</form>
