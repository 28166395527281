import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SelectOptionData } from 'src/app/sharedata/selectOptionData';
import { FoodService } from './../../../../../services/food.service';

@Component({
  selector: 'app-food-form',
  templateUrl: './food-form.component.html',
  styleUrls: ['./food-form.component.scss']
})
export class FoodFormComponent implements OnInit {

  foodForm: FormGroup;
  profile;

  canadaCity;
  universityList;
  collegeList;
  allUniversityAndCollege;

  years = [];
  minDate = new Date();
  universitySettings = {
    singleSelection: true,
    text: "Select Universite And College",
    selectAllText: null,
    unSelectAllText: null,
    enableSearchFilter: true,
    lazyLoading: true
  };

  constructor(
    private _food: FoodService,
  ) { }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear()
    for (var year = currentYear; year <= currentYear + 3; year++) {
      this.years.push(year);
    }

    this.foodForm = new FormGroup({
      'university': new FormControl('', Validators.required),
      'city': new FormControl('', Validators.required),
      'startSemesterYear': new FormControl('', [Validators.required]),
      'semesterType': new FormControl('', [Validators.required]),
      'dietType': new FormControl('', [Validators.required]),
      'cuisinePreference': new FormControl('', [Validators.required]),
      'allergies': new FormControl('', []),
    });

    const serviceData = new SelectOptionData;
    this.canadaCity = serviceData.canadaCity();
    this.universityList = serviceData.university();
    this.collegeList = serviceData.collegeList();

    this.allUniversityAndCollege = this.universityList.concat(this.collegeList);

    this.allUniversityAndCollege.sort(function (a, b) {
      if (a.itemName < b.itemName) { return -1; }
      if (a.itemName > b.itemName) { return 1; }
      return 0;
    })
  }

  submitService() {
    this._food.foodSubmit(this.foodForm.value).subscribe(
      res => {
        window.location.reload()
      }
    )
  }

  selectUniversity(item) {
    const city = item[0].city;
    if (city) {
      this.foodForm.patchValue({
        city
      });
    }
  }


}
