<div class="card" mat-dialog-content>
  <div class="card-header">Transcript/Grade Information
    <button class="close" aria-label="Close" (click)="closeDegreeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <form [formGroup]="schoolEditFormData" (ngSubmit)="editGrade(schoolEditFormData)" novalidate novalidate>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Class/Grade/Year*</label>
            <input class="form-control" placeholder="Enter Class/Grade/Year" type="text" formControlName="class"
              name="class" required>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Board Name*</label>
            <select class="form-control" type="text" formControlName="boardName" name="boardName" required
              (change)="boardSelect($event.target.value)">
              <option value="" disabled selected>Select Board</option>
              <option value="CBSE">CBSE</option>
              <option value="ICSE">ICSE</option>
              <option value="IB Diploma">IB Diploma</option>
              <option value="IB Certificate">IB Certificate</option>
              <option value="State Board">State Board</option>
              <option value="Under Graduate">Under Graduate</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Year of completion*</label>
            <select class="form-control" placeholder="Passed Year" type="text" formControlName="passedYear"
              name="passedYear" required>
              <option *ngFor="let year of years" [value]="year">
                {{year}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <label>Subjects</label>
          <div formArrayName="subjects"
            *ngFor="let item of schoolEditFormData.get('subjects')['controls']; let i = index;">
            <div class="row" [formGroupName]="i">
              <div class="col-md-5">
                <div class="form-group">
                  <input class="form-control" placeholder="Enter Subject Name" type="text" formControlName="name"
                    name="name" required>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <input class="form-control" placeholder="Enter Subject Marks" type="number" formControlName="marks"
                    name="marks" required>
                </div>
              </div>
              <div class="col-md-1">
                {{subjectMarkVal}}
              </div>
              <div class="col-md-1">
                <a class="btn btn-square btn-danger" (click)="removeSubject(i)">X</a>
              </div>
            </div>
          </div>
          <a (click)="addSubject()" class="btn btn-primary btn-square add-field-btn" nbButton>Add New Field</a>
        </div>
        <div class="col-md-6 mt-3">
          <div class="form-group">
            <label>Overall/Cumulative Percentage/Grade (% equivalent)</label>
            <input type="number" class="form-control" placeholder="Enter Value" formControlName="percentage"
              name="percentage" required>
          </div>
        </div>
        <div class="col-md-2 mt-3">
          <br>
          <br>
          {{totalBoardValue}}
        </div>
        <div class="col-md-4">
          <div class="submit-btn-second text-right">
            <br><br>
            <button type="submit" [disabled]="schoolEditFormData.invalid"
              class="btn btn-square btn-primary">Update</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
