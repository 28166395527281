<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeInUp">Important Information</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li><a href="/">Services</a></li>
              <li class="active">Important Information</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-detail">
    <div class="container">
      <h2 class="wow animate__animated animate__fadeInUp white-text">Important Information</h2>
      <p class="wow animate__animated animate__fadeInUp white-text"> On <strong>Student-Stop</strong>, we provide you
        with useful information required to prepare you for your stay in Canada in a timely manner. Register today to begin your
        journey.</p>

        <div class="row mt-5">
          <div class="col-md-5 my-auto">
            <div class="why-choose-image wow animate__animated animate__fadeInUp">
              <img src="/assets/images/winter.png" alt="image">
            </div>
          </div>
          <div class="col-md-7 my-auto">
            <h2 class="wow animate__animated animate__fadeInUp">Weather</h2>
            <p class="wow animate__animated animate__fadeInUp white-text">Canada is a large country, where weather can vary
              significantly from place to place.
              Canada has 4 seasons, Fall, Winter, Spring and Summer. Usually summer is nice and warm, fall and spring
              are beautiful and winter
              can be freezing, snowy and cold(Vancouver is milder). For Indian standards(unless you come from the
              Himalayas!),
              winters can be considered very cold and you should be prepared.
              Temperatures in some parts of Canada can dip to -40 degrees Celcius. Therefore, we would suggest packing
              good quality winter clothing, including jackets, sweaters, winter boots,
              beanies etc. However, do not worry if you do not find something before your trip, these items are usually
              easily available in most cities, including special winter jackets.
              Still confused? Don't worry, our team can also support in helping you get the items before your trip,
              through our special Canada preparation basket(limited stocks available). Register for more details.
            </p>
          </div>
        </div>

      <!-- <h2 class="wow animate__animated animate__fadeInUp mt-5 white-text">COVID-19 Update</h2>
      <div class="row">
        <div class="col-md-6">
          <p class="wow animate__animated animate__fadeInUp white-text">As you are aware, due to COVID-19, there has
            been an impact on
            the education system. However, do not worry, your plans may not be affected.
            Most educational institutions are currently offering online classes. Regarding your plans to work in Canada
            under the Post Graduate Work Permit Program(PGWPP),
            Immigration, Refugees and Citizenship Canada has stated that "PGWPP eligibility will not be affected for
            international students whose fall 2021 courses will
            be online due to COVID-19. This is in line with guidance provided to students already studying in Canada or
            whose program had a spring or summer start date." They also mentioned that
            "Students in this situation may begin their classes while outside Canada and complete up to 50% of their
            program via distance learning if they cannot travel to Canada sooner.". This is great news for most
            students,
            as you get to cover a part of your education from home, without increasing your spending. On the other hand,
            you will still be eligible for the PGWPP, if you complete the remaining 50% of your course in Canada.</p>
        </div>
        <div class="col-md-6">
          <div class="wow animate__animated animate__fadeInUp">
            <img src="/assets/images/covid.png" alt="Covid Safety Product Image">
          </div>
        </div>
        <div class="col-md-12">
          <p class="white-text">Regarding classes, if you are excited and decide to come to Canada, there will be a
            mandatory 14 day
            home quarantine period. Most residences have prepared for this and you should not face any trouble. <strong
              style="color: yellow">If you have registered
              for our meal plan service, we can arrange food delivery and essentials at your doorstep.</strong>
            However, we will
            recommend contacting your residence provider for more details. In case you have booked housing through us,
            we will inform you of the complete process. For your own safety and safety of others around you,
            remember to bring with you lots of protective equipment such as masks, face shields etc. and also sanitizer.
            Note: It is easier to find this in India than in Canada. </p>
        </div>
      </div> -->
    </div>
  </section>

  <section class="service-detail bg-white">
    <div class="container">
      <div class="row">
        <div class="col-md-6 my-auto">
          <h2 class="wow animate__animated animate__fadeInUp dark-text">Culture and Religion</h2>
          <p class="wow animate__animated animate__fadeInUp"> Canada is one of the worlds most tolerant and
            multicutural
            countries. You can be rest assured to find all your requirements closeby. With a huge Indian
            community present in almost every major city, you will always find cultural familiarity. For religious
            students, you will
            find
            Temples, Gurudwara, Churches, Mosques, Synagogues and most other places of worship. Indian Festivals are
            also
            celebrated in Canada by
            Indo-Canadians and often joined by people from other communities. Many educational institutions also offer
            prayer rooms and special
            accomodation to religious practices. Dietary restriction is also not a major concern, as you will find food
            that meets
            your requirement in most cities(including Vegetarian, Halal, Kosher & Vegan).
            Check out our Food page for more information.</p>
        </div>
        <div class="col-md-6">
          <div class="wow animate__animated animate__fadeInUp">
            <img src="/assets/images/collage.png" alt="Collage ">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="call-to-section-second">
    <div class="container">
      <div class="content">
        <div class="sub-title wow animate__animated animate__lightSpeedInRight">Hurry and Sign Up Now!</div>
        <h2 class="wow animate__animated animate__fadeInUp">Register now and get access to your pre-departure
          checklist and lots of important information! Registration is free. </h2>
        <div class="link-box">
          <a href="/register" class="cta-btn wow animate__animated animate__zoomIn animate__delay-1s">Get Started</a>
        </div>
      </div>
    </div>
  </section>

  <app-site-footer></app-site-footer>

</main>
