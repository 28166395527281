<section class="auth-section">
  <div class="container-fluid">
    <div class="row mx-0">
      <div class="col-md-6 form-box">
        <img class="bg-image-2" src="/assets/images/shapes/bg-circle-large.png" alt="">
        <div class="content-box">
          <div class="header">
            <a routerLink="/" class="logo"><img src="/assets/images/ss-logo-dark.png" alt="Logo"></a>
            <div class="nav-list">
              <a routerLink="/login" class="link">Log In</a>
              <a routerLink="/register" class="link">Register</a>
            </div>
          </div>
          <div class="login-form">
            <div class="form-div">
              <h2>Reset Password</h2>
              <p>Create your new password</p>
              <div class="error" *ngIf="error; else successElement">
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                  {{error}}
                </div>
              </div>
              <ng-template #successElement>
                <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
                  <div class="form-group">
                    <input class="form-control" placeholder="Enter new password" type="password" name="password"
                      formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                  </div>
                  <div class="form-group">
                    <input class="form-control" placeholder="Confirm Password" type="password" name="confirmPassword"
                      formControlName="confirmPassword"
                      [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                  </div>
                  <ngb-alert type="success" *ngIf="successClosed" (close)="successClosed=false">{{ success }}
                  </ngb-alert>
                  <ngb-alert type="danger" *ngIf="errorClosed" (close)="errorClosed=false">{{ error }}</ngb-alert>
                  <div class="form-group">
                    <button type="submit" class="btn generic-btn">
                      <span>Submit</span>
                    </button>
                  </div>
                </form>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 side-bg-img reset-img"></div>
    </div>
  </div>
</section>
