import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-job',
  templateUrl: './student-job.component.html',
  styleUrls: ['./student-job.component.scss']
})
export class StudentJobComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
