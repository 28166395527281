import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticlesService } from 'src/app/services/articles.service';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.scss']
})
export class ArticlePageComponent implements OnInit {

  article;

  constructor(
    private _route: ActivatedRoute,
    private _article: ArticlesService
  ) { }

  ngOnInit(): void {
    const title = this._route.snapshot.paramMap.get('title');

    this._article.getArticle(title).subscribe(res => {
      this.article = res;
    })
  }

}
