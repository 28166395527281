import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-admission-application-summary-dialog',
  templateUrl: './admission-application-summary-dialog.component.html',
  styleUrls: ['./admission-application-summary-dialog.component.scss']
})
export class AdmissionApplicationSummaryDialogComponent implements OnInit {

  userStudyData;

  constructor(
    public dialogRef: MatDialogRef<AdmissionApplicationSummaryDialogComponent>,
    private _profile: ProfileService,
  ) { }

  ngOnInit(): void {
    this._profile.getStudentDetails().subscribe((res) => {
      if (res.userStudyDetails) {
        this.userStudyData = res.userStudyDetails;
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
