import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-study-permit-important-info',
  templateUrl: './study-permit-important-info.component.html',
  styleUrls: ['./study-permit-important-info.component.scss']
})
export class StudyPermitImportantInfoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<StudyPermitImportantInfoComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
