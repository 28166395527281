import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private _completeProfile = environment.server;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  logout() {
    this.cookieService.delete('token');
  }

  getToken() {
    return this.cookieService.get('token');
  }

  getuser() {
    return this.http.get<any>(this._completeProfile + '/auth/get-user');
  }

  confirmMail(id) {
    return this.http.get<any>(this._completeProfile + '/auth/confirm-mail/' + id);
  }

  getStudentDetails() {
    return this.http.get<any>(this._completeProfile + '/student');
  }

  submitForm(user) {
    return this.http.post<any>(this._completeProfile + '/user-study-details', user, { responseType: 'text' as 'json' });
  }

  submitPersonalForm(user) {
    return this.http.post<any>(this._completeProfile + '/user-study-details/personal-form', user, { responseType: 'text' as 'json' });
  }

  submitAdmissionForm(user) {
    return this.http.post<any>(this._completeProfile + '/user-study-details/admission-form', user, { responseType: 'text' as 'json' });
  }

  resendConfirmMail(id) {
    return this.http.post<any>(this._completeProfile + '/auth/resend-mail/' + id, id);
  }

  getExtraProfile() {
    return this.http.get<any>(this._completeProfile + '/user-study-details');
  }

  updateProfile(user) {
    return this.http.put<any>(this._completeProfile + '/auth/update-profile', user, { responseType: 'text' as 'json' });
  }

  updateProfileImg(file) {
    return this.http.put<any>(this._completeProfile + '/auth/update-profile-pic', file, { responseType: 'text' as 'json' });
  }

  updateExtraProfile(user) {
    return this.http.put<any>(this._completeProfile + '/user-study-details', user, { responseType: 'text' as 'json' });
  }

  getExtendedUserProfile(id) {
    return this.http.get<any>(this._completeProfile + '/auth/user/' + id)
  }

  userRefresh(id) {
    return this.http.get<any>(this._completeProfile + '/auth/user/' + id);
  }

  payment(data) {
    return this.http.post<any>(this._completeProfile + '/payment/payumoney', data);
  }
}
