import { Component, OnInit } from '@angular/core';
import { AppServicesService } from '../../../services/app-services.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactForm: FormGroup
  submitted;
  msgSuccess;

  constructor(
    private _services: AppServicesService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      'name': ['', [Validators.required]],
      'email': ['', [Validators.required, Validators.email]],
      'phone': ['', [Validators.required]],
      'subject': ['', [Validators.required]],
      'message': ['', [Validators.required]],
    });
  }

  contactFormSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      console.log('add')
      return;
    } else {
      this._services.contactForm(this.contactForm.value).subscribe((res) => {
        this.msgSuccess = res;
        setTimeout(() => {
          this.msgSuccess = '';
        }, 3000);
      });
    }
  }

  get f() {
    return this.contactForm.controls;
  }
}
