<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeInUp">Travel</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li><a href="/">Services</a></li>
              <li class="active">Travel</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-detail">
    <div class="container">
      <h2 class="wow animate__animated animate__fadeInUp white-text">Travel Support</h2>
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <p class="wow animate__animated animate__fadeInUp white-text">International Travel can sometimes be
            stressful (especially for students travelling abroad for the first time).
            Let us make your travel to and within Canada hassle-free.
            Our experts will find the best travel arrangement for your journey and can also
            provide you with recommendations to match your needs. Your travel could include 2 sectors:</p>

          <p class="white-text"><strong>International sector:</strong> This sector will involve flying into one of Canada's 17
            international airports.
            Not all airports are directly accessible from Indian cities and only limited carriers offer direct
            flights to selected Canadian cities. Our experts can recommend the best options based on your destination,
            to make your journey comfortable, cost-effective and fast.
          </p>
          <p class="white-text"><strong>Domestic sector:</strong> This sector includes travel to your end-destination in Canada,
            from your point of first entry in Canada. Our expert team can help you with domestic flights 
            to the city of your institution and then road transport to get you to your final destination in the 
            most cost-effective manner. Otherwise, you could end up paying a hefty sum for local transportation to reach 
            your institution from the airport.
          </p>
        </div>
        <div class="col-lg-6 col-md-6">
          <img id="travelimage" src="/assets/images/travel.png" alt="image">
        </div>
      </div>
    </div>
  </section>

  <section class="service-detail bg-white pt-5 pb-5">
    <div class="container">
      <div class="challange-solution-section clearfix wow animate__animated animate__fadeInUp">
        <div>
          <h3>General Useful Information:</h3>
          <ul>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Some International Carriers: Air
              Canada (direct), Air India (direct), Emirates Airlines, Qatar Airways & Lufthansa.</li>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Approximate Flight duration: Direct
              - 14hrs 30mins to 16 hrs depending on destination. Note: With stopover, duration of journery can be much
              longer.</li>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Flight cost: Approximately CAD
              1500-2500 (INR 80,000- 1.3 Lakhs) for two-way Economy. We can help you get the best deals!</li>
          </ul>
        </div>
        <div>
          <h3>Public transportation options from nearest Airport to University:</h3>
          <ul>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Public transit: Most cost effective.
              Different cities have different options:
              including Metro/Subway(TTC, CTrain, LRT, O-Train), Commuter Train(GO, Agence métropolitaine de transport,
              West Coast Express), Bus, Boat/Ferry</li>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>The Taxi/Limousine service is easy
              as you just need to give the destination to the driver
              and he will take you. However, be aware, this can be significantly expensive depending on distance of
              travel and you should only travel with authorized taxi companies. </li>
            <li><span class="icon"><img alt="" src="assets/images/check.png"></span>Uber and Lyft: Available in most
              larger cities and usually comparable to Taxi prices in some
              cities and more expensive than public transit.</li>
          </ul>
        </div>
      </div>

      <p>An example of a domestic road transportation price comparison:
        If you are going to York University (Keele campus) in Toronto from Toronto Pearson Airport,
        the cost of a Taxi/Limousine could be approximately $50 (20-30 minute journey). 
        Using Uber or Lyft, the cost would be $35-$45. However, the cheapest option would
        be the GO bus, which is only $11. On the contrary, if you were to travel by a similiar 
        transportation to a college in Sarnia from Toronto Pearson Airport, this could cost you 
        more than $500. </p>
    </div>
  </section>

  <app-site-footer></app-site-footer>

</main>
