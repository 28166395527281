import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-starting-documents',
  templateUrl: './starting-documents.component.html',
  styleUrls: ['./starting-documents.component.scss']
})
export class StartingDocumentsComponent implements OnInit {

  userProfile;
  url;

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.url = environment.url;
  }
}
