<div (click)="openInfoDialog()" class="info-dialog-btn">Important Information</div>

<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <h3 class="mt-2 mb-4">Select your Plan</h3>
    <table class="price-table">
      <tbody>
        <tr class="price-table-head">
          <td></td>
          <td>Application Support (No ICCRC Certified Consultant)</td>
          <td>Professional Support (ICCRC Certified Consultant involved)</td>
        </tr>
        <tr>
          <td><strong>Service Charge</strong> <br>(Does not include disbursements such as IRCC Application
            fee)
          </td>
          <td class="price"><span>$200 + Tax + Transaction Fee</span>
          </td>
          <td class="price"><span>$350 + Tax + Transaction Fee</span>
          </td>
        </tr>
        <tr>
          <td class="strong">Application Documents and Forms Preparation Support (Verifying documents and
            forms are filled)
          </td>
          <td><i class="fas fa-check"></i></td>
          <td><i class="fas fa-check"></i></td>
        </tr>
        <tr>
          <td class="strong">Statement of Purpose (SOP) Preparation Support</td>
          <td><i class="fas fa-times not"></i></td>
          <td><i class="fas fa-check"></i></td>
        </tr>
        <tr>
          <td class="strong">Interview preparation support (if applicable) </td>
          <td><i class="fas fa-times not"></i></td>
          <td><i class="fas fa-check"></i></td>
        </tr>
        <tr>
          <td class="strong">ICCRC Certified Consultant in Canada to verify and submit application</td>
          <td><i class="fas fa-times not"></i></td>
          <td><i class="fas fa-check"></i></td>
        </tr>
        <tr>
        <tr class="select-btn" *ngIf="taxInvoice === false">
          <td></td>
          <td><a [ngClass]="{selected: selectedPlan === 1}" (click)="selectPackage('application')">Select and
              Pay</a>
          </td>
          <td><a [ngClass]="{selected: selectedPlan === 2}" (click)="selectPackage('professional')">Select and
              Pay</a>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="taxInvoice === true" class="text-center mt-4">
      <h4>Our team will be contact you soon for payment process.</h4>
    </div>

    <p class="mt-3"><strong>Note: </strong> No consultant or 3rd party can guarantee the outcome of your
      study permit application. However, the Student-Stop team of experts have the experience of ensuring
      that your chances of approval are maximized.</p>
    <p class="mt-3"><strong>Note: </strong>The above are Student-Stop's service charges for support being
      provided. Our fee does not include the IRCC application fee. You will be asked to pay the IRCC
      application fee at the time of submission. </p>
  </div>
</div>
