<div class="card-body">
  <form class="form-group mb-3" novalidate>
    <div class="row fileUploader">
      <div class="col-sm-12">
        <div id="uploader">
          <div class="row mt-3" *ngFor="let item of documentList; let i = index">
            <div class="col-sm-12" id="one">
              <div id="uploader">
                <div class="row uploadDoc">
                  <div class="col-sm-8 my-auto">
                    <h4>{{i + 1}}. {{item.name}} <span class="red-color" *ngIf="item.mandatory">(Mandatory)</span> <span
                        *ngIf="!item.mandatory">(Not
                        Mandatory)</span></h4>
                    <p>{{item.description}}</p>
                  </div>
                  <div class="col-sm-4 text-right my-auto">
                    <button class="btn btn-primary" *ngIf="uploadDocumentsData[i]?.url === null; else afterUploaded"
                      (click)="uploadDocuments(item.name)">Upload File</button>
                    <ng-template #afterUploaded>
                      <a target="blank" [href]="uploadDocumentsData[i].url"
                        class="btn btn-square btn-primary mr-1">View</a>
                      <a (click)="uploadDocuments(item.name)" class="btn btn-square btn-success mr-1">Update</a>
                      <a class="btn btn-square btn-danger" (click)="removeDocuments(item.name)">X</a>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="form-check mt-4 text-center">
            <form [formGroup]="checkBoxForm">
              <input class="form-check-input" type="checkbox" formControlName="checkboxinput" id="flexCheckChecked">
              <label class="form-check-label" for="flexCheckChecked">I hereby certify that the information provided is
                true and correct and the documents uploaded or transmitted to Student-Stop by any other method are
                original or scanned copies of the original. I also understand that any dishonesty may render for refusal
                of my applications and can have other serious consequences.</label>

              <div class="text-center">
                <button type="submit" class="btn btn-primary mt-3" [disabled]="!submitbtnValidation"
                  (click)="onSubmit()">Submit</button>
                <a *ngIf="docType === 'Starting Document'" class="btn btn-danger ml-2" href="/profile">Upload later</a>
                <p *ngIf="formError" class="error mt-3">Please Accept the condition before Submit.</p>
              </div>
            </form>
          </div> -->

          <div class="text-center mt-4">
            <button type="submit" class="btn btn-primary" [disabled]="!submitbtnValidation"
              (click)="onSubmit()">Submit</button>
            <a *ngIf="docType === 'Starting Document'" class="btn btn-danger ml-2" href="/profile">Upload later</a>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
