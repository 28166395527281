import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DocumentsService } from 'src/app/services/documents.service';
import { ProfileService } from 'src/app/services/profile.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-upload-doc-dialog',
  templateUrl: './upload-doc-dialog.component.html',
  styleUrls: ['./upload-doc-dialog.component.scss']
})
export class UploadDocDialogComponent implements OnInit {

  selectedFile;
  documentValidation: Boolean = false;
  loading = false;
  docName;

  uploadDocumentsData;
  submitbtnValidation;

  constructor(
    private _document: DocumentsService,
    private _profile: ProfileService,
    public dialogRef: MatDialogRef<UploadDocDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.docName = this.data.name;

    $(document).on('change', '.up', function () {
      var names: any = [];
      var length = $(this).get(0).files.length;
      for (var i = 0; i < $(this).get(0).files.length; ++i) {
        names.push($(this).get(0).files[i].name);
      }
      if (length > 2) {
        var fileName = names.join(', ');
        $(this).closest('.form-group').find('.form-control').attr("value", length + " files selected");
      }
      else {
        $(this).closest('.form-group').find('.form-control').attr("value", names);
      }
    });
  }

  selectFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const file_size = event.target.files[0].size;
      const fileType = file['type'];
      const validImageTypes = ['image/jpeg', 'image/jpg'];
      if (file_size > 10485760) {
        $("#file_error").html("File size must less then 10mb. Compress file <a target='blank' href='https://www.wecompress.com/en/'>Here</a>").show().delay(5000).fadeOut('slow');
        $("#uploadShow").css("border: 1px solid red");
        this.selectedFile = null;
        this.documentValidation = false;
      } else if (!validImageTypes.includes(fileType)) {
        $("#file_error").html("Invalid Document only Accept jpg, jpeg").show().delay(5000).fadeOut('slow');;
        $("#uploadShow").css("border: 1px solid red");
        this.selectedFile = null;
        this.documentValidation = false;
      } else {
        this.selectedFile = file;
        this.documentValidation = true;
      }
    }
  }

  uploadDocumentsFile(name) {
    this.loading = true;
    const formData = new FormData();
    formData.append('document', this.selectedFile);
    formData.append('name', this.docName);

    this._document.uploadDocument(formData).subscribe((res) => {
      this.loading = false;
      this._profile.getStudentDetails().subscribe((res) => {
        if (res.documents) {
          const studentDoc = res.documents;
          var uploadDocuments = [];
          for (let i in studentDoc.documents) {
            for (let categoryIndex in studentDoc.documents[i].category) {
              if (studentDoc.documents[i].category[categoryIndex] === "Starting Document") {
                uploadDocuments.push(studentDoc.documents[i]);
              }
            }
          }
          this.uploadDocumentsData = uploadDocuments.sort(this.dynamicSort("name"));
          for (let docIndex in this.uploadDocumentsData) {
            if (this.uploadDocumentsData[docIndex].url !== null) {
              this._document.viewDoc({ name: this.uploadDocumentsData[docIndex].name }).subscribe(
                res => {
                  this.uploadDocumentsData[docIndex].url = res['document_url']
                },
                err => {
                  console.log(err)
                }
              )
            }
          }
          const validator = uploadDocuments.filter(item => item.url === null);
          const mandatoryCheck = validator.filter(item => item.mandatory === true)
          if (mandatoryCheck.length === 0) {
            this.submitbtnValidation = true;
          }

          this.dialogRef.close();
          this.selectedFile = null;
          this.documentValidation = false;
        }
      });
    });
  }

  onDialogClose(): void {
    this.dialogRef.close();
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
}
