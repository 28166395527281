import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HouseService {

  private _completeProfile = environment.server;

  constructor(private http: HttpClient) { }

  getHousingDetails() {
    return this.http.get<any>(this._completeProfile + '/service/housing/get-housing');
  }

  getAvailableHouses(data) {
    return this.http.post<any>(this._completeProfile + '/service/housing/get-available-houses', data, { responseType: 'text' as 'json' });
  }

  housingSubmit(data) {
    return this.http.post<any>(this._completeProfile + '/service/housing/add-housing', data);
  }

  editHousingSubmit(data) {
    return this.http.post<any>(this._completeProfile + '/service/housing/edit-housing', data);
  }

  selectHouse(data) {
    return this.http.post<any>(this._completeProfile + '/service/housing/select-housing', data);
  }

  getHouseById(id) {
    return this.http.get<any>(this._completeProfile + '/service/housing/get-house-by-id/' + id);
  }
}
