import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SelectOptionData } from 'src/app/sharedata/selectOptionData';
import { SelectOptions } from 'src/app/sharedata/selectedOptions';

@Component({
  selector: 'app-edit-institute-dialog',
  templateUrl: './edit-institute-dialog.component.html',
  styleUrls: ['./edit-institute-dialog.component.scss']
})
export class EditInstituteDialogComponent implements OnInit {

  editFormData: FormGroup;
  toDate = new Date();
  todayDate = new Date();

  studyTypeList;
  studyTypeSettings;
  countryList;
  countrySettings;

  constructor(
    public dialogRef: MatDialogRef<EditInstituteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    const serviceData = new SelectOptionData;
    const selectedOptions = new SelectOptions;

    this.countryList = serviceData.country();
    this.countrySettings = selectedOptions.countrySettings();
    this.studyTypeList = serviceData.studyType();
    this.studyTypeSettings = selectedOptions.studyTypeSettings();
  }

  ngOnInit(): void {
    this.editFormData = new FormGroup({
      'studyType': new FormControl(this.data.editFormDetails.studyType, Validators.required),
      'country': new FormControl(this.data.editFormDetails.country),
      'nameOfInstitute': new FormControl(this.data.editFormDetails.nameOfInstitute, Validators.required),
      'attendedFrom': new FormControl(this.data.editFormDetails.attendedFrom, Validators.required),
      'attendedTo': new FormControl(this.data.editFormDetails.attendedTo, Validators.required),
      'degreeName': new FormControl(this.data.editFormDetails.degreeName, [Validators.required]),
      'indicateDate': new FormControl(this.data.editFormDetails.indicateDate, [Validators.required]),
    });
    this.toDate = this.data.editFormDetails.attendedFrom;
  }

  editInstitute(form) {
    this.dialogRef.close(form.value);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  depart() {
    this.toDate = this.editFormData.value.attendedFrom;
  }
}
