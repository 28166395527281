import { Component, OnInit } from '@angular/core';
import { AppServicesService } from '../../../services/app-services.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {

  feedbackForm: FormGroup
  submitted;
  msgSuccess;

  constructor(
    private _services: AppServicesService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.feedbackForm = this.fb.group({
      'name': ['', [Validators.required]],
      'email': ['', [Validators.required, Validators.email]],
      'phone': ['', [Validators.required]],
      'topic': ['', [Validators.required]],
      'message': ['', [Validators.required]],
    });
  }

  feedbackFormSubmit() {
    this.submitted = true;
    if (this.feedbackForm.invalid) {
      return;
    } else {
      this._services.feedbackForm(this.feedbackForm.value).subscribe((res) => {
        this.msgSuccess = "Thanks for Give Suggestions";
        setTimeout(() => {
          this.msgSuccess = '';
        }, 3000);
      });
    }
  }

  get f() {
    return this.feedbackForm.controls;
  }

}
