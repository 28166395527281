import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, Input, OnInit } from '@angular/core';
import { StudypermitService } from 'src/app/services/studypermit.service';
import { environment } from 'src/environments/environment';
import { StudyPermitImportantInfoComponent } from 'src/app/components/dialogs/study-permit-important-info/study-permit-important-info.component';
import { ProfileService } from '../../../../../services/profile.service';

@Component({
  selector: 'app-study-permit-services',
  templateUrl: './study-permit-services.component.html',
  styleUrls: ['./study-permit-services.component.scss']
})
export class StudyPermitServicesComponent implements OnInit {

  @Input() profile;
  planSelected;
  selectedPlan = 0;
  planPrice = 0;
  taxInvoice = false;

  dialogRef;

  constructor(
    private _studypermit: StudypermitService,
    public dialog: MatDialog,
    private _profile: ProfileService
  ) { }

  ngOnInit(): void {
    this._profile.getuser().subscribe(res => {
      if (res.student.country === "Canada") {
        this.taxInvoice = true;
      } else {
        this.taxInvoice = false;
      }
    });
    this.loadStripe();
  }

  openInfoDialog() {
    this.dialogRef = this.dialog.open(StudyPermitImportantInfoComponent, {
      width: '1200px',
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  selectPackage(value) {
    if (value === 'application') {
      this.planSelected = value;
      this.selectedPlan = 1;
      this.planPrice = 200;
      this.payAmount()
    }
    if (value === 'professional') {
      this.planSelected = value;
      this.selectedPlan = 2;
      this.planPrice = 350;
      this.payAmount()
    }
  }

  payAmount() {
    const totalPay = (((6 / 100) * this.planPrice) + this.planPrice);

    var handler = (<any>window).StripeCheckout.configure({
      key: environment.stripeKey,
      locale: 'auto',
      token: (paymentDetails: any) => {
        const finalPaymentDetails = {
          ...paymentDetails,
          payAmount: totalPay
        }
        const data = {
          paymentDetails: finalPaymentDetails,
          planName: this.planSelected
        }
        this._studypermit.paymentDone(data).subscribe((res) => {
          this._studypermit.createInvoice(this.profile._id).subscribe(invoice => {
            window.location.reload()
          });
        });
      }
    });

    handler.open({
      name: 'Student-Stop',
      description: 'Payment of study permit',
      amount: totalPay * 100
    });
  }

  loadStripe() {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }

  calcPercent(num, percentage) {
    return num * (percentage / 100);
  }

}
