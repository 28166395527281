const menuData = [
    { href: "/", label: "Home" },
    { href: "/about-us", label: "About" },
    {
        href: "/services", label: "Services", children: [
            { href: "/services", label: "All Services" },
            { href: "/services/admission", label: "Admission Support" },
            { href: "/services/study-permit", label: "Immigration/Study Permit" },
            { href: "/services/banking", label: "Banking" },
            { href: "/services/food", label: "Food" },
            { href: "/services/travel", label: "Travel" },
            { href: "/services/housing", label: "Housing" },
            { href: "/services/student-job", label: "Student Job" },
            { href: "/services/medical-insurance", label: "Medical Insurance" },
            { href: "/services/important-information", label: "Important Information" },
        ]
    },
    { href: "/partner", label: "Channel Partner" },
    { href: "/contact-us", label: "Contact" }
]

export default menuData;