import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicesSubmitService {

  private _completeProfile = environment.server;

  constructor(private http: HttpClient) { }

  getBanking(id) {
    return this.http.get<any>(this._completeProfile + '/services/banking/' + id);
  }

  bankingSubmit(data) {
    return this.http.post<any>(this._completeProfile + '/services/banking', data, { responseType: 'text' as 'json' });
  }

  startBanking() {
    return this.http.post<any>(this._completeProfile + '/services/banking/start', null, { responseType: 'text' as 'json' });
  }

  getServices(id) {
    return this.http.get<any>(this._completeProfile + '/services/' + id);
  }

  getSelectedServices() {
    return this.http.get<any>(this._completeProfile + '/services/selected-services');
  }

  updateSelectedServices(data) {
    return this.http.post<any>(this._completeProfile + '/services/selected-services/update', data, { responseType: 'text' as 'json' });
  }

}
