<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeIn">Scholarship</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">Scholarship</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="challenges-area">
    <div class="container">
      <div class="section-title white-title"><span>Apply for Scholarship</span>
        <h2>Get the Scholarship to Study in Canada</h2>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="single-challenges overly-one">
            <div class="overly-two"><i class="fa fa-water"></i>
              <h3>Identifying Heading</h3>
              <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p> <i
                class="bg-icon fa fa-water"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="single-challenges overly-one">
            <div class="overly-two"><i class="fa fa-unlock-alt"></i>
              <h3>Low Risk Assessment</h3>
              <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p> <i
                class="bg-icon fa fa-unlock-alt"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="single-challenges overly-one">
            <div class="overly-two"><i class="fa fa-trophy"></i>
              <h3>Testing Good Product</h3>
              <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p> <i
                class="bg-icon fa fa-trophy"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="single-challenges overly-one">
            <div class="overly-two"><i class="fa fa-ribbon"></i>
              <h3>Managing Tea Plant</h3>
              <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p> <i
                class="bg-icon fa fa-ribbon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="contact-us-section">
    <div class="container">
      <div class="upper-box">
        <div class="container">
          <div *ngIf="!registered">
            <div class="sec-title text-center">
              <div class="title wow animate__animated animate__fadeInUp">Register For The Scholarship</div>
              <div class="separator center"><span></span></div>
            </div>
            <div class="row">
              <div class="col-md-9 mx-auto wow animate__animated animate__fadeInRight">
                <div class="from-wraper">
                  <form class="contact-form" [formGroup]="webinarForm" (ngSubmit)="webinarFormSubmit()" F novalidate>
                    <div class="row">
                      <div class="col-md-12">
                        <input type="text" placeholder="Name*" class="form-control mt-0" name="name"
                          formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                          <div *ngIf="f.name.errors.required">Name is a required.</div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <input type="email" placeholder="Email*" class="form-control" name="email"
                          formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email is a required.</div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <input type="text" placeholder="Phone Number*" class="form-control" name="phone"
                          formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                          <div *ngIf="f.phone.errors.required">Phone Number is a required.</div>
                        </div>
                      </div>
                      <div class="col-md-12 text-center">
                        <button type="submit" class="submit-btn">Submit</button>
                        <div *ngIf="msgSuccess" class="alert alert-success dark alert-dismissible fade show"
                          role="alert">
                          <p>{{msgSuccess}}</p>
                          <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                              aria-hidden="true">×</span></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="registered">
            <div class="sec-title text-center">
              <div class="title wow animate__animated animate__fadeInUp">Thanks For The Registered</div>
              <div class="separator center"><span></span></div>
            </div>
            <div class="text-center">
              <h4>Click on the below button to join us during the live webinar on the <br> 15nd July 2022 at 8:30 pm
                IST.
              </h4>
              <a class="btn btn-primary mt-2" target="_blank" href="https://meet.google.com/ctx-przp-nkh">Joining
                Link</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-site-footer></app-site-footer>

</main>
