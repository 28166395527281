import { Component, OnInit } from '@angular/core';
import { StudypermitService } from 'src/app/services/studypermit.service';

@Component({
  selector: 'app-study-permit-info',
  templateUrl: './study-permit-info.component.html',
  styleUrls: ['./study-permit-info.component.scss']
})
export class StudyPermitInfoComponent implements OnInit {

  constructor(
    private _studypermit: StudypermitService,
  ) { }

  ngOnInit(): void { }

  submitImmigration() {
    this._studypermit.startImmigration().subscribe((res) => {
      window.location.reload()
    })
  }
}
