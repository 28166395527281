import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-banking-main',
  templateUrl: './banking-main.component.html',
  styleUrls: ['./banking-main.component.scss']
})
export class BankingMainComponent implements OnInit {

  step: Number = 0;
  bankService: any;

  constructor(
    private _profile: ProfileService,
  ) { }

  ngOnInit(): void {
    this._profile.getStudentDetails().subscribe((res) => {
      if (res.selectedService) {
        this.bankService = res.bankService
        for (var i = 0; i < res.selectedService.servicesName.length; i++) {
          if (res.selectedService.servicesName[i].name === 'banking') {
            if (res.selectedService.servicesName[i].statusStep === 2 || res.selectedService.servicesName[i].statusStep === 3) {
              this.step = 1
            } else {
              this.step = 0
            }
          }
        }
      }
    });
  }

}
