import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../../../services/profile.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { ChangeProfileDialogComponent } from 'src/app/components/dialogs/change-profile-dialog/change-profile-dialog.component';
import { ChangePasswordDialogComponent } from 'src/app/components/dialogs/change-password-dialog/change-password-dialog.component';
import { UploadDocDialogComponent } from 'src/app/components/dialogs/upload-doc-dialog/upload-doc-dialog.component';
import { EditProfileDialogComponent } from 'src/app/components/dialogs/edit-profile-dialog/edit-profile-dialog.component';
import { AdditionalDocsService } from './../../../services/additional-docs.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  url;
  profile: any;

  userStudyDetails: any;
  userSelectedService: any;

  documentList: Array<Object> = [];
  admissionStatus: any;
  immigrationStatus: any;

  nonEditService: Boolean = true;
  nonEditStudy: Boolean = true;

  dialogRef;
  uploadDocumentsData: any;
  submitbtnValidation: any;
  additionalDocs: any = [];

  constructor(
    private _profile: ProfileService,
    private _document: DocumentsService,
    public dialog: MatDialog,
    private _router: Router,
    private toastr: ToastrService,
    private _additionalDocs: AdditionalDocsService
  ) { }

  ngOnInit(): void {
    this.url = environment.url;

    this._profile.getuser().subscribe(res => {
      this.profile = res.student;

      if (this.profile.formComplete === false) {
        window.location.href = "/journey-begin"
      }

      this._profile.getStudentDetails().subscribe((res) => {
        // Basic Details
        if (res.userStudyDetails) {
          this.userStudyDetails = res.userStudyDetails;
          console.log(this.userStudyDetails)
          if (this.userStudyDetails.startingDocument === false) {
            this.toastr.warning(
              'Upload your verification documents and start your journey. <a href="/starting-documents" class="toastr-link">Go Here</a>',
              'Starting Documents',
              {
                enableHtml: true,
                closeButton: true,
                timeOut: 4000,
                disableTimeOut: true,
              }
            );
          }
        }

        // Select Services
        if (res.selectedService) {
          this.userSelectedService = res.selectedService;
          this.admissionStatus = this.userSelectedService.servicesName.find(item => item.name === "admission");
          this.immigrationStatus = this.userSelectedService.servicesName.find(item => item.name === "immigration");
          if (this.userStudyDetails.startingDocument === true) {
            if (this.userSelectedService.servicesName[0].need === true && this.userSelectedService.servicesName[0].step !== 4) {
              this.toastr.warning(
                'Submit Admission details and start a admission process with us.. <a href="/admission" class="toastr-link">Go Here</a>',
                'Admission',
                {
                  enableHtml: true,
                  closeButton: true,
                  timeOut: 4000,
                  disableTimeOut: true,
                }
              );
            }
          }
        }

        // Additional Docs
        this._additionalDocs.getAdditionalDocs().subscribe(data => {
          this.additionalDocs = data
        }, err => {
          console.log(err)
        })

        // Student Documents
        if (res.documents) {
          const studentDoc = res.documents;
          var uploadDocuments = [];
          for (let i in studentDoc.documents) {
            for (let categoryIndex in studentDoc.documents[i].category) {
              if (studentDoc.documents[i].category[categoryIndex] === "Starting Document") {
                uploadDocuments.push(studentDoc.documents[i]);
              }
            }
          }
          this.uploadDocumentsData = uploadDocuments.sort(this.dynamicSort("name"));
          for (let docIndex in this.uploadDocumentsData) {
            if (this.uploadDocumentsData[docIndex].url !== null) {
              this._document.viewDoc({ name: this.uploadDocumentsData[docIndex].name }).subscribe(
                res => {
                  this.uploadDocumentsData[docIndex].url = res['document_url']
                },
                err => {
                  console.log(err)
                }
              )
            }
          }
          for (let key of studentDoc.documents) {
            let result = !Object.values(key).some(o => o === null);
            if (result === false) {
              this.submitbtnValidation = true;
              break;
            }
          }
        }

        this._document.documentList().subscribe((res: any) => {
          if (res) {
            var documents = [];
            for (let i in res) {
              for (let categoryIndex in res[i].category) {
                if (res[i].category[categoryIndex] === "Starting Document") {
                  documents.push(res[i]);
                }
              }
            }
            this.documentList = documents.sort(this.dynamicSort("name"));
          }
        });
      });

      $(document).on('change', '.up', function () {
        var names: any = [];
        var length = $(this).get(0).files.length;
        for (var i = 0; i < $(this).get(0).files.length; ++i) {
          names.push($(this).get(0).files[i].name);
        }
        if (length > 2) {
          var fileName = names.join(', ');
          $(this).closest('.form-group').find('.form-control').attr("value", length + " files selected");
        }
        else {
          $(this).closest('.form-group').find('.form-control').attr("value", names);
        }
      });
    },
      err => {
        console.log(err)
      })
  }

  // Document Functions
  uploadDocuments(name): void {
    this.dialogRef = this.dialog.open(UploadDocDialogComponent, {
      width: '500px',
      data: { name: name },
      autoFocus: false,
      hasBackdrop: false
    });
  }

  removeDocuments(value): void {
    this._document.deleteDocument({ name: value }).subscribe((res) => {
      window.location.reload();
    });
  }

  onSubmit() {
    this._document.documentUploadDone()
      .subscribe(
        res => {
          this._router.navigate(['/profile']);
        },
        err => {
          console.log(err)
        }
      );
  }

  editServicePopup() {
    if (this.nonEditService === true) {
      this.nonEditService = false
    } else {
      this.nonEditService = true
    }
  }

  editStudyPopup() {
    if (this.nonEditStudy === true) {
      this.nonEditStudy = false
    } else {
      this.nonEditStudy = true
    }
  }

  editProfileDialog() {
    this.dialogRef = this.dialog.open(EditProfileDialogComponent, {
      width: '1000px',
      autoFocus: false,
      hasBackdrop: false,
      data: { profile: this.profile },
    });
  }

  changePasswordDialog() {
    this.dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: '500px',
      autoFocus: false,
      hasBackdrop: false
    });
  }

  editProfilePic() {
    this.dialogRef = this.dialog.open(ChangeProfileDialogComponent, {
      width: '600px',
      autoFocus: false,
      hasBackdrop: false
    });
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

}
