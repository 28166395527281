<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <h3 class="mb-4">Travel Ticket</h3>
    <div class="row">
      <div class="col-md-6">
        <p><strong>Origin: </strong> {{travelService?.origin}}</p>
        <p><strong>When Depart: </strong> {{travelService?.depart | date}}</p>
        <p><strong>Class: </strong> {{travelService?.class}}</p>
        <p><strong>Need Insurance: </strong> {{travelService?.insurance ? "Yes" : "No"}}</p>
      </div>
      <div class="col-md-6">
        <p><strong>Destination: </strong> {{travelService?.destination}}</p>
        <p *ngIf="travelService?.return"><strong>Return: </strong> {{travelService?.return | date}}</p>
        <p><strong>Note: </strong> {{travelService?.note}}</p>
      </div>
    </div>
  </div>
</div>

<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <div *ngIf="travelService?.confirm === false; else confirm">
      <h4>Our team will contact you to process the booking.</h4>
    </div>

    <ng-template #confirm>
      <h3>Your Travel Ticket is Confirm With Number:
        <span style="color: #80cf00">{{travelService?.confirmNumber}} </span>
        <span class="download-btn ml-2"> <a [href]="downloadLink" class="btn btn-danger btn-square">Download
            Application</a></span>
      </h3>
    </ng-template>
  </div>
</div>
