import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppServicesService {

  private _completeProfile = environment.server;

  constructor(private http: HttpClient) { }

  getBankStatus(id) {
    return this.http.get<any>(this._completeProfile + '/banking/' + id);
  }

  postBank(data) {
    return this.http.post<any>(this._completeProfile + '/banking', data, { responseType: 'text' as 'json' });
  }

  analyse(type) {
    return this.http.post<any>(this._completeProfile + '/analyse/banking/' + type, { id: "new" }, { responseType: 'text' as 'json' });
  }

  clickLoan(type) {
    return this.http.post<any>(this._completeProfile + '/analyse/loan/' + type, { id: "new" }, { responseType: 'text' as 'json' });
  }

  analyseInsurence() {
    return this.http.post<any>(this._completeProfile + '/analyse/insurence', { id: "new" }, { responseType: 'text' as 'json' });
  }

  contactForm(data) {
    return this.http.post<any>(this._completeProfile + '/contact', data, { responseType: 'text' as 'json' });
  }

  feedbackForm(data) {
    return this.http.post<any>(this._completeProfile + '/contact/feedback-form', data, { responseType: 'text' as 'json' });
  }

  registerWebinar(data) {
    return this.http.post<any>(this._completeProfile + '/contact/webinar-registration', data, { responseType: 'text' as 'json' });
  }
}