import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MobileplansService {

  private _completeProfile = environment.server;

  constructor(private http: HttpClient) { }

  choosePlan(data) {
    return this.http.post<any>(this._completeProfile + '/service/mobile-plans/choose-plan', data);
  }

  getSelectedPlan() {
    return this.http.get<any>(this._completeProfile + '/service/mobile-plans/selected-plan');
  }
}
