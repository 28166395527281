import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-permanent-resident-main',
  templateUrl: './permanent-resident-main.component.html',
  styleUrls: ['./permanent-resident-main.component.scss']
})
export class PermanentResidentMainComponent implements OnInit {

  profile;
  step: Number;
  permanentResidentService;
  documents;

  constructor(
    private _profile: ProfileService
  ) { }

  ngOnInit(): void {
    this._profile.getStudentDetails().subscribe((res) => {
      if (res.selectedService) {
        for (var i = 0; i < res.selectedService.servicesName.length; i++) {
          if (res.selectedService.servicesName[i].name === 'primmigration') {
            if (res.selectedService.servicesName[i].statusStep !== 0) {
              this.step = res.selectedService.servicesName[i].statusStep;
              this.permanentResidentService = res.permanentResidentService;
              this.documents = res.documents;
              this.profile = res;
            } else {
              this.step = 0;
            }
          }
        }
      }
    });
  }

}
