<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body">
    <h3 class="mb-4">Food Information</h3>
    <button *ngIf="!foodService?.selectedFood" class="btn btn-danger edit-btn"
      (click)="editDialog(editModel)">Edit</button>
    <div class="row">
      <div class="col-md-4">
        <p><strong>City: </strong> {{foodService?.city}}</p>
      </div>
      <div class="col-md-8">
        <p><strong>University Or College: </strong> {{foodService?.university.itemName}},
          {{foodService?.university.city}}<span *ngIf="foodService?.university.campus">,
            {{foodService?.university.campus}} Campus</span> </p>
      </div>
      <div class="col-md-4">
        <p><strong>Move-In Year: </strong> {{foodService?.startSemesterYear}}</p>
        <p><strong>Diet Type: </strong> {{foodService?.dietType}}</p>
      </div>
      <div class="col-md-4">
        <p><strong>Move-In Semester: </strong> {{foodService?.semesterType}}</p>
        <p><strong>Allergies: </strong> {{foodService?.allergies}}</p>
      </div>
      <div class="col-md-4">
        <p><strong>Cuisine Preference: </strong> {{foodService?.cuisinePreference}}</p>
      </div>
    </div>
  </div>
</div>

<div class="card wow animate__animated animate__fadeInUp" *ngIf="foodService?.selectedFood">
  <div class="card-header">Your Selected Food Plan</div>
  <div class="card-body">

    <div class="room">
      <div class="r1 r-com-1"><img src="{{foodService?.selectedFood.image}}" alt=""></div>

      <div class="r2 r-com">
        <h4>{{foodService?.selectedFood.name}}</h4>
        <ul>
          <li><strong>Cuisine Type:</strong> {{foodService?.selectedFood.cuisineType}}</li>
          <li><strong>Diet Type:</strong> {{foodService?.selectedFood.dietType}}</li>
        </ul>
      </div>

      <div class="r4 r-com">
        <p>Price for {{foodService?.selectedFood.unit}}</p>
        <p [innerHTML]="foodService?.selectedFood.description"></p>
      </div>

      <div class="r5 r-com">
        <p><span class="room-price-1">C$ {{foodService?.selectedFood.price}}</span></p>
      </div>
    </div>

    <div *ngIf="foodService?.acceptPlan === false; else confirm">
      <h4>Your food plan selection has not been confirmed yet. Our team will contact you with the next steps
      </h4>
    </div>

    <ng-template #confirm>
      <h3>Your Food Plan is Confirm With Number: <span style="color: #80cf00">{{foodService?.confirmNumber}}</span>
      </h3>
    </ng-template>
  </div>
</div>

<div class="card wow animate__animated animate__fadeInUp" *ngIf="foodService?.acceptPlan === false">
  <div class="card-header">Available Food Plans</div>
  <div class="card-body">

    <div class="text-center" *ngIf="foodList.length === 0; else founded">
      <h4 class="special-font">Sorry, there are currently no available food plans. Please check again later.
      </h4>
    </div>
    <ng-template #founded>
      <div class="row">
        <div class="col-md-12" *ngIf="foodService?.selectedFood?.length !== 0">
          <h3>Other Food Plans</h3>
        </div>
        <div class="col-md-4" *ngFor="let food of foodList">
          <div class="featured-item">
            <div class="featured-image">
              <img src="{{food.image}}" alt="image">
              <div class="price"><span>C$ {{food.price}} / {{food.unit}}</span></div>
            </div>
            <div class="featured-top-content">
              <h3>{{food.name}}</h3>
              <p [innerHTML]="food.description"></p>
              <ul class="featured-list">
                <li><strong>Unit: </strong> {{food.unit}}</li>
                <li><strong>Diet Type: </strong> {{food.dietType}}</li>
                <li><strong>Cuisine Type: </strong> {{food.cuisineType}}</li>
              </ul>
            </div>
            <div class="featured-bottom-content">
              <div class="featured-btn">
                <a (click)="selectFood(food._id)" class="btn btn-primary d-inline-block py-2 text-white">SELECT</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #editModel let-tablename let-ref="dialogRef">
  <div class="card" mat-dialog-content>
    <div class="card-header">Edit Food Details</div>
    <a (click)="closeDegreeDialog()" class="btn btn-square btn-danger close-btn-second ml-3 text-white">Close</a>
    <div class="card-body">
      <form [formGroup]="editFoodForm" (ngSubmit)="submitEditFood(editFoodForm)" novalidate novalidate>
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label for="">Select Universite And College*</label>
              <angular2-multiselect (ngModelChange)="selectUniversity($event)" [data]="allUniversityAndCollege"
                class="form-control angular-selector" formControlName="university" name="university"
                [settings]="universitySettings">
                <c-item>
                  <ng-template let-item="item">
                    <label *ngIf="!item.campus"> <strong>{{item.itemName}}, {{item.city}}</strong></label>
                    <label *ngIf="item.campus"><strong>{{item.itemName}}, {{item.city}}, {{item.campus}}
                        Campus </strong></label>
                  </ng-template>
                </c-item>
              </angular2-multiselect>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Select City*</label>
              <select class="form-control" name="city" formControlName="city">
                <option value="">Select City</option>
                <option [value]="city" *ngFor="let city of canadaCity">{{city}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Move-In Year*</label>
              <select class="form-control" placeholder="Move-In Year" type="text" formControlName="startSemesterYear"
                name="startSemesterYear" required>
                <option value="" selected disabled>Move-In Year</option>
                <option *ngFor="let year of years" [value]="year">
                  {{year}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Move-In Semester*</label>
              <select class="form-control" name="semesterType" formControlName="semesterType">
                <option value="" selected disabled>Select Move-In Semester</option>
                <option value="Fall">Fall</option>
                <option value="Winter">Winter</option>
                <option value="Summer">Summer</option>
                <option value="Fall & Winter">Fall & Winter</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Select Diet Type*</label>
              <select class="form-control" name="dietType" formControlName="dietType">
                <option value="">Select Diet Type</option>
                <option value="Non-Vegetarian">Non-Vegetarian</option>
                <option value="Vegetarian">Vegetarian</option>
                <option value="Vegan">Vegan</option>
                <option value="Halal">Halal</option>
                <option value="Kosher">Kosher</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Cuisine preference*</label>
              <select class="form-control" name="cuisinePreference" formControlName="cuisinePreference">
                <option value="">Cuisine preference</option>
                <option value="International">International</option>
                <option value="Indian">Indian</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Enter Allergies</label>
              <input placeholder="Entry Allergies" name="allergies" formControlName="allergies" class="form-control">
            </div>
          </div>
        </div>

        <div class="text-center mt-2">
          <button [disabled]="editFoodForm.invalid" class="btn btn-square btn-primary">Submit</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
