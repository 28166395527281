import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-incurance',
  templateUrl: './medical-incurance.component.html',
  styleUrls: ['./medical-incurance.component.scss']
})
export class MedicalIncuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
