import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, Input, OnInit } from '@angular/core';
import { AdmissionService } from 'src/app/services/admission.service';
import { environment } from 'src/environments/environment';
import { AdmissionApplicationSummaryDialogComponent } from 'src/app/components/dialogs/admission-application-summary-dialog/admission-application-summary-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProfileService } from '../../../../../services/profile.service';

@Component({
  selector: 'app-admission-payment',
  templateUrl: './admission-payment.component.html',
  styleUrls: ['./admission-payment.component.scss']
})
export class AdmissionPaymentComponent implements OnInit {

  @Input() profile;
  planSelected;
  totalPay = 0;
  partnerCollegeInclude = false;
  checkboxAccept = true;
  checkBoxForm: FormGroup;
  formError = false;
  allPartner = false;
  taxInvoice = false;

  applicationStatus;
  dialogRef;
  partnersColleges = [
    "Coquitlam",
    "Seneca",
    "LaSalle",
    
    "Cambrian",
    "Centennial",
    "Conestoaa Colleae",
    "Confederation",
    "Fanshawe Colleae",
    "Flemino",
    "Fleming college - Toronto campus",
    "Georqian",
    "Georoian llac",
    "Niaaara Colleae",
    "Northern",
    "Seneca",
    "Sheridan",
    "St. Lawrence",
    "St. Lawrence - Aloha Camous",
    "University of Windsor",
    "Canadore Colleae - Toronto Camous",
    "Loyalist",
    "Ontario Tech University",
    "Durham",
    "York University",
    "Brock University",
    "Nipissing University",
    "Wilfrid Laurier University",
    "Trent University",
    "Mohawk",
    "Niagara College - Toronto",
    "Lambton",
    "Loyalist college - Toronto campus",
    "Toronto Metropolitan University",
    "Lakehead University",
    "Southern Alberta Institute of Technology - SAIT",
    "Lethbridge",
    "Lakeland",
    "NorQuest",
    "Bow Valley Colleae",
    "Colleae of The Rockies",
    "Vancouver Community",
    "FairleiQh Dickinson University (FDU)",
    "Vancouver Island University",
    "University Canada West",
    "Thompson Rivers University",
    "University of Fraser Valley",
    "Capilano Universitv",
    "New York Institute ofTechnoloav (NYIT )",
    "Northern Liahts Colleae",
    "College of New Caledonia",
    "Kwantlen Polytechnic University",
    "Universitv of Northern British Columbia",
    "Coast Mountain Colleae",
    "Trinity Western Universitv",
    "Lanqara",
    "North Island Colleae",
    "Justice Institute of British Columbia",
    "Parkland",
    "Saskatchewan Polytechnic",
    "University of Regina",
    "Red River Colleae",
    "Manitoba Institute of Trades and Technoloav MITT",
    "Assiniboine Communitv Colleae",
    "Canada Colleae",
    "Trebas Institute",
    "Caoe Breton Universitv",
    "Mount Saint Vincent University",
    "St. Francis Xavier Universitv",
    "Acadia Universitv",
    "Mount Allison Universitv",
    "Universitv of New Brunswick",
    "Crandall Universitv",
    "University of Prince Edward Island"
  ];

  partnerCollegesList = [];
  nonPartnerCollegesList = [];
  non_partner_fee = 0;
  partner_fee = 0;
  transactionFee = 0;
  finalTotalPay = 0;

  constructor(
    private _admission: AdmissionService,
    public dialog: MatDialog,
    private _profile: ProfileService
  ) { }

  ngOnInit(): void {
    this._profile.getuser().subscribe(res => {
      if (res.student.country === "Canada") {
        this.taxInvoice = true;
      } else {
        this.taxInvoice = false;
      }
    });
    this.loadStripe();
    this.checkBoxForm = new FormGroup({
      'checkboxinput': new FormControl(false, Validators.requiredTrue),
      'checkboxinput2': new FormControl(false, Validators.requiredTrue)
    });
    this._admission.getAdmission().subscribe(res => {
      this.applicationStatus = res.applicationStatus;

      var margeArray = [
        ...this.applicationStatus.university,
        ...this.applicationStatus.colleges
      ];

      for (let college of margeArray) {
        for (let partnerCollege of this.partnersColleges) {
          if (college.collegeName) {
            let matchCheck = college.collegeName.toLowerCase().search(partnerCollege.toLowerCase());
            if (matchCheck !== -1) {
              this.partnerCollegeInclude = true;
            }
          } else {
            let matchCheck = college.universityName.toLowerCase().search(partnerCollege.toLowerCase());
            if (matchCheck !== -1) {
              this.partnerCollegeInclude = true;
            }
          }
        }
        // Add Partner College
        if (this.partnerCollegeInclude === false) {
          this.nonPartnerCollegesList.push(college);
        } else {
          this.partnerCollegesList.push(college);
          this.partnerCollegeInclude = false;
        }
      }

      this.partner_fee = 0;
      this.non_partner_fee = 0;

      if (this.partnerCollegesList.length !== 0) {
        if (this.partnerCollegesList.length <= 5) {
          this.partner_fee = 100
        } else {
          this.partner_fee = 100 + ((this.partnerCollegesList.length - 5) * 100)
        }
      }

      this.non_partner_fee = this.nonPartnerCollegesList.length * 100;
      this.totalPay = this.non_partner_fee + this.partner_fee;
      this.transactionFee = this.calcPercent(this.totalPay, 6.5);
      this.finalTotalPay = this.totalPay + this.transactionFee;
    });
  }

  openAppSummaryDialog() {
    this.dialogRef = this.dialog.open(AdmissionApplicationSummaryDialogComponent, {
      width: '1200px',
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  payAmount() {
    if (this.checkBoxForm.valid) {
      var handler = (<any>window).StripeCheckout.configure({
        key: environment.stripeKey,
        locale: 'auto',
        token: (paymentDetails: any) => {
          const finalPaymentDetails = {
            ...paymentDetails,
            payAmount: this.finalTotalPay
          }
          this._admission.paymentDone(finalPaymentDetails).subscribe((res) => {
            this._admission.createAdmissionInvoice(this.profile._id).subscribe(invoice => {
              window.location.reload();
            });
            setTimeout(() => {
              window.location.reload();
            }, 5000)
          });
        }
      });

      handler.open({
        name: 'Student-Stop',
        description: 'Payment of Application preparation support and guidance',
        amount: this.finalTotalPay * 100
      });
    } else {
      this.formError = true;
      setTimeout(() => {
        this.formError = false;
      }, 3000)
    }
  }

  loadStripe() {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }

  calcPercent(num, percentage) {
    return num * (percentage / 100);
  }

}
