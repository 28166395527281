import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FoodService {

  private _completeProfile = environment.server;

  constructor(private http: HttpClient) { }

  food(id, data) {
    return this.http.post<any>(this._completeProfile + '/service/food/' + id, data);
  }

  getFoodDetails(id) {
    return this.http.get<any>(this._completeProfile + '/service/food/get-food/' + id);
  }

  editFoodSubmit(data) {
    return this.http.post<any>(this._completeProfile + '/service/food/edit-food/', data);
  }

  foodSubmit(data) {
    return this.http.post<any>(this._completeProfile + '/service/food/add-food', data);
  }

  getAvailableFoods(data) {
    return this.http.post<any>(this._completeProfile + '/service/food/get-available-foods', data, { responseType: 'text' as 'json' });
  }

  selectFood(data) {
    return this.http.post<any>(this._completeProfile + '/service/food/select-food', data);
  }

  getfoodbyId(id) {
    return this.http.get<any>(this._completeProfile + '/service/food/get-food-by-id/' + id);
  }
}
