<main class="bg-body-color">
  <app-site-header></app-site-header>

  <section class="page-banner">
    <div class="image-layer banner-img"></div>

    <div class="banner-inner">
      <div class="container">
        <div class="inner-container clearfix">
          <h1 class="wow animate__animated animate__fadeIn">Our Services</h1>
          <div class="page-nav">
            <ul class="bread-crumb clearfix">
              <li><a href="/">Home</a></li>
              <li class="active">Our Services</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-inner-top-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="service-inner-content">
            <div class="sec-title">
              <div class="title wow animate__animated animate__fadeInUp">Our Services</div>
              <div class="separator"><span></span></div>
            </div>
            <h2 class="wow animate__animated animate__fadeInUp">Studying abroad has never been easier.</h2>
            <p class="wow animate__animated animate__fadeIn animate__delay-1s">Student-Stop, is the one-stop-shop
              for all your international student requirements. Student-Stop provides you with all the services that
              a student may need. Your journey with us begins from the planning phase till your arrival in Canada and
              thereafter. </p>
            <p class="wow animate__animated animate__fadeIn animate__delay-1s"><a href="/register">Register</a> now and
              start your journey today! Registration is free.</p>


          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="why-choose-image">
            <img src="/assets/images/our-services.png" alt="image">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="services-section-two">
    <div class="container">
      <div class="sec-title text-center">
        <h2 class="white-text wow animate__animated animate__fadeIn">Our Services</h2>
        <div class="separator center"><span></span></div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-sm-6 wow animate__animated animate__slideInUp">
          <div class="service-card-one bg-white white-two center">
            <div class="icon">
              <i class="fi flaticon-guarantee"></i>
            </div>
            <h3>
              <a href="/services/admission">Admission Support</a>
            </h3>
            <p>We support you in preparing your University and college applications. We also
              help with admission in our partner universites/colleges. </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 wow animate__animated animate__slideInUp">
          <div class="service-card-one bg-white white-two center">
            <div class="icon">
              <i class="fi flaticon-content"></i>
            </div>
            <h3>
              <a href="/services/study-permit">Immigration/Study Permit</a>
            </h3>
            <p>We offer legal support to prepare and submit your study permit application. We ensure that everything is
              in order before you depart.</p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 wow animate__animated animate__slideInUp">
          <div class="service-card-one bg-white white-two center">
            <div class="icon">
              <i class="fi flaticon-email"></i>
            </div>
            <h3>
              <a href="/services/banking">Banking & Finance</a>
            </h3>
            <p>We support you in opening a Canadian Bank account for your GIC requirement & arrange study loans before
              you arrive in Canada.</p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 wow animate__animated animate__slideInUp">
          <div class="service-card-one bg-white white-two center">
            <div class="icon">
              <i class="fi flaticon-aim"></i>
            </div>
            <h3>
              <a href="/services/food">Food</a>
            </h3>
            <p>Getting your favourite food is not a hassle anymore. Register for our meal plan available in selected
              cities and get excellent quality food of your choice.</p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 wow animate__animated animate__slideInUp">
          <div class="service-card-one bg-white white-two center">
            <div class="icon">
              <i class="fi flaticon-paper-plane"></i>
            </div>
            <h3>
              <a href="/services/travel">Travel</a>
            </h3>
            <p>Arrange your flights and local transportation making your arrival in Canada a very smooth and comfortable
              experience.</p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 wow animate__animated animate__slideInUp">
          <div class="service-card-one bg-white white-two center">
            <div class="icon">
              <i class="fi flaticon-house"></i>
            </div>
            <h3>
              <a href="/services/housing">Housing</a>
            </h3>
            <p>We help you find comfortable housing near to campus to make your daily commute easy and guarantee
              yourself a place to stay.</p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 wow animate__animated animate__slideInUp">
          <div class="service-card-one bg-white white-two center">
            <div class="icon">
              <i class="fi flaticon-work"></i>
            </div>
            <h3>
              <a href="/services/student-job">Student Job</a>
            </h3>
            <p>Want to work part time to meet your expenses or just want to get some work experience? We use our network
              to bring student work opportunities during and post-academic completion.</p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 wow animate__animated animate__slideInUp">
          <div class="service-card-one bg-white white-two center">
            <div class="icon">
              <i class="fas fa-notes-medical"></i>
            </div>
            <h3>
              <a href="/services/medical-insurance">Medical Insurance</a>
            </h3>
            <p>Medical insurance is a necessity when travelling abroad. In case of an emergency, medical expenses can be
              extremely high. Register and let us take care of this requirement.</p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 wow animate__animated animate__slideInUp">
          <div class="service-card-one bg-white white-two center">
            <div class="icon">
              <i class="fi flaticon-visibility"></i>
            </div>
            <h3>
              <a href="/services/important-information">Important Information</a>
            </h3>
            <p>On <strong>Student-Stop</strong>, we provide you with all the important information, that will prepare
              you for your stay
              in Canada. Click here to check out a snapshot of general important information.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-site-footer></app-site-footer>
</main>
