<div class="navbar-custom">
  <div class="container-fluid">
    <ul class="list-unstyled topnav-menu float-right mb-0">

      <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right" *ngIf="userProfile">
        <a class="nav-link nav-user mr-0 waves-effect waves-light" tabindex="0" ngbDropdownToggle id="profileDropdown"
          role="button">
          <img [src]="url + 'uploads/profilePic/' + userProfile.profilePic" alt="user-image" class="rounded-circle">
          <span class="pro-user-name ml-1">
            {{userProfile.firstname}} {{userProfile.middlename}} {{userProfile.lastname}}
            <i class="mdi mdi-chevron-down"></i>
          </span>
        </a>
        <div ngbDropdownMenu aria-labelledby="profileDropdown"
          class="dropdown-menu dropdown-menu-right profile-dropdown">
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome !</h6>
          </div>
          <a ngbDropdownItem [routerLink]="['/profile']" class="notify-item">
            <i class="fe-user"></i>
            <span>My Profile</span>
          </a>
          <a ngbDropdownItem (click)="logout()" class="notify-item">
            <i class="fe-log-out"></i>
            <span>Logout</span>
          </a>
        </div>
      </li>
    </ul>

    <div class="logo-box">
      <a routerLink="/" class="logo logo-light text-center">
        <span class="logo-lg">
          <img src="/assets/images/ss-logo-dark.png" alt="">
        </span>
      </a>
    </div>

    <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
      <li>
        <button id="sidebar-btn" class="button-menu-mobile waves-effect waves-light">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-align-center font-primary" id="sidebar-toggle">
            <line x1="18" y1="10" x2="6" y2="10"></line>
            <line x1="21" y1="6" x2="3" y2="6"></line>
            <line x1="21" y1="14" x2="3" y2="14"></line>
            <line x1="18" y1="18" x2="6" y2="18"></line>
          </svg>
        </button>
      </li>

      <li *ngFor="let item of menu" class="dropdown d-none d-xl-block">
        <a *ngIf="!item.children" class="nav-link" [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }" [routerLink]="item.href">{{item.label}}</a>
        <a *ngIf="item.children" class="nav-link dropdown-toggle waves-effect waves-light" data-toggle="dropdown"
          [routerLink]="item.href" role="button" aria-haspopup="false" aria-expanded="false">{{item.label}}</a>
        <div *ngIf="item.children" class="dropdown-menu">
          <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="submenu.href"
            *ngFor="let submenu of item.children" class="dropdown-item">
            <span>{{submenu.label}}</span>
          </a>
        </div>
      </li>
    </ul>
    <div class="clearfix"></div>
  </div>
</div>
