import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../../../../../services/profile.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { AdmissionService } from 'src/app/services/admission.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-admission-status',
  templateUrl: './admission-status.component.html',
  styleUrls: ['./admission-status.component.scss']
})
export class AdmissionStatusComponent implements OnInit {

  url;
  currentStatus;
  reuploadDocuments;
  selectedFile;
  uploadDocumentsData = [];
  submitbtnValidation;
  documentValidation = false;
  documentDone;
  dialogRef;
  @Input() userStudyDetails;
  @Input() admissionService;
  @Input() documents;
  applicationStatus;
  studyList = [];
  gradeList = [];
  applied = 0;
  status = 0;
  universityAppliedArray = 0;
  collegeAppliedArray = 0;
  universityStatusArray = 0;
  collegeStatusArray = 0;

  final = false;
  loading = false;

  downloadLinkInvoice;

  constructor(
    private _document: DocumentsService,
    private _admission: AdmissionService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.url = environment.url;
    this.fetchAdmission(this.admissionService, this.documents);

    $(document).on('change', '.up', function () {
      var names: any = [];
      var length = $(this).get(0).files.length;
      for (var i = 0; i < $(this).get(0).files.length; ++i) {
        names.push($(this).get(0).files[i].name);
      }
      if (length > 2) {
        var fileName = names.join(', ');
        $(this).closest('.form-group').find('.form-control').attr("value", length + " files selected");
      }
      else {
        $(this).closest('.form-group').find('.form-control').attr("value", names);
      }
    });
  }

  selectFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const file_size = event.target.files[0].size;
      const fileType = file['type'];
      const validImageTypes = ['image/jpeg', 'image/jpg', 'application/pdf'];
      if (file_size > 4194304) {
        $("#file_error").html("File size must less then 4MB. Compress file <a target='blank' href='https://www.wecompress.com/en/'>Here</a>").show().delay(5000).fadeOut('slow');
        $("#uploadShow").css("border: 1px solid red");
        this.selectedFile = null;
        this.documentValidation = false;
      } else if (!validImageTypes.includes(fileType)) {
        $("#file_error").html("Invalid Document only Accept jpg, jpeg and pdf").show().delay(5000).fadeOut('slow');;
        $("#uploadShow").css("border: 1px solid red");
        this.selectedFile = null;
        this.documentValidation = false;
      } else {
        this.selectedFile = file;
        this.documentValidation = true;
      }
    }
  }

  docDiv() {
    document.getElementById("reuploadDiv").scrollIntoView();
  }

  uploadDocuments(content, name) {
    this.dialogRef = this.dialog.open(content, {
      width: '500px',
      data: { name: name },
      autoFocus: false,
      hasBackdrop: false
    });
  }

  uploadDocumentsFile(name) {
    this.loading = true;
    const formData = new FormData();
    formData.append('document', this.selectedFile);
    formData.append('name', name);

    this._document.uploadDocument(formData).subscribe((res) => {
      this._document.documentReupload({ name }, 'Admission').subscribe((res) => {
        this.loading = false;
        this.closeDialog();
        window.location.reload();
      });
    });
  }

  reuploadConfirm() {
    this._document.reuploadDocumentSubmit(true, 'Admission').subscribe((res) => {
      window.location.reload();
    });
  }

  fetchDoc(doc) {
    var uploadDocuments = [];

    for (let degreeIndex in this.userStudyDetails.degree) {
      if (this.userStudyDetails.degree[degreeIndex].itemName === "Master's") {
        for (let i in doc.documents) {
          for (let categoryIndex in doc.documents[i].category) {
            if (doc.documents[i].category[categoryIndex] === "Master Degree In Admission") {
              uploadDocuments.push(doc.documents[i]);
            }
          }
        }
      }
      if (this.userStudyDetails.degree[degreeIndex].itemName === "Bachelor's Degree") {
        for (let i in doc.documents) {
          for (let categoryIndex in doc.documents[i].category) {
            if (doc.documents[i].category[categoryIndex] === "Bachelor Degree In Admission") {
              uploadDocuments.push(doc.documents[i]);
            }
          }
        }
      }
      if (
        this.userStudyDetails.degree[degreeIndex].itemName === "Associate Degree" ||
        this.userStudyDetails.degree[degreeIndex].itemName === "Diploma" ||
        this.userStudyDetails.degree[degreeIndex].itemName === "Certificate" ||
        this.userStudyDetails.degree[degreeIndex].itemName === "Bachelor's/First" ||
        this.userStudyDetails.degree[degreeIndex].itemName === "Graduate Certificate"
      ) {
        for (let i in doc.documents) {
          for (let categoryIndex in doc.documents[i].category) {
            if (
              doc.documents[i].category[categoryIndex] === "Associate Degree In Admission" ||
              doc.documents[i].category[categoryIndex] === "Diploma In Admission" ||
              doc.documents[i].category[categoryIndex] === "Certificate In Admission" ||
              doc.documents[i].category[categoryIndex] === "Bachelor's/First In Admission"
            ) {
              uploadDocuments.push(doc.documents[i]);
            }
          }
        }
      }
    }

    this.uploadDocumentsData = uploadDocuments.reduce((unique, o) => {
      if (!unique.some(obj => obj.name === o.name)) {
        unique.push(o);
      }
      return unique;
    }, []);

    for (let docIndex in this.uploadDocumentsData) {
      if (this.uploadDocumentsData[docIndex].url !== null) {
        this._document.viewDoc({ name: this.uploadDocumentsData[docIndex].name }).subscribe(
          res => {
            this.uploadDocumentsData[docIndex].url = res['document_url']
          },
          err => {
            console.log(err)
          }
        )
      }
    }

    const validator = uploadDocuments.filter(item => item.url === null);
    const mandatoryCheck = validator.filter(item => item.mandatory === true)
    if (mandatoryCheck.length === 0) {
      this._document.reuploadDocumentSubmit(true, 'Admission').subscribe((res) => {
        window.location.reload();
      });
    }
  }

  removeDocuments(value): void {
    this._document.deleteDocument({ name: value }).subscribe((res) => {
      this._document.documentReuploadDelete(value, 'Admission').subscribe((res) => {
        window.location.reload();
      });
    })
  }

  closeDialog() {
    this.dialogRef.close();
    this.selectedFile = null;
    this.documentValidation = false;
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  onDialogClose(): void {
    this.dialogRef.close();
    this.selectedFile = null;
    this.documentValidation = false;
  }

  getColor(color) {
    switch (color) {
      case 'Application Not yet Submitted':
        return '#ff4d0d';
      case 'Application Submitted':
        return '#80cf00';
    }
  }

  getStatusColor(color) {
    switch (color) {
      case 'Awaiting Decision':
        return '#ff4d0d';
      case 'Congratulation, you have been Accepted':
        return '#80cf00';
      case 'Rejected':
        return '#fc1f57';
    }
  }

  fetchAdmission(res, doc) {
    if (res.currentStatus.documents.reupload === true) {
      this.fetchDoc(doc);
    }

    let admissionStatus = res;
    this.applicationStatus = admissionStatus.applicationStatus;

    if (admissionStatus.invoice !== null && admissionStatus.invoice !== undefined) {
      this.onClickDownloadPdf(admissionStatus.invoice, 'invoice')
    }

    // Applied
    for (var i = 0; i < this.applicationStatus.university.length; i++) {
      if (this.applicationStatus.university[i].applied !== "Application Not yet Submitted") {
        this.universityAppliedArray += 1;
      }
    }

    for (var i = 0; i < this.applicationStatus.colleges.length; i++) {
      if (this.applicationStatus.colleges[i].applied !== "Application Not yet Submitted") {
        this.collegeAppliedArray += 1;
      }
    }

    if (this.universityAppliedArray > 0 && this.universityAppliedArray < this.applicationStatus.university.length) {
      this.applied = 1;
    }

    if (this.universityAppliedArray === this.applicationStatus.university.length) {
      this.applied = 2;
    }

    if (this.applied === 2) {
      if (this.collegeAppliedArray === 0) {
        this.applied = 1;
      }
    }

    if (this.collegeAppliedArray > 0 && this.collegeAppliedArray < this.applicationStatus.colleges.length) {
      this.applied = 1;
    }

    if (this.collegeAppliedArray === this.applicationStatus.colleges.length && this.universityAppliedArray === this.applicationStatus.university.length) {
      this.applied = 2;
    }

    // Status
    for (var i = 0; i < this.applicationStatus.university.length; i++) {
      if (this.applicationStatus.university[i].status !== "Awaiting Decision") {
        this.universityStatusArray += 1;
      }
    }

    for (var i = 0; i < this.applicationStatus.colleges.length; i++) {
      if (this.applicationStatus.colleges[i].status !== "Awaiting Decision") {
        this.collegeStatusArray += 1;
      }
    }

    if (this.universityStatusArray > 0 && this.universityStatusArray < this.applicationStatus.university.length) {
      this.status = 1;
    }

    if (this.universityStatusArray === this.applicationStatus.university.length) {
      this.status = 2;
    }

    if (this.status === 2) {
      if (this.collegeStatusArray === 0) {
        this.status = 1;
      }
    }

    if (this.collegeStatusArray > 0 && this.collegeStatusArray < this.applicationStatus.colleges.length) {
      this.status = 1;
    }

    if (this.collegeStatusArray === this.applicationStatus.colleges.length && this.universityStatusArray === this.applicationStatus.university.length) {
      this.status = 2;
    }

    if (this.applied === 2 && this.status === 2) {
      if (res.currentStatus.medVerify.status === false) {
        this._admission.allDoneAdmission().subscribe((res) => {
          window.location.reload();
        });
      }
    }

    this.currentStatus = admissionStatus.currentStatus;
    if (admissionStatus.currentStatus.documents.reupload === true) {
      this.reuploadDocuments = admissionStatus.currentStatus.reuploadDocuments;
      this.reuploadDocuments = this.reuploadDocuments.sort(this.dynamicSort("name"));
    }
    this.studyList = admissionStatus.studyInfo;
    this.gradeList = admissionStatus.gradeList;
  }

  onClickDownloadPdf(url, type) {
    if (type === 'invoice') {
      this._document.downloadDoc(url).subscribe(
        res => {
          this.downloadLinkInvoice = res['document_url'];
        },
        err => {
          console.log(err)
        }
      )
    }
  }
}
