import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-confirm-mail',
  templateUrl: './confirm-mail.component.html',
  styleUrls: ['./confirm-mail.component.scss']
})
export class ConfirmMailComponent implements OnInit {

  success;
  studentType;

  constructor(
    private route: ActivatedRoute,
    private _profile: ProfileService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this._profile.confirmMail(id).subscribe((res) => {
        if (res === null) {
          this.success = 0
        } else {
          if (res.userType === "student") {
            this.studentType = 0;
            this.success = 1;
          } else if (res.userType === "partner") {
            this.studentType = 1;
            this.success = 1;
          } else if (res.userType === "food") {
            this.studentType = 3;
            this.success = 1;
          } else {
            this.studentType = 2;
            this.success = 1;
          }
        }
      });
    });
  }

}
