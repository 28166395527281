<div class="card wow animate__animated animate__fadeInUp">
  <div class="card-body gray-bg">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="wrap-search-filter">
          <form [formGroup]="searchForm" (ngSubmit)="searchJob()" class="row">
            <div class="col-md-10 col-sm-10">
              <input type="text" class="form-control" formControlName="name" placeholder="Search Product...">
            </div>
            <div class="col-md-2 col-sm-2">
              <button type="submit" class="btn btn-primary full-width">Search</button>
            </div>
          </form>
        </div>

        <div *ngIf="requestedProducts?.length !== 0">
          <div class="alert alert-primary">
            You Requested {{requestedProducts?.length}} Products for Buy.
            <button class="btn btn-secondary ml-2" (click)="viewItems(viewProd)">View Products</button>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-4" *ngFor="let product of products; let i = index">
            <article class="prod-card">
              <div class="prod-card-thumb">
                <a>
                  <img class="prod-card-img" [src]="product.image" alt="product-img">
                </a>
              </div>
              <div class="prod-card-content text-center">
                <h3 class="prod-card-title">{{product.name}}</h3>
                <div [ngClass]="{'showMore': idClass === i }" id={{i}} class="description"
                  [innerHTML]="product.description"></div>
                <a (click)="showMore(i)" class="show-more-btn">{{(idClass === i) ? 'Show Less' : 'Show More'}}</a>
                <div class="prod-card-price">
                  <span class="current-price">${{product.price}}</span>
                </div>
                <button (click)="buyProd(product)" class="prod-card-btn">Buy Now</button>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>


    <ng-template #viewProd>
      <div class="card">
        <div class="card-header">
          Requested Products
          <button class="close" aria-label="Close" (click)="onDialogClose()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="card-body">
          <div class="row mt-3">
            <div class="col-md-6" *ngFor="let product of requestedProducts; let i = index">
              <article class="prod-card">
                <div class="prod-card-thumb">
                  <a>
                    <img class="prod-card-img" [src]="product.productId.image" alt="product-img">
                  </a>
                </div>
                <div class="prod-card-content text-center">
                  <h3 class="prod-card-title">{{product.productId.name}}</h3>
                  <p><strong>Quantity:</strong> {{product.quantity}}</p>
                  <p><strong>Delivery Address:</strong> {{product.address}}</p>
                  <div class="prod-card-price">
                    <span class="current-price">${{product.productId.price}}</span>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
