<div class="card">
  <div class="card-header">Select Document
    <button class="close" aria-label="Close" (click)="onDialogClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <div *ngIf="loading" class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="alert alert-info dark mt-0 mt-0" role="alert">
      <p>Please upload documents only in JPEG, JPG & PDF formats with a maximum size of 4MB. Other
        formats are not accepted. </p>
    </div>
    <form class="form-group box-form" novalidate>
      <div class="row">
        <div class="col-md-12">
          <div class="input-group">
            <input type="text" class="form-control" readonly>
            <div class="input-group-btn">
              <span class="fileUpload btn btn-danger">
                <span class="upl" id="upload">Select Document</span>
                <input type="file" class="upload up btn btn-primary" id="up" [name]="docName"
                  (change)="selectFile($event)" required />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-3">
        <button type="submit" class="btn btn-square btn-primary w-100" [disabled]="!documentValidation"
          (click)="uploadDocumentsFile(docName)">Upload</button>
      </div>
      <div id="file_error"></div>
    </form>
  </div>
</div>
