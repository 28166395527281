import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdmissionService } from 'src/app/services/admission.service';
import { SelectOptions } from 'src/app/sharedata/selectedOptions';
import { SelectOptionData } from 'src/app/sharedata/selectOptionData';

@Component({
  selector: 'app-admission-confirm',
  templateUrl: './admission-confirm.component.html',
  styleUrls: ['./admission-confirm.component.scss']
})
export class AdmissionConfirmComponent implements OnInit {

  @Input() userStudyDetails;
  admissionForm: FormGroup;

  collegesList;
  degreeList;
  universityList;
  countryList;
  stateList;
  programsList;
  interestedList;

  universityShow = true;
  collegesShow = true;

  nonEditStudy = true;

  universitySettings;
  collegesSettings;
  degreeSettings;
  programsSettings;
  interestedSettings;

  constructor(
    private _admission: AdmissionService,
    private _formBuilder: FormBuilder
  ) {
    const serviceData = new SelectOptionData;
    const selectedOptions = new SelectOptions;

    this.collegesList = serviceData.collegeList();
    this.countryList = serviceData.country();
    this.degreeList = serviceData.degree();
    this.universityList = serviceData.university();
    this.stateList = serviceData.state();
    this.programsList = serviceData.program();
    this.interestedList = serviceData.interested();

    this.universitySettings = selectedOptions.universitySettings();
    this.collegesSettings = selectedOptions.collegesSettings();
    this.degreeSettings = selectedOptions.degreeSettings();
    this.programsSettings = selectedOptions.programsSettings();
    this.interestedSettings = selectedOptions.interestedSettings();
  }

  ngOnInit(): void {
    this.admissionForm = this._formBuilder.group({
      'interested': new FormControl(null, [Validators.required]),
      'degree': new FormControl('', [Validators.required]),
      'university': new FormControl('', [Validators.required]),
      'colleges': new FormControl('', [Validators.required]),
      'programs': new FormControl('', [Validators.required]),
      'currentStudy': new FormControl(''),
      'scholarship': new FormControl('')
    });

    if (this.userStudyDetails) {
      this.admissionForm = this._formBuilder.group({
        'interested': new FormControl(null, [Validators.required]),
        'degree': new FormControl(this.userStudyDetails.degree, [Validators.required]),
        'university': new FormControl(this.userStudyDetails.university, [Validators.required]),
        'colleges': new FormControl(this.userStudyDetails.colleges, [Validators.required]),
        'programs': new FormControl(this.userStudyDetails.programs, [Validators.required]),
        'currentStudy': new FormControl(this.userStudyDetails.currentStudy),
        'scholarship': new FormControl('')
      });

      if (this.userStudyDetails.interested[0]?.itemName === "Universities") {
        this.admissionForm.get('interested').setValue(this.userStudyDetails.interested);
        this.admissionForm.get('colleges').disable();
        this.universityShow = true;
        this.collegesShow = false;
      } else if (this.userStudyDetails.interested[0]?.itemName === "Colleges") {
        this.admissionForm.get('interested').setValue(this.userStudyDetails.interested);
        this.admissionForm.get('university').disable();
        this.universityShow = false;
        this.collegesShow = true;
      } else if (this.userStudyDetails.interested[0]?.itemName === "Universities And Colleges") {
        this.admissionForm.get('interested').setValue(this.userStudyDetails.interested);
        this.universityShow = true;
        this.collegesShow = true;
      }
    }
  }

  submitAdmission() {
    this._admission.startAdmission().subscribe((res) => {
      window.location.reload()
    });
  }

  editStudyPopup() {
    if (this.nonEditStudy === true) {
      this.nonEditStudy = false
    } else {
      this.nonEditStudy = true
    }
  }

  onSubmit() {
    this._admission.startAdmissionWithData(this.admissionForm.value)
      .subscribe(
        res => {
          window.location.reload();
        },
        err => {
          console.log(err)
        }
      )
  }

  onSelectInterested() {
    this.admissionForm.get('interested').valueChanges.subscribe(selectedInterested => {
      if (selectedInterested.length !== 0) {
        if (selectedInterested[0].itemName === 'Universities') {

          this.degreeList = [
            { "id": 4, "itemName": "Bachelor's/First", value: "Bachelor's/First (8 semesters / 4 years)" },
            { "id": 5, "itemName": "Master's", value: "Master's (2-4 semesters / 1-2 years)" }
          ];

          this.admissionForm.get('degree').reset();

          this.admissionForm.get('university').setValue(this.userStudyDetails.university);
          this.admissionForm.get('university').enable();

          this.admissionForm.get('colleges').setValue([]);
          this.admissionForm.get('colleges').disable();

          this.universitySettings['disabled'] = false;
          this.universitySettings = Object.assign({}, this.universitySettings);

          this.collegesSettings['disabled'] = true;
          this.collegesSettings = Object.assign({}, this.collegesSettings);

          this.universityShow = true;
          this.collegesShow = false;
        } else if (selectedInterested[0].itemName === 'Colleges') {

          this.degreeList = [
            { "id": 1, "itemName": "Certificate", value: "Certificate (<= 2 semesters / 1 year)" },
            { "id": 2, "itemName": "Diploma", value: "Diploma (at least 4 semesters / 2 years)" },
            { "id": 3, "itemName": "Associate Degree", value: "Associate Degree (4 semesters / 2 years)" },
            { "id": 4, "itemName": "Bachelor's/First", value: "Bachelor's/First (8 semesters / 4 years)" },
            { "id": 6, "itemName": "Graduate Certificate", value: "Graduate Certificate (2 Semesters / 1 year)" }
          ];

          this.admissionForm.get('degree').reset();

          this.admissionForm.get('university').setValue([]);
          this.admissionForm.get('university').disable();

          this.admissionForm.get('colleges').setValue(this.userStudyDetails.colleges);
          this.admissionForm.get('colleges').enable();

          this.universitySettings['disabled'] = true;
          this.universitySettings = Object.assign({}, this.universitySettings);

          this.collegesSettings['disabled'] = false;
          this.collegesSettings = Object.assign({}, this.collegesSettings);

          this.universityShow = false;
          this.collegesShow = true;
        } else if (selectedInterested[0].itemName === 'Universities And Colleges') {
          const serviceData = new SelectOptionData;
          this.degreeList = serviceData.degree();

          this.admissionForm.get('degree').reset();

          this.admissionForm.get('university').setValue(this.userStudyDetails.university);
          this.admissionForm.get('colleges').setValue(this.userStudyDetails.colleges);

          this.admissionForm.get('university').enable();
          this.admissionForm.get('colleges').enable();

          this.universitySettings['disabled'] = false;
          this.universitySettings = Object.assign({}, this.universitySettings);

          this.collegesSettings['disabled'] = false;
          this.collegesSettings = Object.assign({}, this.collegesSettings);

          this.universityShow = true;
          this.collegesShow = true;
        }
      } else {
        const serviceData = new SelectOptionData;
        this.degreeList = serviceData.degree();

        this.admissionForm.get('degree').reset();

        this.admissionForm.get('university').setValue([]);
        this.admissionForm.get('colleges').setValue([]);

        this.universitySettings['disabled'] = true;
        this.universitySettings = Object.assign({}, this.universitySettings);

        this.collegesSettings['disabled'] = true;
        this.collegesSettings = Object.assign({}, this.collegesSettings);

        this.universityShow = false;
        this.collegesShow = false;
      }
    });
  }

}
